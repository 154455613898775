import { commonRoutes } from 'routes/route.common';

export const contractDomainRoutes = {
  base: 'contract',
  subdomains: {
    contract: {
      base: 'contract',
    },
    constractTemplate: {
      base: 'contract-template',
    },
  },
};

export const baseContractRoute = (): string => `/${contractDomainRoutes.base}/`;
export const listContractRoute = (): string =>
  `/${contractDomainRoutes.base}`;
export const listContractTemplate = (): string =>
  `/${contractDomainRoutes.base}/${contractDomainRoutes.subdomains.constractTemplate.base}`;
