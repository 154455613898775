/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from '@mui/material';
import { IUserDetailResult } from 'corede-common-cocrm';

interface IUserDetailProfileSectionComponent {
  userData: IUserDetailResult | undefined;
}

export const UserDetailProfileSectionComponent = (props: IUserDetailProfileSectionComponent) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'end'}
      gap={1}
      sx={{
        mt: '-54px',
      }}
    >
      <Avatar
        sx={{
          width: '108px',
          height: '108px',
          ml: 2,
          border: '5px solid #fff',
          objectFit: 'cover',
          fontSize: '40px',
        }}
        alt={props.userData?.name}
        src={props.userData?.profileImage?.thumbnailPublicUrl}
      >
        {(props.userData?.name + ' ' + props.userData?.surname)
          ?.split(' ')
          ?.map((n) => n[0])
          .join('')
          .toUpperCase()}
      </Avatar>

      <Stack direction={'column'}>
        <Typography variant="h5">
          {props.userData?.name} {props.userData?.surname}{' '}
        </Typography>
        <Typography variant="subtitle2" color={'text.secondary'}>
          {(props.userData as any)?.department?.name}
        </Typography>
      </Stack>
    </Stack>
  );
};
