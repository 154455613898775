import { Route } from 'react-router-dom';
import {
  ForgotPassword,
  Login,
  Signup,
  ForgotPasswordSuccessfull,
  Confirmation,
  ResetPassword,
  CreatePassword,
  SignupSuccessfull,
  EstimatePublic,
  ResendConfirmationSuccessfull,
  Proposal,
  Estimate,
  Invoice,
} from '../pages';
import { routes } from './routes';
import RedirectSuccess from '../pages/RedirectSuccess';
import RedirectError from '../pages/RedirectError';
import LeadFormPublic from '../pages/LeadFormPublic';

export const AuthRouter = () => {
  return (
    <>
      <Route path={routes.auth.login} element={<Login />} />
      <Route path={routes.auth.signup} element={<Signup />} />
      <Route path={routes.auth.forgotPassword} element={<ForgotPassword />} />
      <Route path={routes.auth.forgotPasswordSuccessfull} element={<ForgotPasswordSuccessfull />} />
      <Route
        path={routes.auth.resendConfirmationSuccessfull}
        element={<ResendConfirmationSuccessfull />}
      />
      <Route path={routes.auth.signupSuccessfull} element={<SignupSuccessfull />} />
      <Route path={routes.auth.confirmation} element={<Confirmation />} />
      <Route path={routes.auth.resetPassword} element={<ResetPassword />} />
      <Route path={routes.auth.createPassword} element={<CreatePassword />} />

      <Route path={'/oauth/login/success'} element={<RedirectSuccess />} />
      <Route path={'/oauth/register/success'} element={<RedirectSuccess />} />
      <Route path={'/oauth/register/fail'} element={<RedirectError />} />
      <Route path={'/oauth/login/fail'} element={<RedirectError />} />
      {/* <Route path="/estimate/:id" element={<EstimatePublic />} /> */}
      <Route path="/proposal" element={<Proposal />} />
      <Route path="/estimate" element={<Estimate />} />
      <Route path="/invoice" element={<Invoice />} />
      <Route path="/lead-form" element={<LeadFormPublic />} />
      <Route path="*" element={<Login />} />
    </>
  );
};
