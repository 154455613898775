import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { memo, useCallback } from 'react';
import Welcome from '../components/Welcome';
import Statistics from '../components/Statistics';
import LeadStatistics from './statisctics/LeadStatistics';
import CustomerStatistics from './statisctics/CustomerStatistics';
import EstimateStatistics from './statisctics/EstimateStatistics';
import ProposalStatistics from './statisctics/ProposalStatistics';
import TaskStatistics from './statisctics/TaskStatistics';
import { PermissionsAnyWrapper, PermissionWrapper } from 'permission/PermissionWrapper';
import { PermissionSubject } from 'corede-common-cocrm';

interface IHomeForActive {}

const HomeForActive = memo((props: IHomeForActive) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const renderWelcome = useCallback(() => <Welcome />, []);
  const renderStatistics = useCallback(() => <Statistics />, []);
  const renderLeadStatistics = useCallback(() => <LeadStatistics />, []);
  const renderCustomerStatistics = useCallback(() => <CustomerStatistics />, []);
  const renderEstimateStatistics = useCallback(() => <EstimateStatistics />, []);
  const renderProposalStatistics = useCallback(() => <ProposalStatistics />, []);
  const renderTaskStatistics = useCallback(() => <TaskStatistics />, []);

  return (
    <>
      <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'}>
        {renderWelcome()}
        <PermissionsAnyWrapper
          checksAny={[
            {
              subject: PermissionSubject.lead,
            },
            {
              subject: PermissionSubject.customer,
            },
            {
              subject: PermissionSubject.estimate,
            },
            {
              subject: PermissionSubject.proposal,
            },
          ]}
        >
          {renderStatistics()}
        </PermissionsAnyWrapper>
      </Stack>

      {/* LEAD */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.lead,
        }}
      >
        {renderLeadStatistics()}
      </PermissionWrapper>

      {/* CUSTOMER */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.customer,
        }}
      >
        {renderCustomerStatistics()}
      </PermissionWrapper>

      {/* ESTIMATE */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.estimate,
        }}
      >
        {renderEstimateStatistics()}
      </PermissionWrapper>

      {/* PROPOSAL */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.proposal,
        }}
      >
        {renderProposalStatistics()}
      </PermissionWrapper>

      {/* TASK */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.task,
        }}
      >
        {renderTaskStatistics()}
      </PermissionWrapper>

      <Box height={40} mb={10} width={10} />

      {/* <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'}>
        <CustomBarChart
          title="Lead filter with Status"
          subtitle="Weekly Report"
          theme="info"
          flexBasis={'33%'}
          data={[
            {
              name: 'C',
              value1: 124,
              value2Format: {
                count: 15,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'N',
              value1: 24,
              value2Format: {
                count: 15,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'U',
              value1: 14,
              value2Format: {
                count: 15,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'C',
              value1: 110,
              value2Format: {
                count: 15,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'Q',
              value1: 124,
              value2Format: {
                count: 15,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'Q',
              value1: 124,
              value2Format: {
                count: 67,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'N',
              value1: 90,
              value2Format: {
                count: 23,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'L',
              value1: 70,
              value2Format: {
                count: 25,
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
        />
        <CustomDonutChart
          title="Lead filter with Type"
          subtitle="Weekly Report"
          colors={['#24B364', '#53D28C', '#7EDDA9', '#A9E9C5']}
          theme="success"
          flexBasis={'33%'}
          data={[
            {
              name: 'A',
              value1: 4.325,
              value2Format: {
                count: 15.2,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'B',
              value1: 2.345,
              value2Format: {
                count: 58.2,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'C',
              value1: 1.243,
              value2Format: {
                count: 115.2,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'D',
              value1: 3.654,
              value2Format: {
                count: 1.2,
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
        />
      </Stack> */}

      {/* <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'}>
        <CustomVerticalChart
          title="Estimate by Status"
          flexBasis="66%"
          data={[
            {
              name: 'Open Tickets',
              color: 'tertiary',
              value: 35,
            },
            {
              name: 'In Progress',
              color: 'info',
              value: 20,
            },
            {
              name: 'Answered',
              color: 'success',
              value: 14,
            },
            {
              name: 'On Hold',
              color: 'primary',
              value: 12,
            },
            {
              name: 'Closed',
              color: 'error',
              value: 10,
            },
          ]}
        />

        <CustomProgressChart
          title="Services by Category"
          subtitle2="Total Proposal"
          value1={468}
          valueFormat={{
            count: 4.2,
            prefix: '+',
            suffix: '%',
          }}
          data={[
            {
              label: 'Lorem Ipsum',
              color: 'secondary',
              value: {
                count: 85,
                suffix: '%',
              },
            },
            {
              label: 'Lorem Ipsum',
              color: 'tertiary',
              value: {
                count: 86,
                suffix: '%',
              },
            },
            {
              label: 'Lorem Ipsum',
              color: 'success',
              value: {
                count: 90,
                suffix: '%',
              },
            },
            {
              label: 'Lorem Ipsum',
              color: 'success',
              value: {
                count: 90,
                suffix: '%',
              },
            },
          ]}
          flexBasis="33%"
        />
      </Stack> */}

      {/* <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'}>
        <CustomTabChart
          title="Type & TargetType"
          subtitle="Total "
          flexBasis={'33%'}
          data={[
            {
              tabLabel: 'Type',
              tabData: [
                {
                  icon: {
                    name: 'solar:user-minus-bold',
                    color: 'tertiary',
                  },
                  label: 'Qualified',
                  value: 24,
                },
                {
                  icon: {
                    name: 'fluent:book-information-24-filled',
                    color: 'success',
                  },
                  label: 'IQL',
                  value: 14,
                },
                {
                  icon: {
                    name: 'hugeicons:marketing',
                    color: 'secondary',
                  },
                  label: 'MQL',
                  value: 12,
                },
                {
                  icon: {
                    name: 'solar:sale-square-bold',
                    color: 'primary',
                  },
                  label: 'SQL',
                  value: 124,
                },
              ],
            },
            {
              tabLabel: 'TargetType',
              tabData: [
                {
                  icon: {
                    name: 'solar:user-minus-bold',
                    color: 'info',
                  },
                  label: 'Qualified',
                  value: 24,
                },
                {
                  icon: {
                    name: 'fluent:book-information-24-filled',
                    color: 'success',
                  },
                  label: 'IQL',
                  value: 14,
                },
                {
                  icon: {
                    name: 'hugeicons:marketing',
                    color: 'secondary',
                  },
                  label: 'MQL',
                  value: 12,
                },
                {
                  icon: {
                    name: 'solar:sale-square-bold',
                    color: 'primary',
                  },
                  label: 'SQL',
                  value: 124,
                },
              ],
            },
          ]}
        />

        <CustomAreaChartV2
          title="$25.825"
          subtitle="Total"
          theme="secondary"
          flexBasis={'33%'}
          data={[
            {
              name: 'Page A',
              uv: 4000,
              pv: 2400,
              amt: 2400,
            },
            {
              name: 'Page B',
              uv: 3000,
              pv: 1398,
              amt: 2210,
            },
            {
              name: 'Page C',
              uv: 2000,
              pv: 9800,
              amt: 2290,
            },
            {
              name: 'Page D',
              uv: 2780,
              pv: 3908,
              amt: 2000,
            },
            {
              name: 'Page E',
              uv: 1890,
              pv: 4800,
              amt: 2181,
            },
            {
              name: 'Page F',
              uv: 2390,
              pv: 3800,
              amt: 2500,
            },
            {
              name: 'Page G',
              uv: 3490,
              pv: 4300,
              amt: 2100,
            },
          ]}
        />

        <CustomBarChartV4
          title="Total Invoices"
          theme="warning"
          flexBasis={'33%'}
          data={[
            {
              name: 'Mo',
              value1Format: {
                count: 125,
                prefix: '$',
              },
              value2Format: {
                count: 15,
                prefix: '^',
                suffix: '%',
              },
            },
            {
              name: 'Tu',
              value1Format: {
                count: 15,
                prefix: '$',
              },
              value2Format: {
                count: 15,
                prefix: '^',
                suffix: '%',
              },
            },
            {
              name: 'We',
              value1Format: {
                count: 315,
                prefix: '$',
              },
              value2Format: {
                count: 15,
                prefix: '^',
                suffix: '%',
              },
            },
            {
              name: 'Th',
              value1Format: {
                count: 215,
                prefix: '$',
              },
              value2Format: {
                count: 15,
                prefix: '^',
                suffix: '%',
              },
            },
            {
              name: 'Fr',
              value1Format: {
                count: 125,
                prefix: '$',
              },
              value2Format: {
                count: 15,
                prefix: '^',
                suffix: '%',
              },
            },
            {
              name: 'Sa',
              value1Format: {
                count: 1544,
                prefix: '$',
              },
              value2Format: {
                count: 67,
                prefix: '^',
                suffix: '%',
              },
            },
            {
              name: 'Su',
              value1Format: {
                count: 56,
                prefix: '$',
              },
              value2Format: {
                count: 23,
                prefix: '^',
                suffix: '%',
              },
            },
          ]}
          topToolbarData={{
            count: 124,
            valueFormat: {
              count: 25.8,
              prefix: '^',
              suffix: '%',
            },
          }}
          bottomToolbarData={{
            title: 'Invoices Status',
            valueFormat: [
              {
                icon: 'nimbus:money',
                label: 'Total Revenue',
                count: 24,
              },
              {
                icon: 'nimbus:money',
                label: 'Total Sales',
                count: 12,
              },
              {
                icon: 'nimbus:money',
                label: 'Total Revenue',
                count: 54,
              },
            ],
          }}
        />
      </Stack> */}

      {/* <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'}>
        <CustomDonutChartV2
          title="Total Payments"
          colors={['#24B364', '#53D28C', '#7EDDA9', '#A9E9C5']}
          theme="success"
          flexBasis={'33%'}
          data={[
            {
              name: 'A',
              value1: 4.325,
              value2Format: {
                count: 15.2,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'B',
              value1: 2.345,
              value2Format: {
                count: 58.2,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'C',
              value1: 1.243,
              value2Format: {
                count: 115.2,
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: 'D',
              value1: 3.654,
              value2Format: {
                count: 1.2,
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
        />

        <CustomLineItem
          title="Total Returns"
          subtitle2="Returns Status"
          value1={124}
          valueFormat={{
            count: 25.8,
            prefix: '^',
            suffix: '%',
          }}
          data={[
            {
              status: 'Total Revenue',
              color: 'secondary',
              value1: 24,
              value2: {
                prefix: '-',
                count: 4.7,
                suffix: '%',
              },
            },
            {
              status: 'Total Sales',
              color: 'success',
              value1: 24,
              value2: {
                prefix: '+',
                count: 2.4,
                suffix: '%',
              },
            },
            {
              status: 'Total Revenue',
              color: 'info',
              value1: 24,
              value2: {
                prefix: '-',
                count: 1.2,
                suffix: '%',
              },
            },
            {
              status: 'Total Sales',
              color: 'success',
              value1: 24,
              value2: {
                prefix: '+',
                count: 2.4,
                suffix: '%',
              },
            },
            {
              status: 'Total Revenue',
              color: 'secondary',
              value1: 24,
              value2: {
                prefix: '-',
                count: 4.7,
                suffix: '%',
              },
            },
            {
              status: 'Total Revenue',
              color: 'info',
              value1: 24,
              value2: {
                prefix: '-',
                count: 1.2,
                suffix: '%',
              },
            },
          ]}
          flexBasis="33%"
        />

        <CustomLineItem
          title="Total Products"
          subtitle1="Weekly Report"
          subtitle2="Products by Category"
          value1={124}
          valueFormat={{
            count: 25.8,
            prefix: '^',
            suffix: '%',
          }}
          data={[
            {
              status: 'Total Revenue',
              color: 'secondary',
              icon: 'nimbus:money',
              value1: 24,
              value2: {
                prefix: '-',
                count: 4.7,
                suffix: '%',
              },
            },
            {
              status: 'Total Sales',
              color: 'success',
              icon: 'nimbus:money',
              value1: 24,
              value2: {
                prefix: '+',
                count: 2.4,
                suffix: '%',
              },
            },
            {
              status: 'Total Revenue',
              color: 'info',
              icon: 'nimbus:money',
              value1: 24,
              value2: {
                prefix: '-',
                count: 1.2,
                suffix: '%',
              },
            },
            {
              status: 'Total Sales',
              color: 'success',
              icon: 'nimbus:money',
              value1: 24,
              value2: {
                prefix: '+',
                count: 2.4,
                suffix: '%',
              },
            },
            {
              status: 'Total Revenue',
              color: 'secondary',
              icon: 'nimbus:money',
              value1: 24,
              value2: {
                prefix: '-',
                count: 4.7,
                suffix: '%',
              },
            },
            {
              status: 'Total Revenue',
              color: 'info',
              icon: 'nimbus:money',
              value1: 24,
              value2: {
                prefix: '-',
                count: 1.2,
                suffix: '%',
              },
            },
          ]}
          flexBasis="33%"
        />
      </Stack> */}

      {/* <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'}>
        <CustomProgressChart
          title="Services by Category"
          subtitle2="Total Proposal"
          value1={468}
          valueFormat={{
            count: 4.2,
            prefix: '+',
            suffix: '%',
          }}
          data={[
            {
              label: 'Lorem Ipsum',
              color: 'secondary',
              value: {
                count: 85,
                suffix: '%',
              },
            },
            {
              label: 'Lorem Ipsum',
              color: 'tertiary',
              value: {
                count: 86,
                suffix: '%',
              },
            },
            {
              label: 'Lorem Ipsum',
              color: 'success',
              value: {
                count: 90,
                suffix: '%',
              },
            },
            {
              label: 'Lorem Ipsum',
              color: 'success',
              value: {
                count: 90,
                suffix: '%',
              },
            },
          ]}
          flexBasis="33%"
        />

        <CustomVerticalChart
          title="Ticket Statistics"
          flexBasis="66%"
          data={[
            {
              name: 'Open Tickets',
              color: 'tertiary',
              value: 35,
            },
            {
              name: 'In Progress',
              color: 'info',
              value: 20,
            },
            {
              name: 'Answered',
              color: 'success',
              value: 14,
            },
            {
              name: 'On Hold',
              color: 'primary',
              value: 12,
            },
            {
              name: 'Closed',
              color: 'error',
              value: 10,
            },
          ]}
        />
      </Stack> */}

      {/* <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'} sx={{ pb: 3.5 }}>
        <CustomAreaChart
          title="Tasks"
          subtitle="Daily History"
          theme="warning"
          flexBasis={'100%'}
          data={[
            {
              name: 'Page A',
              uv: 4000,
              pv: 2400,
              amt: 2400,
            },
            {
              name: 'Page B',
              uv: 3000,
              pv: 1398,
              amt: 2210,
            },
            {
              name: 'Page C',
              uv: 2000,
              pv: 9800,
              amt: 2290,
            },
            {
              name: 'Page D',
              uv: 2780,
              pv: 3908,
              amt: 2000,
            },
            {
              name: 'Page E',
              uv: 1890,
              pv: 4800,
              amt: 2181,
            },
            {
              name: 'Page F',
              uv: 2390,
              pv: 3800,
              amt: 2500,
            },
            {
              name: 'Page G',
              uv: 3490,
              pv: 4300,
              amt: 2100,
            },
          ]}
        />
      </Stack> */}

      {/* <Stack direction={downMd ? 'column' : 'row'} gap={3} mb={1} width={'100%'} sx={{ pb: 3.5 }}>
        <CustomTabChartV2
          title="Tasks"
          subtitle="Total Statistics"
          value1={468}
          value2={{
            prefix: '+',
            count: 4.2,
            suffix: '%',
          }}
          flexBasis={'100%'}
          theme={'secondary'}
          data={[
            {
              tabLabel: 'Status',
              tabData: [
                {
                  name: 'C',
                  value1: 124,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'N',
                  value1: 24,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'U',
                  value1: 14,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'C',
                  value1: 110,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'Q',
                  value1: 124,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'Q',
                  value1: 124,
                  value2Format: {
                    count: 67,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'N',
                  value1: 90,
                  value2Format: {
                    count: 23,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'L',
                  value1: 70,
                  value2Format: {
                    count: 25,
                    prefix: '+',
                    suffix: '%',
                  },
                },
              ],
            },
            {
              tabLabel: 'Priority',
              tabData: [
                {
                  name: 'C',
                  value1: 124,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'N',
                  value1: 24,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'U',
                  value1: 14,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'C',
                  value1: 110,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'Q',
                  value1: 124,
                  value2Format: {
                    count: 15,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'Q',
                  value1: 124,
                  value2Format: {
                    count: 67,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'N',
                  value1: 90,
                  value2Format: {
                    count: 23,
                    prefix: '+',
                    suffix: '%',
                  },
                },
                {
                  name: 'L',
                  value1: 70,
                  value2Format: {
                    count: 25,
                    prefix: '+',
                    suffix: '%',
                  },
                },
              ],
            },
          ]}
        />
      </Stack> */}

      {/* <Stack direction={'column'} gap={2} sx={{ width: '100%' }}>
        <TopLine />
        <HeaderSection />
        <RevenueSection />
        <RevenueBottomLine />
        <BodySection />
      </Stack> */}

      {/* <CustomTabChart
          title="Qualification&Source"
          subtitle="Total "
          flexBasis={'33%'}
          data={[
            {
              tabLabel: 'Qualification',
              tabData: [
                {
                  icon: {
                    name: 'solar:user-minus-bold',
                    color: 'tertiary',
                  },
                  label: 'Qualified',
                  value: 24,
                },
                {
                  icon: {
                    name: 'fluent:book-information-24-filled',
                    color: 'success',
                  },
                  label: 'IQL',
                  value: 14,
                },
                {
                  icon: {
                    name: 'hugeicons:marketing',
                    color: 'secondary',
                  },
                  label: 'MQL',
                  value: 12,
                },
                {
                  icon: {
                    name: 'solar:sale-square-bold',
                    color: 'primary',
                  },
                  label: 'SQL',
                  value: 124,
                },
                
              ],
            },
            {
              tabLabel: 'Source',
              tabData: [
                {
                  icon: {
                    name: 'solar:user-minus-bold',
                    color: 'info',
                  },
                  label: 'Qualified',
                  value: 24,
                },
                {
                  icon: {
                    name: 'fluent:book-information-24-filled',
                    color: 'success',
                  },
                  label: 'IQL',
                  value: 14,
                },
                {
                  icon: {
                    name: 'hugeicons:marketing',
                    color: 'secondary',
                  },
                  label: 'MQL',
                  value: 12,
                },
                {
                  icon: {
                    name: 'solar:sale-square-bold',
                    color: 'primary',
                  },
                  label: 'SQL',
                  value: 124,
                },
              ],
            },
          ]}
        /> */}
    </>
  );
});

export default HomeForActive;
