import { Stack, Box, Typography } from '@mui/material';
import { Iconify } from 'components';
import { AreaChart, Area, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { memo, useCallback } from 'react';

interface ICustomAreaChartItem {
  name: string;
  uv: number;
  pv: number;
  amt: number;
}

interface IBottomToolbarData {
  name: string;
  valueFormat: {
    count1?: number;
    count2?: number;
    prefix: string;
    suffix?: string;
  };
}

interface ICustomAreaChart {
  data: ICustomAreaChartItem[];
  title: string;
  subtitle: string;
  theme: string;
  flexBasis: string;
  bottomToolbarData?: IBottomToolbarData[];
  topRightCount?: string;
  icon?: string;
  count?: number;
}

const CustomAreaChart = (props: ICustomAreaChart) => {
  const theme = useTheme();
  const palette = theme.palette as Record<string, any>;
  const hexColor = palette[props.theme].main;

  const fallbackData = Array.from({ length: 7 }, (_, i) => ({
    name: (6 - i).toString(),
    uv: 1,
    pv: 1,
    amt: 1,
  }));

  const processedData =
    props.data && props.data.length > 0
      ? props.data.map((item) => ({
          ...item,
          uv: item.uv && item.uv > 0 ? item.uv : 1,
        }))
      : fallbackData;

  const renderBottomToolbarData = useCallback(() => {
    return props.bottomToolbarData?.map((item, index) => (
      <Stack direction={'row'} justifyContent={'space-between'} key={index}>
        <Typography variant="caption">{item.name}</Typography>

        <Stack direction={'row'} gap={1}>
          <Typography variant="caption">{item.valueFormat.count1}</Typography>

          <Typography
            variant="caption"
            sx={{ color: item.valueFormat.prefix === '+' ? 'success.main' : 'error.main' }}
          >
            {item.valueFormat.prefix}
            {item.valueFormat.count2}
          </Typography>
        </Stack>
      </Stack>
    ));
  }, [props.bottomToolbarData]);

  return (
    <Stack
      direction={'column'}
      gap={1}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={props.flexBasis}
      width={'100%'}
    >
      <Typography variant="h5" sx={{ fontWeight: 500 }}>
        {props.title}
      </Typography>

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
        <Stack direction={'row'} gap={1}>
          {props.icon && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 40,
                height: 40,
                borderRadius: '6px',
                backgroundColor: `${props.theme}.extraLight`,
              }}
            >
              <Iconify icon={props.icon} sx={{ color: `${props.theme}.main` }} width={24} />
            </Box>
          )}

          <Box>
            {props.count !== 0 && (
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                {props.count}
              </Typography>
            )}

            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {props.subtitle}
            </Typography>
          </Box>
        </Stack>

        {props.topRightCount && (
          <Typography variant="caption" sx={{ color: 'success.main' }}>
            {props.topRightCount}
          </Typography>
        )}
      </Stack>

      <ResponsiveContainer>
        <AreaChart data={processedData}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={hexColor} stopOpacity={0.8} />
              <stop offset="95%" stopColor={hexColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area type="monotone" dataKey="uv" stroke={hexColor} fill="url(#colorUv)" />
        </AreaChart>
      </ResponsiveContainer>

      {props.bottomToolbarData && props.bottomToolbarData.length > 0 && renderBottomToolbarData()}
    </Stack>
  );
};

export default memo(CustomAreaChart);
