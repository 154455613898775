import {
  fileListQuery,
  IFileListRequest,
  IFileListInput,
  IFileListResponse,
  IFileListResult,
  fileListOwnQuery,
  IFileListOwnRequest,
  IFileListOwnInput,
  IFileListOwnResponse,
  IFileListOwnResult,
  fileDetailQuery,
  IFileDetailRequest,
  IFileDetailInput,
  IFileDetailResponse,
  IFileDetailResult,
  fileFolderListQuery,
  IFileFolderListRequest,
  IFileFolderListInput,
  IFileFolderListResponse,
  IFileFolderListResult,
  fileDownloadQuery,
  IFileDownloadRequest,
  IFileDownloadInput,
  IFileDownloadResponse,
  IFileDownloadResult,
  leadDocumentCreateManyQuery,
  ILeadDocumentCreateManyRequest,
  ILeadDocumentCreateManyResponse,
  leadDocumentAddManyQuery,
  ILeadDocumentAddManyRequest,
  ILeadDocumentAddManyResponse,
  customerDocumentCreateManyQuery,
  ICustomerDocumentCreateManyRequest,
  ICustomerDocumentCreateManyResponse,
  invoiceDocumentCreateManyQuery,
  IInvoiceDocumentCreateManyRequest,
  IInvoiceDocumentCreateManyResponse,
  taskDocumentCreateManyQuery,
  ITaskDocumentCreateManyRequest,
  ITaskDocumentCreateManyResponse,
  customerDocumentAddManyQuery,
  ICustomerDocumentAddManyRequest,
  ICustomerDocumentAddManyResponse,
  invoiceDocumentAddManyQuery,
  IInvoiceDocumentAddManyRequest,
  IInvoiceDocumentAddManyResponse,
  taskDocumentAddManyQuery,
  ITaskDocumentAddManyRequest,
  ITaskDocumentAddManyResponse,
  userDocumentAddManyQuery,
  IUserDocumentAddManyRequest,
  IUserDocumentAddManyResponse,
  userDocumentCreateManyQuery,
  IUserDocumentCreateManyRequest,
  IUserDocumentCreateManyResponse,
  organizationDocumentAddManyQuery,
  IOrganizationDocumentAddManyRequest,
  IOrganizationDocumentAddManyResponse,
  organizationDocumentCreateManyQuery,
  IOrganizationDocumentCreateManyRequest,
  IOrganizationDocumentCreateManyResponse,
  estimateDocumentAddManyQuery,
  IEstimateDocumentAddManyRequest,
  IEstimateDocumentAddManyResponse,
  estimateDocumentCreateManyQuery,
  IEstimateDocumentCreateManyRequest,
  IEstimateDocumentCreateManyResponse,
  proposalDocumentAddManyQuery,
  IProposalDocumentAddManyRequest,
  IProposalDocumentAddManyResponse,
  proposalDocumentCreateManyQuery,
  IProposalDocumentCreateManyRequest,
  IProposalDocumentCreateManyResponse,
  projectDocumentAddManyQuery,
  IProjectDocumentAddManyRequest,
  IProjectDocumentAddManyResponse,
  projectDocumentCreateManyQuery,
  IProjectDocumentCreateManyRequest,
  IProjectDocumentCreateManyResponse,
  contractDocumentAddManyQuery,
  IContractDocumentAddManyRequest,
  IContractDocumentAddManyResponse,
  contractDocumentCreateManyQuery,
  IContractDocumentCreateManyRequest,
  IContractDocumentCreateManyResponse,
  expenseDocumentAddManyQuery,
  IExpenseDocumentAddManyRequest,
  IExpenseDocumentAddManyResponse,
  expenseDocumentCreateManyQuery,
  IExpenseDocumentCreateManyRequest,
  appointmentDocumentAddManyQuery,
  IAppointmentDocumentAddManyRequest,
  IAppointmentDocumentAddManyResponse,
  appointmentDocumentCreateManyQuery,
  IAppointmentDocumentCreateManyRequest,
  IAppointmentDocumentCreateManyResponse,
  ticketDocumentAddManyQuery,
  ITicketDocumentAddManyRequest,
  ITicketDocumentAddManyResponse,
  ticketDocumentCreateManyQuery,
  ITicketDocumentCreateManyRequest,
  ITicketDocumentCreateManyResponse,
  fileDeleteQuery,
  IFileDeleteRequest,
  IFileDeleteInput,
  IFileDeleteResponse,
  IFileDeleteResult,
  IExpenseDocumentCreateManyResponse,
} from 'corede-common-cocrm';
import {
  IDomainFilesCreateOwnInput,
  IDomainFilesCreateResult,
  IEntityFilesAssignInput,
  IEntityFilesAssignResult,
} from 'corede-common';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { IGraphqlVariables } from 'corede-common';
import { documentTags } from './document.tags';
export const documentApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    fileList: builder.query<IFileListResult, IGraphqlVariables<IFileListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFileListRequest,
        IFileListResponse,
        IFileListResult,
        IFileListInput
      >({
        query: fileListQuery,
        providesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    fileListOwn: builder.query<IFileListOwnResult, IGraphqlVariables<IFileListOwnInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFileListOwnRequest,
        IFileListOwnResponse,
        IFileListOwnResult,
        IFileListOwnInput
      >({
        query: fileListOwnQuery,
        providesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    fileDetail: builder.query<IFileDetailResult, IGraphqlVariables<IFileDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFileDetailRequest,
        IFileDetailResponse,
        IFileDetailResult,
        IFileDetailInput
      >({
        query: fileDetailQuery,
        providesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    fileFolderList: builder.query<IFileFolderListResult, IGraphqlVariables<IFileFolderListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFileFolderListRequest,
        IFileFolderListResponse,
        IFileFolderListResult,
        IFileFolderListInput
      >({
        query: fileFolderListQuery,
        providesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    fileDownload: builder.query<IFileDownloadResult, IGraphqlVariables<IFileDownloadInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFileDownloadRequest,
        IFileDownloadResponse,
        IFileDownloadResult,
        IFileDownloadInput
      >({
        query: fileDownloadQuery,
        providesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    // mutation

    leadDocumentCreateMany: builder.mutation<
      ILeadDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadDocumentCreateManyRequest,
        ILeadDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: leadDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    customerDocumentCreateMany: builder.mutation<
      ICustomerDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerDocumentCreateManyRequest,
        ICustomerDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: customerDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    invoiceDocumentCreateMany: builder.mutation<
      IInvoiceDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceDocumentCreateManyRequest,
        IInvoiceDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: invoiceDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    taskDocumentCreateMany: builder.mutation<
      ITaskDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskDocumentCreateManyRequest,
        ITaskDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: taskDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    userDocumentCreateMany: builder.mutation<
      IUserDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDocumentCreateManyRequest,
        IUserDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: userDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    organizationDocumentCreateMany: builder.mutation<
      IOrganizationDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationDocumentCreateManyRequest,
        IOrganizationDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: organizationDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    estimateDocumentCreateMany: builder.mutation<
      IEstimateDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateDocumentCreateManyRequest,
        IEstimateDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: estimateDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    proposalDocumentCreateMany: builder.mutation<
      IProposalDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalDocumentCreateManyRequest,
        IProposalDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: proposalDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    projectDocumentCreateMany: builder.mutation<
      IProjectDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectDocumentCreateManyRequest,
        IProjectDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: projectDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    expenseDocumentCreateMany: builder.mutation<
      IExpenseDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseDocumentCreateManyRequest,
        IExpenseDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: expenseDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    appointmentDocumentCreateMany: builder.mutation<
      IAppointmentDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentDocumentCreateManyRequest,
        IAppointmentDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: appointmentDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    contractDocumentCreateMany: builder.mutation<
      IContractDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractDocumentCreateManyRequest,
        IContractDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: contractDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    ticketDocumentCreateMany: builder.mutation<
      ITicketDocumentCreateManyResponse,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketDocumentCreateManyRequest,
        ITicketDocumentCreateManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: ticketDocumentCreateManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    leadDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadDocumentAddManyRequest,
        ILeadDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: leadDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    customerDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerDocumentAddManyRequest,
        ICustomerDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: customerDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    invoiceDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceDocumentAddManyRequest,
        IInvoiceDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: invoiceDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    taskDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskDocumentAddManyRequest,
        ITaskDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: taskDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    userDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDocumentAddManyRequest,
        IUserDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: userDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    organizationDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationDocumentAddManyRequest,
        IOrganizationDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: organizationDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    estimateDocumentAddMany: builder.mutation<
      IDomainFilesCreateResult,
      IGraphqlVariables<IDomainFilesCreateOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateDocumentAddManyRequest,
        IEstimateDocumentAddManyResponse,
        IDomainFilesCreateResult,
        IDomainFilesCreateOwnInput
      >({
        query: estimateDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    proposalDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalDocumentAddManyRequest,
        IProposalDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: proposalDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    projectDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectDocumentAddManyRequest,
        IProjectDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: projectDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    contractDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractDocumentAddManyRequest,
        IContractDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: contractDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    expenseDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseDocumentAddManyRequest,
        IExpenseDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: expenseDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    appointmentDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentDocumentAddManyRequest,
        IAppointmentDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: appointmentDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    ticketDocumentAddMany: builder.mutation<
      IEntityFilesAssignResult,
      IGraphqlVariables<IEntityFilesAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketDocumentAddManyRequest,
        ITicketDocumentAddManyResponse,
        IEntityFilesAssignResult,
        IEntityFilesAssignInput
      >({
        query: ticketDocumentAddManyQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),

    fileDelete: builder.mutation<IFileDeleteResult, IGraphqlVariables<IFileDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IFileDeleteRequest,
        IFileDeleteResponse,
        IFileDeleteResult,
        IFileDeleteInput
      >({
        query: fileDeleteQuery,
        invalidatesTags: [documentTags.document, documentTags.documents],
      }),
    ),
  }),
});

export const {
  useFileListQuery,
  useLazyFileListQuery,
  useFileListOwnQuery,
  useLazyFileListOwnQuery,
  useFileDetailQuery,
  useLazyFileDetailQuery,
  useFileFolderListQuery,
  useLazyFileFolderListQuery,
  useFileDownloadQuery,
  useLazyFileDownloadQuery,
  useLeadDocumentCreateManyMutation,
  useCustomerDocumentCreateManyMutation,
  useInvoiceDocumentCreateManyMutation,
  useTaskDocumentCreateManyMutation,
  useUserDocumentCreateManyMutation,
  useOrganizationDocumentCreateManyMutation,
  useEstimateDocumentCreateManyMutation,
  useProposalDocumentCreateManyMutation,
  useProjectDocumentCreateManyMutation,
  useContractDocumentCreateManyMutation,
  useExpenseDocumentCreateManyMutation,
  useAppointmentDocumentCreateManyMutation,
  useTicketDocumentCreateManyMutation,
  useOrganizationDocumentAddManyMutation,
  useInvoiceDocumentAddManyMutation,
  useLeadDocumentAddManyMutation,
  useCustomerDocumentAddManyMutation,
  useTaskDocumentAddManyMutation,
  useUserDocumentAddManyMutation,
  useEstimateDocumentAddManyMutation,
  useProposalDocumentAddManyMutation,
  useProjectDocumentAddManyMutation,
  useContractDocumentAddManyMutation,
  useExpenseDocumentAddManyMutation,
  useAppointmentDocumentAddManyMutation,
  useTicketDocumentAddManyMutation,
  useFileDeleteMutation,
} = documentApi;
