const locale = {
  permissions: 'İzinler',
  errorFetchingPermissions: 'İzinler getirilirken hata oluştu',
  allPermissions: 'Tüm İzinler',
  all: 'Tümü',
  ownPermissionsAlert: 'Kendi izinlerinizi değiştiremezsiniz.',
  action: 'Eylem',
  actionScope: 'Eylem Kapsamı',
  updateSuccess: 'İzin başarıyla güncellendi',
};

export default locale;
