/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Grid, Grow, Stack } from '@mui/material';
import { MyTab, SettingsTitle } from 'components';
import { useEffect, useState } from 'react';
import {
  useOrganizationDetailOwnQuery,
  useOrganizationSettingsUpdateOwnMutation,
} from '../../context/setting.api';
import { enqueueSnackbar } from 'notistack';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useSearchParams } from 'react-router-dom';
import EmptyState from 'components/emptyState/EmptyState';
import { useTranslation } from 'react-i18next';
import { CrmLeadTab } from './crm.lead';
import { CrmCustomerTab } from './crm.customer';
import { CrmEstimateTab } from './crm.estimate';
import { CrmCalendarTab } from './crm.calendar';
import { CrmFileManagementTab } from './crm.fileManagement';
import { CrmInvoiceTab } from './crm.invoice';
import { CrmNotificationTab } from './crm.notification';
import { CrmProjectTab } from './crm.project';
import { CrmTicketTab } from './crm.ticket';
import { CrmTaskTab } from './crm.task';
import { CrmProposalTab } from './crm.proposal';

const Crm = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [selectedTabName, setSelectedTabName] = useState('');
  const [tabLabels, setTabLabels] = useState(['']);
  const {
    data: organizationDetailOwnData,
    isLoading: organizationDetailOwnLoading,
    error: organizationDetailOwnError,
  } = useOrganizationDetailOwnQuery({});
  const [
    organizationSettingsUpdateOwn,
    {
      data: organizationSettingsUpdateOwnData,
      isLoading: organizationSettingsUpdateOwnLoading,
      error: organizationSettingsUpdateOwnError,
    },
  ] = useOrganizationSettingsUpdateOwnMutation();
  const status = organizationDetailOwnData?.status;

  //Common

  useEffect(() => {
    if (organizationSettingsUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
    }
  }, [organizationSettingsUpdateOwnData]);

  useEffect(() => {
    switch (status) {
      case 'restricted':
        setTabLabels(['settings.settings.settings.crm']);
        break;
      case 'active': 
        setTabLabels([
          // 'settings.settings.settings.lead',
          // 'settings.settings.settings.customer',
          'settings.settings.settings.estimate',
          'settings.settings.settings.proposal',
          'settings.settings.settings.invoice',
          // 'settings.settings.settings.project',
          // 'settings.settings.settings.task',
          // 'settings.settings.settings.ticket',
          'settings.settings.settings.fileManagement',
          // 'settings.settings.settings.calendar',
          // 'settings.settings.settings.notification',
        ]);
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (tabLabels) {
      switch (tab) {
        case 'estimate':
          setSelectedTabName(t('settings.settings.settings.estimate'));
          break;
        default:
          setSelectedTabName(tabLabels[0]);
          break;
      }
    }
  }, [tabLabels, tab]);

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={3}>
      <Stack
        direction={'row'}
        sx={{
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        <MyTab
          type="settings"
          labels={tabLabels}
          setSelectedTab={setSelectedTabName}
          selectedTab={selectedTabName}
        />
      </Stack>

      {/* {selectedTabName === 'settings.settings.settings.lead' && (
        <CrmLeadTab selectedTabName={selectedTabName} />
      )} */}
      {/* {selectedTabName === 'settings.settings.settings.customer' && (
        <CrmCustomerTab selectedTabName={selectedTabName} />
      )} */}
      {selectedTabName === 'settings.settings.settings.estimate' && (
        <CrmEstimateTab selectedTabName={selectedTabName} />
      )}
      {selectedTabName === 'settings.settings.settings.proposal' && (
        <CrmProposalTab selectedTabName={selectedTabName} />
      )}
      {selectedTabName === 'settings.settings.settings.invoice' && (
        <CrmInvoiceTab selectedTabName={selectedTabName} />
      )}
      {/* {selectedTabName === 'settings.settings.settings.project' && (
        <CrmProjectTab selectedTabName={selectedTabName} />
      )} */}
      {/* {selectedTabName === 'settings.settings.settings.task' && (
        <CrmTaskTab selectedTabName={selectedTabName} />
      )} */}
      {/* {selectedTabName === 'settings.settings.settings.ticket' && (
        <CrmTicketTab selectedTabName={selectedTabName} /> 
      )}*/}
      {selectedTabName === 'settings.settings.settings.fileManagement' && (
        <CrmFileManagementTab selectedTabName={selectedTabName} />
      )}
      {/* {selectedTabName === 'settings.settings.settings.calendar' && (
        <CrmCalendarTab selectedTabName={selectedTabName} />
      )} */}
      {/* {selectedTabName === 'settings.settings.settings.notification' && (
        <CrmNotificationTab selectedTabName={selectedTabName} />
      )} */}
      {selectedTabName === 'settings.settings.settings.crm' && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.crm')} />
          <Grid item xs={12}>
            <Grow in={selectedTabName === 'settings.settings.settings.crm'} timeout={500}>
              <Card
                sx={{
                  p: 2,
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                }}
              >
                <Grid container>
                  <Grid item xs={12} mb={2}>
                    <EmptyState
                      content1={t('settings.settings.settings.noCrmContent1')}
                      content2={t('settings.settings.settings.noCrmContent2')}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default Crm;
