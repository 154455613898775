import { IMenuItem } from 'interfaces/menu-item.interface';
import { useTranslation } from 'react-i18next';
import { baseContractRoute, listContractRoute, listContractTemplate } from '../routes/contract.base.route';

import { PermissionSubdomain } from 'corede-common-cocrm';

export const useContractDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    title: t('contract'),
    path: baseContractRoute(),
    children: [
      {
        title: t('crm.contractLabel'),
        path: listContractRoute(),
        subdomain: PermissionSubdomain.contract,
      },
      {
        title: t('crm.contractTemplate'),
        path: listContractTemplate(),
        subdomain: PermissionSubdomain.contractTemplate,
      },
    ],
  };
};
