/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { Dispatch, memo, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { IGraphqlVariables } from 'corede-common';
import 'react-quill/dist/quill.snow.css';
import { UseFormReturn } from 'react-hook-form';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import { DefaultDateTimePickerInput } from 'components/form/DefaultDateTimePickerInput';

export interface IUpsertMilestoneComponentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  useForm: UseFormReturn<IGraphqlVariables<any, any>>;
}

export const UpsertMilestoneComponent = memo((props: IUpsertMilestoneComponentProps) => {
  // general
  const { t } = useTranslation();
  // queries

  // useEffects.success

  // useEffects.error

  // useEffects.init

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <DefaultTextFieldInput
          control={props.useForm.control}
          inputName="input.name"
          label={t('crm.name')}
          isRequired
          errors={props.useForm.formState.errors}
          isFirstLetterCapitalize
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultDateTimePickerInput
          control={props.useForm.control}
          inputName="input.startDate"
          label={t('crm.startDate')}
          isRequired
          errors={props.useForm.formState.errors}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultDateTimePickerInput
          control={props.useForm.control}
          inputName="input.dueDate"
          label={t('crm.dueDate')}
          isRequired
          errors={props.useForm.formState.errors}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultRichTextQuillInput
          inputName="input.description"
          control={props.useForm.control}
          label={t('crm.description')}
          placeholder={t('crm.description')}
          errors={props.useForm.formState.errors}
        />
      </Grid>
    </Grid>
  );
});
