import { useState, lazy, Suspense } from 'react';

const ProjectUpdateDrawer = lazy(() => import('./ProjectUpdateDrawer'));

const ProjectUpdateDrawerWrapper = ({
  open,
  setOpen,
  selectedProject,
}: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ProjectUpdateDrawer
            open={open}
            setOpen={setOpen}
            selectedProject={selectedProject}
          />
        </Suspense>
      )}
    </>
  );
};

export default ProjectUpdateDrawerWrapper;
