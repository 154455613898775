export const EntityIcons = {
  dashboard: 'mage:dashboard-2',
  organizationalChart: 'solar:chart-broken',
  lead: 'material-symbols-light:social-leaderboard-outline-rounded',
  customer: 'solar:user-linear',
  preSale: 'mage:preview',
  sales: 'solar:sale-broken',
  contract: 'teenyicons:contract-outline',
  productsAndServices: 'fluent-mdl2:product-catalog',
  expenses: 'icon-park-outline:expenses',
  projects: 'streamline:projector-board',
  tasks: 'carbon:product',
  calendar: 'solar:calendar-linear',
  reports: 'mingcute:report-fill',
  notes: 'hugeicons:task-01',
  ticket: 'f7:tickets',
};
