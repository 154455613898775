import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';

export interface IInvoiceDetailDrawerLeftPanelProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDetailDrawerLeftPanelContactDetailComponent = (
  props: IInvoiceDetailDrawerLeftPanelProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      {/* Genel Bilgiler */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
        {!downLg && (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.sale.invoice.generalInfoTitle')}
            </Typography>
            <Divider />
          </Stack>
        )}

        {/* Invoice ID */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.sale.invoice.invoiceId')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.invoiceDetailData?.invoiceId || '-'}
          </Typography>
        </Stack>

        {/* Status */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.status')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {getTranslatedEnumValue(props.invoiceDetailData?.status || '')}
          </Typography>
        </Stack>

        {/* Created At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {moment(props.invoiceDetailData?.createdAt).format('DD/MM/YYYY') || '-'}
          </Typography>
        </Stack>
      </Stack>

      {/* Müşteri Bilgileri */}
      <Stack direction={{ xs: 'row', lg: 'column' }} width={'100%'} gap={2} mt={4}>
        <Stack direction={'column'} width={'100%'} gap={0.5}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'text.primary',
            }}
          >
            {t('crm.sale.invoice.customerInfoTitle')}
          </Typography>
          <Divider />
        </Stack>

        {/* Customer Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.customerName')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.invoiceDetailData?.customerName || '-'}
          </Typography>
        </Stack>

        {/* Contact Email */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.contactEmail')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.invoiceDetailData?.customerContactEmail || '-'}
          </Typography>
        </Stack>

        {/* Contact Phone */}
        {/* <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.sale.invoice.contactPhoneNumber')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.invoiceDetailData?.customer || '-'}
          </Typography>
        </Stack> */}

        {/* Customer Address */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.address')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {[
              props.invoiceDetailData?.customerCountry,
              props.invoiceDetailData?.customerState,
              props.invoiceDetailData?.customerCity,
            ]
              .filter(Boolean)
              .join(', ') || '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
