/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { IProjectDetailResult } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { AddCircle } from '@mui/icons-material';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { OverlayType } from 'components/dialog/ActionDialog';
import EmptyState from 'components/emptyState/EmptyState';
import { representHtmlAsString, representDateTimeAsString } from 'utils/representationHelper';
import { useEffect, useState } from 'react';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import {
  useProjectTimeSheetDeleteMutation,
  useProjectTimeSheetUpdateMutation,
} from '../../context/project.api';
import TimeSheetCreateOverlay from './create/TimeSheetCreateOverlay';
import { MemberInfoCellComponent } from './TimeSheetSection-memberInfo.component';
import { TaskInfoCellComponent } from './TimeSheetSectiont-taskInfo.component';
import TimeSheetUpdateOverlay from './update/TimeSheetUpdateOverlay';
import { TimeSheetDeleteDialog } from './TimeSheetDeleteDialog';

export interface TimeSheetSectionProps extends DefaultTabComponentProps<IProjectDetailResult> {
  projectDetailData: IProjectDetailResult | undefined;
  tabKey: 'timeSheet';
  name: 'crm.project.project.timeSheet';
}

export const TimeSheetSection = (props: TimeSheetSectionProps) => {
  const { t } = useTranslation();
  const [openTimeSheetCreateDialog, setOpenTimeSheetCreateDialog] = useState(false);
  const [openTimeSheetUpdateDialog, setOpenTimeSheetUpdateDialog] = useState(false);
  const [openTimeSheetDeleteDialog, setOpenTimeSheetDeleteDialog] = useState(false);
  const [selectedTimeSheet, setSelectedTimeSheet] = useState<any>();
  const [projectHasNoAssignee, setProjectHasNoAssignee] = useState(false);

  const timeSheetData = props.projectDetailData?.timeSheets;

  useEffect(() => {
    const projectHasNoAssignee =
      !props.projectDetailData ||
      !props.projectDetailData.members ||
      props.projectDetailData.members.length === 0;
    setProjectHasNoAssignee(projectHasNoAssignee);
  }, [props.projectDetailData]);

  const [
    timeSheetUpdate,
    { data: timeSheetUpdateData, isLoading: timeSheetUpdateLoading, error: timeSheetUpdateError },
  ] = useProjectTimeSheetUpdateMutation();

  const [timeSheetDelete, { data: timeSheetDeleteData, isLoading: timeSheetDeleteLoading }] =
    useProjectTimeSheetDeleteMutation();

  return (
    <DefaultTabContainer
      title={''}
      rightButton={
        timeSheetData && timeSheetData?.length > 0 && !projectHasNoAssignee
          ? {
              title: t('crm.project.project.addTimeSheet'),
              onClick: () => setOpenTimeSheetCreateDialog(true),
              icon: <AddCircle sx={{ mr: 1 }} />,
            }
          : undefined
      }
    >
      {timeSheetData && projectHasNoAssignee && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no assignee yet."
            />
          }
          content1={t('crm.project.project.noAssigneeToCreateTimeSheet')}
        />
      )}

      {timeSheetData && !projectHasNoAssignee && timeSheetData?.length === 0 && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no task yet."
            />
          }
          content1={t('crm.project.project.emptyStateTimeSheetList1')}
          button1={{
            leftIcon: <AddCircle sx={{ mr: 1 }} fontSize="small" />,
            title: t('crm.project.project.addTimeSheet'),
            onClick: () => setOpenTimeSheetCreateDialog(true),
          }}
        />
      )}

      {timeSheetData && !projectHasNoAssignee && timeSheetData?.length > 0 && (
        <BaseDataGrid
          rows={timeSheetData?.map((timeSheet, index) => ({
            id: index + 1,
            _id: timeSheet?._id,
            memberInfo: props.projectDetailData?.members.find(
              (member) => member._id === timeSheet?.member?._id,
            ),
            taskInfo: props.projectDetailData?.taskList?.find(
              (task) => task.task?._id === timeSheet?.task?._id,
            ), //TODO: taskInfo
            startTime: representDateTimeAsString(timeSheet?.startTime),
            endTime: representDateTimeAsString(timeSheet?.endTime),
            duration: timeSheet?.duration,
            note: representHtmlAsString({ data: timeSheet?.note, trimLength: 150 }),
            timeSheet: timeSheet,
          }))}
          loading={timeSheetUpdateLoading || timeSheetDeleteLoading}
          columns={[
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'id',
                  disableColumnMenu: true,
                  filterable: false,
                  width: 40,
                  align: 'center',
                },
                headerConfig: {
                  name: '#',
                  align: 'center',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.any,
                column: {
                  field: 'memberInfo',
                  width: 180,
                  sortable: false,
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: `${t('crm.project.project.member')}`,
                },
                overrideCell: MemberInfoCellComponent(),
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.any,
                column: {
                  field: 'taskInfo',
                  width: 180,
                  sortable: false,
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: `${t('crm.taskLabel')}`,
                },
                overrideCell: TaskInfoCellComponent(),
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'startTime',
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.project.project.startTime'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'endTime',
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.project.project.endTime'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'duration',
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.project.project.duration'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'note',
                  editable: false,
                  filterable: false,
                  width: 200,
                },
                headerConfig: {
                  name: t('crm.noteLabel'),
                },
              },
            },
          ]}
          actionColumn={{
            width: 70,
            defaultActions: {
              edit: {
                clickConfig: {
                  setSelectedRow: setSelectedTimeSheet,
                  setOpenAction: setOpenTimeSheetUpdateDialog,
                },
              },
              delete: {
                clickConfig: {
                  setSelectedRow: setSelectedTimeSheet,
                  setOpenAction: setOpenTimeSheetDeleteDialog,
                },
              },
            },
            actionHeaderName: t('crm.actions'),
          }}
          listFilter={{
            filterInput: {},
            setFilterInput: () => null,
          }}
          count={timeSheetData?.length}
          config={{
            columnVisibilityModel: {},
            features: [],
          }}
          update={{
            updateQuery: timeSheetUpdate,
          }}
          disableColumnFilter={true}
          hideFooterPagination={true}
        />
      )}

      <TimeSheetCreateOverlay
        open={openTimeSheetCreateDialog}
        setOpen={setOpenTimeSheetCreateDialog}
        overlayType={OverlayType.dialog}
        projectId={props.projectDetailData?._id ?? ''}
      />

      <TimeSheetUpdateOverlay
        open={openTimeSheetUpdateDialog}
        setOpen={setOpenTimeSheetUpdateDialog}
        selectedTimeSheet={selectedTimeSheet}
        projectId={props.projectDetailData?._id ?? ''}
        overlayType={OverlayType.dialog}
      />

      <TimeSheetDeleteDialog
        open={openTimeSheetDeleteDialog}
        onClose={() => {
          setSelectedTimeSheet(undefined);
          setOpenTimeSheetDeleteDialog(false);
        }}
        projectId={props.projectDetailData?._id ?? ''}
        timeSheetId={selectedTimeSheet?._id ?? ''}
        setTimeSheet={setSelectedTimeSheet}
      />
    </DefaultTabContainer>
  );
};
