import { Stack, useMediaQuery } from '@mui/material';
import CustomAreaChart from '../../components/CustomAreaChart';
import CustomBarChart from '../../components/CustomBarChart';
import CustomDonutChart from '../../components/CustomDonutChart';
import { useTranslation } from 'react-i18next';
import { useCustomerStatisticsQuery } from 'apps/crm/domains/04-customer/subdomains/customer';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { CustomerType } from 'corede-common-cocrm';
import { memo, useCallback } from 'react';

const CustomerStatistics = memo((props: {}) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const { data: customerStatisticsData } = useCustomerStatisticsQuery({
    input: {
      filter: {
        numberOfPastDays: 7,
      },
    },
  });

  const dailyCreatedCounts = customerStatisticsData?.dailyHistory?.dailyCreatedCounts ?? [];
  const totalDailyCreatedCounts = dailyCreatedCounts.reduce((a, b) => a + b, 0);
  const dailyTotalCounts = customerStatisticsData?.dailyHistory?.dailyTotalCounts ?? [];

  const individualCount = customerStatisticsData?.countByCustomerType?.countByIndividual ?? 0;
  const businessCount = customerStatisticsData?.countByCustomerType?.countByBusiness ?? 0;
  const governmentCount = customerStatisticsData?.countByCustomerType?.countByGovernment ?? 0;

  const countByCategories = customerStatisticsData?.countByCustomerCategory ?? [];
  const totalCountByCategories = countByCategories.reduce((a, b) => a + (b?.customerCount ?? 0), 0);

  const getBarChartData = useCallback(() => [
    {
      name: getTranslatedEnumValue(CustomerType.individual),
      value1: individualCount ?? 0,
      value2Format: {
        count: parseFloat(
          (
            (individualCount ?? 0 / (individualCount + businessCount + governmentCount)) *
            100
          ).toFixed(1),
        ),
        prefix: '+',
        suffix: '%',
      },
    },
    {
      name: getTranslatedEnumValue(CustomerType.business),
      value1: businessCount ?? 0,
      value2Format: {
        count: parseFloat(
          (
            (businessCount / (individualCount + businessCount + governmentCount)) *
            100
          ).toFixed(1),
        ),
        prefix: '+',
        suffix: '%',
      },
    },
    {
      name: getTranslatedEnumValue(CustomerType.government),
      value1: governmentCount ?? 0,
      value2Format: {
        count: parseFloat(
          (
            (governmentCount / (individualCount + businessCount + governmentCount)) *
            100
          ).toFixed(1),
        ),
        prefix: '+',
        suffix: '%',
      },
    },
  ], [individualCount, businessCount, governmentCount, getTranslatedEnumValue, CustomerType]);

  const getDonutChartData = useCallback(() => 
    countByCategories.map((item) => ({
      name: item.category?.name ?? t("noCategory"),
      value1: item?.customerCount ?? 0,
      value2Format: {
        count: parseFloat(
          (((item?.customerCount ?? 0) / totalCountByCategories) * 100).toFixed(2),
        ),
        prefix: '+',
        suffix: '%',
      },
    })) ?? []
  , [countByCategories, totalCountByCategories]);

  const getAreaChartData = useCallback(() => 
    dailyTotalCounts
      ?.map((item, index) => ({
        name: index.toString(),
        uv: item ?? 0,
        pv: 3000,
        amt: 3000,
      }))
      .reverse() ?? []
  , [dailyTotalCounts]);

  const getBottomToolbarData = useCallback(() => [
    {
      name: t('crm.dashboard.dashboard.today'),
      valueFormat: {
        count1: dailyTotalCounts?.[0] ?? 0,
        count2: dailyCreatedCounts?.[0] ?? undefined,
        prefix: dailyCreatedCounts?.[0] ? '+' : '',
      },
    },
    {
      name: t('crm.dashboard.dashboard.yesterday'),
      valueFormat: {
        count1: dailyTotalCounts?.[1] ?? 0,
        count2: dailyCreatedCounts?.[1] ?? undefined,
        prefix: dailyCreatedCounts?.[1] ? '+' : '',
      },
    },
  ], [t, dailyTotalCounts, dailyCreatedCounts]);

  console.log(getBarChartData(), 'getBarChartData()');
  return (
    <Stack direction={downMd ? 'column' : 'row'} gap={2} mb={1} width={'100%'}>
      <Stack flexBasis={'33%'} width={'100%'} gap={2}>
        <CustomBarChart
          title={t('crm.dashboard.dashboard.customerFilterType')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          theme="secondary"
          flexBasis={'33%'}
          data={getBarChartData()}
          language={currentLanguage}
        />
        <CustomDonutChart
          title={t('crm.dashboard.dashboard.customerFilterCategory')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          colors={[
            '#00BAD1',
            '#00BAD1CC',
            '#00BAD199',
            '#00BAD166',
            '#00BAD133',
            '#00BAD1',
            '#00BAD1',
          ]}
          language={currentLanguage}
          theme="info"
          flexBasis={'33%'}
          totalValue={totalCountByCategories}
          data={getDonutChartData()}
        />
      </Stack>
      <CustomAreaChart
        title={t('crm.dashboard.dashboard.totalCustomer')}
        subtitle={t('crm.dashboard.dashboard.totalCustomerSubtitle')}
        theme="warning"
        flexBasis={'67%'}
        icon="solar:user-linear"
        count={dailyTotalCounts?.[0] ?? 0}
        data={getAreaChartData()}
        topRightCount={
          totalDailyCreatedCounts > 0
            ? `${(((totalDailyCreatedCounts ?? 0) / dailyTotalCounts[0]) * 100).toFixed(1)}%`
            : '0%'
        }
        bottomToolbarData={getBottomToolbarData()}
      />
    </Stack>
  );
});

export default CustomerStatistics;
