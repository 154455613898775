import { Route, Routes } from 'react-router-dom';

import { List } from '../pages';

import { ProjectsLayout } from '../layouts/ProjectsLayout';
import { ProjectDetail } from '../pages/detail/ProjectDetail';

export const ProjectsRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectsLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<ProjectDetail />} />
      </Route>
    </Routes>
  );
};
