import { memo, useCallback, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import {
  DataGridPro,
  GridCallbackDetails,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridFilterModel,
  GridRowId,
  GridRowModel,
  GridSlotProps,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { hasValue, IFilter, IPagination, isEmptyObject, Language } from 'corede-common';
import { trTR, enUS } from '@mui/x-data-grid/locales';
import { IBaseDataGripParams } from './infra/IBaseDataGripParams';
import { generateBaseGridColumnDefs } from './gridColumnComponents/BaseGridColumnDef';
import { CustomPagination } from './paginationComponents/CustomPagination';
import { BaseGridColType, GridCellClickActionType } from './infra/enums';
import { convertGridFilterModel } from './infra/FilterFactory';
import { DisableMultiFiltersDataGridOptions } from './infra/options';
import { CustomToolbar } from './toolbarComponents/CustomGridToolbarComponent';
import { getGridColConfig } from './infra/IGridColConfigInput.helper';
import { ICellConfigClickAction, IGridColConfigInput } from './infra/IGridColConfigInput';
import { TPaginationSort } from './infra/types';
import { generateBaseGridColumnActions } from './gridColumnComponents/BaseGridActionColumnDef';
import { defaultTableStyle } from './BaseDataGrid.table-style';

export const BaseDataGrid = memo(
  <T extends GridValidRowModel, TFilter extends IFilter, TUpdate>({
    columns,
    actionColumn,
    rows,
    count,
    listFilter,
    update,
    loading,
    config,
    toolbar,
    customStyle,
    disableColumnFilter,
    hideFooterPagination,
    sortingMode,
  }: IBaseDataGripParams<T, TFilter, TUpdate>) => {
    const currentLanguage = getCurrentLanguage();
    const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

    const colConfigs = columns.map((column) => column.config);
    const colDefList = [
      ...generateBaseGridColumnDefs(columns),
      actionColumn ? (generateBaseGridColumnActions(actionColumn) as GridColDef) : undefined,
    ];

    const pagination = (_: GridSlotProps['pagination']) => {
      return (
        <CustomPagination // TODO: add key
          count={count}
          key={listFilter?.filterInput.input?.pagination?.page ?? 0}
          page={listFilter?.filterInput.input?.pagination?.page}
          pageSize={listFilter?.filterInput.input?.pagination?.pageSize}
          onChange={(pagination: IPagination): void =>
            listFilter.setFilterInput((prevFilter) => {
              return {
                ...prevFilter,
                input: {
                  ...prevFilter.input,
                  pagination: {
                    ...prevFilter.input?.pagination,
                    ...pagination,
                  },
                },
              };
            })
          }
        />
      );
    };

    const handleFilterChange = useCallback(
      (newFilterModel: GridFilterModel, _: GridCallbackDetails<'filter'>) => {
        const filter: TFilter = convertGridFilterModel({
          configs: colConfigs,
          filterModel: newFilterModel,
        });

        listFilter.setFilterInput((prevFilter) => {
          return {
            ...prevFilter,
            input: {
              ...prevFilter.input,
              filter: {
                // ...prevFilter.input?.filter,
                ...filter,
              },
              pagination: {
                page: 1,
                pageSize: 10,
                sort: undefined,
              },
            },
          };
        });
      },
      [],
    );

    const handleSortModelChange = useCallback(
      (sortModel: GridSortModel, _: GridCallbackDetails) => {
        const sort = convertSortModel(sortModel);

        listFilter.setFilterInput((prevFilter) => {
          return {
            ...prevFilter,
            input: {
              ...prevFilter.input,
              pagination: {
                ...prevFilter.input?.pagination,
                sort: sort,
              },
            },
          };
        });
      },
      [],
    );

    const handleCellModesModelChange = useCallback((newModel: any) => {
      setCellModesModel(newModel);
    }, []);

    const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
      const config = getGridColConfig(colConfigs, params.field);
      if (!config) {
        return;
      }
      if (!config.column.editable && !config.cellConfig?.clickAction) {
        return;
      }

      willOpenPopup(config.cellConfig?.clickAction) &&
        config.cellConfig!.clickAction!.popupAction!(event as React.MouseEvent<HTMLButtonElement>);

      willOpenDrawer(config.cellConfig?.clickAction) &&
        config.cellConfig!.clickAction!.drawerAction!(true);

      willTakeCustomAction(config.cellConfig?.clickAction) &&
        config.cellConfig!.clickAction!.customAction!();

      setCellModesModel((prevModel) => {
        return ConvertEditCellInOneClick({
          prevModel: prevModel,
          cellId: params.id,
          cellField: params.field,
        });
      });
    }, []);

    const handleProcessRowUpdate = useCallback(
      async (newRow: GridRowModel, oldRow: GridRowModel): Promise<GridRowModel> => {
        const updatedFields = getChangedFieldsInRowUpdate({
          colConfigs,
          newRow,
          oldRow,
        });
        if (isEmptyObject(updatedFields)) {
          return newRow;
        }

        if (update?.updateQuery) {
          console.log(newRow, "newRow")
          const result = await update!.updateQuery({
            filter: update.customerUpdateQueryFilter
              ? update.customerUpdateQueryFilter(newRow)
              : {
                  _id: newRow._id,
                },
            input: updatedFields as TUpdate,
          });
          // TODO: handle query result
        }

        return newRow;
      },
      [],
    );

    return (
      <DataGridPro
        columns={colDefList.filter(Boolean) as readonly GridColDef<GridValidRowModel>[]}
        rows={rows}
        localeText={
          currentLanguage === Language.tr
            ? trTR?.components?.MuiDataGrid?.defaultProps.localeText
            : enUS?.components?.MuiDataGrid?.defaultProps.localeText
        }
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        initialState={{
          columns: { columnVisibilityModel: config.columnVisibilityModel },
        }}
        showColumnVerticalBorder
        checkboxSelection={false}
        disableRowSelectionOnClick
        disableColumnFilter={disableColumnFilter}
        // //disableAggregation TODO @madraselcuk
        // // disableRowGrouping
        hideFooterPagination={hideFooterPagination}
        loading={loading}
        scrollbarSize={1}
        scrollEndThreshold={10}
        rowsLoadingMode="server"
        columnHeaderHeight={45}
        rowHeight={70}
        slots={{
          pagination: pagination,
          toolbar: CustomToolbar({
            customActions: toolbar?.customActions ?? [],
          }),
        }}
        slotProps={{
          filterPanel: {
            logicOperators: [],
            filterFormProps: {
              filterColumns: DisableMultiFiltersDataGridOptions.filterColumns,
            },
            getColumnForNewFilter: DisableMultiFiltersDataGridOptions.getColumnForNewFilter,
          },
        }}
        pagination
        filterMode="server"
        onFilterModelChange={handleFilterChange}
        filterDebounceMs={1000}
        processRowUpdate={handleProcessRowUpdate}
        sortingMode={sortingMode ?? 'server'}
        onSortModelChange={handleSortModelChange}
        pinnedColumns={{ left: ['id'], right: ['actions'] }}
        sx={[defaultTableStyle, customStyle]}
      />
    );
  },
);

function convertSortModel(sortModel: GridSortModel): TPaginationSort {
  if (sortModel.length === 0) {
    return undefined;
  }
  let sort: TPaginationSort = {};

  for (const item of sortModel) {
    if (item.sort === `asc`) {
      sort[item.field] = 1;
    } else if (item.sort === `desc`) {
      sort[item.field] = -1;
    }
  }

  return sort;
}

function hasFilterChanged(prevFilter?: IFilter, newFilter?: IFilter): boolean {
  if (isEmptyObject(prevFilter) && isEmptyObject(newFilter)) {
    return false;
  }

  Object.keys(prevFilter!).forEach((field) => {
    if (prevFilter![field] !== newFilter![field]) {
      return true;
    }
  });

  return false;
}

// Function to get only the changed fields
function getChangedFieldsInRowUpdate(params: {
  colConfigs: IGridColConfigInput[];
  newRow: GridRowModel;
  oldRow: GridRowModel;
}) {
  const changes: Partial<GridRowModel> = {};

  // Compare each field in the row
  Object.keys(params.newRow).forEach((field) => {
    const config = getGridColConfig(params.colConfigs, field);
    if (hasValue(params.newRow[field]) && params.newRow[field] !== params.oldRow[field]) {
      if (config && config.baseGridColType === BaseGridColType.object) {
        changes[`${field}Id`] = params.newRow[field]?._id; // if the field is object, then we need to update using id
      } else {
        changes[field] = params.newRow[field]; // Store only the changed values
      }
    }
  });

  return changes;
}

function willOpenDrawer(config?: ICellConfigClickAction): boolean {
  if (!config) {
    return false;
  }

  return (
    config?.type === GridCellClickActionType.drawer &&
    hasValue(config?.drawerAction) &&
    hasValue(config?.setSelectedRow)
  );
}

function willOpenPopup(config?: ICellConfigClickAction): boolean {
  if (!config) {
    return false;
  }

  return config?.type === GridCellClickActionType.popup && hasValue(config?.popupAction);
}

function willTakeCustomAction(config?: ICellConfigClickAction): boolean {
  if (!config) {
    return false;
  }

  return config?.type === GridCellClickActionType.custom && hasValue(config?.customAction);
}

function ConvertEditCellInOneClick(params: {
  prevModel: GridCellModesModel;
  cellId: GridRowId;
  cellField: string;
}): GridCellModesModel {
  const result = {
    ...Object.keys(params.prevModel).reduce(
      (acc, id) => ({
        ...acc,
        [id]: Object.keys(params.prevModel[id]).reduce(
          (acc2, field) => ({
            ...acc2,
            [field]: { mode: GridCellModes.View },
          }),
          {},
        ),
      }),
      {},
    ),
    [params.cellId]: {
      ...Object.keys(params.prevModel[params.cellId] || {}).reduce(
        (acc, field) => ({
          ...acc,
          [field]: { mode: GridCellModes.View },
        }),
        {},
      ),
      [params.cellField]: { mode: GridCellModes.Edit },
    },
  };
  return result;
}
