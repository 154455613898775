import { Card, Grid, Grow } from '@mui/material';
import { SettingsTitle } from 'components';
import EmptyState from 'components/emptyState/EmptyState';
import { useTranslation } from 'react-i18next';

export const PaymentInvoices = (props: { selectedTabName: string }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t('settings.settings.settings.billingInfo')} />
      <Grid item xs={12}>
        <Grow in={props.selectedTabName === 'settings.settings.settings.invoices'} timeout={500}>
          <Card
            sx={{
              p: 2,
              border: '1px solid',
              bgcolor: 'background.thirdWithBlur',
              borderColor: 'primary.lighter',
            }}
          >
            <Grid container>
              <Grid item xs={12} mb={2}>
                <EmptyState
                  content1={t('settings.settings.settings.noInvoiceContent1')}
                  content2={t('settings.settings.settings.noInvoiceContent2')}
                />
              </Grid>
            </Grid>
          </Card>
        </Grow>
      </Grid>
    </Grid>
  );
};
