/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import { ILegalAgreement, ILegalAgreementListInput, LegalAgreementType } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useLegalAgreementListQuery,
  useLegalAgreementUpdateMutation,
} from '../../../context/setting.api';
import { LegalAgreementDeleteDialog } from '../LegalAgreementDeleteDialog';
import LegalAgreementUpdateDrawerWrapper from '../update/LegalAgreementUpdateDrawer.wrapper';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { representHtmlAsString } from 'utils/representationHelper';
import LegalAgreementDetailDrawer from '../detailDrawer/LegalAgreementDetailDrawer.wrapper';

export interface ILegalAgreementListGridComponent {
  // legalAgreement

  setOpenLegalAgreementCreateDrawer?: (value: boolean) => void | undefined;
}

const LegalAgreementListGridComponent = memo((props: ILegalAgreementListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedLegalAgreement, setSelectedLegalAgreement] =
    useState<TListGridSelectedEntity<ILegalAgreement>>();
  const [openLegalAgreementDetailDrawer, setOpenLegalAgreementDetailDrawer] = useState(false);
  const [openLegalAgreementDeleteDialog, setOpenLegalAgreementDeleteDialog] = useState(false);
  const [openLegalAgreementUpdateDrawer, setOpenLegalAgreementUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const [legalAgreementListFilter, setLegalAgreementListFilter] = useState<
    IGraphqlVariables<ILegalAgreementListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = legalAgreementListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = legalAgreementListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: legalAgreementListData,
    isLoading: legalAgreementListLoading,
    isFetching: legalAgreementListFetching,
    error: legalAgreementListError,
    refetch,
  } = useLegalAgreementListQuery(legalAgreementListFilter);

  const { data: legalAgreementListCountData } = useLegalAgreementListQuery({});

  // used for direct update in the list rows
  const [
    legalAgreementUpdate,
    { isLoading: legalAgreementUpdateLoading, error: legalAgreementUpdateError },
  ] = useLegalAgreementUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [legalAgreementListFilter]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(legalAgreementListError as IBaseError, currentLanguage);
  }, [legalAgreementListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(legalAgreementUpdateError as IBaseError, currentLanguage);
  }, [legalAgreementUpdateError]);
 
  const isEmptyState =
    legalAgreementListData?.data?.length === 0 &&
    !legalAgreementListLoading &&
    !legalAgreementListFetching &&
    legalAgreementListCountData?.count === 0;

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedLegalAgreement}
      setSelectedEntity={setSelectedLegalAgreement}
      listFilter={legalAgreementListFilter}
      setListFilter={setLegalAgreementListFilter}
      listData={legalAgreementListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '90%' }}>
        {legalAgreementListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                legalAgreementListData?.data?.map((legalAgreement, index) => ({
                  id:
                    ((legalAgreementListFilter.input?.pagination?.page || 1) - 1) *
                      (legalAgreementListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: legalAgreement._id,
                  name: legalAgreement.name,
                  content: representHtmlAsString({
                    data: legalAgreement.content,
                    trimLength: 150,
                  }),
                  type: legalAgreement.type,
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'name',
                      filterable: true,
                      editable: true,
                    },
                    headerConfig: {
                      name: t('crm.name'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'content',
                      filterable: true,
                      editable: false,
                      width: 300,
                    },
                    headerConfig: {
                      name: t('crm.content'),
                    },
                  },
                },

                {
                  config: {
                    baseGridColType: BaseGridColType.enum,
                    column: {
                      field: 'type',
                      editable: false,
                      width: 150,
                    },
                    headerConfig: {
                      name: t('crm.type'),
                      creatable: false,
                    },
                    filterConfig: {
                      customFieldName: `type`,
                      isArrayFilter: true,
                    },
                  },
                  data: LegalAgreementType,
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  view: {
                    clickConfig: {
                      setSelectedRow: setSelectedLegalAgreement,
                      setOpenAction: setOpenLegalAgreementDetailDrawer,
                    },
                  },
                  edit: {
                    clickConfig: {
                      setSelectedRow: setSelectedLegalAgreement,
                      setOpenAction: setOpenLegalAgreementUpdateDrawer,
                    },
                  },
                  delete: {
                    clickConfig: {
                      setSelectedRow: setSelectedLegalAgreement,
                      setOpenAction: setOpenLegalAgreementDeleteDialog,
                    },
                  },
                },
                customCellItems: [],
                actionHeaderName: t('crm.actions'),
              }}
              loading={
                legalAgreementListLoading ||
                legalAgreementUpdateLoading ||
                legalAgreementListFetching
              }
              listFilter={{
                filterInput: legalAgreementListFilter,
                setFilterInput: setLegalAgreementListFilter,
              }}
              update={{
                updateQuery: legalAgreementUpdate,
              }}
              count={legalAgreementListData?.count ?? 0}
              config={{
                columnVisibilityModel: {},
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('settings.settings.settings.noLegalAgreementsContent1')}
              content2={t('settings.settings.settings.noLegalAgreementsContent2')}
              button1={{
                title: t('settings.settings.settings.createLegalAgreement'),
                onClick: () => (props?.setOpenLegalAgreementCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <LegalAgreementDetailDrawer
        open={openLegalAgreementDetailDrawer}
        legalAgreement={selectedLegalAgreement}
        setOpen={setOpenLegalAgreementDetailDrawer}
      />

      <LegalAgreementUpdateDrawerWrapper
        open={openLegalAgreementUpdateDrawer}
        setOpen={setOpenLegalAgreementUpdateDrawer}
        selectedLegalAgreement={selectedLegalAgreement}
      />

      <LegalAgreementDeleteDialog
        open={openLegalAgreementDeleteDialog}
        legalAgreementId={selectedLegalAgreement?._id ?? ''}
        setLegalAgreement={setSelectedLegalAgreement}
        onClose={() => {
          setSelectedLegalAgreement(undefined);
          setOpenLegalAgreementDeleteDialog(false);
        }}
        key={selectedLegalAgreement?._id}
      />
    </DefaultListGridComponent>
  );
});

export default LegalAgreementListGridComponent;
