/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import {
  AppointmentCategory,
  AppointmentTargetType,
  IAppointmentCreateInput,
  ProgressCalculationType,
} from 'corede-common-cocrm';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useAppointmentCreateMutation } from '../../context/appointment.api';
import { validateCreateAppointmentInput } from '../../validations/create.validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertAppointmentComponent } from '../UpsertAppointment.component';

interface IAppointmentCreateDrawer {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  overlayType?: 'drawer' | 'dialog';
  relationType?: AppointmentTargetType;
  relationId?: string;
}

const AppointmentCreateDrawer = memo((props: IAppointmentCreateDrawer) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries

  // mutations
  const [
    appointmentCreate,
    {
      data: appointmentCreateData,
      isLoading: appointmentCreateLoading,
      error: appointmentCreateError,
    },
  ] = useAppointmentCreateMutation();

  // constants
  const initialValues = useMemo<IGraphqlVariables<IAppointmentCreateInput>>(() => {
    const startTime = new Date();
    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000); // startTime + 1 hour

    return {
      input: {
        title: '',
        description: '',
        endTime: endTime,
        startTime: startTime,
        timezone: 'Turkey',
        location: undefined,
        organizerId: undefined,
        targetType: props.relationType ?? undefined,
        attendeeIds: props.relationId ? [props.relationId] : undefined,
        category: AppointmentCategory.call,
        meetingLink: undefined,
      },
    };
  }, []);

  // form setup
  const appointmentCreateUseForm = useForm<IGraphqlVariables<IAppointmentCreateInput>>({
    values: initialValues,
    resolver: yupResolver(validateCreateAppointmentInput),
    mode: 'onChange',
  });

  const onSubmit = async (values: IGraphqlVariables<IAppointmentCreateInput>) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values,
    });
    await appointmentCreate(transformedValues as IGraphqlVariables<IAppointmentCreateInput>);
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (appointmentCreateData) {
      enqueueSnackbar(t('crm.calendar.appointment.createSuccess'), {
        variant: 'success',
      });
      appointmentCreateUseForm.reset();
      props.setOpen(false);
    }
  }, [appointmentCreateData, appointmentCreateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(appointmentCreateError as IBaseError, currentLanguage);
  }, [appointmentCreateError]);

  useEffect(() => {
    if (props.relationType && props.relationId) {
      appointmentCreateUseForm.setValue('input.targetType', props.relationType);
      appointmentCreateUseForm.setValue('input.attendeeIds', [props.relationId]);
    }
  }, [props.relationType, props.relationId]);

  return (
    <UpsertAppointmentComponent
      open={props.open}
      setOpen={props.setOpen}
      title={t('crm.calendar.appointment.create')}
      buttonTitle={t('crm.calendar.appointment.create')}
      loading={appointmentCreateLoading || loading}
      useForm={appointmentCreateUseForm}
      onSubmit={onSubmit}
      type="create"
      overlayType={props.overlayType}
    />
  );
});

export default AppointmentCreateDrawer;
