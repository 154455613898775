/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Grow, Card, InputLabel, TextField, Typography, Divider } from '@mui/material';
import { FilledButton, SettingsTitle } from 'components';
import EmptyState from 'components/emptyState/EmptyState';
import { FileMimeType, IBaseError, IGraphqlVariables, Language } from 'corede-common';
import {
  DocumentFolder,
  FileFormat,
  FileFormatTransformer,
  IOrganizationSettingsUpdateInput,
  LegalAgreementType,
  NotificationChannelType,
  PaymentMethod,
  documentValidMimeTypes,
} from 'corede-common-cocrm';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { useLegalAgreementListQuery } from '../../context/setting.api';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import {
  generateAutocompleteTranslatedOptions,
  getTranslatedEnumValue,
  getTranslatedEnumValues,
} from 'localization';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { RefreshOutlined } from '@mui/icons-material';
import {
  useOrganizationDetailOwnQuery,
  useOrganizationSettingsDetailOwnQuery,
  useOrganizationSettingsUpdateOwnMutation,
} from '../../context/setting.api';
import { enqueueSnackbar } from 'notistack';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { DefaultArrayEnumAutocompleteInput } from 'components/form/DefaultArrayEnumAutocompleteInput';
import { DefaultArrayAutocompleteInput } from 'components/form/DefaultArrayAutocompleteInput';

export const CrmFileManagementTab = (props: { selectedTabName: string }) => {
  const { t } = useTranslation();

  const { data: organizationSettings } = useOrganizationSettingsDetailOwnQuery({});

  const { data: NDAListData, isLoading: NDAListLoading } = useLegalAgreementListQuery({
    input: {
      filter: { types: [LegalAgreementType.NDA] },
      pagination: {
        sort: { createdAt: 1 },
      },
    },
  });

  const { data: termsAndConditionListData, isLoading: termsAndConditionListLoading } =
    useLegalAgreementListQuery({
      input: {
        filter: { types: [LegalAgreementType.termsAndConditions] },
        pagination: {
          sort: { createdAt: 1 },
        },
      },
    });

  const fileManagementFormInitialValues = useMemo<
    IGraphqlVariables<IOrganizationSettingsUpdateInput>
  >(
    () => ({
      input: {
        fileManagement: {
          allowableFileFormats: [],
        },
      },
    }),
    [],
  );

  const fileManagementForm = useForm<IGraphqlVariables<IOrganizationSettingsUpdateInput>>({
    values: fileManagementFormInitialValues,
    mode: 'onSubmit',
  });

  const [
    updateOrganizationSettings,
    {
      data: organizationSettingsUpdateData,
      isLoading: organizationSettingsUpdateOwnLoading,
      error: organizationSettingsUpdateOwnError,
    },
  ] = useOrganizationSettingsUpdateOwnMutation();

  const fileManagementFormOnSubmit = async (
    values: IGraphqlVariables<IOrganizationSettingsUpdateInput>,
  ) => {
    updateOrganizationSettings({ input: values.input });
  };

  useEffect(() => {
    if (organizationSettingsUpdateData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
    }
  }, [organizationSettingsUpdateData]);

  useEffect(() => {
    if (organizationSettingsUpdateOwnError) {
      DefaultErrorHandlerUseEffect(organizationSettingsUpdateOwnError as IBaseError, Language.en);
    }
  }, [organizationSettingsUpdateOwnError]);

  useEffect(() => {
    if (organizationSettings) {
      fileManagementForm.reset({
        input: {
          fileManagement: {
            allowableFileFormats: organizationSettings?.fileManagement?.allowableFileFormats ?? [],
          },
        },
      });
    }
  }, [organizationSettings]);

  console.log(organizationSettings?.fileManagement?.allowableFileFormats, 'allowableFileFormats');
  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t('settings.settings.settings.fileManagement')} />
      <Grid item xs={12}>
        <Grow
          in={props.selectedTabName === 'settings.settings.settings.fileManagement'}
          timeout={500}
        >
          <Card
            sx={{
              p: 2,
              border: '1px solid',
              bgcolor: 'background.thirdWithBlur',
              borderColor: 'primary.lighter',
            }}
          >
            {/* <Grid container width={{ xs: '100%', md: '50%', lg: '35%' }} spacing={2}> */}
            <Grid container width={{ xs: '100%' }} spacing={2}>
              {true ? (
                <>
                  <Grid item xs={12}>
                    <DefaultArrayAutocompleteInput
                      control={fileManagementForm.control as any}
                      inputName="input.fileManagement.allowableFileFormats"
                      label={t('settings.settings.settings.allowableFileFormats')}
                      options={Object.values(FileFormat)}
                      errors={fileManagementForm.formState.errors}
                      touchedFields={fileManagementForm.formState.touchedFields}
                      placeholder={t('settings.settings.settings.allowableFileFormats')}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FilledButton
                      leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                      color="secondary"
                      title={t('settings.settings.settings.updateSettings')}
                      onClick={fileManagementForm.handleSubmit(fileManagementFormOnSubmit)}
                      loading={organizationSettingsUpdateOwnLoading}
                      disabled={organizationSettingsUpdateOwnLoading}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} mb={2}>
                  <EmptyState
                    content1={t('settings.settings.settings.noFileManagementContent1')}
                    content2={t('settings.settings.settings.noFileManagementContent2')}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grow>
      </Grid>
    </Grid>
  );
};
