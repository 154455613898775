export const projectsDomainRoutes = {
  base: 'projects',
  subdomains: {
    projects: {
      base: 'projects',
    },
    project: {
      base: 'project',
    },
  },
};

export const baseProjectsRoute = (): string => `/${projectsDomainRoutes.base}/`;
export const listProjectsRoute = (): string =>
  `/${projectsDomainRoutes.base}/${projectsDomainRoutes.subdomains.projects.base}`;
export const viewProjectRoute = (id: string): string => `/${projectsDomainRoutes.base}/${id}`;
