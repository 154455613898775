/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Stack, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { Update } from '@mui/icons-material';
import { Iconify } from 'components';
import { IUserDetailResult } from 'corede-common-cocrm';
import { getTranslatedEnumValue } from 'localization';
import { Gender, Language } from 'corede-common';
import { t } from 'i18next';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import moment from 'moment';

interface IUserDetailTabInfoSectionComponent extends DefaultTabComponentProps<IUserDetailResult> {
  userData: IUserDetailResult | undefined;
  setOpenUserUpdateDrawer: (value: boolean) => void;
  tabKey: 'info';
  name: 'crm.info';
}

export const UserDetailTabInfoSectionComponent = (props: IUserDetailTabInfoSectionComponent) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const infoData = [
    {
      title: t('crm.email'),
      value: props.userData?.email ?? '-',
    },
    {
      title: t('crm.phoneNumber'),
      value: props.userData?.phoneNumber ?? '-',
    },
    {
      title: t('crm.country'),
      value: props.userData?.country ?? '-',
    },
    {
      title: t('crm.state'),
      value: props.userData?.state ?? '-',
    },
    {
      title: t('crm.city'),
      value: props.userData?.city ?? '-',
    },
    {
      title: t('crm.address'),
      value: props.userData?.address ?? '-',
    },
    {
      title: t('crm.birthDate'),
      value: props.userData?.birthDate
        ? moment(props.userData?.birthDate).format('DD/MM/YYYY')
        : '-',
    },
    {
      title: t('crm.gender'),
      value: props.userData?.gender
        ? getTranslatedEnumValue(props.userData?.gender as Gender)
        : '-',
    },
  ];

  const contactDetailsData = [
    {
      title: t('crm.language'),
      value: props.userData?.language
        ? getTranslatedEnumValue(props.userData?.language as Language)
        : '-',
      icon: 'tabler:language',
    },
    {
      title: t('crm.description'),
      value: props.userData?.description ?? '-',
      icon: 'fluent:text-description-16-filled',
    },
    {
      title: t('crm.organizationalChart.user.lastLoginDate'),
      value:
        props.userData?.lastLoginDate ? moment(props.userData?.lastLoginDate).format('DD/MM/YYYY') : '-',
      icon: 'stash:last-updates',
    },
  ];

  return (
    <Grid container mt={downMd ? 0 : 2} spacing={2.5} mb={2} px={{ xs: 0, lg: 2 }}>
      <Grid item xs={12} md={4}>
        <Stack
          direction={'column'}
          p={3}
          gap={1.5}
          sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
        >
          <Stack direction={'column'} width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant={'overline'} sx={{ fontWeight: 500 }}>
                {t('crm.about')}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: 'auto',
                  height: 32,
                  fontSize: { xs: '13px', md: '13px' },
                  lineHeight: 1,
                  borderRadius: '24px',
                }}
                onClick={() => props.setOpenUserUpdateDrawer(true)}
              >
                <Update sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
                {t('crm.update')}
              </Button>
            </Stack>

            <Divider sx={{ mt: 1 }} />
          </Stack>

          {infoData.map((item) => (
            <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
              <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
                {item.title}:
              </Typography>
              <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
                {item.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack direction={'column'} py={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.contactDetails')}
          </Typography>
          <Grid container spacing={2}>
            {contactDetailsData.map((item) => (
              <Grid item xs={12}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title ?? '-'}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
