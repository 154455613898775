const locale = {
  leadStatuses: "Lead Statuses",
  title: "Lead Statuses",
  create: "Create Lead Status",
  delete: "Delete Lead Status",
  deleteConfirm: "Are you sure you want to delete this status: ",
  deletedSuccessfully: "Lead status deleted successfully",
  createSuccess: "Lead status created successfully",
  update: "Update Lead Status",
  updateSuccess: "Lead status updated successfully",
  selectColor: "Select Color",
  selectIcon: "Select Icon",
};

export default locale;
