const locale = {
  pricing: 'Pricing',
  saveUpTo: 'Save up to',
  monthly: 'monthly',
  monthlyUppercase: 'Monthly',
  annually: 'annually',
  annuallyUppercase: 'Annually',
  organization: 'Organization',
  month: 'Month',
  billedMonthly: 'Billed Monthly',
  billedAnnually: 'Billed Annually',
  users: 'Users',
  startTrialForFree: 'Start Trial for Free',
  buy: 'BUY',
  contactUs: 'Contact Us',
  starterPlan: 'Starter Plan',
  enterprisePlan: 'Enterprise Plan',
  mostPopular: 'Most Popular',
  buyPackage: 'Buy Package',
  buyPackageDescription:
    'This package called {{name}} includes a storage limit of {{storageLimit}} GB and a user limit of {{userLimit}}.',
  dontHaveBillingInfo: "You don't have billing information to use.",
  dontHaveCard: "You don't have any card to use.",
  addBillingInfo: 'Add Billing Info',
  addCard: 'Add Card',
  manageBillingInfo: 'Manage Billing Info',
  manageCards: 'Manage Cards',
  summary: 'Summary',
  iAcceptThe: 'I accept the',
  preliminaryInformation: 'Preliminary Information',
  distanceSalesAgreement: 'Distance Sales Agreement',
  close: 'Close',
  cancel: 'Cancel',
  subscribeSuccess1: 'Your subscription has been successfully started.',
  subscribeSuccess2: 'Welcome aboard.',
  continue: 'Continue',
  letUsCall: 'Let us Call',
  billingInfo: 'Billing Info',
  selectedCard: 'Selected Card',
  currentPlan: 'Current Plan',
  renewAt: 'Renew at',
  active: 'Active',
  usageLimits: 'Usage Limits',
  storageUsage: 'Usage of storage',
  activeCard: 'Active Card',
  trialPackage: 'Trial Package',
  trialPackageDescription:
    'This package offers a usage period of 7 days, with a storage limit of {{storageLimit}} GB and a user limit of {{userLimit}}.',
  confirmTrial: 'Are you sure you want to begin trial?',
  beginTrial: 'Begin Trial',
  contactFormSuccess: 'Your request has been submitted successfully. We will contact you soon.',

  packageDescription:
    'This package offers a usage period of 7 days, with a storage limit of {{storageLimit}} GB and a user limit of {{userLimit}}.',
  beginTrialSuccess: 'Trial started successfully.',
  purchaseSuccess: 'Purchased successfully.',
  title: 'Pricing',
  unknownError: 'An unknown error occurred.',
  contactMessage: 'Phone: {{phone}}, OrgName: {{orgName}}',
  detailTitle: 'Detail',
  trialPackageTitle: 'Trial Package',
  buyPackageTitle: 'Buy Package',
  contactUsTitle: 'Contact Us',
  preliminaryInfoTitle: 'Preliminary Information',
  distanceSalesAgreementTitle: 'Distance Sales Agreement',
  welcomeTrial1: 'Your trial package has been successfully started. Welcome aboard.',
  welcomeTrial2: 'Welcome aboard.',
  noBillingInfo: "You don't have billing information to use.",
  billingInfoLabel: 'Billing Info*',
  noCardInfo: "You don't have any card to use.",
  selectedCardLabel: 'Selected Card*',
  acceptPreliminaryInfo: 'I accept the Preliminary Information',
  acceptDistanceSalesAgreement: 'I accept the Distance Sales Agreement',
  preliminaryInfoDescription:
    'This package offers a usage period of 7 days, with a storage limit and user permissions as outlined in the agreement.',
  subscriptionSuccess: 'Your subscription has been successfully started. Welcome aboard.',
  selectedPeriodLabel: '/{{selectedPeriod}}',
  distanceSalesAgreementDescription:
    'This package offers a usage period of 7 days, with a storage limit and user permissions as outlined in the distance sales agreement.',
  goCRM: 'Go CRM',
  tryForFree: 'Try for Free',
  onePaymentOf: 'One payment of',

  cancelled: 'Cancelled',
  expired: 'Expired',
  completed: 'Completed',
  failed: 'Failed',
  ongoingWithCancelled: 'Ongoing with cancelled',
  ongoingWithFailure: 'Ongoing with failure',

  payment: 'Payment',
  upgrade: 'UPGRADE',
  paymentSuccess: 'Payment completed successfully.',
  paymentFailure: 'Payment process completed',
};

export default locale;
