const locale = {
  leadSources: "Lead Sources",
  title: "Lead Sources",
  create: "Create Lead Source",
  delete: "Delete Lead Source",
  deleteConfirm: "Are you sure you want to delete this source: ",
  deletedSuccessfully: "Lead source deleted successfully",
  createSuccess: "Lead source created successfully",
  updateSuccess: "Lead source updated successfully",
  update: "Update Lead Source",
  updateLeadSourceSuccess: "Lead source updated successfully",
  selectColor: "Select Color",
  selectIcon: "Select Icon",
};

export default locale;
