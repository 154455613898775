import { useState, lazy, Suspense } from 'react';

const LegalAgreementUpdateDrawer = lazy(() => import('./LegalAgreementUpdateDrawer'));

const LegalAgreementUpdateDrawerWrapper = ({
  open,
  setOpen,
  selectedLegalAgreement,
}: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <LegalAgreementUpdateDrawer
            open={open}
            setOpen={setOpen}
            selectedLegalAgreement={selectedLegalAgreement}
          />
        </Suspense>
      )}
    </>
  );
};

export default LegalAgreementUpdateDrawerWrapper;
