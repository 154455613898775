import { PermissionDomain, PermissionSubdomain } from 'corede-common-cocrm';

export const domainsInOrder: PermissionDomain[] = [
  PermissionDomain.dashboard,
  PermissionDomain.auth,
  PermissionDomain.user,
  PermissionDomain.customer,
  PermissionDomain.lead,
  PermissionDomain.project,
  PermissionDomain.task,
  PermissionDomain.presale,
  PermissionDomain.sale,
  PermissionDomain.payment,
  PermissionDomain.contract,
  PermissionDomain.expense,
  PermissionDomain.calendar,
  PermissionDomain.comment,
  PermissionDomain.dms,
  PermissionDomain.note,
  PermissionDomain.notification,
  PermissionDomain.product,
  PermissionDomain.ticket,
];

export const subDomainsInOrder: Map<PermissionDomain, PermissionSubdomain[]> = new Map([
  [PermissionDomain.dashboard, [PermissionSubdomain.dashboard]],
  [
    PermissionDomain.auth,
    [
      PermissionSubdomain.role,
      PermissionSubdomain.permission,
      PermissionSubdomain.userPermission,
      PermissionSubdomain.userRole,
    ],
  ],
  [
    PermissionDomain.user,
    [
      PermissionSubdomain.user,
      PermissionSubdomain.organization,
      PermissionSubdomain.department,
      PermissionSubdomain.organizationChart,
      PermissionSubdomain.organizationSettings,
    ],
  ],
  [
    PermissionDomain.customer,
    [
      PermissionSubdomain.customer,
      PermissionSubdomain.customerCategory,
      PermissionSubdomain.customerTag,
    ],
  ],
  [
    PermissionDomain.lead,
    [
      PermissionSubdomain.lead,
      PermissionSubdomain.leadStatus,
      PermissionSubdomain.leadSource,
      PermissionSubdomain.leadTag,
    ],
  ],
  [PermissionDomain.project, [PermissionSubdomain.project]],
  [PermissionDomain.task, [PermissionSubdomain.task]],
  [PermissionDomain.presale, [PermissionSubdomain.estimate, PermissionSubdomain.proposal]],
  [
    PermissionDomain.sale,
    [
      PermissionSubdomain.invoice,
      PermissionSubdomain.invoicePayment,
      PermissionSubdomain.invoiceReturn,
    ],
  ],
  [
    PermissionDomain.payment,
    [PermissionSubdomain.subscription, PermissionSubdomain.purchase, PermissionSubdomain.order],
  ],
  [PermissionDomain.contract, [PermissionSubdomain.contract, PermissionSubdomain.contractTemplate]],
  [PermissionDomain.expense, [PermissionSubdomain.expense]],
  [PermissionDomain.calendar, [PermissionSubdomain.appointment, PermissionSubdomain.reminder]],
  [PermissionDomain.comment, [PermissionSubdomain.comment]],
  [PermissionDomain.dms, [PermissionSubdomain.file]],
  [PermissionDomain.note, [PermissionSubdomain.note]],
  [
    PermissionDomain.notification,
    [PermissionSubdomain.webNotification, PermissionSubdomain.notificationHistory],
  ],
  [PermissionDomain.product, [PermissionSubdomain.product, PermissionSubdomain.productCategory]],
  [PermissionDomain.ticket, [PermissionSubdomain.ticket]],
]);
