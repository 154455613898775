/* eslint-disable react-hooks/exhaustive-deps */
import { useContractDetailQuery } from '../../context/contract.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { IContract, IContractDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { ContractDetailDrawerLeftPanelComponent } from './ContractDetailDrawer-leftPanel.component';
import { ContractDetailDrawerRightPanelComponent } from './ContractDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IContractDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedContract: IContractDetailResult & IHasId<number>;
  setSelectedContract: Dispatch<SetStateAction<TListGridSelectedEntity<IContract>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const ContractDetailDrawer = memo((props: IContractDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: contractDetailData, error: contractDetailError } = useContractDetailQuery(
    props?.selectedContract?._id
      ? {
          input: {
            _id: props?.selectedContract?._id,
          },
        }
      : skipToken,
    {
      skip: !props?.selectedContract?._id,
    },
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(contractDetailError as IBaseError, currentLanguage);
  }, [contractDetailError]);

  return (
    <DefaultDetailDrawer<IContractDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedContract}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.contract.contract.title'),
            }
      }
    >
      <ContractDetailDrawerLeftPanelComponent
        contractDetailData={contractDetailData}
        setOpen={props.setOpen}
      />
      <ContractDetailDrawerRightPanelComponent contractDetailData={contractDetailData} />
    </DefaultDetailDrawer>
  );
});

export default ContractDetailDrawer;
