/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Divider } from '@mui/material';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useDepartmentListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/department';
import { useUserListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import { useLeadListByRelatedQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { useCustomerListByRelatedQuery } from 'apps/crm/domains/04-customer/subdomains/customer';
import { useEstimateListQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { useProposalListQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { useInvoiceListQuery } from 'apps/crm/domains/06-sale/subdomains/invoice';
import { useContractListQuery } from 'apps/crm/domains/07-contract/subdomains/contract';
import { useExpenseListQuery } from 'apps/crm/domains/09-expense/subdomains/expense';
import { useProjectListQuery } from 'apps/crm/domains/10-project/subdomains/project';
import { useTaskDocumentCreateManyMutation } from 'apps/disk/domains/document/subdomains/document';
import { useOrganizationDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';
import { MyTab } from 'components';
import { DefaultArrayAutocompleteInput } from 'components/form/DefaultArrayAutocompleteInput';
import { DefaultArrayObjectAutocompleteInput } from 'components/form/DefaultArrayObjectAutocompleteInput';
import { DefaultDatePickerInput } from 'components/form/DefaultDatePickerInput';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { DefaultRelationshipSwitchInput } from 'components/form/DefaultRelationshipSwitchInput';
import { DefaultSwitchInput } from 'components/form/DefaultSwitchInput';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { IFileData, UploadDocumentForDrawer } from 'components/uploadFile/UploadDocumentForDrawer';
import { Currency, IGraphqlVariables, PermissionAction } from 'corede-common';
import {
  CustomerRelatedTargetType,
  DepartmentRelatedTargetType,
  ITaskCreateInput,
  ITaskUpdateInput,
  LeadRelatedTargetType,
  PermissionSubject,
  TaskPriority,
  TaskTargetType,
  UserRelatedTargetType,
} from 'corede-common-cocrm';
import { generateAutocompleteTranslatedOptions } from 'localization';
import { usePermissions } from 'permission/PermissionContext';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type CombinedErrorsType =
  | FieldErrors<IGraphqlVariables<ITaskCreateInput, undefined>>
  | FieldErrors<IGraphqlVariables<ITaskUpdateInput, undefined>>;

interface ITaskForm {
  // watch: CombinedWatchType
  open: boolean;
  setValue: UseFormSetValue<IGraphqlVariables<any>>;
  watch: UseFormWatch<IGraphqlVariables<any>>;
  // control: CombinedControlType
  control: Control<IGraphqlVariables<any>>;
  type: 'create' | 'update';
  touchedFields: Partial<Readonly<any>>;
  errors: CombinedErrorsType;
  getValues: any;
  targetEntityRef?: TaskTargetType;
  targetId?: string;
  onUpload?: () => void;
  uploadDocumentRef?: React.RefObject<{ handleUpload: () => void }>;
}

export const UpsertTaskComponent = (props: ITaskForm) => {
  const { t } = useTranslation();
  const [targetOptions, setTargetOptions] = useState<{ _id: string; name: string }[]>([]);
  const [haveRelation, setHaveRelation] = useState(false);
  const [justForMe, setJustForMe] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<IFileData[]>([]);

  const tabListLabels = [
    t('crm.task.task.detailedInfo'),
    t('crm.task.task.recurrentInfo'),
    t('crm.task.task.billableInfo'),
    t('crm.task.task.documents'),
  ];

  const [selectedTab, setSelectedTab] = useState(tabListLabels[0]);

  const { hasPermission } = usePermissions();
  const hasLeadPermission = hasPermission({
    subject: PermissionSubject.lead,
    action: PermissionAction.update,
  });
  const hasCustomerPermission = hasPermission({
    subject: PermissionSubject.customer,
    action: PermissionAction.update,
  });
  const hasEstimatePermission = hasPermission({
    subject: PermissionSubject.estimate,
    action: PermissionAction.update,
  });
  const hasProposalPermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.update,
  });
  const hasInvoicePermission = hasPermission({
    subject: PermissionSubject.invoice,
    action: PermissionAction.update,
  });
  const hasExpensePermission = hasPermission({
    subject: PermissionSubject.expense,
    action: PermissionAction.update,
  });
  const hasProjectPermission = hasPermission({
    subject: PermissionSubject.project,
    action: PermissionAction.update,
  });
  const hasContractPermission = hasPermission({
    subject: PermissionSubject.contract,
    action: PermissionAction.update,
  });

  // TODO for skip query
  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
  } = useUserListByRelatedQuery({
    input: {
      filter: {
        relatedTargetType: UserRelatedTargetType.Task,
      },
    },
  });
  const { data: userListForAssigneeData, isLoading: userListForAssigneeLoading } =
    useUserListByRelatedQuery({
      input: {
        filter: {
          relatedTargetType: UserRelatedTargetType.Task,
          departmentId: props.watch('input.departmentId'),
        },
      },
    });

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const { data: organizationDetailOwnData } = useOrganizationDetailOwnQuery({});
  const { data: leadListData, isLoading: leadListLoading } = useLeadListByRelatedQuery(
    {
      input: {
        filter: {
          relatedTargetType: LeadRelatedTargetType.Task,
        },
      },
    },
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Lead) ||
        !hasLeadPermission,
    },
  );
  const { data: customerListData, isLoading: customerListLoading } = useCustomerListByRelatedQuery(
    {
      input: {
        filter: {
          relatedTargetType: CustomerRelatedTargetType.Task,
        },
      },
    },
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Customer) ||
        !hasCustomerPermission,
    },
  );
  const { data: estimateListData, isLoading: estimateListLoading } = useEstimateListQuery(
    {},
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Estimate) ||
        !hasEstimatePermission,
    },
  );
  const { data: proposalListData, isLoading: proposalListLoading } = useProposalListQuery(
    {},
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Proposal) ||
        !hasProposalPermission,
    },
  );
  const { data: invoiceListData, isLoading: invoiceListLoading } = useInvoiceListQuery(
    {},
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Invoice) ||
        !hasInvoicePermission,
    },
  );
  const { data: expenseListData, isLoading: expenseListLoading } = useExpenseListQuery(
    {},
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Expense) ||
        !hasExpensePermission,
    },
  );
  const { data: projectListData, isLoading: projectListLoading } = useProjectListQuery(
    {},
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Project) ||
        !hasProjectPermission,
    },
  );
  const { data: contractListData, isLoading: contractListLoading } = useContractListQuery(
    {},
    {
      skip:
        !haveRelation ||
        !(props.watch('input.relatedEntityRef') === TaskTargetType.Contract) ||
        !hasContractPermission,
    },
  );

  const { data: departmentListData, isLoading: departmentListLoading } =
    useDepartmentListByRelatedQuery({
      input: {
        filter: {
          relatedTargetType: DepartmentRelatedTargetType.Task,
        },
      },
    });

  const { fields, append, remove, update } = useFieldArray<IGraphqlVariables<ITaskCreateInput>>({
    control: props.control,
    name: 'input.checklist',
  });

  const addChecklistItem = () => {
    // setChecklist((prev) => [...prev, { description: '', assigneeId: '', isChecked: false }]);

    append({ description: '', assigneeId: '' });
  };

  const updateChecklistItem = (index: number, field: string, value: any) => {
    // setChecklist((prev) =>
    //   prev?.map((item, i) => (i === index ? { ...item, [field]: value } : item)),
    // );
    update(index, { ...fields[index], [field]: value });
  };

  const removeChecklistItem = (index: number) => {
    // setChecklist((prev) => prev.filter((_, i) => i !== index));
    remove(index);
  };

  const justForMeChecked = (value: boolean): void => {
    setJustForMe(value);
    if (value) {
      setHaveRelation(false);
    }
  };

  const haveRelationChecked = (value: boolean): void => {
    setHaveRelation(value);
    if (value) {
      setJustForMe(false);
    }
  };

  useEffect(() => {
    if (!props.watch('input.isBillable')) {
      props.setValue('input.hourlyRateCurrency', undefined);
      props.setValue('input.hourlyRate', undefined);
    }
  }, [props.watch('input.isBillable')]);

  useEffect(() => {
    if (!props.watch('input.isRecurrent')) {
      props.setValue('input.repeatDays', undefined);
      props.setValue('input.totalCycle', undefined);
    }
  }, [props.watch('input.isRecurrent')]);

  useEffect(() => {
    if (!props.targetEntityRef) {
      switch (props.watch('input.relatedEntityRef')) {
        case TaskTargetType.User:
          setTargetOptions(
            userListData?.data?.map((user) => ({
              _id: user._id,
              name: user.name + ' ' + user.surname,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Lead:
          setTargetOptions(
            leadListData?.data?.map((lead) => ({
              _id: lead._id,
              name: lead.fullName,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Customer:
          setTargetOptions(
            customerListData?.data?.map((customer) => ({
              _id: customer._id,
              name: customer.name,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Proposal:
          setTargetOptions(
            proposalListData?.data?.map((proposal) => ({
              _id: proposal._id,
              name: proposal.prefix,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Estimate:
          setTargetOptions(
            estimateListData?.data?.map((estimate) => ({
              _id: estimate._id,
              name: estimate.prefix,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Invoice:
          setTargetOptions(
            invoiceListData?.data?.map((invoice) => ({
              _id: invoice._id,
              name: invoice.invoiceId,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Expense:
          setTargetOptions(
            expenseListData?.data?.map((expense) => ({
              _id: expense._id,
              name: expense.name,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Project:
          setTargetOptions(
            projectListData?.data?.map((project) => ({
              _id: project._id,
              name: project.name,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Contract:
          setTargetOptions(
            contractListData?.data?.map((contract) => ({
              _id: contract._id,
              name: contract.title,
            })) ?? ([] as any),
          );
          break;
        case TaskTargetType.Organization:
          setTargetOptions([
            {
              _id: organizationDetailOwnData?._id ?? '',
              name: organizationDetailOwnData?.name ?? '',
            },
          ]);
          break;
        default:
          setTargetOptions([]);
      }
    }
  }, [
    props.watch('input.relatedEntityRef'),
    userListData,
    leadListData,
    customerListData,
    proposalListData,
    estimateListData,
    invoiceListData,
    expenseListData,
    projectListData,
    contractListData,
  ]);

  useEffect(() => {
    if (props.open && props.targetEntityRef) {
      props.setValue('input.relatedEntityRef', props.targetEntityRef);
      props.setValue('input.relatedEntityId', props.targetId);
    } else if (props.open && justForMe) {
      props.setValue('input.relatedEntityRef', TaskTargetType.User);
      props.setValue('input.relatedEntityId', userDetailOwnData?._id);
    } else if (props.open && !haveRelation) {
      props.setValue('input.relatedEntityRef', TaskTargetType.Organization);
      props.setValue('input.relatedEntityId', userDetailOwnData?.organization?._id);
    }
  }, [props.open, props.targetEntityRef, haveRelation, justForMe]);

  useEffect(() => {
    if (props.open) {
      setSelectedTab(tabListLabels[0]);
      props.watch('input.isRecurrent') && props.setValue('input.isRecurrent', false);
      props.watch('input.isBillable') && props.setValue('input.isBillable', false);
      props.watch('input.documents') && props.setValue('input.documents', []);
      setUploadedDocuments([]);
    }
  }, [props.open]);

  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.subject"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          label={t('crm.task.task.subject')}
          errors={props.errors}
          touchedFields={props.touchedFields}
          isRequired
          isFirstLetterCapitalize
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.description"
          control={props.control as Control<IGraphqlVariables<any, any>>}
          label={t('crm.task.task.description')}
          errors={props.errors}
          touchedFields={props.touchedFields}
          isFirstLetterCapitalize
          multiline
          isRequired
          rows={3}
        />
      </Grid>
      <Grid item xs={6}>
        <DefaultDatePickerInput
          inputName="input.startDate"
          control={props.control}
          label={t('crm.task.task.startDate')}
          errors={props.errors}
        />
      </Grid>
      <Grid item xs={6}>
        <DefaultDatePickerInput
          inputName="input.dueDate"
          control={props.control}
          label={t('crm.task.task.dueDate')}
          errors={props.errors}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultArrayObjectAutocompleteInput
          inputName="input.assigneeIds"
          control={props.control}
          label={t('crm.task.task.assigneeIds')}
          options={userListForAssigneeData?.data || []}
          loading={userListForAssigneeLoading}
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultObjectAutocompleteInput
          inputName="input.departmentId"
          control={props.control}
          options={departmentListData?.data || []}
          label={t('crm.task.task.department')}
          errors={props.errors}
          touchedFields={props.touchedFields}
        />
      </Grid>

      <Grid item xs={12}>
        <MyTab labels={tabListLabels} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <Divider sx={{ mb: 3 }} />
        {selectedTab === t('crm.task.task.detailedInfo') && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DefaultEnumAutocompleteInput
                inputName="input.priority"
                control={props.control as Control<IGraphqlVariables<any, any>>}
                options={generateAutocompleteTranslatedOptions(TaskPriority)}
                enum={TaskPriority}
                label={t('crm.task.task.priority')}
              />
            </Grid>

            <Grid item xs={12}>
              <DefaultArrayAutocompleteInput
                inputName="input.tags"
                control={props.control}
                options={[]}
                label={t('crm.task.task.tags')}
                errors={props.errors}
                touchedFields={props.touchedFields}
              />
            </Grid>

            <Grid item xs={12}>
              <DefaultArrayObjectAutocompleteInput
                inputName="input.followerIds"
                control={props.control}
                label={t('crm.task.task.followerIds')}
                options={userListData?.data || []}
                loading={userListLoading}
              />
            </Grid>

            {props.type === 'create' && !props.targetEntityRef && (
              <>
                <Grid item xs={12} sm={6}>
                  <DefaultRelationshipSwitchInput
                    description={t('crm.relationship')}
                    checked={haveRelation}
                    setChecked={haveRelationChecked}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DefaultRelationshipSwitchInput
                    description={t('crm.justForMe')}
                    checked={justForMe}
                    setChecked={justForMeChecked}
                  />
                </Grid>
              </>
            )}

            {props.type === 'create' && haveRelation && (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: !!props.targetEntityRef ? 'none' : 'block',
                }}
              >
                <DefaultEnumAutocompleteInput
                  inputName="input.relatedEntityRef"
                  control={props.control as Control<IGraphqlVariables<any, any>>}
                  label={t('crm.relationshipType')}
                  options={generateAutocompleteTranslatedOptions(TaskTargetType)}
                  enum={TaskTargetType}
                  disableClearable
                  isRequired
                />
              </Grid>
            )}
            {props.type === 'create' &&
              haveRelation &&
              props.watch('input.relatedEntityRef') !== TaskTargetType.Organization && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: !!props.targetEntityRef ? 'none' : 'block',
                  }}
                >
                  <DefaultObjectAutocompleteInput
                    key={props.watch('input.relatedEntityRef')}
                    inputName="input.relatedEntityId"
                    control={props.control}
                    options={targetOptions}
                    label={
                      props.watch('input.relatedEntityRef') === TaskTargetType.User
                        ? t('crm.relatedUser')
                        : props.watch('input.relatedEntityRef') === TaskTargetType.Lead
                          ? t('crm.relatedLead')
                          : props.watch('input.relatedEntityRef') === TaskTargetType.Customer
                            ? t('crm.relatedCustomer')
                            : props.watch('input.relatedEntityRef') === TaskTargetType.Proposal
                              ? t('crm.relatedProposal')
                              : props.watch('input.relatedEntityRef') === TaskTargetType.Estimate
                                ? t('crm.relatedEstimate')
                                : props.watch('input.relatedEntityRef') === TaskTargetType.Invoice
                                  ? t('crm.relatedInvoice')
                                  : props.watch('input.relatedEntityRef') === TaskTargetType.Expense
                                    ? t('crm.relatedExpense')
                                    : props.watch('input.relatedEntityRef') ===
                                        TaskTargetType.Project
                                      ? t('crm.relatedProject')
                                      : props.watch('input.relatedEntityRef') ===
                                          TaskTargetType.Contract
                                        ? t('crm.relatedContract')
                                        : t('crm.relatedEntity')
                    }
                    isRequired
                    isLoading={
                      userListLoading ||
                      leadListLoading ||
                      customerListLoading ||
                      proposalListLoading ||
                      estimateListLoading ||
                      invoiceListLoading ||
                      expenseListLoading ||
                      projectListLoading ||
                      contractListLoading
                    }
                    errors={props.errors}
                    touchedFields={props.touchedFields}
                    disableAddNewOption
                  />
                </Grid>
              )}
          </Grid>
        )}

        {selectedTab === t('crm.task.task.recurrentInfo') && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DefaultSwitchInput
                inputName="input.isRecurrent"
                control={props.control as Control<IGraphqlVariables<any, any>>}
                description={t('crm.task.task.isRecurrent')}
              />
            </Grid>

            {props.watch('input.isRecurrent') && (
              <Grid item xs={6}>
                <DefaultTextFieldInput
                  inputName="input.repeatDays"
                  control={props.control as Control<IGraphqlVariables<any, any>>}
                  errors={props.errors}
                  type="number"
                  touchedFields={props.touchedFields}
                  label={t('crm.task.task.repeatDays')}
                />
              </Grid>
            )}
            {props.watch('input.isRecurrent') && (
              <Grid item xs={6}>
                <DefaultTextFieldInput
                  inputName="input.totalCycle"
                  control={props.control as Control<IGraphqlVariables<any, any>>}
                  errors={props.errors}
                  type="number"
                  touchedFields={props.touchedFields}
                  label={t('crm.task.task.totalCycle')}
                />
              </Grid>
            )}
          </Grid>
        )}

        {selectedTab === t('crm.task.task.billableInfo') && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <DefaultSwitchInput
                inputName="input.isBillable"
                control={props.control as Control<IGraphqlVariables<any, any>>}
                description={t('crm.task.task.isBillable')}
              />
            </Grid>

            {props.watch('input.isBillable') && (
              <Grid item xs={6}>
                <DefaultTextFieldInput
                  inputName="input.hourlyRate"
                  control={props.control as Control<IGraphqlVariables<any, any>>}
                  errors={props.errors}
                  type="number"
                  touchedFields={props.touchedFields}
                  label={t('crm.task.task.hourlyRate')}
                />
              </Grid>
            )}
            {props.watch('input.isBillable') && (
              <Grid item xs={6}>
                <DefaultEnumAutocompleteInput
                  inputName="input.hourlyRateCurrency"
                  control={props.control as Control<IGraphqlVariables<any, any>>}
                  options={generateAutocompleteTranslatedOptions(Currency)}
                  enum={Currency}
                  label={t('crm.task.task.hourlyRateCurrency')}
                />
              </Grid>
            )}
          </Grid>
        )}

        <Grid
          container
          spacing={1}
          sx={{ display: selectedTab === t('crm.task.task.documents') ? 'block' : 'none' }}
        >
          <UploadDocumentForDrawer
            ref={props.uploadDocumentRef}
            useDocumentCreateManyMutation={useTaskDocumentCreateManyMutation}
            onUpload={props.onUpload}
            externalFileDataList={uploadedDocuments}
            setExternalFileDataList={setUploadedDocuments}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
