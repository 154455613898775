const locale = {
  projects: 'Projeler',
  project: 'Proje',
  projectConversions: 'Proje Dönüştürme',
  title: 'Projeler',
  create: 'Proje Oluştur',
  update: 'Proje Güncelle',
  delete: 'Proje Sil',
  createSuccess: 'Proje başarıyla oluşturuldu.',
  updateSuccess: 'Proje başarıyla güncellendi.',
  deleteSuccess: 'Proje silindi.',
  deleteConfirm: 'Bu proje silinmek istediğinize emin misiniz? Bu işlem geri alınamaz.',
  emptyState1: 'Henüz başlatılmış bir proje yok.',
  emptyState2:
    'İlerlemeyi takip etmek, görevler atamak ve ekibinizle işbirliği yapmak için projelerinizi burada oluşturun ve yönetin.',
  progressCalculationType: 'İlerleme Hesaplaması',
  progressPercentage: 'İlerleme Oranı %',
  isBillable: 'Faturalanabilir',
  billingType: 'Faturalama Tipi',
  billingRatePerHour: 'Faturalama Oranı',
  members: 'Üyeler',
  startDate: 'Başlangıç Tarihi',
  deadline: 'Bitiş Tarihi',
  estimatedHours: 'Tahmini Saat',

  // labels
  welcome: 'Hoş geldiniz',
  description: 'Bu hafta projelerinizdeki ilerlemeniz muhteşem. Devam edelim.',

  //detail
  changeStatus: 'Durumu Değiştir',
  generalInfoTitle: 'Genel Bilgiler',
  customerInfoTitle: 'Müşteri Bilgileri',
  contactPhoneNumber: 'Telefon Numarası',
  membersTitle: 'Üyeler',
  milestone: 'Dönüm Noktası',
  timeSheet: 'Zaman Çizelgesi',
  projectInfoTitle: 'Proje Bilgileri',
  billingInfoTitle: 'Fatura Bilgileri',
  statusChangeSuccess: 'Proje durumu başarıyla değiştirildi.',
  detail: 'Proje Detayları',
  noMember: 'Henüz üye yok.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Lütfen zaman çizelgeleri oluşturmak için projeye kullanıcı atayın.',
  emptyStateTimeSheetList1: 'Henüz bir zaman çizelgesi yok.',
  addTimeSheet: 'Zaman Çizelgesi Ekle',
  timeSheetCreate: 'Zaman Çizelgesi Oluştur',
  timeSheetUpdate: 'Zaman Çizelgesi Güncelle',
  member: 'Üye',
  startTime: 'Başlangıç Zamanı',
  endTime: 'Bitiş Zamanı',
  duration: 'Süre',
  timeSheetCreateSuccess: 'Zaman çizelgesi başarıyla oluşturuldu.',
  timeSheetUpdateSuccess: 'Zaman çizelgesi başarıyla güncellendi.',
  timeSheetDeleteSuccess: 'Zaman çizelgesi başarıyla silindi.',

  // milestone
  emptyStateMilestoneList1: 'Henüz bir dönüm noktası yok.',
  addMilestone: 'Dönüm Noktası Ekle',
  milestoneCreate: 'Dönüm Noktası Oluştur',
  milestoneUpdate: 'Dönüm Noktası Güncelle',
  milestoneCreateSuccess: 'Dönüm noktası başarıyla oluşturuldu.',
  milestoneUpdateSuccess: 'Dönüm noktası başarıyla güncellendi.',
  milestoneDeleteSuccess: 'Dönüm noktası başarıyla silindi.',
  addTask: 'Görev Ekle',
};

export default locale;
