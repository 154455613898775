/* eslint-disable react-hooks/exhaustive-deps */
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  DocumentTargetType,
  ITicketDetailResult,
  NoteTargetType,
  PermissionSubject,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { TicketDetailDrawerTabInfoComponent } from './TicketDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useTicketDocumentAddManyMutation,
  useTicketDocumentCreateManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { Iconify } from 'components';
import { PermissionAction } from 'corede-common';

export interface ITicketDetailDrawerRightPanelTabsComponentProps {
  ticketDetailData: ITicketDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const TicketDetailDrawerRightPanelTabsComponent = (
  props: ITicketDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  const icons = [
    <Iconify icon="fluent:info-32-regular" width={18} />,
    <Iconify icon="hugeicons:contact" width={18} />,
    <Iconify icon="stash:reading-time-solid" width={18} />,
    <Iconify icon="hugeicons:task-01" width={18} />,
    <Iconify icon="carbon:product" width={18} />,
    <Iconify icon="fluent:document-32-regular" width={18} />,
    <Iconify icon="iconoir:bell" width={18} />,
    <Iconify icon="tabler:activity" width={18} />,
  ] as React.ReactElement[];

  return (
    <DefaultTabsComponent<ITicketDetailResult> entityData={props.ticketDetailData} icons={icons}>
      <TicketDetailDrawerTabInfoComponent
        ticketDetailData={props.ticketDetailData}
        tabKey="info"
        name="crm.info"
      />

      <DocumentListTabComponent
        targetId={props.ticketDetailData?._id ?? ''}
        targetEntityRef={DocumentTargetType.Ticket}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useTicketDocumentCreateManyMutation}
        useDocumentAddManyMutation={useTicketDocumentAddManyMutation}
        permission={{
          subject: PermissionSubject.file,
          action: PermissionAction.list,
        }}
      />

      <NoteListTabComponent
        targetId={props.ticketDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Ticket}
        targetDisplayName={props.ticketDetailData?._id.toString() ?? ''}
        tabKey="notes"
        name="crm.notes"
        permission={{
          subject: PermissionSubject.note,
          action: PermissionAction.list,
        }}
      />

      <ReminderListTabComponent
        targetId={props.ticketDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Ticket}
        targetDisplayName={props.ticketDetailData?._id.toString() ?? ''}
        tabKey="reminders"
        name="crm.reminders"
        permission={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.list,
        }}
      />

      {/* <DefaultEmptyTabComponent
        title={t('crm.activities')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      /> */}
    </DefaultTabsComponent>
  );
};
