/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { IBaseError } from 'corede-common';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { ICustomerContactListItemResult } from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { useCustomerContactDeleteMutation } from '../../context/contact.api';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { useCustomerUpdateMutation } from '../../../customer';

const ContactDeleteDialog = (props: {
  open: boolean;
  item: TListGridSelectedEntity<ICustomerContactListItemResult>;
  customerId?: string;
  onClose: () => void;
}) => {
  // general
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();

  // queries

  // mutations
  const [
    customerContactDelete,
    {
      data: customerContactDeleteData,
      isLoading: customerContactDeleteLoading,
      error: customerContactDeleteError,
    },
  ] = useCustomerContactDeleteMutation();

  // constants

  // action handlers
  const handleDelete = async (_id: string) => {
    if (props.item?.isPrimary) {
      props?.onClose();
      enqueueSnackbar(t('crm.customer.contact.deletePrimaryError'), {
        variant: 'error',
      });
    } else {
      await customerContactDelete({
        input: {
          contactId: _id,
          customerId: props.customerId ?? props.item?.customer?._id ?? '',
        },
      });
    }
  };

  // useEffects.success
  useEffect(() => {
    if (customerContactDeleteData) {
      props?.onClose();
      enqueueSnackbar(t('crm.customer.contact.deletedSuccessfully'), {
        variant: 'success',
      });
    }
  }, [customerContactDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerContactDeleteError as IBaseError, currentLanguage);
  }, [customerContactDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <Dialog
      open={props?.open}
      onClose={props?.onClose}
      sx={{
        '& .MuiDialog-paper': {
          padding: 1,
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>{t('crm.customer.contact.delete')}</DialogTitle>
      <DialogContent>
        <Typography>{t('crm.customer.contact.deleteConfirm')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props?.onClose} color="primary">
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="error"
          disabled={customerContactDeleteLoading}
          onClick={() => {
            handleDelete(props?.item?._id ?? '');
          }}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactDeleteDialog;
