/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Box, Button, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
import { IContractDetailResult, PermissionSubject } from 'corede-common-cocrm';
import { ContractDetailDrawerLeftPanelHeaderTagsComponent } from './ContractDetailDrawer-leftPanel.header.tags.component';
import { ContractDetailDrawerLeftPanelActionsComponent } from './ContractDetailDrawer-leftPanel-header-actions.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';
import { ContractRenewDialog } from '../ContractRenewDialog';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { PermissionAction } from 'corede-common';

export interface IContractDetailDrawerLeftPanelHeaderComponentProps {
  contractDetailData: IContractDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerMenu = (props: IContractDetailDrawerLeftPanelHeaderComponentProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const [openConvertForm, setOpenConvertForm] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <PermissionWrapper
        check={{
          subject: PermissionSubject.contract,
          action: PermissionAction.update,
        }}
      >
        <Button
          id="contract-detail-button"
          aria-controls={open ? 'contract-detail-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          variant="outlined"
          color="secondary"
          sx={{
            position: downMd ? 'relative' : 'absolute',
            top: 0,
            right: 0,
            height: 24,
            width: 24,
            minWidth: 0,
            borderRadius: '50%',
            padding: 0,
          }}
        >
          <Icons.MoreVert />
        </Button>
        <Menu
          id="contract-detail-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'contract-detail-button',
          }}
        >
          <MenuItem onClick={() => setOpenConvertForm(true)}>
            <Icons.Refresh sx={{ mr: 1 }} />
            <Typography fontSize={'small'} fontWeight={'bold'}>
              {t('crm.contract.contract.contractRenew')}
            </Typography>
          </MenuItem>
        </Menu>

        <ContractRenewDialog
          contractDetailData={props.contractDetailData}
          openConvertForm={openConvertForm}
          setOpen={setOpenConvertForm}
          setOpenConvertForm={setOpenConvertForm}
        />
      </PermissionWrapper>
    </Stack>
  );
};

/**
 * Container for views in left panel header
 */
export const ContractDetailDrawerLeftPanelHeaderComponent = (
  props: IContractDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      justifyContent={{ xs: 'space-between', lg: 'flex-start' }}
      gap={1}
      sx={{ width: '100%' }}
    >
      {!downMd && (
        <Box
          sx={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <DrawerMenu contractDetailData={props.contractDetailData} setOpen={props.setOpen} />
        </Box>
      )}

      {/* TAGS */}
      {!downMd && (
        <ContractDetailDrawerLeftPanelHeaderTagsComponent
          status={props.contractDetailData?.status}
        />
      )}

      {/* Contract Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.contractDetailData?.title ?? ''}
      </Typography>

      {!downMd && (
        <ContractDetailDrawerLeftPanelActionsComponent
          contractDetailData={props.contractDetailData}
          setOpen={props.setOpen}
        />
      )}

      {downMd && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap:2 }}>
          <ContractDetailDrawerLeftPanelActionsComponent
            contractDetailData={props.contractDetailData}
            setOpen={props.setOpen}
          />
          <DrawerMenu contractDetailData={props.contractDetailData} setOpen={props.setOpen} />
        </Box>
      )}
    </Stack>
  );
};
