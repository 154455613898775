import { Stack, Box, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { useState, useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Language } from 'corede-common';

interface ICustomDonutChartItem {
  name: string;
  value1: number;
  value2Format: {
    count: number;
    prefix: string;
    suffix: string;
  };
}

interface ICustomDonutChart {
  data: ICustomDonutChartItem[];
  title: string;
  subtitle: string;
  theme: string;
  colors: string[];
  flexBasis: string;
  totalValue: number;
  language: Language;
}

const CustomDonutChart = memo((props: ICustomDonutChart) => {
  const theme = useTheme();
  const palette = theme.palette as Record<string, any>;
  const hexColor = palette[props.theme].lighter;
  const selectedColor = palette[props.theme].main;
  const zeroValueColor = theme.palette.grey[300]; // Slightly darker color for zero values
  const { t } = useTranslation();

  const [activeIndex, setActiveIndex] = useState(1);

  const defaultData = [
    { name: '', value1: 1, value2Format: { count: 0, prefix: '', suffix: '' }, fill: hexColor }
  ];

  const updatedData = props.data.length === 0
    ? defaultData
    : props.data.map((item, index) => ({
        ...item,
        value1: item.value1 === 0 ? 0.1 : item.value1,
        fill: item.value1 === 0 ? zeroValueColor : (index === activeIndex ? selectedColor : hexColor),
      }));

  const handlePieClick = useCallback((_: any, index: number) => {
    setActiveIndex(index);
  }, []);

  const displayValue = (value: number) => (value === 0.1 ? 0 : value);

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const nameLabel = props.language === 'tr' ? 'İsim' : 'Name';
      const valueLabel = props.language === 'tr' ? 'Değer' : 'Value';
      const noCategoryLabel = props.language === 'tr' ? 'Kategori Yok' : 'No Category';
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 10,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {`${nameLabel}: ${payload[0].payload.name || noCategoryLabel}`}
          </Typography>
          <Typography variant="body2">{`${valueLabel}: ${displayValue(payload[0].value)}`}</Typography>
        </Box>
      );
    }
    return null;
  };

  const displayCount = isNaN(props.data[activeIndex]?.value2Format.count)
    ? 0
    : props.data[activeIndex]?.value2Format.count;

  return (
    <Stack
      direction={'column'}
      gap={1}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={props.flexBasis}
      width={'100%'}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {props.subtitle}
        </Typography>
      </Box>

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {props.data[activeIndex]?.value1}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '6px',
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: 400, color: `${props.theme}.main`, px: 1 }}
            >
              {props.data[activeIndex]?.value2Format.prefix}
              {displayCount}
              {props.data[activeIndex]?.value2Format.suffix}
            </Typography>
          </Box>
        </Box>

        <Stack
          position={'relative'}
          direction={'row'}
          justifyContent={'center'}
          alignItems={'flex-end'}
        >
          <ResponsiveContainer width={200} height={160}>
            <PieChart>
              <Tooltip content={<CustomTooltip />} />
              <Pie
                data={updatedData}
                innerRadius={60}
                outerRadius={80}
                dataKey="value1"
                onClick={handlePieClick}
              >
                {updatedData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index === activeIndex ? selectedColor : props.colors[index]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          <Box
            position={'absolute'}
            top={'50%'}
            left={'50%'}
            width={'100%'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              transform: 'translate(-50%, -50%)',
              mb: 2,
              zIndex: -1,
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {props.totalValue}
            </Typography>

            <Typography
              variant="caption"
              sx={{ fontWeight: 400, color: `${props.theme}.main`, px: 1 }}
            >
              {t('crm.total')}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
});

export default CustomDonutChart;
