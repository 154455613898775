/* eslint-disable react-hooks/exhaustive-deps */
import { Box, SwipeableDrawer } from '@mui/material';
import { memo } from 'react';
import { RoleList } from './RoleList';

interface IRoleListDrawer {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const RoleListDrawer = memo((props: IRoleListDrawer) => {
  return (
    <SwipeableDrawer
      open={props.open}
      onOpen={() => props.setOpen(true)}
      onClose={() => props.setOpen(false)}
      anchor="right"
      sx={{
        zIndex: 1201,
      }}
    >
      <Box sx={{ width: 740 }}>
        <RoleList />
      </Box>
    </SwipeableDrawer>
  );
});
