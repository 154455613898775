/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, useMediaQuery } from '@mui/material';
import { IExpenseDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { ExpenseDetailDrawerLeftPanelHeaderComponent } from './ExpenseDetailDrawer-leftPanel-header.component';
import { ExpenseDetailDrawerLeftPanelGeneralInfoComponent } from './ExpenseDetailDrawer-leftPanel-generalInfo.component';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface IExpenseDetailDrawerLeftPanelComponentProps {
  expenseDetailData: IExpenseDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ExpenseDetailDrawerLeftPanelComponent = (
  props: IExpenseDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <DefaultDetailDrawerLeftPanelComponent<IExpenseDetailResult>
      entityData={props.expenseDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <ExpenseDetailDrawerLeftPanelHeaderComponent
        expenseDetailData={props.expenseDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <ExpenseDetailDrawerLeftPanelGeneralInfoComponent
        expenseDetailData={props.expenseDetailData}
        setOpen={props.setOpen}
      />

      <Divider />

      {/* <Button
        variant="contained"
        color="secondary"
        sx={{
          width: { xs: '100%', sm: '200px', md: '200px', lg: '100%' },
          height: 32,
          fontSize: { xs: '13px', md: '13px' },
          lineHeight: 1,
          borderRadius: '24px',
        }}
        onClick={() => navigate(viewExpenseRoute(props.expenseDetailData?._id ?? ''))}
      >
        {t('crm.expense.expense.detail')}
      </Button> */}
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
