const locale = {
  leadForms: "Lead Form",
  title: "Lead Form",
  newForm: "Yeni Form",
  emptyStateDesc1: "Müşteri bilgilerini yakalamak için oluşturulan potansiyel müşteri formlarınız burada görünecektir.",
  emptyStateDesc2: "Her etkileşim için doğru bilgileri topladığınızdan emin olmak için formlarınızı kolayca yönetip özelleştirebilirsiniz.",
  
  create: 'Lead Formu Oluştur',
  update: 'Lead Formunu Güncelle',
  updateSuccess: 'Lead formu başarıyla güncellendi',
  createSuccess: 'Lead formu başarıyla oluşturuldu',
  deleteSuccess: 'Lead formu başarıyla silindi',
  deleteConfirm: 'Bu lead formunu silmek istediğinizden emin misiniz?',
  deletedSuccessfully: 'Lead formu başarıyla silindi',

  // tabs
  detailedInfo: 'Detaylı Bilgi',
  branding: 'Markalama',
  formFields: 'Form Alanları',
  submission: 'Gönderim*',
  notification: 'Bildirim',

  //fields
  addField: 'Alan Ekle',
  updateField: 'Alanı Güncelle',
  label: 'Etiket',
  formName: 'Form Adı',
  defaultLanguage: 'Varsayılan Dil',
  defaultPrefix: 'Varsayılan Önek',
  defaultStatus: 'Varsayılan Durum',
  defaultSource: 'Varsayılan Kaynak',
  defaultAssigned: 'Varsayılan Atanan',
  defaultBusinessType: 'Varsayılan İş Türü',
  defaultType: 'Varsayılan Tür',
  defaultQualification: 'Varsayılan Nitelik',
  defaultTags: 'Varsayılan Etiketler',
  submitButtonText: 'Gönder Butonu Metni',
  header: 'Başlık',
  isRequired: 'Gerekli',
  type: 'Tür',
  defaultIsPublic: 'Varsayılan Halka Açık',
  followUpRedirectUrl: 'Takip Yönlendirme URL\'si',
  followUpMessage: 'Takip Mesajı',
  specificUsersToNotify: 'Bildirim Gönderilecek Belirli Kullanıcılar',
  willNotify: 'Bildirim Gönderilecek',
  willNotifyResponsibleUsers: 'Sorumlu Kullanıcılara Bildirim Gönderilecek',
  leadField: 'Lead Alanı',
  placeholder: 'Yer Tutucu',
  fullNamePlaceholder: 'Tam adınızı girin',
  emailPlaceholder: 'E-postanızı girin',
  formTitle: 'Form',
};

export default locale;
