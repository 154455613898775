const locale = {
  expenses: 'Expenses',
  expense: 'Expense',
  expenseConversions: 'Expense Conversions',
  title: 'Expenses',
  create: 'Create Expense',
  delete: 'Delete Expense',
  update: 'Update Expense',
  deletedSuccessfully: 'Expense deleted successfully.',
  createSuccess: 'Expense created successfully.',
  updateSuccess: 'Expense updated successfully.',
  deleteConfirm: 'Are you sure you want to delete this expense? This action cannot be undone.',
  emptyState1: 'No expenses added yet.',
  emptyState2:
    'Store and manage all expense details here to streamline your invoicing and maintain clear agreements with your customers.',
  
  // labels
  id: "Expense ID",
  totalAmount: "Total Amount",
  totalTax: "Total Tax",
  isBillable: "Is Billable",
  isInvoiced: "Is Invoiced",

  // detailDrawer
  changeStatus: 'Change Status',
  generalInfoTitle: 'General Information',
  customerInfoTitle: 'Customer Information',
  expenseInfoTitle: 'Expense Information',
  projectInfoTitle: 'Project Information',
  projectName: 'Project Name',
  paymentMethod: 'Payment Method',
  };

export default locale;
