import {
  appointmentListQuery,
  IAppointmentListRequest,
  IAppointmentListInput,
  IAppointmentListResponse,
  IAppointmentListResult,
  appointmentListCountQuery,
  IAppointmentListCountRequest,
  IAppointmentListCountResponse,
  appointmentDetailQuery,
  IAppointmentDetailRequest,
  IAppointmentDetailInput,
  IAppointmentDetailResponse,
  IAppointmentDetailResult,
  appointmentCreateQuery,
  IAppointmentCreateRequest,
  IAppointmentCreateInput,
  IAppointmentCreateResponse,
  IAppointmentCreateResult,
  appointmentUpdateQuery,
  IAppointmentUpdateRequest,
  IAppointmentUpdateInput,
  IAppointmentUpdateResponse,
  IAppointmentUpdateResult,
  IAppointmentUpdateFilterInput,
  appointmentDeleteQuery,
  IAppointmentDeleteRequest,
  IAppointmentDeleteInput,
  IAppointmentDeleteResponse,
  IAppointmentDeleteResult,
  appointmentUpdateStatusQuery,
  IAppointmentUpdateStatusRequest,
  IAppointmentUpdateStatusInput,
  IAppointmentUpdateStatusResponse,
  IAppointmentUpdateStatusResult,
  IAppointmentUpdateStatusFilterInput,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { appointmentTags } from './appointment.tags';
import { ICount, IGraphqlVariables } from 'corede-common';

export const appointmentsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    appointmentList: builder.query<
      IAppointmentListResult,
      IGraphqlVariables<IAppointmentListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentListRequest,
        IAppointmentListResponse,
        IAppointmentListResult,
        IAppointmentListInput
      >({
        query: appointmentListQuery(),
        providesTags: [appointmentTags.appointments, appointmentTags.appointment],
      }),
    ),

    // appointmentListCount: builder.query<
    //   IAppointmentListCountResponse,
    //   IGraphqlVariables<undefined>
    // >(
    //   EndpointQueryBuilder.BuildGraphqlQuery<
    //     IAppointmentListCountRequest,
    //     IAppointmentListCountResponse,
    //     ICount,
    //     undefined
    //   >({
    //     query: appointmentListCountQuery,
    //     providesTags: [appointmentTags.appointments],
    //   }),
    // ),

    appointmentDetail: builder.query<
      IAppointmentDetailResult,
      IGraphqlVariables<IAppointmentDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentDetailRequest,
        IAppointmentDetailResponse,
        IAppointmentDetailResult,
        IAppointmentDetailInput
      >({
        query: appointmentDetailQuery(),
        providesTags: [appointmentTags.appointment],
      }),
    ),

    // mutation
    appointmentCreate: builder.mutation<
      IAppointmentCreateResult,
      IGraphqlVariables<IAppointmentCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentCreateRequest,
        IAppointmentCreateResponse,
        IAppointmentCreateResult,
        IAppointmentCreateInput
      >({
        query: appointmentCreateQuery,
        invalidatesTags: [appointmentTags.appointments],
      }),
    ),

    appointmentUpdate: builder.mutation<
      IAppointmentUpdateResult,
      IGraphqlVariables<IAppointmentUpdateInput, IAppointmentUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentUpdateRequest,
        IAppointmentUpdateResponse,
        IAppointmentUpdateResult,
        IAppointmentUpdateInput,
        IAppointmentUpdateFilterInput
      >({
        query: appointmentUpdateQuery,
        invalidatesTags: [appointmentTags.appointments, appointmentTags.appointment],
      }),
    ),

    appointmentDelete: builder.mutation<
      IAppointmentDeleteResult,
      IGraphqlVariables<IAppointmentDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentDeleteRequest,
        IAppointmentDeleteResponse,
        IAppointmentDeleteResult,
        IAppointmentDeleteInput
      >({
        query: appointmentDeleteQuery,
        invalidatesTags: [appointmentTags.appointments],
      }),
    ),

    appointmentUpdateStatus: builder.mutation<
      IAppointmentUpdateStatusResult,
      IGraphqlVariables<IAppointmentUpdateStatusInput, IAppointmentUpdateStatusFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IAppointmentUpdateStatusRequest,
        IAppointmentUpdateStatusResponse,
        IAppointmentUpdateStatusResult,
        IAppointmentUpdateStatusInput,
        IAppointmentUpdateStatusFilterInput
      >({
        query: appointmentUpdateStatusQuery,
        invalidatesTags: [appointmentTags.appointments, appointmentTags.appointment],
      }),
    ),
  }),
});

export const {
  useAppointmentListQuery,
  useLazyAppointmentListQuery,
  // useAppointmentListCountQuery,
  // useLazyAppointmentListCountQuery,
  useAppointmentDetailQuery,
  useLazyAppointmentDetailQuery,
  useAppointmentCreateMutation,
  useAppointmentUpdateMutation,
  useAppointmentDeleteMutation,
  useAppointmentUpdateStatusMutation,
} = appointmentsApi;
