import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';
import * as Yup from 'yup';

export const validateCreateLeadStatusInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    index: ValidationHelper.RequiredNumber(getTranslatedText('isRequired'))
      .min(1, getTranslatedText('minValue0'))
      .typeError(getTranslatedText('mustBeNumber')),
    name: ValidationHelper.RequiredString(getTranslatedText('isRequired')),
  }),
});
