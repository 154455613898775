/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, memo, SetStateAction, useEffect, useMemo, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import {
  IProjectMilestoneCreateFilterInput,
  IProjectMilestoneCreateInput,
} from 'corede-common-cocrm';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useProjectMilestoneCreateMutation } from '../../../context/project.api';
import { validateCreateMilestoneInput } from '../../../validations/create.validation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertMilestoneComponent } from '../UpsertMilestone.component';
import ActionDrawer from 'components/drawer/ActionDrawer';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';

export interface IMilestoneCreateOverlay {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  overlayType: OverlayType;
  projectId: string;
}

const MilestoneCreateOverlay = memo((props: IMilestoneCreateOverlay) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries

  // mutations
  const [
    milestoneCreate,
    { data: milestoneCreateData, isLoading: milestoneCreateLoading, error: milestoneCreateError },
  ] = useProjectMilestoneCreateMutation();

  // constants
  const initialValues = useMemo<
    IGraphqlVariables<IProjectMilestoneCreateInput, IProjectMilestoneCreateFilterInput>
  >(
    () => ({
      filter: {
        projectId: props.projectId,
      },
      input: {
        name: '',
        description: undefined,
        startDate: new Date(),
        dueDate: new Date(),
      },
    }),
    [],
  );

  // form setup
  const milestoneCreateUseForm = useForm<
    IGraphqlVariables<IProjectMilestoneCreateInput, IProjectMilestoneCreateFilterInput>
  >({
    defaultValues: initialValues,
    resolver: yupResolver(validateCreateMilestoneInput),
    mode: 'onChange',
  });

  const onSubmit = async (
    values: IGraphqlVariables<IProjectMilestoneCreateInput, IProjectMilestoneCreateFilterInput>,
  ) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values,
    });
    await milestoneCreate(
      transformedValues as IGraphqlVariables<
        IProjectMilestoneCreateInput,
        IProjectMilestoneCreateFilterInput
      >,
    );
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (milestoneCreateData) {
      enqueueSnackbar(t('crm.project.project.milestoneCreateSuccess'), {
        variant: 'success',
      });
      milestoneCreateUseForm.reset();
      props.setOpen(false);
    }
  }, [milestoneCreateData, milestoneCreateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(milestoneCreateError as IBaseError, currentLanguage);
  }, [milestoneCreateError]);

  const commonContent = (
    <UpsertMilestoneComponent
      open={props.open}
      setOpen={props.setOpen}
      loading={milestoneCreateLoading || loading}
      useForm={milestoneCreateUseForm}
    />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDrawer
          open={props.open}
          setOpen={props.setOpen}
          size="medium"
          title={t('crm.project.project.milestoneCreate')}
          handleSubmit={milestoneCreateUseForm.handleSubmit(onSubmit)}
          disabled={milestoneCreateLoading || loading || !milestoneCreateUseForm.formState.isValid}
          loading={milestoneCreateLoading || loading}
          buttonTitle={t('crm.create')}
        >
          {commonContent}
        </ActionDrawer>
      );

    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.project.project.milestoneCreate')}
          handleClick={milestoneCreateUseForm.handleSubmit(onSubmit)}
          disabled={milestoneCreateLoading || loading || !milestoneCreateUseForm.formState.isValid}
          loading={milestoneCreateLoading || loading}
          buttonTitle={t('crm.create')}
          width={500}
        >
          {commonContent}
        </ActionDialog>
      );

    default:
      return null;
  }
});

export default MilestoneCreateOverlay;
