/* eslint-disable react-hooks/exhaustive-deps */
import * as Icons from '@mui/icons-material';
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { FilledButton } from 'components';
import { NameFactory } from 'corede-common';
import { ITaskDetailResult } from 'corede-common-cocrm';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { TaskDetailDrawerLeftPanelHeaderComponent } from './TaskDetailDrawer-leftPanel-header.component';
import { TaskDetailDrawerLeftPanelContactDetailComponent } from './TaskDetailDrawer-leftPanel-contactDetail.component';
import { TaskDetailDrawerLeftPanelAssignedsComponent } from './TaskDetailDrawer-leftPanel-assigned.component';

export interface ITaskDetailDrawerLeftPanelComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TaskDetailDrawerLeftPanelComponent = (
  props: ITaskDetailDrawerLeftPanelComponentProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  return (
    <DefaultDetailDrawerLeftPanelComponent<ITaskDetailResult>
      entityData={props.taskDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <TaskDetailDrawerLeftPanelHeaderComponent
        taskDetailData={props.taskDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <TaskDetailDrawerLeftPanelContactDetailComponent
        taskDetailData={props.taskDetailData}
        setOpen={props.setOpen}
      />

      {/* Assigned List */}
      <TaskDetailDrawerLeftPanelAssignedsComponent
        taskDetailData={props.taskDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
