import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useOrganizationSettingsDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';
import { useAppSelector } from 'context';
import { PermissionSubject } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import moment from 'moment';
import { usePermissions } from 'permission/PermissionContext';
import { memo, useEffect } from 'react';
import { LicenseInfo } from '@mui/x-license';
import { Language } from 'corede-common';

interface IInitializationProviderProps {
  children: React.ReactNode;
}

export const InitializationProvider = memo((props: IInitializationProviderProps) => {
  const { accessToken } = useAppSelector((state) => state.auth);
  const currentLanguage = getCurrentLanguage();

  const { hasPermission } = usePermissions();
  const hasOrganizationSettingsPermission = hasPermission({
    subject: PermissionSubject.organizationSettings,
  });

  const { data: userDetailOwnData } = useUserDetailOwnQuery({}, { skip: !accessToken });

  const { data: organizationSettingsData } = useOrganizationSettingsDetailOwnQuery(
    {},
    {
      skip: !hasOrganizationSettingsPermission || !userDetailOwnData?.organization || !accessToken,
    },
  );

  // initialize mui premium
  LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_KEY}`);

  useEffect(() => {
    if (currentLanguage === Language.tr) {
      moment.locale('tr');
    } else {
      moment.locale('en-gb');
    }

    moment.defaultFormat = organizationSettingsData?.common?.dateFormat || 'DD.MM.YYYY';
  }, [currentLanguage, organizationSettingsData]);

  return <>{props.children}</>;
});
