import * as Icons from '@mui/icons-material';
import { Stack, Box, Typography, Divider, Button, useMediaQuery, Theme } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IReminder,
  IReminderDetailResult,
  PermissionSubject,
  ReminderPriority,
  ReminderStatus,
  ReminderTargetType,
} from 'corede-common-cocrm';
import { t } from 'i18next';
import { useState } from 'react';
import {
  representDateAsString,
  representDateTimeAsString,
  representString,
} from 'utils/representationHelper';
import { useReminderListQuery, useReminderUpdateMutation } from '../context/reminder.api';
import { ReminderDeleteDialog } from '../pages/ReminderDeleteDialog';
import ReminderUpdateDialog from '../pages/update/ReminderUpdateOverlay.wrapper';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { IEntity, IHasId, PermissionAction } from 'corede-common';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import ReminderCreateDrawerWrapper from '../pages/create/ReminderCreateOverlay.wrapper';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { OverlayType } from 'components/dialog/ActionDialog';
import ReminderDetailDrawerWrapper from '../pages/detailDrawer/ReminderDetailDrawer';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import ReminderListItemComponent from './ReminderListItem';
import { AddCircle } from '@mui/icons-material';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IReminderListTabSectionComponent extends DefaultTabComponentProps<IEntity> {
  targetId: string;
  targetDisplayName: string;
  targetEntityRef: ReminderTargetType;
  tabKey: 'reminders';
  name: 'crm.reminders';
  overlayType?: OverlayType;
}

const ReminderListTabComponent = (props: IReminderListTabSectionComponent) => {
  const [openReminderDetailDrawer, setOpenReminderDetailDrawer] = useState(false);
  const [openReminderCreateDialog, setOpenReminderCreateDialog] = useState(false);
  const [openReminderUpdateDialog, setOpenReminderUpdateDialog] = useState(false);
  const [openReminderDeleteDialog, setOpenReminderDeleteDialog] = useState(false);
  const [selectedReminder, setSelectedReminder] = useState<TListGridSelectedEntity<IReminder>>();

  console.debug('loaded: ReminderCreateDrawer');

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.delete,
  });

  const { data: reminderListData, isLoading: reminderListLoading } = useReminderListQuery(
    {
      input: {
        filter: {
          targetIds: [props.targetId],
          targetTypes: [props.targetEntityRef],
        },
        pagination: {
          sort: {
            remindDate: 1,
          },
        },
      },
    },
    {
      skip: !props.targetId,
    },
  );
  const [reminderUpdate] = useReminderUpdateMutation();

  return (
    <DefaultTabContainer
      title={''}
      rightButton={
        reminderListData && reminderListData?.count > 0
          ? {
              title: t('crm.addReminder'),
              onClick: () => setOpenReminderCreateDialog(true),
              icon: <AddCircle sx={{ mr: 1 }} />,
              permission: {
                subject: PermissionSubject.reminder,
                action: PermissionAction.create,
              },
            }
          : undefined
      }
    >
      {reminderListData && reminderListData?.count === 0 && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no reminder yet."
            />
          }
          content1={t('crm.emptyStateReminderList1')}
          button1={{
            leftIcon: <AddCircle sx={{ mr: 1 }} fontSize="small" />,
            title: t('crm.addReminder'),
            onClick: () => setOpenReminderCreateDialog(true),
            permission: {
              subject: PermissionSubject.reminder,
              action: PermissionAction.create,
            },
          }}
        />
      )}

      {reminderListData && reminderListData?.count > 3 && (
        <BaseDataGrid
          rows={reminderListData?.data?.map((reminder, index) => ({
            id: index + 1,
            _id: reminder?._id,
            title: representString(reminder?.title),
            content: representString(reminder?.content),
            priority: reminder?.priority,
            reminderDate: representDateTimeAsString(reminder?.remindDate),
            remindDate: reminder?.remindDate,
            remindUsers: reminder?.remindUsers,
            status: reminder?.status,
            tags: reminder?.tags,
            target: reminder?.target,
            targetType: reminder?.targetType,
            createdAt: representDateAsString(reminder?.createdAt),
          }))}
          columns={[
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'id',
                  disableColumnMenu: true,
                  filterable: false,
                  width: 40,
                  align: 'center',
                },
                headerConfig: {
                  name: '#',
                  align: 'center',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'title',
                  editable: false,
                  filterable: false,
                },
                headerConfig: {
                  name: t('crm.title'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.enum,
                column: {
                  field: 'priority',
                  editable: hasUpdatePermission,
                  filterable: false,
                  width: 90,
                },
                headerConfig: {
                  name: t('crm.priority'),
                  creatable: false,
                },
              },
              data: ReminderPriority,
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'reminderDate',
                  filterable: false,
                  editable: false,
                  width: 120,
                },
                headerConfig: {
                  name: t('crm.remindDate'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.enum,
                column: {
                  field: 'status',
                  editable: hasUpdatePermission,
                  filterable: false,
                  width: 120,
                },
                headerConfig: {
                  name: t('crm.status'),
                  creatable: false,
                },
              },
              data: ReminderStatus,
            },
          ]}
          actionColumn={{
            width: 70,
            defaultActions: {
              view: hasDetailPermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedReminder,
                      setOpenAction: setOpenReminderDetailDrawer,
                    },
                  }
                : undefined,
              edit: hasUpdatePermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedReminder,
                      setOpenAction: setOpenReminderUpdateDialog,
                    },
                  }
                : undefined,
              delete: hasDeletePermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedReminder,
                      setOpenAction: setOpenReminderDeleteDialog,
                    },
                  }
                : undefined,
            },
            actionHeaderName: t('crm.actions'),
          }}
          loading={reminderListLoading}
          listFilter={{
            filterInput: {},
            setFilterInput: () => null,
          }}
          count={reminderListData?.count}
          config={{
            columnVisibilityModel: {},
            features: [],
          }}
          update={{
            updateQuery: hasUpdatePermission ? reminderUpdate : undefined,
          }}
          disableColumnFilter={true}
          hideFooterPagination={true}
        />
      )}

      {reminderListData && reminderListData?.count > 0 && reminderListData?.count < 4 && (
        <Stack direction={'column'} gap={1.5} width={'100%'}>
          {reminderListData?.data?.map((reminder, index) => {
            return (
              <ReminderListItemComponent
                key={index}
                reminder={reminder}
                setSelectedReminder={setSelectedReminder}
                setOpenReminderUpdateDialog={setOpenReminderUpdateDialog}
                setOpenReminderDeleteDialog={setOpenReminderDeleteDialog}
              />
            );
          })}
        </Stack>
      )}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.delete,
        }}
      >
        <ReminderDetailDrawerWrapper
          open={openReminderDetailDrawer}
          setOpen={setOpenReminderDetailDrawer}
          selectedReminder={selectedReminder as IReminderDetailResult & IHasId<number>}
          setSelectedReminder={setSelectedReminder}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.create,
        }}
      >
        <ReminderCreateDrawerWrapper
          open={openReminderCreateDialog}
          setOpen={setOpenReminderCreateDialog}
          targetId={props.targetId}
          targetType={props.targetEntityRef}
          overlayType={props.overlayType ?? OverlayType.dialog}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.update,
        }}
      >
        <ReminderUpdateDialog
          open={openReminderUpdateDialog}
          setOpen={setOpenReminderUpdateDialog}
          reminderId={selectedReminder?._id ?? ''}
          overlayType={props.overlayType ?? OverlayType.dialog}
        />
      </PermissionWrapper>

      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.delete,
        }}
      >
        <ReminderDeleteDialog
          open={openReminderDeleteDialog}
          onClose={() => {
            setSelectedReminder(undefined);
            setOpenReminderDeleteDialog(false);
          }}
          reminderId={selectedReminder?._id ?? ''}
          setReminder={setSelectedReminder}
        />
      </PermissionWrapper>
    </DefaultTabContainer>
  );
};

export default ReminderListTabComponent;
