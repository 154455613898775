const locale = {
  appointments: "Randevular",
  appointment: "Randevu",
  appointmentConversions: "Randevu Dönüştürme",
  title: "Randevular",
  addAppointment: "Randevu Ekle",
  emptyState1: "Henüz herhangi bir randevu planlanmadı.",
  emptyState2: "Düzenli kalmak ve müşterilerinizle sorunsuz bir şekilde bağlantı kurmak için randevularınızı burada oluşturun ve yönetin.",
  create: "Randevu Oluştur",
  update: "Randevu Güncelle",
  delete: "Randevu Sil",
  details: "Randevu Detayları",
  createSuccess: "Randevu başarıyla oluşturuldu.",
  updateSuccess: "Randevu başarıyla güncellendi.",
  statusChangeSuccess: "Randevu durumu başarıyla değiştirildi.",
  deleteConfirm: "Bu randevuyu silmek istediğinizden emin misiniz?",
  deletedSuccessfully: "Randevu başarıyla silindi.",

  //fields
  startTime: "Başlangıç Saati",
  endTime: "Bitiş Saati",
  timezone: "Zaman Dilimi",
  location: "Konum",
  organizer: "Organizatör",
  targetType: "Hedef Türü",
  attendees: "Katılımcılar",
  category: "Kategori",
  meetingLink: "Toplantı Bağlantısı",
  duration: "Süre",

  //detail drawer
  changeStatus: "Durumu Değiştir",
  generalInfoTitle: "Genel Bilgiler",
  organizerInfoTitle: "Organizatör Bilgileri",
  appointmentInfoTitle: "Randevu Bilgileri",
};

export default locale;



