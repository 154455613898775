import {
  ICustomerListInput,
  ICustomerListResult,
  ICustomerListResponse,
  ICustomerListRequest,
  customerListQuery,
  ICustomerListByRelatedInput,
  ICustomerListByRelatedResult,
  ICustomerListByRelatedResponse,
  ICustomerListByRelatedRequest,
  customerListByRelatedQuery,
  ICustomerListCountRequest,
  ICustomerListCountResponse,
  customerListCountQuery,
  ICustomerDetailInput,
  ICustomerDetailResult,
  ICustomerDetailResponse,
  ICustomerDetailRequest,
  customerDetailQuery,
  // statistics
  ICustomerStatisticsInput,
  ICustomerStatisticsRequest,
  ICustomerStatisticsResponse,
  ICustomerStatisticsResult,
  customerStatisticsQuery,
  ICustomerCreateInput,
  ICustomerCreateResult,
  ICustomerCreateResponse,
  ICustomerCreateRequest,
  customerCreateQuery,
  ICustomerUpdateInput,
  ICustomerUpdateResult,
  ICustomerUpdateResponse,
  ICustomerUpdateRequest,
  customerUpdateQuery,
  ICustomerUpdateFilterInput,
  ICustomerDeleteInput,
  ICustomerDeleteResult,
  ICustomerDeleteResponse,
  ICustomerDeleteRequest,
  customerDeleteQuery,
  ICustomerEstimateListResult,
  ICustomerEstimateListInput,
  ICustomerEstimateListRequest,
  ICustomerEstimateListResponse,
  customerEstimateListQuery,
  customerImageCreateQuery,
  ICustomerImageCreateRequest,
  ICustomerImageCreateResponse,
  customerImageAssignQuery,
  ICustomerImageAssignRequest,
  ICustomerImageAssignResponse,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { customersTags } from './customer.tags';
import {
  ICount,
  IGraphqlVariables,
  IDomainFileCreateOwnInputWithMimeType,
  IDomainFileCreateResult,
  IEntityFileAssignInput,
  IEntityFileAssignResult,
} from 'corede-common';
import { contactsTags } from '../../contact';

export const customersApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    customerList: builder.query<ICustomerListResult, IGraphqlVariables<ICustomerListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerListRequest,
        ICustomerListResponse,
        ICustomerListResult,
        ICustomerListInput
      >({
        query: customerListQuery,
        providesTags: [customersTags.customers, customersTags.customer],
      }),
    ),

    customerListByRelated: builder.query<
      ICustomerListByRelatedResult,
      IGraphqlVariables<ICustomerListByRelatedInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerListByRelatedRequest,
        ICustomerListByRelatedResponse,
        ICustomerListByRelatedResult,
        ICustomerListByRelatedInput
      >({
        query: customerListByRelatedQuery,
        providesTags: [customersTags.customers, customersTags.customer],
      }),
    ),

    customerListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerListCountRequest,
        ICustomerListCountResponse,
        ICount,
        undefined
      >({
        query: customerListCountQuery,
        providesTags: [customersTags.customers],
      }),
    ),

    customerDetail: builder.query<ICustomerDetailResult, IGraphqlVariables<ICustomerDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerDetailRequest,
        ICustomerDetailResponse,
        ICustomerDetailResult,
        ICustomerDetailInput
      >({
        query: customerDetailQuery,
        providesTags: [customersTags.customers, customersTags.customer],
      }),
    ),

    customerEstimateList: builder.query<
      ICustomerEstimateListResult,
      IGraphqlVariables<ICustomerEstimateListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerEstimateListRequest,
        ICustomerEstimateListResponse,
        ICustomerEstimateListResult,
        ICustomerEstimateListInput
      >({
        query: customerEstimateListQuery(),
        providesTags: [customersTags.customers, customersTags.customer],
      }),
    ),

    customerStatistics: builder.query<
      ICustomerStatisticsResult,
      IGraphqlVariables<ICustomerStatisticsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerStatisticsRequest,
        ICustomerStatisticsResponse,
        ICustomerStatisticsResult,
        ICustomerStatisticsInput
      >({
        query: customerStatisticsQuery,
        providesTags: [customersTags.customer, customersTags.customers],
      }),
    ),

    // mutation

    customerCreate: builder.mutation<
      ICustomerCreateResult,
      IGraphqlVariables<ICustomerCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCreateRequest,
        ICustomerCreateResponse,
        ICustomerCreateResult,
        ICustomerCreateInput
      >({
        query: customerCreateQuery,
        invalidatesTags: [customersTags.customers, customersTags.customer],
      }),
    ),

    customerUpdate: builder.mutation<
      ICustomerUpdateResult,
      IGraphqlVariables<ICustomerUpdateInput, ICustomerUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerUpdateRequest,
        ICustomerUpdateResponse,
        ICustomerUpdateResult,
        ICustomerUpdateInput,
        ICustomerUpdateFilterInput
      >({
        query: customerUpdateQuery,
        invalidatesTags: [
          customersTags.customers,
          customersTags.customer,
          contactsTags.customerContactTags,
        ],
      }),
    ),

    customerDelete: builder.mutation<
      ICustomerDeleteResult,
      IGraphqlVariables<ICustomerDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerDeleteRequest,
        ICustomerDeleteResponse,
        ICustomerDeleteResult,
        ICustomerDeleteInput
      >({
        query: customerDeleteQuery,
        invalidatesTags: [customersTags.customers],
      }),
    ),

    customerImageCreate: builder.mutation<
      IDomainFileCreateResult,
      IGraphqlVariables<IDomainFileCreateOwnInputWithMimeType>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerImageCreateRequest,
        ICustomerImageCreateResponse,
        IDomainFileCreateResult,
        IDomainFileCreateOwnInputWithMimeType
      >({
        query: customerImageCreateQuery,
      }),
    ),

    customerImageAssign: builder.mutation<
      IEntityFileAssignResult,
      IGraphqlVariables<IEntityFileAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerImageAssignRequest,
        ICustomerImageAssignResponse,
        IEntityFileAssignResult,
        IEntityFileAssignInput
      >({
        query: customerImageAssignQuery,
        invalidatesTags: [customersTags.customer],
      }),
    ),
  }),
});

export const {
  useCustomerListQuery,
  useLazyCustomerListQuery,
  useCustomerListByRelatedQuery,
  useLazyCustomerListByRelatedQuery,
  useCustomerListCountQuery,
  useLazyCustomerListCountQuery,
  useCustomerDetailQuery,
  useLazyCustomerDetailQuery,
  useCustomerCreateMutation,
  useCustomerUpdateMutation,
  useCustomerDeleteMutation,
  useCustomerEstimateListQuery,
  useCustomerStatisticsQuery,
  useLazyCustomerStatisticsQuery,
  useCustomerImageCreateMutation,
  useCustomerImageAssignMutation,
} = customersApi;
