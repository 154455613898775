import { Grid } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { useInvoiceReturnListQuery } from '../../context/return.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ReturnListComponent from './ReturnList-grid.component';
import ReturnCreateWrapper from '../create/ReturnCreateDrawer.wrapper';
import { useLocation } from 'react-router-dom';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionAction } from 'corede-common';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const ReturnList = memo(() => {
  // general
  const { t } = useTranslation();
  const location = useLocation();
  const [openReturnCreateDrawer, setOpenReturnCreateDrawer] = useState(false);

  // queries
  const { data: returnListData } = useInvoiceReturnListQuery({});

  let totalReturnCount = returnListData?.count ?? 0;
  let isEmpty = totalReturnCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenReturnCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.sale.return.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.sale.return.create`),
          onClick: () => setOpenReturnCreateDrawer(true),
          permission: {
            subject: PermissionSubject.invoiceReturn,
            action: PermissionAction.create,
          },
        }}
      />

      <ReturnListComponent setOpenReturnCreateDrawer={setOpenReturnCreateDrawer} />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.invoiceReturn,
          action: PermissionAction.create,
        }}
      >
        <ReturnCreateWrapper open={openReturnCreateDrawer} setOpen={setOpenReturnCreateDrawer} />
      </PermissionWrapper>
    </Grid>
  );
});

export default ReturnList;
