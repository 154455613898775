/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { ITaskDetailResult } from 'corede-common-cocrm';
import { TaskDetailDrawerHeaderStatusComponent } from './TaskDetailDrawer-rightPanel-header-status.component';
// import { TaskDetailDrawerBaseInfoComponent } from './TaskDetailDrawer-rightPanel-header-baseInfo.component';

export interface ITaskDetailDrawerRightPanelHeaderComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const TaskDetailDrawerRightPanelHeaderComponent = (
  props: ITaskDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <TaskDetailDrawerHeaderStatusComponent taskDetailData={props.taskDetailData} />
      {/* <TaskDetailDrawerBaseInfoComponent taskDetailData={props.taskDetailData} /> */}
    </>
  );
};
