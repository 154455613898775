const locale = {
  demo: 'Demo',
  title: '<highlight>CoCRM</highlight> Community Nedir?',
  description:
    'CoCRM olarak; iş ve müşteri takip süreçlerinizi etkin yönetmenize sunduğumuz faydaya ek olarak network ve iş hacminizin genişlemesi için sunduğumuz bir iş birliği alanıdır.',
  featuresTitle: 'CoCRM Community ile birlikte;',
  features1: 'Referansla yeni iş fırsatları elde edebilir,',
  features2: 'Güvenilir tedarikçilere ulaşabilir,',
  features3: 'Güvenilir iş ortakları bulabilir,',
  features4: 'Networkünüzü artırarak müşterilerinize 360° hizmetler sunabilirsiniz.',
  buttonTitle: 'Community’e Katıl!',
  formTitle: 'Bekleme Listesine Katılın',
  formDescription: 'Lütfen aşağıdaki formu doldurun. En kısa sürede sizinle iletişime geçeceğiz.',
  sendSuccess: 'Teşekkürler! En kısa sürede sizinle iletişime geçeceğiz.',
  formButtonTitle: 'Katıl',
  fullName: 'Ad Soyad',
  email: 'E-posta',
  phone: 'Telefon',
  orgName: 'Organizasyon Adı',
};

export default locale;
