/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useProjectDeleteMutation } from "../context/project.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const ProjectDeleteDialog = memo(
  (props: {
    open: boolean;
    projectId: string;
    onClose: () => void;
    setProject: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      projectDelete,
      {
        data: projectDeleteData,
        isLoading: projectDeleteIsLoading,
        error: projectDeleteError,
      },
    ] = useProjectDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.projectId}
        setItem={props.setProject}
        deleteMutation={projectDelete}
        deleteMutationData={{
          data: projectDeleteData,
          isLoading: projectDeleteIsLoading,
          error: projectDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.delete"),
          successMessage: t("crm.project.project.deletedSuccessfully"),
          content: t("crm.project.project.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
