/* eslint-disable react-hooks/exhaustive-deps */
import { IReminderDetailResult } from 'corede-common-cocrm';
import { ReminderDetailDrawerHeaderStatusComponent } from './ReminderDetailDrawer-rightPanel-header-status.component';
// import { ReminderDetailDrawerBaseInfoComponent } from './ReminderDetailDrawer-rightPanel-header-baseInfo.component';

export interface IReminderDetailDrawerRightPanelHeaderComponentProps {
  reminderDetailData: IReminderDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ReminderDetailDrawerRightPanelHeaderComponent = (
  props: IReminderDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <ReminderDetailDrawerHeaderStatusComponent reminderDetailData={props.reminderDetailData} />
      {/* <ReminderDetailDrawerBaseInfoComponent reminderDetailData={props.reminderDetailData} /> */}
    </>
  );
};
