/* eslint-disable react-hooks/exhaustive-deps */
import { IExpenseDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { ExpenseDetailDrawerRightPanelHeaderComponent } from './ExpenseDetailDrawer-rightPanel-header.component';
import { ExpenseDetailDrawerRightPanelTabsComponent } from './ExpenseDetailDrawer-rightPanel.tabs.component';

export interface IExpenseDetailDrawerRightPanelComponentProps {
  expenseDetailData: IExpenseDetailResult | undefined;
}

export const ExpenseDetailDrawerRightPanelComponent = (
  props: IExpenseDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <ExpenseDetailDrawerRightPanelHeaderComponent expenseDetailData={props.expenseDetailData} />

      {/* Tabs Section */}
      <ExpenseDetailDrawerRightPanelTabsComponent expenseDetailData={props.expenseDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
