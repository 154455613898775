const locale = {
  leadStatuses: 'Lead Statuses',
  title: 'Lead Durumları',
  create: 'Lead Durumu Oluştur',
  delete: 'Lead Durumu Sil',
  deleteConfirm: 'Durumu silmek istediğinize emin misiniz: ',
  deletedSuccessfully: 'Durum başarıyla silindi!',
  update: 'Güncelle',
  updateStatus: 'Lead Durumu Güncelle',
  updateLeadStatusSuccess: 'Durum başarıyla güncellendi!',
  createLeadSuccess: 'Durum başarıyla eklendi!',
  selectColor: 'Renk Seçiniz',
  selectIcon: 'İkon Seçiniz',
};

export default locale;
