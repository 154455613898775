/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import ActionDialog from 'components/dialog/ActionDialog';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Check, Close, FileUpload } from '@mui/icons-material';
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import {
  FileMimeType,
  IDomainFileCreateResult,
  IDomainFilesCreateOwnInput,
  IEntityFilesAssignInput,
} from 'corede-common';
import { OutlinedButton } from 'components/buttons';
import {
  PNGIcon,
  JPGIcon,
  DOCIcon,
  MP4Icon,
  PDFIcon,
  PPTIcon,
  TXTIcon,
  XLSIcon,
  ZIPIcon,
  UPLOADIcon,
} from 'assets/images/fileIcons';
import { DocumentFolder } from 'corede-common-cocrm';

enum FileUploadStatus {
  notUploaded = 'notUploaded',
  failed = 'failed',
  success = 'success',
}

export enum FileTargetType {
  lead = 'lead',
  customer = 'customer',
  invoice = 'invoice',
  task = 'task',
}

export interface IFileData {
  id: number;
  file: File;
  uploadProgress: number;
  status: FileUploadStatus;
}

export const UploadDocumentComponent = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  useDocumentCreateManyMutation: any;
  useDocumentAddManyMutation: any;
  entityId: string;
  targetList?: [];
}) => {
  const { t } = useTranslation();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [currentFileDataId, setCurrentFileDataId] = useState(0);
  const [fileDataList, setFileDataList] = useState<IFileData[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  console.log(fileDataList, 'fileDataList');
  const [documentCreateMany] = props.useDocumentCreateManyMutation();
  const [documentAddMany] = props.useDocumentAddManyMutation();

  useEffect(() => {
    const newFileDataList: IFileData[] = [];

    let newId = currentFileDataId;

    for (const selectedFile of selectedFiles) {
      newFileDataList.push({
        id: newId,
        file: selectedFile,
        uploadProgress: 0,
        status: FileUploadStatus.notUploaded,
      });
      newId += 1;
    }

    setCurrentFileDataId(newId);
    setFileDataList([...fileDataList, ...newFileDataList]);
  }, [selectedFiles]);

  const handleUpload = async () => {
    setIsUploading(true);

    const fileDataToBeUploaded = fileDataList.filter(
      (fileData) => fileData.status !== FileUploadStatus.success,
    );

    for (const fileData of fileDataToBeUploaded) {
      await handleUploadForSingleFileData({ fileData });
    }
    setIsUploading(false);
  };

  const handleUploadForSingleFileData = async (params: { fileData: IFileData }) => {
    try {
      await uploadDocument({
        entityId: props.entityId,
        documentCreateManyQuery: documentCreateMany,
        documentAddManyQuery: documentAddMany,
        fileData: params.fileData,
        setFileDataList,
      });
    } catch (error) {
      setFileDataList((prevItems) => {
        return setFileDataStatus({
          items: prevItems,
          item: params.fileData,
          newStatus: FileUploadStatus.failed,
        });
      });
    }
  };

  const handleDocumentSelect = async ({ e }: { e?: any }) => {
    console.log(e.target.files, 'e.target.files');
    setSelectedFiles(e.target.files);
  };

  const handleDeleteFile = (id: number) => {
    const newFileDataList = Object.values(fileDataList).filter((fileData, _) => fileData.id !== id);
    setFileDataList(newFileDataList);
  };

  return (
    <ActionDialog
      title={t('uploadDocument')}
      open={props.open}
      onClose={() => {
        props.setOpen(false);
        setFileDataList([]);
        setSelectedFiles([]);
        setIsUploading(false);
        setCurrentFileDataId(0);
      }}
      buttonTitle={t('upload')}
      leftIcon={<FileUpload sx={{ mr: 1 }} />}
      handleClick={handleUpload}
      width={600}
      loading={isUploading}
      disabled={
        isUploading ||
        fileDataList.length === 0 ||
        fileDataList.some((fileData) => fileData.status === FileUploadStatus.failed) ||
        fileDataList.every((fileData) => fileData.status === FileUploadStatus.success)
      }
      buttonCancelTitle={
        fileDataList.length > 0 &&
        fileDataList.some((fileData) => fileData.status === FileUploadStatus.success)
          ? t('close')
          : t('cancel')
      }
    >
      <Grid item xs={12}>
        <Stack>
          <Stack
            onClick={() => document.getElementById('profile-image-input')?.click()}
            sx={{
              width: 'calc(100% - 20px)',
              border: '1px dashed',
              borderColor: 'primary.light',
              borderRadius: '16px',
              p: 1,
              flexGrow: 1,
              cursor: 'pointer',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={1}
              py={2}
              sx={{ width: '100%', height: '100%' }}
            >
              <img src={UPLOADIcon} alt="upload" style={{ width: '50px', height: '50px' }} />
              <Typography variant="body2"> {t('uploadDocuments')}</Typography>
            </Stack>
          </Stack>
          <input
            id="profile-image-input"
            type="file"
            accept={Object.values(FileMimeType).join(',')}
            multiple
            style={{ display: 'none' }}
            onChange={(e) => {
              console.log(e.target.files, 'e.target. - onchange');
              handleDocumentSelect({ e });
            }}
          />
          <Stack direction={'column'} gap={1} mt={2}>
            {Object.values(fileDataList)?.map((fileData: IFileData, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    borderRadius: 2,
                    py: 1,
                    px: 2,
                    border: '1px solid',
                    borderColor: 'grey.300',
                    position: 'relative',
                  }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    zIndex={2}
                  >
                    <Stack
                      direction={'row'}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      gap={1}
                      zIndex={2}
                    >
                      <img
                        src={fileTypeForIcon(fileData.file.type)}
                        alt="file"
                        style={{ width: '30px', height: '30px' }}
                      />
                      <Stack direction={'column'}>
                        <Typography fontSize={'12px'} fontWeight={600}>
                          {fileData.file.name?.slice(0, 20)}{' '}
                          {fileData.file.name?.length > 30 ? '...' : ''}
                        </Typography>
                        <Typography fontSize={'10px'} color={'GrayText'}>
                          {(fileData.file.size / 1024).toFixed(2)} KB
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      zIndex={2}
                      direction={'row'}
                      gap={1}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                    >
                      {fileData?.status === FileUploadStatus.failed && (
                        <OutlinedButton
                          title={t('Retry')}
                          onClick={() => handleUploadForSingleFileData({ fileData })}
                        />
                      )}
                      <IconButton
                        color="inherit"
                        size="small"
                        onClick={() =>
                          fileData?.status === FileUploadStatus.success
                            ? {}
                            : handleDeleteFile(fileData?.id)
                        }
                      >
                        {(fileData?.status === FileUploadStatus.notUploaded ||
                          fileData?.status === FileUploadStatus.failed) && (
                          <Close fontSize="small" />
                        )}
                        {fileData?.status === FileUploadStatus.success && (
                          <Check fontSize="small" color="success" />
                        )}
                      </IconButton>
                    </Stack>
                  </Stack>
                  <LinearProgress
                    variant="determinate"
                    value={fileData?.uploadProgress || 0}
                    sx={{
                      width: '100%',
                      zIndex: 0,
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      height: '100%',
                      opacity: 0.4,
                      borderRadius: 2,
                      bgcolor:
                        fileData?.status === FileUploadStatus.failed ? 'error.light' : '#00000000',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 2,
                        bgcolor: '#00000000',
                      },
                      '& .MuiLinearProgress-barColorPrimary': {
                        borderRadius: 2,
                        bgcolor: 'success.light',
                      },
                    }}
                  />
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Grid>
    </ActionDialog>
  );
};

/**
 *
 * @param params
 */
async function uploadDocument(params: {
  entityId: string;
  documentCreateManyQuery: any;
  documentAddManyQuery: any;
  fileData: IFileData;
  setFileDataList: Dispatch<SetStateAction<IFileData[]>>;
}) {
  params.setFileDataList((prevItems) => {
    return setFileDataProgress({
      items: prevItems,
      item: params.fileData,
      newProgress: 0,
    });
  });

  // creates file from backend - gets fileMetadata and presignedUrl
  const createDocumentResult = await createDocument({
    documentCreateManyQuery: params.documentCreateManyQuery,
    file: params.fileData.file,
  });

  // uploads file to s3 bucket using presignedUrl obtained from above
  await uploadDocumentUsingPresignedUrl({
    createDocumentResult: createDocumentResult,
    fileData: params.fileData,
    setFileDataList: params.setFileDataList,
  });

  // assigns fileMetadata with the related entity
  await assignDocumentToEntity({
    entityId: params.entityId,
    documentAddManyQuery: params.documentAddManyQuery,
    createDocumentResult: createDocumentResult,
  });

  params.setFileDataList((prevItems) => {
    return setFileDataProgress({
      items: prevItems,
      item: params.fileData,
      newProgress: 100,
    });
  });

  params.setFileDataList((prevItems) => {
    return setFileDataStatus({
      items: prevItems,
      item: params.fileData,
      newStatus: FileUploadStatus.success,
    });
  });
}

/**
 * creates file from backend - gets fileMetadata and presignedUrl
 */
async function createDocument(params: {
  documentCreateManyQuery: any;
  file: File;
}): Promise<IDomainFileCreateResult> {
  const input: IDomainFilesCreateOwnInput = {
    inputs: [
      {
        mimeType: params.file.type,
        size: params.file.size,
        customName: params.file.name,
      },
    ],
  };

  const response = await params.documentCreateManyQuery({
    input: input,
  });

  const createFilesResult = response?.data.files as IDomainFileCreateResult[];

  if (!createFilesResult || createFilesResult?.length === 0 || !createFilesResult?.at(0)) {
    throw new Error('create document fileMetadata and presigned url failed');
  }

  const createDocumentResult = createFilesResult.at(0);
  return createDocumentResult!;
}

/**
 * uploads file to s3 bucket using presignedUrl obtained from above
 */
async function uploadDocumentUsingPresignedUrl(params: {
  createDocumentResult: IDomainFileCreateResult;
  fileData: IFileData;
  setFileDataList: Dispatch<SetStateAction<IFileData[]>>;
}): Promise<void> {
  const config: AxiosRequestConfig<File> = {
    method: 'PUT',
    maxBodyLength: Infinity,
    url: params.createDocumentResult.presignedUrl.uploadPresignedUrl,
    headers: {
      'Content-Type': params.fileData.file.type,
    },
    data: params.fileData.file,
    onUploadProgress: (event: AxiosProgressEvent) => {
      if (event.lengthComputable && event.total) {
        const progress = Math.round((event.loaded / event.total) * 100);
        params.setFileDataList((prevItems) => {
          return setFileDataProgress({
            items: prevItems,
            item: params.fileData,
            newProgress: progress,
          });
        });
      }
    },
  };

  try {
    const response = await axios(config);
    if (!response) {
      throw new Error('upload document failed');
    }
  } catch (error: any) {
    throw new Error(`upload document failed: error: ${error.message}`);
  }
}

/**
 * assigns fileMetadata with the related entity
 */
async function assignDocumentToEntity(params: {
  entityId: string;
  documentAddManyQuery: any;
  createDocumentResult: IDomainFileCreateResult;
}): Promise<void> {
  try {
    const input: IEntityFilesAssignInput = {
      _id: params.entityId,
      files: [params.createDocumentResult.fileMetadata],
    };

    const result = await params.documentAddManyQuery({
      input: input,
    });
    if (!result) {
      throw new Error('assign file to entity failed');
    }
  } catch (error: any) {
    throw new Error(`assign file to entity failed: error: ${error.message}`);
  }
}

function setFileDataProgress(params: {
  items: IFileData[];
  item: IFileData;
  newProgress: number;
}): IFileData[] {
  return params.items.map((item) => {
    return item.id === params.item.id
      ? {
          ...item,
          uploadProgress: params.newProgress,
        }
      : item;
  });
}

function setFileDataStatus(params: {
  items: IFileData[];
  item: IFileData;
  newStatus: FileUploadStatus;
}): IFileData[] {
  return params.items.map((item) => {
    return item.id === params.item.id
      ? {
          ...item,
          status: params.newStatus,
        }
      : item;
  });
}

export function fileTypeForIcon(fileType: any) {
  switch (fileType) {
    case 'image/jpeg':
      return JPGIcon;
    case 'image/png':
      return PNGIcon;
    case 'application/pdf':
      return PDFIcon;
    case 'application/msword':
      return DOCIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return DOCIcon;
    case 'application/vnd.ms-excel':
      return XLSIcon;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return XLSIcon;
    case 'application/vnd.ms-powerpoint':
      return PPTIcon;
    case 'application/application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return PPTIcon;
    case 'text/plain':
      return TXTIcon;
    case 'video/mp4':
      return MP4Icon;
    case 'application/zip':
      return ZIPIcon;
    default:
      return UPLOADIcon;
  }
}
