/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, useMediaQuery, Theme } from '@mui/material';
import { Iconify } from 'components';
import { useTranslation } from 'react-i18next';
import { IContractDetailResult } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import parse from 'html-react-parser';
import moment from 'moment';

export interface IContractDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IContractDetailResult> {
  contractDetailData: IContractDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const ContractDetailDrawerTabInfoComponent = (
  props: IContractDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { title, description, startDate, endDate, contractValue, currency, contractType } =
    props.contractDetailData || {};

  const additionalInfoData = [
    {
      title: t('crm.title'),
      value: title || '-',
      icon: 'clarity:map-marker-line',
    },
    {
      title: t('crm.description'),
      value: parse(description ?? '') || '-',
      icon: 'solar:city-broken',
    },
    {
      title: t('crm.startDate'),
      value: moment(startDate).format() || '-',
      icon: 'mage:location',
    },
    {
      title: t('crm.endDate'),
      value: moment(endDate).format() || '-',
      icon: 'tabler:language',
    },
    {
      title: t('crm.contract.contract.contractValue'),
      value: contractValue || '-',
      icon: 'tabler:language',
    },
    {
      title: t('crm.currency'),
      value: currency || '-',
      icon: 'mdi:currency-usd',
    },
    {
      title: t('crm.contract.contract.contractType'),
      value: contractType || '-',
      icon: 'tabler:language',
    },
  ];

  return (
    <>
      <Grid container mt={downMd ? 0 : 2} mb={2}>
        <Grid item xs={12}>
          <Stack
            direction={'column'}
            p={2.5}
            mt={downMd ? 2 : 0}
            sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
          >
            <Grid container spacing={1}>
              {additionalInfoData.map((item) => (
                <Grid item xs={12}>
                  <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Box
                      sx={{
                        width: 30,
                        height: 30,
                        bgcolor: 'secondary.extraLight',
                        borderRadius: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                    </Box>
                    <Stack direction={'column'}>
                      <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                        {item.value ?? '-'}
                      </Typography>
                      <Typography variant="caption" color={'text.secondary'}>
                        {item.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
