/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack, Typography, Tooltip, IconButton } from '@mui/material';
import { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { generateAutocompleteTranslatedOptions } from 'localization';
import { useTranslation } from 'react-i18next';
import { IGraphqlVariables, PermissionAction } from 'corede-common';
import 'react-quill/dist/quill.snow.css';
import ActionDrawer from 'components/drawer/ActionDrawer';
import { UseFormReturn } from 'react-hook-form';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { Iconify } from 'components';
import { useCustomerListByRelatedQuery } from 'apps/crm/domains/04-customer/subdomains/customer';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import {
  AppointmentCategory,
  AppointmentTargetType,
  CustomerRelatedTargetType,
  LeadRelatedTargetType,
  PermissionSubject,
  UserRelatedTargetType,
} from 'corede-common-cocrm';
import { useLeadListByRelatedQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { useUserListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import { DefaultDateTimePickerInput } from 'components/form/DefaultDateTimePickerInput';
import momentTimezone from 'moment-timezone';
import { DefaultArrayObjectAutocompleteInput } from 'components/form/DefaultArrayObjectAutocompleteInput';
import { DefaultRelationshipSwitchInput } from 'components/form/DefaultRelationshipSwitchInput';
import ActionDialog from 'components/dialog/ActionDialog';
import { usePermissions } from 'permission/PermissionContext';

export interface IUpsertAppointmentComponentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  buttonTitle: string;
  loading?: boolean;
  useForm: UseFormReturn<IGraphqlVariables<any, any>>;
  onSubmit: (values: IGraphqlVariables<any, any>) => Promise<void>;
  type?: 'create' | 'update';
  overlayType?: 'dialog' | 'drawer';
  targetType?: AppointmentTargetType;
}

export const UpsertAppointmentComponent = memo((props: IUpsertAppointmentComponentProps) => {
  // general
  const { t } = useTranslation();
  const timezones = momentTimezone.tz.names();
  const [hasRelation, setHasRelation] = useState(false);

  const { hasPermission } = usePermissions();
  const hasLeadPermission = hasPermission({
    subject: PermissionSubject.lead,
    action: PermissionAction.update,
  });
  const hasCustomerPermission = hasPermission({
    subject: PermissionSubject.customer,
    action: PermissionAction.update,
  });

  // queries

  const { data: leadListData, isLoading: leadListLoading } = useLeadListByRelatedQuery(
    {
      input: {
        filter: {
          relatedTargetType: LeadRelatedTargetType.Appointment,
        },
      },
    },
    {
      skip:
        !props.open ||
        // props.useForm.watch('input.targetId') ||
        (props.useForm.watch('input.targetType') !== AppointmentTargetType.Lead &&
          props.targetType !== AppointmentTargetType.Lead) ||
        !hasLeadPermission,
    },
  );

  const { data: customerListData, isLoading: customerListLoading } = useCustomerListByRelatedQuery(
    {
      input: {
        filter: {
          relatedTargetType: CustomerRelatedTargetType.Appointment,
        },
      },
    },
    {
      skip:
        !props.open ||
        !hasRelation ||
        props.useForm.watch('input.targetId') ||
        props.useForm.watch('input.targetType') !== AppointmentTargetType.Customer ||
        props.targetType !== AppointmentTargetType.Customer ||
        !hasCustomerPermission,
    },
  );
  const { data: userListData, isLoading: userListLoading } = useUserListByRelatedQuery(
    {
      input: {
        filter: {
          relatedTargetType: UserRelatedTargetType.Appointment,
        },
      },
    },
    { skip: !props.open },
  );

  // useEffects.success

  // useEffects.error

  // useEffects.init

  console.log(props.targetType, 'targetType');

  // useEffect(() => {
  //   if (!hasRelation && props.type === 'create') {
  //     props.useForm.setValue('input.targetType', undefined);
  //     props.useForm.setValue('input.attendeeIds', undefined);
  //   }
  // }, [hasRelation]);

  const contentForm = (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <DefaultTextFieldInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.title"
          label={t('crm.title')}
          touchedFields={props.useForm.formState.touchedFields}
          isFirstLetterCapitalize
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultRichTextQuillInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.description"
          label={t('crm.description')}
          isRequired
          placeholder={t('crm.description')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DefaultDateTimePickerInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.startTime"
          label={t('crm.calendar.appointment.startTime')}
          isRequired
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DefaultDateTimePickerInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.endTime"
          touchedFields={props.useForm.formState.touchedFields}
          label={t('crm.calendar.appointment.endTime')}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DefaultObjectAutocompleteInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.timezone"
          label={t('crm.calendar.appointment.timezone')}
          options={timezones.map((timezone) => ({ name: timezone, _id: timezone }))}
          touchedFields={props.useForm.formState.touchedFields}
          disableAddNewOption
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DefaultTextFieldInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.location"
          label={t('crm.calendar.appointment.location')}
          touchedFields={props.useForm.formState.touchedFields}
          isFirstLetterCapitalize
          isRequired
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DefaultObjectAutocompleteInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.organizerId"
          label={t('crm.calendar.appointment.organizer')}
          options={
            userListData?.data?.map((user) => ({
              name: user.name ?? '',
              _id: user._id,
            })) ?? []
          }
          touchedFields={props.useForm.formState.touchedFields}
          disableAddNewOption
          isLoading={userListLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DefaultEnumAutocompleteInput
          control={props.useForm.control}
          enum={AppointmentCategory}
          inputName="input.category"
          label={t('crm.category')}
          options={generateAutocompleteTranslatedOptions(AppointmentCategory)}
          disableClearable
          isRequired
        />
      </Grid>
      {props.useForm.watch('input.category') === AppointmentCategory.meeting && (
        <Grid item xs={12}>
          <DefaultTextFieldInput
            control={props.useForm.control}
            errors={props.useForm.formState.errors}
            inputName="input.meetingLink"
            label={t('crm.calendar.appointment.meetingLink')}
            touchedFields={props.useForm.formState.touchedFields}
          />
        </Grid>
      )}
      {props.type === 'create' && props.overlayType !== 'dialog' && (
        <Grid item xs={12} md={6}>
          <DefaultRelationshipSwitchInput
            description={t('crm.relationship')}
            checked={hasRelation}
            setChecked={setHasRelation}
          />
        </Grid>
      )}
      {props.type === 'create' && hasRelation && props.overlayType !== 'dialog' && (
        <Grid item xs={12} md={6}>
          <DefaultEnumAutocompleteInput
            control={props.useForm.control}
            enum={AppointmentTargetType}
            inputName="input.targetType"
            label={t('crm.calendar.appointment.targetType')}
            options={generateAutocompleteTranslatedOptions(AppointmentTargetType)}
          />
        </Grid>
      )}
      {((props.type === 'create' && props.useForm?.watch('input.targetType') && hasRelation) ||
        props.type === 'update') &&
        props.overlayType !== 'dialog' && (
          <Grid item xs={12}>
            <DefaultArrayObjectAutocompleteInput
              control={props.useForm.control}
              key={props.useForm.watch('input.targetType')}
              inputName="input.attendeeIds"
              label={t('crm.calendar.appointment.attendees')}
              options={
                props.useForm.watch('input.targetType') === AppointmentTargetType.Lead ||
                props.targetType === AppointmentTargetType.Lead
                  ? (leadListData?.data?.map((lead) => ({
                      name: lead.fullName,
                      _id: lead._id,
                    })) ?? [])
                  : props.useForm.watch('input.targetType') === AppointmentTargetType.Customer ||
                      props.targetType === AppointmentTargetType.Customer
                    ? (customerListData?.data?.map((user) => ({
                        name: user.name,
                        _id: user._id,
                      })) ?? [])
                    : []
              }
              loading={leadListLoading || customerListLoading}
            />
          </Grid>
        )}
    </Grid>
  );
  if (props.overlayType === 'dialog') {
    return (
      <ActionDialog
        open={props.open}
        onClose={() => props.setOpen?.(false)}
        size="medium"
        title={props.title}
        handleClick={props.useForm.handleSubmit(props.onSubmit)}
        disabled={props.loading || !props.useForm.formState.isValid}
        loading={props.loading}
        buttonTitle={props.buttonTitle}
      >
        {contentForm}
      </ActionDialog>
    );
  }
  return (
    <ActionDrawer
      open={props.open}
      setOpen={props.setOpen}
      size="medium"
      backgroundColor={'background.default'}
      title={
        <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            {props.title}
          </Typography>

          <Tooltip title={t('webNotification.close')}>
            <IconButton sx={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
              <Iconify icon={'material-symbols:close'} />
            </IconButton>
          </Tooltip>
        </Stack>
      }
      handleSubmit={props.useForm.handleSubmit(props.onSubmit)}
      disabled={props.loading || !props.useForm.formState.isValid}
      loading={props.loading}
      buttonTitle={props.buttonTitle}
    >
      {contentForm}
    </ActionDrawer>
  );
});
