import {
  IDepartmentListInput,
  IDepartmentListRequest,
  IDepartmentListResponse,
  IDepartmentListResult,
  departmentListQuery,
  IDepartmentListByRelatedInput,
  IDepartmentListByRelatedRequest,
  IDepartmentListByRelatedResponse,
  IDepartmentListByRelatedResult,
  departmentListByRelatedQuery,
  IDepartmentDetailInput,
  IDepartmentDetailRequest,
  IDepartmentDetailResponse,
  IDepartmentDetailResult,
  departmentDetailQuery,
  IDepartmentCreateInput,
  IDepartmentCreateRequest,
  IDepartmentCreateResponse,
  IDepartmentCreateResult,
  departmentCreateQuery,
  IDepartmentUpdateInput,
  IDepartmentUpdateRequest,
  IDepartmentUpdateResponse,
  IDepartmentUpdateResult,
  departmentUpdateQuery,
  IDepartmentUpdateFilterInput,
  IDepartmentDeleteInput,
  IDepartmentDeleteRequest,
  IDepartmentDeleteResponse,
  IDepartmentDeleteResult,
  deleteDepartmentQuery,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { departmentTags } from './department.tags';
import { IGraphqlVariables } from 'corede-common';

export const departmentsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    //  queries
    departmentList: builder.query<IDepartmentListResult, IGraphqlVariables<IDepartmentListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentListRequest,
        IDepartmentListResponse,
        IDepartmentListResult,
        IDepartmentListInput
      >({
        query: departmentListQuery,
        providesTags: [departmentTags.department, departmentTags.departments],
      }),
    ),

    departmentListByRelated: builder.query<
      IDepartmentListByRelatedResult,
      IGraphqlVariables<IDepartmentListByRelatedInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentListByRelatedRequest,
        IDepartmentListByRelatedResponse,
        IDepartmentListByRelatedResult,
        IDepartmentListByRelatedInput
      >({
        query: departmentListByRelatedQuery,
        providesTags: [departmentTags.department, departmentTags.departments],
      }),
    ),

    departmentDetail: builder.query<
      IDepartmentDetailResult,
      IGraphqlVariables<IDepartmentDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentDetailRequest,
        IDepartmentDetailResponse,
        IDepartmentDetailResult,
        IDepartmentDetailInput
      >({
        query: departmentDetailQuery,
        providesTags: [departmentTags.department, departmentTags.departments],
      }),
    ),

    //mutations

    departmentCreate: builder.mutation<
      IDepartmentCreateResult,
      IGraphqlVariables<IDepartmentCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentCreateRequest,
        IDepartmentCreateResponse,
        IDepartmentCreateResult,
        IDepartmentCreateInput
      >({
        query: departmentCreateQuery,
        invalidatesTags: [departmentTags.departments],
      }),
    ),

    departmentUpdate: builder.mutation<
      IDepartmentUpdateResult,
      IGraphqlVariables<IDepartmentUpdateInput, IDepartmentUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentUpdateRequest,
        IDepartmentUpdateResponse,
        IDepartmentUpdateResult,
        IDepartmentUpdateInput,
        IDepartmentUpdateFilterInput
      >({
        query: departmentUpdateQuery,
        invalidatesTags: [departmentTags.departments],
      }),
    ),

    departmentDelete: builder.mutation<
      IDepartmentDeleteResult,
      IGraphqlVariables<IDepartmentDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IDepartmentDeleteRequest,
        IDepartmentDeleteResponse,
        IDepartmentDeleteResult,
        IDepartmentDeleteInput
      >({
        query: deleteDepartmentQuery,
        invalidatesTags: [departmentTags.departments],
      }),
    ),
  }),
});

export const {
  useDepartmentListQuery,
  useLazyDepartmentListQuery,
  useDepartmentListByRelatedQuery,
  useLazyDepartmentListByRelatedQuery,
  useDepartmentDetailQuery,
  useLazyDepartmentDetailQuery,

  useDepartmentCreateMutation,
  useDepartmentUpdateMutation,
  useDepartmentDeleteMutation,
} = departmentsApi;
