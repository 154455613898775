/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Menu, MenuItem, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IReminderDetailResult, ReminderStatus, PermissionSubject } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import {
  useReminderCancelMutation,
  useReminderCompleteMutation,
  useReminderScheduleMutation,
} from '../../context/reminder.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError, PermissionAction } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import ActionDialog from 'components/dialog/ActionDialog';
import { useForm } from 'react-hook-form';
import { DefaultDateTimePickerInput } from 'components/form/DefaultDateTimePickerInput';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IReminderDetailDrawerLeftPanelActionsComponentProps {
  reminderDetailData: IReminderDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export enum ReminderChangeableStatus {
  schedule = 'schedule',
  complete = 'complete',
  cancel = 'cancel',
}

type SubmitHandler<T> = (data: T) => Promise<void>;

/**
 * contains actions: add appointment, convert reminder to customer
 */
export const ReminderDetailDrawerLeftPanelActionsComponent = (
  props: IReminderDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openReminderSchedule, setOpenReminderSchedule] = React.useState(false);

  const [
    reminderCancel,
    { data: reminderCancelData, isLoading: reminderCancelLoading, error: reminderCancelError },
  ] = useReminderCancelMutation();

  const [
    reminderComplete,
    {
      data: reminderCompleteData,
      isLoading: reminderCompleteLoading,
      error: reminderCompleteError,
    },
  ] = useReminderCompleteMutation();

  const [
    reminderSchedule,
    {
      data: reminderScheduleData,
      isLoading: reminderScheduleLoading,
      error: reminderScheduleError,
    },
  ] = useReminderScheduleMutation();

  const handleChangeStatus = (status: ReminderChangeableStatus) => {
    if (status === ReminderChangeableStatus.schedule) {
      setOpenReminderSchedule(true);
    } else if (status === ReminderChangeableStatus.complete) {
      reminderComplete({
        input: {
          reminderId: props.reminderDetailData?._id ?? '',
        },
      });
    } else if (status === ReminderChangeableStatus.cancel) {
      reminderCancel({
        input: {
          reminderId: props.reminderDetailData?._id ?? '',
        },
      });
    }
  };

  const scheduleForm = useForm({
    values: {
      input: {
        remindDate: props.reminderDetailData?.remindDate,
      },
    },
    mode: 'onChange',
  });

  const onSubmit = async (data: { input: { remindDate: Date } }) => {
    await reminderSchedule({
      filter: {
        reminderId: props.reminderDetailData?._id ?? '',
      },
      input: {
        remindDate: data?.input?.remindDate ?? new Date(),
      },
    });
  };

  useEffect(() => {
    if (reminderCancelData) {
      enqueueSnackbar(t('crm.calendar.reminder.cancelSuccess'), {
        variant: 'success',
      });
    }
  }, [reminderCancelData]);

  useEffect(() => {
    if (reminderCompleteData) {
      enqueueSnackbar(t('crm.calendar.reminder.completeSuccess'), {
        variant: 'success',
      });
    }
  }, [reminderCompleteData]);

  useEffect(() => {
    if (reminderScheduleData) {
      enqueueSnackbar(t('crm.calendar.reminder.scheduleSuccess'), {
        variant: 'success',
      });
      setOpenReminderSchedule(false);
    }
  }, [reminderScheduleData]);

  useEffect(() => {
    if (reminderCancelError) {
      DefaultErrorHandlerUseEffect(reminderCancelError as IBaseError, currentLanguage);
    }
  }, [reminderCancelError]);

  useEffect(() => {
    if (reminderCompleteError) {
      DefaultErrorHandlerUseEffect(reminderCompleteError as IBaseError, currentLanguage);
    }
  }, [reminderCompleteError]);

  useEffect(() => {
    if (reminderScheduleError) {
      DefaultErrorHandlerUseEffect(reminderScheduleError as IBaseError, currentLanguage);
    }
  }, [reminderScheduleError]);

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const isScheduleDisabled = !(
      props.reminderDetailData?.status === ReminderStatus.scheduled ||
      props.reminderDetailData?.status === ReminderStatus.unscheduled
    );

    const isCompleteDisabled =
      props.reminderDetailData?.status === ReminderStatus.completed ||
      props.reminderDetailData?.status === ReminderStatus.expired ||
      props.reminderDetailData?.status === ReminderStatus.cancelled;

    const isCancelDisabled =
      props.reminderDetailData?.status === ReminderStatus.cancelled ||
      props.reminderDetailData?.status === ReminderStatus.completed ||
      props.reminderDetailData?.status === ReminderStatus.expired;

    return (
      <div>
        <Button
          id="reminder-detail-button"
          aria-controls={open ? 'reminder-detail-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          variant="contained"
          disabled={isScheduleDisabled && isCompleteDisabled && isCancelDisabled}
          sx={{
            height: 40,
            fontSize: '13px',
            borderRadius: 100,
          }}
        >
          <Icons.RefreshRounded sx={{ mr: 1 }} />
          {t('crm.calendar.reminder.changeStatus')}
        </Button>
        <Menu
          id="reminder-detail-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'reminder-detail-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              bgcolor: 'background.secondWithBlur',
              backdropFilter: 'blur(3px)',
              borderRadius: 2,
            },
          }}
        >
          {Object.entries(ReminderChangeableStatus).map(([key, value]) => (
            <MenuItem
              key={key}
              onClick={() => handleChangeStatus(value)}
              disabled={
                (value === ReminderChangeableStatus.schedule && isScheduleDisabled) ||
                (value === ReminderChangeableStatus.complete && isCompleteDisabled) ||
                (value === ReminderChangeableStatus.cancel && isCancelDisabled)
              }
            >
              <Typography fontSize={'small'} fontWeight={'bold'}>
                {getTranslatedEnumValue(value)}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  };

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Reminder base actions */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.update,
        }}
      >
        <StatusMenu />

        <ActionDialog
          open={openReminderSchedule}
          onClose={() => setOpenReminderSchedule(false)}
          title={t('crm.calendar.reminder.schedule')}
          loading={reminderScheduleLoading}
          handleClick={scheduleForm.handleSubmit(
            onSubmit as SubmitHandler<{ input: { remindDate: Date | undefined } }>,
          )}
        >
          <DefaultDateTimePickerInput
            control={scheduleForm.control}
            errors={scheduleForm.formState.errors}
            inputName="input.remindDate"
            label={t('crm.calendar.reminder.remindDate')}
          />
        </ActionDialog>
      </PermissionWrapper>
    </Stack>
  );
};
