/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useEffect } from 'react';
// import {  } from "../context/permissions.api";
import { IBaseError } from 'corede-common';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { IRole } from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';
import { useRoleDeleteMutation } from '../../context';

export interface IRoleDeleteProps {
  open: boolean;
  item: IRole;
  onSuccess: () => void;
  onClose: () => void;
}

const RoleDelete = (prop: IRoleDeleteProps) => {
  const { t } = useTranslation();
  // general
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [
    roleDelete,
    { data: roleDeleteData, isLoading: roleDeleteLoading, error: roleDeleteError },
  ] = useRoleDeleteMutation();

  // constants

  // action handlers
  const handleDelete = (_id: string) => {
    roleDelete({ input: { _id: _id } });
  };

  // useEffects.success
  useEffect(() => {
    if (roleDeleteData) {
      prop?.onSuccess();
      enqueueSnackbar(t('crm.deletedSuccessfully'), {
        variant: 'success',
      });
    }
  }, [roleDeleteData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(roleDeleteError as IBaseError, currentLanguage);
  }, [roleDeleteError, currentLanguage]);

  // useEffects.init

  return (
    <ActionDialog
      open={prop?.open}
      onClose={prop?.onClose}
      handleClick={() => handleDelete(prop?.item?._id as string)}
      title={t('crm.delete')}
      disabled={roleDeleteLoading}
      buttonTitle={t('delete')}
      buttonColor="error"
      loading={roleDeleteLoading}
    >
      <Grid item xs={12}>
        {t('crm.organizationalChart.role.deleteConfirm')} {prop?.item?.name}?
      </Grid>
    </ActionDialog>
  );
};

export default RoleDelete;
