const locale = {
  demo: 'Demo',
  adjustment: 'Adjustment',
  currency: 'Currency',
  exchangeRates: 'Exchange Rates',
  fromCurrency: 'From Currency',
  toCurrency: 'To Currency',
  rate: 'Rate',
  updatedAt: 'Updated At',
  language: 'Language',
  locale: 'Locale',
  updateSettings: 'Update Settings',
  NDA: 'NDA',
  followUpMailTemplate: 'Follow Up Mail Template',
  emailType: 'Email Type',
  subject: 'Subject',
  content: 'Content',
  variables: 'Variables',
  prefix: 'Prefix',
  paymentPrefix: 'Payment Prefix',
  returnPrefix: 'Return Prefix',
  termsAndConditions: 'Terms and Conditions',
  paymentMethods: 'Payment Methods',
  companyBackground: 'Company Background',
  companyReferences: 'Company References',
  timezone: 'Timezone',
  updateSuccess: 'Settings updated successfully',
  name: 'Name',
  surname: 'Surname',
  email: 'Email',
  phone: 'Phone',
  identityNumber: 'Identity Number',
  vat: 'Vat Number',
  country: 'Country',
  state: 'State',
  city: 'City',
  street: 'Street',
  address: 'Address',
  taxOffice: 'Tax Office',
  zip: 'Zip',
  fullName: 'Full Name',
  update: 'Update',
  create: 'Create',
  updateBillingInfo: 'Update Billing Info',
  updateBillingInfoSuccess: 'Billing info updated successfully',
  commonSettingsUpdateSuccess: 'Settings updated successfully',
  save: 'Save',
  organizationSettings: 'Organization',
  monthly: 'monthly',
  trial: 'Trial',
  free: 'Free',
  trialEnds: 'Trial Ends',
  browsePackages: 'Browse Packages',
  cancelSubscription: 'Cancel Subscription',
  updateOrganizationLogoSuccess: 'Organization logo updated successfully',
  organizationLogo: 'Organization Logo',
  summary: 'Summary',
  website: 'Website',
  legalName: 'Legal Name',
  dateFormat: 'Date Format',
  timeFormat: 'Time Format',
  vatNumber: 'Vat Number',
  taxRate: 'Tax Rate',
  exchangeRateMode: 'Exchange Rate Mode',
  general: 'General',
  localizations: 'Localizations',
  finance: 'Finance',
  generalInfo: 'General Info',
  socialMedia: 'Social Media',
  billingInfo: 'Billing Info',
  cards: 'Cards',
  currentPlan: 'Current Plan',
  invoices: 'Invoices',
  cardListSecure: 'Your cards are stored in the iyzico database.',
  noInvoiceContent1: "You don't have any invoice yet.",
  noInvoiceContent2:
    'Track and manage payments for your invoices here to keep your finances organized and up-to-date.',
  noLanguageContent1: "You don't have any language yet.",
  noLanguageContent2:
    'Create and manage languages here to make your website accessible to a global audience.',
  noLeadContent1: 'Lead settings are currently unavailable.',
  noLeadContent2:
    'Customize how leads are captured, assigned, and tracked to streamline your CRM workflow.',
  noCustomerContent1: 'No customer settings configured yet.',
  noCustomerContent2:
    'Set up preferences to manage customer information and interactions effectively within your CRM.',
  noEstimateContent1: 'No invoice settings configured yet.',
  noEstimateContent2:
    'Customize your invoice preferences here to streamline billing and ensure accurate records.',
  noProposalContent1: 'Proposal settings are currently unavailable.',
  noProposalContent2:
    'Customize your proposal preferences here to streamline client communication and improve sales.',
  noInvoiceContent3: 'No invoice settings configured yet.',
  noInvoiceContent4:
    'Customize your invoice preferences here to streamline billing and ensure accurate records.',
  noProjectContent1: 'Project settings have not been accessed yet.',
  noProjectContent2:
    'Customize your project preferences here to optimize task management and workflow in your CRM.',
  noTaskContent1: 'Task settings have not been accessed yet.',
  noTaskContent2:
    'Configure your task preferences here to streamline task management and improve team productivity.',
  noTicketContent1: 'Ticket/support settings have not been accessed yet.',
  noTicketContent2:
    'Set up your preferences here to manage customer support requests and ensure timely resolutions.',
  noFileManagementContent1: 'File management settings have not been accessed yet.',
  noFileManagementContent2:
    'Configure your preferences here to organize and manage documents efficiently within your CRM.',
  noCalendarContent1: 'Calendar settings have not been accessed yet.',
  noCalendarContent2:
    'Set up your preferences here to customize your calendar and optimize scheduling within your CRM.',
  noNotificationContent1: 'Notification settings have not been accessed yet.',
  noNotificationContent2:
    'Customize your notification preferences here to stay informed and up-to-date on important activities within your CRM.',
  noLeadPlusContent1: 'No active Lead+ product or plan.',
  noLeadPlusContent2:
    'Purchase or be authorized a plan to unlock full access and customize your Lead+ settings.',
  noMarketingContent1: 'No active Marketing product or plan.',
  noMarketingContent2:
    'Purchase or be authorized a plan to unlock full access and customize your Marketing settings.',
  noWindowContent1: 'No active Window product or plan.',
  noWindowContent2:
    'Purchase or be authorized a plan to unlock full access and customize your Window settings.',
  noCommunityContent1: 'No active Community product or plan.',
  noCommunityContent2:
    'Purchase or be authorized a plan to unlock full access and customize your Community settings.',
  noIntegrationsContent1: 'No integrations set up yet.',
  noIntegrationsContent2:
    'Configure integrations here to enhance CoCRM’s capabilities and streamline workflows with authorized connections.',
  noMenuContent1: 'Menu settings are currently unavailable.',
  noMenuContent2:
    'Authorize access to customize your menu layout and streamline navigation in your CRM.',
  noLegalAgreementsContent1: 'Legal agreement settings are currently unavailable. ',
  noLegalAgreementsContent2:
    'Authorize access to manage and customize legal agreements within your CRM.',
  noCrmContent1: 'No active CRM product or plan.',
  noCrmContent2:
    'Purchase or be authorized a plan to unlock full access and customize your CRM settings.',
  noUserListContent1: 'No active User List product or plan.',
  noUserListContent2:
    'Purchase or be authorized a plan to unlock full access and customize your User List settings.',
  noPermissionsContent1: 'No active Permissions product or plan.',
  noPermissionsContent2:
    'Purchase or be authorized a plan to unlock full access and customize your Permissions settings.',
  lead: 'Lead',
  crm: 'CRM',
  menu: 'Menu',
  customer: 'Customer',
  estimate: 'Estimate',
  proposal: 'Proposal',
  invoice: 'Invoice',
  project: 'Project',
  task: 'Task',
  ticket: 'Ticket',
  fileManagement: 'File Management',
  calendar: 'Calendar',
  notification: 'Notification',
  leadPlus: 'Lead+',
  marketing: 'Marketing',
  window: 'Window',
  community: 'Community',
  integrations: 'Integrations',
  userList: 'User List',
  permissions: 'Permissions',
  userType: 'User Type',
  companyName: 'Company Name',
  allowableFileFormats: 'Allowable File Formats',

  // currentPlan
  yourCurrentPlan: 'Your Current Plan is ',
  yourCurrentPlanDesc: 'A simple start for everyone',
  activeUntil: 'Active Until',
  activeUntilDesc: 'We will send you a notification upon Subscription expiration',
  paymentDesc: 'Payment will be charged to your account',
  paymentForTrialDesc: 'You need to purchase a subscription to continue using',
  perMonth: 'Per Month',
  prtYear: 'Per Year',
  perMonthDesc: 'You can cancel your subscription anytime',
  upgradePlan: 'Upgrade Plan',
  attentionTitle: 'We need your attention!',
  attentionDesc: 'Your plan requires updates',
  days: 'Days',
  of: 'of',
  remainingDesc: 'days remaining until your plan requires update',
  usageOfStorage: 'Usage of Storage',
  users: 'Users',

  //legalAgreements
  legalAgreements: 'Legal Agreements',
  createLegalAgreement: 'Create Legal Agreement',
  updateLegalAgreement: 'Update Legal Agreement',
  deleteLegalAgreement: 'Delete Legal Agreement',
  createLegalAgreementSuccess: 'Legal Agreement created successfully',
  updateLegalAgreementSuccess: 'Legal Agreement updated successfully',
  deleteLegalAgreementSuccess: 'Legal Agreement deleted successfully',
  deleteLegalAgreementDesc: 'Are you sure you want to delete this legal agreement?',

  // cards
  deleteCard: 'Delete Card',
  addCard: 'Add New Card',
  createCardSuccess: 'Card created successfully',
  addCardSuccess: 'Card added successfully',
  deleteCardSuccess: 'Card deleted successfully',
  deleteCardDesc: 'Are you sure you want to delete this card?',
  requiredCard: 'Card number is required',
  requiredCardHolder: 'Card holder name is required',
  requiredExpireMonth: 'Expiration month is required',
  requiredExpireYear: 'Expiration year is required',
  requiredCvc: 'CVC is required',
};

export default locale;
