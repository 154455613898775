const locale = {
  permissions: 'İzinler',
  roles: 'Roller',
  createRole: 'Rol Oluştur',
  rolesInfo: 'Roller oluşturabilir ve bunlara izinler atayabilirsiniz.',
  roleName: 'Rol Adı',
  roleDetail: 'Rol Detayı',
  addRole: 'Ekle',
  updateRole: 'Güncelle',
  deleteRole: 'Sil',
  roleCreatedSuccess: 'Rol başarıyla oluşturuldu',
  roleUpdatedSuccess: 'Rol başarıyla güncellendi',
  roleDeletedSuccess: 'Rol başarıyla silindi',
  errorFetchingRoles: 'Roller getirilirken hata oluştu',
  errorCreatingRole: 'Rol oluşturulurken hata oluştu',
  errorUpdatingRole: 'Rol güncellenirken hata oluştu',
  errorDeletingRole: 'Rol silinirken hata oluştu',
  capabilities: 'Yetkinlikler',
  advancedOptions: 'Gelişmiş Seçenekler',
  addNewRole: 'Yeni Rol Ekle',
  rolesAndPermissions: 'Roller & İzinler',
  action: 'Eylem',
  actionScope: 'Eylem Kapsamı',
  deleteConfirm: 'Rolü silmek istediğinizden emin misiniz "',
  deletedSuccessfully: "Rol başarıyla silindi",
};

export default locale;
