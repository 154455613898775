/* eslint-disable react-hooks/exhaustive-deps */
import { SwipeableDrawer, Box, Stack, useMediaQuery, Theme } from '@mui/material';
import { Dispatch, SetStateAction, ReactNode } from 'react';
import { DefaultDetailDrawerUpperPanelComponent } from './DefaultDetailDrawer-upperPanel.component';
import { DefaultDetailDrawerSideNavigationComponent } from './DefaultDetailDrawer-sideNavigation.component';
import { IEntity, IHasId } from 'corede-common';
import { genericMemo } from 'types/generic-memo';

export interface IDefaultDetailDrawer<T extends IEntity> {
  children: ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  entity: T & IHasId<number>;
  sideNavigationProps?: {
    count: number;
    recordsTranslation?: string;
    handleNavigate: Function;
  };
  widthResponsive?: { xs: string | number; sm: string | number; md: number; lg: number };
}

export const DefaultDetailDrawer = genericMemo(
  <T extends IEntity>(props: IDefaultDetailDrawer<T>) => {
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    // init
    return (
      <SwipeableDrawer
        open={props.open}
        onClose={() => props.setOpen(false)}
        onOpen={() => props.setOpen(true)}
        anchor="right"
        className="user-detail-nav"
        sx={{ zIndex: 1249 }}
        elevation={downMd ? 0 : 16}
        ModalProps={{
          // onBackdropClick: () => props.setOpen(false),
          BackdropProps: { invisible: downMd },
        }}
      >
        {props.sideNavigationProps && !downMd && (
          <DefaultDetailDrawerSideNavigationComponent
            index={props.entity?.id}
            count={props.sideNavigationProps.count}
            handleNavigate={props.sideNavigationProps.handleNavigate}
            rightWidthResponsive={
              props?.widthResponsive ?? { xs: '100%', sm: '100%', md: 850, lg: 1200 }
            }
          />
        )}
        <Box
          // minWidth={260}
          width={props?.widthResponsive ?? { xs: '100%', sm: '100%', md: 850, lg: 1200 }}
          bgcolor={'background.primary'}
          height="100%"
          sx={{ overflowY: 'auto' }}
        >
          <Stack direction="column" alignItems="center">
            <DefaultDetailDrawerUpperPanelComponent
              setOpen={props.setOpen}
              sideNavigation={
                !props.sideNavigationProps
                  ? undefined
                  : {
                      index: props?.entity?.id,
                      count: props.sideNavigationProps.count,
                      recordsTranslation: props.sideNavigationProps.recordsTranslation,
                    }
              }
            />
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              width={'100%'}
              sx={{
                p: 2.5,
                boxSizing: 'border-box',
                gap: 2,
              }}
            >
              {props.children}
            </Stack>
          </Stack>
        </Box>
      </SwipeableDrawer>
    );
  },
);
