import profileLocale from "../domains/profile/locales/tr";

const locale = {
  profile: {
    ...profileLocale,
  },
  demoo: 'Teklif İste',
};

export default locale;
