/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from 'corede-common';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityDeleteDialog } from 'components/entityDeleteDialog/EntityDeleteDialog';
import { useProjectTimeSheetDeleteMutation } from '../../context/project.api';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import ActionDialog from 'components/dialog/ActionDialog';
import { Grid, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';

export const TimeSheetDeleteDialog = memo(
  (props: {
    open: boolean;
    projectId: string;
    timeSheetId: string;
    onClose: () => void;
    setTimeSheet: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>;
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      timeSheetDelete,
      {
        data: timeSheetDeleteData,
        isLoading: timeSheetDeleteIsLoading,
        error: timeSheetDeleteError,
      },
    ] = useProjectTimeSheetDeleteMutation();

    // action handlers
    const handleDelete = (id: string) => {
      timeSheetDelete({
        input: {
          projectId: props.projectId,
          projectTimeSheetId: id,
        },
      });
    };

    // useEffects.success
    useEffect(() => {
      if (timeSheetDeleteData) {
        props.setTimeSheet?.(undefined);
        props?.onClose();
        enqueueSnackbar(t('crm.deletedEntitySuccessfully'), {
          variant: 'success',
        });
      }
    }, [timeSheetDeleteData]);

    // useEffects.error
    useEffect(() => {
      DefaultErrorHandlerUseEffect(timeSheetDeleteError as IBaseError, getCurrentLanguage());
    }, [timeSheetDeleteError, getCurrentLanguage]);

    return (
      <ActionDialog
        open={props?.open}
        onClose={props?.onClose}
        title={t('crm.deleteEntityTitle')}
        buttonTitle={t('delete')}
        handleClick={() => handleDelete(props?.timeSheetId)}
        disabled={timeSheetDeleteIsLoading}
        loading={timeSheetDeleteIsLoading}
        buttonColor="error"
        width={500}
      >
        <Grid item xs={12}>
          <Typography>{t('crm.deleteEntityContent')}</Typography>
        </Grid>
      </ActionDialog>
    );
  },
);
