import { Stack, Box, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { memo, useCallback } from 'react';

interface ICustomHalfPieChartItem {
  name: string;
  value1: number;
  currency?: string;
  totalValue: number;
  value2Format?: {
    count: number;
    prefix: string;
    suffix: string;
  };
}

interface ICustomHalfPieChart {
  data: ICustomHalfPieChartItem[];
  title: string;
  subtitle: string;
  description: string;
  theme: string;
  flexBasis: string;
}

const CustomHalfPieChart = (props: ICustomHalfPieChart) => {
  const theme = useTheme();
  const palette = theme.palette as Record<string, any>;
  const hexColor = palette[props.theme].main;

  // const endAngle = useCallback(() => {
  //   return (props.data[0].value1 / props.data[0].totalValue) * 180;
  // }, [props.data]);

  const getFontSize = (value: number) => {
    const length = value.toString().length;
    if (length > 6) return '1.2rem';
    if (length > 4) return '1.5rem';
    return '2rem';
  };

  console.log(props.data, 'props.data');

  return (
    <Stack
      direction={'column'}
      gap={1}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={props.flexBasis}
      width={'100%'}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {props.subtitle}
        </Typography>
      </Box>

      <Stack
        position={'relative'}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'flex-end'}
      >
        <ResponsiveContainer height={160}>
          <PieChart>
            <Pie
              // data={props.data}
              data={[
                {
                  name: 'A',
                  value1: 21361.4,
                  totalValue: 21361400,
                  currency: '₺',
                },
              ]}
              innerRadius={60}
              outerRadius={80}
              dataKey="value1"
              startAngle={180}
              endAngle={0}
            >
              {props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={hexColor} stroke={hexColor} strokeWidth={1} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <Box
          position={'absolute'}
          bottom={'5%'}
          left={'50%'}
          width={'100%'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            transform: 'translate(-50%)',
            mb: 0,
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontWeight: 500, fontSize: getFontSize(props.data[0].value1) }}
          >
            {props.data?.[0]?.currency} {props.data[0].value1}
          </Typography>

          <Typography variant="caption" sx={{ color: 'text.secondary', mt: 2, mb: 1.5 }}>
            {props.description}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '6px',
              bgcolor: `${props.theme}.extraLight`,
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: 400, color: `${props.theme}.main`, px: 1 }}
            >
              {props.data[0].value2Format?.prefix}
              {props.data[0].value2Format?.count || 0}
              {props.data[0].value2Format?.suffix}
            </Typography>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default memo(CustomHalfPieChart);
