/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, useMediaQuery } from '@mui/material';
import { IAppointmentDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { AppointmentDetailDrawerLeftPanelHeaderComponent } from './AppointmentDetailDrawer-leftPanel-header.component';
import { AppointmentDetailDrawerLeftPanelContactDetailComponent } from './AppointmentDetailDrawer-leftPanel-contactDetail.component';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppointmentDetailDrawerLeftPanelAttendeesComponent } from './AppointmentDetailDrawer-leftPanel-attendees.componen';

export interface IAppointmentDetailDrawerLeftPanelComponentProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppointmentDetailDrawerLeftPanelComponent = (
  props: IAppointmentDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <DefaultDetailDrawerLeftPanelComponent<IAppointmentDetailResult>
      entityData={props.appointmentDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <AppointmentDetailDrawerLeftPanelHeaderComponent
        appointmentDetailData={props.appointmentDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <AppointmentDetailDrawerLeftPanelContactDetailComponent
        appointmentDetailData={props.appointmentDetailData}
        setOpen={props.setOpen}
      />

      {/* Attendees */}
      <AppointmentDetailDrawerLeftPanelAttendeesComponent
        appointmentDetailData={props.appointmentDetailData}
        setOpen={props.setOpen}
      />

      <Divider />

      {/* <Button
        variant="contained"
        color="secondary"
        sx={{
          width: { xs: '100%', sm: '200px', md: '200px', lg: '100%' },
          height: 32,
          fontSize: { xs: '13px', md: '13px' },
          lineHeight: 1,
          borderRadius: '24px',
        }}
        onClick={() => navigate(viewAppointmentRoute(props.appointmentDetailData?._id ?? ''))}
      >
        {t('crm.calendar.appointment.detail')}
      </Button> */}
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
