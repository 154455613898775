import { Checkbox, FormControlLabel, IconButton, Stack } from '@mui/material';
import { PermissionActionScope } from 'corede-common';
import { IPermission, PermissionSubject } from 'corede-common-cocrm';
import { getTranslatedEnumValue } from 'localization';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { PermissionHelper } from '../helper/permission.helper';
import { IUseStatePropagationProp } from 'interfaces/useState-propagation-props.interface';
import { Close, LockOpen, Lock } from '@mui/icons-material';
import { toCamelCase } from 'utils/string';

export interface IPermissionSubjectOfScopeStackComponentProps {
  subject: PermissionSubject;
  scope: PermissionActionScope;
  permissions: IPermission[];
  downMd: boolean;
  downLg: boolean;
  selectedPermissionIds: string[];
  rolePermissionIds: string[];
  openedPermissionsState: IUseStatePropagationProp<string[]>;
  handleChangeCheckbox: (permissionId: string) => void;
}

export const PermissionSubjectOfScopeStackComponent = (
  props: IPermissionSubjectOfScopeStackComponentProps,
) => {
  return (
    <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
      {props.permissions.map((permission) => {
        // const isManage = permission.action === PermissionAction.manage;
        // const isView = permission.action === PermissionAction.view;
        // const relatedPermissions = isManage
        //   ? ['create', 'delete', 'update']
        //   : isView
        //     ? ['list', 'detail']
        //     : [];

        // const relatedPermissionIds =
        //   groupedPermissionsAll[subject]
        //     ?.filter(
        //       (p) =>
        //         relatedPermissions.includes(p.action) && p.actionScope === permission.actionScope,
        //     )
        //     .map((p) => p._id) || [];

        // const allRelatedChecked = relatedPermissionIds.every((id) =>
        //   selectedPermissionIds.includes(id),
        // );
        // const someRelatedChecked = relatedPermissionIds.some((id) =>
        //   selectedPermissionIds.includes(id),
        // );

        // isManage || isView
        //   ? allRelatedChecked
        //     ? true
        //     : someRelatedChecked
        //       ? 'indeterminate'
        //       : false
        //   : selectedPermissionIds.includes(permission._id);

        const isSelected = props.selectedPermissionIds.includes(permission._id);
        const isIncludedInRole = PermissionHelper.isIncludesRole(
          props.rolePermissionIds,
          permission._id,
        );
        const isIncludedOpenedPermissions = props.openedPermissionsState.state.includes(
          permission._id,
        );

        const isChecked = isSelected;
        const isDisabled = isIncludedInRole && !isIncludedOpenedPermissions;

        return (
          <Stack direction={'row'} alignItems={'center'} key={permission._id}>
            <FormControlLabel
              key={permission._id}
              onClick={(event) => {
                event.stopPropagation();
              }}
              control={
                <Checkbox
                  id={permission._id}
                  checked={isChecked}
                  disabled={isDisabled}
                  // indeterminate={isIndeterminate}
                  onChange={(event) => {
                    // props.handleChangeCheckbox({
                    //   checkState: event.target.checked,
                    //   permission: permission,
                    //   // relatedPermissionIds: relatedPermissionIds,
                    //   relatedPermissionIds: [],
                    // });
                    props.handleChangeCheckbox(permission._id);
                  }}
                  icon={
                    isIncludedInRole ? (
                      <Close
                        sx={{
                          fontSize: '15px',
                          m: '2.5px',
                          border: '2px solid',
                          borderRadius: '3px',
                        }}
                      />
                    ) : undefined
                  }
                />
              }
              label={toCamelCase(getTranslatedEnumValue(permission.action))}
              sx={{
                ml: { xs: 0, sm: '10px' },
                '.MuiFormControlLabel-label': {
                  fontSize: { xs: '11px', md: 'small' },
                  fontWeight: 'bold',
                },
              }}
            />
            {isIncludedInRole && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  isIncludedOpenedPermissions
                    ? props.openedPermissionsState.setState(
                        props.openedPermissionsState.state.filter((id) => id !== permission._id),
                      )
                    : props.openedPermissionsState.setState([
                        ...props.openedPermissionsState.state,
                        permission._id,
                      ]);
                }}
                sx={{ ml: -2 }}
              >
                {isIncludedOpenedPermissions ? (
                  <Lock sx={{ fontSize: '16px', color: 'primary' }} />
                ) : (
                  <LockOpen sx={{ fontSize: '16px', color: 'primary' }} />
                )}
              </IconButton>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
