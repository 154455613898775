import React, { useState } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  useQuery,
  useMutation,
} from '@apollo/client';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import parse from 'html-react-parser';
import { FilledButton } from 'components';
import ActionDialog from 'components/dialog/ActionDialog';
import { useTranslation } from 'react-i18next';

interface IUiElementSettings {
  label: string;
  class: string;
  leadField: keyof IFormInput;
  placeHolder: string;
  required: boolean;
  type: string;
}

interface ILeadFormDetailPublic {
  _id: string;
  uiElementSettings: IUiElementSettings[];
  submitButtonText: string;
  submitButtonBackgroundColor: string;
  submitButtonTextColor: string;
  followUpMessage: string;
  followUpRedirectUrl: string;
}

interface IFormInput {
  fullName: string;
  email: string;
  phoneNumber?: string;
  company?: string;
  position?: string;
  website?: string;
  sector?: string;
  city?: string;
  state?: string;
  country?: string;
}

const LEAD_FORM_DETAIL_PUBLIC = gql`
  query LeadFormDetailPublic {
    leadFormDetailPublic {
      _id
      uiElementSettings {
        label
        class
        leadField
        placeHolder
        required
        type
      }
      submitButtonText
      submitButtonBackgroundColor
      submitButtonTextColor
      followUpMessage
      followUpRedirectUrl
    }
  }
`;

const LEAD_CREATE_FROM_LEAD_FORM_PUBLIC = gql`
  mutation LeadCreateFromLeadFormPublic($input: LeadCreateFromLeadFormPublicInput!) {
    leadCreateFromLeadFormPublic(input: $input) {
      success
    }
  }
`;

const LeadFormContent = () => {
  const { t } = useTranslation();
  const [openFollowUpMessage, setOpenFollowUpMessage] = useState(false);

  const { control, handleSubmit } = useForm<IFormInput>();
  const { loading, error, data } = useQuery<{ leadFormDetailPublic: ILeadFormDetailPublic }>(
    LEAD_FORM_DETAIL_PUBLIC,
  );

  const [createLeadForm, { loading: mutationLoading }] = useMutation(
    LEAD_CREATE_FROM_LEAD_FORM_PUBLIC,
  );

  const onSubmit = async (formData: IFormInput) => {
    try {
      const response = await createLeadForm({
        variables: {
          input: {
            ...formData,
          },
        },
      });

      if (response.data?.leadCreateFromLeadFormPublic?.success) {
        setOpenFollowUpMessage(true);
      }
    } catch (error) {
      console.error('Mutation error:', error);
      alert('Error submitting form');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Container maxWidth="sm">
      <Card sx={{ p: 3, mt: 4, boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, my: 1 }}>
            {/* Headers first */}
            {data?.leadFormDetailPublic?.uiElementSettings
              .filter((element) => element.type === 'header')
              .map((element, index) => (
                <Typography key={`header-${index}`} variant="h6" component="h2">
                  {element.label}
                </Typography>
              ))}

            {/* Paragraphs second */}
            {data?.leadFormDetailPublic?.uiElementSettings
              .filter((element) => element.type === 'paragraph')
              .map((element, index) => (
                <Typography key={`paragraph-${index}`} variant="body1" sx={{ mt: 1, mb: 2 }}>
                  {parse(element.label || '')}
                </Typography>
              ))}

            {/* Form fields last */}
            {data?.leadFormDetailPublic?.uiElementSettings
              .filter((element) => element.type === 'fieldInput')
              .map((element, index) => (
                <>
                  <InputLabel
                    htmlFor={element.leadField}
                    sx={{ ml: 1, mb: -0.5, fontSize: 'small', cursor: 'pointer' }}
                  >
                    {element.label}
                    <Box component="span" sx={{ color: 'error.main' }}>
                      {element.required && '*'}
                    </Box>
                  </InputLabel>
                  <Controller
                    key={`field-${index}`}
                    name={element.leadField}
                    control={control}
                    defaultValue=""
                    rules={{ required: element.required }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        placeholder={element.placeHolder}
                        fullWidth
                        size="small"
                        required={element.required}
                        error={!!error}
                        helperText={error ? 'This field is required' : ''}
                      />
                    )}
                  />
                </>
              ))}

            {/* <Button type="submit" variant="contained" color="primary" fullWidth>
              {data?.leadFormDetailPublic?.submitButtonText || 'Submit'}
            </Button> */}
            <Box sx={{ mt: 2 }} />
            <FilledButton
              color={'primary'}
              disabled={!data || mutationLoading}
              loading={loading || mutationLoading}
              title={data?.leadFormDetailPublic?.submitButtonText || 'Submit'}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </Card>

      <ActionDialog
        open={openFollowUpMessage}
        title={t('crm.lead.leadForm.followUpMessage')}
        onClose={() => {
          setOpenFollowUpMessage(false);
          data?.leadFormDetailPublic?.followUpRedirectUrl &&
            window.open(data.leadFormDetailPublic.followUpRedirectUrl, '_self');
        }}
        buttonCancelTitle={t('close')}
        width={500}
      >
        <Typography variant="body1">{data?.leadFormDetailPublic?.followUpMessage}</Typography>
      </ActionDialog>
    </Container>
  );
};

const LeadFormPublic = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `${token}`,
    },
  });

  return (
    <ApolloProvider client={client}>
      <LeadFormContent />
    </ApolloProvider>
  );
};

export default LeadFormPublic;
