import { dashboardDomainRoutes } from '../domains/01-dashboard/routes/dashboard.base.route';
import { DashboardDomainRouter } from '../domains/01-dashboard/routes/DashboardDomainRouter';

import { organizationalChartDomainRoutes } from '../domains/02-organizationalChart/routes/organizationalChart.base.route';
import { OrganizationalChartDomainRouter } from '../domains/02-organizationalChart/routes/OrganizationalChartDomainRouter';

import { leadDomainRoutes } from '../domains/03-lead/routes/lead.base.route';
import { LeadDomainRouter } from '../domains/03-lead/routes/LeadDomainRouter';
import { customerDomainRoutes } from '../domains/04-customer/routes/customer.base.route';
import { CustomerDomainRouter } from '../domains/04-customer/routes/CustomerDomainRouter';
import { preSalesDomainRoutes } from '../domains/05-preSale/routes/preSales.base.route';
import { PreSalesDomainRouter } from '../domains/05-preSale/routes/PreSalesDomainRouter';
import { salesDomainRoutes } from '../domains/06-sale/routes/sales.base.route';
import { SalesDomainRouter } from '../domains/06-sale/routes/SalesDomainRouter';
import { productsAndServicesDomainRoutes } from '../domains/08-productAndService/routes/productsAndServices.base.route';
import { ProductsAndServicesDomainRouter } from '../domains/08-productAndService/routes/ProductsAndServicesDomainRouter';
import { expenseDomainRoutes } from '../domains/09-expense/routes/expense.base.route';
import { ExpenseDomainRouter } from '../domains/09-expense/routes/ExpenseDomainRouter';
import { projectsDomainRoutes } from '../domains/10-project/routes/projects.base.route';
import { ProjectsDomainRouter } from '../domains/10-project/routes/ProjectsDomainRouter';
import { tasksDomainRoutes } from '../domains/11-task/routes/tasks.base.route';
import { TasksDomainRouter } from '../domains/11-task/routes/TasksDomainRouter';
import { calendarDomainRoutes } from '../domains/12-calendar/routes/calendar.base.route';
import { CalendarDomainRouter } from '../domains/12-calendar/routes/CalendarDomainRouter';
import { reportsDomainRoutes } from '../domains/13-report/routes/reports.base.route';
import { ReportsDomainRouter } from '../domains/13-report/routes/ReportsDomainRouter';
import { supportDomainRoutes } from '../domains/15-support/routes/support.base.route';
import { SupportDomainRouter } from '../domains/15-support/routes/SupportDomainRouter';
import { ContractDomainRouter } from '../domains/07-contract/routes/ContractDomainRouter';
import { contractDomainRoutes } from '../domains/07-contract/routes/contract.base.route';

import CrmLayout from '../layouts/CrmLayout';
import { Route } from 'react-router-dom';
import { pricingDomainRoutes } from '../domains/16-pricing/routes/pricing.base.route';
import { PricingDomainRouter } from '../domains/16-pricing/routes/PricingDomainRouter';
import { notesDomainRoutes } from '../domains/14-note/routes/notes.base.route';
import { NotesDomainRouter } from '../domains/14-note/routes/NotesDomainRouter';
import { PaymentSuccess } from '../domains/16-pricing/subdomains/pricing/pages/PaymentSuccess';
import { PaymentFailure } from '../domains/16-pricing/subdomains/pricing/pages/PaymentFailure';
import { ForbiddenRoute, ProtectedDomainRoute } from 'permission/ProtectedRoute';
import { PermissionDomain } from 'corede-common-cocrm';
import LeadFormPublic from 'apps/auth/pages/LeadFormPublic';

export const CrmRouter = () => {
  
  return (
    <>
      <Route path="/" element={<CrmLayout />}>
        <Route
          index
          element={
            <ProtectedDomainRoute domain={PermissionDomain.dashboard}>
              <DashboardDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${dashboardDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.dashboard}>
              <DashboardDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${organizationalChartDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.user}>
              <OrganizationalChartDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${leadDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.lead}>
              <LeadDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${customerDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.customer}>
              <CustomerDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${preSalesDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.presale}>
              <PreSalesDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${salesDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.sale}>
              <SalesDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${contractDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.contract}>
              <ContractDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${productsAndServicesDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.product}>
              <ProductsAndServicesDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${expenseDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.expense}>
              <ExpenseDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${projectsDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.project}>
              <ProjectsDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${tasksDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.task}>
              <TasksDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${calendarDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.calendar}>
              <CalendarDomainRouter />
            </ProtectedDomainRoute>
            // <ProtectedDomainListRoute
            //   domains={[PermissionDomain.reminder, PermissionDomain.appointment]}
            // >
            //   <CalendarDomainRouter />
            // </ProtectedDomainListRoute>
          }
        />
        <Route
          path={`${reportsDomainRoutes.base}/*`}
          element={
            <ForbiddenRoute>
              <ReportsDomainRouter />
            </ForbiddenRoute>
          }
        />
        <Route
          path={`${notesDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.note}>
              <NotesDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route
          path={`${supportDomainRoutes.base}/*`}
          element={
            <ProtectedDomainRoute domain={PermissionDomain.ticket}>
              <SupportDomainRouter />
            </ProtectedDomainRoute>
          }
        />
        <Route path={`${pricingDomainRoutes.base}/*`} element={<PricingDomainRouter />} />
        {/* Todo For Generation */}
        {/* <Route
        path={`${userDomainRoutes.base}/*`}
        element={<UsersDomainRouter />}
      /> */}
        <Route
          path="*"
          element={
            <ProtectedDomainRoute domain={PermissionDomain.dashboard}>
              <DashboardDomainRouter />
            </ProtectedDomainRoute>
          }
        />
      </Route>
      <Route path="/payment-success" element={<PaymentSuccess />} />
      <Route path="/payment-failed" element={<PaymentFailure />} />
      <Route path="/lead-form" element={<LeadFormPublic />} />
    </>
  );
};
