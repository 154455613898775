import userLocale from '../subdomains/user/locales/tr';
import departmentLocale from '../subdomains/department/locales/tr';
import roleLocale from '../subdomains/role/locales/tr';
import permissionLocale from '../subdomains/permission/locales/tr';

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  user: {
    ...userLocale,
  },
  department: {
    ...departmentLocale,
  },
  role: {
    ...roleLocale,
  },
  permission: {
    ...permissionLocale,
  },
};

export default locale;
