/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Divider, Stack, Typography, Box } from '@mui/material';
import { Iconify } from 'components';
import { ILeadDetailResult, ProposalProspectTargetEntityRef } from 'corede-common-cocrm';
import React from 'react';
import { t } from 'i18next';
import { useProposalListQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';

export interface ILeadDetailDrawerLeftPanelProposalComponentProps {
  leadDetailData: ILeadDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  visible?: boolean;
}

/**
 * Container for views in proposal list section of left panel header
 */
export const LeadDetailDrawerLeftPanelProposalComponent = (
  props: ILeadDetailDrawerLeftPanelProposalComponentProps,
) => {
  const { data: proposalData } = useProposalListQuery(
    {
      input: {
        filter: {
          prospectTargetEntityRef: ProposalProspectTargetEntityRef.Lead,
          prospectTargetEntityId: props.leadDetailData?._id,
        },
        pagination: {
          page: 1,
          pageSize: 1,
        },
      },
    },
    {
      skip: !props.visible || !props.leadDetailData?._id,
    },
  );

  return props.visible ? (
    <Stack direction={'column'} width={'100%'} gap={2}>
      <Stack direction={'column'} width={'100%'} gap={1}>
        {proposalData?.data && proposalData?.data?.length > 0 ? (
          <Stack direction={'row'} gap={2} alignItems={'center'} width={'100%'}>
            <Box
              sx={{
                width: 32,
                height: 32,
                bgcolor: 'secondary.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify icon="nimbus:money" width={18} sx={{ color: 'secondary.main' }} />
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  lineHeight: 1,
                  color: 'text.primary',
                }}
              >
                {proposalData?.data?.[0]?.totalPrice?.total || 0}{' '}
                {proposalData?.data?.[0]?.currency}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {t('crm.lastProposal')}
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Stack direction={'row'} gap={2} alignItems={'center'} width={'100%'}>
            <Box
              sx={{
                width: 32,
                height: 32,
                bgcolor: 'secondary.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify icon="nimbus:money" width={18} sx={{ color: 'secondary.main' }} />
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  lineHeight: 1,
                  color: 'text.primary',
                }}
              >
                0
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {t('crm.noProposal')}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Stack>
  ) : null;
};
