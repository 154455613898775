/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EstimateStatus, IEstimateDetailResult, PermissionSubject } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage } from 'localization';
import { useEstimateSentMutation } from '../../context/estimate.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError, PermissionAction } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import ActionDialog from 'components/dialog/ActionDialog';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IEstimateDetailDrawerLeftPanelActionsComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert estimate to customer
 */
export const EstimateDetailDrawerLeftPanelActionsComponent = (
  props: IEstimateDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openSure, setOpenSure] = React.useState(false);
  const status = props.estimateDetailData?.status;

  const [
    estimateSent,
    { data: estimateSentData, isLoading: estimateSentLoading, error: estimateSentError },
  ] = useEstimateSentMutation();

  const handleSend = () => {
    estimateSent({
      input: {
        _id: props.estimateDetailData?._id ?? '',
      },
    });
  };

  useEffect(() => {
    if (estimateSentData) {
      enqueueSnackbar(t('crm.preSale.estimate.sentSuccessfully'), {
        variant: 'success',
      });
      setOpenSure(false);
    }
  }, [estimateSentData]);

  useEffect(() => {
    if (estimateSentError) {
      DefaultErrorHandlerUseEffect(estimateSentError as IBaseError, currentLanguage);
      setOpenSure(false);
    }
  }, [estimateSentError]);
  
  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Estimate base actions */}
      {/* TODO: If there is no permissions we are not loading below views, is it ok? */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.estimate,
          action: PermissionAction.update,
        }}
      >
        <Button
          onClick={() => setOpenSure(true)}
          color="primary"
          variant="contained"
          disabled={status !== EstimateStatus.draft}
          sx={{
            height: 40,
            fontSize: '13px',
            borderRadius: 100,
          }}
        >
          <Icons.RefreshRounded sx={{ mr: 1 }} />
          {t('crm.preSale.estimate.send')}
        </Button>
      
        <ActionDialog
          open={openSure}
          setOpen={setOpenSure}
          title={t('crm.preSale.estimate.sendSureTitle')}
          buttonTitle={t('crm.preSale.estimate.send')}
          loading={estimateSentLoading}
          handleClick={() => {
            handleSend();
          }}
        >
          <p>{t('crm.preSale.estimate.sendSureDesc')}</p>
        </ActionDialog>
      </PermissionWrapper>
    </Stack>
  );
};
