/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Stack, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { Iconify } from 'components';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import DetailBox from 'components/box/DetailBox';
import { ICustomerDetailResult, PermissionSubject } from 'corede-common-cocrm';
import { t } from 'i18next';
import { memo, useState } from 'react';
import ContactCreateWrapper from '../../../contact/pages/create/ContactCreateDrawer.wrapper';
import ContactDeleteDialog from '../../../contact/pages/delete/ContactDeleteDialog';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionAction } from 'corede-common';
import { PermissionWrapper } from 'permission/PermissionWrapper';

interface ICustomerDetailTabContactInfoSectionComponentProps
  extends DefaultTabComponentProps<ICustomerDetailResult> {
  customerData: ICustomerDetailResult | undefined;
  tabKey: 'contactInformation';
  name: 'crm.contactInformation';
}

export const CustomerDetailTabContactInfoSectionComponentProps = memo(
  (props: ICustomerDetailTabContactInfoSectionComponentProps) => {
    const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const [openContactCreate, setOpenContactCreate] = useState(false);
    const [openContactDelete, setOpenContactDelete] = useState(false);
    const [selectedItemForDelete, setSelectedItemForDelete] = useState<any>(null); //TODO

    const contactData = [
      {
        title: t('crm.firstName'),
        value: props.customerData?.primaryContact?.firstName,
        icon: 'clarity:map-marker-line',
      },
      {
        title: t('crm.lastName'),
        value: props.customerData?.primaryContact?.lastName,
        icon: 'solar:city-broken',
      },
      {
        title: t('crm.email'),
        value: props.customerData?.primaryContact?.email,
        icon: 'mage:location',
      },
      {
        title: t('crm.phone'),
        value: props.customerData?.primaryContact?.phone,
        icon: 'tabler:language',
      },
      {
        title: t('crm.position'),
        value: props.customerData?.primaryContact?.position,
        icon: 'tabler:language',
      },
    ];

    return (
      <Grid container mt={downMd ? 0 : 2} spacing={2.5} mb={2} px={{ xs: 0, lg: 2 }}>
        <Grid item xs={12}>
          <Stack direction={'column'} p={2.5} sx={{ borderRadius: 3 }}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography
                variant={'caption'}
                sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
              >
                {t('crm.contactInformation')}
              </Typography>
              <PermissionWrapper
                check={{
                  subject: PermissionSubject.customer,
                  action: PermissionAction.update,
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: 'none',
                    fontSize: '12px',
                    fontWeight: 500,
                    padding: '6px 12px',
                    height: 'auto',
                  }}
                  onClick={() => setOpenContactCreate(true)}
                >
                  {t('crm.customer.customer.addContact')}
                </Button>
              </PermissionWrapper>
            </Stack>
            <Grid container spacing={1}>
              {contactData.map((item) => (
                <Grid item xs={12} md={6}>
                  <Stack direction={'row'} gap={2} alignItems={'center'}>
                    <Box
                      sx={{
                        width: 30,
                        height: 30,
                        borderRadius: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                    </Box>
                    <Stack direction={'column'}>
                      <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                        {item.value ?? '-'}
                      </Typography>
                      <Typography variant="caption" color={'text.secondary'}>
                        {item.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Grid>

        {props.customerData?.otherContactList &&
          props.customerData?.otherContactList?.length > 0 &&
          props.customerData?.otherContactList.map((item, index) => {
            const contactData = [
              {
                title: t('crm.firstName'),
                value: item.firstName,
                icon: 'clarity:map-marker-line',
              },
              {
                title: t('crm.lastName'),
                value: item.lastName,
                icon: 'solar:city-broken',
              },
              {
                title: t('crm.email'),
                value: item.email,
                icon: 'mage:location',
              },
              {
                title: t('crm.phone'),
                value: item.phone,
                icon: 'tabler:language',
              },
              {
                title: t('crm.position'),
                value: item.position,
                icon: 'tabler:language',
              },
            ];
            return (
              <Grid item xs={12}>
                <Stack direction={'column'} p={2.5} sx={{ borderRadius: 3 }}>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography
                      variant={'caption'}
                      sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
                    >
                      {t('crm.customer.customer.otherContacts')} {index + 1}
                    </Typography>

                    <PermissionWrapper
                      check={{
                        subject: PermissionSubject.customer,
                        action: PermissionAction.update,
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        sx={{
                          textTransform: 'none',
                          fontSize: '12px',
                          fontWeight: 500,
                          padding: '6px 12px',
                          height: 'auto',
                        }}
                        onClick={() => {
                          setOpenContactDelete(true);
                          setSelectedItemForDelete(item);
                        }}
                      >
                        {t('crm.delete')}
                      </Button>
                    </PermissionWrapper>
                  </Stack>
                  <Grid container spacing={1}>
                    {contactData.map((item) => (
                      <Grid item xs={12} md={6}>
                        <Stack direction={'row'} gap={2} alignItems={'center'}>
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              borderRadius: '6px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                          </Box>
                          <Stack direction={'column'}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                              color={'text.primary'}
                            >
                              {item.value ?? '-'}
                            </Typography>
                            <Typography variant="caption" color={'text.secondary'}>
                              {item.title}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>
            );
          })}

        <ContactCreateWrapper
          open={openContactCreate}
          setOpen={setOpenContactCreate}
          customerId={props.customerData?._id}
        />

        <ContactDeleteDialog
          open={openContactDelete}
          onClose={() => setOpenContactDelete(false)}
          item={selectedItemForDelete}
          customerId={props.customerData?._id}
        />
      </Grid>
    );
  },
);
