/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography } from '@mui/material';
import { ProjectStatus } from 'corede-common-cocrm';
import { getTranslatedEnumValue } from 'localization';

export interface IProjectDetailDrawerLeftPanelHeaderTagsComponentProps {
  status?: ProjectStatus;
}

/**
 * Container for views representing tag like information at the top of the left panel header
 */
export const ProjectDetailDrawerLeftPanelHeaderTagsComponent = (
  props: IProjectDetailDrawerLeftPanelHeaderTagsComponentProps,
) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={1}
      width={{ xs: 'auto', lg: '100%' }}
    >
      <Box
        sx={{
          border: '2px solid',
          borderColor: 'secondary.main',
          borderRadius: '10px',
          py: 0.5,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          px: 1,
        }}
      >
        <Typography
          fontSize={'12px'}
          textAlign={'center'}
          fontWeight={'bold'}
          color={'secondary.main'}
        >
          {getTranslatedEnumValue(props.status as ProjectStatus)}
        </Typography>
      </Box>
    </Stack>
  );
};
