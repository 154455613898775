import { demoSlice } from "apps/settings/domains/settings/context";
import { dashboardSlice } from "apps/crm/domains/01-dashboard/context";
import { organizationalChartSlice } from "apps/crm/domains/02-organizationalChart/context";
import { leadSlice } from "apps/crm/domains/03-lead/context";
import { customerSlice } from "apps/crm/domains/04-customer/context";
import dataReducer from "./slices/data";

import { configureStore } from "@reduxjs/toolkit";
import { commonApi } from "./commonApi";
import { authSlice } from "apps/auth/context";
import { themeSlice, languageSlice, routeSlice } from "./slices";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    auth: authSlice,
    lead: leadSlice,
    theme: themeSlice,
    language: languageSlice,
    route: routeSlice,
    dashboard: dashboardSlice,
    organizationalChart: organizationalChartSlice,
    customer: customerSlice,
    demo: demoSlice,
    data: dataReducer,
  
    [commonApi.reducerPath]: commonApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(commonApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
