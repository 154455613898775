import { useState, lazy, Suspense } from 'react';

const LegalAgreementCreateDrawer = lazy(() => import('./LegalAgreementCreateDrawer'));

const LegalAgreementCreateDrawerWrapper = ({ open, setOpen, selectedType }: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <LegalAgreementCreateDrawer open={open} setOpen={setOpen} selectedType={selectedType} />
        </Suspense>
      )}
    </>
  );
};

export default LegalAgreementCreateDrawerWrapper;
