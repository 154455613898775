const locale = {
  // actions
  create: 'Müşteri Oluştur',
  update: 'Müşteri Güncelle',
  customerDetail: 'Müşteri Detayı',
  view: 'Müşteri Görüntüle',
  list: 'Müşterileri Listele',
  viewMüşteri: 'Görüntüle',
  createMüşteri: 'Müşteri Oluştur',
  addNewRole: 'Yeni Rol Ekle',
  tagsPlaceholder: 'Etiket ekle',
  listInfo: 'Tüm müşterilerin listesi.',
  weeklyReport: 'Haftalık Rapor',
  copyBillingInfo: 'Fatura Bilgisini Kopyala',

  customerListTitle: 'Müşteri Listesi',
  title: 'Müşteriler',
  customers: 'Müşteriler',
  headerTitle: 'Müşteriler',

  columnParentCategory: 'Üst Kategori',
  columnActions: 'Aksiyon',
  emptyState1: 'Müşteriler bulunamadı',
  emptyState2: 'Lütfen yeni bir müşteri oluşturun',

  relatedCustomers: 'İlgili Müşteriler',
  importCustomer: 'Müşteri İçe Aktar',
  import: 'Müşteri İçe Aktar',
  importTitle: 'Müşterileri İçe Aktar',
  createCustomerTitle: 'Müşteri Oluştur',
  b2bB2c: 'B2B / B2C',
  customerNameLabel: 'Müşteri Adı',
  customerEmailLabel: 'Müşteri E-posta',
  customerPhoneLabel: 'Müşteri Telefonu',
  customerTypeLabel: 'Müşteri Türü',
  customerPictureLabel: 'Müşteri Resmi',
  otherContacts: 'Diger İletişimler',

  customerLanguageLabel: 'Müşteri Dili',
  uploadPictureText: 'Resim yükle',
  createCustomerButton: 'Müşteri Oluştur',
  createSuccess: 'Müşteri başarıyla oluşturuldu',
  deleteConfirm: 'Bu müşteriyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  deletedSuccessfully: 'Müşteri başarıyla silindi',

  selectIconTitle: 'Bir Simge Seç',
  searchIcons: 'Simgeleri Ara',
  searchForAnIcon: 'Bir simge ara',

  //accordions
  primaryContact: 'Birincil İletişim*',
  primaryBillingInfo: 'Birincil Fatura Bilgisi',
  primaryShippingInfo: 'Birincil Teslimat Bilgisi',
  detailInformation: 'Detaylı Bilgi',
  address: 'Adres',

  // labels
  welcome: 'Hoş geldiniz',
  description: 'Bu hafta müşterilerdeki ilerlemeniz muhteşem. Devam edelim.',

  createdSuccessfully: 'Müşteri başarıyla oluşturuldu',
  updatedSuccessfully: 'Müşteri başarıyla güncellendi',
  createInfo: 'Buradan yeni müşteriler oluşturabilirsiniz.',
  updateCustomerSuccess: 'Müşteri başarıyla güncellendi',
  createCustomerSuccess: 'Müşteri başarıyla oluşturuldu',
  importText1: 'Bu sayfadan müşterileri içe aktarabilirsiniz.',
  importText2: 'Müşterilerinizi yayınlamak için aşağıdaki örnek formu kullanabilirsiniz.',
  customer: 'Müşteri',
  assigneds: 'Atananlar',
  qualification: 'Nitelik',
  justOwn: 'Sadece Kendi',
  isJunk: 'Çöp mü',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Çöp',
  unJunk: 'Çöp Değil',
  prospectName: 'Aday Adı',
  businessType: 'İş Türü',
  customerCount: 'müşteriler',
  customerValue: 'Müşteri Değeri',
  addContact: 'İletişim Ekle',
  contactedDate: 'İletişim Tarihi',
  emptyStateContactContent1: 'Burada iletişim bulunamadı.',
  contactDetail: 'İletişim Detayı',
  contactHistory: 'İletişim Geçmişi',
  lastContacted: 'Son İletişim',
  lastContact: 'Son İletişim',
  contacted: 'İletişim Kuruldu',
  onSiteEstimateWith: 'Yerinde tahmin ile',
  prospectType: 'Aday Türü',
  prospectAddress: 'Aday Adresi',
  prospectContactName: 'Aday İletişim Adı',
};

export default locale;
