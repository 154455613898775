import {
  contractTemplateListQuery,
  IContractTemplateListRequest,
  IContractTemplateListResponse,
  IContractTemplateListInput,
  IContractTemplateListResult,
  contractTemplateDetailQuery,
  IContractTemplateDetailRequest,
  IContractTemplateDetailResponse,
  IContractTemplateDetailInput,
  IContractTemplateDetailResult,
  contractTemplateCreateQuery,
  IContractTemplateCreateRequest,
  IContractTemplateCreateResponse,
  IContractTemplateCreateInput,
  IContractTemplateCreateResult,
  contractTemplateUpdateQuery,
  IContractTemplateUpdateRequest,
  IContractTemplateUpdateResponse,
  IContractTemplateUpdateInput,
  IContractTemplateUpdateFilterInput,
  IContractTemplateUpdateResult,
  contractTemplateDeleteQuery,
  IContractTemplateDeleteRequest,
  IContractTemplateDeleteInput,
  IContractTemplateDeleteResponse,
  IContractTemplateDeleteResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { contractTemplateTags } from './contractTemplate.tags';
import { IGraphqlVariables } from 'corede-common';

export const contractTemplateApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    contractTemplateList: builder.query<
      IContractTemplateListResult,
      IGraphqlVariables<IContractTemplateListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractTemplateListRequest,
        IContractTemplateListResponse,
        IContractTemplateListResult,
        IContractTemplateListInput
      >({
        query: contractTemplateListQuery(),
        providesTags: [
          contractTemplateTags.contractTemplates,
          contractTemplateTags.contractTemplate,
        ],
      }),
    ),

    contractTemplateDetail: builder.query<
      IContractTemplateDetailResult,
      IGraphqlVariables<IContractTemplateDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractTemplateDetailRequest,
        IContractTemplateDetailResponse,
        IContractTemplateDetailResult,
        IContractTemplateDetailInput
      >({
        query: contractTemplateDetailQuery(),
        providesTags: [
          contractTemplateTags.contractTemplates,
          contractTemplateTags.contractTemplate,
        ],
      }),
    ),

    // mutation

    contractTemplateCreate: builder.mutation<
      IContractTemplateCreateResult,
      IGraphqlVariables<IContractTemplateCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractTemplateCreateRequest,
        IContractTemplateCreateResponse,
        IContractTemplateCreateResult,
        IContractTemplateCreateInput
      >({
        query: contractTemplateCreateQuery,
        invalidatesTags: [
          contractTemplateTags.contractTemplates,
          contractTemplateTags.contractTemplate,
        ],
      }),
    ),

    contractTemplateUpdate: builder.mutation<
      IContractTemplateUpdateResult,
      IGraphqlVariables<IContractTemplateUpdateInput, IContractTemplateUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractTemplateUpdateRequest,
        IContractTemplateUpdateResponse,
        IContractTemplateUpdateResult,
        IContractTemplateUpdateInput,
        IContractTemplateUpdateFilterInput
      >({
        query: contractTemplateUpdateQuery,
        invalidatesTags: [
          contractTemplateTags.contractTemplates,
          contractTemplateTags.contractTemplate,
        ],
      }),
    ),

    contractTemplateDelete: builder.mutation<
      IContractTemplateDeleteResult,
      IGraphqlVariables<IContractTemplateDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractTemplateDeleteRequest,
        IContractTemplateDeleteResponse,
        IContractTemplateDeleteResult,
        IContractTemplateDeleteInput
      >({
        query: contractTemplateDeleteQuery,
        invalidatesTags: [contractTemplateTags.contractTemplates],
      }),
    ),
  }),
});

export const {
  useContractTemplateListQuery,
  useLazyContractTemplateDetailQuery,
  useContractTemplateDetailQuery,
  useLazyContractTemplateListQuery,

  useContractTemplateCreateMutation,
  useContractTemplateDeleteMutation,
  useContractTemplateUpdateMutation,
} = contractTemplateApi;
