import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IInvoiceListInput,
  IInvoiceListRequest,
  IInvoiceListResponse,
  IInvoiceListResult,
  invoiceListQuery,
  IInvoiceDetailInput,
  IInvoiceDetailRequest,
  IInvoiceDetailResponse,
  IInvoiceDetailResult,
  invoiceDetailQuery,

  // mutations
  IInvoiceCreateInput,
  IInvoiceCreateRequest,
  IInvoiceCreateResponse,
  IInvoiceCreateResult,
  invoiceCreateQuery,
  IInvoiceUpdateInput,
  IInvoiceUpdateRequest,
  IInvoiceUpdateResponse,
  IInvoiceUpdateResult,
  invoiceUpdateQuery,
  IInvoiceUpdateFilterInput,
  IInvoiceDeleteInput,
  IInvoiceDeleteRequest,
  IInvoiceDeleteResponse,
  IInvoiceDeleteResult,
  invoiceDeleteQuery,
  invoiceSentQuery,
  IInvoiceSentInput,
  IInvoiceSentRequest,
  IInvoiceSentResponse,
  IInvoiceSentResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { invoiceTags } from './invoice.tags';
import { IGraphqlVariables } from 'corede-common';

export const invoicesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // invoiceTags: builder.query<
    //     IInvoiceTagsResult,
    //     IGraphqlVariables<IInvoiceTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         IInvoiceTagsRequest,
    //         IInvoiceTagsResponse,
    //         IInvoiceTagsResult,
    //         IInvoiceTagsFilterInput,
    //     >({
    //         query: invoiceTagsQuery,
    //         providesTags: [invoiceTags.invoiceTags],
    //     })),

    invoiceList: builder.query<IInvoiceListResult, IGraphqlVariables<IInvoiceListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceListRequest,
        IInvoiceListResponse,
        IInvoiceListResult,
        IInvoiceListInput
      >({
        query: invoiceListQuery,
        providesTags: [invoiceTags.invoice, invoiceTags.invoices],
      }),
    ),

    invoiceDetail: builder.query<IInvoiceDetailResult, IGraphqlVariables<IInvoiceDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceDetailRequest,
        IInvoiceDetailResponse,
        IInvoiceDetailResult,
        IInvoiceDetailInput
      >({
        query: invoiceDetailQuery,
        providesTags: [invoiceTags.invoice],
      }),
    ),

    // mutations

    invoiceCreate: builder.mutation<IInvoiceCreateResult, IGraphqlVariables<IInvoiceCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceCreateRequest,
        IInvoiceCreateResponse,
        IInvoiceCreateResult,
        IInvoiceCreateInput
      >({
        query: invoiceCreateQuery,
        invalidatesTags: [invoiceTags.invoice, invoiceTags.invoices],
      }),
    ),

    invoiceUpdate: builder.mutation<
      IInvoiceUpdateResult,
      IGraphqlVariables<IInvoiceUpdateInput, IInvoiceUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceUpdateRequest,
        IInvoiceUpdateResponse,
        IInvoiceUpdateResult,
        IInvoiceUpdateInput,
        IInvoiceUpdateFilterInput
      >({
        query: invoiceUpdateQuery,
        invalidatesTags: [invoiceTags.invoice, invoiceTags.invoices],
      }),
    ),

    invoiceDelete: builder.mutation<IInvoiceDeleteResult, IGraphqlVariables<IInvoiceDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceDeleteRequest,
        IInvoiceDeleteResponse,
        IInvoiceDeleteResult,
        IInvoiceDeleteInput
      >({
        query: invoiceDeleteQuery,
        invalidatesTags: [invoiceTags.invoices],
      }),
    ),

    invoiceSent: builder.mutation<IInvoiceSentResult, IGraphqlVariables<IInvoiceSentInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IInvoiceSentRequest,
        IInvoiceSentResponse,
        IInvoiceSentResult,
        IInvoiceSentInput
      >({
        query: invoiceSentQuery,
        invalidatesTags: [invoiceTags.invoice, invoiceTags.invoices],
      }),
    ),
  }),
});

export const {
  useInvoiceListQuery,
  useLazyInvoiceListQuery,
  useInvoiceDetailQuery,
  useLazyInvoiceDetailQuery,
  useInvoiceCreateMutation,
  useInvoiceUpdateMutation,
  useInvoiceDeleteMutation,
  useInvoiceSentMutation,
} = invoicesApi;
