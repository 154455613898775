import { Box, Card, Typography } from '@mui/material';
import Lottie from 'react-lottie-player';
import Failure from 'assets/animations/failure.json';
import { useTranslation } from 'react-i18next';

export const PaymentFailure = () => {
  const { t } = useTranslation();
  localStorage.setItem('payment_status', 'fail');

  return (
    <Box sx={{ height: '90dvh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Card
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 10,
          m: 5,
        }}
      >
        <Typography variant="h6" textAlign={'center'} mb={5}>
          {t('crm.pricing.pricing.paymentFailure')}
        </Typography>
        <Lottie animationData={Failure} play loop={false} style={{ width: 150, height: 150 }} />
      </Card>
    </Box>
  );
};
