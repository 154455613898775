/* eslint-disable react-hooks/exhaustive-deps */
import { useAppointmentDetailQuery } from '../../context/appointment.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { IAppointment, IAppointmentDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { AppointmentDetailDrawerLeftPanelComponent } from './AppointmentDetailDrawer-leftPanel.component';
import { AppointmentDetailDrawerRightPanelComponent } from './AppointmentDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IAppointmentDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedAppointment: IAppointmentDetailResult & IHasId<number>;
  setSelectedAppointment: Dispatch<SetStateAction<TListGridSelectedEntity<IAppointment>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const AppointmentDetailDrawer = memo((props: IAppointmentDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: appointmentDetailData, error: appointmentDetailError } = useAppointmentDetailQuery(
    {
      input: {
        _id: props?.selectedAppointment?._id,
      },
    },
    {
      skip: !props?.selectedAppointment?._id,
    },
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(appointmentDetailError as IBaseError, currentLanguage);
  }, [appointmentDetailError]);

  return (
    <DefaultDetailDrawer<IAppointmentDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedAppointment}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.calendar.appointment.title'),
            }
      }
    >
      <AppointmentDetailDrawerLeftPanelComponent
        appointmentDetailData={appointmentDetailData}
        setOpen={props.setOpen}
      />
      <AppointmentDetailDrawerRightPanelComponent appointmentDetailData={appointmentDetailData} />
    </DefaultDetailDrawer>
  );
});

export default AppointmentDetailDrawer;
