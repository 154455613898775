/* eslint-disable react-hooks/exhaustive-deps */
import { IProjectDetailResult } from 'corede-common-cocrm';
import { ProjectDetailDrawerHeaderStatusComponent } from './ProjectDetailDrawer-rightPanel-header-status.component';
// import { ProjectDetailDrawerBaseInfoComponent } from './ProjectDetailDrawer-rightPanel-header-baseInfo.component';

export interface IProjectDetailDrawerRightPanelHeaderComponentProps {
  projectDetailData: IProjectDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ProjectDetailDrawerRightPanelHeaderComponent = (
  props: IProjectDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <ProjectDetailDrawerHeaderStatusComponent projectDetailData={props.projectDetailData} />
      {/* <ProjectDetailDrawerBaseInfoComponent projectDetailData={props.projectDetailData} /> */}
    </>
  );
};
