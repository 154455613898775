/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, useMediaQuery } from '@mui/material';
import { IEstimateDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { EstimateDetailDrawerLeftPanelHeaderComponent } from './EstimateDetailDrawer-leftPanel-header.component';
import { EstimateDetailDrawerLeftPanelContactDetailComponent } from './EstimateDetailDrawer-leftPanel-contactDetail.component';
import { EstimateDetailDrawerLeftPanelAssignedsComponent } from './EstimateDetailDrawer-leftPanel-assigned.component';

export interface IEstimateDetailDrawerLeftPanelComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EstimateDetailDrawerLeftPanelComponent = (
  props: IEstimateDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <DefaultDetailDrawerLeftPanelComponent<IEstimateDetailResult>
      entityData={props.estimateDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <EstimateDetailDrawerLeftPanelHeaderComponent
        estimateDetailData={props.estimateDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <EstimateDetailDrawerLeftPanelContactDetailComponent
        estimateDetailData={props.estimateDetailData}
        setOpen={props.setOpen}
      />

      {/* Assigned List */}
      <EstimateDetailDrawerLeftPanelAssignedsComponent
        estimateDetailData={props.estimateDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
      <Divider />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
