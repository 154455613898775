import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
// import { useLeadFormListCountQuery } from '../../context/leadForm.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import LeadFormListComponent from './LeadFormList-grid.component';
import { useLocation } from 'react-router-dom';
import { LeadFormListStatisticsView } from './LeadFormList-statistics.component';
import LeadFormCreateDrawerWrapper from '../create/LeadFormCreateDrawer.wrapper';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useLeadFormListQuery } from '../../context/leadForm.api';

const LeadFormList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openLeadFormCreateDrawer, setOpenLeadFormCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();

  // queries
  // const { data: leadFormListCountData, isLoading: leadFormListCountLoading } = useLeadFormListCountQuery({});
  const { data: leadFormListCountData, isLoading: leadFormListCountLoading } = useLeadFormListQuery({});
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});

  let totalLeadFormCount = leadFormListCountData?.count ?? 0;
  let isEmpty = totalLeadFormCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenLeadFormCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {t(`crm.lead.leadForm.title`)}
            </Typography>
          </Stack>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.leadForm.create`),
          onClick: () => setOpenLeadFormCreateDrawer(true),
        }}
      />

      <LeadFormListStatisticsView
        count={totalLeadFormCount}
        leadFormListCountLoading={leadFormListCountLoading}
      />

      <LeadFormListComponent setOpenLeadFormCreateDrawer={setOpenLeadFormCreateDrawer} />

      <LeadFormCreateDrawerWrapper
        open={openLeadFormCreateDrawer}
        setOpen={setOpenLeadFormCreateDrawer}
      />
    </Grid>
  );
});

export default LeadFormList;
