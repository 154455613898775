/* eslint-disable react-hooks/exhaustive-deps */
import { IReminderDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { ReminderDetailDrawerRightPanelHeaderComponent } from './ReminderDetailDrawer-rightPanel-header.component';
import { ReminderDetailDrawerRightPanelTabsComponent } from './ReminderDetailDrawer-rightPanel.tabs.component';

export interface IReminderDetailDrawerRightPanelComponentProps {
  reminderDetailData: IReminderDetailResult | undefined;
}

export const ReminderDetailDrawerRightPanelComponent = (
  props: IReminderDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <ReminderDetailDrawerRightPanelHeaderComponent reminderDetailData={props.reminderDetailData} />

      {/* Tabs Section */}
      <ReminderDetailDrawerRightPanelTabsComponent reminderDetailData={props.reminderDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
