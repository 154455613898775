import { useState, lazy, Suspense } from 'react';

const ContractCreateDrawer = lazy(() => import('./ContractCreateDrawer'));

const ContractCreateDrawerWrapper = ({ open, setOpen }: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ContractCreateDrawer open={open} setOpen={setOpen} />
        </Suspense>
      )}
    </>
  );
};

export default ContractCreateDrawerWrapper;
