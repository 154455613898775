import { AccordionDetailsProps, styled } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const PermissionAccordionDetails = styled(
  (
    props: AccordionDetailsProps & {
      downMd: boolean;
    },
  ) => <MuiAccordionDetails {...props} />,
)(({ theme, downMd }) => ({
  padding: downMd ? 0 : theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
