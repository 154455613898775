const locale = {
  // actions
  create: 'Lead Oluştur',
  update: 'Lead Güncelle',
  detail: 'Lead Detayı',
  view: 'Lead Görüntüle',
  list: 'Leadleri Listele',
  addNewRole: 'Yeni Rol Ekle',
  tagsPlaceholder: 'Etiket Ekle',
  listInfo: 'Tüm Leadlerin Listesi.',
  weeklyReport: 'Haftalık Rapor',

  // situational messages
  createdSuccessfully: 'Lead başarıyla oluşturuldu',
  updatedSuccessfully: 'Lead başarıyla güncellendi',
  deleteConfirm: 'Silmek istediğinize emin misiniz? Bu işlem geri alınamaz.',
  deletedSuccessfully: 'Lead başarıyla silindi',
  createInfo: 'Buradan yeni lead oluşturabilirsiniz.',
  updateLeadSuccess: 'Lead başarıyla güncellendi',
  createLeadSuccess: 'Lead başarıyla oluşturuldu',
  emptyState1: 'Burada Lead bulunamadı.',
  emptyState2: 'Hemen leadleri oluşturabilir veya içe aktarabilirsiniz.',

  // import
  importLead: 'Lead İçe Aktar',
  importTitle: 'Leadleri İçe Aktar',
  importText1: 'Bu sayfadan leadleri içe aktarabilirsiniz.',
  importText2: 'Aşağıdaki örnek formu kullanarak leadlerinizi yükleyebilirsiniz.',

  // fields and names
  lead: 'Lead',
  leads: 'Leadler',
  title: 'Leadleri Listele',
  assigneds: 'Atananlar',
  qualification: 'Nitelik',
  b2b: 'B2B',
  b2c: 'B2C',
  junk: 'Geçersiz',
  unJunk: 'Çöp Kutusundan Kaldır',
  justOwn: 'Sadece Kendim',
  isJunk: 'Çöp Kutusu',
  prospectName: 'Adayın Adı: ',
  businessType: 'İş Türü',
  leadType: 'Lead Türü',
  leadCount: 'Lead Sayısı',
  leadValue: 'Lead Değeri',
  tracking: 'Takip',
  image: 'Lead Resmi',

  // contact
  addContact: 'İletişim Ekle',
  contactedDate: 'İletişim Tarihi',
  emptyStateContactContent1: 'Burada iletişim bulunamadı.',
  contactDetail: 'İletişim Detayı',
  contactHistory: 'İletişim Geçmişi',
  lastContacted: 'Son İletişim',
  lastContact: 'Son İletişim',
  contacted: 'İletişime Geçen',

  // statistics
  leadConversions: 'Lead Dönüşümleri',

  // lead to customer
  convertToCustomer: 'Müşteri Yap',
  customerType: 'Müşteri Türü',
  leadConvertedSuccessfully: 'Lead başarıyla dönüştürüldü',

  onSiteEstimateWith: 'Yerinde tahmin',

  // labels
  welcome: 'Hoş geldiniz',
  description: 'Bu hafta liderlikteki ilerlemeniz muhteşem. Devam edelim.',
};

export default locale;
