export class PermissionHelper {
  static isIncludesRole(rolePermissionIds: string[], permissionId: string) {
    const inRolePermissions = rolePermissionIds.some((p) => p === permissionId);
    return inRolePermissions;
  }

  static isProhibited(prohibitedPermissionIds: string[], permissionId: string) {
    const inProhibitedPermissions = prohibitedPermissionIds.some((p) => p === permissionId);
    return inProhibitedPermissions;
  }
}
