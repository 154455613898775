import { IMenuItem } from 'interfaces/menu-item.interface';
import { useTranslation } from 'react-i18next';
import { baseExpenseRoute } from '../routes/expense.base.route';

export const useExpenseDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation();

  return {
    title: t('expenses'),
    path: baseExpenseRoute(),
    children: [
      // Todo
      // {
      //   title: t("users"),
      //   path: listUsersRoute(),
      // },
    ],
  };
};
