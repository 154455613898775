import { Stack, useMediaQuery } from '@mui/material';
import CustomAreaChart from '../../components/CustomAreaChart';
import CustomCompareChart from '../../components/CustomCompareChart';
import CustomHalfPieChart from '../../components/CustomHalfPieChart';
import { useLeadStatisticsQuery } from 'apps/crm/domains/03-lead/subdomains/lead';
import { useTranslation } from 'react-i18next';
import { memo, useCallback } from 'react';
import { convertCurrency } from 'utils/convertCurrency';
import { useOrganizationSettingsDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';

const LeadStatistics = memo((props: {}) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const { data: organizationSettingsDetailData } = useOrganizationSettingsDetailOwnQuery({});

  const { data: leadStatisticsData } = useLeadStatisticsQuery({
    input: {
      filter: {
        numberOfPastDays: 7,
      },
    },
  });

  const dailyCreatedCounts = leadStatisticsData?.dailyHistory?.dailyCreatedCounts ?? [];
  const totalDailyCreatedCounts = dailyCreatedCounts.reduce((a, b) => a + b, 0);
  const dailyTotalCounts = leadStatisticsData?.dailyHistory?.dailyTotalCounts ?? [];

  const b2bCount = leadStatisticsData?.countByLeadBusinessType?.countByB2b ?? 0;
  const b2cCount = leadStatisticsData?.countByLeadBusinessType?.countByB2c ?? 0;

  const convertedLeadCount = leadStatisticsData?.countByConvertedToCustomer ?? 0;

  const getChartData = useCallback(() => {
    return (
      dailyTotalCounts
        ?.map((item, index) => ({
          name: index.toString(),
          uv: item ?? 0,
          pv: 3000,
          amt: 3000,
        }))
        .reverse() ?? []
    );
  }, [dailyTotalCounts]);

  const getTopRightCount = useCallback(() => {
    return leadStatisticsData?.count && leadStatisticsData?.count > 0
      ? `${(((totalDailyCreatedCounts ?? 0) / leadStatisticsData?.count) * 100).toFixed(1)}%`
      : '0%';
  }, [leadStatisticsData, totalDailyCreatedCounts]);

  const getBottomToolbarData = useCallback(() => {
    return [
      {
        name: t('crm.dashboard.dashboard.today'),
        valueFormat: {
          count1: dailyTotalCounts?.[0] ?? 0,
          count2: dailyCreatedCounts?.[0] ?? undefined,
          prefix: dailyCreatedCounts?.[0] ? '+' : '',
        },
      },
      {
        name: t('crm.dashboard.dashboard.yesterday'),
        valueFormat: {
          count1: dailyTotalCounts?.[1] ?? 0,
          count2: dailyCreatedCounts?.[1] ?? undefined,
          prefix: dailyCreatedCounts?.[1] ? '+' : '',
        },
      },
    ];
  }, [dailyTotalCounts, dailyCreatedCounts, t]);

  console.log(getChartData())
  return (
    <Stack direction={downMd ? 'column' : 'row'} gap={2} mb={1} width={'100%'}>
      <CustomAreaChart
        title={t('crm.dashboard.dashboard.totalLead')}
        subtitle={t('crm.dashboard.dashboard.totalLeadSubtitle')}
        theme="warning"
        flexBasis={'66%'}
        icon="material-symbols-light:social-leaderboard-outline-rounded"
        count={leadStatisticsData?.count ?? 0}
        data={getChartData()}
        topRightCount={getTopRightCount()}
        bottomToolbarData={getBottomToolbarData()}
      />
      <Stack flexBasis={'34%'} width={'100%'} gap={2}>
        <CustomCompareChart
          title="B2B/B2C"
          //   count={{
          //     value: (b2bCount / (b2cCount === 0 ? 1 : b2cCount)),
          //     prefix: '+',
          //     suffix: '%',
          //   }}
          totalValue={{
            value: b2bCount + b2cCount,
            prefix: convertCurrency(organizationSettingsDetailData?.common.currency),
            suffix: 'k',
          }}
          flexBasis={'100%'}
          data={[
            {
              title: 'B2B',
              count1: {
                value: parseFloat(((b2bCount / (b2bCount + b2cCount || 1)) * 100).toFixed(1)),
                suffix: '%',
              },
              count2: {
                value: b2bCount,
              },
            },
            {
              title: 'B2C',
              count1: {
                value: parseFloat(((b2cCount / (b2bCount + b2cCount || 1)) * 100).toFixed(1)),
                suffix: '%',
              },
              count2: {
                value: b2cCount,
              },
            },
          ]}
        />
        <CustomHalfPieChart
          title={t('crm.dashboard.dashboard.leadConversion')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          description={t('crm.dashboard.dashboard.leadConversionSubtitle')}
          theme="tertiary"
          flexBasis={'100%'}
          data={[
            {
              name: t('crm.dashboard.dashboard.leadConversion'),
              value1: convertedLeadCount, 
              totalValue: 1000,
              value2Format: {
                count: parseFloat(
                  totalDailyCreatedCounts > 0
                    ? (((convertedLeadCount ?? 0) / totalDailyCreatedCounts) * 100).toFixed(1)
                    : '0'
                ),
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
        />
      </Stack>
    </Stack>
  );
});

export default LeadStatistics;
