import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { IBaseGridColumnParams } from 'components/baseDataGrid/infra/IBaseGridColumnParams';
import { ReminderPriority, ReminderStatus, ReminderTargetType } from 'corede-common-cocrm';
import { TFunction } from 'i18next';

export function reminderListColumnData(params: {
  t: TFunction<'translation', undefined>;
  containsTargetType: boolean;
  hasUpdatePermission: boolean;
}): IBaseGridColumnParams[] {
  const targetTypeColumn: IBaseGridColumnParams[] = [];
  if (params.containsTargetType) {
    targetTypeColumn.push({
      config: {
        baseGridColType: BaseGridColType.enum,
        column: {
          field: 'targetType',
          width: 160,
          editable: params.hasUpdatePermission,
        },
        headerConfig: {
          icon: 'mdi:bullseye',
          name: params.t('crm.targetType'),
        },
        filterConfig: {
          customFieldName: `targetTypes`,
          isArrayFilter: true,
        },
      },
      data: ReminderTargetType,
    });
  }

  const columns: IBaseGridColumnParams[] = [
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'id',
          disableColumnMenu: true,
          filterable: false,
          width: 40,
          align: 'center',
        },
        headerConfig: {
          name: '#',
          align: 'center',
        },
      },
    },
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'title',
          filterable: true,
          editable: params.hasUpdatePermission,
        },
        headerConfig: {
          icon: 'mdi:note-text',
          name: params.t('crm.title'),
        },
      },
    },
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'content',
          editable: false,
          filterable: true,
          sortable: false,
        },
        headerConfig: {
          icon: 'mdi:note',
          name: params.t('crm.content'),
        },
      },
    },
    {
      config: {
        baseGridColType: BaseGridColType.enum,
        column: {
          field: 'status',
          width: 120,
          editable: params.hasUpdatePermission,
        },
        headerConfig: {
          icon: 'mdi:checkbox-marked-circle-outline',
          name: params.t('crm.status'),
        },
        filterConfig: {
          customFieldName: `statuses`,
          isArrayFilter: true,
        },
      },
      data: ReminderStatus,
    },
    {
      config: {
        baseGridColType: BaseGridColType.textArray,
        column: {
          field: 'tags',
          editable: params.hasUpdatePermission,
          sortable: false,
        },
        headerConfig: {
          icon: 'mdi:tag-multiple',
          name: params.t('crm.tags'),
        },
      },
    },
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'remindDateString',
          editable: params.hasUpdatePermission,
          width: 150,
        },
        headerConfig: {
          icon: 'mdi:tag-multiple',
          name: params.t('crm.remindDate'),
        },
      },
    },
    {
      config: {
        baseGridColType: BaseGridColType.enum,
        column: {
          field: 'priority',
          width: 100,
          editable: params.hasUpdatePermission,
        },
        headerConfig: {
          icon: 'mdi:alert-circle-outline',
          name: params.t('crm.priority'),
        },
        filterConfig: {
          customFieldName: `priorities`,
          isArrayFilter: true,
        },
      },
      data: ReminderPriority,
    },
    ...targetTypeColumn,
    // {
    //   config: {
    //     baseGridColType: BaseGridColType.objectArray,
    //     column: {
    //       field: "remindUsers",
    //       editable: params.hasUpdatePermission,
    //     },
    //     headerConfig: {
    //       icon: "mdi:account-group-outline",
    //       name: params.t("crm.remindUsers"),
    //     },
    //   },
    // }, //TODO : add user array to grid
    {
      config: {
        baseGridColType: BaseGridColType.text,
        column: {
          field: 'createdAt',
          width: 170,
          editable: params.hasUpdatePermission,
        },
        headerConfig: {
          icon: 'mdi:calendar-check',
          name: params.t('crm.createdAt'),
        },
      },
    },
  ];

  return columns;
}
