import { Divider, Stack, Typography } from '@mui/material';
import { PermissionActionScope } from 'corede-common';
import { IPermission, PermissionSubdomain, PermissionSubject } from 'corede-common-cocrm';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';
import { PermissionAccordionSummary } from './PermissionAccordionSummaryContainer';
import { PermissionAccordion } from './PermissionAccordionContainer';
import { PermissionSubjectOfScopeStackComponent } from './PermissionSubjectOfScope';
import { IUseStatePropagationProp } from 'interfaces/useState-propagation-props.interface';

export interface IPermissionSubdomainAccordionComponentProps {
  subdomain: PermissionSubdomain;
  permissions: IPermission[];
  downMd: boolean;
  downLg: boolean;
  selectedPermissionIds: string[];
  rolePermissionIds: string[];
  handleChangeCheckbox: (permissionId: string) => void;
  openedPermissionsState: IUseStatePropagationProp<string[]>;
}

export const PermissionSubDomainAccordionComponent = (
  props: IPermissionSubdomainAccordionComponentProps,
): JSX.Element => {
  const { t } = useTranslation();

  const subjects: Set<PermissionSubject> = new Set();
  props.permissions.forEach((p) => {
    subjects.add(p.subject as PermissionSubject);
  });

  const subjectToPermissionsMap: Map<
    PermissionSubject,
    Map<PermissionActionScope, IPermission[]>
  > = new Map();
  subjects.forEach((s) => {
    // initialize map
    subjectToPermissionsMap.set(
      s,
      new Map<PermissionActionScope, IPermission[]>([
        [
          PermissionActionScope.all,
          props.permissions.filter(
            (p) => p.subject === s && p.actionScope === PermissionActionScope.all,
          ),
        ],
        [
          PermissionActionScope.org,
          props.permissions.filter(
            (p) => p.subject === s && p.actionScope === PermissionActionScope.org,
          ),
        ],
        [
          PermissionActionScope.dep,
          props.permissions.filter(
            (p) => p.subject === s && p.actionScope === PermissionActionScope.dep,
          ),
        ],
        [
          PermissionActionScope.own,
          props.permissions.filter(
            (p) => p.subject === s && p.actionScope === PermissionActionScope.own,
          ),
        ],
      ]),
    );
  });

  return (
    <>
      {Array.from(subjects.values()).map((subject) => (
        <PermissionAccordion key={subject} expanded={false}>
          <PermissionAccordionSummary expandIcon={null}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              alignItems="center"
              justifyContent={'space-between'}
              sx={{ width: '100%' }}
            >
              <Typography fontSize={'medium'} fontWeight={'bold'}>
                {getTranslatedEnumValue(subject)}
              </Typography>
              {props.downLg && (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={'center'}
                  sx={{ width: '100%' }}
                >
                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                    <Typography
                      fontSize={'10px'}
                      textAlign={'center'}
                      fontWeight={'bold'}
                      sx={{ opacity: 0.5 }}
                    >
                      {t('organization')}
                    </Typography>
                  </Stack>
                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                    <Typography
                      fontSize={'10px'}
                      textAlign={'center'}
                      fontWeight={'bold'}
                      sx={{ opacity: 0.5 }}
                    >
                      {t('department')}
                    </Typography>
                  </Stack>
                  <Stack width={{ xs: '75px', sm: '100px', lg: '150px' }}>
                    <Typography
                      fontSize={'10px'}
                      textAlign={'center'}
                      fontWeight={'bold'}
                      sx={{ opacity: 0.5 }}
                    >
                      {t('user')}
                    </Typography>
                  </Stack>
                </Stack>
              )}
              {props.downLg && (
                <Divider
                  sx={{
                    width: { xs: '80%', sm: '300px' },
                    mr: { xs: '0px', sm: '50px' },
                    mt: 0.5,
                    mb: 0.5,
                  }}
                />
              )}
              <Stack direction="row" alignItems="center" justifyContent={'flex-end'}>
                <Stack direction="row">
                  <PermissionSubjectOfScopeStackComponent
                    subject={subject}
                    scope={PermissionActionScope.org}
                    permissions={
                      subjectToPermissionsMap.get(subject)!.get(PermissionActionScope.org)!
                    }
                    downMd={props.downMd}
                    downLg={props.downLg}
                    selectedPermissionIds={props.selectedPermissionIds}
                    rolePermissionIds={props.rolePermissionIds}
                    openedPermissionsState={props.openedPermissionsState}
                    handleChangeCheckbox={props.handleChangeCheckbox}
                  />
                  <PermissionSubjectOfScopeStackComponent
                    subject={subject}
                    scope={PermissionActionScope.dep}
                    permissions={
                      subjectToPermissionsMap.get(subject)!.get(PermissionActionScope.dep)!
                    }
                    downMd={props.downMd}
                    downLg={props.downLg}
                    selectedPermissionIds={props.selectedPermissionIds}
                    rolePermissionIds={props.rolePermissionIds}
                    openedPermissionsState={props.openedPermissionsState}
                    handleChangeCheckbox={props.handleChangeCheckbox}
                  />
                  <PermissionSubjectOfScopeStackComponent
                    subject={subject}
                    scope={PermissionActionScope.own}
                    permissions={
                      subjectToPermissionsMap.get(subject)!.get(PermissionActionScope.own)!
                    }
                    downMd={props.downMd}
                    downLg={props.downLg}
                    selectedPermissionIds={props.selectedPermissionIds}
                    rolePermissionIds={props.rolePermissionIds}
                    openedPermissionsState={props.openedPermissionsState}
                    handleChangeCheckbox={props.handleChangeCheckbox}
                  />
                </Stack>
              </Stack>
            </Stack>
          </PermissionAccordionSummary>
        </PermissionAccordion>
      ))}
    </>
  );
};
