import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ContractTemplateListComponent from './ContractTemplateList-grid.component';
import { useLocation } from 'react-router-dom';
import ContractTemplateCreateDrawerWrapper from '../create/ContractTemplateCreateDrawer.wrapper';
import { useContractTemplateListQuery } from '../../context/contractTemplate.api';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const ContractTemplateList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openContractTemplateCreateDrawer, setOpenContractTemplateCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();

  // queries
  const { data: contractTemplateListCountData, isLoading: contractTemplateListCountLoading } =
    useContractTemplateListQuery({});

  let totalContractTemplateCount = contractTemplateListCountData?.count ?? 0;
  let isEmpty = totalContractTemplateCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenContractTemplateCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {t('crm.contract.contractTemplate.title')}
            </Typography>
          </Stack>
        }
        // description={
        //   <Typography variant={downMd ? 'caption' : 'body1'}>
        //     {t(`crm.contract.contractTemplate.description`)}
        //   </Typography>
        // }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.contract.contractTemplate.create`),
          onClick: () => setOpenContractTemplateCreateDrawer(true),
          permission: {
            subject: PermissionSubject.contractTemplate,
            action: PermissionAction.create,
          },
        }}
      />

      <ContractTemplateListComponent
        setOpenContractTemplateCreateDrawer={setOpenContractTemplateCreateDrawer}
      />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contractTemplate,
          action: PermissionAction.update,
        }}
      >
        <ContractTemplateCreateDrawerWrapper
          open={openContractTemplateCreateDrawer}
          setOpen={setOpenContractTemplateCreateDrawer}
        />
      </PermissionWrapper>
    </Grid>
  );
});

export default ContractTemplateList;
