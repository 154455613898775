/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from '@mui/material';
import { TCellRenderFunc } from 'components/baseDataGrid/infra/types';

export function MemberInfoCellComponent(): TCellRenderFunc {
  return (cellParams) => {
    console.log(cellParams);
    return (
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={1}>
        <Avatar
          alt={cellParams.value?.name}
          src={cellParams.value?.profileImage?.thumbnailPublicUrl}
          sx={{ width: 35, height: 35 }}
        />
        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={0.5}>
          <Typography fontSize={'12px'} fontWeight={500} color={'text.primary'}>
            {cellParams.value?.name} {cellParams.value?.surname}
          </Typography>
        </Stack>
      </Stack>
    );
  };
}
