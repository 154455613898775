const locale = {
  leadForms: 'Lead Forms',
  title: 'Lead Forms',
  newForm: 'New Form',
  emptyStateDesc1:
    'Your lead forms will appear here as you create them to capture valuable customer information.',
  emptyStateDesc2:
    'Easily manage and customize your forms to ensure you’re collecting the right details for every interaction.',

  create: 'Create Lead Form',
  update: 'Update Lead Form',
  updateSuccess: 'Lead form updated successfully',
  createSuccess: 'Lead form created successfully',
  deleteSuccess: 'Lead form deleted successfully',
  deleteConfirm: 'Are you sure you want to delete this lead form?',
  deletedSuccessfully: 'Lead form deleted successfully',

  // tabs
  detailedInfo: 'Detailed Info',
  branding: 'Branding',
  formFields: 'Form Fields',
  submission: 'Submission*',
  notification: 'Notification',

  //fields
  addField: 'Add Field',
  updateField: 'Update Field',
  label: 'Label',
  formName: 'Form Name',
  defaultLanguage: 'Default Language',
  defaultPrefix: 'Default Prefix',
  defaultStatus: 'Default Status',
  defaultSource: 'Default Source',
  defaultAssigned: 'Default Assigned',
  defaultBusinessType: 'Default Business Type',
  defaultType: 'Default Type',
  defaultQualification: 'Default Qualification',
  defaultTags: 'Default Tags',
  submitButtonText: 'Submit Button Text',
  header: 'Header',
  isRequired: 'Required',
  type: 'Type',
  defaultIsPublic: 'Default Is Public',
  followUpRedirectUrl: 'Follow Up Redirect Url',
  followUpMessage: 'Follow Up Message',
  specificUsersToNotify: 'Specific Users To Notify',
  willNotify: 'Will Notify',
  willNotifyResponsibleUsers: 'Will Notify Responsible Users',
  leadField: 'Lead Field',
  placeholder: 'Placeholder',
  fullNamePlaceholder: 'Enter your full name',
  emailPlaceholder: 'Enter your email',
  formTitle: 'Form',

};

export default locale;
