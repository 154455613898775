/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IProposalDetailResult } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';

export interface IProposalDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IProposalDetailResult> {
  proposalDetailData: IProposalDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const ProposalDetailDrawerTabInfoComponent = (
  props: IProposalDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();

  const totalPrice = props.proposalDetailData?.totalPrice;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';

  return (
    <Box sx={{ height: maxHeight, overflowY: 'auto' }}>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.preSale.proposal.productInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          {props.proposalDetailData?.products?.length ? (
            <Grid container gap={3}>
              {props.proposalDetailData.products.map((product: any, index: number) => (
                <Grid key={index} item xs={12}>
                  <Box>
                    {/* Ürün Adı */}
                    <Stack direction="row" alignItems="center" spacing={1} sx={{ marginBottom: 2 }}>
                      <Icon icon="mdi:package-variant" width={20} />
                      <Typography
                        variant={'caption'}
                        sx={{ fontWeight: 500, color: 'text.primary' }}
                      >
                        {product.name || t('crm.preSale.proposal.productNameUnknown')}
                      </Typography>
                    </Stack>

                    {/* Fiyat ve Miktar */}
                    <Grid container spacing={1.5}>
                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" gap={2}>
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              bgcolor: 'secondary.extraLight',
                              borderRadius: '6px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Icon icon="mdi:cash" width={20} sx={{ color: 'secondary.main' }} />
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                              color={'text.primary'}
                            >
                              {product.price ? `${product.price} ${product.currency || ''}` : '-'}
                            </Typography>
                            <Typography variant="caption">
                              {t('crm.preSale.proposal.price')}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" gap={2}>
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              bgcolor: 'secondary.extraLight',
                              borderRadius: '6px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Icon icon="mdi:tag" width={20} sx={{ color: 'secondary.main' }} />
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                              color={'text.primary'}
                            >
                              {product.discountedPrice
                                ? `${product.discountedPrice} ${product.currency || ''}`
                                : '-'}
                            </Typography>
                            <Typography variant="caption">
                              {t('crm.preSale.proposal.discountedPrice')}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" gap={2}>
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              bgcolor: 'secondary.extraLight',
                              borderRadius: '6px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Icon
                              icon="mdi:cart-outline"
                              width={20}
                              sx={{ color: 'secondary.main' }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                              color={'text.primary'}
                            >
                              {product.quantity || '-'}
                            </Typography>
                            <Typography variant="caption">
                              {t('crm.preSale.proposal.quantity')}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" gap={1}>
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              bgcolor: 'secondary.extraLight',
                              borderRadius: '6px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Icon icon="mdi:percent" width={20} sx={{ color: 'secondary.main' }} />
                          </Box>
                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                              color={'text.primary'}
                            >
                              {product.tax ? `${product.tax}%` : '-'}
                            </Typography>
                            <Typography variant="caption">
                              {t('crm.preSale.proposal.tax')}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack direction="row" alignItems="center" gap={2}>
                          <Box
                            sx={{
                              width: 30,
                              height: 30,
                              bgcolor: 'secondary.extraLight',
                              borderRadius: '6px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Icon
                              icon="mdi:calculator-variant"
                              width={20}
                              sx={{ color: 'secondary.main' }}
                            />
                          </Box>

                          <Box>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 500 }}
                              color={'text.primary'}
                            >
                              {product.finalPrice
                                ? `${product.finalPrice} ${product.currency || ''}`
                                : '-'}
                            </Typography>
                            <Typography variant="caption">
                              {t('crm.preSale.proposal.finalPrice')}
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {t('crm.preSale.proposal.noProducts')}
            </Typography>
          )}
        </Box>
      </Stack>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.preSale.proposal.financialInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          {totalPrice ? (
            <Grid container spacing={1.5}>
              {/* Currency */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon icon="mdi:currency-usd" width={20} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {props.proposalDetailData?.currency || '-'}
                    </Typography>
                    <Typography variant="caption">{t('crm.preSale.proposal.currency')}</Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Sub Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon
                      icon="mdi:calculator-variant"
                      width={20}
                      sx={{ color: 'secondary.main' }}
                    />
                  </Box>

                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {totalPrice.subTotal
                        ? `${totalPrice.subTotal} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                    <Typography variant="caption">{t('crm.preSale.proposal.subTotal')}</Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Discount */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon icon="mdi:tag-outline" width={20} sx={{ color: 'secondary.main' }} />
                  </Box>

                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {totalPrice.discount
                        ? `-${totalPrice.discount} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                    <Typography variant="caption">{t('crm.preSale.proposal.discount')}</Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Tax Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon icon="mdi:percent" width={20} sx={{ color: 'secondary.main' }} />
                  </Box>

                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {totalPrice.taxTotal
                        ? `${totalPrice.taxTotal} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                    <Typography variant="caption">{t('crm.preSale.proposal.taxTotal')}</Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Shipping Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon icon="mdi:truck-outline" width={20} sx={{ color: 'secondary.main' }} />
                  </Box>

                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {totalPrice.shippingTotal
                        ? `${totalPrice.shippingTotal} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                    <Typography variant="caption">
                      {t('crm.preSale.proposal.shippingTotal')}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              {/* Total */}
              <Grid item xs={6}>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Icon icon="mdi:cash-multiple" width={20} sx={{ color: 'secondary.main' }} />
                  </Box>

                  <Box>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {totalPrice.total
                        ? `${totalPrice.total} ${props.proposalDetailData?.currency || ''}`
                        : '-'}
                    </Typography>
                    <Typography variant="caption">{t('crm.preSale.proposal.total')}</Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {t('crm.preSale.proposal.noFinancialData')}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
