/* eslint-disable react-hooks/exhaustive-deps */
import { IAppointmentDetailResult } from 'corede-common-cocrm';
import { AppointmentDetailDrawerHeaderStatusComponent } from './AppointmentDetailDrawer-rightPanel-header-status.component';
// import { AppointmentDetailDrawerBaseInfoComponent } from './AppointmentDetailDrawer-rightPanel-header-baseInfo.component';

export interface IAppointmentDetailDrawerRightPanelHeaderComponentProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const AppointmentDetailDrawerRightPanelHeaderComponent = (
  props: IAppointmentDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <AppointmentDetailDrawerHeaderStatusComponent
        appointmentDetailData={props.appointmentDetailData}
      />
      {/* <AppointmentDetailDrawerBaseInfoComponent appointmentDetailData={props.appointmentDetailData} /> */}
    </>
  );
};
