import { Stack, useMediaQuery } from '@mui/material';
import CustomAreaChart from '../../components/CustomAreaChart';
import CustomBarChartV2 from '../../components/CustomBarChartV2';
import CustomBarChartV3 from '../../components/CustomBarChartV3';
import CustomDonutChart from '../../components/CustomDonutChart';
import CustomHalfPieChart from '../../components/CustomHalfPieChart';
import { useTranslation } from 'react-i18next';
import { useEstimateStatisticsQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { EstimateProspectTargetEntityRef, EstimateStatus, EstimateType } from 'corede-common-cocrm';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { memo, useCallback } from 'react';
import { useOrganizationSettingsDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';
import { convertCurrency } from 'utils/convertCurrency';

const EstimateStatistics = memo((props: {}) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const { data: organizationSettingsDetailData } = useOrganizationSettingsDetailOwnQuery({});

  const { data: useEstimateStatisticsData } = useEstimateStatisticsQuery({
    input: {
      filter: {
        numberOfPastDays: 7,
      },
    },
  });

  const dailyCreatedCounts = useEstimateStatisticsData?.dailyHistory?.dailyCreatedCounts ?? [];
  const totalDailyCreatedCounts = dailyCreatedCounts.reduce((a, b) => a + b, 0);
  const dailyTotalCounts = useEstimateStatisticsData?.dailyHistory?.dailyTotalCounts ?? [];

  const estimateTotalPrice = useEstimateStatisticsData?.sumOfAllTotalPricesOfAllEstimates ?? 0;

  const countByEstimateStatus = useEstimateStatisticsData?.countByEstimateStatus;
  const totalCountByEstimateStatus = Object.values(
    useEstimateStatisticsData?.countByEstimateStatus ?? {},
  ).reduce((sum, count) => sum + count, 0);

  const countByEstimateType = useEstimateStatisticsData?.countByEstimateType;
  const totalCountByEstimateType = Object.values(
    useEstimateStatisticsData?.countByEstimateType ?? {},
  ).reduce((sum, count) => sum + count, 0);

  const countByEstimateTargetType = useEstimateStatisticsData?.countByEstimateTargetType;
  const totalCountByEstimateTargetType = Object.values(
    useEstimateStatisticsData?.countByEstimateTargetType ?? {},
  ).reduce((sum, count) => sum + count, 0);

  const getDailyTotalCountsData = useCallback(() => {
    return (
      dailyTotalCounts
        ?.map((item, index) => ({
          name: index.toString(),
          uv: item ?? 0,
          pv: 3000,
          amt: 3000,
        }))
        .reverse() ?? []
    );
  }, [dailyTotalCounts]);

  const getBottomToolbarData = useCallback(() => {
    return [
      {
        name: t('crm.dashboard.dashboard.today'),
        valueFormat: {
          count1: dailyTotalCounts?.[0] ?? 0,
          count2: dailyCreatedCounts?.[0] ?? undefined,
          prefix: dailyCreatedCounts?.[0] ? '+' : '',
        },
      },
      {
        name: t('crm.dashboard.dashboard.yesterday'),
        valueFormat: {
          count1: dailyTotalCounts?.[1] ?? 0,
          count2: dailyCreatedCounts?.[1] ?? undefined,
          prefix: dailyCreatedCounts?.[1] ? '+' : '',
        },
      },
    ];
  }, [dailyTotalCounts, dailyCreatedCounts, t]);

  return (
    <>
      <Stack direction={downMd ? 'column' : 'row'} gap={2} mb={1} width={'100%'}>
        <CustomAreaChart
          title={t('crm.dashboard.dashboard.totalEstimate')}
          subtitle={t('crm.dashboard.dashboard.totalEstimateSubtitle')}
          theme="warning"
          flexBasis={'66%'}
          icon="mage:preview"
          count={dailyTotalCounts?.[0] ?? 0}
          data={getDailyTotalCountsData()}
          topRightCount={
            totalDailyCreatedCounts > 0
              ? `${(((totalDailyCreatedCounts ?? 0) / dailyTotalCounts[0]) * 100).toFixed(1)}%`
              : '0%'
          }
          bottomToolbarData={getBottomToolbarData()}
        />
        <CustomHalfPieChart
          title={t('crm.dashboard.dashboard.totalEstimate')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          description={t('crm.dashboard.dashboard.totalEstimateSubtitle')}
          theme="tertiary"
          flexBasis={'34%'}
          data={[
            {
              name: 'A',
              value1: estimateTotalPrice,
              totalValue: estimateTotalPrice * 1000,
              value2Format: undefined,
              currency: convertCurrency(organizationSettingsDetailData?.common.currency),
            },
          ]}
        />
      </Stack>
      <Stack direction={downMd ? 'column' : 'row'} gap={2} mb={1} width={'100%'}>
        <CustomBarChartV2
          title={t('crm.dashboard.dashboard.estimatesByTypes')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          theme="secondary"
          flexBasis={'33%'}
          language={getCurrentLanguage()}
          data={[
            {
              name: getTranslatedEnumValue(EstimateType.individual),
              value1: countByEstimateType?.countByIndividual ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateType?.countByIndividual ?? 0) / totalCountByEstimateType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateType.business),
              value1: countByEstimateType?.countByBusiness ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateType?.countByBusiness ?? 0) / totalCountByEstimateType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateType.government),
              value1: countByEstimateType?.countByGovernment ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateType?.countByGovernment ?? 0) / totalCountByEstimateType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
        />
        <CustomBarChartV3
          title={t('crm.dashboard.dashboard.estimatesByTarget')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          theme="secondary"
          flexBasis={'33%'}
          language={getCurrentLanguage()}
          data={[
            {
              name: getTranslatedEnumValue(EstimateProspectTargetEntityRef.Lead),
              value1Format: {
                count: countByEstimateTargetType?.countByLead ?? 0,
                prefix: '',
              },
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateTargetType?.countByLead ?? 0) /
                      totalCountByEstimateTargetType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateProspectTargetEntityRef.Customer),
              value1Format: {
                count: countByEstimateTargetType?.countByCustomer ?? 0,
                prefix: '',
              },
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateTargetType?.countByCustomer ?? 0) /
                      totalCountByEstimateTargetType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '',
                suffix: '%',
              },
            },
          ]}
        />

        <CustomDonutChart
          title={t('crm.dashboard.dashboard.estimatesByStatus')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          colors={['#24B364', '#53D28C', '#7EDDA9', '#A9E9C5', '#D4F5E2', '#24B364', '#A9E9C5']}
          theme="success"
          flexBasis={'34%'}
          totalValue={totalCountByEstimateStatus}
          language={getCurrentLanguage()}
          data={[
            {
              name: getTranslatedEnumValue(EstimateStatus.draft),
              value1: countByEstimateStatus?.countByDraft ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateStatus?.countByDraft ?? 0) / (totalCountByEstimateStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateStatus.sent),
              value1: countByEstimateStatus?.countBySent ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateStatus?.countBySent ?? 0) / (totalCountByEstimateStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateStatus.accepted),
              value1: countByEstimateStatus?.countByAccepted ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateStatus?.countByAccepted ?? 0) / (totalCountByEstimateStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateStatus.declined),
              value1: countByEstimateStatus?.countByDeclined ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateStatus?.countByDeclined ?? 0) / (totalCountByEstimateStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateStatus.expired),
              value1: countByEstimateStatus?.countByExpired ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateStatus?.countByExpired ?? 0) / (totalCountByEstimateStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateStatus.opened),
              value1: countByEstimateStatus?.countByOpened ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateStatus?.countByOpened ?? 0) / (totalCountByEstimateStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(EstimateStatus.revised),
              value1: countByEstimateStatus?.countByRevised ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByEstimateStatus?.countByRevised ?? 0) / (totalCountByEstimateStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
        />
      </Stack>
    </>
  );
});

export default EstimateStatistics;
