/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  IProjectDetailResult,
  ProgressCalculationType,
  ProjectBillingType,
} from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';

export interface IProjectDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IProjectDetailResult> {
  projectDetailData: IProjectDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const ProjectDetailDrawerTabInfoComponent = (
  props: IProjectDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();

  const project = props.projectDetailData;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';

  return (
    <Box sx={{ height: maxHeight, overflowY: 'auto' }}>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.project.project.projectInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container gap={3}>
            <Grid item xs={12}>
              <Box>
                {/* Start date */}
                <Grid container spacing={1.5}>
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:cash" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {moment(project?.startDate).format() || '-'}
                        </Typography>
                        <Typography variant="caption">
                          {' '}
                          {t('crm.project.project.startDate')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* Deadline*/}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:tag" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {moment(project?.deadline).format() || ''}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.project.project.deadline')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* Tahmini saat */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:cart-outline" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {project?.estimatedHours || '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.project.project.estimatedHours')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* progressPercentage */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:percent" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {project?.progressPercentage}%
                        </Typography>
                        <Typography variant="caption">
                          {' '}
                          {t('crm.project.project.progressPercentage')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* progressCalculationType */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="mdi:calculator-variant"
                          width={20}
                          sx={{ color: 'secondary.main' }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {getTranslatedEnumValue(
                            project?.progressCalculationType as ProgressCalculationType,
                          ) || '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.project.project.progressCalculationType')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.project.project.billingInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container spacing={1.5}>
            {/* Is Billable */}
            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: 'secondary.extraLight',
                    borderRadius: '6px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon icon="mdi:currency-usd" width={20} sx={{ color: 'secondary.main' }} />
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                    {project?.isBillable ? t('yes') : t('no')}
                  </Typography>
                  <Typography variant="caption"> {t('crm.project.project.isBillable')}</Typography>
                </Box>
              </Stack>
            </Grid>

            {/* billingType */}
            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: 'secondary.extraLight',
                    borderRadius: '6px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon icon="mdi:calculator-variant" width={20} sx={{ color: 'secondary.main' }} />
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                    {getTranslatedEnumValue(project?.billingType as ProjectBillingType) || '-'}
                  </Typography>
                  <Typography variant="caption"> {t('crm.project.project.billingType')}</Typography>
                </Box>
              </Stack>
            </Grid>

            {/* billingRatePerHour */}
            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: 'secondary.extraLight',
                    borderRadius: '6px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon icon="mdi:tag-outline" width={20} sx={{ color: 'secondary.main' }} />
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                    {project?.billingRatePerHour || '-'}
                  </Typography>
                  <Typography variant="caption">
                    {' '}
                    {t('crm.project.project.billingRatePerHour')}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
