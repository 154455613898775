import { useEffect, useState } from 'react';
import { Container, Grid, Stack } from '@mui/material';
import { Iconify, MyTab } from 'components';
import { useTranslation } from 'react-i18next';

import Header from './components/Header';
import InfoSection from './components/InfoSection';
import TableComponent from './components/Table';
import Descriptions from './components/Descriptions';
import Comments from './components/Comments';
import Documents from './components/Documents';
import ActionButtons from './components/ActionButtons';
import { ApolloClient, ApolloProvider, InMemoryCache, useMutation, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import {
  InvoiceStatus,
  IInvoiceDetailPublicResult,
  invoiceDetailPublicQuery,
  invoiceOpenQuery,
} from 'corede-common-cocrm';
import Timeline from './components/Timeline';

const InvoiceComponent = () => {
  const { t } = useTranslation();

  const { loading, error, data, refetch } = useQuery<{
    invoiceDetailPublic: IInvoiceDetailPublicResult;
  }>(invoiceDetailPublicQuery);
  const invoiceData = data?.invoiceDetailPublic;

  const [invoiceOpen, { data: invoiceOpenData }] = useMutation(invoiceOpenQuery);

  const tabIcons = [
    <Iconify icon="majesticons:comment-text" width={20} />,
    <Iconify icon="basil:document-solid" width={20} />,
  ] as React.ReactElement[];

  const tabLabels = [
    // t('auth.comments'),
    t('auth.documents'),
  ];
  const [selectedTab, setSelectedTab] = useState(t('auth.documents'));

  const boxStyles = {
    boxSizing: 'border-box',
    bgcolor: 'background.default',
    width: '100%',
    borderRadius: 3,
    boxShadow: '0px 3px 12px 0px rgba(47, 43, 61, 0.14)',
    px: 3,
    py: 6,
    mt: 3,
    gap: 3,
  };

  useEffect(() => {
    if (invoiceData?.status === InvoiceStatus.sent && invoiceOpen) {
      invoiceOpen();
    }
  }, [invoiceData, invoiceOpen]);

  useEffect(() => {
    if (invoiceOpenData) {
      refetch();
    }
  }, [invoiceOpenData, refetch]);

  return (
    <Container maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <Stack sx={boxStyles}>
            <Header invoiceData={invoiceData} />

            <InfoSection invoiceData={invoiceData} />

            <TableComponent invoiceData={invoiceData} />

            {/* <Timeline /> */}

            {/* <Descriptions invoiceData={invoiceData} /> */}
          </Stack>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Stack sx={{ ...boxStyles, p: 3, gap: 1 }}>
            <MyTab
              icons={tabIcons}
              labels={tabLabels}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />

            {/* {selectedTab === t('auth.comments') && <Comments />} */}

            {selectedTab === t('auth.documents') && <Documents invoiceData={invoiceData} />}
          </Stack>

          <Stack sx={{ ...boxStyles, p: 3 }}>
            <ActionButtons invoiceData={invoiceData} refetch={refetch} />
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

const Invoice = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `${token}`,
    },
  });

  return (
    <ApolloProvider client={client}>
      <InvoiceComponent />
    </ApolloProvider>
  );
};

export default Invoice;
