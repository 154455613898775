import { TUndefinable } from 'corede-common';

/**
 * For some of the requests, before sending the request body we need to transform the fields.
 * This class is used for that kind of operations
 * For example, for updating a field, default value of the field can be an empty string because of restrictions of the used tools and empty string may be something should not be sent to backend.
 * We need to change empty string to undefined before sending it and at the same time should not change current value of the text field (must be empty string still).
 */
export class RequestTransformerHelper {
  /**
   * Transform empty string fields in the args to undefined
   */
  static TransformEmptyStringsToUndefined<T>(params: {
    input?: T;
    fields: string[];
  }): TUndefinable<T> {
    if (!params.input) {
      return undefined;
    }
    const result: any = {
      ...params.input,
    };
    params.fields.forEach((field) => {
      if (result[field] === '') {
        result[field] = undefined;
      }
    });

    return result as T;
  }

  /**
   * Transform all empty string fields to undefined
   */
  static TransformAllEmptyStringFieldsToUndefined<T>(params: { input?: T }): TUndefinable<T> {
    if (!params.input) {
      return undefined;
    }
    const result: any = {
      ...params.input,
    };
    for (const property in result) {
      if (Object.hasOwn(result, property)) {
        if (typeof result[property] === 'string' && result[property] === '') {
          result[property] = undefined;
        }
      }
    }

    return result as T;
  }
}

