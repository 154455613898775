/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import DetailBox from 'components/box/DetailBox';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import {
  ICustomerDetailResult,
  IUserDetailResult,
  CustomerType,
  ICustomerListInput,
  ICustomerCategory,
  ICustomer,
} from 'corede-common-cocrm';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { useCustomerListQuery, useCustomerUpdateMutation } from '../context/customer.api';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { Default1 } from 'assets/images/avatars';
import { TCellRenderFunc } from 'components/baseDataGrid/infra/types';
import EmptyState from 'components/emptyState/EmptyState';
import { IGraphqlVariables, IHasId, Language } from 'corede-common';
import CustomerDetailDrawerWrapper from '../pages/detailDrawer/CustomerDetailDrawer.wrapper';
import { CustomerDeleteDialog } from '../pages/CustomerDeleteDialog';
import CustomerUpdateDrawerWrapper from '../pages/update/CustomerUpdateDrawer.wrapper';
import { useCustomerCategoryListQuery } from '../../customerCategory';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import {
  handleSideNavigationNavigate,
  IPendingNavigation,
} from 'apps/crm/util/handleSideNavigationNavigate.action';
import CustomerCategoryCreateDrawerWrapper from '../../customerCategory/pages/create/CustomerCategoryCreateDrawer.wrapper';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { BaseGridColumnDefFactory } from 'components/baseDataGrid/factories/base-grid-column.factory';

interface IAssociatedCustomersTabSectionComponent
  extends DefaultTabComponentProps<IUserDetailResult> {
  tabKey: 'associatedCustomers';
  name: 'crm.organizationalChart.user.associatedCustomers';
}

export const AssociatedCustomersTab = (props: IAssociatedCustomersTabSectionComponent) => {
  const { t } = useTranslation();
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<TListGridSelectedEntity<ICustomer>>();
  const [openCustomerDetailDrawer, setOpenCustomerDetailDrawer] = useState(false);
  const [openCustomerDeleteDialog, setOpenCustomerDeleteDialog] = useState(false);
  const [openCustomerUpdateDrawer, setOpenCustomerUpdateDrawer] = useState(false);
  const [customerOpenCategoryCreate, setOpenCustomerCategoryCreate] = useState(false);
  const [customerListFilter, setCustomerListFilter] = useState<
    IGraphqlVariables<ICustomerListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });
  const currentPage = customerListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = customerListFilter?.input?.pagination?.pageSize ?? 10;

  const {
    data: customerListData,
    isLoading: customerListLoading,
    isFetching: customerListFetching,
    error: customerListError,
  } = useCustomerListQuery({
    input: {
      filter: {},
    },
  });
  const { data: customerCategoryListDataFromApi, error: customerCategoryListError } =
    useCustomerCategoryListQuery({});
  const customerCategoryList: ICustomerCategory[] | undefined =
    customerCategoryListDataFromApi?.data;

  const [customerUpdate, { isLoading: customerUpdateLoading, error: customerUpdateError }] =
    useCustomerUpdateMutation();

  return (
    <DefaultTabContainer title={t('crm.organizationalChart.user.associatedCustomers')}>
      {customerListData ? (
        customerListData?.count > 0 ? (
          <BaseDataGrid
            rows={
              customerListData?.data?.map((customer, index) => ({
                id:
                  ((customerListFilter.input?.pagination?.page || 1) - 1) *
                    (customerListFilter.input?.pagination?.pageSize || 0) +
                  index +
                  1,
                _id: customer._id,
                customerInfo: {
                  fullName: customer.name ?? '',
                  email: customer.email ?? '',
                  image: customer.image?.thumbnailPublicUrl ?? '',
                },
                name: customer.name || '',
                email: customer.email || '',
                type: customer.type || '',
                phone: customer.phone || '',
                language: customer.language || '',
                category: customer.category || '',
                about: customer.about || '',
                website: customer.website || '',
                country: customer.country || '',
                state: customer.state || '',
                city: customer.city || '',
                address: customer.address || '',
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                  },
                  headerConfig: {
                    name: '#',
                  },
                },
              },
              BaseGridColumnDefFactory.EntityProfileColumnDef({
                field: 'customerInfo',
                headerName: `${t('crm.name')}`,
                headerIcon: 'radix-icons:person',
              }),
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'name',
                    filterable: true,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'radix-icons:text',
                    name: t('crm.name'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'email',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'radix-icons:envelope-closed',
                    name: t('crm.email'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'type',
                    editable: true,
                    width: 90,
                  },
                  headerConfig: {
                    icon: 'oui:app-users-roles',
                    name: t('crm.type'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `types`,
                    isArrayFilter: true,
                  },
                },
                data: CustomerType,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'phone',
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mi:call',
                    name: t('crm.phone'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'language',
                    width: 100,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'clarity:language-line',
                    name: t('crm.language'),
                  },
                  filterConfig: {
                    customFieldName: `languages`,
                    isArrayFilter: true,
                  },
                },
                data: Language,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.object,
                  column: {
                    field: 'category',
                    editable: true,
                    width: 150,
                  },
                  headerConfig: {
                    icon: 'mdi:source-fork',
                    name: t('crm.category'),
                    creatable: true,
                    createFunc: setOpenCustomerCategoryCreate,
                  },
                  filterConfig: {
                    customFieldName: `categoryIds`,
                    isArrayFilter: true,
                  },
                  valueOptionFields: customerCategoryList,
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'about',
                    width: 140,
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'streamline:web',
                    name: t('crm.about'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'website',
                    width: 140,
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'streamline:web',
                    name: t('crm.website'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'country',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'bi:geo-alt',
                    name: t('crm.country'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'state',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'bi:geo',
                    name: t('crm.state'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'city',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'hugeicons:city-03',
                    name: t('crm.city'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'address',
                    width: 140,
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'streamline:web',
                    name: t('crm.address'),
                  },
                },
              },
            ]}
            actionColumn={{
              width: 80,
              defaultActions: {
                view: {
                  clickConfig: {
                    setSelectedRow: setSelectedCustomer,
                    setOpenAction: setOpenCustomerDetailDrawer,
                  },
                },
                edit: {
                  clickConfig: {
                    setSelectedRow: setSelectedCustomer,
                    setOpenAction: setOpenCustomerUpdateDrawer,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: setSelectedCustomer,
                    setOpenAction: setOpenCustomerDeleteDialog,
                  },
                },
              },
              customCellItems: [],
              actionHeaderName: t('crm.actions'),
            }}
            loading={customerListLoading || customerUpdateLoading || customerListFetching}
            listFilter={{
              filterInput: customerListFilter,
              setFilterInput: setCustomerListFilter,
            }}
            update={{
              updateQuery: customerUpdate,
            }}
            count={customerListData?.count || 0}
            config={{
              columnVisibilityModel: {
                about: false,
                longitude: false,
                latitude: false,
                organization: false,
                department: false,
              },
              features: [],
            }}
            toolbar={{
              customActions: [
                // <Box display="flex" alignItems="center">
                //   <Box display="flex" alignItems="center">
                //     <ToggleButtonGroup
                //       id="export"
                //       sx={{ ml: 2 }}
                //       color="warning"
                //       size="small"
                //       exclusive
                //     >
                //       <ToggleButton
                //         value={false}
                //         onClick={() =>
                //           handleDownloadCustomerList({
                //             currentLanguage: currentLanguage,
                //             filter: customerListFilter?.input?.filter,
                //           })
                //         }
                //       >
                //         <DownloadOutlined fontSize="small" sx={{ mr: 1 }} />
                //         <Typography fontWeight={"bold"} fontSize={"12px"}>
                //           {t("crm.export")}
                //         </Typography>
                //       </ToggleButton>
                //     </ToggleButtonGroup>
                //   </Box>
                // </Box>
              ],
            }}
          />
        ) : (
          <EmptyState content1={t('crm.customer.customer.emptyState1')} />
        )
      ) : null}
      <CustomerDetailDrawerWrapper
        open={openCustomerDetailDrawer}
        setOpen={setOpenCustomerDetailDrawer}
        selectedCustomer={selectedCustomer as ICustomerDetailResult & IHasId<number>}
        sideNavigationProps={{
          count: customerListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: customerListData,
            listFilter: customerListFilter,
            setListFilter: setCustomerListFilter,
            selectedEntity: selectedCustomer,
            setSelectedEntity: setSelectedCustomer,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      />

      <CustomerUpdateDrawerWrapper
        open={openCustomerUpdateDrawer}
        setOpen={setOpenCustomerUpdateDrawer}
        selectedCustomer={selectedCustomer}
      />

      <CustomerDeleteDialog
        open={openCustomerDeleteDialog}
        estimateId={selectedCustomer?._id ?? ''}
        setEstimate={setSelectedCustomer}
        onClose={() => {
          setSelectedCustomer(undefined);
          setOpenCustomerDeleteDialog(false);
        }}
        key={selectedCustomer?._id}
      />

      <CustomerCategoryCreateDrawerWrapper
        open={customerOpenCategoryCreate}
        setOpen={setOpenCustomerCategoryCreate}
      />
    </DefaultTabContainer>
  );
};
