/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack, Typography } from '@mui/material';
import { IAppointmentDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Iconify } from 'components';

export interface IAppointmentDetailDrawerLeftPanelAttendeesComponentProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Container for views in assigned list section of left panel header
 */
export const AppointmentDetailDrawerLeftPanelAttendeesComponent = (
  props: IAppointmentDetailDrawerLeftPanelAttendeesComponentProps,
) => {
  const { t } = useTranslation();
  return props.appointmentDetailData?.attendees &&
    props.appointmentDetailData?.attendees.length > 0 ? (
    <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
      <Stack direction={'column'} width={'100%'} gap={0.5}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            color: 'text.primary',
          }}
        >
          {t('crm.calendar.appointment.attendees')}
        </Typography>
        <Divider />
      </Stack>
      <Stack direction={'column'} width={'100%'} gap={1}>
        {props.appointmentDetailData?.attendees?.map((item, index) => (
          <Stack direction={'row'} gap={2} alignItems={'center'} width={'100%'}>
            <Box
              sx={{
                width: 32,
                height: 32,
                bgColor: 'secondary.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify icon="fa-solid:user-tie" width={18} sx={{ color: 'secondary.main' }} />
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </Stack>
        ))}
      </Stack>
    </Stack>
  ) : null;
};
