import { IDefaultObjectAutocompleteInputItem } from 'components/form/DefaultObjectAutocompleteInput';
import { IUserProfile, NameFactory } from 'corede-common';
import { IUser } from 'corede-common-cocrm';

export function convertUserListToUserProfileList(
  userList?: Pick<IUser, '_id' | 'name' | 'surname' | 'profileImage'>[],
): IUserProfile[] {
  return userList
    ? userList.map((u) => {
        return {
          _id: u._id,
          name: u.name ?? '',
          surname: u.surname ?? '',
          profileImage: u.profileImage,
        };
      })
    : [];
}

export function convertUserListToObjectAutoCompleteItem(
  userList?: Pick<IUser, '_id' | 'name' | 'surname' | 'profileImage'>[],
): IDefaultObjectAutocompleteInputItem[] {
  return userList
    ? userList.map((u) => {
        return {
          _id: u._id,
          name: NameFactory.getFullName({
            name: u.name,
            surname: u.surname,
          }),
        };
      })
    : [];
}

/**
 * filters users from `params.userList` using `params.selectedUserIds` and use them as auto complete items
 */
export function convertSelectedUserListToObjectAutoCompleteItem(params: {
  userList?: Pick<IUser, '_id' | 'name' | 'surname' | 'profileImage'>[];
  selectedUserIds: string;
}): IDefaultObjectAutocompleteInputItem[] {
  const result = params.userList
    ? params.userList
        .filter((u) => params.selectedUserIds.includes(u._id))
        .map((u) => {
          return {
            _id: u._id,
            name: NameFactory.getFullName({
              name: u.name,
              surname: u.surname,
            }),
          };
        })
    : [];
  return result
}
