import { Grid } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { useInvoicePaymentListQuery } from '../../context/payment.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import PaymentListComponent from './PaymentList-grid.component';
import PaymentCreateWrapper from '../create/PaymentCreateDrawer.wrapper';
import { useLocation } from 'react-router-dom';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const PaymentList = memo(() => {
  // general
  const { t } = useTranslation();
  const location = useLocation();
  const [openPaymentCreateDrawer, setOpenPaymentCreateDrawer] = useState(false);

  // queries
  const { data: paymentListData } = useInvoicePaymentListQuery({});

  let totalPaymentCount = paymentListData?.count ?? 0;
  let isEmpty = totalPaymentCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenPaymentCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.sale.payment.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.sale.payment.create`),
          onClick: () => setOpenPaymentCreateDrawer(true),
          permission: {
            subject: PermissionSubject.invoicePayment,
            action: PermissionAction.create,
          },
        }}
      />

      <PaymentListComponent setOpenPaymentCreateDrawer={setOpenPaymentCreateDrawer} />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.invoicePayment,
          action: PermissionAction.create,
        }}
      >
        <PaymentCreateWrapper open={openPaymentCreateDrawer} setOpen={setOpenPaymentCreateDrawer} />
      </PermissionWrapper>
    </Grid>
  );
});

export default PaymentList;
