import { TicketTargetType } from 'corede-common-cocrm';
import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';
import * as Yup from 'yup';

export const validateCreateSupportInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    // targetId: Yup.string().test(
    //   'requiredIfNotNone',
    //   getTranslatedText('isRequired'),
    //   function (value) {
    //     const { parent } = this;
    //     const currentType = parent.targetType;
    //     if (currentType === undefined) {
    //       return true;
    //     }
    //     return !!value;
    //   },
    // ),
    contactName: ValidationHelper.RequiredString(getTranslatedText('isRequired')),
    contactEmail: ValidationHelper.RequiredEmail(getTranslatedText('isRequired')),
  }),
});
