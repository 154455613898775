/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useExpenseDeleteMutation } from "../context/expense.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const ExpenseDeleteDialog = memo(
  (props: {
    open: boolean;
    expenseId: string;
    onClose: () => void;
    setExpense: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      expenseDelete,
      {
        data: expenseDeleteData,
        isLoading: expenseDeleteIsLoading,
        error: expenseDeleteError,
      },
    ] = useExpenseDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.expenseId}
        setItem={props.setExpense}
        deleteMutation={expenseDelete}
        deleteMutationData={{
          data: expenseDeleteData,
          isLoading: expenseDeleteIsLoading,
          error: expenseDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.delete"),
          successMessage: t("crm.expense.expense.deletedSuccessfully"),
          content: t("crm.expense.expense.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
