import { Route, Routes } from 'react-router-dom';

import { RolesLayout } from '../layouts/RolesLayout';
import { RoleDetail } from '../pages/detail/RoleDetail';
import { RoleList } from '../pages/list/RoleList';
import { RoleCreate } from '../pages/create/RoleCreate';

export const RolesRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<RolesLayout />}>
        <Route index element={<RoleList />} />
        <Route path="/:id" element={<RoleDetail />} />
        <Route path="/create" element={<RoleCreate />} />
      </Route>
    </Routes>
  );
};
