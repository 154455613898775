import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { IGraphqlVariables } from 'corede-common';
import { ValidationHelper } from 'validations/validation.helper';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Stack, Box, Avatar } from '@mui/material';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import LogoMini from 'assets/images/co-black.svg';

interface ICommentsProps {
  comments: string;
}

const validateCommentsInput = ValidationHelper.BuildObjectValidationSchema({
  comments: ValidationHelper.NotRequiredString(),
});

const Comments = () => {
  const { t } = useTranslation();

  const initialValues = useMemo<IGraphqlVariables<ICommentsProps>>(
    () => ({
      input: {
        comments: '',
      },
    }),
    [],
  );

  const commentUseForm = useForm<IGraphqlVariables<ICommentsProps>>({
    defaultValues: initialValues,
    resolver: yupResolver(validateCommentsInput),
    mode: 'onChange',
  });

  return (
    <>
      <DefaultRichTextQuillInput
        inputName="input.comments"
        control={commentUseForm.control}
        placeholder={t('auth.comments')}
      />

      <Typography variant="body2" sx={{ fontWeight: '500' }}>
        {t('auth.comments')}
      </Typography>

      {
        // TODO: You can create Array from props data - Selcuk
        [1, 2].map((item) => (
          <Stack key={item} direction={'row'} gap={2} sx={{ mt: 2 }}>
            <Avatar alt={'coCRM'} src={LogoMini} sx={{ width: 38, height: 38 }} />

            <Box>
              <Typography variant="body2">
                Lorem ipsum dolor sit amet consecter. Imperdiet lectus pellentesque cursus senectus
                nulla quam sodales.{' '}
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                Today 10:20 AM
              </Typography>
            </Box>
          </Stack>
        ))
      }
    </>
  );
};

export default Comments;
