import crmLocale from '../../apps/crm/locales/tr';
import authLocale from '../../apps/auth/locales/tr';
import leadPlusLocale from '../../apps/leadPlus/locales/tr';
import communityLocale from '../../apps/community/locales/tr';
import marketingLocale from '../../apps/marketing/locales/tr';
import windowLocale from '../../apps/window/locales/tr';
import settingsLocale from '../../apps/settings/locales/tr';
import webNotificationLocale from '../../apps/webNotification/locales/tr';
import accountLocale from '../../apps/account/locales/tr';
import diskLocale from '../../apps/disk/locales/tr';

const locale = {
  crm: {
    ...crmLocale,
  },
  auth: {
    ...authLocale,
  },
  leadPlus: {
    ...leadPlusLocale,
  },
  community: {
    ...communityLocale,
  },
  marketing: {
    ...marketingLocale,
  },
  window: {
    ...windowLocale,
  },
  settings: {
    ...settingsLocale,
  },
  webNotification: {
    ...webNotificationLocale,
  },
  account: {
    ...accountLocale,
  },
  disk: {
    ...diskLocale,
  },
  enums: {
    '-': '-',
    undefined: '-',
    null: '-',
    active: 'Aktif',
    pending: 'Beklemede',
    passive: 'Pasif',
    neutral: 'Nötr',
    cold: 'Soğuk',
    warm: 'Ilık',
    hot: 'Sıcak',
    customer: 'Müşteri',
    unqualified: 'Niteliksiz',
    IQL: 'IQL (Bilgi)',
    MQL: 'MQL (Pazarlama)',
    SQL: 'SQL (Satış)',
    en: 'İngilizce',
    fr: 'Fransızca',
    de: 'Almanca',
    it: 'İtalyanca',
    pt: 'Portekizce',
    ru: 'Rusça',
    es: 'İspanyolca',
    tr: 'Türkçe',
    individual: 'Bireysel',
    business: 'İşletme',
    government: 'Kamu',
    b2b: 'B2B',
    b2c: 'B2C',
    TRY: 'TRY',
    EUR: 'EUR',
    USD: 'USD',
    IRR: 'IRR',
    GBP: 'GBP',
    NOK: 'NOK',
    RUB: 'RUB',
    CHF: 'CHF',
    flat: 'Sabit',
    hourly: 'Saatlik',
    daily: 'Günlük',
    monthly: 'Aylık',
    NDA: 'Gizlilik Sözleşmesi',
    termsAndConditions: 'Şartlar ve Koşullar',
    lead: 'Lead',
    unknown: 'Bilinmeyen',
    everyday: 'Her Gün',
    everyWeek: 'Her Hafta',
    annual: 'Yıllık',
    male: 'Erkek',
    female: 'Kadın',
    other: 'Diğer',
    All: 'Hepsi',
    Unread: 'Okunmamış',
    TWENTY_FOUR_HOURS: '24 saat',
    TWELVE_HOURS: '12 saat',
    '24-hours': '24 saat',
    '12-hours': '12 saat',
    automatic: 'Otomatik',
    manual: 'Manuel',
    high: 'Yüksek',
    low: 'Düşük',
    medium: 'Orta',
    urgent: 'Acil',
    draft: 'Taslak',
    sent: 'Gönderildi',
    opened: 'Açıldı',
    revised: 'Revize Edildi',
    declined: 'Reddedildi',
    accepted: 'Kabul Edildi',
    product: 'Ürün',
    service: 'Hizmet',
    defective: 'Kusurlu Ürün',
    incorrectItem: 'Yanlış Ürün',
    changeOfMind: 'Fikir Değişimi',
    moneyTransfer: 'Para Transferi',
    throughTasks: 'Görevler Aracılığıyla',
    fixedRate: 'Sabit Ücret',
    projectHours: 'Proje Saatleri',
    taskHours: 'Görev Saatleri',
    onHold: 'Beklemede',
    nearToExpire: 'Süresi Yakında Dolacak',
    trash: 'Çöp',
    A: 'A',
    B: 'B',
    C: 'C',
    yes: 'Evet',
    no: 'Hayır',
    default: 'Varsayılan',
    meeting: 'Toplantı',
    call: 'Arama',
    event: 'Etkinlik',
    postponed: 'Ertelendi',
    canceled: 'İptal Edildi',
    email: 'E-posta',
    sms: 'Sms',
    push: 'Push',
    web: 'Web',
    iyzico: 'Iyzico',
    stripe: 'Stripe',
    posbasit: 'POS Basit',
    paytr: 'PayTR',
    requested: 'Talep Edildi',
    inspected: 'İncelendi',
    approved: 'Onaylandı',
    disapproved: 'Onaylanmadı',
    resolved: 'Çözüldü',
    refund: 'İade',
    replacement: 'Değişim',
    repair: 'Tamir',
    exchange: 'Takas',

    // UIElementType
    fieldInput: 'Alan Girişi',
    header: 'Başlık',
    paragraph: 'Paragraf',

    // LeadFieldEnum
    fullName: 'Ad Soyad',
    position: 'Pozisyon',
    website: 'Web Sitesi',
    phoneNumber: 'Telefon Numarası',
    company: 'Şirket',
    sector: 'Sektör',
    country: 'Ülke',
    state: 'Eyalet',
    city: 'Şehir',
    documents: 'Dökümanlar',

    // reminder
    schedule: 'Programla',
    complete: 'Tamamla',
    cancel: 'İptal Et',
    scheduled: 'Programlandı',
    unscheduled: 'Programlanmadı',
    expired: 'Süresi Doldu',
    cancelled: 'İptal Edildi',

    // subscription status
    ongoing: 'AKTİF',
    ongoingWithFailure: 'AKTİF',
    ongoingWithCancelled: 'AKTİF',
    failed: 'BAŞARISIZ',

    // task status
    notStarted: 'Başlamadı',
    inProgress: 'Devam Ediyor',
    tested: 'Test Edildi',
    awaitingFeedback: 'Geri Bildirim Bekleniyor',
    completed: 'Tamamlandı',

    // target type
    Lead: 'Lead',
    Customer: 'Müşteri',
    Estimate: 'Tahmin',
    Proposal: 'Teklif',
    Invoice: 'Fatura',
    Task: 'Görev',
    User: 'Kullanıcı',
    Organization: 'Organizasyon',
    Project: 'Proje',
    None: 'Hiçbiri',
    Ticket: 'Bilet',
    Expense: 'Harcama',
    Contract: 'Sözleşme',

    //ticket categories
    incidentTicket: 'Olay Bileti',
    requestForInformation: 'Bilgi Talebi',
    serviceRequest: 'Hizmet Talebi',
    problemTicket: 'Sorun Bileti',
    changeRequest: 'Değişiklik Talebi',
    featureRequest: 'Özellik Talebi',
    feedback: 'Geri Bildirim',
    complaint: 'Şikayet',
    maintenanceAndOutageReport: 'Bakım ve Kesinti Raporu',
    billingAndPaymentIssue: 'Fatura ve Ödeme Sorunu',
    internalTicket: 'Dahili Bilet',
    open: 'Açık',
    answered: 'Cevaplandı',
    closed: 'Kapandı',

    //permissions
    auth: 'Kimlik Doğrulama',
    comment: 'Yorum',
    dms: 'Dosya',
    file: 'Dosya',
    note: 'Not',
    notification: 'Bildirim',
    payment: 'Ödeme',
    presale: 'Ön Satış',
    reminder: 'Hatırlatıcı',
    sale: 'Satış',
    task: 'Görev',
    user: 'Kullanıcı',
    permission: 'İzin',
    role: 'Rol',
    leadSource: 'Lead Kaynağı',
    leadStatus: 'Lead Durumu',
    leadTag: 'Lead Etiketi',
    productCategory: 'Ürün Kategorisi',
    webNotification: 'Web Bildirimi',
    notificationHistory: 'Bildirim Geçmişi',
    order: 'Sipariş',
    purchase: 'Satın Alma',
    subscription: 'Abonelik',
    proposal: 'Teklif',
    estimate: 'Tahmin',
    customerCategory: 'Müşteri Kategorisi',
    customerTag: 'Müşteri Etiketi',
    invoice: 'Fatura',
    invoicePayment: 'Ödeme',
    invoiceReturn: 'İade',
    organization: 'Organizasyon',
    organizationSettings: 'Organizasyon Ayarları',
    organizationChart: 'Organizasyon Şeması',
    department: 'Departman',
    userRole: 'Kullanıcı Rolü',
    userPermission: 'Kullanıcı İzni',
    manage: 'Yönet',
    view: 'Görüntüle',
    export: 'Dışa Aktar',
    import: 'İçe Aktar',
    calendar: 'Takvim',
    appointment: 'Randevu',
    contract: 'Sözleşme',
    contractTemplate: 'Sözleşme Şablonu',
    expense: 'Harcama',
    project: 'Proje',
    ticket: 'Bilet',
    dashboard: 'Gösterge Paneli',
  },
  aboutUs: 'Hakkımızda',
  termsAndConditions: 'Şartlar ve Koşullar',
  privacyPolicy: 'Gizlilik Politikası',
  cookiesPolicy: 'Çerez Politikası',
  resources: 'Kaynaklar',
  rowsPerPage: 'Sayfa başına satır',
  chartUpdateSuccess: 'Grafik başarıyla güncellendi!',
  imageUploadSuccess: 'Resim başarıyla yüklendi!',
  uploadPicture: 'Resim yükle',
  uploadDocuments: 'Döküman yükle',
  expiresIn: 'Kalan gün: ',
  expiresDays: '',
  subscriptionExpired: 'Kullanım süreniz bitmiştir!',
  noOptionsText: 'Seçenek yok',
  noCategory: 'Kategori yok',

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////

  isRequired: 'Bu alan zorunludur',
  minValue0: 'Minimum 0 olmalıdır',
  fullNameIsRequired: 'Ad soyad gereklidir',
  emailIsRequired: 'Email gereklidir',
  emailIsInvalid: 'Email yanliş girildi',
  phoneIsInvalid: 'Telefon numarası yanlış girildi',
  nameIsRequired: 'İsim zorunludur',
  titleIsRequired: 'Başlık zorunludur',
  profileImageIsRequired: 'Profil resmi gereklidir',
  descriptionIsRequired: 'Açıklama zorunludur',
  subjectIsRequired: 'Konu gereklidir',
  phoneNumberIsRequired: 'Telefon numarası gereklidir',
  quotaYupIsRequired: 'Kota gereklidir',
  dateYupIsRequired: 'Tarih gereklidir',
  statusYupIsRequired: 'Durum gereklidir',
  locationYupIsRequired: 'Konum gereklidir',
  detailsIsRequired: 'Detaylar gereklidir',
  surnameIsRequired: 'Soyadı gereklidir',
  birthDateIsRequired: 'Doğum tarihi gereklidir',
  genderIsRequired: 'Cinsiyet gereklidir',
  countryIsRequired: 'Ülke gereklidir',
  cityIsRequired: 'İlçe gereklidir',
  addressIsRequired: 'Adres gereklidir',
  contentIsRequired: 'İçerik gereklidir',
  readingTimeIsRequired: 'Okuma süresi gereklidir',
  tagsIsRequired: 'Etiketler gereklidir',
  referencesIsRequired: 'Referanslar gereklidir',
  relatedVideoIsRequired: 'İlgili video gereklidir',
  authorIsRequired: 'Yazar gereklidir',
  categoryIsRequired: 'Kategori gereklidir',
  targetCategoryIsRequired: 'Hedef kategori gereklidir',
  languageIsRequired: 'Dil gereklidir',
  domainIsRequired: 'Alan gereklidir',
  targetIsRequired: 'Hedef gereklidir',
  titleIsTooShort25: 'Başlık çok kısa, minimum uzunluk 25',
  titleIsTooLong50: 'Başlık çok uzun, maksimum uzunluk 50',
  contentIsTooShort50: 'İçerik çok kısa, minimum uzunluk 50',
  contentIsTooLong250: 'İçerik çok uzun, maksimum uzunluk 250',
  descriptionIsTooShort400: 'Açıklama çok kısa, minimum uzunluk 400',
  descriptionIsTooLong4000: 'Açıklama çok uzun, maksimum uzunluk 4000',
  quotaMustBePositive: 'Kota pozitif olmalıdır',
  quotaMustBeMultipleOf10: "Kota 10'un katı olmalıdır",
  participantsIsRequired: 'Katılımcılar gereklidir',
  startDateIsRequired: 'Başlangıç tarihi gereklidir',
  durationIsRequired: 'Süre gereklidir',
  participationLinkIsRequired: 'Katılım linki gereklidir',
  lastApplicationDateIsRequired: 'Son başvuru tarihi gereklidir',
  statusIsRequired: 'Durum gereklidir',
  locationIsRequired: 'Konum gereklidir',
  typeIsRequired: 'Tip gereklidir',
  durationMustBeMultipleOf5: "Süre 5'in katı olmalıdır",
  durationMustBePositive: 'Süre pozitif olmalıdır',
  stateIsRequired: 'Şehir gereklidir',
  positionIsRequired: 'Pozisyon gereklidir',
  companyIsRequired: 'Şirket adı gereklidir',
  sectorIsRequired: 'Bölüm gereklidir',
  qualificationIsRequired: 'Derece gereklidir',
  websiteIsInvalid: 'Web sitesi yanlış girildi',
  identityNumberIsRequired: 'Kimlik numarası gereklidir',
  taxOfficeIsRequired: 'Vergi dairesi gereklidir',
  streetIsRequired: 'Sokak gereklidir',
  zipIsRequired: 'Posta kodu gereklidir',
  passwordIsRequired: 'Parola gereklidir',
  passwordIsInvalid: 'Minimum 8 karakter, büyük/küçük harf, rakam ve sembol içermelidir.',
  passwordAgainIsInvalid: 'Parolalar uyumsuz',
  cannotBeGreaterThanPrice: 'Fiyattan büyük olamaz',
  endTimeMustBeLaterThanStartTime: 'Başlangıçtan sonra olmalıdır',
  questionIsRequired: 'Soru gereklidir',
  answerIsRequired: 'Cevap gereklidir',
  nameIsTooShort2: 'İsim çok kısa, minimum uzunluk 2',
  nameIsTooLong50: 'İsim çok uzun, maksimum uzunluk 50',
  surnameIsTooShort2: 'Soyisim çok kısa, minimum uzunluk 2',
  surnameIsTooLong50: 'Soyisim çok uzun, maksimum uzunluk 50',
  prospectNameIsRequired: 'Lead adı gereklidir',
  prospectPhoneNumberIsRequired: 'Lead telefon numarası gereklidir',
  prospectContactFirstNameIsRequired: 'Lead iletişim adı gereklidir',
  prospectContactLastNameIsRequired: 'Lead iletişim soyadı gereklidir',
  prospectContactEmailIsRequired: 'Lead iletişim e-postası gereklidir',
  quotaIsRequired: 'Kota gereklidir',
  mustBeNumber: 'Sayı girilmeilidir',

  cardValidation: {
    required: 'Zorunlu',
    invalid: 'Geçersiz',
    expired: 'Süresi dolmuş',
  },

  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: 'İptal',
  save: 'Kaydet',
  update: 'Güncelle',
  delete: 'Sil',
  create: 'Oluştur',
  add: 'Ekle',
  close: 'Kapat',
  submit: 'Gönder',
  view: 'Göster',
  edit: 'Düzenle',
  remove: 'Sil',
  myPlan: 'Planım',
  expandAll: 'Genişlet',
  collapseAll: 'Daralt',
  show: 'Göster',
  addDocument: 'Dökuman Ekle',
  upload: 'Yükle',
  download: 'İndir',
  yes: 'Evet',
  no: 'Hayır',
  completed: 'Tamamlandı',
  uploadDocument: 'Döküman Yükle',
  newTask: 'Yeni Görev',
  loadMore: 'Daha Fazla',

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: 'İşlemler',
  title: 'Başlık',
  content: 'İçerik',
  category: 'Kategori',
  target: 'Hedef',
  status: 'Durum',
  date: 'Tarih',
  location: 'Konum',
  details: 'Detaylar',
  name: 'İsim',
  surname: 'Soyisim',
  birthDate: 'Doğum Tarihi',
  fullName: 'Ad Soyad',
  email: 'E-posta',
  subject: 'Konu',
  createdAt: 'Oluşturulma Tarihi',
  page: 'Sayfa',
  news: 'Haber',
  offer: 'Teklif',
  products: 'Ürünler',
  updatedAt: 'Güncellenme Tarihi',
  readingTime: 'Okuma Süresi',
  tags: 'Etiketler',
  references: 'Referanslar',
  version: 'Versiyon',
  domain: 'Alan',
  type: 'Tip',
  language: 'Dil',
  duration: 'Süre',
  startDate: 'Başlangıç Tarihi',
  lastApplicationDate: 'Son Başvuru Tarihi',
  participationLink: 'Katılım Linki',
  question: 'Soru',
  answer: 'Cevap',
  selectAnOption: 'Bir seçenek seçin',
  showing: '{{start}} ile {{end}} arası, toplam {{total}} kayıt',

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: 'Panel',
  blog: 'Blog',
  blogs: 'Bloglar',
  categories: 'Kategoriler',
  authors: 'Yazarlar',
  faq: 'SSS',
  faqs: "SSS'ler",
  faqCategory: 'SSS Kategorileri',
  form: 'Form',
  contactForm: 'İletişim Formları',
  subscriptionForm: 'Abonelik Formları',
  blogTargetCategories: 'Hedef Kategoriler',
  users: 'Kullanıcılar',
  departments: 'Departmanlar',
  roles: 'Roller',
  permissions: 'Izinler',
  leadStatuses: 'Lead Durumları',
  leadSources: 'Lead Kaynakları',
  leadForms: 'Lead Formları',
  customers: 'Müşteriler',
  contacts: 'İletişim',
  customerCategories: 'Müşteri Kategorileri',
  estimates: 'Tahminler',
  proposals: 'Teklifler',
  invoices: 'Faturalar',
  payments: 'Ödemeler',
  contracts: 'Sozlesmeler',
  returns: 'İadeler',
  services: 'Hizmetler',
  reminders: 'Hatırlatmalar',
  appointments: 'Randevular',
  starred: 'Yıldızlı',
  recent: 'En Son',
  actionMenu: 'Aksiyon Menü',
  themeMenu: 'Tema Menü',
  notifications: 'Bildirimler',
  languageMenu: 'Dil Menüsü',

  ////////////////////////////////////////////////////////////////
  // Permissions
  ////////////////////////////////////////////////////////////////

  organizationLevel: 'Organizasyon Seviyesi',
  organizationLevelDesc: 'Kullanıcının bulunduğu organizasyonun seviyesi.',
  departmentLevel: 'Departman Seviyesi',
  departmentLevelDesc: 'Kullanıcının bulunduğu departman seviyesi.',
  userLevel: 'Kullanıcı Seviyesi',
  userLevelDesc: 'Kullanıcının seviyesi.',

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: 'dk',
  userType: 'Kullanıcı Türü',
  participants: 'Katılımcılar',
  quota: 'Kota',
  userRole: 'Kullanıcı Rolü',
  userPermission: 'Kullanıcı İzni',

  ////////////////////////////////////////////////////////////////
  // Days
  ////////////////////////////////////////////////////////////////

  monday: 'Pazartesi',
  tuesday: 'Salı',
  wednesday: 'Çarşamba',
  thursday: 'Perşembe',
  friday: 'Cuma',
  saturday: 'Cumartesi',
  sunday: 'Pazar',
  today: 'Bugün',
};

export default locale;
