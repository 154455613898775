/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button, Menu, MenuItem, Typography } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IExpenseDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { useExpenseUpdateMutation } from '../../context/expense.api';
import { enqueueSnackbar } from 'notistack';
import { IBaseError } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import ActionDialog from 'components/dialog/ActionDialog';

export interface IExpenseDetailDrawerLeftPanelActionsComponentProps {
  expenseDetailData: IExpenseDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert expense to customer
 */
export const ExpenseDetailDrawerLeftPanelActionsComponent = (
  props: IExpenseDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const [
    expenseUpdate,
    { data: expenseUpdateData, isLoading: expenseUpdateLoading, error: expenseUpdateError },
  ] = useExpenseUpdateMutation();


  useEffect(() => {
    if (expenseUpdateData) {
      enqueueSnackbar(t('crm.expense.expense.statusChangeSuccess'), {
        variant: 'success',
      });
    }
  }, [expenseUpdateData]);

  useEffect(() => {
    if (expenseUpdateError) {
      DefaultErrorHandlerUseEffect(expenseUpdateError as IBaseError, currentLanguage);
    }
  }, [expenseUpdateError]);

  const StatusMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        {/* <Button
          id="expense-detail-button"
          aria-controls={open ? 'expense-detail-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          color="primary"
          variant="contained"
          disabled={expenseUpdateLoading}
          sx={{
            height: 40,
            fontSize: '13px',
            borderRadius: 100,
          }}
        >
          <Icons.RefreshRounded sx={{ mr: 1 }} />
          {t('crm.expense.expense.changeStatus')}
        </Button> */}
        {/* <Menu
          id="expense-detail-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'expense-detail-button',
          }}
          sx={{
            '& .MuiPaper-root': {
              bgcolor: 'background.secondWithBlur',
              backdropFilter: 'blur(3px)',
              borderRadius: 2,
            },
          }}
        >
          {Object.entries(ExpenseStatus).map(([key, value]) => (
            <MenuItem key={key} onClick={() => handleChangeStatus(value)}>
              <Typography fontSize={'small'} fontWeight={'bold'}>
                {getTranslatedEnumValue(value)}
              </Typography>
            </MenuItem>
          ))}
        </Menu> */}
      </div>
    );
  };

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Expense base actions */}
      <StatusMenu />
    </Stack>
  );
};
