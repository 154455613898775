/* eslint-disable react-hooks/exhaustive-deps */
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import { InvoiceDetailDrawerHeaderStatusComponent } from './InvoiceDetailDrawer-rightPanel-header-status.component';
// import { InvoiceDetailDrawerBaseInfoComponent } from './InvoiceDetailDrawer-rightPanel-header-baseInfo.component';

export interface IInvoiceDetailDrawerRightPanelHeaderComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const InvoiceDetailDrawerRightPanelHeaderComponent = (
  props: IInvoiceDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <InvoiceDetailDrawerHeaderStatusComponent invoiceDetailData={props.invoiceDetailData} />
      {/* <InvoiceDetailDrawerBaseInfoComponent invoiceDetailData={props.invoiceDetailData} /> */}
    </>
  );
};
