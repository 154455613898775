import { Grid } from '@mui/material';
import { memo, useState } from 'react';
import { Add } from '@mui/icons-material';
import { useCustomerCategoryListCountQuery } from '../../context/customerCategory.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import CustomerCategoryListComponent from './CustomerCategoryList-grid.component';
import CustomerCategoryCreateDrawerWrapper from '../create/CustomerCategoryCreateDrawer.wrapper';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionAction } from 'corede-common';

const CustomerCategoryList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openCustomerCategoryCreateDrawer, setOpenCustomerCategoryCreateDrawer] = useState(false);

  const [customerCategoryListType, setCustomerCategoryListType] = useState('list');

  // queries
  const { data: customerCategoryListCountData } = useCustomerCategoryListCountQuery({});

  let totalCustomerCategoryCount = customerCategoryListCountData?.count ?? 0;
  let isEmpty = totalCustomerCategoryCount === 0;

  // useEffects.error

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.customer.customerCategory.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.customer.customerCategory.create`),
          onClick: () => setOpenCustomerCategoryCreateDrawer(true),
          permission: {
            subject: PermissionSubject.customerCategory,
            action: PermissionAction.create,
          },
        }}
      />

      {customerCategoryListType === 'list' ? (
        <CustomerCategoryListComponent
          setOpenCustomerCategoryCreateDrawer={setOpenCustomerCategoryCreateDrawer}
        />
      ) : null}

      <CustomerCategoryCreateDrawerWrapper
        open={openCustomerCategoryCreateDrawer}
        setOpen={setOpenCustomerCategoryCreateDrawer}
      />
    </Grid>
  );
});

export default CustomerCategoryList;
