

import { ExpenseDomainLayout } from "../layouts";
import { ExpenseRouter } from "../subdomains/expense/routes/ExpenseRouter";
import { expenseDomainRoutes } from "./expense.base.route";
import { Route, Routes } from "react-router-dom";

export const ExpenseDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ExpenseDomainLayout />}>
        <Route index element={<ExpenseRouter />} />
        <Route path="*" element={<ExpenseRouter />} />
      </Route>
    </Routes>
  );
};
