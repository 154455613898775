const locale = {
  myAccount: "My Account",
  profilePicture: "Profile Picture",
  profile: "Profile",
  name: "Name",
  surname: "Surname",
  birthDate: "Birth Date",
  gender: "Gender",
  update: "Update",
  changeImage: "Change Image",
  change: "Change",
  updateProfileSuccess: "Profile updated successfully",
  updateProfilePictureSuccess: "Profile picture updated successfully",
  country: "Country",
  city: "City",
  signatureImage: "Signature Image",
};

export default locale;
