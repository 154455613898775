const locale = {
  supports: 'Tickets',
  support: 'Ticket',
  detail: 'Ticket Details',
  subject: 'Subject',
  title: 'Tickets',
  create: 'Create Ticket',
  createSuccess: 'Ticket created successfully.',
  update: 'Update Ticket',
  updateSuccess: 'Ticket updated successfully.',
  delete: 'Delete Ticket',
  deleteSuccess: 'Ticket deleted successfully.',
  deleteContent: 'Are you sure you want to delete the ticket?',
  emptyState1: 'No ticket yet.',
  emptyState2:
    'You haven’t created any tickets, or they’ve already been resolved. Once your team raise a support request, all tickets will appear here.',

  // detailDrawer
  generalInfoTitle: 'General Information',
  ticketInfoTitle: 'Ticket Information',
  closedDate: 'Closed Date',
  changeStatus: 'Change Status',
  statusChangeSuccess: 'Status changed successfully.',
  };

export default locale;
