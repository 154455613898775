const locale = {
  somethingWentWrong: 'Something went wrong!',
  loginSuccess: 'Login Successfully',
  signinText: "Let's sign in.",
  emailAddress: 'Email address',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  login: 'Login',
  continueWith: 'or continue with',
  noAccount: "Don't have an account?",
  signUp: 'Sign up',
  logoAlt: 'logo',
  growYourBusiness: 'All You Need to Grow Your',
  business: 'Business',
  bgPattern: 'background pattern',
  manageYourBusiness:
    'Manage your business efficiently, increase your sales, reach potential customers, and empower yourself by joining the community.',

  quote1: 'Your most unhappy customers are your greatest source of learning.',
  quoteName1: 'Bill Gates',
  quoteCompany1: 'Microsoft',
  quoteImage1: 'Bill-Gates',

  quote2: 'Customer service should not be a department. It should be the entire company.',
  quoteName2: 'Tony Hsieh',
  quoteCompany2: 'Zappos',
  quoteImage2: 'Tony-Hsieh',

  quote3: 'The sole reason we are in business is to make life less difficult for our clients.',
  quoteName3: 'Matthew Odgers',
  quoteCompany3: 'Odgers Law Group',
  quoteImage3: 'Matthew-Odgers',

  quote4: 'If you are not taking care of your customer, your competitor will.',
  quoteName4: 'Bob Hooey',
  quoteCompany4: 'Ideaman',
  quoteImage4: 'Bob-Hooey',

  quote5: 'In sales, a referral is the key to the door of resistance.',
  quoteName5: 'Bo Bennett',
  quoteCompany5: 'Archieboy',
  quoteImage5: 'Bo-Bennett',

  quote6: 'Always do your best. What you plant now, you will harvest later.',
  quoteName6: 'Og Mandino',
  quoteCompany6: 'Author',
  quoteImage6: 'Og-Mandino',

  quote7: 'One of the greatest gifts you can give to anyone is the gift of your attention.',
  quoteName7: 'Jim Rohn',
  quoteCompany7: 'Author',
  quoteImage7: 'Jim-Rohn',

  quote8: 'Do what you do so well that they will want to see it again and bring their friends.',
  quoteName8: 'Walt Disney',
  quoteCompany8: 'Disney',
  quoteImage8: 'Walt-Disney',

  passwordResetSuccess: 'Your password has been reset successfully!',
  resetPasswordTitle: 'Reset Password',
  enterNewPassword: 'Please enter your new password.',
  passwordAgain: 'Password Again',
  resetPassword: 'Reset Password',
  alreadyHaveAccount: 'Already have an account?',
  resetPasswordRequestSuccess: 'Request Reset Password Successfully',
  forgotPasswordTitle: 'Forgot Password',
  resetPasswordInstructions: 'You can reset your password by entering your email address.',
  rememberPassword: 'Remember your password?',
  userConfirmedSuccess: 'User confirmed successfully!',
  emailConfirmationTitle: 'Email Confirmation',
  enterOtp: 'Please enter the OTP sent to your email.',
  submit: 'Verify',
  didNotReceiveCode: "Didn't receive the code?",
  resendCode: 'Resend code',
  resendCodeTitle: 'Resend Confirmation Code',
  enterEmailToResend: 'Please enter your email address to receive the confirmation code.',
  email: 'Email',
  close: 'Close',
  send: 'Send',
  signupSuccess: 'Signup Successfully',
  letsRegister: "Let's register you in.",
  name: 'Name',
  surname: 'Surname',
  iAccept: 'I accept the',
  and: 'and',
  with: 'and',
  termsAndConditions: 'Terms and Conditions',
  privacyPolicy: 'Privacy Policy',
  explicitConsent: 'Explicit Consent Policy',
  kvkkPolicy: 'KVKK Information Policy',
  orContinueWith: 'or continue with',
  createPassword: 'Create Password',
  createPasswordTitle: 'Create Password',
  signupSuccessfullTitle: 'Sign up successfully!',
  signupSuccessfullDescription: 'Please check your email to confirm your account.',
  backToLogin: 'Back to Login',
  resendConfirmationSuccessTitle: 'Resend Confirmation Code Successfully',
  resendConfirmationSuccessText: 'Please check your email to confirm your account.',
  resentCodeSuccess: 'Resent code successfully',
  acceptAllPolicies: 'Please accept all policies!',
  alreadyRegisteredError: 'You are already registered! Please login.',
  userNotFoundError: 'User not found!',

  proposal: 'Proposal',
  estimate: 'Estimate',
  invoice: 'Invoice',
  startDate: 'Start Date',
  expireDate: 'Expire Date',
  deadline: 'Deadline',
  proposalInformation: 'Proposal Information',
  estimateInformation: 'Estimate Information',
  invoiceInformation: 'Invoice Information',
  billTo: 'Bill To',
  bankName: 'Bank Name',
  country: 'Country',
  iban: 'IBAN',
  currency: 'Currency',
  productDescription: 'Product Description',
  qty: 'QTY',
  rate: 'Rate',
  tax: 'Tax',
  amount: 'Amount',
  projectTimeline: 'Project Timeline',
  timeline: 'Timeline',
  accept: 'Accept',
  decline: 'Decline',
  download: 'Download',
  comments: 'Comments',
  documents: 'Documents',
  uploadDocument: 'Upload a document',
  addDocument: 'Add Document',

  estimateAccepted: 'Estimate accepted',
  estimateDeclined: 'Estimate declined',
  proposalAccepted: 'Proposal accepted',
  proposalDeclined: 'Proposal declined',
  invoiceAccepted: 'Invoice accepted',
  invoiceDeclined: 'Invoice declined',
  declineReason: 'Decline Reason',
  declineReasonPlaceholder: 'Please enter the reason for decline',
  
};

export default locale;
