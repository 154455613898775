const locale = {
  demo: 'Demo',
  adjustment: 'Düzenleme',
  currency: 'Para Birimi',
  exchangeRates: 'Döviz Kurları',
  fromCurrency: 'Kaynak Para Birimi',
  toCurrency: 'Hedef Para Birimi',
  rate: 'Kur',
  updatedAt: 'Güncelleme Zamanı',
  language: 'Dil',
  locale: 'Yerel Ayar',
  updateSettings: 'Ayarları Güncelle',
  NDA: 'Gizlilik Sözleşmesi',
  followUpMailTemplate: 'Takip E-Posta Şablonu',
  emailType: 'E-Posta Türü',
  subject: 'Konu',
  content: 'İçerik',
  variables: 'Değişkenler',
  prefix: 'Önek',
  paymentPrefix: 'Ödeme Önek',
  returnPrefix: 'İade Önek',
  termsAndConditions: 'Şartlar ve Koşullar',
  paymentMethods: 'Ödeme Yöntemleri',
  companyBackground: 'Şirket Geçmişi',
  companyReferences: 'Şirket Referansları',
  timezone: 'Zaman Dilimi',
  updateSuccess: 'Ayarlar başarıyla güncellendi',
  name: 'Ad',
  surname: 'Soyad',
  email: 'E-posta',
  phone: 'Telefon',
  identityNumber: 'Kimlik Numarası',
  vat: 'Vergi Numarası',
  country: 'Ülke',
  state: 'Şehir',
  city: 'İlçe',
  street: 'Cadde',
  address: 'Adres',
  taxOffice: 'Vergi Dairesi',
  zip: 'Posta Kodu',
  fullName: 'Tam Ad',
  update: 'Güncelle',
  create: 'Oluştur',
  updateBillingInfo: 'Fatura Bilgisini Güncelle',
  updateBillingInfoSuccess: 'Fatura bilgileri güncellendi',
  commonSettingsUpdateSuccess: 'Ayarlar başarıyla güncellendi',
  save: 'Kaydet',
  organizationSettings: 'Organizasyon',
  monthly: 'aylık',
  trial: 'Deneme',
  free: 'Ücretsiz',
  trialEnds: 'Deneme Sonu',
  browsePackages: 'Paketlere Gözat',
  cancelSubscription: 'Abonelik İptali',
  updateOrganizationLogoSuccess: 'Organizasyon logosu güncellendi',
  organizationLogo: 'Organizasyon Logosu',
  dateFormat: 'Tarih Formatı',
  timeFormat: 'Zaman Formatı',
  vatNumber: 'Vergi Numarası',
  taxRate: 'Vergi Oranı',
  general: 'Genel',
  localizations: 'Yerel Ayarlar',
  finance: 'Finans',
  generalInfo: 'Genel Bilgiler',
  summary: 'Özet',
  socialMedia: 'Sosyal Medya',
  exchangeRateMode: 'Döviz Kuru',
  billingInfo: 'Fatura Bilgileri',
  cards: 'Kartlar',
  currentPlan: 'Mevcut Plan',
  invoices: 'Faturalar',
  cardListSecure: 'Kartlarınız iyzico veritabanında saklanmaktadır.',
  noInvoiceContent1: 'Henuz fatura kaydedilmedi.',
  noInvoiceContent2: 'Faturalarınızı oluşturmadan önce fatura bilgilerini düzenleyebilirsiniz.',
  noLanguageContent1: 'Henuz dil kaydedilmedi.',
  noLanguageContent2: 'Dil ayarlarınızı oluşturmadan önce dil bilgilerini düzenleyebilirsiniz.',
  noLeadContent1: 'Potansiyel müşteri ayarları şu anda kullanılamıyor. CRM ',
  noLeadContent2:
    'CRM iş akışınızı kolaylaştırmak için potansiyel müşterilerin nasıl yakalandığını, atandığını ve takip edildiğini özelleştirin.',
  noCustomerContent1: 'Müşteri ayarları şu anda kullanılamıyor. ',
  noCustomerContent2:
    "Müşteri bilgilerini ve etkileşimlerini CRM'niz içinde etkili bir şekilde yönetmek için tercihleri ayarlayın.",
  noEstimateContent1: 'Faturalandırma ayarları şu anda kullanılamıyor. ',
  noEstimateContent2:
    'Fatura tercihlerinizi burada özelleştirerek faturalandırmayı kolaylaştırın ve doğru kayıtlar sağlayın.',
  noProposalContent1: 'Teklif ayarları şu anda kullanılamıyor. ',
  noProposalContent2: 'Teklif tercihlerinizi burada özelleştirerek satış sürecinizi optimize edin.',
  noInvoiceContent3: 'Fatura ayarları şu anda kullanılamıyor. ',
  noInvoiceContent4:
    'Fatura tercihlerinizi burada özelleştirerek faturalandırmayı kolaylaştırın ve doğru kayıtlar sağlayın.',
  noProjectContent1: 'Proje ayarları şu anda kullanılamıyor. ',
  noProjectContent2:
    "CRM'nizde görev yönetimini ve iş akışını optimize etmek için proje tercihlerinizi burada özelleştirin.",
  noTaskContent1: 'Görev ayarları şu anda kullanılamıyor. ',
  noTaskContent2:
    'Görev yönetimini kolaylaştırmak ve ekip üretkenliğini artırmak için görev tercihlerinizi burada yapılandırın.',
  noTicketContent1: 'Destek ayarları şu anda kullanılamıyor. ',
  noTicketContent2:
    'Müşteri destek taleplerini yönetmek ve zamanında çözümler sağlamak için tercihlerinizi burada ayarlayın.',
  noFileManagementContent1: 'Dosya yönetimi ayarları şu anda kullanılamıyor.',
  noFileManagementContent2:
    " Belgeleri CRM'nizde etkin bir şekilde düzenlemek ve yönetmek için tercihlerinizi burada yapılandırın.",
  noCalendarContent1: 'Takvim ayarları şu anda kullanılamıyor.',
  noCalendarContent2:
    "Takviminizi özelleştirmek ve CRM'niz içinde planlamayı optimize etmek için tercihlerinizi burada ayarlayın.",
  noNotificationContent1: 'Bildirim ayarları şu anda kullanılamıyor. ',
  noNotificationContent2:
    "CRM'inizdeki önemli etkinlikler hakkında bilgi sahibi olmak ve güncel kalmak için bildirim tercihlerinizi buradan özelleştirin.",
  noLeadPlusContent1: 'Etkin bir Lead+ ürün veya plan mevcut değil.',
  noLeadPlusContent2:
    'Lead+ ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.',
  noMarketingContent1:
    'Marketing ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.',
  noMarketingContent2: 'Etkin bir Marketing ürün veya plan mevcut değil.',
  noWindowContent1: 'Etkin bir Window ürün veya plan mevcut değil. ',
  noWindowContent2:
    'Window ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.',
  noCommunityContent1: 'Etkin bir Community ürün veya plan mevcut değil. ',
  noCommunityContent2:
    'Community ayarlarınızı özelleştirmek ve tam erişimi açmak için bir plan satın alın veya yetkilendirilin.',
  noIntegrationsContent1: 'Henüz hiçbir entegrasyon ayarlanmadı. ',
  noIntegrationsContent2:
    "CoCRM'nin yeteneklerini geliştirmek ve yetkili bağlantılarla iş akışlarını kolaylaştırmak için entegrasyonları burada yapılandırın.",
  noMenuContent1: 'Menü ayarları şu anda kullanılamıyor.',
  noMenuContent2:
    "Menü düzeninizi özelleştirmek ve CRM'nizde gezinmeyi kolaylaştırmak için menü erişimini yetkilendirin.",
  noLegalAgreementsContent1: 'Yasal sözleşmeler şu anda kullanılamıyor.',
  noLegalAgreementsContent2:
    'CRM içerisinde yasal sözleşmeleri yönetmek ve özelleştirmek için erişimi yetkilendirin.',
  noCrmContent1: 'Etkin bir CRM ürün veya plan mevcut değil.',
  noCrmContent2:
    'CRM ayarlarınızı özelleştirerek tam erişimini açmak için bir plan satın alın veya yetkilendirin.',
  noUserListContent1: 'Kullanıcı listesi ayarları şu anda kullanılamıyor.',
  noUserListContent2: 'Kullanıcı listesini özelleştirerek erişiminizi yetkilendirin.',
  noPermissionsContent1: 'Yetki ayarları şu anda kullanılamıyor.',
  noPermissionsContent2: 'Yetkileri özelleştirerek erişiminizi yetkilendirin.',
  lead: 'Lead',
  crm: 'CRM',
  menu: 'Menü',
  customer: 'Müşteri',
  estimate: 'Tahmin',
  proposal: 'Teklif',
  invoice: 'Fatura',
  project: 'Proje',
  task: 'Görev',
  ticket: 'Talep',
  fileManagement: 'Dosya Yönetimi',
  calendar: 'Takvim',
  notification: 'Bildirimler',
  leadPlus: 'Lead+',
  marketing: 'Marketing',
  window: 'Window',
  community: 'Community',
  integrations: 'Entegrasyonlar',
  integration: 'Entegrasyon',
  userList: 'Kullanıcı Listesi',
  permissions: 'Yetkiler',
  userType: 'Kullanıcı Tipi',
  companyName: 'Şirket Adı',
  allowableFileFormats: 'İzin Verilen Dosya Formatları',

  // currentPlan
  yourCurrentPlan: 'Mevcut Planınız ',
  yourCurrentPlanDesc: 'Herkes için basit bir başlangıç',
  activeUntil: 'Aktif Kalan',
  activeUntilDesc: 'Abonelik süresi dolunca size bir bildirim göndereceğiz',
  paymentDesc: 'Ödeme hesabınıza yansıtılacaktır',
  paymentForTrialDesc: 'Kullanmaya devam etmek için abonelik satın almalısınız',
  perMonth: 'Aylık',
  prtYear: 'Yıllık',
  perMonthDesc: 'Aboneliğinizi istediğiniz zaman iptal edebilirsiniz',
  upgradePlan: 'Planı Yükselt',
  attentionTitle: 'Dikkatinizi çekmek istiyoruz!',
  attentionDesc: 'Planınız güncelleme gerektiriyor',
  days: 'Gün',
  of: '/',
  remainingDesc: 'güncelleme gerektiren planınızın kalan günleri',
  usageOfStorage: 'Depolama Kullanımı',
  users: 'Kullanıcılar',

  //legalAgreements
  legalAgreements: 'Yasal Sözleşmeler',
  createLegalAgreement: 'Yasal Sözleşme Oluştur',
  updateLegalAgreement: 'Yasal Sözleşme Güncelle',
  deleteLegalAgreement: 'Yasal Sözleşme Sil',
  createLegalAgreementSuccess: 'Yasal Sözleşme başarıyla oluşturuldu',
  updateLegalAgreementSuccess: 'Yasal Sözleşme başarıyla güncellendi',
  deleteLegalAgreementSuccess: 'Yasal Sözleşme başarıyla silindi',
  deleteLegalAgreementDesc: 'Bu yasal sözleşmeyi silmek istediğinize emin misiniz?',

  // cards

  deleteCard: 'Kartı Sil',
  addCard: 'Yeni Kart Ekle',
  createCardSuccess: 'Kart başarıyla oluşturuldu',
  addCardSuccess: 'Kart başarıyla eklendi',
  deleteCardSuccess: 'Kart başarıyla silindi',
  deleteCardDesc: 'Bu kartı silmek istediğinize emin misiniz?',
  requiredCard: 'Kart numarası zorunludur',
  requiredCardHolder: 'Kart sahibi adı zorunludur',
  requiredExpireMonth: 'Son kullanma ayı zorunludur',
  requiredExpireYear: 'Son kullanma yılı zorunludur',
  requiredCvc: 'CVC zorunludur',
};

export default locale;
