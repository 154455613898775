import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';

export const validateUpdateProjectInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    name: ValidationHelper.RequiredString(getTranslatedText('nameIsRequired')),
    estimatedHours: ValidationHelper.RequiredNumber(getTranslatedText('estimatedHoursIsRequired')),
    memberIds: ValidationHelper.RequiredArray(getTranslatedText('membersIsRequired')),
  }),
});

export const validateUpdateTimeSheetInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    // title: ValidationHelper.RequiredString(getTranslatedText('titleIsRequired')),
    // content: ValidationHelper.RequiredString(getTranslatedText('contentIsRequired')),
    // targetId: ValidationHelper.RequiredString(getTranslatedText('targetIdIsRequired')),
    // targetType: ValidationHelper.RequiredString(getTranslatedText('targetTypeIsRequired')),
  }),
});

export const validateUpdateMilestoneInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    // taskId: ValidationHelper.RequiredString(getTranslatedText('taskIdIsRequired')),
    // memberId: ValidationHelper.RequiredString(getTranslatedText('memberIdIsRequired')),
    // startTime: ValidationHelper.RequiredDate(getTranslatedText('startTimeIsRequired')),
    // endTime: ValidationHelper.RequiredDate(getTranslatedText('endTimeIsRequired')),
  }),
});
