/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Stack, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { Update } from '@mui/icons-material';
import { Iconify } from 'components';
import {
  IProjectDetailResult,
  ProgressCalculationType,
  ProjectBillingType,
} from 'corede-common-cocrm';
import { getTranslatedEnumValue } from 'localization';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import moment from 'moment';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

interface IProjectDetailTabInfoSectionComponent
  extends DefaultTabComponentProps<IProjectDetailResult> {
  projectData: IProjectDetailResult | undefined;
  setOpenProjectUpdateDrawer: (value: boolean) => void;
  tabKey: 'info';
  name: 'crm.info';
}

export const ProjectDetailTabInfoSectionComponent = (
  props: IProjectDetailTabInfoSectionComponent,
) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const project = props.projectData;
  const { t } = useTranslation();

  const projectData = [
    {
      title: t('crm.project.project.startDate'),
      value: moment(project?.startDate).format() || '-',
      icon: 'ic:round-home-work',
    },
    {
      title: t('crm.project.project.deadline'),
      value: moment(project?.deadline).format() || '-',
      icon: 'iconoir:position',
    },
    {
      title: t('crm.project.project.estimatedHours'),
      value: project?.estimatedHours || '-',
      icon: 'mingcute:sector-line',
    },
    {
      title: t('crm.project.project.progressPercentage'),
      value: project?.progressPercentage + '%',
      icon: 'fluent-mdl2:website',
    },
    {
      title: t('crm.project.project.progressCalculationType'),
      value:
        getTranslatedEnumValue(project?.progressCalculationType as ProgressCalculationType) || '-',
      icon: 'mdi:calculator-variant',
    },
  ];

  const billingInfoData = [
    {
      title: t('crm.project.project.isBillable'),
      value: project?.isBillable ? t('yes') : t('no'),
      icon: 'clarity:map-marker-line',
    },
    {
      title: t('crm.project.project.billingType'),
      value: getTranslatedEnumValue(project?.billingType as ProjectBillingType) || '-',
      icon: 'solar:city-broken',
    },
    {
      title: t('crm.project.project.billingRatePerHour'),
      value: project?.billingRatePerHour || '-',
      icon: 'mage:location',
    },
  ];

  return (
    <Grid container mt={downMd ? 0 : 2} spacing={2.5} mb={2} px={{ xs: 0, lg: 2 }}>
      <Grid item xs={12} md={4}>
        <Stack
          direction={'column'}
          p={3}
          gap={1.5}
          sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
        >
          <Stack direction={'column'} width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant={'overline'} sx={{ fontWeight: 500 }}>
                {t('crm.about')}
              </Typography>

              <Button
                variant="contained"
                color="primary"
                sx={{
                  width: 'auto',
                  height: 32,
                  fontSize: { xs: '13px', md: '13px' },
                  lineHeight: 1,
                  borderRadius: '24px',
                }}
                onClick={() => props.setOpenProjectUpdateDrawer(true)}
              >
                <Update sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
                {t('crm.update')}
              </Button>
            </Stack>

            <Divider sx={{ mt: 1 }} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.name')}:
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {project?.name}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.description')}:
            </Typography>
            <Typography
              variant={'caption'}
              sx={{ fontWeight: 500, lineHeight: 1, color: 'primary.main' }}
            >
              {parse(project?.description ?? '')}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.status')}:
            </Typography>
            <Button
              sx={{
                padding: '4px 8px !important',
                height: 'auto',
                borderRadius: 0.5,
                backgroundColor: 'success.extraLight',
              }}
            >
              <Typography
                variant={'caption'}
                sx={{ fontWeight: 500, lineHeight: 1, color: 'success.main' }}
              >
                {getTranslatedEnumValue(project?.status ?? '')}
              </Typography>
            </Button>
          </Stack>

          <Stack direction={'column'} width={'100%'}>
            <Typography variant={'overline'} sx={{ fontWeight: 500 }}>
              {t('crm.project.project.customerInfoTitle')}
            </Typography>
            <Divider />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.name')}:
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {project?.customer?.name || '-'}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.contactEmail')}:
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {project?.customer?.email || '-'}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.project.project.contactPhoneNumber')}
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {project?.customer?.phone || '-'}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.address')}
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {[
                project?.customer?.address,
                project?.customer?.city,
                project?.customer?.state,
                project?.customer?.country,
              ]
                .filter(Boolean)
                .join(', ') || '-'}
            </Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack direction={'column'} py={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.company')}
          </Typography>
          <Grid container>
            {projectData.map((item, index) => (
              <Grid item xs={6} md={3} key={`project-data-${index}`}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>

        {/* Localization */}
        <Stack direction={'column'} pb={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.localization')}
          </Typography>
          <Grid container>
            {billingInfoData.map((item, index) => (
              <Grid item xs={6} md={3} key={`billing-info-${index}`}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
