/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  Stack,
  Theme,
  useMediaQuery,
  Typography,
  Button,
  Divider,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { listCustomerRoute } from '../../../../routes/customer.base.route';
import { useCustomerDetailQuery } from '../../context/customer.api';
import { CustomerDetailProfileSectionComponent } from './CustomerDetail-profileSection.component';
import {
  DocumentTargetType,
  EstimateProspectTargetEntityRef,
  NoteTargetType,
  PermissionSubject,
  ProposalProspectTargetEntityRef,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { MyTab } from 'components';
import EstimateListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/estimate/components/EstimateListTabComponent';
import ProposalListTabSectionComponent from 'apps/crm/domains/05-preSale/subdomains/proposal/components/ProposalListTabComponent';
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import { CustomerDetailTabInfoSectionComponent } from './CustomerDetail-tab-infoSection.component';
import CustomerUpdateDrawerWrapper from '../update/CustomerUpdateDrawer.wrapper';
import { DefaultDetailTabsComponent } from 'apps/crm/components/detail/DefaultDetail.tabs.component';
import { CustomerDetailTabContactInfoSectionComponentProps } from './CustomerDetail-tab-contactInfoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useCustomerDocumentAddManyMutation,
  useCustomerDocumentCreateManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { Iconify } from 'components';
import { OverlayType } from 'components/dialog/ActionDialog';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionAction } from 'corede-common';

/**
 * @property children - list of components of the tabs. Each component should have props extending `DetailDrawerTabComponentProps` interface
 */
export const CustomerDetail = memo(() => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) {
    navigate(listCustomerRoute());
  }

  const icons = [
    <Iconify icon="fluent:info-32-regular" width={18} />,
    <Iconify icon="hugeicons:contact" width={18} />,
    <Iconify icon="teenyicons:cost-estimate-outline" width={18} />,
    <Iconify icon="nimbus:money" width={18} />,
    <Iconify icon="iconoir:bell" width={18} />,
    <Iconify icon="fluent:document-32-regular" width={18} />,
    <Iconify icon="hugeicons:task-01" width={18} />,
    <Iconify icon="carbon:product" width={18} />,
    <Iconify icon="tabler:activity" width={18} />,
  ] as React.ReactElement[];

  const { hasPermission } = usePermissions();
  const hasEstimatePermission = hasPermission({
    subject: PermissionSubject.estimate,
    action: PermissionAction.list,
  });
  const hasProposalPermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.list,
  });
  const hasNotePermission = hasPermission({
    subject: PermissionSubject.note,
    action: PermissionAction.list,
  });
  const hasReminderPermission = hasPermission({
    subject: PermissionSubject.reminder,
    action: PermissionAction.list,
  });
  const hasTaskPermission = hasPermission({
    subject: PermissionSubject.task,
    action: PermissionAction.list,
  });
  const hasDocumentPermission = hasPermission({
    subject: PermissionSubject.file,
    action: PermissionAction.list,
  });

  // useStates
  const tabNames = new Map<string, string>([
    ['info', t('crm.info')],
    ['contactInformation', t('crm.contactInformation')],
    // ['activities', t('crm.activities')],
  ]);

  if (hasEstimatePermission) tabNames.set('estimates', t('crm.estimates'));
  if (hasProposalPermission) tabNames.set('proposals', t('crm.proposals'));
  if (hasNotePermission) tabNames.set('notes', t('crm.notes'));
  if (hasReminderPermission) tabNames.set('reminders', t('crm.reminders'));
  if (hasTaskPermission) tabNames.set('tasks', t('crm.tasks'));
  if (hasDocumentPermission) tabNames.set('documents', t('crm.documentLabel'));

  console.log(tabNames, "tabNames")

  const tabKeys = Array.from(tabNames.keys());
  console.log(tabKeys, "tabKeys")
  
  const tabValues = Array.from(tabNames.values());
  console.log(tabValues, "tabValues")

  const firstSelectedTab = tabNames.get(tabKeys.at(0) ?? '');

  // useStates
  const [selectedTabName, setSelectedTabName] = useState(firstSelectedTab);
  const [openCustomerUpdateDrawer, setOpenCustomerUpdateDrawer] = useState(false);

  // queries
  const { data: customerData } = useCustomerDetailQuery({
    input: {
      _id: id || '',
    },
  });

  return (
    <Grid item xs={12}>
      {!downMd && <HeaderBase title={t('crm.customer.customer.detail')} showBackButton={true} />}

      <Stack
        direction={'column'}
        mt={2}
        borderRadius={3}
        sx={{ boxShadow: downMd ? 'none' : '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
      >
        {!downMd && (
          <Box
            width={'100%'}
            sx={{
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
              height: '72px',
              bgcolor: 'secondary.extraLight',
            }}
          />
        )}

        <Stack direction={'column'} gap={1}>
          {!downMd && <CustomerDetailProfileSectionComponent customerData={customerData} />}

          {downMd && (
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Button
                sx={{
                  borderRadius: '50%',
                  width: 24,
                  height: 24,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '1px solid',
                  padding: 0,
                  minWidth: '24px',
                }}
                onClick={() => setSelectedTabName('')}
              >
                <Iconify icon={'iconamoon:arrow-left-2-light'} width={18} />
              </Button>

              <Typography variant="body1">
                {selectedTabName !== '' ? selectedTabName : t('crm.customer.customer.detail')}
              </Typography>
            </Stack>
          )}

          {selectedTabName === '' && (
            <MenuList>
              {tabValues.map((tabName, index) => (
                <MenuItem
                  sx={{
                    width: '100%',
                    borderRadius: '6px',
                    boxShadow: '0px 2px 5px 0px rgba(35, 32, 32, 0.1)',
                    border: '1px solid',
                    borderColor: 'divider',
                    boxSizing: 'border-box',
                    p: 1.5,
                    mb: 1,
                  }}
                  onClick={() => setSelectedTabName(tabName)}
                >
                  <ListItemIcon
                    sx={{
                      width: 32,
                      height: 32,
                      backgroundColor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mr: 1,
                    }}
                  >
                    {icons[index]}
                  </ListItemIcon>
                  <ListItemText>
                    {' '}
                    <Typography variant="body1">{tabName}</Typography>
                  </ListItemText>
                  <Iconify icon={'iconamoon:arrow-right-2-light'} width={18} />
                </MenuItem>
              ))}
            </MenuList>
          )}

          {!downMd && (
            <Stack width={'100%'} direction={'row'} justifyContent={'center'}>
              <MyTab
                labels={tabValues}
                setSelectedTab={setSelectedTabName}
                selectedTab={selectedTabName}
                icons={[
                  <Iconify icon="fluent:info-32-regular" width={18} />,
                  <Iconify icon="hugeicons:contact" width={18} />,
                  <Iconify icon="teenyicons:cost-estimate-outline" width={18} />,
                  <Iconify icon="nimbus:money" width={18} />,
                  <Iconify icon="carbon:product" width={18} />,
                  <Iconify icon="iconoir:bell" width={18} />,
                  <Iconify icon="hugeicons:task-01" width={18} />,
                  <Iconify icon="fluent:document-32-regular" width={18} />,
                  <Iconify icon="tabler:activity" width={18} />,
                ]}
              />
            </Stack>
          )}

          {downMd && <Divider />}
        </Stack>

        {selectedTabName !== '' && (
          <DefaultDetailTabsComponent
            tabNames={tabNames}
            selectedTabName={selectedTabName}
            entityData={customerData}
          >
            <CustomerDetailTabInfoSectionComponent
              customerData={customerData}
              setOpenCustomerUpdateDrawer={setOpenCustomerUpdateDrawer}
              tabKey="info"
              name="crm.info"
            />

            <CustomerDetailTabContactInfoSectionComponentProps
              customerData={customerData}
              tabKey="contactInformation"
              name="crm.contactInformation"
            />

            <EstimateListTabSectionComponent
              prospectTargetId={customerData?._id ?? ''}
              prospectTargetEntityRef={EstimateProspectTargetEntityRef.Customer}
              tabKey="estimates"
              name="crm.estimates"
              hasPermission={hasEstimatePermission}
            />

            <ProposalListTabSectionComponent
              prospectTargetId={customerData?._id ?? ''}
              prospectTargetEntityRef={ProposalProspectTargetEntityRef.Customer}
              tabKey="proposals"
              name="crm.proposals"
              hasPermission={hasProposalPermission}
            />

            <NoteListTabComponent
              targetId={customerData?._id ?? ''}
              targetEntityRef={NoteTargetType.Customer}
              targetDisplayName={customerData?.name ?? ''}
              tabKey="notes"
              name="crm.notes"
              hasPermission={hasNotePermission}
            />

            <ReminderListTabComponent
              targetId={customerData?._id ?? ''}
              targetEntityRef={ReminderTargetType.Customer}
              targetDisplayName={customerData?.name ?? ''}
              tabKey="reminders"
              name="crm.reminders"
              overlayType={OverlayType.drawer}
              hasPermission={hasReminderPermission}
            />

            <TaskListTabComponent
              targetId={customerData?._id ?? ''}
              targetEntityRef={TaskTargetType.Customer}
              tabKey={'tasks'}
              name={'crm.tasks'}
              overlayType={OverlayType.drawer}
              hasPermission={hasTaskPermission}
            />

            <DocumentListTabComponent
              targetId={customerData?._id ?? ''}
              targetEntityRef={DocumentTargetType.Customer}
              tabKey={'documents'}
              name={'crm.documents'}
              useDocumentCreateManyMutation={useCustomerDocumentCreateManyMutation}
              useDocumentAddManyMutation={useCustomerDocumentAddManyMutation}
              hasPermission={hasDocumentPermission}
            />

            {/* <DefaultEmptyTabComponent
              title={t('crm.activities')}
              emptyStateContent={t('crm.noActivity')}
              tabKey="activities"
              nameTranslation={t('crm.activities')}
            /> */}
          </DefaultDetailTabsComponent>
        )}

        <Overlays
          openCustomerUpdateDrawer={openCustomerUpdateDrawer}
          setOpenCustomerUpdateDrawer={setOpenCustomerUpdateDrawer}
          customerData={customerData}
        />
      </Stack>
    </Grid>
  );
});

const Overlays = ({ openCustomerUpdateDrawer, setOpenCustomerUpdateDrawer, customerData }: any) => {
  return (
    <>
      <CustomerUpdateDrawerWrapper
        open={openCustomerUpdateDrawer}
        setOpen={setOpenCustomerUpdateDrawer}
        selectedCustomer={customerData}
      />
    </>
  );
};
