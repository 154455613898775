/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { IProposalDetailResult } from 'corede-common-cocrm';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { ProposalDetailDrawerLeftPanelHeaderComponent } from './ProposalDetailDrawer-leftPanel-header.component';
import { ProposalDetailDrawerLeftPanelContactDetailComponent } from './ProposalDetailDrawer-leftPanel-contactDetail.component';
import { ProposalDetailDrawerLeftPanelAssignedsComponent } from './ProposalDetailDrawer-leftPanel-assigned.component';

export interface IProposalDetailDrawerLeftPanelComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProposalDetailDrawerLeftPanelComponent = (
  props: IProposalDetailDrawerLeftPanelComponentProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  return (
    <DefaultDetailDrawerLeftPanelComponent<IProposalDetailResult>
      entityData={props.proposalDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <ProposalDetailDrawerLeftPanelHeaderComponent
        proposalDetailData={props.proposalDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <ProposalDetailDrawerLeftPanelContactDetailComponent
        proposalDetailData={props.proposalDetailData}
        setOpen={props.setOpen}
      />

      {/* Assigned List */}
      <ProposalDetailDrawerLeftPanelAssignedsComponent
        proposalDetailData={props.proposalDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
      <Divider />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
