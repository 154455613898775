/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Stack,
  Box,
  TextField,
  Button,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useRoleCreateMutation } from '../../context/roles.api';
import { AddRounded } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import { IBaseError } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { PermissionList } from '../../../permission';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useNavigate } from 'react-router-dom';
import { listRolesRoute } from 'apps/crm/domains/02-organizationalChart/routes/organizationalChart.base.route';

export const RoleCreate = (props: {
  hideChips?: boolean;
  setOpenDialog?: (open: boolean) => void;
}) => {
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const [roleName, setRoleName] = useState<string>('');
  const [rolePermissionIds, setRolePermissionIds] = useState<string[]>([]);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);

  const [
    roleCreate,
    { data: roleCreateData, isLoading: roleCreateLoading, error: roleCreateError },
  ] = useRoleCreateMutation();

  const handleAddRole = () => {
    roleCreate({
      input: {
        name: roleName,
        permissionIds: selectedPermissionIds,
      },
    });
  };

  // SUCCESS

  useEffect(() => {
    if (roleCreateData) {
      setRoleName('');
      setSelectedPermissionIds([]);
      setRolePermissionIds([]);
      enqueueSnackbar(t('crm.organizationalChart.role.roleCreatedSuccess'), {
        variant: 'success',
      });
      !props.hideChips && navigate(listRolesRoute());
      props.setOpenDialog && props.setOpenDialog(false);
    }
  }, [roleCreateData]);

  // ERRORS

  useEffect(() => {
    DefaultErrorHandlerUseEffect(roleCreateError as IBaseError, currentLanguage);
  }, [roleCreateError, currentLanguage]);

  return (
    <Grid item xs={12} p={{ xs: 0, md: 3 }}>
      {!downMd && (
        <HeaderBase title={t('crm.organizationalChart.role.createRole')} showBackButton={true} />
      )}
      <Box sx={{ mt: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{ width: '100%' }}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <TextField
              required
              size="small"
              placeholder={t('crm.organizationalChart.role.roleName')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={roleName}
              onChange={(e) =>
                setRoleName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
              }
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ height: 42, mt: '16px', mb: '8px', pl: 1, pr: 2 }}
              onClick={handleAddRole}
              disabled={roleCreateLoading || !roleName || selectedPermissionIds?.length === 0}
            >
              {roleCreateLoading ? (
                <CircularProgress size={'1rem'} />
              ) : (
                <>
                  <AddRounded sx={{ mr: 1 }} />
                  {t('crm.organizationalChart.role.addRole')}
                </>
              )}
            </Button>
          </Stack>

          <PermissionList
            existingPermissionIds={rolePermissionIds}
            setSelectedPermissionIds={setSelectedPermissionIds}
            hideChips={props.hideChips}
          />
        </Box>
      </Stack>
    </Grid>
  );
};
