/* eslint-disable react-hooks/exhaustive-deps */
import { IContractDetailResult } from 'corede-common-cocrm';
import { ContractDetailDrawerHeaderStatusComponent } from './ContractDetailDrawer-rightPanel-header-status.component';
// import { ContractDetailDrawerBaseInfoComponent } from './ContractDetailDrawer-rightPanel-header-baseInfo.component';

export interface IContractDetailDrawerRightPanelHeaderComponentProps {
  contractDetailData: IContractDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ContractDetailDrawerRightPanelHeaderComponent = (
  props: IContractDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <ContractDetailDrawerHeaderStatusComponent contractDetailData={props.contractDetailData} />
      {/* <ContractDetailDrawerBaseInfoComponent contractDetailData={props.contractDetailData} /> */}
    </>
  );
};
