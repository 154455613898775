const locale = {
  permissions: 'Permissions',
  roles: 'Roles',
  createRole: 'Create Role',
  rolesInfo: 'You can create roles and assign permissions to them.',
  roleName: 'Role Name',
  roleDetail: 'Role Detail',
  addRole: 'Add',
  updateRole: 'Update',
  deleteRole: 'Delete',
  roleCreatedSuccess: 'Role created successfully',
  roleUpdatedSuccess: 'Role updated successfully',
  roleDeletedSuccess: 'Role deleted successfully',
  errorFetchingRoles: 'Error fetching roles',
  errorCreatingRole: 'Error creating role',
  errorUpdatingRole: 'Error updating role',
  errorDeletingRole: 'Error deleting role',
  capabilities: 'Capabilities',
  advancedOptions: 'Advanced Options',
  addNewRole: 'Add New Role',
  rolesAndPermissions: 'Roles & Permissions',
  action: 'Action',
  actionScope: 'Action Scope',
  deleteConfirm: 'Are you sure you want to remove the role ',
  deletedSuccessfully: "Role deleted successfully",
};

export default locale;
