import { useState, lazy, Suspense } from 'react';


const ContactCreateDrawer = lazy(() => import('./ContactCreateDrawer'));


const ContactCreateWrapper = ({
    open,
    setOpen,
    customerId,
}: any) => {

    const [mounted, setMounted] = useState(false);

    if (open && !mounted) {
        setMounted(true);
    }

    return (
        <>
            {mounted && (
                <Suspense>
                    < ContactCreateDrawer
                        open={open}
                        setOpen={setOpen}
                        customerId={customerId}
                    />
                </Suspense>
            )}
        </>
    );
};

export default ContactCreateWrapper;