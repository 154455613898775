import { ExpandMore } from '@mui/icons-material';
import { INoteListItemResult } from 'corede-common-cocrm';
import {
  Grid,
  Stack,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Theme,
} from '@mui/material';
import { Iconify } from 'components';
import moment from 'moment';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

interface INoteListItemProps {
  note: INoteListItemResult;
}

const NoteListItemComponent = (props: INoteListItemProps) => {
  const { t } = useTranslation();
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        boxShadow: 'none',
        borderRadius: '24px !important',
        border: '1px solid',
        borderColor: 'grey.300',
        padding: '0',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          alignItems: 'center',
          flexDirection: 'row-reverse',
        }}
      >
        <Grid container pl={1.5} sx={{ width: '100%' }} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack direction={'row'} alignItems={'center'} gap={0.5}>
              <Box
                sx={{
                  width: 22,
                  height: 22,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="tabler:activity" width={14} sx={{ color: 'secondary.main' }} />
              </Box>

              <Typography variant={'subtitle2'}>
                {/* <span style={{ color: 'secondary.main' }}>{t('crm.title')} </span>{' '} */}
                <strong>{props.note.title}</strong>
              </Typography>
            </Stack>
          </Grid>
          {!downSm && (
            <Grid item xs={6} justifyItems={'flex-end'}>
              <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                <Iconify icon="solar:calendar-linear" width={18} sx={{ color: 'text.secondary' }} />

                <Typography
                  variant={'subtitle2'}
                  sx={{ fontWeight: '500', color: 'text.secondary' }}
                >
                  {moment(props.note.createdAt).format('DD/MM/YYYY')}
                </Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ pl: 5.5 }}>
        <Grid container pl={1.5} sx={{ width: '100%' }} spacing={2}>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Stack>
                <Typography variant={'subtitle2'} sx={{ color: 'text.secondary' }}>
                  {parse(props.note.content ?? '')}
                </Typography>
              </Stack>

              {downSm && (
                <Stack direction={'row'} alignItems={'center'} gap={0.5} sx={{ ml: 'auto' }}>
                  <Iconify
                    icon="solar:calendar-linear"
                    width={18}
                    sx={{ color: 'text.secondary' }}
                  />

                  <Typography
                    variant={'subtitle2'}
                    sx={{ fontWeight: '500', color: 'text.secondary' }}
                  >
                    {moment(props.note.createdAt).format('DD/MM/YYYY')}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default NoteListItemComponent;
