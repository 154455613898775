import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DataState {
  items: any[];
}

const initialState: DataState = {
  items: [],
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any[]>) => {
      state.items = action.payload;
    },
    clearData: (state) => {
      state.items = []; 
    },
  },
});

export const { setData, clearData } = dataSlice.actions;
export default dataSlice.reducer;
