import { Add, AddCircle } from '@mui/icons-material';
import EmptyState from 'components/emptyState/EmptyState';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { IBaseError, IEntity, isNullOrUndefinedOrEmpty, PermissionAction } from 'corede-common';
import FilesGridList from '../pages/list/FilesGridList';
import { UploadDocumentComponent } from 'components/uploadFile/UploadDocument';
import { useFileListQuery } from '../context/document.api';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import DetailBox from 'components/box/DetailBox';
import { Stack, Typography, Box, Button } from '@mui/material';
import { Iconify } from 'components';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import FileUpload from './FileUpload';
import { DocumentTargetType, PermissionSubject } from 'corede-common-cocrm';

export interface IDocumentListTabSectionComponent extends DefaultTabComponentProps<IEntity> {
  targetId: string;
  targetEntityRef: DocumentTargetType;
  tabKey: 'documents';
  name: 'crm.documents';
  useDocumentCreateManyMutation: any;
  useDocumentAddManyMutation: any;
  disableShow?: boolean;
  smallSize?: boolean;
}

const DocumentListTabComponent = (props: IDocumentListTabSectionComponent) => {
  const [openDocumentCreateDialog, setOpenDocumentCreateDialog] = useState(false);

  const { data: fileListData, error: fileListError } = useFileListQuery(
    {
      input: {
        filter: {
          entityId: props.targetId,
          entityModels: [props.targetEntityRef],
        },
      },
    },
    {
      skip: isNullOrUndefinedOrEmpty(props.targetId),
    },
  );

  useEffect(() => {
    if (fileListError) {
      DefaultErrorHandlerUseEffect(fileListError as IBaseError, getCurrentLanguage());
    }
  }, [fileListError]);

  return (
    <DefaultTabContainer
      title={t('')}
      rightButton={
        fileListData && fileListData?.count > 0
          ? {
              title: t('crm.create'),
              onClick: () => setOpenDocumentCreateDialog(true),
              icon: <AddCircle sx={{ mr: 1 }} />,
              permission: {
                subject: PermissionSubject.file,
                action: PermissionAction.create,
              },
            }
          : undefined
      }
    >
      <Stack direction={'column'} gap={2} sx={{ width: '100%' }}>
        {/* <FileUpload openUploadFilePopup={setOpenDocumentCreateDialog} /> */}

        <Stack
          width={'100%'}
          sx={{
            p: 3,
            // borderRadius: 3,
            // border: '1px solid',
            // borderColor: 'grey.300',
            boxSizing: 'border-box',
          }}
        >
          {fileListData ? (
            fileListData?.count === 0 ? (
              <EmptyState
                icon={
                  <img
                    src={DocumentEmptyIcon}
                    style={{
                      width: '180px',
                      height: '180px',
                      marginBottom: '30px',
                    }}
                    alt="There is no document yet."
                  />
                }
                content1={t('disk.document.document.emptyStateContent1')}
                // content2={t('disk.document.document.emptyStateContent2')}
                button1={{
                  leftIcon: <AddCircle sx={{ mr: 1 }} fontSize="small" />,
                  title: t('crm.create'),
                  onClick: () => setOpenDocumentCreateDialog(true),
                  permission: {
                    subject: PermissionSubject.file,
                    action: PermissionAction.create,
                  },
                }}
              />
            ) : (
              <FilesGridList
                fileData={fileListData}
                disableShow={props.disableShow}
                smallSize={props.smallSize}
              />
            )
          ) : null}
        </Stack>
      </Stack>

      <UploadDocumentComponent
        entityId={props.targetId ?? ''}
        open={openDocumentCreateDialog}
        setOpen={setOpenDocumentCreateDialog}
        useDocumentCreateManyMutation={props.useDocumentCreateManyMutation}
        useDocumentAddManyMutation={props.useDocumentAddManyMutation}
      />
    </DefaultTabContainer>
  );
};

export default DocumentListTabComponent;
