import {
  leadFormListQuery,
  ILeadFormListRequest,
  ILeadFormListInput,
  ILeadFormListResponse,
  ILeadFormListResult,
  leadFormDetailQuery,
  ILeadFormDetailRequest,
  ILeadFormDetailInput,
  ILeadFormDetailResponse,
  ILeadFormDetailResult,
  leadFormUrl,
  ILeadFormUrlRequest,
  ILeadFormUrlResponse,
  ILeadFormUrlInput,
  ILeadFormUrlResult,

  leadFormCreateQuery,
  ILeadFormCreateRequest,
  ILeadFormCreateInput,
  ILeadFormCreateResponse,
  ILeadFormCreateResult,
  leadFormUpdateQuery,
  ILeadFormUpdateRequest,
  ILeadFormUpdateInput,
  ILeadFormUpdateResponse,
  ILeadFormUpdateResult,
  ILeadFormUpdateFilterInput,
  leadFormDeleteQuery,
  ILeadFormDeleteRequest,
  ILeadFormDeleteInput,
  ILeadFormDeleteResponse,
  ILeadFormDeleteResult,
  leadCreateFromLeadFormPublicQuery,
  ILeadCreateFromLeadFormPublicRequest,
  ILeadCreateFromLeadFormPublicInput,
  ILeadCreateFromLeadFormPublicResponse,
  ILeadCreateFromLeadFormPublicResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { leadFormTags } from './leadForm.tags';
import { IGraphqlVariables } from 'corede-common';

export const leadFormsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    leadFormList: builder.query<ILeadFormListResult, IGraphqlVariables<ILeadFormListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadFormListRequest,
        ILeadFormListResponse,
        ILeadFormListResult,
        ILeadFormListInput
      >({
        query: leadFormListQuery,
        providesTags: [leadFormTags.leadForms],
      }),
    ),

    leadFormDetail: builder.query<ILeadFormDetailResult, IGraphqlVariables<ILeadFormDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadFormDetailRequest,
        ILeadFormDetailResponse,
        ILeadFormDetailResult,
        ILeadFormDetailInput
      >({
        query: leadFormDetailQuery,
        providesTags: [leadFormTags.leadForm],
      }),
    ),

    leadFormUrl: builder.query<ILeadFormUrlResult, IGraphqlVariables<ILeadFormUrlInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadFormUrlRequest,
        ILeadFormUrlResponse,
        ILeadFormUrlResult,
        ILeadFormUrlInput
      >({
        query: leadFormUrl,
      }),
    ),

    
    

    // mutation

    leadFormCreate: builder.mutation<
      ILeadFormCreateResult,
      IGraphqlVariables<ILeadFormCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadFormCreateRequest,
        ILeadFormCreateResponse,
        ILeadFormCreateResult,
        ILeadFormCreateInput
      >({
        query: leadFormCreateQuery,
        invalidatesTags: [leadFormTags.leadForms],
      }),
    ),

    leadFormUpdate: builder.mutation<
      ILeadFormUpdateResult,
      IGraphqlVariables<ILeadFormUpdateInput, ILeadFormUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadFormUpdateRequest,
        ILeadFormUpdateResponse,
        ILeadFormUpdateResult,
        ILeadFormUpdateInput,
        ILeadFormUpdateFilterInput
      >({
        query: leadFormUpdateQuery,
        invalidatesTags: [leadFormTags.leadForm, leadFormTags.leadForms],
      }),
    ),

    leadFormDelete: builder.mutation<
      ILeadFormDeleteResult,
      IGraphqlVariables<ILeadFormDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadFormDeleteRequest,
        ILeadFormDeleteResponse,
        ILeadFormDeleteResult,
        ILeadFormDeleteInput
      >({
        query: leadFormDeleteQuery,
        invalidatesTags: [leadFormTags.leadForm, leadFormTags.leadForms],
      }),
    ),

    leadCreateFromLeadFormPublic: builder.mutation<
      ILeadCreateFromLeadFormPublicResult,
      IGraphqlVariables<ILeadCreateFromLeadFormPublicInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadCreateFromLeadFormPublicRequest,
        ILeadCreateFromLeadFormPublicResponse,
        ILeadCreateFromLeadFormPublicResult,
        ILeadCreateFromLeadFormPublicInput
      >({
        query: leadCreateFromLeadFormPublicQuery,
      }),
    ),
  }),
});

export const {
  useLeadFormListQuery,
  useLeadFormDetailQuery,
  useLeadFormUrlQuery,
  useLazyLeadFormUrlQuery,
  useLeadFormCreateMutation,
  useLeadFormUpdateMutation,
  useLeadFormDeleteMutation,
  useLeadCreateFromLeadFormPublicMutation,
} = leadFormsApi;
