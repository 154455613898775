import dashboardLocale from '../domains/01-dashboard/locales/en';
import productAndServiceLocale from '../domains/08-productAndService/locales/en';
import organizationalChartLocale from '../domains/02-organizationalChart/locales/en';
import leadLocale from '../domains/03-lead/locales/en';
import customerLocale from '../domains/04-customer/locales/en';
import preSaleLocale from '../domains/05-preSale/locales/en';
import saleLocale from '../domains/06-sale/locales/en';
import pricingLocale from '../domains/16-pricing/locales/en';
import calendarLocale from '../domains/12-calendar/locales/en';
import contractLocale from '../domains/07-contract/locales/en';
import supportLocale from '../domains/15-support/locales/en';
import reportLocale from '../domains/13-report/locales/en';
import taskLocale from '../domains/11-task/locales/en';
import projectLocale from '../domains/10-project/locales/en';
import expenseLocale from '../domains/09-expense/locales/en';
import noteLocale from '../domains/14-note/locales/en';

const locale = {
  lead: {
    ...leadLocale,
  },
  dashboard: {
    ...dashboardLocale,
  },
  organizationalChart: {
    ...organizationalChartLocale,
  },
  productAndService: {
    ...productAndServiceLocale,
  },
  customer: {
    ...customerLocale,
  },
  preSale: {
    ...preSaleLocale,
  },
  sale: {
    ...saleLocale,
  },
  pricing: {
    ...pricingLocale,
  },
  calendar: {
    ...calendarLocale,
  },
  contract: {
    ...contractLocale,
  },
  support: {
    ...supportLocale,
  },
  report: {
    ...reportLocale,
  },
  task: {
    ...taskLocale,
  },
  project: {
    ...projectLocale,
  },
  expense: {
    ...expenseLocale,
  },
  note: {
    ...noteLocale,
  },

  ////////////////////////////////////////////////////////////////
  // common used names
  ////////////////////////////////////////////////////////////////
  preSaleLabel: 'Pre-Sale',
  preSales: 'Pre-Sales',
  contactDetails: 'Details',
  localization: 'Localization',
  info: 'Info',
  about: 'About',
  contact: 'Contact',
  record: 'Record',
  records: 'Records',
  tracking: 'Tracking',
  pricingLabel: 'Pricing',
  dashboardLabel: 'Dashboard',
  marketing: 'Marketing',
  campaigns: 'Campaigns',
  emails: 'Emails',
  sms: 'SMS',
  socialMedia: 'Social Media',
  salesLabel: 'Sales',
  businessIntelligence: 'Business Intelligence',
  calendarLabel: 'Calendar',
  supportLabel: 'Ticket',
  accountMenu: 'Account Menu',
  profile: 'Profile',
  myAccount: 'My Account',
  logout: 'Logout',
  searchPlaceholder: 'Try searching "insights"',
  searchAnIcon: 'Search Icon',

  // --------------------
  // settings related
  // --------------------
  settings: 'Settings',
  english: 'English',
  turkish: 'Turkish',
  darkMode: 'Dark Mode',
  lightMode: 'Light Mode',
  languageSelectLabel: 'Select Language',

  ////////////////////////////////////////////////////////////////
  // common action texts
  ////////////////////////////////////////////////////////////////
  actions: 'Actions',
  yes: 'Yes',
  no: 'No',
  importExport: 'Import/Export',
  view: 'View',
  list: 'List',
  create: 'Create',
  update: 'Update',
  delete: 'Delete',
  edit: 'Edit',
  cancel: 'Cancel',
  downloadSuccessful: 'Successfully downloaded',
  export: 'Export',
  import: 'Import',
  exportData: 'Export Data',
  importData: 'Import Data',
  showMore: 'Show More',
  justForMe: 'Just For Me',

  ////////////////////////////////////////////////////////////////
  // common situational texts
  ////////////////////////////////////////////////////////////////
  total: 'Total',
  weeklyTotal: 'Weekly Total',
  hasCreatedAt: 'has created at ',
  createdBy: 'Created by ',
  updatedBy: 'Updated by ',
  uploadLogo: 'Upload Logo',
  selectIconTitle: 'Select an Icon',
  searchIcons: 'Search Icons',
  uploadPictureText: 'Upload a picture',
  searchForAnIcon: 'Search for an Icon',
  selectedIcon: 'Selected Icon:',

  // --------------------
  // delete dialog
  // --------------------
  deletedEntitySuccessfully: 'Deleted successfully',
  deleteEntityContent: 'Are you sure you want to delete. This action cannot be undone.',
  deleteEntityTitle: 'Delete',

  // --------------------
  // organization
  // --------------------
  mustCreateOrganizationTitle: 'Create Organization',
  mustCreateOrganizationContent: 'You must create an organization to use the panel.',
  mustCreateOrganizationSuccess: 'Organization created successfully.',

  // --------------------
  // import export
  // --------------------
  importedError: 'Imported error',
  importedSuccessfully: 'Imported successfully',
  downloadExample: 'Download Example',
  downloadFormError: 'Download form error',
  downloadFormSuccessfully: 'Download form successfully',
  downloadExampleFormSuccessfully: 'Download example form successfully',
  downloadExampleFormError: 'Download example form error',

  // --------------------
  // estimate
  // --------------------
  addEstimate: 'Add Estimate',
  noEstimate: 'There are no estimates',
  noEstimateContent1: 'Estimates not found',
  additionalInfo: 'Additional Info',

  // --------------------
  // proposal
  // --------------------
  addProposal: 'Add Proposal',
  noProposal: 'There are no proposals',
  lastProposal: 'Last Proposal',
  proposalSent: 'Proposal Sent',
  noProposalSent: 'There are no proposals sent',
  noProposalContent1: 'Proposals not found',
  sendEmailOnExpiration: 'Send email on expiration',
  testimonialCaseStudies: 'Testimonial Case Studies',
  projectTimeline: 'Project Timeline',
  executiveSummary: 'Executive Summary',
  costBreakdown: 'Cost Breakdown',
  conclusion: 'Conclusion',
  projectorServiceDesc: 'Detailed Project/Service Description',
  noSalentAgents: 'There are no sales agents',

  // --------------------
  // document
  // --------------------
  addDocument: 'Add Document',
  noDocument: 'There are no documents',
  noDocumentContent1: 'Documents not found',

  // --------------------
  // task
  // --------------------
  noTask: 'There are no tasks',
  noTaskContent1: 'Tasks not found',

  // --------------------
  // activity
  // --------------------
  noActivity: 'There are no activities',
  addActivity: 'Add Activity',
  noActivityContent1: 'Activities not found',
  noActivityContent2: 'There are no activities for this entity',

  // --------------------
  // note
  // --------------------
  addNote: 'Add Note',
  noNote: 'There are no notes',
  emptyStateNoteList1: 'Notes not found',
  noteDate: 'Note Date',
  noteUsers: 'Note Users',
  noteTitle: 'Title',

  // --------------------
  // reminder
  // --------------------
  addReminder: 'Add Reminder',
  noReminder: 'There are no reminders',
  emptyStateReminderList1: 'Reminders not found',
  remindDate: 'Reminder Date',
  remindUsers: 'Remind Users',
  remindTitle: 'Title',

  // --------------------
  // appointment
  // --------------------
  noAppointments: 'There are no appointments',
  makeAnAppointment: 'Make an Appointment',

  // --------------------
  // assignee
  // --------------------
  assignees: 'Assignees',
  noAssignees: 'No Assignees',
  addAssignee: 'Add Assignee',

  // --------------------
  // followers
  // --------------------
  followers: 'Followers',
  noFollowers: 'No Followers',

  // --------------------
  // product and services
  // --------------------
  addProduct: 'Add Product',
  addProductOrService: 'Add Product or Service',
  editProdutctOrService: 'Edit Product or Service',
  productType: 'Product Type',
  editProduct: 'Edit Product',
  product: 'Product',

  // --------------------
  // ticket
  // --------------------
  subject: 'Subject',
  contactName: 'Contact Name',
  tickets: 'Tickets',

  ////////////////////////////////////////////////////////////////
  // common used words
  ////////////////////////////////////////////////////////////////
  of: 'of',

  ////////////////////////////////////////////////////////////////
  // common used entity names
  ////////////////////////////////////////////////////////////////
  leadLabel: 'Lead',
  leads: 'Leads',
  leadForms: 'Lead Forms',
  leadStatuses: 'Lead Statuses',
  leadSources: 'Lead Sources',
  customerLabel: 'Customer',
  customers: 'Customers',
  estimateLabel: 'Estimate',
  estimates: 'Estimates',
  proposalLabel: 'Proposal',
  proposals: 'Proposals',
  invoiceLabel: 'Invoice',
  invoices: 'Invoices',
  productsServices: 'Products & Services',
  activityLabel: 'Activity',
  activities: 'Activities',
  noteLabel: 'Note',
  notes: 'Notes',
  documentLabel: 'Document',
  documents: 'Documents',
  appointmentLabel: 'Appointment',
  appointments: 'Appointments',
  reminderLabel: 'Reminders',
  reminders: 'Reminders',
  departmentLabel: 'Department',
  departments: 'Departments',
  contractLabel: 'Contract',
  contractTemplate: 'Contract Template',
  contracts: 'Contracts',
  paymentLabel: 'Payment',
  payments: 'Payments',
  organizationalChartLabel: 'Organizational Chart',
  expenseLabel: 'Expense',
  expenses: 'Expenses',
  productAndServiceLabel: 'Products & Services',
  projectLabel: 'Project',
  projects: 'Projects',
  taskLabel: 'Task',
  tasks: 'Tasks',
  reportLabel: 'Report',
  reports: 'Reports',
  billingInformation: 'Billing Information',
  billingInfo: 'Billing Info',
  otherBillingInformation: 'Other Billing Information',
  shippingInfo: 'Shipping Info',
  contactInformation: 'Contact Information',
  otherContactInformation: 'Other Contact Information',
  warningBillingInfo: 'Once one field is filled, all fields must be completed.',
  addRole: 'Add Role',

  ////////////////////////////////////////////////////////////////
  // common fields
  ////////////////////////////////////////////////////////////////
  color: 'Color',
  icon: 'Icon',
  index: 'Index',
  title: 'Title',
  fullName: 'Full Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  surname: 'Surname',
  convert: 'Convert',
  country: 'Country',
  state: 'State',
  city: 'City',
  industry: 'Industry',
  address: 'Address',
  website: 'Website',
  emailAddress: 'Email Address',
  name: 'Name',
  companyName: 'Company Name',
  companyEmail: 'Company Email',
  companyPhone: 'Company Phone',
  timezone: 'Timezone',
  companyLogo: 'Company Logo',
  language: 'Language',
  department: 'Department',
  email: 'Email',
  phoneNumber: 'Phone Number',
  type: 'Type',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  prefix: 'Prefix',
  tags: 'Tags',
  tagsPlaceholder: 'Tags',
  addTagsPlaceholder: 'Add Tags',
  addNewOption: 'Add new option',
  enterText: 'Enter text...',
  company: 'Company',
  sector: 'Sector',
  phone: 'Phone',
  content: 'Content',
  priority: 'Priority',
  position: 'Position',
  status: 'Status',
  source: 'Source',
  date: 'Date',
  expireDate: 'Expire Date',
  totalPrice: 'Total Price',
  currency: 'Currency',
  category: 'Category',
  parentCategory: 'Parent Category',
  vat: 'Vat Number',
  zip: 'Zip',
  street: 'Street',
  taxOffice: 'Tax Office',
  target: 'Target',
  targetType: 'Target Type',
  description: 'Description',
  birthDate: 'Birthdate',
  gender: 'Gender',
  isPrimary: 'Is Primary',
  prospectName: 'Prospect Name',
  prospectAddress: 'Prospect Address',
  prospectContactName: 'Prospect Contact Name',
  prospectType: 'Prospect Type',
  leadValue: 'Lead Value',
  estimateId: 'Estimate Id',
  proposalId: 'Proposal Id',
  invoiceId: 'Invoice Id',
  paymentId: 'Payment Id',
  returnId: 'Return Id',
  expenseId: 'Expense Id',
  customerName: 'Customer Name',
  customerEmail: 'Customer Email',
  customerType: 'Customer Type',
  finalPrice: 'Final Price',
  paymentMethod: 'Payment Method',
  confirmationNumber: 'Confirmation Number',
  amoundPaid: 'Amount Paid',
  refundAmount: 'Refund Amount',
  isOpen: 'Is Open',
  resolveReason: 'Resolve Reason',
  disapproveReason: 'Disapprove Reason',
  sku: 'Sku',
  priceType: 'Price Type',
  price: 'Price',
  discountedPrice: 'Discounted Price',
  discount: 'Discount',
  unit: 'Unit',
  unitPrice: 'Unit Price',
  natTaxRate: 'National Tax Rate',
  intTaxRate: 'International Tax Rate',
  selectRoles: 'Select Roles',
  timeZone: 'Time Zone',
  prospect: 'Prospect',
  prospectTargetEntityRef: 'Prospect Target Entity Ref',
  prospectTarget: 'Prospect Target',
  prospectVAT: 'Prospect VAT',
  prospectPhoneNumber: 'Prospect Phone Number',
  prospectZIP: 'Prospect ZIP',
  contactFirstName: 'Contact First Name',
  contactLastName: 'Contact Last Name',
  contactEmail: 'Contact Email',
  expiration: 'Expiration',
  detailedInformation: 'Detailed Information',
  projectOrServiceDesc: 'Project/Service Description',
  scopeOfWork: 'Scope of Work',
  assumptionsAndExclusions: 'Assumptions and Exclusions',
  paymentTerms: 'Payment Terms',
  products: 'Products',
  services: 'Services',
  productName: 'Product Name',
  productDescription: 'Product Description',
  productUnit: 'Product Unit',
  quantity: 'Quantity',
  tax: 'Tax',
  extraInformation: 'Extra Information',
  offers: 'Offers',
  reference: 'Reference',
  allowComments: 'Allow Comments',
  totally: 'Totally',
  subTotal: 'Sub Total',
  taxTotal: 'Tax Total',
  extraDiscount: 'Extra Discount',
  shippingTotal: 'Total Shipping Cost',
  salesAgents: 'Sales Agents',
  legal: 'Legal',
  useLegalNDA: 'Use Legal NDA',
  useLegalTermAndCond: 'Use Legal Term And Cond',
  signature: 'Signature',
  signerUser: 'Signer User',
  signedDate: 'Signed Date',
  generalInfo: 'General Info',
  startDate: 'Start Date',
  dueDate: 'Due Date',
  endDate: 'End Date',
  expirationEmailSentDate: 'Expiration Email Sent Date',
  allowedPaymentMethods: 'Allowed Payment Methods',
  sendEmail: 'Send Email',
  identityNumber: 'Identity Number',
  recurringCount: 'Recurring Count',
  isRecurring: 'Is Recurring',
  recurringFrequency: 'Recurring Frequency',
  recurringDueDate: 'Recurring Due Date',
  paymentID: 'Payment ID',
  paymentPrefix: 'Payment Prefix',
  returnPrefix: 'Return Prefix',
  paymentDate: 'Payment Date',
  amountPaid: 'Amount Paid',
  invoiceID: 'Invoice ID',
  targetDetail: 'Target Detail',
  productsAndServices: 'Products & Services',
  popularSearch: 'Popular Search',
  returnReason: 'Return Reason',
  partialReturn: 'Partial Return',
  amount: 'Amount',
  relationship: 'Relationship',
  relationshipType: 'Relationship Type',
  relatedEntity: 'Related Entity',
  relatedEstimate: 'Related Estimate',
  relatedProposal: 'Related Proposal',
  relatedTask: 'Related Task',
  relatedProject: 'Related Project',
  relatedLead: 'Related Lead',
  relatedCustomer: 'Related Customer',
  relatedInvoice: 'Related Invoice',
  relatedPayment: 'Related Payment',
  relatedUser: 'Related User',
  relatedExpense: 'Related Expense',
  relatedContract: 'Related Contract',
  order: 'Order',

  ////////////////////////////////////////////////////////////////
  // SEARCH
  ////////////////////////////////////////////////////////////////
  leadCreate: 'Create Lead',
  leadList: 'List Lead',
  leadImport: 'Import Leads',
  customerCreate: 'Create Customer',
  customerList: 'List Customer',
  customerImport: 'Import Customers',
  estimateCreate: 'Create Estimate',
  estimateList: 'List Estimate',
  proposalCreate: 'Create Proposal',
  proposalList: 'List Proposal',
  invoiceCreate: 'Create Invoice',
  invoiceList: 'List Invoice',
  paymentCreate: 'Create Payment',
  paymentList: 'List Payment',
  returnCreate: 'Create Return',
  returnList: 'List Return',
  contractCreate: 'Create Contract',
  contractList: 'List Contract',
  productCreate: 'Create Product',
  productList: 'List Product',
  serviceList: 'List Service',
  serviceCreate: 'Create Service',
  expenseCreate: 'Create Expense',
  expenseList: 'List Expense',
  projectCreate: 'Create Project',
  projectList: 'List Project',
  taskCreate: 'Create Task',
  taskList: 'List Task',
  reminderList: 'List Reminder',
  reminderCreate: 'Create Reminder',
  appointmentList: 'List Appointment',
  appointmentCreate: 'Create Appointment',
  noteCreate: 'Create Note',
  noteList: 'List Note',
  ticketList: 'List Ticket',
  ticketCreate: 'Create Ticket',
  noResultsFound: 'No results found',
  trySearching: 'Try searching for',
};

export default locale;
