/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import {
  IContractRenewInput,
  IContractDetailResult,
  IContractRenewFilterInput,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useContractRenewMutation } from '../context/contract.api';
import ActionDialog from 'components/dialog/ActionDialog';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateContractRenewInput } from '../validations/renew.validation';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultDatePickerInput } from 'components/form/DefaultDatePickerInput';

export interface IContractRenewDialogProps {
  contractDetailData: IContractDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
  openConvertForm: boolean;
  setOpenConvertForm: Dispatch<SetStateAction<boolean>>;
}

export const ContractRenewDialog = memo((props: IContractRenewDialogProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries

  // mutations
  const [
    customerCreateFromContract,
    {
      data: customerCreateFromContractData,
      isLoading: customerCreateFromContractLoading,
      error: customerCreateFromContractError,
    },
  ] = useContractRenewMutation();

  // constants

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, touchedFields },
  } = useForm<IGraphqlVariables<IContractRenewInput, IContractRenewFilterInput>>({
    defaultValues: {
      filter: {
        contractId: props.contractDetailData?._id,
      },
      input: {
        contractValue: props.contractDetailData?.contractValue,
        startDate: props.contractDetailData?.startDate,
        endDate: props.contractDetailData?.endDate,
      },
    },
    resolver: yupResolver(validateContractRenewInput),
    mode: 'onChange',
  });

  // action handlers

  const handleConvertContract = async (
    values: IGraphqlVariables<IContractRenewInput, IContractRenewFilterInput>,
  ) => {
    customerCreateFromContract(values);
  };

  // useEffects
  useEffect(() => {
    if (props.contractDetailData) {
      setValue('filter.contractId', props.contractDetailData._id);
      setValue('input.contractValue', props.contractDetailData.contractValue);
      setValue('input.startDate', props.contractDetailData.startDate);
      setValue('input.endDate', props.contractDetailData.endDate);
    }
  }, [props.contractDetailData]);

  // useEffects.success
  useEffect(() => {
    if (customerCreateFromContractData) {
      enqueueSnackbar(t('crm.contract.contract.renewSuccess'), {
        variant: 'success',
      });
      reset();
      props.setOpen(false);
      props.setOpenConvertForm(false);
    }
  }, [customerCreateFromContractData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerCreateFromContractError as IBaseError, currentLanguage);
  }, [customerCreateFromContractError]);

  return (
    <ActionDialog
      open={props.openConvertForm}
      onClose={() => props.setOpenConvertForm(false)}
      title={t('crm.contract.contract.contractRenew')}
      handleClick={handleSubmit(handleConvertContract)}
      loading={customerCreateFromContractLoading}
      buttonTitle={t('crm.contract.contract.renew')}
      width={500}
    >
      <Grid item xs={12}>
        <DefaultTextFieldInput
          control={control}
          errors={errors}
          inputName="input.contractValue"
          label={t('crm.contract.contract.contractValue')}
          touchedFields={touchedFields}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultDatePickerInput
          control={control}
          errors={errors}
          inputName="input.startDate"
          label={t('crm.startDate')}
          isRequired
        />
      </Grid>
      <Grid item xs={12}>
        <DefaultDatePickerInput
          control={control}
          errors={errors}
          inputName="input.endDate"
          label={t('crm.endDate')}
          isRequired
        />
      </Grid>
    </ActionDialog>
  );
});
