import { Stack, useMediaQuery } from '@mui/material';
import CustomAreaChart from '../../components/CustomAreaChart';
import CustomBarChart from '../../components/CustomBarChart';
import CustomDonutChart from '../../components/CustomDonutChart';
import { useTranslation } from 'react-i18next';
import { useTaskStatisticsQuery } from 'apps/crm/domains/11-task/subdomains/task';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { TaskPriority, TaskStatus } from 'corede-common-cocrm';
import { memo, useCallback } from 'react';
import { Language } from 'corede-common';

const TaskStatistics = memo((props: {}) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const { data: taskStatisticsData } = useTaskStatisticsQuery({
    input: {
      filter: {
        numberOfPastDays: 7,
      },
    },
  });

  const dailyCreatedCounts = taskStatisticsData?.dailyHistory?.dailyCreatedCounts ?? [];
  const totalDailyCreatedCounts = dailyCreatedCounts.reduce((a, b) => a + b, 0);
  const dailyTotalCounts = taskStatisticsData?.dailyHistory?.dailyTotalCounts ?? [];

  const lowCount = taskStatisticsData?.countByTaskPriority?.countByLow ?? 0;
  const mediumCount = taskStatisticsData?.countByTaskPriority?.countByMedium ?? 0;
  const highCount = taskStatisticsData?.countByTaskPriority?.countByHigh ?? 0;
  const urgentCount = taskStatisticsData?.countByTaskPriority?.countByUrgent ?? 0;

  const countByStatuses = taskStatisticsData?.countByTaskStatus ?? {};
  const totalCountByStatuses = Object.values(taskStatisticsData?.countByTaskStatus ?? {}).reduce(
    (sum, count) => sum + count,
    0,
  );

  const getBarChartData = useCallback(() => {
    return [
      {
        name: getTranslatedEnumValue(TaskPriority.low),
        value1: lowCount ?? 0,
        value2Format: {
          count: parseFloat(
            ((lowCount ?? 0 / (lowCount + mediumCount + highCount + urgentCount)) * 100).toFixed(1),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
      {
        name: getTranslatedEnumValue(TaskPriority.medium),
        value1: mediumCount ?? 0,
        value2Format: {
          count: parseFloat(
            ((mediumCount ?? 0 / (lowCount + mediumCount + highCount + urgentCount)) * 100).toFixed(1),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
      {
        name: getTranslatedEnumValue(TaskPriority.high),
        value1: highCount ?? 0,
        value2Format: {
          count: parseFloat(
            ((highCount ?? 0 / (lowCount + mediumCount + highCount + urgentCount)) * 100).toFixed(1),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
      {
        name: getTranslatedEnumValue(TaskPriority.urgent),
        value1: urgentCount ?? 0,
        value2Format: {
          count: parseFloat(
            ((urgentCount ?? 0 / (lowCount + mediumCount + highCount + urgentCount)) * 100).toFixed(1),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
    ];
  }, [lowCount, mediumCount, highCount, urgentCount, getTranslatedEnumValue]);

  const getDonutChartData = useCallback(() => {
    return [
      {
        name: getTranslatedEnumValue(TaskStatus.awaitingFeedback),
        value1: countByStatuses?.countByAwaitingFeedback ?? 0,
        value2Format: {
          count: parseFloat(
            ((countByStatuses?.countByAwaitingFeedback ?? 0 / totalCountByStatuses) * 100).toFixed(
              2,
            ),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
      {
        name: getTranslatedEnumValue(TaskStatus.completed),
        value1: countByStatuses?.countByCompleted ?? 0,
        value2Format: {
          count: parseFloat(
            ((countByStatuses?.countByCompleted ?? 0 / totalCountByStatuses) * 100).toFixed(2),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
      {
        name: getTranslatedEnumValue(TaskStatus.inProgress),
        value1: countByStatuses?.countByInProgress ?? 0,
        value2Format: {
          count: parseFloat(
            ((countByStatuses?.countByInProgress ?? 0 / totalCountByStatuses) * 100).toFixed(2),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
      {
        name: getTranslatedEnumValue(TaskStatus.notStarted),
        value1: countByStatuses?.countByNotStarted ?? 0,
        value2Format: {
          count: parseFloat(
            ((countByStatuses?.countByNotStarted ?? 0 / totalCountByStatuses) * 100).toFixed(2),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
      {
        name: getTranslatedEnumValue(TaskStatus.tested),
        value1: countByStatuses?.countByTested ?? 0,
        value2Format: {
          count: parseFloat(
            ((countByStatuses?.countByTested ?? 0 / totalCountByStatuses) * 100).toFixed(2),
          ),
          prefix: '+',
          suffix: '%',
        },
      },
    ];
  }, [countByStatuses, totalCountByStatuses, getTranslatedEnumValue]);

  const getAreaChartData = useCallback(() => {
    return (
      dailyTotalCounts
        ?.map((item, index) => ({
          name: index.toString(),
          uv: item ?? 0,
          pv: 3000,
          amt: 3000,
        }))
        .reverse() ?? []
    );
  }, [dailyTotalCounts]);

  const getBottomToolbarData = useCallback(() => {
    return [
      {
        name: t('crm.dashboard.dashboard.today'),
        valueFormat: {
          count1: dailyTotalCounts?.[0] ?? 0,
          count2: dailyCreatedCounts?.[0] ?? undefined,
          prefix: dailyCreatedCounts?.[0] ? '+' : '',
        },
      },
      {
        name: t('crm.dashboard.dashboard.yesterday'),
        valueFormat: {
          count1: dailyTotalCounts?.[1] ?? 0,
          count2: dailyCreatedCounts?.[1] ?? undefined,
          prefix: dailyCreatedCounts?.[1] ? '+' : '',
        },
      },
    ];
  }, [dailyTotalCounts, dailyCreatedCounts, t]);


  return (
    <Stack direction={downMd ? 'column' : 'row'} gap={2} mb={1} width={'100%'}>
      <Stack flexBasis={'33%'} width={'100%'} gap={2}>
        <CustomBarChart
          title={t('crm.dashboard.dashboard.taskFilterPriority')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          theme="secondary"
          flexBasis={'33%'}
          data={getBarChartData()}
          language={currentLanguage}
        />
        <CustomDonutChart
          title={t('crm.dashboard.dashboard.taskFilterStatus')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          language={currentLanguage}
          colors={[
            '#00BAD1',
            '#00BAD1CC',
            '#00BAD199',
            '#00BAD166',
            '#00BAD133',
            '#00BAD1',
            '#00BAD1',
          ]}
          theme="info"
          flexBasis={'33%'}
          totalValue={totalCountByStatuses}
          data={getDonutChartData()}
        />
      </Stack>
      <CustomAreaChart
        title={t('crm.dashboard.dashboard.totalTask')}
        subtitle={t('crm.dashboard.dashboard.totalTaskSubtitle')}
        theme="warning"
        flexBasis={'67%'}
        icon="carbon:product"
        count={dailyTotalCounts?.[0] ?? 0}
        data={getAreaChartData()}
        topRightCount={
          totalDailyCreatedCounts > 0
            ? `${(((totalDailyCreatedCounts ?? 0) / dailyTotalCounts[0]) * 100).toFixed(1)}%`
            : '0%'
        }
        bottomToolbarData={getBottomToolbarData()}
      />
    </Stack>
  );
});

export default TaskStatistics;
