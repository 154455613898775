import {
  // queries

  IUserListInput,
  IUserListRequest,
  IUserListResponse,
  IUserListResult,
  userListQuery,
  IUserListByRelatedInput,
  IUserListByRelatedRequest,
  IUserListByRelatedResponse,
  IUserListByRelatedResult,
  userListByRelatedQuery,
  IOrganizationChartDetailInput,
  IOrganizationChartDetailRequest,
  IOrganizationChartDetailResponse,
  IOrganizationChartDetailResult,
  organizationChartDetailQuery,
  IUserPermissionDetailInput,
  IUserPermissionDetailRequest,
  IUserPermissionDetailResponse,
  IUserPermissionDetailResult,
  userPermissionDetailQuery,
  IUserPermissionDetailOwnInput,
  IUserPermissionDetailOwnRequest,
  IUserPermissionDetailOwnResponse,
  IUserPermissionDetailOwnResult,
  userPermissionDetailOwnQuery,

  // mutations
  IUserCreateInput,
  IUserCreateRequest,
  IUserCreateResponse,
  IUserCreateResult,
  userCreateQuery,
  IUserUpdateInput,
  IUserUpdateRequest,
  IUserUpdateResponse,
  IUserUpdateResult,
  userUpdateQuery,
  IUserUpdateFilterInput,
  IUserDeleteInput,
  IUserDeleteRequest,
  IUserDeleteResponse,
  IUserDeleteResult,
  userDeleteQuery,
  IUserRoleUpdateInput,
  IUserRoleUpdateRequest,
  IUserRoleUpdateResponse,
  IUserRoleUpdateResult,
  userRoleUpdateQuery,
  IUserRoleUpdateFilterInput,
  IOrganizationChartUpdateInput,
  IOrganizationChartUpdateRequest,
  IOrganizationChartUpdateResponse,
  IOrganizationChartUpdateResult,
  organizationChartUpdateQuery,
  IOrganizationChartUpdateFilterInput,
  IUserPermissionUpdateInput,
  IUserPermissionUpdateRequest,
  IUserPermissionUpdateResponse,
  IUserPermissionUpdateResult,
  userPermissionUpdateQuery,
  IUserPermissionUpdateFilterInput,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { userTags } from './user.tags';
import { IGraphqlVariables } from 'corede-common';

export const organizationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    userList: builder.query<IUserListResult, IGraphqlVariables<IUserListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserListRequest,
        IUserListResponse,
        IUserListResult,
        IUserListInput
      >({
        query: userListQuery,
        providesTags: [userTags.users, userTags.user],
      }),
    ),

    userListByRelated: builder.query<
      IUserListByRelatedResult,
      IGraphqlVariables<IUserListByRelatedInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserListByRelatedRequest,
        IUserListByRelatedResponse,
        IUserListByRelatedResult,
        IUserListByRelatedInput
      >({
        query: userListByRelatedQuery,
        providesTags: [userTags.usersByRelated],
      }),
    ),

    organizationChartDetail: builder.query<
      IOrganizationChartDetailResult,
      IGraphqlVariables<IOrganizationChartDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationChartDetailRequest,
        IOrganizationChartDetailResponse,
        IOrganizationChartDetailResult,
        IOrganizationChartDetailInput
      >({
        query: organizationChartDetailQuery,
        providesTags: [userTags.organization],
      }),
    ),

    userPermissionDetail: builder.query<
      IUserPermissionDetailResult,
      IGraphqlVariables<IUserPermissionDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserPermissionDetailRequest,
        IUserPermissionDetailResponse,
        IUserPermissionDetailResult,
        IUserPermissionDetailInput
      >({
        query: userPermissionDetailQuery,
        providesTags: [userTags.permissions],
      }),
    ),

    userPermissionDetailOwn: builder.query<
      IUserPermissionDetailOwnResult,
      IGraphqlVariables<IUserPermissionDetailOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserPermissionDetailOwnRequest,
        IUserPermissionDetailOwnResponse,
        IUserPermissionDetailOwnResult,
        IUserPermissionDetailOwnInput
      >({
        query: userPermissionDetailOwnQuery,
        providesTags: [userTags.permissions],
      }),
    ),

    // mutations

    userCreate: builder.mutation<IUserCreateResult, IGraphqlVariables<IUserCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserCreateRequest,
        IUserCreateResponse,
        IUserCreateResult,
        IUserCreateInput
      >({
        query: userCreateQuery,
        invalidatesTags: [userTags.users, userTags.user, userTags.usersByRelated],
      }),
    ),

    userUpdate: builder.mutation<
      IUserUpdateResult,
      IGraphqlVariables<IUserUpdateInput, IUserUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserUpdateRequest,
        IUserUpdateResponse,
        IUserUpdateResult,
        IUserUpdateInput,
        IUserUpdateFilterInput
      >({
        query: userUpdateQuery,
        invalidatesTags: [userTags.users, userTags.user, userTags.usersByRelated],
      }),
    ),

    userDelete: builder.mutation<IUserDeleteResult, IGraphqlVariables<IUserDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDeleteRequest,
        IUserDeleteResponse,
        IUserDeleteResult,
        IUserDeleteInput
      >({
        query: userDeleteQuery,
        invalidatesTags: [userTags.users, userTags.usersByRelated],
      }),
    ),

    userRoleUpdate: builder.mutation<
      IUserRoleUpdateResult,
      IGraphqlVariables<IUserRoleUpdateInput, IUserRoleUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserRoleUpdateRequest,
        IUserRoleUpdateResponse,
        IUserRoleUpdateResult,
        IUserRoleUpdateInput,
        IUserRoleUpdateFilterInput
      >({
        query: userRoleUpdateQuery,
        invalidatesTags: [userTags.users, userTags.user],
      }),
    ),

    organizationChartUpdate: builder.mutation<
      IOrganizationChartUpdateResult,
      IGraphqlVariables<IOrganizationChartUpdateInput, IOrganizationChartUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationChartUpdateRequest,
        IOrganizationChartUpdateResponse,
        IOrganizationChartUpdateResult,
        IOrganizationChartUpdateInput,
        IOrganizationChartUpdateFilterInput
      >({
        query: organizationChartUpdateQuery,
        invalidatesTags: [userTags.organization],
      }),
    ),

    userPermissionUpdate: builder.mutation<
      IUserPermissionUpdateResult,
      IGraphqlVariables<IUserPermissionUpdateInput, IUserPermissionUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserPermissionUpdateRequest,
        IUserPermissionUpdateResponse,
        IUserPermissionUpdateResult,
        IUserPermissionUpdateInput,
        IUserPermissionUpdateFilterInput
      >({
        query: userPermissionUpdateQuery,
        invalidatesTags: [userTags.permissions],
      }),
    ),
  }),
});

export const {
  useUserListQuery,
  useLazyUserListQuery,
  useUserListByRelatedQuery,
  useLazyUserListByRelatedQuery,
  useOrganizationChartDetailQuery,
  useLazyOrganizationChartDetailQuery,
  useUserPermissionDetailQuery,
  useLazyUserPermissionDetailQuery,
  useUserPermissionDetailOwnQuery,
  useLazyUserPermissionDetailOwnQuery,

  useUserCreateMutation,
  useUserUpdateMutation,
  useUserDeleteMutation,
  useUserRoleUpdateMutation,
  useOrganizationChartUpdateMutation,
  useUserPermissionUpdateMutation,
} = organizationApi;
