const locale = {
  contracts: 'Sözleşmeler',
  contract: 'Sözleşme',
  contractConversions: 'Sözleşme Dönüşümleri',
  title: 'Sözleşmeler',
  create: 'Sözleşme Oluştur',
  update: 'Sözleşme Güncelle',
  delete: 'Sözleşme Sil',
  createSuccess: 'Sözleşme başarıyla oluşturuldu.',
  updateSuccess: 'Sözleşme başarıyla güncellendi.',
  deleteSuccess: 'Sözleşme başarıyla silindi.',
  deleteConfirm: 'Bu sözleşmeyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  emptyState1: 'Henüz sözleşme eklenmedi.',
  emptyState2:
    'Faturalarınızı kolaylaştırmak ve müşterilerinizle net anlaşmalar sağlamak için tüm sözleşme detaylarını burada saklayın ve yönetin.',

  // Etiketler
  contractTemplateLabel: 'Sözleşme Şablonu',
  contractValue: 'Sözleşme Değeri',
  contractType: 'Sözleşme Türü',
  signedDate: 'İmzalanma Tarihi',
  signedUser: 'İmzalayan Kullanıcı',
  signStatus: 'İmza Durumu',

  // detail drawer
  changeStatus: 'Durumu Değiştir',
  statusChangeSuccess: 'Durum başarıyla değiştirildi.',
  generalInfoTitle: 'Genel Bilgiler',
  customerInfoTitle: 'Müşteri Bilgileri',
  projectInfoTitle: 'Proje Bilgileri',
  contractRenew: 'Sözleşme Yenile',
  renew: 'Sözleşme Yenile',
  renewSuccess: 'Sözleşme yenileme başarıyla gerçekleştirildi.',
  emptyStateRenew: 'Sözleşme yenileme mevcut değil.',
  renewHistory: 'Yenileme Geçmişi',
};

export default locale;
