import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  ILeadListInput,
  ILeadListRequest,
  ILeadListResponse,
  ILeadListResult,
  leadListQuery,
  ILeadListByRelatedInput,
  ILeadListByRelatedRequest,
  ILeadListByRelatedResponse,
  ILeadListByRelatedResult,
  leadListByRelatedQuery,
  ILeadDetailInput,
  ILeadDetailRequest,
  ILeadDetailResponse,
  ILeadDetailResult,
  leadDetailQuery,
  ILeadListCountRequest,
  ILeadListCountResponse,
  leadListCountQuery,
  ILeadStatisticsInput,
  ILeadStatisticsRequest,
  ILeadStatisticsResponse,
  ILeadStatisticsResult,
  leadStatisticsQuery,

  // mutations
  ILeadCreateInput,
  ILeadCreateRequest,
  ILeadCreateResponse,
  ILeadCreateResult,
  leadCreateQuery,
  ILeadUpdateInput,
  ILeadUpdateRequest,
  ILeadUpdateResponse,
  ILeadUpdateResult,
  leadUpdateQuery,
  ILeadUpdateFilterInput,
  ILeadDeleteInput,
  ILeadDeleteRequest,
  ILeadDeleteResponse,
  ILeadDeleteResult,
  leadDeleteQuery,
  ILeadContactAddInput,
  ILeadContactAddRequest,
  ILeadContactAddResponse,
  leadContactAddQuery,
  ICustomerCreateFromLeadInput,
  ICustomerCreateFromLeadResponse,
  ICustomerCreateResult,
  customerCreateFromLeadQuery,
  ICustomerCreateFromLeadRequest,
  leadImageCreateQuery,
  ILeadImageCreateRequest,
  ILeadImageCreateResponse,
  leadImageAssignQuery,
  ILeadImageAssignRequest,
  ILeadImageAssignResponse,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { leadTags } from './lead.tags';
import {
  IBaseResult,
  ICount,
  IDomainFileCreateOwnInputWithMimeType,
  IDomainFileCreateResult,
  IEntity,
  IEntityFileAssignInput,
  IEntityFileAssignResult,
  IGraphqlVariables,
} from 'corede-common';
import { customersTags } from 'apps/crm/domains/04-customer/subdomains/customer';

export const organizationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    leadList: builder.query<ILeadListResult, IGraphqlVariables<ILeadListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadListRequest,
        ILeadListResponse,
        ILeadListResult,
        ILeadListInput
      >({
        query: leadListQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      }),
    ),

    leadListByRelated: builder.query<
      ILeadListByRelatedResult,
      IGraphqlVariables<ILeadListByRelatedInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadListByRelatedRequest,
        ILeadListByRelatedResponse,
        ILeadListByRelatedResult,
        ILeadListByRelatedInput
      >({
        query: leadListByRelatedQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      }),
    ),

    leadListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadListCountRequest,
        ILeadListCountResponse,
        ICount,
        undefined
      >({
        query: leadListCountQuery,
        providesTags: [leadTags.leads],
      }),
    ),

    leadDetail: builder.query<ILeadDetailResult, IGraphqlVariables<ILeadDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadDetailRequest,
        ILeadDetailResponse,
        ILeadDetailResult,
        ILeadDetailInput
      >({
        query: leadDetailQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      }),
    ),

    leadStatistics: builder.query<ILeadStatisticsResult, IGraphqlVariables<ILeadStatisticsInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadStatisticsRequest,
        ILeadStatisticsResponse,
        ILeadStatisticsResult,
        ILeadStatisticsInput
      >({
        query: leadStatisticsQuery,
        providesTags: [leadTags.lead, leadTags.leads],
      }),
    ),

    // mutations

    leadCreate: builder.mutation<ILeadCreateResult, IGraphqlVariables<ILeadCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadCreateRequest,
        ILeadCreateResponse,
        ILeadCreateResult,
        ILeadCreateInput
      >({
        query: leadCreateQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads],
      }),
    ),

    leadUpdate: builder.mutation<
      ILeadUpdateResult,
      IGraphqlVariables<ILeadUpdateInput, ILeadUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadUpdateRequest,
        ILeadUpdateResponse,
        ILeadUpdateResult,
        ILeadUpdateInput,
        ILeadUpdateFilterInput
      >({
        query: leadUpdateQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads],
      }),
    ),

    leadDelete: builder.mutation<ILeadDeleteResult, IGraphqlVariables<ILeadDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadDeleteRequest,
        ILeadDeleteResponse,
        ILeadDeleteResult,
        ILeadDeleteInput
      >({
        query: leadDeleteQuery,
        invalidatesTags: [leadTags.leads],
      }),
    ),

    leadContactAdd: builder.mutation<IBaseResult, IGraphqlVariables<ILeadContactAddInput, IEntity>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadContactAddRequest,
        ILeadContactAddResponse,
        IBaseResult,
        ILeadContactAddInput,
        IEntity
      >({
        query: leadContactAddQuery,
        invalidatesTags: [leadTags.lead, leadTags.leads],
      }),
    ),

    customerCreateFromLead: builder.mutation<
      ICustomerCreateResult,
      IGraphqlVariables<ICustomerCreateFromLeadInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ICustomerCreateFromLeadRequest,
        ICustomerCreateFromLeadResponse,
        ICustomerCreateResult,
        ICustomerCreateFromLeadInput
      >({
        query: customerCreateFromLeadQuery,
        invalidatesTags: [
          leadTags.lead,
          leadTags.leads,
          customersTags.customer,
          customersTags.customers,
        ],
      }),
    ),

    leadImageCreate: builder.mutation<
      IDomainFileCreateResult,
      IGraphqlVariables<IDomainFileCreateOwnInputWithMimeType>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadImageCreateRequest,
        ILeadImageCreateResponse,
        IDomainFileCreateResult,
        IDomainFileCreateOwnInputWithMimeType
      >({
        query: leadImageCreateQuery,
      }),
    ),

    leadImageAssign: builder.mutation<
      IEntityFileAssignResult,
      IGraphqlVariables<IEntityFileAssignInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILeadImageAssignRequest,
        ILeadImageAssignResponse,
        IEntityFileAssignResult,
        IEntityFileAssignInput
      >({
        query: leadImageAssignQuery,
        invalidatesTags: [leadTags.lead],
      }),
    ),
  }),
});

export const {
  useLeadListQuery,
  useLazyLeadListQuery,
  useLeadListByRelatedQuery,
  useLazyLeadListByRelatedQuery,
  useLeadDetailQuery,
  useLazyLeadDetailQuery,
  useLeadListCountQuery,
  useLazyLeadListCountQuery,
  useLeadStatisticsQuery,
  useLazyLeadStatisticsQuery,

  useLeadCreateMutation,
  useLeadUpdateMutation,
  useLeadDeleteMutation,

  useLeadContactAddMutation,
  useCustomerCreateFromLeadMutation,

  useLeadImageCreateMutation,
  useLeadImageAssignMutation,
} = organizationApi;
