/* eslint-disable react-hooks/exhaustive-deps */
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  DocumentTargetType,
  ITaskDetailResult,
  NoteTargetType,
  PermissionSubject,
  ReminderTargetType,
} from 'corede-common-cocrm';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { TaskDetailDrawerTabInfoComponent } from './TaskDetailDrawer-tab-infoSection.component';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useTaskDocumentCreateManyMutation,
  useTaskDocumentAddManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { Iconify } from 'components';
import { PermissionAction } from 'corede-common';

export interface ITaskDetailDrawerRightPanelTabsComponentProps {
  taskDetailData: ITaskDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const TaskDetailDrawerRightPanelTabsComponent = (
  props: ITaskDetailDrawerRightPanelTabsComponentProps,
) => {
  const icons = [
    <Iconify icon="fluent:info-32-regular" width={18} />,
    <Iconify icon="carbon:product" width={18} />,
    <Iconify icon="fluent:document-32-regular" width={18} />,
    <Iconify icon="iconoir:bell" width={18} />,
  ] as React.ReactElement[];

  return (
    <DefaultTabsComponent<ITaskDetailResult> entityData={props.taskDetailData} icons={icons}>
      <TaskDetailDrawerTabInfoComponent
        taskDetailData={props.taskDetailData}
        tabKey="info"
        name="crm.info"
      />

      <DocumentListTabComponent
        targetId={props.taskDetailData?._id ?? ''}
        targetEntityRef={DocumentTargetType.Task}
        tabKey={'documents'}
        name={'crm.documents'}
        // disableShow
        useDocumentCreateManyMutation={useTaskDocumentCreateManyMutation}
        useDocumentAddManyMutation={useTaskDocumentAddManyMutation}
        permission={{
          subject: PermissionSubject.file,
          action: PermissionAction.list,
        }}
      />

      <NoteListTabComponent
        targetId={props.taskDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Task}
        targetDisplayName={props.taskDetailData?.subject ?? ''}
        tabKey="notes"
        name="crm.notes"
        permission={{
          subject: PermissionSubject.note,
          action: PermissionAction.list,
        }}
      />

      <ReminderListTabComponent
        targetId={props.taskDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Task}
        targetDisplayName={props.taskDetailData?.subject ?? ''}
        tabKey="reminders"
        name="crm.reminders"
        permission={{
          subject: PermissionSubject.note,
          action: PermissionAction.list,
        }}
      />

      {/* <DefaultEmptyTabComponent
        title={t('crm.activities')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      /> */}
    </DefaultTabsComponent>
  );
};
