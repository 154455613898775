/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useTicketDeleteMutation } from "../context/support.api";

export const TicketDeleteDialog = memo(
  (props: {
    open: boolean;
    ticketId: string;
    onClose: () => void;
    setTicket: Dispatch<any>;
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      ticketDelete,
      {
        data: ticketDeleteData,
        isLoading: ticketDeleteIsLoading,
        error: ticketDeleteError,
      },
    ] = useTicketDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.ticketId}
        setItem={props.setTicket}
        deleteMutation={ticketDelete}
        deleteMutationData={{
          data: ticketDeleteData,
          isLoading: ticketDeleteIsLoading,
          error: ticketDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.support.support.delete"),
          successMessage: t("crm.support.support.deleteSuccess"),
          content: t("crm.support.support.deleteContent"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
