/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from '@mui/material';
import { TCellRenderFunc } from 'components/baseDataGrid/infra/types';

export function OrganizerInfoCellComponent(): TCellRenderFunc {
  return (cellParams) => {
    console.log(cellParams.value?.name);
    return (
      <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} gap={1}>
        <Avatar
          alt={cellParams.value?.name + ' ' + cellParams.value?.surname}
          src={cellParams.value?.image}
          sx={{ width: 35, height: 35 }}
        />
        <Stack direction={'column'} alignItems={'flex-start'} justifyContent={'center'} gap={0.5}>
          {cellParams.value?.name ? (
            <Typography fontSize={'12px'} fontWeight={500} color={'text.primary'}>
              {cellParams.value?.name + ' ' + cellParams.value?.surname}
            </Typography>
          ) : (
            <Typography fontSize={'12px'} fontWeight={500} color={'text.primary'}>
              -
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  };
}
