import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { useProjectListCountQuery } from '../../context/project.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ProjectListComponent from './ProjectList-grid.component';
import { useLocation } from 'react-router-dom';
import { ProjectListStatisticsView } from './ProjectList-statistics.component';
import ProjectCreateDrawerWrapper from '../create/ProjectCreateDrawer.wrapper';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';

const ProjectList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openProjectCreateDrawer, setOpenProjectCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();

  // queries
  const { data: projectListCountData, isLoading: projectListCountLoading } =
    useProjectListCountQuery({});
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});

  let totalProjectCount = projectListCountData?.count ?? 0;
  let isEmpty = totalProjectCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenProjectCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {t(`crm.project.project.title`)}
            </Typography>
          </Stack>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.project.project.create`),
          onClick: () => setOpenProjectCreateDrawer(true),
          permission: {
            subject: PermissionSubject.project,
            action: PermissionAction.create,
          },
        }}
      />

      <ProjectListStatisticsView
        count={totalProjectCount}
        projectListCountLoading={projectListCountLoading}
      />

      <ProjectListComponent setOpenProjectCreateDrawer={setOpenProjectCreateDrawer} />

      <ProjectCreateDrawerWrapper
        open={openProjectCreateDrawer}
        setOpen={setOpenProjectCreateDrawer}
      />
    </Grid>
  );
});

export default ProjectList;
