import { Stack, Box, Typography } from '@mui/material';
import { BarChart, Bar, Tooltip, XAxis, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { useState, useCallback, memo } from 'react';
import { Language } from 'corede-common';

interface ICustomBarChartV3Item {
  name: string;
  value1Format: {
    count: number;
    prefix?: string;
    suffix?: string;
  };
  value2Format: {
    count: number;
    prefix?: string;
    suffix?: string;
  };
}

interface ICustomBarChartV3 {
  data: ICustomBarChartV3Item[];
  title: string;
  subtitle: string;
  theme: string;
  flexBasis: string;
  language: Language
}

const CustomBarChartV3 = memo((props: ICustomBarChartV3) => {
  const theme = useTheme();
  const palette = theme.palette as Record<string, any>;
  const hexColor = palette[props.theme].lighter;
  const selectedColor = palette[props.theme].main;
  const zeroValueColor = theme.palette.grey[300]; // Slightly darker color for zero values

  const [activeIndex, setActiveIndex] = useState(5);

  const updatedData = props.data.map((item, index) => ({
    ...item,
    value1Format: {
      ...item.value1Format,
      count: item.value1Format.count === 0 ? 0.1 : item.value1Format.count,
    },
    fill: item.value1Format.count === 0 ? zeroValueColor : (index === activeIndex ? selectedColor : hexColor),
  }));

  const displayValue = (value: number) => (value === 0.1 ? 0 : value);

  const CustomTooltip = useCallback(({ active, payload }: any) => {
    if (active && payload && payload.length) {

      const nameLabel = props.language === 'tr' ? 'İsim' : 'Name';
      const valueLabel = props.language === 'tr' ? 'Değer' : 'Value';
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {`${nameLabel}: ${payload[0].payload?.name}`}
          </Typography>
          <Typography variant="body2">{`${valueLabel}: ${displayValue(payload[0].value)}`}</Typography>
        </Box>
      );
    }
    return null;
  }, [props.language]);

  return (
    <Stack
      direction={'column'}
      gap={1}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={props.flexBasis}
      width={'100%'}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {props.subtitle}
        </Typography>
      </Box>

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="caption">{props.data[activeIndex]?.name}</Typography>

        <Stack direction={'row'} gap={2}>
          <Typography variant="caption">
            {props.data[activeIndex]?.value1Format.prefix}
            {displayValue(props.data[activeIndex]?.value1Format.count)}
            {props.data[activeIndex]?.value1Format.suffix}
          </Typography>

          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {props.data[activeIndex]?.value2Format.prefix}
            {props.data[activeIndex]?.value2Format.count}
            {props.data[activeIndex]?.value2Format.suffix}
          </Typography>
        </Stack>
      </Stack>

      <ResponsiveContainer height={160}>
        <BarChart
          data={updatedData} // Güncellenmiş veri
          barGap={10}
          onClick={(e) => setActiveIndex(e?.activeTooltipIndex ?? 1)}
        >
          <XAxis dataKey="name" tickLine={false} axisLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value1Format.count"
            fill={hexColor}
            radius={10}
            barSize={50}
            cursor="pointer"
          />
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
});

export default CustomBarChartV3;
