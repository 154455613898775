import {
  Autocomplete,
  Box,
  Chip,
  InputLabel,
  Switch,
  SwitchPropsColorOverrides,
  TextField,
  Typography,
} from '@mui/material';
import { ActionIconColor } from 'components/baseDataGrid/infra/IBaseGridColumnActionComponentsParams';
import { useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

export const DefaultRelationshipSwitchInput = (props: {
  label?: string;
  description: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';
  isRequired?: boolean;
  checked?: boolean;
  setChecked?: (value: boolean) => void;
}) => {
  return (
    <>
      <InputLabel
        htmlFor={'relationship'}
        sx={{
          ml: 1,
          mb: 0.4,
          fontSize: 'small',
          color: '#00000000',
        }}
      >
       _
      </InputLabel>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '16px',
          p: 0.9,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Switch
          id={'relationship'}
          checked={props.checked || false}
          color={props.color || 'secondary'}
          size="small"
          onChange={(e) => props.setChecked && props.setChecked(e.target.checked)}
        />
        <Typography sx={{ ml: 1, fontSize: 'small', fontWeight: 'bold' }}>
          {props.description} {props.isRequired && '*'}
        </Typography>
      </Box>
    </>
  );
};
