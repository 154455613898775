/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState, useRef } from "react";
import { getCurrentLanguage } from "localization";
import { useTranslation } from "react-i18next";
import { IBaseError, IGraphqlVariables, Language } from "corede-common";
import {
  ILead,
  ILeadSourceResult,
  ILeadStatusResult,
  ILeadUpdateFilterInput,
  ILeadUpdateInput, 
  LeadBusinessType,
  LeadQualification,
  LeadType,
} from "corede-common-cocrm";
import { validateUpdateLeadInput } from "../../validations/update.validation";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import {
  useLeadDetailQuery,
  useLeadUpdateMutation,
} from "../../context/lead.api";
import { useLeadStatusListQuery } from "../../../leadStatus/context/leadStatus.api";
import { useLeadSourceListQuery } from "../../../leadSource/context/leadSource.api";
import { enqueueSnackbar } from "notistack";
import "react-quill/dist/quill.snow.css";
import { skipToken } from "@reduxjs/toolkit/query";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpsertLeadComponent } from "../UpsertLead.component";

interface ILeadUpdateDrawer {
  open: any;
  setOpen: any;
  selectedLead: ILead;
  leadStatusList?: ILeadStatusResult[];
  leadSourceList?: ILeadSourceResult[];
}

const LeadUpdateDrawer = memo((props: ILeadUpdateDrawer) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // queries
  const {
    data: leadStatusListDataFromApi,
    isLoading: leadStatusListLoading,
    error: leadStatusListError,
  } = useLeadStatusListQuery(
    {},
    {
      skip: !!props.leadStatusList, // Skip the query if props data exists
    }
  );
  const leadStatusList: ILeadStatusResult[] | undefined =
    props.leadStatusList || leadStatusListDataFromApi?.data;

  const {
    data: leadSourceListDataFromApi,
    isLoading: leadSourceListLoading,
    error: leadSourceListError,
  } = useLeadSourceListQuery(
    {},
    {
      skip: !!props.leadSourceList, // Skip the query if props data exists
    }
  );
  const leadSourceList: ILeadSourceResult[] | undefined =
    props.leadSourceList || leadSourceListDataFromApi?.data;

  const { data: leadDetailData } = useLeadDetailQuery(
    props?.selectedLead?._id
      ? {
        input: {
          _id: props?.selectedLead?._id,
        },
      }
      : skipToken
  );

  // mutations
  const [
    leadUpdate,
    {
      data: leadUpdateData,
      isLoading: leadUpdateLoading,
      error: leadUpdateError,
    },
  ] = useLeadUpdateMutation();

  // constants
  const [initialValues, setInitialValues] = useState<
    IGraphqlVariables<ILeadUpdateInput, ILeadUpdateFilterInput>
  >({
    filter: {
      _id: props.selectedLead._id,
    },
    input: {
      businessType: LeadBusinessType.b2b,
      fullName: leadDetailData?.fullName || "",
      email: leadDetailData?.email || "",
      isJunk: leadDetailData?.isJunk || false,
      isPublic: leadDetailData?.isPublic || true,
      qualification:
        leadDetailData?.qualification || LeadQualification.unqualified,
      sourceId:
        leadDetailData?.source?._id ||
        (leadSourceList ? leadSourceList[0]?._id : undefined),
      statusId:
        leadDetailData?.status?._id ||
        (leadStatusList ? leadStatusList[0]?._id : undefined),
      type: leadDetailData?.type || LeadType.neutral,
      tags: leadDetailData?.tags || [],
      company: leadDetailData?.company || "",
      position: leadDetailData?.position || "",
      website: leadDetailData?.website || "",
      phoneNumber: leadDetailData?.phoneNumber || "",
      sector: leadDetailData?.sector || "",
      country: leadDetailData?.country || "",
      state: leadDetailData?.state || "",
      city: leadDetailData?.city || "",
      language: leadDetailData?.language || Language.en,
      assignedIds: leadDetailData?.assigned?.map((user) => user._id) || [],
      note: leadDetailData?.note || "",
    },
  });

  // form setup
  const leadUpdateUseForm = useForm<
    IGraphqlVariables<ILeadUpdateInput, ILeadUpdateFilterInput>
  >({
    // defaultValues: initialValues,
    values: initialValues,
    resolver: yupResolver(validateUpdateLeadInput),
    mode: "onChange",
  });

  const onSubmit = async (
    values: IGraphqlVariables<ILeadUpdateInput, ILeadUpdateFilterInput>
  ) => {
    setLoading(true);
    const transformedValues =
      RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
        input: values.input,
      });
    await leadUpdate({
      filter: values.filter,
      input: transformedValues,
    });
    if (isMounted.current) {
      setLoading(false);
    }
  };

  // useEffects.success

  useEffect(() => {
    if (leadUpdateData && isMounted.current) {
      enqueueSnackbar(t("crm.lead.lead.updateLeadSuccess"), {
        variant: "success",
      });
      leadUpdateUseForm.reset();
      props.setOpen(false);
    }
  }, [leadUpdateData, leadUpdateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadUpdateError as IBaseError,
      currentLanguage
    );
  }, [leadUpdateError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadStatusListError as IBaseError,
      currentLanguage
    );
  }, [leadStatusListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      leadSourceListError as IBaseError,
      currentLanguage
    );
  }, [leadSourceListError]);
  // useEffects.init

  useEffect(() => {
    if (leadDetailData) {
      setInitialValues({
        filter: {
          _id: props.selectedLead._id,
        },
        input: {
          businessType: leadDetailData?.businessType || LeadBusinessType.b2b,
          fullName: leadDetailData?.fullName || "",
          email: leadDetailData?.email || "",
          isJunk: leadDetailData?.isJunk || false,
          isPublic: leadDetailData?.isPublic || true,
          qualification:
            leadDetailData?.qualification || LeadQualification.unqualified,
          sourceId:
            leadDetailData?.source?._id ||
            (leadSourceList ? leadSourceList[0]?._id : undefined),
          statusId:
            leadDetailData?.status?._id ||
            (leadStatusList ? leadStatusList[0]?._id : undefined),
          type: leadDetailData?.type || LeadType.neutral,
          tags: leadDetailData?.tags || [],
          company: leadDetailData?.company || "",
          position: leadDetailData?.position || "",
          website: leadDetailData?.website || "",
          phoneNumber: leadDetailData?.phoneNumber || "",
          sector: leadDetailData?.sector || "",
          country: leadDetailData?.country || "",
          state: leadDetailData?.state || "",
          city: leadDetailData?.city || "",
          language: leadDetailData?.language || Language.en,
          assignedIds: leadDetailData?.assigned?.map((user) => user._id) || [],
          note: leadDetailData?.note || "",
        },
      });
    }
  }, [leadDetailData, props?.selectedLead?._id]);

  return (
    <UpsertLeadComponent
      open={props.open}
      setOpen={props.setOpen}
      title={t("crm.lead.lead.update")}
      buttonTitle={t("crm.lead.lead.update")}
      loading={leadUpdateLoading || loading}
      useForm={leadUpdateUseForm}
      onSubmit={onSubmit}
      leadSourceList={leadSourceList}
      leadStatusList={leadStatusList}
      type="update"
    />
  );
});

export default LeadUpdateDrawer;
