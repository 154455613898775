/* eslint-disable react-hooks/exhaustive-deps */
import { ITicketDetailResult } from 'corede-common-cocrm';
import { TicketDetailDrawerHeaderStatusComponent } from './TicketDetailDrawer-rightPanel-header-status.component';
// import { TicketDetailDrawerBaseInfoComponent } from './TicketDetailDrawer-rightPanel-header-baseInfo.component';

export interface ITicketDetailDrawerRightPanelHeaderComponentProps {
  ticketDetailData: ITicketDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const TicketDetailDrawerRightPanelHeaderComponent = (
  props: ITicketDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <TicketDetailDrawerHeaderStatusComponent ticketDetailData={props.ticketDetailData} />
      {/* <TicketDetailDrawerBaseInfoComponent ticketDetailData={props.ticketDetailData} /> */}
    </>
  );
};
