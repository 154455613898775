export const expenseDomainRoutes = {
  base: 'expenses',
  subdomains: {
    expense: {
      base: 'expenses',
    },
  },
};

export const baseExpenseRoute = (): string => `/${expenseDomainRoutes.base}/`;
export const listExpenseRoute = (): string =>
  `/${expenseDomainRoutes.base}/${expenseDomainRoutes.subdomains.expense.base}`;
