import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { ITicketDetailResult } from 'corede-common-cocrm';
import React from 'react';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';

export interface ITicketDetailDrawerLeftPanelProps {
  ticketDetailData: ITicketDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TicketDetailDrawerLeftPanelGeneralInfoComponent = (
  props: ITicketDetailDrawerLeftPanelProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      {/* Genel Bilgiler */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
        {!downLg && (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.support.support.generalInfoTitle')}
            </Typography>
            <Divider />
          </Stack>
        )}

        {/* Created By */}
        {/* <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdBy')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.ticketDetailData?.createdBy?.name || '-'}
          </Typography>
        </Stack> */}

        {/*Created At*/}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.ticketDetailData?.createdAt
              ? moment(props.ticketDetailData?.createdAt).format()
              : '-'}
          </Typography>
        </Stack>

        {/* Closed At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.support.support.closedDate')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.ticketDetailData?.closedDate
              ? moment(props.ticketDetailData?.closedDate).format()
              : '-'}
          </Typography>
        </Stack>

        {/* Priority */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.priority')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {getTranslatedEnumValue(props.ticketDetailData?.priority ?? '') || '-'}
          </Typography>
        </Stack>

        {/* Category */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.category')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {getTranslatedEnumValue(props.ticketDetailData?.category ?? '') || '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
