import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import {
  IReminderDetailResult,
  ProjectStatus,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import React from 'react';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';
import { DetailDrawerTargetComponent } from 'apps/crm/components/detailDrawer/DetailDrawer-target.component';

export interface IReminderDetailDrawerLeftPanelProps {
  reminderDetailData: IReminderDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReminderDetailDrawerLeftPanelContactDetailComponent = (
  props: IReminderDetailDrawerLeftPanelProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      {/* Genel Bilgiler */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={2} mb={2}>
        {!downLg && (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.calendar.reminder.generalInfoTitle')}
            </Typography>
            <Divider />
          </Stack>
        )}

        {/* Reminder Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.reminderDetailData?.title || '-'}
          </Typography>
        </Stack>

        {/* Status */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.status')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {getTranslatedEnumValue(props.reminderDetailData?.status || '')}
          </Typography>
        </Stack>

        {/* Created At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {moment(props.reminderDetailData?.createdAt).format('DD/MM/YYYY') || '-'}
          </Typography>
        </Stack>
      </Stack>

      {/* Target */}
      {props.reminderDetailData?.target && (
        <DetailDrawerTargetComponent
          key={props.reminderDetailData?.target?._id}
          extraSmallSize={true}
          targetId={props.reminderDetailData?.target?._id as string}
          targetType={props.reminderDetailData?.targetType as ReminderTargetType}
        />
      )}
    </Stack>
  );
};
