import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IProposalListInput,
  IProposalListRequest,
  IProposalListResponse,
  IProposalListResult,
  proposalListQuery,
  IProposalListCountRequest,
  IProposalListCountResponse,
  proposalListCountQuery,
  IProposalDetailInput,
  IProposalDetailRequest,
  IProposalDetailResponse,
  IProposalDetailResult,
  proposalDetailQuery,
  proposalStatisticsQuery,
  IProposalStatisticsInput,
  IProposalStatisticsRequest,
  IProposalStatisticsResponse,
  IProposalStatisticsResult,

  // mutations
  IProposalCreateInput,
  IProposalCreateRequest,
  IProposalCreateResponse,
  IProposalCreateResult,
  proposalCreateQuery,
  IProposalUpdateInput,
  IProposalUpdateRequest,
  IProposalUpdateResponse,
  IProposalUpdateResult,
  proposalUpdateQuery,
  IProposalUpdateFilterInput,
  IProposalDeleteInput,
  IProposalDeleteRequest,
  IProposalDeleteResponse,
  IProposalDeleteResult,
  proposalDeleteQuery,
  proposalSentQuery,
  IProposalSentInput,
  IProposalSentRequest,
  IProposalSentResponse,
  IProposalSentResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { proposalTags } from './proposal.tags';
import { ICount, IGraphqlVariables } from 'corede-common';

export const proposalsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // proposalTags: builder.query<
    //     IProposalTagsResult,
    //     IGraphqlVariables<IProposalTagsFilterInput>
    // >(
    //     EndpointQueryBuilder.BuildGraphqlQuery<
    //         IProposalTagsRequest,
    //         IProposalTagsResponse,
    //         IProposalTagsResult,
    //         IProposalTagsFilterInput,
    //     >({
    //         query: proposalTagsQuery,
    //         providesTags: [proposalTags.proposalTags],
    //     })),

    proposalList: builder.query<IProposalListResult, IGraphqlVariables<IProposalListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalListRequest,
        IProposalListResponse,
        IProposalListResult,
        IProposalListInput
      >({
        query: proposalListQuery,
        providesTags: [proposalTags.proposal, proposalTags.proposals],
      }),
    ),

    proposalListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalListCountRequest,
        IProposalListCountResponse,
        ICount,
        undefined
      >({
        query: proposalListCountQuery,
        providesTags: [proposalTags.proposals],
      }),
    ),

    proposalDetail: builder.query<IProposalDetailResult, IGraphqlVariables<IProposalDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalDetailRequest,
        IProposalDetailResponse,
        IProposalDetailResult,
        IProposalDetailInput
      >({
        query: proposalDetailQuery,
        providesTags: [proposalTags.proposal, proposalTags.proposals],
      }),
    ),

    proposalStatistics: builder.query<
      IProposalStatisticsResult,
      IGraphqlVariables<IProposalStatisticsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalStatisticsRequest,
        IProposalStatisticsResponse,
        IProposalStatisticsResult,
        IProposalStatisticsInput
      >({
        query: proposalStatisticsQuery,
        providesTags: [proposalTags.proposal, proposalTags.proposals],
      }),
    ),

    // mutations

    proposalCreate: builder.mutation<
      IProposalCreateResult,
      IGraphqlVariables<IProposalCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalCreateRequest,
        IProposalCreateResponse,
        IProposalCreateResult,
        IProposalCreateInput
      >({
        query: proposalCreateQuery,
        invalidatesTags: [proposalTags.proposal, proposalTags.proposals],
      }),
    ),

    proposalUpdate: builder.mutation<
      IProposalUpdateResult,
      IGraphqlVariables<IProposalUpdateInput, IProposalUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalUpdateRequest,
        IProposalUpdateResponse,
        IProposalUpdateResult,
        IProposalUpdateInput,
        IProposalUpdateFilterInput
      >({
        query: proposalUpdateQuery,
        invalidatesTags: [proposalTags.proposal, proposalTags.proposals],
      }),
    ),

    proposalDelete: builder.mutation<
      IProposalDeleteResult,
      IGraphqlVariables<IProposalDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalDeleteRequest,
        IProposalDeleteResponse,
        IProposalDeleteResult,
        IProposalDeleteInput
      >({
        query: proposalDeleteQuery,
        invalidatesTags: [proposalTags.proposals],
      }),
    ),

    proposalSent: builder.mutation<IProposalSentResult, IGraphqlVariables<IProposalSentInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProposalSentRequest,
        IProposalSentResponse,
        IProposalSentResult,
        IProposalSentInput
      >({
        query: proposalSentQuery,
        invalidatesTags: [proposalTags.proposals],
      }),
    ),
  }),
});

export const {
  useProposalListQuery,
  useLazyProposalListQuery,
  useProposalListCountQuery,
  useLazyProposalListCountQuery,
  useProposalDetailQuery,
  useLazyProposalDetailQuery,
  useProposalStatisticsQuery,
  useLazyProposalStatisticsQuery,
  useProposalCreateMutation,
  useProposalUpdateMutation,
  useProposalDeleteMutation,
  useProposalSentMutation,
} = proposalsApi;
