const locale = {
  expenses: 'Giderler',
  expense: 'Gider',
  expenseConversions: 'Gider Dönüşümleri',
  title: 'Giderler',
  create: 'Gider Oluştur',
  delete: 'Gider Sil',
  update: 'Gider Güncelle',
  deletedSuccessfully: 'Gider başarıyla silindi.',
  createSuccess: 'Gider başarıyla oluşturuldu.',
  updateSuccess: 'Gider başarıyla güncellendi.',
  deleteConfirm: 'Bu gideri silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  emptyState1: 'Henüz eklenmiş gider yok.',
  emptyState2:
    'Faturalandırmanızı kolaylaştırmak ve müşterilerinizle net anlaşmalar sağlamak için tüm gider detaylarını burada saklayın ve yönetin.',
  
  // labels
  id: "Gider ID",
  totalAmount: "Toplam Tutar",
  totalTax: "Toplam Vergi",
  isBillable: "Faturalanabilir",
  isInvoiced: "Faturalandı",
  };

export default locale;
