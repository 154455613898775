/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack, Typography } from '@mui/material';
import { IReminderDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Iconify } from 'components';

export interface IReminderDetailDrawerLeftPanelAssignedsComponentProps {
  reminderDetailData: IReminderDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  visible?: boolean;
}

/**
 * Container for views in assigned list section of left panel header
 */
export const ReminderDetailDrawerLeftPanelAssignedsComponent = (
  props: IReminderDetailDrawerLeftPanelAssignedsComponentProps,
) => {
  const { t } = useTranslation();
  return props.visible ? (
    <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
      <Stack direction={'column'} width={'100%'} gap={0.5}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            color: 'text.primary',
          }}
        >
          {t('crm.calendar.reminder.remindUsers')}
        </Typography>
        <Divider />
      </Stack>
      <Stack direction={'column'} width={'100%'} gap={1}>
        {props.reminderDetailData?.remindUsers &&
          props.reminderDetailData?.remindUsers.length > 0 &&
          props.reminderDetailData?.remindUsers.map((item, index) => (
            <Stack direction={'row'} gap={2} alignItems={'center'} width={'100%'}>
              <Box
                sx={{
                  width: 32,
                  height: 32,
                  bgColor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="fa-solid:user-tie" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Box>
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {item.name} {item.surname} 
                </Typography>
              </Box>
            </Stack>
          ))}
      </Stack>
    </Stack>
  ) : null;
};
