import { InputLabel, TextField } from '@mui/material';
import { IGraphqlVariables } from 'corede-common';
import { HTMLInputTypeAttribute } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

export const DefaultTextFieldInput = (props: {
  inputName: `input.${string}`;
  control: Control<IGraphqlVariables<any, any>>;
  label: string;
  isRequired?: boolean;
  isFirstLetterCapitalize?: boolean;
  errors?: FieldErrors<IGraphqlVariables<any, any>>;
  touchedFields?: Partial<Readonly<any>>;
  type?: HTMLInputTypeAttribute | undefined;
  multiline?: boolean;
  rows?: number;
  value?: unknown;
  minValue?: number;
  disabled?: boolean;
}) => {
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  return (
    <>
      <InputLabel htmlFor={props.inputName} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
        {props.isRequired ? '*' : ''}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <TextField
            {...field}
            id={props.inputName}
            disabled={props.disabled}
            fullWidth
            size="small"
            type={props.type || 'text'}
            multiline={props.multiline}
            rows={props.rows || 1}
            value={
              props.type === 'number'
                ? field.value === 0
                  ? '0'
                  : String(field.value ?? '').replace(/^0+/, '')
                : (props.value ?? field.value)
            }
            onChange={(e) => {
              const inputValue = e.target.value;

              if (props.type === 'number') {
                if (!inputValue) {
                  field.onChange(0);
                } else {
                  const sanitizedValue = inputValue.replace(/^0+/, '');
                  field.onChange(sanitizedValue === '' ? 0 : Number(sanitizedValue));
                }
              } else {
                field.onChange(
                  props.isFirstLetterCapitalize
                    ? inputValue.charAt(0).toUpperCase() + inputValue.slice(1)
                    : inputValue,
                );
              }
            }}
            error={!!getNestedValue(props.errors, props.inputName)}
            helperText={
              getNestedValue(props.touchedFields, props.inputName) &&
              getNestedValue(props.errors, props.inputName)?.message
            }
            inputProps={{
              min: props.type === 'number' ? (props.minValue ?? 0) : undefined,
            }}
            FormHelperTextProps={{
              sx: {
                fontSize: '9px',
                mt: '5px',
              },
            }}
          />
        )}
      />
    </>
  );
};
