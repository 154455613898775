/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Grid, InputLabel, TextField } from '@mui/material';
import { FilledButton, SettingsTitle } from 'components';
import { IGraphqlVariables, unknownError } from 'corede-common';
import { IOrganizationBillingInfoCreateOwnInput, IUserDetailOwnResult } from 'corede-common-cocrm';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useOrganizationBillingInfoCreateOwnMutation,
  useOrganizationBillingInfoUpdateOwnMutation,
  useOrganizationDetailOwnQuery,
} from '../../context/setting.api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  validateBusinessBillingInfoInput,
  validateIndividualBillingInfoInput,
} from '../../validations/billingInfo.validation';
import {
  useCountryListQuery,
  useLazyCityListQuery,
  useLazyStateListQuery,
} from 'apps/auth/context';
import { enqueueSnackbar } from 'notistack';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultNameAutocompleteInput } from 'components/form/DefaultNameAutocompleteInput';
import { RefreshOutlined } from '@mui/icons-material';

enum UserType {
  individual = 'individual',
  business = 'business',
}

export const PaymentBillingInfo = (props: {
  userDetailOwnData: IUserDetailOwnResult | undefined;
}) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [userType, setUserType] = useState<UserType>(UserType.individual);

  const {
    data: organizationDetailOwnData,
    error: organizationDetailOwnError,
    refetch,
  } = useOrganizationDetailOwnQuery({});

  const { data: countryListData, isLoading: countryListLoading } = useCountryListQuery({
    input: {
      pagination: {
        sort: {
          name: 1,
        },
      },
    },
  });
  const [getStateList, { data: stateListData, isLoading: stateListLoading }] =
    useLazyStateListQuery({});
  const [getCityList, { data: cityListData, isLoading: cityListLoading }] = useLazyCityListQuery(
    {},
  );

  const [
    organizationBillingInfoCreateOwn,
    {
      data: organizationBillingInfoCreateOwnData,
      isLoading: organizationBillingInfoCreateOwnLoading,
      error: organizationBillingInfoCreateOwnError,
    },
  ] = useOrganizationBillingInfoCreateOwnMutation();
  const [
    organizationBillingInfoUpdateOwn,
    {
      data: organizationBillingInfoUpdateOwnData,
      isLoading: organizationBillingInfoUpdateOwnLoading,
      error: organizationBillingInfoUpdateOwnError,
    },
  ] = useOrganizationBillingInfoUpdateOwnMutation();

  const billingInfoFormInitialValues = useMemo<IGraphqlVariables<any, any>>(
    () => ({
      input: {
        name: organizationDetailOwnData?.billingInfo?.name ?? '',
        surname: organizationDetailOwnData?.billingInfo?.surname ?? '',
        companyName: organizationDetailOwnData?.billingInfo?.companyName ?? '',
        email: organizationDetailOwnData?.billingInfo?.email ?? '',
        phone: organizationDetailOwnData?.billingInfo?.phone ?? '',
        country: organizationDetailOwnData?.billingInfo?.country ?? '',
        state: organizationDetailOwnData?.billingInfo?.state ?? '',
        city: organizationDetailOwnData?.billingInfo?.city ?? '',
        address: organizationDetailOwnData?.billingInfo?.address ?? '',
        identityNumber: organizationDetailOwnData?.billingInfo?.identityNumber ?? '',
        vat: organizationDetailOwnData?.billingInfo?.vat ?? '',
        taxOffice: organizationDetailOwnData?.billingInfo?.taxOffice ?? '',
        zip: organizationDetailOwnData?.billingInfo?.zip ?? '',
      },
    }),
    [],
  );

  const billingInfoForm = useForm<IGraphqlVariables<IOrganizationBillingInfoCreateOwnInput, any>>({
    defaultValues: billingInfoFormInitialValues,
    resolver: yupResolver(
      userType === UserType.individual
        ? validateIndividualBillingInfoInput
        : validateBusinessBillingInfoInput,
    ),
    mode: 'onChange',
  });

  const billingInfoFormOnSubmit = async (
    values: IGraphqlVariables<IOrganizationBillingInfoCreateOwnInput>,
  ) => {
    organizationDetailOwnData?.billingInfo
      ? organizationBillingInfoUpdateOwn({ input: values.input })
      : organizationBillingInfoCreateOwn({ input: values.input });
  };

  useEffect(() => {
    if (organizationDetailOwnData) {
      billingInfoForm.setValue('input.name', organizationDetailOwnData?.billingInfo?.name ?? '');
      billingInfoForm.setValue(
        'input.surname',
        organizationDetailOwnData?.billingInfo?.surname ?? '',
      );
      billingInfoForm.setValue('input.email', organizationDetailOwnData?.billingInfo?.email ?? '');
      billingInfoForm.setValue('input.phone', organizationDetailOwnData?.billingInfo?.phone ?? '');
      billingInfoForm.setValue(
        'input.identityNumber',
        organizationDetailOwnData?.billingInfo?.identityNumber ?? '',
      );
      billingInfoForm.setValue('input.vat', organizationDetailOwnData?.billingInfo?.vat ?? '');
      billingInfoForm.setValue(
        'input.country',
        organizationDetailOwnData?.billingInfo?.country ?? '',
      );
      billingInfoForm.setValue('input.state', organizationDetailOwnData?.billingInfo?.state ?? '');
      billingInfoForm.setValue('input.city', organizationDetailOwnData?.billingInfo?.city ?? '');
      billingInfoForm.setValue(
        'input.address',
        organizationDetailOwnData?.billingInfo?.address ?? '',
      );
      billingInfoForm.setValue(
        'input.taxOffice',
        organizationDetailOwnData?.billingInfo?.taxOffice ?? '',
      );
      billingInfoForm.setValue('input.zip', organizationDetailOwnData?.billingInfo?.zip ?? '');
      billingInfoForm.setValue(
        'input.companyName',
        organizationDetailOwnData?.billingInfo?.companyName ?? '',
      );
    }
  }, [organizationDetailOwnData]);

  useEffect(() => {
    if (organizationDetailOwnData?.billingInfo?.country) {
      getStateList({
        input: {
          filter: {
            country_name: organizationDetailOwnData?.billingInfo?.country,
          },
        },
      });
    }
  }, [organizationDetailOwnData?.billingInfo?.country]);

  useEffect(() => {
    if (
      organizationDetailOwnData?.billingInfo?.country &&
      organizationDetailOwnData?.billingInfo?.state
    ) {
      getCityList({
        input: {
          filter: {
            country_name: organizationDetailOwnData?.billingInfo?.country,
            state_name: organizationDetailOwnData?.billingInfo?.state,
          },
        },
      });
    }
  }, [
    organizationDetailOwnData?.billingInfo?.country,
    organizationDetailOwnData?.billingInfo?.state,
  ]);

  useEffect(() => {
    if (billingInfoForm.watch('input.country')) {
      const controller = new AbortController();

      getStateList({
        input: {
          filter: {
            country_name: billingInfoForm.watch('input.country'),
          },
        },
      }).catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('State list fetch failed:', error);
        }
      });

      return () => {
        controller.abort();
      };
    }
  }, [billingInfoForm.watch('input.country'), getStateList]);

  useEffect(() => {
    const controller = new AbortController();

    if (billingInfoForm.watch('input.country') && billingInfoForm.watch('input.state')) {
      getCityList({
        input: {
          filter: {
            country_name: billingInfoForm.watch('input.country'),
            state_name: billingInfoForm.watch('input.state'),
          },
        },
      }).catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('City list fetch failed:', error);
        }
      });
    }

    return () => {
      controller.abort();
    };
  }, [billingInfoForm.watch('input.country'), billingInfoForm.watch('input.state'), getCityList]);

  useEffect(() => {
    if (organizationBillingInfoCreateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateBillingInfoSuccess'), {
        variant: 'success',
      });
      refetch();
    }
  }, [organizationBillingInfoCreateOwnData]);

  useEffect(() => {
    if (organizationBillingInfoUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateBillingInfoSuccess'), {
        variant: 'success',
      });
      refetch();
    }
  }, [organizationBillingInfoUpdateOwnData]);

  useEffect(() => {
    if (organizationBillingInfoCreateOwnError) {
      enqueueSnackbar(
        (organizationBillingInfoCreateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationBillingInfoCreateOwnError]);

  useEffect(() => {
    if (organizationBillingInfoUpdateOwnError) {
      enqueueSnackbar(
        (organizationBillingInfoUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationBillingInfoUpdateOwnError]);

  useEffect(() => {
    if (props.userDetailOwnData) {
      billingInfoForm.setValue('input.email', props.userDetailOwnData?.email ?? '');
    }
  }, [props.userDetailOwnData]);

  useEffect(() => {
    if (userType === UserType.business) {
      billingInfoForm.setValue('input.identityNumber', '');
    } else if (userType === UserType.individual) {
      billingInfoForm.setValue('input.companyName', '');
      billingInfoForm.setValue('input.vat', '');
      billingInfoForm.setValue('input.taxOffice', '');
    }
  }, [userType]);

  useEffect(() => {
    if (organizationDetailOwnData?.billingInfo?.identityNumber) {
      setUserType(UserType.individual);
    } else if (organizationDetailOwnData?.billingInfo?.companyName) {
      setUserType(UserType.business);
    } else {
      setUserType(UserType.individual);
    }
  }, [organizationDetailOwnData]);

  useEffect(() => {
    if (
      billingInfoForm.watch('input.country') &&
      billingInfoForm.watch('input.country') !== 'Turkey'
    ) {
      billingInfoForm.setValue('input.identityNumber', '2222222222');
    } else if (
      billingInfoForm.watch('input.country') &&
      billingInfoForm.watch('input.country') === 'Turkey'
    ) {
      billingInfoForm.setValue(
        'input.identityNumber',
        organizationDetailOwnData?.billingInfo?.identityNumber ?? '',
      );
    }
  }, [billingInfoForm.watch('input.country')]);

  useEffect(() => {
    if (organizationDetailOwnError) {
      enqueueSnackbar(
        (organizationDetailOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationDetailOwnError]);

  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t('settings.settings.settings.billingInfo')} />
      <Grid item xs={12} lg={9}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
              {t('settings.settings.settings.userType')}
            </InputLabel>
            <Autocomplete
              noOptionsText={t('noOptionsText')}
              size="small"
              options={Object.values(UserType).map((userType) => ({
                name: getTranslatedEnumValue(userType),
                _id: userType,
              }))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} />}
              value={userType ? { name: getTranslatedEnumValue(userType), _id: userType } : null}
              onChange={(event, newValue) => {
                if (newValue) {
                  setUserType(newValue._id);
                }
              }}
            />
          </Grid>

          {userType === UserType.business && (
            <Grid item xs={12} sm={6}>
              <DefaultTextFieldInput
                control={billingInfoForm.control}
                inputName="input.companyName"
                label={t('settings.settings.settings.companyName')}
                errors={billingInfoForm.formState.errors}
                touchedFields={billingInfoForm.formState.touchedFields}
                isFirstLetterCapitalize
                isRequired
              />
            </Grid>
          )}
          {userType === UserType.business && (
            <Grid item xs={12} sm={6}>
              <DefaultTextFieldInput
                control={billingInfoForm.control}
                inputName="input.taxOffice"
                label={t('settings.settings.settings.taxOffice')}
                errors={billingInfoForm.formState.errors}
                touchedFields={billingInfoForm.formState.touchedFields}
                isFirstLetterCapitalize
                isRequired
              />
            </Grid>
          )}
          {userType === UserType.business && (
            <Grid item xs={12} sm={6}>
              <DefaultTextFieldInput
                control={billingInfoForm.control}
                inputName="input.vat"
                label={t('settings.settings.settings.vat')}
                errors={billingInfoForm.formState.errors}
                touchedFields={billingInfoForm.formState.touchedFields}
                isFirstLetterCapitalize
                isRequired
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <DefaultTextFieldInput
              control={billingInfoForm.control}
              inputName="input.name"
              label={t('settings.settings.settings.name')}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
              isFirstLetterCapitalize
              isRequired
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultTextFieldInput
              control={billingInfoForm.control}
              inputName="input.surname"
              label={t('settings.settings.settings.surname')}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
              isFirstLetterCapitalize
              isRequired
            />
          </Grid>
          {/* 
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.email"
                  label={t('settings.settings.settings.email')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                  disabled
                />
              </Grid> */}
          <Grid item xs={12} sm={6}>
            <DefaultTextFieldInput
              control={billingInfoForm.control}
              inputName="input.phone"
              label={t('settings.settings.settings.phone')}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
              isFirstLetterCapitalize
              isRequired
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultNameAutocompleteInput
              inputName="input.country"
              label={t('settings.settings.settings.country')}
              control={billingInfoForm.control}
              options={countryListData?.data ?? []}
              isRequired
              isLoading={countryListLoading}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
            />
          </Grid>
          {userType === UserType.individual &&
            billingInfoForm.watch('input.country') === 'Turkey' && (
              <Grid item xs={12} sm={6}>
                <DefaultTextFieldInput
                  control={billingInfoForm.control}
                  inputName="input.identityNumber"
                  label={t('settings.settings.settings.identityNumber')}
                  errors={billingInfoForm.formState.errors}
                  touchedFields={billingInfoForm.formState.touchedFields}
                  isFirstLetterCapitalize
                  isRequired
                />
              </Grid>
            )}
          <Grid item xs={12} sm={6}>
            <DefaultNameAutocompleteInput
              inputName="input.state"
              label={t('settings.settings.settings.state')}
              control={billingInfoForm.control}
              options={stateListData?.data ?? []}
              isRequired
              isLoading={stateListLoading}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultNameAutocompleteInput
              inputName="input.city"
              label={t('settings.settings.settings.city')}
              control={billingInfoForm.control}
              options={cityListData?.data ?? []}
              isRequired
              isLoading={cityListLoading}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultTextFieldInput
              control={billingInfoForm.control}
              inputName="input.zip"
              label={t('settings.settings.settings.zip')}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
              isFirstLetterCapitalize
              isRequired
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DefaultTextFieldInput
              control={billingInfoForm.control}
              inputName="input.address"
              label={t('settings.settings.settings.address')}
              errors={billingInfoForm.formState.errors}
              touchedFields={billingInfoForm.formState.touchedFields}
              isFirstLetterCapitalize
              isRequired
              rows={2}
              multiline
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} mt={1}>
        <FilledButton
          leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
          color="secondary"
          loading={
            organizationBillingInfoCreateOwnLoading || organizationBillingInfoUpdateOwnLoading
          }
          title={
            organizationDetailOwnData?.billingInfo
              ? t('settings.settings.settings.save')
              : t('settings.settings.settings.save')
          }
          onClick={billingInfoForm.handleSubmit(billingInfoFormOnSubmit)}
        />
      </Grid>
    </Grid>
  );
};
