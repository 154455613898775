/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { IProjectDetailResult } from 'corede-common-cocrm';
import { ProjectDetailDrawerLeftPanelHeaderTagsComponent } from './ProjectDetailDrawer-leftPanel.header.tags.component';
import { ProjectDetailDrawerLeftPanelActionsComponent } from './ProjectDetailDrawer-leftPanel-header-actions.component';

export interface IProjectDetailDrawerLeftPanelHeaderComponentProps {
  projectDetailData: IProjectDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const ProjectDetailDrawerLeftPanelHeaderComponent = (
  props: IProjectDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* status */}
      {downLg ? null : (
        <ProjectDetailDrawerLeftPanelHeaderTagsComponent status={props.projectDetailData?.status} />
      )}

      {/* Project Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.projectDetailData?.name ?? ''}
      </Typography>

      <ProjectDetailDrawerLeftPanelActionsComponent
        projectDetailData={props.projectDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
