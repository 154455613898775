/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  TicketCategory,
  ITicketDetailResult,
  ProgressCalculationType,
  TaskTargetType,
  DocumentTargetType,
  TicketTargetType,
} from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { DetailDrawerTargetComponent } from 'apps/crm/components/detailDrawer/DetailDrawer-target.component';

export interface ITicketDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<ITicketDetailResult> {
  ticketDetailData: ITicketDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const TicketDetailDrawerTabInfoComponent = (
  props: ITicketDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  const ticket = props.ticketDetailData;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';

  return (
    <Box sx={{ height: maxHeight, overflowY: 'auto' }}>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box mb={2}>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.support.support.ticketInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container gap={3}>
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={1.5}>
                  {/* Prefix*/}
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:tag" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="caption"> {t('crm.subject')}</Typography>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {ticket?.subject || '-'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* Ticket Body */}
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:format-text" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="caption">{t('crm.description')}</Typography>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {ticket?.ticketBody || '-'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {props.ticketDetailData?.target?._id && (
          <DetailDrawerTargetComponent
            targetType={props.ticketDetailData?.targetType as TicketTargetType}
            targetId={props.ticketDetailData?.target?._id ?? ''}
            extraSmallSize={downLg}
            smallSize={!downLg}
          />
        )}
      </Stack>
    </Box>
  );
};
