import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import LegalAgreementListComponent from './LegalAgreementList-grid.component';
import { useLocation } from 'react-router-dom';
import LegalAgreementCreateDrawerWrapper from '../create/LegalAgreementCreateDrawer.wrapper';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useLegalAgreementListQuery } from '../../../context/setting.api';

const LegalAgreementList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openLegalAgreementCreateDrawer, setOpenLegalAgreementCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();

  // queries
  const { data: legalAgreementListCountData, isLoading: legalAgreementListCountLoading } =
    useLegalAgreementListQuery({});

  let totalLegalAgreementCount = legalAgreementListCountData?.count ?? 0;
  let isEmpty = totalLegalAgreementCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenLegalAgreementCreateDrawer(true);
    }
  }, [location]);
 
  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {t("settings.settings.settings.legalAgreements")}
            </Typography>
          </Stack>
        }
        // description={
        //   <Typography variant={downMd ? 'caption' : 'body1'}>
        //     {t(`settings.settings.settings.description`)}
        //   </Typography>
        // }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`settings.settings.settings.create`),
          onClick: () => setOpenLegalAgreementCreateDrawer(true),
        }}
      />

      <LegalAgreementListComponent
        setOpenLegalAgreementCreateDrawer={setOpenLegalAgreementCreateDrawer}
      />

      <LegalAgreementCreateDrawerWrapper
        open={openLegalAgreementCreateDrawer}
        setOpen={setOpenLegalAgreementCreateDrawer}
      />
    </Grid>
  );
});

export default LegalAgreementList;
