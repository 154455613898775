const locale = {
  supports: 'Biletler',
  support: 'Bilet',
  detail: 'Bilet Detayları',
  subject: 'Konu',
  supportConversions: 'Destek Talepleri Dönüşümleri',
  title: 'Biletler',
  create: 'Bilet Oluştur',
  createSuccess: 'Bilet başarıyla oluşturuldu.',
  update: 'Bilet Güncelle',
  updateSuccess: 'Bilet başarıyla güncellendi.',
  delete: 'Bilet Sil',
  deleteSuccess: 'Bilet başarıyla silindi.',
  deleteContent: 'Bilet silmek istediğinize emin misiniz?',
  emptyState1: 'Henüz bilet yok.',
  emptyState2:
    'Herhangi bir destek bildirimi oluşturmadınız veya sorunlar zaten çözüldü. Ekibiniz bir destek isteğinde bulunduğunda tüm biletler burada görünecektir.',

  // detailDrawer
  generalInformation: 'Genel Bilgiler',
  ticketInformation: 'Bilet Bilgileri',
  closedDate: 'Kapanış Tarihi',
  changeStatus: 'Durumu Değiştir',
  statusChangeSuccess: 'Durum başarıyla değiştirildi.',
};

export default locale;
