import { Box, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill-new';

export const DefaultRichTextQuillInput = (props: {
  inputName: `input.${string}`;
  control: Control<any>;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  errors?: any;
  height?: number;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {props.label && (
        <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>
          {props.label}
          {props.isRequired ? '*' : ''}
        </Typography>
      )}
      <Box
        sx={{
          bgcolor: 'background.firstWithBlur',
          borderRadius: 2,
          mt: 0,
          border: '1px solid',
          borderColor: 'grey.400',
        }}
      >
        <Controller
          name={props.inputName}
          control={props.control}
          render={({ field }) => (
            <>
              <ReactQuill
                id={props.inputName}
                className="react-quill"
                theme="snow"
                value={field.value || ''}
                onChange={(content, delta, source, editor) => field.onChange(editor.getHTML())}
                onBlur={field.onBlur}
                placeholder={props.placeholder || t('crm.enterText')}
                style={{ height: props.height ? props.height : 200, boxSizing: 'border-box' }}
              />
            </>
          )}
        />
        {props.errors?.[props.inputName]?.message && (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            {props.errors[props.inputName].message}
          </Typography>
        )}
      </Box>
    </>
  );
};
