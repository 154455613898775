/* eslint-disable react-hooks/exhaustive-deps */
import { useExpenseDetailQuery } from '../../context/expense.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { IExpense, IExpenseDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { ExpenseDetailDrawerLeftPanelComponent } from './ExpenseDetailDrawer-leftPanel.component';
import { ExpenseDetailDrawerRightPanelComponent } from './ExpenseDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IExpenseDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedExpense: IExpenseDetailResult & IHasId<number>;
  setSelectedExpense: Dispatch<SetStateAction<TListGridSelectedEntity<IExpense>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const ExpenseDetailDrawer = memo((props: IExpenseDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: expenseDetailData, error: expenseDetailError } = useExpenseDetailQuery(
    props?.selectedExpense?._id
      ? {
          input: {
            _id: props?.selectedExpense?._id,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(expenseDetailError as IBaseError, currentLanguage);
  }, [expenseDetailError]);

  return (
    <DefaultDetailDrawer<IExpenseDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedExpense}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.expense.expense.title'),
            }
      }
    >
      <ExpenseDetailDrawerLeftPanelComponent
        expenseDetailData={expenseDetailData}
        setOpen={props.setOpen}
      />
      <ExpenseDetailDrawerRightPanelComponent expenseDetailData={expenseDetailData} />
    </DefaultDetailDrawer>
  );
});

export default ExpenseDetailDrawer;
