/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Avatar, Typography, Grid, InputLabel } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { compressImageAndGetUrls } from 'utils/imageUpload';
import UploadImage from 'assets/icons/UploadImage.png';
import { IFileMetadata, unknownError } from 'corede-common';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import ActionDialog from 'components/dialog/ActionDialog';

interface IUploadSignatureImageComponent {
  currentImage?: string;
  setLoading?: (loading: boolean) => void;
  useImageCreateMutation?: any;
  useImageAssignMutation?: any;
  isAssignAuto?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  setImageFile?: (imageFile: IFileMetadata | undefined) => void;
  title?: string;
}

export const UploadSignatureImageComponent = (props: IUploadSignatureImageComponent) => {
  const { t } = useTranslation();
  const [selectedImageFile, setSelectedImageFile] = useState<File | Blob | null>(null);
  const [loading, setLoading] = useState(false);

  const currentLanguage = getCurrentLanguage();

  const [imageCreate, { isLoading: imageCreateLoading, error: imageCreateError }] =
    props.useImageCreateMutation();
  const [
    imageAssign,
    { data: imageAssignData, isLoading: imageAssignLoading, error: imageAssignError },
  ] = props.useImageAssignMutation();

  const handleImageChange = async ({ e, file }: { e?: any; file?: File }) => {
    const imageFile = file ? file : e?.target?.files?.[0];

    if (imageFile) {
      setSelectedImageFile(imageFile);
    }
  };

  const handleSaveLogo = async () => {
    setLoading(true);
    try {
      // Görüntüyü sıkıştır
      const compressedImages = await compressImageAndGetUrls({
        file: selectedImageFile as File,
        quality: 0.6,
        maxWidth: 1000,
        thumbnailQuality: 0.5,
        thumbnailMaxWidth: 200,
      });

      if (!compressedImages) return;

      const { compressedImageFile, compressedImageThumbnail } = compressedImages;

      // Görüntü oluşturma fonksiyonu
      const createImage = async () => {
        const input = {
          tags: [''],
          size: (compressedImageFile as any)?.size * 0.001,
          mimeType: (compressedImageFile as any)?.type,
        };

        return await imageCreate({
          input: input,
        });
      };

      // Görüntü oluşturma ve yükleme
      const response = await createImage();

      if (response?.data?.presignedUrl) {
        const { uploadPresignedUrl, uploadThumbnailPresignedUrl } = response.data.presignedUrl;

        // Yükleme yapılandırmaları
        const uploadConfig = {
          method: 'PUT',
          maxBodyLength: Infinity,
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': (compressedImageFile as any)?.type,
          },
          data: compressedImageFile,
        };

        const uploadThumbnailConfig = {
          ...uploadConfig,
          url: uploadThumbnailPresignedUrl,
          data: compressedImageThumbnail,
        };

        // Dosyaları yükle
        await axios({ ...uploadConfig, url: uploadPresignedUrl });
        await axios(uploadThumbnailConfig);
      }

      if (!response?.data?.fileMetadata) return;

      props.setImageFile?.(response.data.fileMetadata);
      props.isAssignAuto && props.setOpen?.(false);
      props.isAssignAuto && setLoading(false);
      // Görüntüyü bağla

      !props.isAssignAuto &&
        (await imageAssign({
          input: { file: response?.data?.fileMetadata },
        }));

    } catch (error) {
      enqueueSnackbar(
        (error as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    props.setLoading && props.setLoading(imageAssignLoading);
  }, [imageAssignLoading]);

  useEffect(() => {
    if (imageAssignData) {
      enqueueSnackbar(t('imageUploadSuccess'), { variant: 'success' });
      props.setOpen && props.setOpen(false);
    }
  }, [imageAssignData]);

  return (
    <ActionDialog
      open={props.open || false}
      onClose={() => {
        setSelectedImageFile(null);
        props.setOpen && props.setOpen(false);
      }}
      title={props?.title ?? t('account.profile.profile.changeImage')}
      buttonTitle={t('account.profile.profile.change')}
      loading={imageCreateLoading || imageAssignLoading || loading}
      handleClick={() => handleSaveLogo()}
      width={400}
    >
      <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
        <Box
          onClick={() => document.getElementById('profile-image-input')?.click()}
          sx={{
            width: 'calc(100% - 20px)',
            border: '1px dashed',
            borderColor: 'primary.light',
            borderRadius: '16px',
            p: 1,
            flexGrow: 1,
            cursor: 'pointer',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{ width: '100%', height: '100%' }}
          >
            <Avatar
              sx={{
                width: selectedImageFile || props.currentImage ? '60px' : '40px',
                height: selectedImageFile || props.currentImage ? '60px' : '40px',
                padding: selectedImageFile || props.currentImage ? '0px' : '10px',
                backgroundColor: 'background.default',
                objectFit: 'cover',
                resize: 'cover',
              }}
              src={
                selectedImageFile
                  ? URL.createObjectURL(selectedImageFile)
                  : props.currentImage
                    ? props.currentImage
                    : UploadImage
              }
            />
            <Typography variant="body2"> {t('uploadPicture')}</Typography>
          </Stack>
        </Box>
        <input
          id="profile-image-input"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(e) => handleImageChange({ e })}
        />
      </Grid>
    </ActionDialog>
  );
};
