import { useState, lazy, Suspense } from 'react';

const LeadFormUpdateDrawer = lazy(() => import('./LeadFormUpdateDrawer'));

const LeadFormUpdateDrawerWrapper = ({
  open,
  setOpen,
  selectedLeadForm,
}: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <LeadFormUpdateDrawer
            open={open}
            setOpen={setOpen}
            selectedLeadForm={selectedLeadForm}
          />
        </Suspense>
      )}
    </>
  );
};

export default LeadFormUpdateDrawerWrapper;
