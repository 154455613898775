/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { UseFormReturn } from 'react-hook-form';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import { useTaskListByRelatedQuery } from 'apps/crm/domains/11-task/subdomains/task';
import { DefaultDateTimePickerInput } from 'components/form/DefaultDateTimePickerInput';
import { TaskTargetType } from 'corede-common-cocrm';
import { convertUserListToObjectAutoCompleteItem } from 'utils/user.entity.util';
import { useProjectDetailQuery } from '../../context/project.api';

export interface IUpsertTimeSheetComponentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  projectId: string;
  loading?: boolean;
  useForm: UseFormReturn<IGraphqlVariables<any, any>>;
}

export const UpsertTimeSheetComponent = memo((props: IUpsertTimeSheetComponentProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  
  // queries
  const {
    data: taskListData,
    isLoading: taskListLoading,
    error: taskListError,
    refetch
  } = useTaskListByRelatedQuery(
    {
      input: {
        filter: {
          relatedTargetType: TaskTargetType.Project,
          relatedEntityRef: TaskTargetType.Project,
          relatedEntityId: props.projectId,
        },
      },
    },
    { skip: !props.open || !props.projectId },
  );

  const {
    data: projectDetailData,
    isLoading: projectDetailLoading,
    error: projectDetailError,
  } = useProjectDetailQuery(
    {
      input: {
        _id: props.projectId,
      },
    },
    { skip: !props.open || !props.projectId },
  );

  // useEffects.success

  // useEffects.error
  useEffect(() => {
    if (taskListError) {
      DefaultErrorHandlerUseEffect(taskListError as IBaseError, currentLanguage);
    }
  }, [taskListError]);

  // useEffects.init
  useEffect(() => {
    refetch()
  }, [props.projectId]);

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      <Grid item xs={12}>
        <DefaultObjectAutocompleteInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.taskId"
          label={t('crm.taskLabel')}
          options={
            taskListData?.data?.map((task) => ({
              _id: task._id,
              name: task.subject,
            })) ?? []
          }
          isLoading={taskListLoading}
          disableClearable
          touchedFields={props.useForm.formState.touchedFields}
          isRequired
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultObjectAutocompleteInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.memberId"
          label={t('crm.project.project.member')}
          // options={convertUserListToObjectAutoCompleteItem(userListData?.data)}
          options={convertUserListToObjectAutoCompleteItem(projectDetailData?.members)}
          disableClearable
          touchedFields={props.useForm.formState.touchedFields}
          isRequired
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultDateTimePickerInput
          control={props.useForm.control}
          inputName="input.startTime"
          label={t('crm.project.project.startTime')}
          isRequired
          errors={props.useForm.formState.errors}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultDateTimePickerInput
          control={props.useForm.control}
          inputName="input.endTime"
          label={t('crm.project.project.endTime')}
          isRequired
          errors={props.useForm.formState.errors}
          // minDate={props.useForm.watch('input.startTime')}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.duration"
          control={props.useForm.control}
          label={t('crm.project.project.duration')}
          type="number"
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
          isRequired
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultRichTextQuillInput
          inputName="input.note"
          control={props.useForm.control}
          label={t('crm.noteLabel')}
          placeholder={t('crm.noteLabel')}
          errors={props.useForm.formState.errors}
        />
      </Grid>
    </Grid>
  );
});
