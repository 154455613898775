import {
  taskListQuery,
  ITaskListInput,
  ITaskListResponse,
  ITaskListRequest,
  ITaskListResult,
  taskListByRelatedQuery,
  ITaskListByRelatedInput,
  ITaskListByRelatedResponse,
  ITaskListByRelatedRequest,
  ITaskListByRelatedResult,
  taskListOwnQuery,
  ITaskListOwnInput,
  ITaskListOwnResponse,
  ITaskListOwnRequest,
  ITaskListOwnResult,
  taskDetailQuery,
  ITaskDetailInput,
  ITaskDetailResponse,
  ITaskDetailRequest,
  ITaskDetailResult,
  taskStatisticsQuery,
  ITaskStatisticsInput,
  ITaskStatisticsResponse,
  ITaskStatisticsRequest,
  ITaskStatisticsResult,
  taskCreateQuery,
  ITaskCreateInput,
  ITaskCreateResponse,
  ITaskCreateRequest,
  ITaskCreateResult,
  taskUpdateQuery,
  ITaskUpdateInput,
  ITaskUpdateResponse,
  ITaskUpdateRequest,
  ITaskUpdateResult,
  ITaskUpdateFilterInput,
  taskDeleteQuery,
  ITaskDeleteInput,
  ITaskDeleteResponse,
  ITaskDeleteRequest,
  ITaskDeleteResult,
  taskChecklistItemAddQuery,
  ITaskChecklistItemAddInput,
  ITaskChecklistItemAddResponse,
  ITaskChecklistItemAddRequest,
  ITaskChecklistItemAddResult,
  ITaskChecklistItemAddFilterInput,
  ITaskChecklistItemCreateInput,
  ITaskChecklistItemUpdateInput,
  ITaskChecklistItem,
  taskChecklistItemCheckQuery,
  ITaskChecklistItemCheckInput,
  ITaskChecklistItemCheckResponse,
  ITaskChecklistItemCheckRequest,
  ITaskChecklistItemCheckResult,
  taskChecklistItemUnCheckQuery,
  ITaskChecklistItemUnCheckInput,
  ITaskChecklistItemUnCheckResponse,
  ITaskChecklistItemUnCheckRequest,
  ITaskChecklistItemUnCheckResult,
  taskChecklistItemDeleteQuery,
  ITaskChecklistItemDeleteInput,
  ITaskChecklistItemDeleteResponse,
  ITaskChecklistItemDeleteRequest,
  ITaskChecklistItemDeleteResult,
  taskListByStatusQuery,
  ITaskListByStatusRequest,
  ITaskListByStatusInput,
  ITaskListByStatusResponse,
  ITaskListByStatusResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { taskTags } from './task.tags';
import { IGraphqlVariables } from 'corede-common';

export const taskApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    taskList: builder.query<ITaskListResult, IGraphqlVariables<ITaskListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskListRequest,
        ITaskListResponse,
        ITaskListResult,
        ITaskListInput
      >({
        query: taskListQuery(),
        providesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskListByRelated: builder.query<
      ITaskListByRelatedResult,
      IGraphqlVariables<ITaskListByRelatedInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskListByRelatedRequest,
        ITaskListByRelatedResponse,
        ITaskListByRelatedResult,
        ITaskListByRelatedInput
      >({
        query: taskListByRelatedQuery(),
        providesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskListOwn: builder.query<ITaskListOwnResult, IGraphqlVariables<ITaskListOwnInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskListOwnRequest,
        ITaskListOwnResponse,
        ITaskListOwnResult,
        ITaskListOwnInput
      >({
        query: taskListOwnQuery(),
        providesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskDetail: builder.query<ITaskDetailResult, IGraphqlVariables<ITaskDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskDetailRequest,
        ITaskDetailResponse,
        ITaskDetailResult,
        ITaskDetailInput
      >({
        query: taskDetailQuery(),
        providesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskListByStatus: builder.query<
      ITaskListByStatusResult,
      IGraphqlVariables<ITaskListByStatusInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskListByStatusRequest,
        ITaskListByStatusResponse,
        ITaskListByStatusResult,
        ITaskListByStatusInput
      >({
        query: taskListByStatusQuery(),
        providesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskStatistics: builder.query<ITaskStatisticsResult, IGraphqlVariables<ITaskStatisticsInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskStatisticsRequest,
        ITaskStatisticsResponse,
        ITaskStatisticsResult,
        ITaskStatisticsInput
      >({
        query: taskStatisticsQuery,
        providesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    // mutation

    taskCreate: builder.mutation<ITaskCreateResult, IGraphqlVariables<ITaskCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskCreateRequest,
        ITaskCreateResponse,
        ITaskCreateResult,
        ITaskCreateInput
      >({
        query: taskCreateQuery,
        invalidatesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskUpdate: builder.mutation<
      ITaskUpdateResult,
      IGraphqlVariables<ITaskUpdateInput, ITaskUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskUpdateRequest,
        ITaskUpdateResponse,
        ITaskUpdateResult,
        ITaskUpdateInput,
        ITaskUpdateFilterInput
      >({
        query: taskUpdateQuery,
        invalidatesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskKanbanUpdate: builder.mutation<
      ITaskUpdateResult,
      IGraphqlVariables<ITaskUpdateInput, ITaskUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskUpdateRequest,
        ITaskUpdateResponse,
        ITaskUpdateResult,
        ITaskUpdateInput,
        ITaskUpdateFilterInput
      >({
        query: taskUpdateQuery,
      }),
    ),

    taskDelete: builder.mutation<ITaskDeleteResult, IGraphqlVariables<ITaskDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskDeleteRequest,
        ITaskDeleteResponse,
        ITaskDeleteResult,
        ITaskDeleteInput
      >({
        query: taskDeleteQuery,
        invalidatesTags: [taskTags.tasks],
      }),
    ),

    taskChecklistItemAdd: builder.mutation<
      ITaskChecklistItemAddResult,
      IGraphqlVariables<ITaskChecklistItemAddInput, ITaskChecklistItemAddFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskChecklistItemAddRequest,
        ITaskChecklistItemAddResponse,
        ITaskChecklistItemAddResult,
        ITaskChecklistItemAddInput,
        ITaskChecklistItemAddFilterInput
      >({
        query: taskChecklistItemAddQuery,
        invalidatesTags: [taskTags.task, taskTags.tasks],
      }),
    ),

    taskChecklistItemCheck: builder.mutation<
      ITaskChecklistItemCheckResult,
      IGraphqlVariables<ITaskChecklistItemCheckInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskChecklistItemCheckRequest,
        ITaskChecklistItemCheckResponse,
        ITaskChecklistItemCheckResult,
        ITaskChecklistItemCheckInput
      >({
        query: taskChecklistItemCheckQuery,
        invalidatesTags: [taskTags.task, taskTags.tasks],
      }),
    ),
    taskChecklistItemUnCheck: builder.mutation<
      ITaskChecklistItemUnCheckResult,
      IGraphqlVariables<ITaskChecklistItemUnCheckInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskChecklistItemUnCheckRequest,
        ITaskChecklistItemUnCheckResponse,
        ITaskChecklistItemUnCheckResult,
        ITaskChecklistItemUnCheckInput
      >({
        query: taskChecklistItemUnCheckQuery,
        invalidatesTags: [taskTags.task, taskTags.tasks],
      }),
    ),
    taskChecklistItemDelete: builder.mutation<
      ITaskChecklistItemDeleteResult,
      IGraphqlVariables<ITaskChecklistItemDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITaskChecklistItemDeleteRequest,
        ITaskChecklistItemDeleteResponse,
        ITaskChecklistItemDeleteResult,
        ITaskChecklistItemDeleteInput
      >({
        query: taskChecklistItemDeleteQuery,
        invalidatesTags: [taskTags.task, taskTags.tasks],
      }),
    ),
  }),
});

export const {
  useTaskListQuery,
  useTaskListByRelatedQuery,
  useTaskListOwnQuery,
  useTaskDetailQuery,
  useTaskListByStatusQuery,
  useLazyTaskListByStatusQuery,
  useTaskStatisticsQuery,
  useLazyTaskStatisticsQuery,
  useTaskCreateMutation,
  useTaskUpdateMutation,
  useTaskKanbanUpdateMutation,
  useTaskDeleteMutation,
  useTaskChecklistItemAddMutation,
  useTaskChecklistItemCheckMutation,
  useTaskChecklistItemUnCheckMutation,
  useTaskChecklistItemDeleteMutation,
} = taskApi;
