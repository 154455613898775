import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const ContractDomainLayout = () => {
  return (
    <Grid container spacing={3}>
      <Outlet />
    </Grid>
  );
};
