/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Box, Button, Menu, MenuItem, Stack, Typography, useMediaQuery } from '@mui/material';
import { IReminderDetailResult } from 'corede-common-cocrm';
import { ReminderDetailDrawerLeftPanelHeaderPriorityComponent } from './ReminderDetailDrawer-leftPanel.header.priority.component';
import { ReminderDetailDrawerLeftPanelActionsComponent } from './ReminderDetailDrawer-leftPanel-header-actions.component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from '@mui/icons-material';

export interface IReminderDetailDrawerLeftPanelHeaderComponentProps {
  reminderDetailData: IReminderDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const ReminderDetailDrawerLeftPanelHeaderComponent = (
  props: IReminderDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
      sx={{ width: '100%' }}
    >
      {/* Priority */}
      {downLg ? null : (
        <ReminderDetailDrawerLeftPanelHeaderPriorityComponent
          priority={props.reminderDetailData?.priority}
        />
      )}

      {/* Reminder Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.reminderDetailData?.title ?? ''}
      </Typography>

      <ReminderDetailDrawerLeftPanelActionsComponent
        reminderDetailData={props.reminderDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
