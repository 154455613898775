/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, useMediaQuery, Divider } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ITaskDetailResult, TaskTargetType } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { DetailDrawerTargetComponent } from 'apps/crm/components/detailDrawer/DetailDrawer-target.component';
import { DetailDrawerAsigneesComponent } from './TaskDetailDrawer-rightPanel-infoSection-asignees.component';
import { DetailDrawerChecklistComponent } from './TaskDetailDrawer-rightPanel-infoSection-checklist.component';

export interface ITaskDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<ITaskDetailResult> {
  taskDetailData: ITaskDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const TaskDetailDrawerTabInfoComponent = (props: ITaskDetailDrawerTabInfoComponentProps) => {
  const { t } = useTranslation();

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';

  return (
    <Box sx={{ height: maxHeight, overflowY: 'auto' }}>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <DetailDrawerTargetComponent
            targetType={props.taskDetailData?.relatedEntityRef as TaskTargetType}
            targetId={props.taskDetailData?.relatedEntity?._id ?? ''}
          />
        </Box>
      </Stack>

      {/* Task Detail */}
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.task.task.detail')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
        </Box>

        <Grid container spacing={1.5}>
          <Grid item xs={12} sm={3}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Box
                sx={{
                  width: 30,
                  height: 30,
                  bgcolor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icons.TaskOutlined sx={{ color: 'secondary.main', width: 20 }} />
              </Box>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                  {props.taskDetailData?.subject ?? '-'}
                </Typography>
                <Typography variant="caption">{t('crm.task.task.subject')}</Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Stack>
              <Stack direction="row" alignItems="center" gap={2}>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: 'secondary.extraLight',
                    borderRadius: '6px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icons.DescriptionOutlined sx={{ color: 'secondary.main', width: 20 }} />
                </Box>

                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                    {props.taskDetailData?.description ?? '-'}
                  </Typography>
                  <Typography variant="caption">{t('crm.task.task.description')}</Typography>
                </Box>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <DetailDrawerAsigneesComponent taskDetailData={props.taskDetailData} />
      <DetailDrawerChecklistComponent taskDetailData={props.taskDetailData} />
    </Box>
  );
};
