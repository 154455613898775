/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import {
  Grid,
  Stack,
  Box,
  IconButton,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { validateCreateInvoiceInput } from 'apps/crm/domains/06-sale/subdomains/invoice/validations/create.validation';
import { SettingsTitle, FilledButton } from 'components';
import BaseChip from 'components/chip/BaseChip';
import { unknownError } from 'corede-common';
import { enqueueSnackbar } from 'notistack';
import { useState, useMemo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useOrganizationCardCreateOwnMutation,
  useOrganizationCardDeleteOwnMutation,
} from '../../context/setting.api';
import { getCurrentLanguage } from 'localization';
import Cards from 'react-credit-cards-2';
import { IOrganizationCardDetailOwnResult, IOrganizationDetailResult } from 'corede-common-cocrm';
import ActionDialog from 'components/dialog/ActionDialog';
import { validateCreateCardInput } from '../../validations/card.validation';

export const PaymentCards = (props: {
  organizationCardDetailOwnData: IOrganizationCardDetailOwnResult | undefined;
  organizationDetailOwnData: IOrganizationDetailResult | undefined;
}) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  type FocusedField = 'name' | 'number' | 'expiry' | 'cvv' | undefined;

  const [focused, setFocused] = useState<FocusedField>(undefined);
  const [selectedCardToken, setSelectedCardToken] = useState<string | undefined>(undefined);
  const [openCardDelete, setOpenCardDelete] = useState(false);
  const [openCardAdd, setOpenCardAdd] = useState(false);

  const [
    organizationCardCreateOwn,
    {
      data: organizationCardCreateOwnData,
      isLoading: organizationCardCreateOwnLoading,
      error: organizationCardCreateOwnError,
    },
  ] = useOrganizationCardCreateOwnMutation();
  const [
    organizationCardDeleteOwn,
    {
      data: organizationCardDeleteOwnData,
      isLoading: organizationCardDeleteOwnLoading,
      error: organizationCardDeleteOwnError,
    },
  ] = useOrganizationCardDeleteOwnMutation();

  const cardsInitialValues = useMemo(
    () => ({
      input: {
        cardNumber: '',
        cardHolderName: '',
        expireMonth: '',
        expireYear: '',
        cardAlias: '',
      },
    }),
    [],
  );

  const cardsForm = useForm({
    defaultValues: cardsInitialValues,
    resolver: yupResolver(validateCreateCardInput),
    mode: 'onChange',
  });

  const cardsOnSubmit = async (values: any) => {
    organizationCardCreateOwn({
      input: {
        cardNumber: values.input.cardNumber,
        cardHolderName: values.input.cardHolderName,
        expireMonth: values.input.expireMonth,
        expireYear: values.input.expireYear,
        cardAlias: values.input.cardAlias,
      },
    });
  };

  const handleDeleteCard = (cardToken: string) => {
    organizationCardDeleteOwn({
      input: {
        cardToken: cardToken,
      },
    });
  };

  useEffect(() => {
    if (organizationCardCreateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.createCardSuccess'), { variant: 'success' });
      cardsForm.reset();
      setOpenCardAdd(false);
    }
  }, [organizationCardCreateOwnData]);

  useEffect(() => {
    if (organizationCardDeleteOwnData) {
      enqueueSnackbar(t('settings.settings.settings.deleteCardSuccess'), { variant: 'success' });
      setOpenCardDelete(false);
      cardsForm.reset();
    }
  }, [organizationCardDeleteOwnData]);

  useEffect(() => {
    if (organizationCardCreateOwnError) {
      enqueueSnackbar(
        (organizationCardCreateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationCardCreateOwnError]);

  useEffect(() => {
    if (organizationCardDeleteOwnError) {
      enqueueSnackbar(
        (organizationCardDeleteOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    } 
  }, [organizationCardDeleteOwnError]);

  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle
        selectedTabName={t('settings.settings.settings.cards')}
        rightButton={
          props.organizationCardDetailOwnData?.cardDetails &&
          props.organizationCardDetailOwnData?.cardDetails?.length > 0 ? (
            <FilledButton
              leftIcon={<AddOutlined sx={{ mr: 2 }} />}
              color="primary"
              title={t('settings.settings.settings.addCard')}
              onClick={() => {
                setOpenCardAdd(true);
              }}
            />
          ) : undefined
        }
      />

      <Grid item xs={12} lg={9}>
        <Grid container spacing={2}>
          {(!props.organizationCardDetailOwnData ||
            (props.organizationCardDetailOwnData?.cardDetails &&
              props.organizationCardDetailOwnData?.cardDetails?.length === 0)) &&
            props.organizationDetailOwnData && (
              <CardForm
                cardsForm={cardsForm}
                cardsOnSubmit={cardsOnSubmit}
                focused={focused}
                setFocused={setFocused}
                organizationCardCreateOwnLoading={organizationCardCreateOwnLoading}
              />
            )}

          {/* Kartlar listesi */}
          <Grid item xs={12}>
            <Stack
              direction={{ xs: 'column', lg: 'row' }}
              alignItems={{ xs: 'flex-start', lg: 'center' }}
              spacing={2}
            >
              {props.organizationCardDetailOwnData?.cardDetails &&
                props.organizationCardDetailOwnData?.cardDetails?.length > 0 &&
                props.organizationCardDetailOwnData?.cardDetails?.map((card, index) => (
                  <Box key={index} position={'relative'}>
                    <IconButton
                      sx={{
                        position: 'absolute',
                        top: 5,
                        left: 5,
                        zIndex: 1,
                        bgcolor: 'error.main',
                        color: 'error.contrastText',
                      }}
                      onClick={() => {
                        setOpenCardDelete(true);
                        setSelectedCardToken(card.cardToken);
                      }}
                    >
                      <DeleteOutlined />
                    </IconButton>
                    <Cards
                      cvc={'XXX'}
                      expiry={`XX/XX`}
                      name={'XXXXX XXXXX'}
                      number={card.binNumber ?? ''}
                    />
                  </Box>
                ))}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Stack mt={5} direction={'row'} width={'100%'} justifyContent={'center'}>
        <BaseChip
          color="success.main"
          icon="LockOutlined"
          label={t('settings.settings.settings.cardListSecure')}
        />
      </Stack>

      <ActionDialog
        open={openCardAdd}
        onClose={() => setOpenCardAdd(false)}
        title={t('settings.settings.settings.addCard')}
        width={400}
      >
        <CardForm
          cardsForm={cardsForm}
          cardsOnSubmit={cardsOnSubmit}
          focused={focused}
          setFocused={setFocused}
          organizationCardCreateOwnLoading={organizationCardCreateOwnLoading}
          type={'add'}
        />
      </ActionDialog>

      <ActionDialog
        open={openCardDelete}
        setOpen={setOpenCardDelete}
        title={t('settings.settings.settings.deleteCard')}
        width={400}
        handleClick={() => handleDeleteCard(selectedCardToken as string)}
        loading={organizationCardDeleteOwnLoading}
        buttonTitle={t('delete')}
        buttonColor="error"
      >
        <Grid item xs={12}>
          <Typography variant="body2">{t('settings.settings.settings.deleteCardDesc')}</Typography>
        </Grid>
      </ActionDialog>
    </Grid>
  );
};

const CardForm = ({
  cardsForm,
  cardsOnSubmit,
  focused,
  setFocused,
  organizationCardCreateOwnLoading,
  type,
}: any) => {
  const { t } = useTranslation();
  return (
    <>
      {/* Kart Görseli */}
      <Grid item xs={12} md={type === 'add' ? 12 : 6}>
        <Cards
          cvc={cardsForm.watch('input.cardAlias')}
          expiry={`${cardsForm.watch('input.expireMonth')}/${cardsForm.watch('input.expireYear')}`}
          name={cardsForm.watch('input.cardHolderName')}
          number={cardsForm.watch('input.cardNumber')}
          focused={focused}
        />
      </Grid>

      {/* Kart Bilgisi Formu */}
      <Grid item xs={12} md={type === 'add' ? 12 : 6}>
        <form onSubmit={cardsForm.handleSubmit(cardsOnSubmit)}>
          <Grid container spacing={2}>
            {/* Kart Numarası */}
            <Grid item xs={12}>
              <Controller
                name="input.cardNumber"
                control={cardsForm.control}
                rules={{ required: t('settings.settings.settings.requiredCard') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Kart Numarası"
                    variant="outlined"
                    inputProps={{
                      maxLength: 16,
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    onFocus={() => setFocused('number')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Kart Sahibi Adı */}
            <Grid item xs={12}>
              <Controller
                name="input.cardHolderName"
                control={cardsForm.control}
                rules={{ required: t('settings.settings.settings.requiredCardHolder') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="Kart Sahibi Adı"
                    variant="outlined"
                    onFocus={() => setFocused('name')}
                    onChange={(e) => {
                      const uppercasedValue = e.target.value.toUpperCase();
                      field.onChange(uppercasedValue);
                    }}
                    value={field.value}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Son Kullanma Ayı ve Yılı */}
            <Grid item xs={6}>
              <Controller
                name="input.expireMonth"
                control={cardsForm.control}
                rules={{ required: t('settings.settings.settings.requiredExpireMonth') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="(MM)"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    onFocus={() => setFocused('expiry')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="input.expireYear"
                control={cardsForm.control}
                rules={{ required: t('settings.settings.settings.requiredExpireYear') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="(YY)"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    onFocus={() => setFocused('expiry')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* CVV Alanı */}
            <Grid item xs={6}>
              <Controller
                name="input.cardAlias"
                control={cardsForm.control}
                rules={{ required: t('settings.settings.settings.requiredCvv') }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    fullWidth
                    label="CVV"
                    variant="outlined"
                    inputProps={{ maxLength: 3 }}
                    onFocus={() => setFocused('cvv')}
                    {...field}
                    error={!!error}
                    helperText={error ? error.message : ''}
                  />
                )}
              />
            </Grid>

            {/* Gönderim Butonu */}
            {
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={organizationCardCreateOwnLoading}
                >
                  {organizationCardCreateOwnLoading ? <CircularProgress size={'1rem'} /> : null}
                  Kart Oluştur
                </Button>
              </Grid>
            }
          </Grid>
        </form>
      </Grid>
    </>
  );
};
