/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { IProjectDetailResult, PermissionSubject, TaskTargetType } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { AddCircle } from '@mui/icons-material';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { OverlayType } from 'components/dialog/ActionDialog';
import EmptyState from 'components/emptyState/EmptyState';
import {
  representString,
  representHtmlAsString,
  representDateTimeAsString,
} from 'utils/representationHelper';
import { useState } from 'react';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import {
  useProjectMilestoneCreateMutation,
  useProjectMilestoneDeleteMutation,
  useProjectMilestoneUpdateMutation,
} from '../../context/project.api';
import MilestoneCreateOverlay from './create/MilestoneCreateOverlay';
import MilestoneKanbanComponent from './MilestoneKanban.component';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { PermissionAction } from 'corede-common';
import TaskCreateDialog from 'apps/crm/domains/11-task/subdomains/task/pages/create/TaskCreateOverlay';

export interface MilestoneSectionProps extends DefaultTabComponentProps<IProjectDetailResult> {
  projectDetailData: IProjectDetailResult | undefined;
  tabKey: 'milestone';
  name: 'crm.project.project.milestone';
}

export const MilestoneSection = (props: MilestoneSectionProps) => {
  const { t } = useTranslation();
  const [openTaskCreateDialog, setOpenTaskCreateDialog] = useState(false);
  const [openMilestoneCreateDialog, setOpenMilestoneCreateDialog] = useState(false);
  const [openMilestoneUpdateDialog, setOpenMilestoneUpdateDialog] = useState(false);
  const [openMilestoneDeleteDialog, setOpenMilestoneDeleteDialog] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState<any>();

  const milestoneData = props.projectDetailData?.milestones;

  const [
    milestoneCreate,
    { data: milestoneCreateData, isLoading: milestoneCreateLoading, error: milestoneCreateError },
  ] = useProjectMilestoneCreateMutation();

  const [
    milestoneUpdate,
    { data: milestoneUpdateData, isLoading: milestoneUpdateLoading, error: milestoneUpdateError },
  ] = useProjectMilestoneUpdateMutation();

  const [milestoneDelete, { data: milestoneDeleteData, isLoading: milestoneDeleteLoading }] =
    useProjectMilestoneDeleteMutation();

  return (
    <DefaultTabContainer
      title={''}
      rightButton2={
        milestoneData && milestoneData?.length > 0
          ? {
              title: t('crm.project.project.addMilestone'),
              onClick: () => setOpenMilestoneCreateDialog(true),
              icon: <AddCircle sx={{ mr: 1 }} />,
            }
          : undefined
      }
      rightButton={
        milestoneData && milestoneData?.length > 0
          ? {
              title: t('crm.project.project.addTask'),
              onClick: () => setOpenTaskCreateDialog(true),
              icon: <AddCircle sx={{ mr: 1 }} />,
            }
          : undefined
      }
    >
      {milestoneData && milestoneData?.length === 0 && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no task yet."
            />
          }
          content1={t('crm.project.project.emptyStateMilestoneList1')}
          button1={{
            leftIcon: <AddCircle sx={{ mr: 1 }} fontSize="small" />,
            title: t('crm.project.project.addMilestone'),
            onClick: () => setOpenMilestoneCreateDialog(true),
          }}
        />
      )}

      {milestoneData && milestoneData?.length > 0 && (
        <MilestoneKanbanComponent
          setOpenTaskCreateDrawer={setOpenMilestoneCreateDialog}
          projectDetailData={props.projectDetailData}
        />

        // <BaseDataGrid
        //   rows={milestoneData?.map((milestone, index) => ({
        //     id: index + 1,
        //     _id: milestone?._id,
        //     name: representString(milestone?.name),
        //     description: representHtmlAsString({ data: milestone?.description, trimLength: 150 }),
        //     startDate: representDateTimeAsString(milestone?.startDate),
        //     dueDate: representDateTimeAsString(milestone?.dueDate),
        //   }))}
        //   loading={milestoneCreateLoading || milestoneUpdateLoading || milestoneDeleteLoading}
        //   columns={[
        //     {
        //       config: {
        //         baseGridColType: BaseGridColType.text,
        //         column: {
        //           field: 'id',
        //           disableColumnMenu: true,
        //           filterable: false,
        //           width: 40,
        //           align: 'center',
        //         },
        //         headerConfig: {
        //           name: '#',
        //           align: 'center',
        //         },
        //       },
        //     },
        //     {
        //       config: {
        //         baseGridColType: BaseGridColType.text,
        //         column: {
        //           field: 'name',
        //           editable: false,
        //           filterable: false,
        //         },
        //         headerConfig: {
        //           name: t('crm.name'),
        //         },
        //       },
        //     },
        //     {
        //       config: {
        //         baseGridColType: BaseGridColType.text,
        //         column: {
        //           field: 'description',
        //           editable: false,
        //           filterable: false,
        //           width: 250,
        //         },
        //         headerConfig: {
        //           name: t('crm.description'),
        //         },
        //       },
        //     },
        //     {
        //       config: {
        //         baseGridColType: BaseGridColType.text,
        //         column: {
        //           field: 'startDate',
        //           editable: false,
        //           filterable: false,
        //         },
        //         headerConfig: {
        //           name: t('crm.startDate'),
        //         },
        //       },
        //     },
        //     {
        //       config: {
        //         baseGridColType: BaseGridColType.text,
        //         column: {
        //           field: 'dueDate',
        //           editable: false,
        //           filterable: false,
        //         },
        //         headerConfig: {
        //           name: t('crm.dueDate'),
        //         },
        //       },
        //     },
        //   ]}
        //   actionColumn={{
        //     width: 70,
        //     defaultActions: {
        //       edit: {
        //         clickConfig: {
        //           setSelectedRow: setSelectedMilestone,
        //           setOpenAction: setOpenMilestoneUpdateDialog,
        //         },
        //       },
        //       delete: {
        //         clickConfig: {
        //           setSelectedRow: setSelectedMilestone,
        //           setOpenAction: setOpenMilestoneDeleteDialog,
        //         },
        //       },
        //     },
        //     actionHeaderName: t('crm.actions'),
        //   }}
        //   listFilter={{
        //     filterInput: {},
        //     setFilterInput: () => null,
        //   }}
        //   count={milestoneData?.length}
        //   config={{
        //     columnVisibilityModel: {},
        //     features: [],
        //   }}
        //   update={{
        //     updateQuery: milestoneUpdate,
        //   }}
        //   disableColumnFilter={true}
        //   hideFooterPagination={true}
        // />
      )}

      <MilestoneCreateOverlay
        open={openMilestoneCreateDialog}
        setOpen={setOpenMilestoneCreateDialog}
        overlayType={OverlayType.dialog}
        projectId={props.projectDetailData?._id ?? ''}
      />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.task,
          action: PermissionAction.create,
        }}
      >
        <TaskCreateDialog
          open={openTaskCreateDialog}
          setOpen={setOpenTaskCreateDialog}
          targetId={props.projectDetailData?._id ?? ''}
          targetEntityRef={TaskTargetType.Project}
          overlayType={OverlayType.dialog}

        />   
      </PermissionWrapper>

      {/*    <MilestoneUpdateOverlay
        open={openMilestoneUpdateDialog}
        setOpen={setOpenMilestoneUpdateDialog}
        selectedMilestoneId={selectedMilestone?._id}
        overlayType={OverlayType.dialog}
      />

      <MilestoneDeleteDialog
        open={openMilestoneDeleteDialog}
        onClose={() => {
          setSelectedMilestone(undefined);
          setOpenMilestoneDeleteDialog(false);
        }}
        milestoneId={selectedMilestone?._id ?? ''}
        setMilestone={setSelectedMilestone}
      /> */}
    </DefaultTabContainer>
  );
};
