const locale = {
  title: 'Contract Template',
  emptyState1: 'No contract templates yet.',
  emptyState2:
    'Store and manage all contract details here to streamline your invoicing and maintain clear agreements with your customers.',
  create: 'Create Contract Template',
  update: 'Update Contract Template',
  delete: 'Delete Contract Template',
  createSuccess: 'Contract template created successfully.',
  updateSuccess: 'Contract template updated successfully.',
  deleteSuccess: 'Contract template deleted successfully.',
  deleteConfirm: 'Are you sure you want to delete this contract template. This action cannot be undone.',
};

export default locale;
