import { Autocomplete, InputLabel, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface IAutoCompleteInputItem {
  _id: string
  name?: string,
  surname?: string,
  imageUrl?: string
}

export const DefaultArrayObjectAutocompleteInput = (props: {
  inputName: `input.${string}` | `filter.${string}`;
  control: Control<any>;
  label: string;
  isRequired?: boolean;
  options: IAutoCompleteInputItem[];
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <InputLabel htmlFor={props.inputName} sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        {props.label}
        {props.isRequired ? '*' : ''}
      </InputLabel>
      <Controller
        name={props.inputName}
        control={props.control}
        render={({ field }) => (
          <Autocomplete
            noOptionsText={t('noOptionsText')}
            id={props.inputName}
            size="small"
            multiple
            fullWidth
            options={props.options}
            loading={props.loading}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            getOptionLabel={(option) => `${option.name} ${option.surname || ''}`}
            value={
              Array.isArray(field.value)
                ? props.options.filter((opt) => field.value.includes(opt._id))
                : []
            }
            onChange={(event, value) => {
              field.onChange(value.map((v) => v._id));
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder={props.label} />
            )}
          />
        )}
      />
    </>
  );
};
