import { Stack, Typography } from '@mui/material';
import { FilledButton } from 'components/buttons';
import * as Icons from '@mui/icons-material';
import { IHasPermissionCheck } from 'permission/permission.interface';
import { PermissionWrapper } from 'permission/PermissionWrapper';

interface IEmptyState {
  content1: string;
  content2?: string;
  button1?: {
    title: string;
    onClick: () => void;
    leftIcon: JSX.Element;
  } & IHasPermissionCheck;
  button2?: {
    title: string;
    onClick: () => void;
    leftIcon: JSX.Element;
  } & IHasPermissionCheck;
  icon?: JSX.Element;
}

const EmptyState = (props: IEmptyState) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      bgcolor={'background.secondWithBlur'}
      gap={1}
      py={2}
      borderRadius={3}
      width={'100%'}
    >
      {props?.icon ? (
        props.icon
      ) : (
        <Icons.ContentPasteOffOutlined sx={{ opacity: 0.2, fontSize: '100px' }} />
      )}
      <Typography variant="h5" px={2} textAlign={'center'} fontWeight={700} mb={1}>
        {props.content1}
      </Typography>
      <Typography variant="body2" px={2} textAlign={'center'}>
        {props.content2}
      </Typography>
      <Stack direction="row" spacing={1} mt={2}>
        {props.button1 && (
          <PermissionWrapper check={props.button1.permission}>
            <FilledButton
              color="secondary"
              title={props.button1.title}
              onClick={() => props.button1?.onClick()}
              leftIcon={props.button1.leftIcon}
            />
          </PermissionWrapper>
        )}
        {props.button2 && (
          <PermissionWrapper check={props.button2.permission}>
            <FilledButton
              color="secondary"
              title={props.button2.title}
              leftIcon={props.button2.leftIcon}
              onClick={() => props.button2?.onClick()}
            />
          </PermissionWrapper>
        )}
      </Stack>
    </Stack>
  );
};

export default EmptyState;
