/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, useMediaQuery } from '@mui/material';
import { IContractDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { ContractDetailDrawerLeftPanelHeaderComponent } from './ContractDetailDrawer-leftPanel-header.component';
import { ContractDetailDrawerLeftPanelContactDetailComponent } from './ContractDetailDrawer-leftPanel-contactDetail.component';
import { ContractDetailDrawerLeftPanelAssignedsComponent } from './ContractDetailDrawer-leftPanel-assigned.component';

export interface IContractDetailDrawerLeftPanelComponentProps {
  contractDetailData: IContractDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContractDetailDrawerLeftPanelComponent = (
  props: IContractDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <DefaultDetailDrawerLeftPanelComponent<IContractDetailResult>
      entityData={props.contractDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <ContractDetailDrawerLeftPanelHeaderComponent
        contractDetailData={props.contractDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <ContractDetailDrawerLeftPanelContactDetailComponent
        contractDetailData={props.contractDetailData}
        setOpen={props.setOpen}
      />

      {/* Assigned List */}
      <ContractDetailDrawerLeftPanelAssignedsComponent
        contractDetailData={props.contractDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
      <Divider />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
