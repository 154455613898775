const locale = {
  permissions: "Permissions",
  errorFetchingPermissions: "Error fetching permissions",
  allPermissions: "All Permissions",
  all: "All",
  ownPermissionsAlert: "You can't change your own permissions.",
  action: "Action",
  actionScope: "Action Scope",
  updateSuccess: "Permission updated successfully",
};

export default locale;
