import { useState, lazy, Suspense } from 'react';

const ExpenseUpdateDrawer = lazy(() => import('./ExpenseUpdateDrawer'));

const ExpenseUpdateDrawerWrapper = ({
  open,
  setOpen,
  selectedExpense,
}: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ExpenseUpdateDrawer
            open={open}
            setOpen={setOpen}
            selectedExpense={selectedExpense}
          />
        </Suspense>
      )}
    </>
  );
};

export default ExpenseUpdateDrawerWrapper;
