/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Box, Typography, Card } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useRoleListQuery } from '../../context/roles.api';
import { AddRounded } from '@mui/icons-material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { IRoleListItemResult, RoleType } from 'corede-common-cocrm';
import { Iconify } from 'components';
import { RoleDetail } from '../detail/RoleDetail';
import { useNavigate } from 'react-router-dom';
import { createRoleRoute, viewRoleRoute } from '../../../../routes/organizationalChart.base.route';

export const RoleList = ({ type }: { type?: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState<IRoleListItemResult | undefined>();

  const {
    data: roleListData,
    isLoading: roleListLoading,
    error: roleListError,
  } = useRoleListQuery({});

  console.log(roleListData);

  return (
    <Grid item xs={12} p={{ xs: 0, md: type === 'onlyAdd' ? 1 : 3 }}>
      {!selectedRole && <HeaderBase title={t('crm.organizationalChart.role.roles')} />}
      {/* {selectedRole === 'add' && (
        <HeaderBase
          title={t('crm.addRole')}
          showBackButton
          backButtonFunc={() => setSelectedRole(undefined)}
        />
      )}
      {selectedRole && selectedRole !== 'add' && (
        <HeaderBase
          title={selectedRole.name}
          showBackButton
          backButtonFunc={() => setSelectedRole(undefined)}
        />
      )} */}
      <Box sx={{ mt: 2 }} />
      <Grid container spacing={2}>
        {!selectedRole ? (
          roleListData &&
          roleListData?.count > 0 && (
            <>
              {roleListData?.data
                ?.filter((role) => role.type !== RoleType.organizationDefault)
                .map((role) => (
                  <Grid item xs={6} sm={4} lg={3}>
                    <Card
                      onClick={() => navigate(viewRoleRoute(role._id))}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 1,
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                        px: 2,
                        py: 5,
                        cursor: 'pointer',
                      }}
                    >
                      <Iconify
                        icon={'oui:app-users-roles'}
                        width={40}
                        sx={{ color: 'secondary.main' }}
                      />
                      <Typography variant="h6">{role.name}</Typography>
                    </Card>
                  </Grid>
                ))}
              <Grid item xs={6} sm={4} lg={3}>
                <Card
                  onClick={() => navigate(createRoleRoute())}
                  // onClick={() => setSelectedRole('add')}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer',
                    px: 2,
                    py: 5,
                  }}
                >
                  <AddRounded sx={{ fontSize: 50 }} />
                  <Typography variant="h6">
                    {t('crm.organizationalChart.role.addRole')}
                  </Typography>
                </Card>
              </Grid>
            </>
          )
        ) : (
          <RoleDetail />
        )}
      </Grid>
    </Grid>
  );
};
