import { Stack, Box, Typography } from '@mui/material';
import { BarChart, Bar, Tooltip, XAxis, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import { useState, useCallback, memo } from 'react';
import { Language } from 'corede-common';

interface ICustomBarChartItem {
  name: string;
  value1: number;
  value2Format: {
    count: number;
    prefix: string;
    suffix: string;
  };
}

interface ICustomBarChart {
  data: ICustomBarChartItem[];
  title: string;
  subtitle: string;
  theme: string;
  flexBasis: string;
  language: Language
}

const CustomBarChart = memo((props: ICustomBarChart) => {
  const theme = useTheme();
  const palette = theme.palette as Record<string, any>;
  const hexColor = palette[props.theme].lighter;
  const selectedColor = palette[props.theme].main;
  const zeroValueColor = theme.palette.grey[300]; 

  const [activeIndex, setActiveIndex] = useState(0);

  const allValuesZero = props.data.every(item => item.value1 === 0);

  const updatedData = props.data.map((item, index) => ({
    ...item,
    value1: item.value1 === 0 ? 0.1 : item.value1,
    fill: item.value1 === 0 ? zeroValueColor : (index === activeIndex ? selectedColor : hexColor),
    name: item.name || '',
  }));

  const displayValue = (value: number) => (value === 0.1 ? 0 : value);

  const CustomTooltip = useCallback(({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const nameLabel = props.language === 'tr' ? 'İsim' : 'Name';
      const valueLabel = props.language === 'tr' ? 'Değer' : 'Value';
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {`${nameLabel}: ${payload[0].payload.name || ''}`}
          </Typography>
          <Typography variant="body2">{`${valueLabel}: ${displayValue(payload[0].value)}`}</Typography>
        </Box>
      );
    }
    return null;
  }, [props.language]);

  return (
    <Stack
      direction={'column'}
      gap={1}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={props.flexBasis}
      width={'100%'}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {props.subtitle}
        </Typography>
      </Box>

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} mt={'auto'}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {displayValue(props.data[activeIndex].value1)}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '6px',
              bgcolor: `${props.theme}.extraLight`,
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: 400, color: `${props.theme}.main`, px: 1 }}
            >
              {props.data[activeIndex].value2Format.prefix}
              {props.data[activeIndex].value2Format.count ?? 0}
              {props.data[activeIndex].value2Format.suffix}
            </Typography>
          </Box>
        </Box>

        <ResponsiveContainer width={160} height={100}>
          <BarChart
            data={updatedData}
            barGap={10}
            onClick={(e) => setActiveIndex(e?.activeTooltipIndex ?? 1)}
          >
            <XAxis dataKey="name" fontSize={9} tickLine={false} axisLine={false} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value1" fill={hexColor} radius={10} barSize={8} cursor="pointer" />
          </BarChart>
        </ResponsiveContainer>
      </Stack>
    </Stack>
  );
});

export default CustomBarChart;
