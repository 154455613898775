/* eslint-disable react-hooks/exhaustive-deps */
import { IEstimateDetailResult } from 'corede-common-cocrm';
import { EstimateDetailDrawerHeaderStatusComponent } from './EstimateDetailDrawer-rightPanel-header-status.component';
// import { EstimateDetailDrawerBaseInfoComponent } from './EstimateDetailDrawer-rightPanel-header-baseInfo.component';

export interface IEstimateDetailDrawerRightPanelHeaderComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const EstimateDetailDrawerRightPanelHeaderComponent = (
  props: IEstimateDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <EstimateDetailDrawerHeaderStatusComponent estimateDetailData={props.estimateDetailData} />
      {/* <EstimateDetailDrawerBaseInfoComponent estimateDetailData={props.estimateDetailData} /> */}
    </>
  );
};
