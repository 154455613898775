import documentLocale from '../domains/document/locales/tr';

const locale = {
  document: {
    ...documentLocale,
  },
  demoo: 'Teklif İste',
};

export default locale;
