import { Add, Circle, LocationOnOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';
import { AppointmentTargetType, PermissionSubject } from 'corede-common-cocrm';
import { t } from 'i18next';
import { useState } from 'react';
import { PermissionAction } from 'corede-common';
import { useAppointmentListQuery } from 'apps/crm/domains/12-calendar/subdomains/appointment';
import AppointmentCreateDrawer from '../pages/create/AppointmentCreateDrawer';
import moment from 'moment';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface ILastAppointmentComponent {
  targetId: string;
  targetType: AppointmentTargetType;
}

export const LastAppointmentComponent = (props: ILastAppointmentComponent) => {
  const [openAddAppointment, setOpenAddAppointment] = useState(false);

  const { data: appointmentListData } = useAppointmentListQuery({
    input: {
      filter: {
        targetIds: [props.targetId],
        targetTypes: [props.targetType],
      },
      pagination: {
        page: 1,
        pageSize: 1,
      },
    },
  });

  const lastAppointment = appointmentListData?.data?.[0];

  return (
    <Stack direction={'column'} pb={2.5}>
      <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography
          variant={'caption'}
          sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
        >
          {t('crm.appointments')}
        </Typography>
        {lastAppointment && (
          <PermissionWrapper
            check={{
              subject: PermissionSubject.appointment,
              action: PermissionAction.create,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: 'auto',
                height: 32,
                fontSize: { xs: '13px', md: '13px' },
                lineHeight: 1,
                borderRadius: '24px',
              }}
              onClick={() => setOpenAddAppointment(true)}
            >
              <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
              {t('crm.makeAnAppointment')}
            </Button>
          </PermissionWrapper>
        )}
      </Stack>
      {lastAppointment ? (
        <Box
          sx={{
            width: '100%',
            border: '2px solid',
            borderColor: 'grey.400',
            borderRadius: 2,
            p: 2,
            mt: 1,
            boxSizing: 'border-box',
          }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Stack direction={'column'} gap={1}>
                <Typography fontSize={'12px'} fontWeight={600} color={'secondary.main'}>
                  {lastAppointment?.startTime && moment(lastAppointment?.startTime).format('dddd')}
                </Typography>
                <Typography fontSize={'15px'} fontWeight={500} color={'text.primary'}>
                  {lastAppointment?.startTime &&
                    moment(lastAppointment?.startTime).format('DD MMMM YYYY')}
                </Typography>
                <Typography fontSize={'12px'} fontWeight={500} color={'text.secondary'}>
                  {lastAppointment?.startTime &&
                    `${moment(lastAppointment?.startTime).format('HH:mm')} | ${moment(
                      lastAppointment?.endTime,
                    ).format('HH:mm')}`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={8} sx={{ borderLeft: '1px solid', borderColor: 'grey.400', pl: 2 }}>
              <Stack direction={'column'} gap={1}>
                <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                  <Circle color="secondary" sx={{ fontSize: '10px', ml: 0.5 }} />
                  <Typography fontSize={'12px'} fontWeight={400}>
                    {lastAppointment?.title}
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                  <LocationOnOutlined sx={{ fontSize: '15px', color: 'primary.light' }} />
                  <Typography fontSize={'12px'} fontWeight={400}>
                    {lastAppointment?.location ?? '-'}
                  </Typography>
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                  <Avatar
                    alt={lastAppointment?.attendees?.[0]?.name}
                    src={lastAppointment?.attendees?.[0]?.profileImage?.thumbnailPublicUrl}
                    sx={{ width: 15, height: 15 }}
                  />
                  {lastAppointment?.attendees?.[0]?.name ? (
                    <Typography fontSize={'12px'} fontWeight={400}>
                      {lastAppointment?.attendees?.[0]?.name}{' '}
                      {lastAppointment?.attendees?.[0]?.surname}
                    </Typography>
                  ) : (
                    <Typography fontSize={'12px'} fontWeight={400}>
                      {'-'}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            p: 2,
            mt: 1,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            fontSize={'14px'}
            fontWeight={400}
            textAlign={'center'}
            color={'text.secondary'}
          >
            {t('crm.noAppointments')}
          </Typography>

          <PermissionWrapper
            check={{
              subject: PermissionSubject.appointment,
              action: PermissionAction.create,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                width: 'auto',
                height: 40,
                fontSize: { xs: '13px', md: '13px' },
                lineHeight: 1,
                borderRadius: '24px',
              }}
              onClick={() => setOpenAddAppointment(true)}
            >
              {t('crm.makeAnAppointment')}
            </Button>
          </PermissionWrapper>
        </Box>
      )}

      <AppointmentCreateDrawer
        open={openAddAppointment}
        setOpen={setOpenAddAppointment}
        relationType={props.targetType}
        relationId={props.targetId}
        overlayType={'dialog'}
      />
    </Stack>
  );
};
