/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Stack,
  Box,
  TextField,
  Button,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useRoleUpdateMutation, useRoleDetailQuery } from '../../context/roles.api';
import { DeleteOutlineRounded, Update } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { getCurrentLanguage } from 'localization';
import { IBaseError } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useNavigate, useParams } from 'react-router-dom';
import { listRolesRoute } from '../../../../routes/organizationalChart.base.route';
import { PermissionList } from '../../../permission/components/PermissionList';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import RoleDeleteDialog from '../delete/RoleDeleteDialog';
import { IRole } from 'corede-common-cocrm';

export const RoleDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  if (!id) {
    navigate(listRolesRoute());
  }

  const [roleName, setRoleName] = useState<string | undefined>(undefined);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [rolePermissionIds, setRolePermissionIds] = useState<string[]>([]);
  const [openRoleDeleteDialog, setOpenRoleDeleteDialog] = useState(false);

  // queries
  const {
    data: roleData,
    isLoading: roleDetailLoading,
    refetch: roleDetailRefetch,
  } = useRoleDetailQuery({
    input: {
      _id: id || '',
    },
  });

  const [
    roleUpdate,
    { data: roleUpdateData, isLoading: roleUpdateLoading, error: roleUpdateError },
  ] = useRoleUpdateMutation();

  const handleRoleUpdate = () => {
    roleUpdate({
      input: {
        name: roleName !== roleData?.name ? roleName : undefined,
        permissionIds: selectedPermissionIds,
      },
      filter: {
        _id: id!,
      },
    });
  };

  // SUCCESS

  useEffect(() => {
    if (roleUpdateData) {
      roleDetailRefetch();
      enqueueSnackbar(t('crm.organizationalChart.role.roleUpdatedSuccess'), {
        variant: 'success',
      });
    }
  }, [roleUpdateData]);

  // ERRORS

  useEffect(() => {
    DefaultErrorHandlerUseEffect(roleUpdateError as IBaseError, currentLanguage);
  }, [roleUpdateError, currentLanguage]);

  useEffect(() => {
    if (roleData) {
      console.log(roleData, 'roleData');
      setRoleName(roleData.name);
      setRolePermissionIds(roleData.permissions.map((p) => p._id));
    }
  }, [roleData]);

  return (
    <Grid item xs={12} p={{ xs: 0, md: 3 }}>
      {!downMd && (
        <HeaderBase title={t('crm.organizationalChart.role.roleDetail')} showBackButton={true} />
      )}
      <Box sx={{ mt: 2 }} />
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{ width: '100%' }}>
          <Stack direction={'row'} gap={1} alignItems={'center'}>
            <TextField
              required
              size="small"
              placeholder={t('crm.organizationalChart.role.roleName')}
              variant="outlined"
              fullWidth
              margin="normal"
              value={roleName}
              onChange={(e) =>
                setRoleName(e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1))
              }
            />
            <Stack direction={'row'} gap={1}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ height: 42, mt: '16px', mb: '8px', pl: 2, pr: 2 }}
                onClick={handleRoleUpdate}
                disabled={roleUpdateLoading || !roleName || selectedPermissionIds?.length === 0}
              >
                {roleUpdateLoading ? (
                  <CircularProgress size={'1rem'} />
                ) : (
                  <>
                    <Update sx={{ mr: { xs: 0, md: 1 } }} />
                    {!downMd && t('crm.organizationalChart.role.updateRole')}
                  </>
                )}
              </Button>
              <Button
                variant="outlined"
                color="error"
                size="small"
                sx={{ height: 42, mt: '16px', mb: '8px', pl: 2, pr: 2 }}
                onClick={() => {
                  setOpenRoleDeleteDialog(true);
                }}
              >
                <DeleteOutlineRounded sx={{ mr: { xs: 0, md: 1 } }} />
                {!downMd && t('crm.organizationalChart.role.deleteRole')}
              </Button>
            </Stack>
          </Stack>

          <PermissionList
            existingPermissionIds={rolePermissionIds}
            setSelectedPermissionIds={setSelectedPermissionIds}
          />
        </Box>
      </Stack>

      <RoleDeleteDialog
        open={openRoleDeleteDialog}
        item={roleData as IRole}
        onSuccess={() => {
          setOpenRoleDeleteDialog(false);
          navigate(listRolesRoute());
        }}
        onClose={() => {
          setOpenRoleDeleteDialog(false);
        }}
        key={roleData?._id}
      />
    </Grid>
  );
};
