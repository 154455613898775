import {
  Dialog,
  DialogTitle,
  Tooltip,
  IconButton,
  DialogContent,
  Slide,
  InputAdornment,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Iconify } from 'components/iconify';
import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionProps } from '@mui/material/transitions';
import { Stack, styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from 'permission/PermissionContext';
import { SearchDataFactory } from './searchData.factory';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '25px',
    width: '300px',
    height: '45px',
    backgroundColor: theme.palette.background.secondary,
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    padding: '10px 10px',
    fontWeight: 600,
    fontSize: '14px',
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const SearchMainDialogComponent = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { hasPermission } = usePermissions();

  const [searchTerm, setSearchTerm] = useState('');
  const staticList = SearchDataFactory.generateSearchData({
    hasPermission,
    t,
  });

  const popularSearches = SearchDataFactory.generatePopularSearchData({
    searchDataList: staticList,
  });

  const filteredList = staticList.filter((item) =>
    item.tags.some((tag) => tag.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  const displayedList = searchTerm ? filteredList?.slice(0, 10) : popularSearches;

  return (
    <Dialog
      open={props.open}
      onClose={() => props.setOpen(false)}
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-paper': {
          width: 500,
          maxWidth: 500,
          height: '50dvh',
        },
      }}
    >
      <DialogTitle
        bgcolor={'background.paper'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <CustomTextField
          id="outlined-search"
          variant="outlined"
          autoFocus
          placeholder={t('crm.searchPlaceholder')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            bgcolor: 'background.thirdWithBlur',
            '& .MuiInputBase-input': {
              bgcolor: '#00000000',
            },
            '& .MuiOutlinedInput-root': {
              bgcolor: 'background.thirdWithBlur',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'mynaui:search'} width={24} />
              </InputAdornment>
            ),
          }}
        />

        <Tooltip title={t('webNotification.close')}>
          <IconButton sx={{ cursor: 'pointer' }} onClick={() => props.setOpen(false)}>
            <Iconify icon={'material-symbols:close'} />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ mt: 0, py: 2 }}>
        {!searchTerm && (
          <Typography
            fontSize={14}
            color="text.secondary"
            sx={{ mt: 2, textTransform: 'uppercase', pl: 2 }}
            fontWeight={600}
          >
            {t('crm.popularSearch')}
          </Typography>
        )}
        {displayedList.length > 0 ? (
          <List>
            {displayedList.map((item) => (
              <ListItem
                key={item.id}
                button
                onClick={() => {
                  props.setOpen(false);
                  setSearchTerm('');
                  navigate(item.link);
                }}
              >
                <ListItemIcon sx={{ minWidth: 32 }}>
                  <Iconify icon={item.icon} width={24} />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        ) : (
          //  EMPTY STATE
          <Stack alignItems="center" spacing={2} mt={3}>
            <Iconify icon="tabler:file-alert" width={48} sx={{ color: 'text.secondary' }} />
            <Typography variant="h6" fontWeight="bold">
              {t('crm.noResultsFound')} "{searchTerm}"
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t('crm.trySearching')}
            </Typography>
            <Stack spacing={1}>
              {popularSearches.map((item) => (
                <Stack
                  key={item.id}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    props.setOpen(false);
                    setSearchTerm('');
                    navigate(item.link);
                  }}
                >
                  <Iconify icon={item.icon} width={20} />
                  <Typography variant="body1">{item.name}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};