/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Grow, Card, Typography, Divider } from '@mui/material';
import { FilledButton, SettingsTitle } from 'components';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import {
  IOrganizationSettingsUpdateInput,
  LegalAgreementType,
  NotificationChannelType,
  PaymentMethod,
} from 'corede-common-cocrm';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { useLegalAgreementListQuery } from '../../context/setting.api';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import { getTranslatedEnumValue, getTranslatedEnumValues } from 'localization';
import { RefreshOutlined } from '@mui/icons-material';
import {
  useOrganizationSettingsDetailOwnQuery,
  useOrganizationSettingsUpdateOwnMutation,
} from '../../context/setting.api';
import { enqueueSnackbar } from 'notistack';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { DefaultArrayAutocompleteInput } from 'components/form/DefaultArrayAutocompleteInput';
import LegalAgreementCreateDrawerWrapper from '../legalAgreement/create/LegalAgreementCreateDrawer.wrapper';

export const CrmInvoiceTab = (props: { selectedTabName: string }) => {
  const { t } = useTranslation();

  const [openLegalAgreementCreateDrawer, setOpenLegalAgreementCreateDrawer] = useState(false);
  const [selectedLegalAgreementType, setSelectedLegalAgreementType] =
    useState<LegalAgreementType>();

  const { data: organizationSettings } = useOrganizationSettingsDetailOwnQuery({});

  const { data: NDAListData, isLoading: NDAListLoading } = useLegalAgreementListQuery({
    input: {
      filter: { types: [LegalAgreementType.NDA] },
      pagination: {
        sort: { createdAt: 1 },
      },
    },
  });

  const { data: termsAndConditionListData, isLoading: termsAndConditionListLoading } =
    useLegalAgreementListQuery({
      input: {
        filter: { types: [LegalAgreementType.termsAndConditions] },
        pagination: {
          sort: { createdAt: 1 },
        },
      },
    });

  const invoiceFormInitialValues = useMemo<IGraphqlVariables<IOrganizationSettingsUpdateInput>>(
    () => ({
      input: {
        invoice: {
          followUpMailTemplate: {
            subject: {
              en: '',
              tr: '',
            },
            content: {
              en: '',
              tr: '',
            },
            type: NotificationChannelType.email,
            variables: [],
          },
          NDAId: undefined,
          prefix: undefined,
          paymentPrefix: undefined,
          returnPrefix: undefined,
          termsAndConditionsId: undefined,
          paymentMethods: [],
        },
      },
    }),
    [],
  );

  const invoiceForm = useForm<IGraphqlVariables<IOrganizationSettingsUpdateInput>>({
    values: invoiceFormInitialValues,
    mode: 'onSubmit',
  });

  const [
    updateOrganizationSettings,
    {
      data: organizationSettingsUpdateData,
      isLoading: organizationSettingsUpdateOwnLoading,
      error: organizationSettingsUpdateOwnError,
    },
  ] = useOrganizationSettingsUpdateOwnMutation();

  const invoiceFormOnSubmit = async (
    values: IGraphqlVariables<IOrganizationSettingsUpdateInput>,
  ) => {
    updateOrganizationSettings({ input: values.input });
  };

  useEffect(() => {
    if (organizationSettingsUpdateData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
    }
  }, [organizationSettingsUpdateData]);

  useEffect(() => {
    if (organizationSettingsUpdateOwnError) {
      DefaultErrorHandlerUseEffect(organizationSettingsUpdateOwnError as IBaseError, Language.en);
    }
  }, [organizationSettingsUpdateOwnError]);

  useEffect(() => {
    if (organizationSettings) {
      invoiceForm.reset({
        input: {
          invoice: {
            followUpMailTemplate: {
              subject: {
                en: organizationSettings.invoice.followUpMailTemplate?.subject?.en,
                tr: organizationSettings.invoice.followUpMailTemplate?.subject?.tr,
              },
              content: {
                en: organizationSettings.invoice.followUpMailTemplate?.content?.en,
                tr: organizationSettings.invoice.followUpMailTemplate?.content?.tr,
              },
              type: NotificationChannelType.email,
              variables: organizationSettings.invoice.followUpMailTemplate?.variables ?? [],
            },
            NDAId: organizationSettings.invoice?.NDA?._id,
            prefix: organizationSettings.invoice?.prefix,
            paymentPrefix: organizationSettings.invoice?.paymentPrefix,
            returnPrefix: organizationSettings.invoice?.returnPrefix,
            termsAndConditionsId: organizationSettings.invoice?.termsAndConditions?._id,
            paymentMethods: organizationSettings.invoice.paymentMethods,
          },
        },
      });
    }
  }, [organizationSettings]);

  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t('settings.settings.settings.invoice')} />
      <Grid item xs={12}>
        <Grow in={props.selectedTabName === 'settings.settings.settings.invoice'} timeout={500}>
          <Card
            sx={{
              p: 2,
              border: '1px solid',
              bgcolor: 'background.thirdWithBlur',
              borderColor: 'primary.lighter',
            }}
          >
            {/* <Grid container width={{ xs: '100%', md: '50%', lg: '35%' }} spacing={2}> */}
            <Grid container width={{ xs: '100%' }} spacing={2}>
              {true ? (
                <>
                  <Grid item xs={12}>
                    <DefaultTextFieldInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.prefix"
                      label={t('settings.settings.settings.prefix')}
                      errors={invoiceForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DefaultTextFieldInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.paymentPrefix"
                      label={t('settings.settings.settings.paymentPrefix')}
                      errors={invoiceForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DefaultTextFieldInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.returnPrefix"
                      label={t('settings.settings.settings.returnPrefix')}
                      errors={invoiceForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultObjectAutocompleteInput
                      control={invoiceForm.control as any}
                      errors={invoiceForm.formState.errors}
                      inputName="input.invoice.NDAId"
                      label={t('settings.settings.settings.NDA')}
                      options={NDAListData?.data || []}
                      touchedFields={invoiceForm.formState.touchedFields}
                      onAddNewOption={() => {
                        setSelectedLegalAgreementType(LegalAgreementType.NDA);
                        setOpenLegalAgreementCreateDrawer(true);
                      }}
                      isLoading={NDAListLoading}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultObjectAutocompleteInput
                      control={invoiceForm.control as any}
                      errors={invoiceForm.formState.errors}
                      inputName="input.invoice.termsAndConditionsId"
                      label={t('settings.settings.settings.termsAndConditions')}
                      options={termsAndConditionListData?.data || []}
                      touchedFields={invoiceForm.formState.touchedFields}
                      onAddNewOption={() => {
                        setSelectedLegalAgreementType(LegalAgreementType.termsAndConditions);
                        setOpenLegalAgreementCreateDrawer(true);
                      }}
                      isLoading={termsAndConditionListLoading}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DefaultArrayAutocompleteInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.paymentMethods"
                      label={t('settings.settings.settings.paymentMethods')}
                      options={getTranslatedEnumValues(PaymentMethod)}
                      errors={invoiceForm.formState.errors}
                      touchedFields={invoiceForm.formState.touchedFields}
                      placeholder={t('settings.settings.settings.paymentMethods')}
                    />
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Typography fontSize={13} fontWeight={600} gutterBottom>
                      {t('settings.settings.settings.followUpMailTemplate')}
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.followUpMailTemplate.subject.en"
                      label={
                        t('settings.settings.settings.subject') +
                        ` (${getTranslatedEnumValue(Language.en)})`
                      }
                      errors={invoiceForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.followUpMailTemplate.subject.tr"
                      label={
                        t('settings.settings.settings.subject') +
                        ` (${getTranslatedEnumValue(Language.tr)})`
                      }
                      errors={invoiceForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultRichTextQuillInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.followUpMailTemplate.content.en"
                      label={
                        t('settings.settings.settings.content') +
                        ` (${getTranslatedEnumValue(Language.en)})`
                      }
                      errors={invoiceForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultRichTextQuillInput
                      control={invoiceForm.control as any}
                      inputName="input.invoice.followUpMailTemplate.content.tr"
                      label={
                        t('settings.settings.settings.content') +
                        ` (${getTranslatedEnumValue(Language.tr)})`
                      }
                      errors={invoiceForm.formState.errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilledButton
                      leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                      color="secondary"
                      title={t('settings.settings.settings.updateSettings')}
                      onClick={invoiceForm.handleSubmit(invoiceFormOnSubmit)}
                      loading={organizationSettingsUpdateOwnLoading}
                      disabled={organizationSettingsUpdateOwnLoading}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} mb={2}>
                  <EmptyState
                    content1={t('settings.settings.settings.noInvoiceContent1')}
                    content2={t('settings.settings.settings.noInvoiceContent2')}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grow>
      </Grid>

      <LegalAgreementCreateDrawerWrapper
        open={openLegalAgreementCreateDrawer}
        setOpen={setOpenLegalAgreementCreateDrawer}
        selectedType={selectedLegalAgreementType}
      />
    </Grid>
  );
};
