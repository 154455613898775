import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { IContractDetailResult, ProjectStatus } from 'corede-common-cocrm';
import React from 'react';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';

export interface IContractDetailDrawerLeftPanelProps {
  contractDetailData: IContractDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ContractDetailDrawerLeftPanelContactDetailComponent = (
  props: IContractDetailDrawerLeftPanelProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      {/* Genel Bilgiler */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
        {!downLg && (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.contract.contract.generalInfoTitle')}
            </Typography>
            <Divider />
          </Stack>
        )}

        {/* Contract Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.title')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.contractDetailData?.title || '-'}
          </Typography>
        </Stack>

        {/* Status */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.status')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {getTranslatedEnumValue(props.contractDetailData?.status || '')}
          </Typography>
        </Stack>

        {/* Created At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {moment(props.contractDetailData?.createdAt).format('DD/MM/YYYY') || '-'}
          </Typography>
        </Stack>
      </Stack>

      {/* Project Bilgileri */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={4}>
        <Stack direction={'column'} width={'100%'} gap={0.5}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'text.primary',
            }}
          >
            {t('crm.contract.contract.customerInfoTitle')}
          </Typography>
          <Divider />
        </Stack>

        {/* Customer Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.name')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.contractDetailData?.customer?.name || '-'}
          </Typography>
        </Stack>

        {/* Contact Email */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.type')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.contractDetailData?.customer?.type || '-'}
          </Typography>
        </Stack>
      </Stack>

      {/* Project Bilgileri */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={4}>
        <Stack direction={'column'} width={'100%'} gap={0.5}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'text.primary',
            }}
          >
            {t('crm.contract.contract.projectInfoTitle')}
          </Typography>
          <Divider />
        </Stack>

        {/* Project Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.name')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.contractDetailData?.project?.name || '-'}
          </Typography>
        </Stack>

        {/* Project Status */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.status')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {getTranslatedEnumValue(props.contractDetailData?.project?.status as ProjectStatus)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
