import { Grid } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import EstimateListComponent from './EstimateList-grid.component';
import EstimateCreateWrapper from '../create/EstimateCreateDrawer.wrapper';
import { useLocation } from 'react-router-dom';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionAction } from 'corede-common';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { useEstimateListCountQuery } from '../../context/estimate.api';
// import EstimateCreateWrapper from '../EstimateCreateWrapper';

const EstimateList = memo(() => {
  // general
  const { t } = useTranslation();
  const location = useLocation;
  const [openEstimateCreateDrawer, setOpenEstimateCreateDrawer] = useState(false);

  // queries
  const { data: estimateListCountData } = useEstimateListCountQuery({});

  let totalEstimateCount = estimateListCountData?.count ?? 0;
  let isEmpty = totalEstimateCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenEstimateCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.preSale.estimate.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.preSale.estimate.create`),
          onClick: () => setOpenEstimateCreateDrawer(true),
          permission: {
            subject: PermissionSubject.estimate,
            action: PermissionAction.create,
          },
        }}
      />

      <EstimateListComponent setOpenEstimateCreateDrawer={setOpenEstimateCreateDrawer} />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.estimate,
          action: PermissionAction.create,
        }}
      >
        <EstimateCreateWrapper
          open={openEstimateCreateDrawer}
          setOpen={setOpenEstimateCreateDrawer}
        />
      </PermissionWrapper>
    </Grid>
  );
});

export default EstimateList;
