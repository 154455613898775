import {
  expenseListQuery,
  IExpenseListRequest,
  IExpenseListInput,
  IExpenseListResponse,
  IExpenseListResult,
  expenseListOwnQuery,
  IExpenseListOwnRequest,
  IExpenseListOwnInput,
  IExpenseListOwnResponse,
  IExpenseListOwnResult,
  expenseDetailQuery,
  IExpenseDetailRequest,
  IExpenseDetailInput,
  IExpenseDetailResponse,
  IExpenseDetailResult,
  expenseCreateQuery,
  IExpenseCreateRequest,
  IExpenseCreateInput,
  IExpenseCreateResponse,
  IExpenseCreateResult,
  expenseUpdateQuery,
  IExpenseUpdateRequest,
  IExpenseUpdateInput,
  IExpenseUpdateResponse,
  IExpenseUpdateResult,
  IExpenseUpdateFilterInput,
  expenseDeleteQuery,
  IExpenseDeleteRequest,
  IExpenseDeleteInput,
  IExpenseDeleteResponse,
  IExpenseDeleteResult,

} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { expenseTags } from './expense.tags';
import { IGraphqlVariables } from 'corede-common';

export const expensesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    expenseList: builder.query<IExpenseListResult, IGraphqlVariables<IExpenseListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseListRequest,
        IExpenseListResponse,
        IExpenseListResult,
        IExpenseListInput
      >({
        query: expenseListQuery(),
        providesTags: [expenseTags.expenses],
      }),
    ),

    expenseListOwn: builder.query<IExpenseListOwnResult, IGraphqlVariables<IExpenseListOwnInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseListOwnRequest,
        IExpenseListOwnResponse,
        IExpenseListOwnResult,
        IExpenseListOwnInput
      >({
        query: expenseListOwnQuery(),
        providesTags: [expenseTags.expenses],
      }),
    ),

    expenseDetail: builder.query<IExpenseDetailResult, IGraphqlVariables<IExpenseDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseDetailRequest,
        IExpenseDetailResponse,
        IExpenseDetailResult,
        IExpenseDetailInput
      >({
        query: expenseDetailQuery(),
        providesTags: [expenseTags.expense],
      }),
    ),

    // mutation
    expenseCreate: builder.mutation<IExpenseCreateResult, IGraphqlVariables<IExpenseCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseCreateRequest,
        IExpenseCreateResponse,
        IExpenseCreateResult,
        IExpenseCreateInput
      >({
        query: expenseCreateQuery,
        invalidatesTags: [expenseTags.expenses],
      }),
    ),

    expenseUpdate: builder.mutation<
      IExpenseUpdateResult,
      IGraphqlVariables<IExpenseUpdateInput, IExpenseUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseUpdateRequest,
        IExpenseUpdateResponse,
        IExpenseUpdateResult,
        IExpenseUpdateInput,
        IExpenseUpdateFilterInput
      >({
        query: expenseUpdateQuery,
        invalidatesTags: [expenseTags.expenses],
      }),
    ),

    expenseDelete: builder.mutation<IExpenseDeleteResult, IGraphqlVariables<IExpenseDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExpenseDeleteRequest,
        IExpenseDeleteResponse,
        IExpenseDeleteResult,
        IExpenseDeleteInput
      >({
        query: expenseDeleteQuery,
        invalidatesTags: [expenseTags.expenses],
      }),
    ),
  }),
});

export const {
  useExpenseListQuery,
  useLazyExpenseListQuery,
  useExpenseListOwnQuery,
  useLazyExpenseListOwnQuery,
  useExpenseDetailQuery,
  useLazyExpenseDetailQuery,
  useExpenseCreateMutation,
  useExpenseUpdateMutation,
  useExpenseDeleteMutation,
} = expensesApi;
