import { getTranslatedText } from 'localization';
import { ValidationHelper } from 'validations/validation.helper';
import * as yup from 'yup';

export const validateCreateTasksInput = ValidationHelper.BuildObjectValidationSchema({
  input: ValidationHelper.BuildObjectValidationSchema({
    subject: ValidationHelper.RequiredString(getTranslatedText('subjectIsRequired')),
    description: ValidationHelper.RequiredString(getTranslatedText('descriptionIsRequired')),
    relatedEntityRef: ValidationHelper.RequiredString(
      getTranslatedText('relatedEntityRefIsRequired'),
    ),
    relatedEntityId: ValidationHelper.RequiredString(
      getTranslatedText('relatedEntityIdIsRequired'),
    ),
  }),
});
