import { baseLeadRoute, importLeadRoute } from 'apps/crm/domains/03-lead/routes/lead.base.route';
import {
  baseCustomerRoute,
  importCustomerRoute,
} from 'apps/crm/domains/04-customer/routes/customer.base.route';
import {
  listEstimatesRoute,
  listProposalsRoute,
} from 'apps/crm/domains/05-preSale/routes/preSales.base.route';
import {
  listInvoicesRoute,
  listPaymentsRoute,
  listReturnsRoute,
} from 'apps/crm/domains/06-sale/routes/sales.base.route';
import { baseContractRoute } from 'apps/crm/domains/07-contract/routes/contract.base.route';
import {
  listProductsRoute,
  listServicesRoute,
} from 'apps/crm/domains/08-productAndService/routes/productsAndServices.base.route';
import { baseExpenseRoute } from 'apps/crm/domains/09-expense/routes/expense.base.route';
import { baseProjectsRoute } from 'apps/crm/domains/10-project/routes/projects.base.route';
import { baseTasksRoute } from 'apps/crm/domains/11-task/routes/tasks.base.route';
import {
  listAppointmentsRoute,
  listRemindersRoute,
} from 'apps/crm/domains/12-calendar/routes/calendar.base.route';
import { baseNotesRoute } from 'apps/crm/domains/14-note/routes/notes.base.route';
import { baseSupportRoute } from 'apps/crm/domains/15-support/routes/support.base.route';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { TFunction } from 'i18next';
import { THasPermissionFunc } from 'permission/PermissionContext';

export interface ISearchData {
  id: string;
  name: string;
  icon: string;
  tags: string[];
  link: string;
}

export class SearchDataFactory {
  static generateSearchData(params: {
    hasPermission: THasPermissionFunc;
    t: TFunction<'translation', undefined>;
  }): ISearchData[] {
    const searchDataList = [];
    if (
      params.hasPermission({
        subject: PermissionSubject.lead,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'leadCreate',
        name: params.t('crm.leadCreate'),
        icon: 'material-symbols-light:social-leaderboard-outline-rounded',
        tags: ['lead', 'potansiyel müşteri', ...createKeyWords],
        link: getCreateRoute(baseLeadRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.lead,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'leadList',
        name: params.t('crm.leadList'),
        icon: 'material-symbols-light:social-leaderboard-outline-rounded',
        tags: ['lead', 'potansiyel müşteri', ...listKeyWords],
        link: baseLeadRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.lead,
        action: PermissionAction.import,
      })
    )
      searchDataList.push({
        id: 'leadImport',
        name: params.t('crm.leadImport'),
        icon: 'material-symbols-light:social-leaderboard-outline-rounded',
        tags: ['lead', 'potansiyel müşteri', ...importKeyWords],
        link: importLeadRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.customer,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'customerCreate',
        name: params.t('crm.customerCreate'),
        icon: 'solar:user-rounded-linear',
        tags: ['customer', 'müşteri', ...createKeyWords, ...createKeyWords],
        link: getCreateRoute(baseCustomerRoute()),
      });

    if (
      params.hasPermission({
        subject: PermissionSubject.customer,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'customerList',
        name: params.t('crm.customerList'),
        icon: 'solar:user-rounded-linear',
        tags: ['customer', 'müşteri', ...listKeyWords],
        link: baseCustomerRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.customer,
        action: PermissionAction.import,
      })
    )
      searchDataList.push({
        id: 'customerImport',
        name: params.t('crm.customerImport'),
        icon: 'material-symbols-light:social-leaderboard-outline-rounded',
        tags: ['customer', 'müşteri', ...importKeyWords],
        link: importCustomerRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.estimate,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'estimateCreate',
        name: params.t('crm.estimateCreate'),
        icon: 'mage:preview',
        tags: ['estimate', 'teklif', ...createKeyWords],
        link: getCreateRoute(listEstimatesRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.estimate,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'estimateList',
        name: params.t('crm.estimateList'),
        icon: 'mage:preview',
        tags: ['estimate', 'teklif', ...listKeyWords],
        link: listEstimatesRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.proposal,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'proposalCreate',
        name: params.t('crm.proposalCreate'),
        icon: 'mage:preview',
        tags: ['proposal', 'öneri', ...createKeyWords],
        link: getCreateRoute(listProposalsRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.proposal,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'proposalList',
        name: params.t('crm.proposalList'),
        icon: 'mage:preview',
        tags: ['proposal', 'öneri', ...listKeyWords],
        link: listProposalsRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.invoice,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'invoiceCreate',
        name: params.t('crm.invoiceCreate'),
        icon: 'solar:sale-broken',
        tags: ['invoice', 'fatura', ...createKeyWords],
        link: getCreateRoute(listInvoicesRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.invoice,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'invoiceList',
        name: params.t('crm.invoiceList'),
        icon: 'solar:sale-broken',
        tags: ['invoice', 'fatura', ...listKeyWords],
        link: listInvoicesRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.invoicePayment,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'paymentList',
        name: params.t('crm.paymentList'),
        icon: 'solar:sale-broken',
        tags: ['payment', 'ödeme', ...basicListKeyWords],
        link: getCreateRoute(listPaymentsRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.invoicePayment,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'paymentList',
        name: params.t('crm.paymentList'),
        icon: 'solar:sale-broken',
        tags: ['payment', 'ödeme', ...basicListKeyWords],
        link: listPaymentsRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.invoiceReturn,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'returnList',
        name: params.t('crm.returnList'),
        icon: 'solar:sale-broken',
        tags: ['return', 'iade', ...basicListKeyWords],
        link: getCreateRoute(listReturnsRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.invoiceReturn,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'returnList',
        name: params.t('crm.returnList'),
        icon: 'solar:sale-broken',
        tags: ['return', 'iade', ...basicListKeyWords],
        link: listReturnsRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.contract,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'contractCreate',
        name: params.t('crm.contractCreate'),
        icon: 'teenyicons:contract-outline',
        tags: ['contract', 'sözleşme', ...createKeyWords],
        link: getCreateRoute(baseContractRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.contract,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'contractList',
        name: params.t('crm.contractList'),
        icon: 'teenyicons:contract-outline',
        tags: ['contract', 'sözleşme', ...createKeyWords],
        link: baseContractRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.product,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'productList',
        name: params.t('crm.productCreate'),
        icon: 'fluent-mdl2:product-catalog',
        tags: ['product', 'ürün', ...basicListKeyWords],
        link: getCreateRoute(listProductsRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.product,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'productList',
        name: params.t('crm.productList'),
        icon: 'fluent-mdl2:product-catalog',
        tags: ['product', 'ürün', ...basicListKeyWords],
        link: listProductsRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.product,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'serviceCreate',
        name: params.t('crm.serviceCreate'),
        icon: 'fluent-mdl2:product-catalog',
        tags: ['service', 'hizmet', ...createKeyWords],
        link: listServicesRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.product,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'serviceCreate',
        name: params.t('crm.serviceCreate'),
        icon: 'fluent-mdl2:product-catalog',
        tags: ['service', 'hizmet', ...createKeyWords],
        link: getCreateRoute(listServicesRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.expense,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'expenseCreate',
        name: params.t('crm.expenseCreate'),
        icon: 'icon-park-outline:expenses',
        tags: ['expense', 'masraf', ...createKeyWords],
        link: getCreateRoute(baseExpenseRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.expense,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'expenseList',
        name: params.t('crm.expenseList'),
        icon: 'icon-park-outline:expenses',
        tags: ['expense', 'masraf', ...basicListKeyWords],
        link: baseExpenseRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.project,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'projectCreate',
        name: params.t('crm.projectCreate'),
        icon: 'streamline:projector-board',
        tags: ['project', 'list', ...createKeyWords],
        link: getCreateRoute(baseProjectsRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.project,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'projectList',
        name: params.t('crm.projectList'),
        icon: 'streamline:projector-board',
        tags: ['project', 'list', ...basicListKeyWords],
        link: baseProjectsRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.task,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'taskCreate',
        name: params.t('crm.taskCreate'),
        icon: 'carbon:product',
        tags: ['task', 'görev', ...createKeyWords],
        link: getCreateRoute(baseTasksRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.task,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'taskList',
        name: params.t('crm.taskList'),
        icon: 'carbon:product',
        tags: ['task', 'görev', ...basicListKeyWords],
        link: baseTasksRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.reminder,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'reminderCreate',
        name: params.t('crm.reminderCreate'),
        icon: 'solar:calendar-linear',
        tags: ['reminder', 'hatırlatma', ...createKeyWords],
        link: getCreateRoute(listRemindersRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.reminder,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'reminderList',
        name: params.t('crm.reminderList'),
        icon: 'solar:calendar-linear',
        tags: ['reminder', 'hatırlatma', ...basicListKeyWords],
        link: listRemindersRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.appointment,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'appointmentCreate',
        name: params.t('crm.appointmentCreate'),
        icon: 'solar:calendar-linear',
        tags: ['appointment', 'randevu', ...createKeyWords],
        link: getCreateRoute(listAppointmentsRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.appointment,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'appointmentList',
        name: params.t('crm.appointmentList'),
        icon: 'solar:calendar-linear',
        tags: ['appointment', 'randevu', ...basicListKeyWords],
        link: listAppointmentsRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.note,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'noteCreate',
        name: params.t('crm.noteCreate'),
        icon: 'hugeicons:task-01',
        tags: ['note', 'not', ...createKeyWords],
        link: getCreateRoute(baseNotesRoute()),
      });

    if (
      params.hasPermission({
        subject: PermissionSubject.note,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'noteList',
        name: params.t('crm.noteList'),
        icon: 'hugeicons:task-01',
        tags: ['note', 'not', ...basicListKeyWords],
        link: baseNotesRoute(),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.ticket,
        action: PermissionAction.create,
      })
    )
      searchDataList.push({
        id: 'ticketCreate',
        name: params.t('crm.ticketCreate'),
        icon: 'f7:tickets',
        tags: ['ticket', 'talep', ...createKeyWords],
        link: getCreateRoute(baseSupportRoute()),
      });
    if (
      params.hasPermission({
        subject: PermissionSubject.ticket,
        action: PermissionAction.list,
      })
    )
      searchDataList.push({
        id: 'ticketList',
        name: params.t('crm.ticketList'),
        icon: 'f7:tickets',
        tags: ['ticket', 'talep', ...basicListKeyWords],
        link: baseSupportRoute(),
      });

    return searchDataList;
  }

  static generatePopularSearchData(params: { searchDataList: ISearchData[] }): ISearchData[] {
    const popularSearches = params.searchDataList.filter(
      (item) =>
        item.id === 'taskCreate' ||
        item.id === 'customerList' ||
        item.id === 'leadCreate' ||
        item.id === 'noteCreate',
    );

    return popularSearches;
  }
}

function getCreateRoute(baseRoute: string): string {
  return baseRoute + '#create';
}

const createKeyWords: string[] = [
  'create',
  'oluştur',
  'add',
  'ekle',
  'new',
  'yeni',
  'oluşturma',
  'başlat',
  'initiate',
];
const listKeyWords: string[] = [
  'listele',
  'list',
  'istatistik',
  'güncelle',
  'update',
  'sil',
  'delete',
  'indir',
  'export',
  'download',
  'view',
  'görüntüle',
  'listeleme',
  'filter',
  'sırala',
  'sort',
  'ara',
  'search',
  'remove',
  'düzenle',
  'edit',
];
const basicListKeyWords: string[] = [
  'list',
  'listele',
  'iade',
  'view',
  'görüntüle',
  'update',
  'güncelle',
  'export',
  'download',
  'remove',
  'sil',
  'düzenle',
  'edit',
];
const importKeyWords: string[] = [
  'import',
  'içe aktar',
  'yükle',
  'aktarma',
  'data',
  'veri',
  'transfer',
  'dışarıdan al',
  'upload',
  'import data',
];
