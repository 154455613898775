/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Button } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceStatus, IInvoiceDetailResult, PermissionSubject } from 'corede-common-cocrm';
import React from 'react';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { IBaseError, PermissionAction } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import ActionDialog from 'components/dialog/ActionDialog';
import { useInvoiceSentMutation } from '../../context/invoice.api';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IInvoiceDetailDrawerLeftPanelActionsComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * contains actions: add appointment, convert invoice to customer
 */
export const InvoiceDetailDrawerLeftPanelActionsComponent = (
  props: IInvoiceDetailDrawerLeftPanelActionsComponentProps,
) => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [openSure, setOpenSure] = React.useState(false);
  const status = props.invoiceDetailData?.status;

  const [
      invoiceSent,
      { data: invoiceSentData, isLoading: invoiceSentLoading, error: invoiceSentError },
    ] = useInvoiceSentMutation();
  
    const handleSend = () => {
      invoiceSent({
        input: {
          _id: props.invoiceDetailData?._id ?? '',
        },
      });
    };
  
    useEffect(() => {
      if (invoiceSentData) {
        enqueueSnackbar(t('crm.sale.invoice.sentSuccessfully'), {
          variant: 'success',
        });
        setOpenSure(false);
      }
    }, [invoiceSentData]);
  
    useEffect(() => {
      if (invoiceSentError) {
        DefaultErrorHandlerUseEffect(invoiceSentError as IBaseError, currentLanguage);
        setOpenSure(false);
      }
    }, [invoiceSentError]);
    

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      {/* Invoice base actions */}
      {/* TODO: If there is no permissions we are not loading below views, is it ok? */}
      <PermissionWrapper
        check={{
          subject: PermissionSubject.invoice,
          action: PermissionAction.update,
        }}
      >
        <Button
          onClick={() => setOpenSure(true)}
          color="primary"
          variant="contained"
          disabled={status !== InvoiceStatus.draft}
          sx={{
            height: 40,
            fontSize: '13px',
            borderRadius: 100,
          }}
        >
          <Icons.RefreshRounded sx={{ mr: 1 }} />
          {t('crm.sale.invoice.send')}
        </Button>
      
        <ActionDialog
          open={openSure}
          setOpen={setOpenSure}
          title={t('crm.sale.invoice.sendSureTitle')}
          buttonTitle={t('crm.sale.invoice.send')}
          loading={invoiceSentLoading}
          handleClick={() => {
            handleSend();
          }}
        >
          <p>{t('crm.sale.invoice.sendSureDesc')}</p>
        </ActionDialog>
      </PermissionWrapper>
    </Stack>
  );
};
