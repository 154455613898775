/* eslint-disable react-hooks/exhaustive-deps */
import { IExpenseDetailResult } from 'corede-common-cocrm';
// import { ExpenseDetailDrawerBaseInfoComponent } from './ExpenseDetailDrawer-rightPanel-header-baseInfo.component';

export interface IExpenseDetailDrawerRightPanelHeaderComponentProps {
  expenseDetailData: IExpenseDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ExpenseDetailDrawerRightPanelHeaderComponent = (
  props: IExpenseDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      {/* <ExpenseDetailDrawerHeaderStatusComponent expenseDetailData={props.expenseDetailData} /> */}
      {/* <ExpenseDetailDrawerBaseInfoComponent expenseDetailData={props.expenseDetailData} /> */}
    </>
  );
};
