/* eslint-disable react-hooks/exhaustive-deps */
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  DocumentTargetType,
  IEstimateDetailResult,
  NoteTargetType,
  PermissionSubject,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { EstimateDetailDrawerTabInfoComponent } from './EstimateDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useEstimateDocumentAddManyMutation,
  useEstimateDocumentCreateManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { EstimateDetailDrawerTabAdditionalInfoComponent } from './EstimateDetailDrawer-tab-additionalInfoSection.component';
// import {
//   useEstimateDocumentCreateManyMutation,
//   useEstimateDocumentAddManyMutation,
// } from 'apps/disk/domains/document/subdomains/document';
import { Iconify } from 'components';
import { PermissionAction } from 'corede-common';

export interface IEstimateDetailDrawerRightPanelTabsComponentProps {
  estimateDetailData: IEstimateDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const EstimateDetailDrawerRightPanelTabsComponent = (
  props: IEstimateDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  const icons = [
    <Iconify icon="fluent:info-32-regular" width={18} />,
    <Iconify icon="hugeicons:contact" width={18} />,
    <Iconify icon="hugeicons:task-01" width={18} />,
    <Iconify icon="carbon:product" width={18} />,
    <Iconify icon="fluent:document-32-regular" width={18} />,
    <Iconify icon="iconoir:bell" width={18} />,
    <Iconify icon="tabler:activity" width={18} />,
  ] as React.ReactElement[];

  return (
    <DefaultTabsComponent<IEstimateDetailResult>
      entityData={props.estimateDetailData}
      icons={icons}
    >
      <EstimateDetailDrawerTabInfoComponent
        estimateDetailData={props.estimateDetailData}
        tabKey="info"
        name="crm.info"
      />

      <EstimateDetailDrawerTabAdditionalInfoComponent
        estimateDetailData={props.estimateDetailData}
        tabKey="additionalInfo"
        name="crm.additionalInfo"
      />

      <TaskListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        targetEntityRef={TaskTargetType.Estimate}
        name="crm.tasks"
        tabKey="tasks"
        permission={{
          subject: PermissionSubject.task,
          action: PermissionAction.list,
        }}
      />

      <DocumentListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        targetEntityRef={DocumentTargetType.Estimate}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useEstimateDocumentCreateManyMutation}
        useDocumentAddManyMutation={useEstimateDocumentAddManyMutation}
        permission={{
          subject: PermissionSubject.file,
          action: PermissionAction.list,
        }}
      />

      <NoteListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Estimate}
        targetDisplayName={props.estimateDetailData?.estimateId.toString() ?? ''}
        tabKey="notes"
        name="crm.notes"
        permission={{
          subject: PermissionSubject.note,
          action: PermissionAction.list,
        }}
      />

      <ReminderListTabComponent
        targetId={props.estimateDetailData?._id ?? ''}
        targetEntityRef={ReminderTargetType.Estimate}
        targetDisplayName={props.estimateDetailData?.estimateId.toString() ?? ''}
        tabKey="reminders"
        name="crm.reminders"
        permission={{
          subject: PermissionSubject.reminder,
          action: PermissionAction.list,
        }}
      />

      {/* <DefaultEmptyTabComponent
        title={t('crm.activities')}
        emptyStateContent={t('crm.noActivityContent1')}
        tabKey="activities"
        nameTranslation={t('crm.activities')}
      /> */}
    </DefaultTabsComponent>
  );
};
