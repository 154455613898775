import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Box, Typography, Stack, Paper, Grid, Button } from '@mui/material';
import { Column, Id, Task } from './TaskList-kanban.component';
import { useSortable, SortableContext } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TaskCard from './TaskList-kanban-taskCard.component';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { ITask, TaskStatus } from 'corede-common-cocrm';
import { OutlinedButton } from 'components';
import { useTranslation } from 'react-i18next';

interface Props {
  column: Column;
  tasks: Task[];
  updateTask: (id: Id, content: string) => void;
  setSelectedTask: Dispatch<SetStateAction<TListGridSelectedEntity<ITask>>>;
  setOpenTaskDetailDrawer: Dispatch<SetStateAction<boolean>>;
  handleLoadMore: (status: TaskStatus) => void;
  taskCount?: number;
}

const ColumnContainer: React.FC<Props> = ({
  column,
  tasks,
  updateTask,
  setSelectedTask,
  setOpenTaskDetailDrawer,
  handleLoadMore,
  taskCount = 0,
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  const tasksIds = useMemo(() => tasks.map((task) => task.id), [tasks]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } = useSortable({
    id: column.id,
    data: { type: 'Column', column },
    disabled: editMode,
  });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  if (isDragging) {
    return (
      <Box
        ref={setNodeRef}
        sx={{
          // width: 250,
          height: 700,
          border: '2px solid',
          borderColor: 'primary.main',
          bgcolor: '#FF7D7D0D',
          borderRadius: 2,
        }}
        style={style}
      />
    );
  }

  console.log(tasks, 'tasks')

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Paper
        ref={setNodeRef}
        elevation={3}
        sx={{
          // width: 250,
          height: 700,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          boxShadow: '0px 0px 45px rgba(0, 0, 0, 0.05)',
        }}
        style={style}
      >
        {/* Column Header */}
        <Box
          {...attributes}
          {...listeners}
          sx={{
            color: 'primary.contrastText',
            bgcolor: '#FF7D7D0D',
            py: 2,
            px: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'grab',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            {!editMode && <Typography variant="body1">{column.title}</Typography>}
            <Typography
              variant="body2"
              sx={{
                bgcolor: 'secondary.extraLight',
                color: 'secondary.main',
                padding: '4px 8px',
                borderRadius: 1,
              }}
            >
              {tasks.length}
            </Typography>
          </Stack>
          {/* <IconButton sx={{ color: 'primary.main' }}>
          <MoreVert />
        </IconButton> */}
        </Box>

        {/* Tasks */}
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            overflowY: 'auto',
            bgcolor: '#FF7D7D0D',
          }}
        >
          <SortableContext items={tasksIds}>
            {tasks.map((task) => (
              <TaskCard
                key={task.id}
                task={task}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
              />
            ))}
          </SortableContext>
          {/* TODO load more task lengthi page sizea göre getir */}
          {tasks.length > 5 && (
            <Box sx={{ textAlign: 'center', marginTop: 2 }}>
              <OutlinedButton
                fullWidth
                title={t('loadMore')}
                onClick={() => handleLoadMore(column.id as TaskStatus)}
                disabled={
                  tasks.length === 0 || tasks.length % 5 !== 0 || taskCount === tasks.length
                }
              />
            </Box>
          )}
        </Box>

        {/* Column Footer */}
      </Paper>
    </Grid>
  );
};

export default ColumnContainer;
