/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, useMediaQuery } from '@mui/material';
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { InvoiceDetailDrawerLeftPanelHeaderComponent } from './InvoiceDetailDrawer-leftPanel-header.component';
import { InvoiceDetailDrawerLeftPanelContactDetailComponent } from './InvoiceDetailDrawer-leftPanel-contactDetail.component';
import { InvoiceDetailDrawerLeftPanelAssignedsComponent } from './InvoiceDetailDrawer-leftPanel-assigned.component';

export interface IInvoiceDetailDrawerLeftPanelComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InvoiceDetailDrawerLeftPanelComponent = (
  props: IInvoiceDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <DefaultDetailDrawerLeftPanelComponent<IInvoiceDetailResult>
      entityData={props.invoiceDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <InvoiceDetailDrawerLeftPanelHeaderComponent
        invoiceDetailData={props.invoiceDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <InvoiceDetailDrawerLeftPanelContactDetailComponent
        invoiceDetailData={props.invoiceDetailData}
        setOpen={props.setOpen}
      />

      {/* Assigned List */}
      <InvoiceDetailDrawerLeftPanelAssignedsComponent
        invoiceDetailData={props.invoiceDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
      <Divider />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
