const locale = {
  contracts: 'Contracts',
  contract: 'Contract',
  contractConversions: 'Contract Conversions',
  title: 'Contracts',
  create: 'Create Contract',
  update: 'Update Contract',
  delete: 'Delete Contract',
  createSuccess: 'Contract created successfully.',
  updateSuccess: 'Contract updated successfully.',
  deleteSuccess: 'Contract deleted successfully.',
  deleteConfirm: 'Are you sure you want to delete this contract. This action cannot be undone.',
  emptyState1: 'No contracts added yet.',
  emptyState2:
    'Store and manage all contract details here to streamline your invoicing and maintain clear agreements with your customers.',

  // labels
  contractTemplateLabel: 'Contract Template',
  contractValue: 'Contract Value',
  contractType: 'Contract Type',
  signedDate: 'Signed Date',
  signedUser: 'Signed User',
  signStatus: 'Sign Status',

  //detail drawer
  changeStatus: 'Change Status',
  statusChangeSuccess: 'Status changed successfully.',
  generalInfoTitle: 'General Info',
  customerInfoTitle: 'Customer Info',
  projectInfoTitle: 'Project Info',
  contractRenew: 'Renew Contract',
  renew: 'Renew Contract',
  renewSuccess: 'Contract renewed successfully.',
  emptyStateRenew: 'No contracts to renew.',
  renewHistory: 'Renew History',
};

export default locale;
