import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { IEstimateDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { t } from 'i18next';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';

export interface IEstimateDetailDrawerLeftPanelProps {
  estimateDetailData: IEstimateDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EstimateDetailDrawerLeftPanelContactDetailComponent = (
  props: IEstimateDetailDrawerLeftPanelProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      {/* Genel Bilgiler */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
        {!downLg && (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.preSale.estimate.generalInfoTitle')}
            </Typography>
            <Divider />
          </Stack>
        )}

        {/* Estimate ID */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.preSale.estimate.estimateId')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.estimateDetailData?.estimateId || '-'}
          </Typography>
        </Stack>

        {/* Status */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.status')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {getTranslatedEnumValue(props.estimateDetailData?.status || '')}
          </Typography>
        </Stack>

        {/* Created At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {moment(props.estimateDetailData?.createdAt).format('DD/MM/YYYY') || '-'}
          </Typography>
        </Stack>
      </Stack>

      {/* Müşteri Bilgileri */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={4}>
        <Stack direction={'column'} width={'100%'} gap={0.5}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'text.primary',
            }}
          >
            {t('crm.preSale.estimate.customerInfoTitle')}
          </Typography>
          <Divider />
        </Stack>

        {/* Customer Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.customerName')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.estimateDetailData?.prospectName || '-'}
          </Typography>
        </Stack>

        {/* Contact Email */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.contactEmail')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.estimateDetailData?.prospectContactEmail || '-'}
          </Typography>
        </Stack>

        {/* Contact Phone */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.preSale.estimate.contactPhoneNumber')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.estimateDetailData?.prospectPhoneNumber || '-'}
          </Typography>
        </Stack>

        {/* Customer Address */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.address')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {[
              props.estimateDetailData?.prospectAddress,
              props.estimateDetailData?.prospectCity,
              props.estimateDetailData?.prospectState,
              props.estimateDetailData?.prospectCountry,
              props.estimateDetailData?.prospectZIP,
            ]
              .filter(Boolean)
              .join(', ') || '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
