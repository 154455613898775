import { Stack, Box, Typography } from '@mui/material';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useUserListQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import WelcomeImg from 'assets/images/login-illustration-2.png';
import AvatarGroupBase from 'components/avatar/AvatarGroupBase';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { useTranslation } from 'react-i18next';
import { memo, useCallback } from 'react';

const Welcome = () => {
  const { t } = useTranslation();

  const { hasPermission } = usePermissions();

  const hasUserListPermission = hasPermission({
    subject: PermissionSubject.user,
    action: PermissionAction.list,
  });

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const { data: userListData } = useUserListQuery(
    {
      input: {
        pagination: {
          sort: {
            createdAt: 1,
          },
        },
      },
    },
    {
      skip: !hasUserListPermission,
    },
  );

  const renderAvatarGroup = useCallback(() => {
    return <AvatarGroupBase userList={userListData?.data} max={5} width={25} />;
  }, [userListData]);

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      gap={1}
      pl={2}
      pt={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={'33%'}
      width={'100%'}
      position={'relative'}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 400, color: 'text.secondary' }}>
          {t('crm.dashboard.dashboard.welcome')},
        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {userDetailOwnData?.name} {userDetailOwnData?.surname} 🎉
        </Typography>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          pl: 2,
          pb: 1,
        }}
      >
        <PermissionWrapper
          check={{
            subject: PermissionSubject.user,
            action: PermissionAction.list,
          }}
        >
          {renderAvatarGroup()}
        </PermissionWrapper>
      </Box>
      <img src={WelcomeImg} alt="Welcome" style={{ height: '138px' }} />
    </Stack>
  );
};

export default memo(Welcome);
