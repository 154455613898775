/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import ActionDialog from 'components/dialog/ActionDialog';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Check, Close, FileUpload } from '@mui/icons-material';
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import {
  FileMimeType,
  IDomainFileCreateResult,
  IDomainFilesCreateOwnInput,
  IEntityFilesAssignInput,
} from 'corede-common';
import { OutlinedButton } from 'components/buttons';
import {
  PNGIcon,
  JPGIcon,
  DOCIcon,
  MP4Icon,
  PDFIcon,
  PPTIcon,
  TXTIcon,
  XLSIcon,
  ZIPIcon,
  UPLOADIcon,
} from 'assets/images/fileIcons';
import { fileTypeForIcon } from './UploadDocument';

enum FileUploadStatus {
  notUploaded = 'notUploaded',
  failed = 'failed',
  success = 'success',
}

export enum FileTargetType {
  lead = 'lead',
  customer = 'customer',
  invoice = 'invoice',
  task = 'task',
}

export interface IFileData {
  id: number;
  file: File;
  uploadProgress: number;
  status: FileUploadStatus;
}

export const UploadDocumentForDrawer = forwardRef(
  (
    props: {
      useDocumentCreateManyMutation: any;
      useDocumentAddManyMutation?: any;
      entityId?: string;
      targetList?: [];
      onUpload?: (file: File) => void;
      externalFileDataList?: IFileData[];
      setExternalFileDataList?: Dispatch<SetStateAction<IFileData[]>>;
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [currentFileDataId, setCurrentFileDataId] = useState(0);
    const fileDataList = props.externalFileDataList || [];
    const setFileDataList = props.setExternalFileDataList || (() => null);
    const [isUploading, setIsUploading] = useState(false);

    const [documentCreateMany, { data: documentCreateManyData }] =
      props.useDocumentCreateManyMutation();

    useEffect(() => {
      setFileDataList((prev) => [
        ...prev,
        ...selectedFiles.map((f, idx) => ({
          id: currentFileDataId + idx,
          file: f,
          uploadProgress: 0,
          status: FileUploadStatus.notUploaded,
        })),
      ]);
      setCurrentFileDataId((prev) => prev + selectedFiles.length);
    }, [selectedFiles]);

    const handleUpload = async () => {
      setIsUploading(true);
      const fileDataToBeUploaded = fileDataList.filter(
        (fileData) => fileData.status !== FileUploadStatus.success,
      );
      const newDocuments: any[] = [];

      for (const fileData of fileDataToBeUploaded) {
        const docMetadata = await handleUploadForSingleFileData({ fileData });
        if (docMetadata) newDocuments.push(docMetadata);
      }
      setIsUploading(false);
      return newDocuments;
    };

    useImperativeHandle(ref, () => ({
      handleUpload,
    }));

    const handleUploadForSingleFileData = async (params: { fileData: IFileData }) => {
      try {
        const uploadedDoc = await uploadDocument({
          entityId: props.entityId ?? '',
          documentCreateManyQuery: documentCreateMany,
          fileData: params.fileData,
          setFileDataList,
        });
        return uploadedDoc;
      } catch (error) {
        setFileDataList((prevItems) => {
          return setFileDataStatus({
            items: prevItems,
            item: params.fileData,
            newStatus: FileUploadStatus.failed,
          });
        });
        return null;
      }
    };

    const handleDocumentSelect = async ({ e }: { e?: any }) => {
      setSelectedFiles(Array.from(e.target.files));
    };

    const handleDeleteFile = (id: number) => {
      const newFileDataList = Object.values(fileDataList).filter(
        (fileData, _) => fileData.id !== id,
      );
      setFileDataList(newFileDataList);
    };

    return (
      <Grid item xs={12}>
        <Stack>
          <Stack
            onClick={() => document.getElementById('profile-image-input')?.click()}
            sx={{
              width: 'calc(100% - 20px)',
              border: '1px dashed',
              borderColor: 'primary.light',
              borderRadius: '16px',
              p: 1,
              flexGrow: 1,
              cursor: 'pointer',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              gap={1}
              py={2}
              sx={{ width: '100%', height: '100%' }}
            >
              <img src={UPLOADIcon} alt="upload" style={{ width: '50px', height: '50px' }} />
              <Typography variant="body2"> {t('uploadDocuments')}</Typography>
            </Stack>
          </Stack>
          <input
            id="profile-image-input"
            type="file"
            accept={Object.values(FileMimeType).join(',')}
            multiple
            style={{ display: 'none' }}
            onChange={(e) => handleDocumentSelect({ e })}
          />
          <Stack direction={'column'} gap={1} mt={2}>
            {Object.values(fileDataList)?.map((fileData: IFileData, index: number) => {
              return (
                <Box
                  key={index}
                  sx={{
                    borderRadius: 2,
                    py: 1,
                    px: 2,
                    border: '1px solid',
                    borderColor: 'grey.300',
                    position: 'relative',
                  }}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    zIndex={2}
                  >
                    <Stack
                      direction={'row'}
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      gap={1}
                      zIndex={2}
                    >
                      <img
                        src={fileTypeForIcon(fileData.file.type)}
                        alt="file"
                        style={{ width: '30px', height: '30px' }}
                      />
                      <Stack direction={'column'}>
                        <Typography fontSize={'12px'} fontWeight={600}>
                          {fileData.file.name?.slice(0, 20)}{' '}
                          {fileData.file.name?.length > 30 ? '...' : ''}
                        </Typography>
                        <Typography fontSize={'10px'} color={'GrayText'}>
                          {(fileData.file.size / 1024).toFixed(2)} KB
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      zIndex={2}
                      direction={'row'}
                      gap={1}
                      alignItems={'center'}
                      justifyContent={'flex-end'}
                    >
                      {fileData?.status === FileUploadStatus.failed && (
                        <OutlinedButton
                          title={t('Retry')}
                          onClick={() => handleUploadForSingleFileData({ fileData })}
                        />
                      )}
                      <IconButton
                        color="inherit"
                        size="small"
                        onClick={() =>
                          fileData?.status === FileUploadStatus.success
                            ? {}
                            : handleDeleteFile(fileData?.id)
                        }
                      >
                        {(fileData?.status === FileUploadStatus.notUploaded ||
                          fileData?.status === FileUploadStatus.failed) && (
                          <Close fontSize="small" />
                        )}
                        {fileData?.status === FileUploadStatus.success && (
                          <Check fontSize="small" color="success" />
                        )}
                      </IconButton>
                    </Stack>
                  </Stack>
                  <LinearProgress
                    variant="determinate"
                    value={fileData?.uploadProgress || 0}
                    sx={{
                      width: '100%',
                      zIndex: 0,
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      height: '100%',
                      opacity: 0.4,
                      borderRadius: 2,
                      bgcolor:
                        fileData?.status === FileUploadStatus.failed ? 'error.light' : '#00000000',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: 2,
                        bgcolor: '#00000000',
                      },
                      '& .MuiLinearProgress-barColorPrimary': {
                        borderRadius: 2,
                        bgcolor: 'success.light',
                      },
                    }}
                  />
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </Grid>
    );
  },
);

/**
 *
 * @param params
 */
async function uploadDocument(params: {
  entityId: string;
  documentCreateManyQuery: any;
  fileData: IFileData;
  setFileDataList: Dispatch<SetStateAction<IFileData[]>>;
}): Promise<any> {
  params.setFileDataList((prevItems) => {
    return setFileDataProgress({
      items: prevItems,
      item: params.fileData,
      newProgress: 0,
    });
  });

  // creates file from backend - gets fileMetadata and presignedUrl
  const createDocumentResult = await createDocument({
    documentCreateManyQuery: params.documentCreateManyQuery,
    file: params.fileData.file,
  });

  // uploads file to s3 bucket using presignedUrl obtained from above
  await uploadDocumentUsingPresignedUrl({
    createDocumentResult: createDocumentResult,
    fileData: params.fileData,
    setFileDataList: params.setFileDataList,
  });

  // assigns fileMetadata with the related entity
  await assignDocumentToEntity({
    entityId: params.entityId,
    createDocumentResult: createDocumentResult,
  });

  params.setFileDataList((prevItems) => {
    return setFileDataProgress({
      items: prevItems,
      item: params.fileData,
      newProgress: 100,
    });
  });

  params.setFileDataList((prevItems) => {
    return setFileDataStatus({
      items: prevItems,
      item: params.fileData,
      newStatus: FileUploadStatus.success,
    });
  });

  // Return newly created metadata
  return createDocumentResult.fileMetadata;
}

/**
 * creates file from backend - gets fileMetadata and presignedUrl
 */
async function createDocument(params: {
  documentCreateManyQuery: any;
  file: File;
}): Promise<IDomainFileCreateResult> {
  const input: IDomainFilesCreateOwnInput = {
    inputs: [
      {
        mimeType: params.file.type,
        size: params.file.size,
        customName: params.file.name,
      },
    ],
  };

  const response = await params.documentCreateManyQuery({
    input: input,
  });

  const createFilesResult = response?.data.files as IDomainFileCreateResult[];

  if (!createFilesResult || createFilesResult?.length === 0 || !createFilesResult?.at(0)) {
    throw new Error('create document fileMetadata and presigned url failed');
  }

  const createDocumentResult = createFilesResult.at(0);
  return createDocumentResult!;
}

/**
 * uploads file to s3 bucket using presignedUrl obtained from above
 */
async function uploadDocumentUsingPresignedUrl(params: {
  createDocumentResult: IDomainFileCreateResult;
  fileData: IFileData;
  setFileDataList: Dispatch<SetStateAction<IFileData[]>>;
}): Promise<void> {
  const config: AxiosRequestConfig<File> = {
    method: 'PUT',
    maxBodyLength: Infinity,
    url: params.createDocumentResult.presignedUrl.uploadPresignedUrl,
    headers: {
      'Content-Type': params.fileData.file.type,
    },
    data: params.fileData.file,
    onUploadProgress: (event: AxiosProgressEvent) => {
      if (event.lengthComputable && event.total) {
        const progress = Math.round((event.loaded / event.total) * 100);
        params.setFileDataList((prevItems) => {
          return setFileDataProgress({
            items: prevItems,
            item: params.fileData,
            newProgress: progress,
          });
        });
      }
    },
  };

  try {
    const response = await axios(config);
    if (!response) {
      throw new Error('upload document failed');
    }
  } catch (error: any) {
    throw new Error(`upload document failed: error: ${error.message}`);
  }
}

/**
 * assigns fileMetadata with the related entity
 */
async function assignDocumentToEntity(params: {
  entityId: string;
  createDocumentResult: IDomainFileCreateResult;
}): Promise<void> {
  try {
    const input: IEntityFilesAssignInput = {
      _id: params.entityId,
      files: [params.createDocumentResult.fileMetadata],
    };

    // const result = await params.documentAddManyQuery({
    //   input: input,
    // });
    // if (!result) {
    //   throw new Error('assign file to entity failed');
    // }
  } catch (error: any) {
    throw new Error(`assign file to entity failed: error: ${error.message}`);
  }
}

function setFileDataProgress(params: {
  items: IFileData[];
  item: IFileData;
  newProgress: number;
}): IFileData[] {
  return params.items.map((item) => {
    return item.id === params.item.id
      ? {
          ...item,
          uploadProgress: params.newProgress,
        }
      : item;
  });
}

function setFileDataStatus(params: {
  items: IFileData[];
  item: IFileData;
  newStatus: FileUploadStatus;
}): IFileData[] {
  return params.items.map((item) => {
    return item.id === params.item.id
      ? {
          ...item,
          status: params.newStatus,
        }
      : item;
  });
}
