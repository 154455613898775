/* eslint-disable react-hooks/exhaustive-deps */
import { ITicketDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { TicketDetailDrawerRightPanelHeaderComponent } from './TicketDetailDrawer-rightPanel-header.component';
import { TicketDetailDrawerRightPanelTabsComponent } from './TicketDetailDrawer-rightPanel.tabs.component';

export interface ITicketDetailDrawerRightPanelComponentProps {
  ticketDetailData: ITicketDetailResult | undefined;
}

export const TicketDetailDrawerRightPanelComponent = (
  props: ITicketDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <TicketDetailDrawerRightPanelHeaderComponent ticketDetailData={props.ticketDetailData} />

      {/* Tabs Section */}
      <TicketDetailDrawerRightPanelTabsComponent ticketDetailData={props.ticketDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
