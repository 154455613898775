/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AppointmentCategory,
  AppointmentPriority,
  AppointmentStatus,
  AppointmentTargetType,
  IAppointmentDetailResult,
  ProgressCalculationType,
} from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import parse from 'html-react-parser';

export interface IAppointmentDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IAppointmentDetailResult> {
  appointmentDetailData: IAppointmentDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

const ensureHttps = (url: string) => {
  if (!url) return '#';
  return url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
};

export const AppointmentDetailDrawerTabInfoComponent = (
  props: IAppointmentDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();

  const appointment = props.appointmentDetailData;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';

  return (
    <Box sx={{ height: maxHeight, overflowY: 'auto' }}>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.calendar.appointment.appointmentInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container gap={3}>
            <Grid item xs={12}>
              <Box>
                {/* Description */}
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:note-text" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="caption">{t('crm.description')}</Typography>
                        <Typography
                          variant="body2"
                          sx={{ fontWeight: 500, mt: -1 }}
                          color={'text.primary'}
                        >
                          {parse(appointment?.description || '')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>

                  {/* priority*/}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:flag" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {/* {getTranslatedEnumValue(appointment?.priority as AppointmentPriority) || '-'} //TODO */}
                        </Typography>
                        <Typography variant="caption">{t('crm.priority')}</Typography>
                      </Box>
                    </Stack>
                  </Grid>

                  {/* category */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:label" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {getTranslatedEnumValue(appointment?.category as AppointmentCategory) ||
                            '-'}
                        </Typography>
                        <Typography variant="caption">{t('crm.category')}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* start time */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:clock-start" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {moment(appointment?.startTime).format('lll')}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.calendar.appointment.startTime')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* end time */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:clock-end" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {moment(appointment?.endTime).format('lll')}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.calendar.appointment.endTime')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* duration */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:timer" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {appointment?.duration
                            ? `${appointment?.duration / 60} ` + t('min')
                            : '-'}
                        </Typography>
                        <Typography variant="caption">
                          {' '}
                          {t('crm.calendar.appointment.duration')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* timezone */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:earth" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {appointment?.timezone || '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.calendar.appointment.timezone')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* target type */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:target" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {appointment?.targetType
                            ? getTranslatedEnumValue(appointment?.targetType)
                            : '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.calendar.appointment.targetType')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* attendees */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="mdi:account-group"
                          width={20}
                          sx={{ color: 'secondary.main' }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {appointment?.attendees?.map((attendee) => attendee.name).join(', ') ||
                            '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.calendar.appointment.attendees')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* meeting link */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:link" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          <a
                            href={ensureHttps(appointment?.meetingLink || '')}
                            target="_blank"
                            style={{ color: 'rosybrown' }}
                            rel="noopener noreferrer"
                          >
                            {appointment?.meetingLink || '-'}
                          </a>
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.calendar.appointment.meetingLink')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
