/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, Divider, Grid, Grow, Stack, Typography } from '@mui/material';
import { MyTab, SettingsTitle } from 'components';
import { unknownError } from 'corede-common';
import { useEffect, useState } from 'react';
import { useOrganizationDetailOwnQuery } from '../context/setting.api';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyState from 'components/emptyState/EmptyState';

const Notification = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [tabLabels, setTabLabels] = useState(['']);
  const [selectedTabName, setSelectedTabName] = useState('');

  const {
    data: organizationDetailOwnData,
    isLoading: organizationDetailOwnLoading,
    error: organizationDetailOwnError,
  } = useOrganizationDetailOwnQuery({});

  const status = organizationDetailOwnData?.status;

  //Common

  useEffect(() => {
    switch (status) {
      case 'restricted':
        setTabLabels(['settings.settings.settings.notification']);
        break;
      case 'active':
        setTabLabels(['settings.settings.settings.notification']);
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (tabLabels) {
      switch (tab) {
        case 'notification':
          setSelectedTabName(t('settings.settings.settings.notification'));
          break;
        default:
          setSelectedTabName(tabLabels[0]);
          break;
      }
    }
  }, [tabLabels, tab]);

  useEffect(() => {
    if (organizationDetailOwnError) {
      enqueueSnackbar(
        (organizationDetailOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationDetailOwnError]);

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={3}>
      <Stack
        direction={'row'}
        sx={{
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        <MyTab
          type="settings"
          labels={tabLabels}
          setSelectedTab={setSelectedTabName}
          selectedTab={selectedTabName}
        />
      </Stack>
      {selectedTabName === 'settings.settings.settings.notification' && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.notification')} />
          <Grid item xs={12}>
            <Grow in={selectedTabName === 'settings.settings.settings.notification'} timeout={500}>
              <Card
                sx={{
                  p: 2,
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                }}
              >
                <Grid container>
                  <Grid item xs={12} mb={2}>
                    <EmptyState
                      content1={t('settings.settings.settings.noNotificationContent1')}
                      content2={t('settings.settings.settings.noNotificationContent2')}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};

export default Notification;
