import {
  Grid,
  Skeleton,
  Stack,
  Box,
  Typography,
  useMediaQuery,
  Theme,
  Divider,
} from '@mui/material';
import { memo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useLeadStatisticsQuery } from '../../context/lead.api';
import { useTranslation } from 'react-i18next';
import { Iconify } from 'components';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { getCurrentLanguage } from 'localization';

export interface ILeadListStatisticsView {
  count: number;
  leadListCountLoading: boolean;
}

const placeholderData = [{ value1: 1, name: '' }];

export const LeadListStatisticsView = memo((props: ILeadListStatisticsView) => {
  // general
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // queries
  const { data: leadStatisticsData, isLoading: leadStatisticsLoading } = useLeadStatisticsQuery({
    input: {
      filter: {
        numberOfPastDays: 7,
      },
    },
  });

  const isLoading = props.leadListCountLoading || leadStatisticsLoading;
  const isEmpty = props.count === 0;

  const theme = useTheme();
  const palette = theme.palette as Record<string, any>;
  const hexColor = palette['success'].lighter;
  const selectedColor = palette['success'].main;

  const [activeIndex, setActiveIndex] = useState(1);

  const donutChartColors = [
    '#1A8F4E',
    '#24B364',
    '#53D28C',
    '#7EDDA9',
    '#A9E9C5',
    '#2EC876',
    '#66D89E',
    '#91E4B8',
  ];

  const daysOfWeek = [
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
    t('sunday'),
  ];

  const today = new Date().getDay();

  const donutChartData =
    leadStatisticsData?.dailyHistory?.dailyCreatedCounts?.map((count, i) => {
      const dayIndex = (today - i + 6) % 7;
      return {
        name: t(daysOfWeek[dayIndex]),
        value1: count ?? 0,
      };
    }) || [];

  const weeklyTotal = leadStatisticsData?.dailyHistory?.dailyCreatedCounts?.reduce(
    (acc, count) => acc + (count ?? 0),
    0,
  );

  const updatedData = donutChartData.map((item, index) => ({
    ...item,
    fill: index === activeIndex ? selectedColor : hexColor,
  }));

  const handlePieClick = (_: any, index: number) => {
    setActiveIndex(index);
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const nameLabel = getCurrentLanguage() === 'tr' ? 'İsim' : 'Name';
      const valueLabel = getCurrentLanguage() === 'tr' ? 'Değer' : 'Value';
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            zIndex: 10,
            border: '1px solid #ddd',
            borderRadius: '8px',
            padding: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {`${nameLabel}: ${payload[0].payload.name}`}
          </Typography>
          <Typography variant="body2">{`${valueLabel}: ${payload[0].value}`}</Typography>
        </Box>
      );
    }
    return null;
  };

  return !isLoading ? (
    !isEmpty ? (
      <Grid container alignItems={'center'} spacing={1}>
        <Grid item xs={12} md={8}>
          <Stack
            direction={downSm ? 'column' : 'row'}
            alignItems={downSm ? 'flex-start' : 'center'}
            gap={{ xs: 2, md: 4 }}
          >
            <Box>
              <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: downMd ? '32px' : '54px',
                    height: downMd ? '32px' : '54px',
                    borderRadius: '6px',
                    backgroundColor: 'secondary.extraLight',
                  }}
                >
                  <Iconify
                    icon={'material-symbols-light:social-leaderboard-outline-rounded'}
                    sx={{ color: 'secondary.main' }}
                    width={downMd ? 24 : 38}
                  />
                </Box>
                <Box>
                  <Typography variant={downMd ? 'caption' : 'body1'} sx={{ fontWeight: '500' }}>
                    {t(`crm.lead.lead.leads`)}
                  </Typography>

                  <Typography
                    variant={downMd ? 'body2' : 'h4'}
                    color="secondary.main"
                    sx={{ fontWeight: '500' }}
                  >
                    {leadStatisticsData?.count ?? 0}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: downMd ? '32px' : '54px',
                    height: downMd ? '32px' : '54px',
                    borderRadius: '6px',
                    backgroundColor: 'info.extraLight',
                  }}
                >
                  <Iconify
                    icon={'material-symbols:conversion-path'}
                    sx={{ color: 'info.main' }}
                    width={downMd ? 24 : 38}
                  />
                </Box>
                <Box>
                  <Typography variant={downMd ? 'caption' : 'body1'} sx={{ fontWeight: '500' }}>
                    {t(`crm.lead.lead.leadConversions`)}
                  </Typography>

                  <Typography
                    variant={downMd ? 'body2' : 'h4'}
                    color="info.main"
                    sx={{ fontWeight: '500' }}
                  >
                    {Number(
                      (
                        ((leadStatisticsData?.countByConvertedToCustomer ?? 0) * 100) /
                        (leadStatisticsData?.count ?? 1)
                      ).toFixed(1),
                    ) ?? 0}{' '}
                    %
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box>
              <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: downMd ? '32px' : '54px',
                    height: downMd ? '32px' : '54px',
                    borderRadius: '6px',
                    backgroundColor: 'warning.extraLight',
                  }}
                >
                  <svg
                    width={downMd ? 24 : 38}
                    height={downMd ? 24 : 38}
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M8.08984 29.9102C6.72422 28.5445 7.62969 25.6797 6.93203 24.0023C6.23438 22.325 3.5625 20.8555 3.5625 19C3.5625 17.1445 6.20469 15.7344 6.93203 13.9977C7.65938 12.2609 6.72422 9.45547 8.08984 8.08984C9.45547 6.72422 12.3203 7.62969 13.9977 6.93203C15.675 6.23438 17.1445 3.5625 19 3.5625C20.8555 3.5625 22.2656 6.20469 24.0023 6.93203C25.7391 7.65938 28.5445 6.72422 29.9102 8.08984C31.2758 9.45547 30.3703 12.3203 31.068 13.9977C31.7656 15.675 34.4375 17.1445 34.4375 19C34.4375 20.8555 31.7953 22.2656 31.068 24.0023C30.3406 25.7391 31.2758 28.5445 29.9102 29.9102C28.5445 31.2758 25.6797 30.3703 24.0023 31.068C22.325 31.7656 20.8555 34.4375 19 34.4375C17.1445 34.4375 15.7344 31.7953 13.9977 31.068C12.2609 30.3406 9.45547 31.2758 8.08984 29.9102Z"
                      fill="#FF9F43"
                    />
                    <path
                      d="M25.5312 15.4375L16.818 23.75L12.4687 19.5937M8.08984 29.9102C6.72422 28.5445 7.62969 25.6797 6.93203 24.0023C6.23437 22.325 3.5625 20.8555 3.5625 19C3.5625 17.1445 6.20469 15.7344 6.93203 13.9977C7.65937 12.2609 6.72422 9.45547 8.08984 8.08984C9.45547 6.72422 12.3203 7.62969 13.9977 6.93203C15.675 6.23437 17.1445 3.5625 19 3.5625C20.8555 3.5625 22.2656 6.20469 24.0023 6.93203C25.7391 7.65937 28.5445 6.72422 29.9102 8.08984C31.2758 9.45547 30.3703 12.3203 31.068 13.9977C31.7656 15.675 34.4375 17.1445 34.4375 19C34.4375 20.8555 31.7953 22.2656 31.068 24.0023C30.3406 25.7391 31.2758 28.5445 29.9102 29.9102C28.5445 31.2758 25.6797 30.3703 24.0023 31.068C22.325 31.7656 20.8555 34.4375 19 34.4375C17.1445 34.4375 15.7344 31.7953 13.9977 31.068C12.2609 30.3406 9.45547 31.2758 8.08984 29.9102Z"
                      stroke="#FF9F43"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Box>
                <Box>
                  <Typography variant={downMd ? 'caption' : 'body1'} sx={{ fontWeight: '500' }}>
                    B2B / B2C
                  </Typography>

                  <Typography
                    variant={downMd ? 'body2' : 'h4'}
                    color="warning.main"
                    sx={{ fontWeight: '500' }}
                  >
                    {leadStatisticsData?.countByLeadBusinessType?.countByB2b ?? 0} /{' '}
                    {leadStatisticsData?.countByLeadBusinessType?.countByB2c ?? 0}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          {downMd && <Divider sx={{ my: { xs: 2, md: 0 } }} />}
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pl={downMd ? 0 : 6}
            gap={2}
            sx={{ borderLeft: downMd ? 'none' : '1px solid', borderColor: 'divider' }}
          >
            <Box>
              <Typography variant={downMd ? 'body1' : 'h5'} sx={{ fontWeight: '500' }}>
                {t(`crm.lead.lead.leads`)}
              </Typography>
              <Typography variant={downMd ? 'caption' : 'body2'}>
                {t(`crm.lead.lead.weeklyReport`)}
              </Typography>
              <Stack
                direction={'row'}
                alignItems={'flex-end'}
                justifyContent={'flex-start'}
                gap={1}
              >
                <Typography variant={downMd ? 'caption' : 'body2'}> {t('today')}: </Typography>
                <Typography variant={downMd ? 'body2' : 'h4'} sx={{ fontWeight: '500' }}>
                  {' '}
                  {leadStatisticsData?.dailyHistory?.dailyCreatedCounts?.[0] ?? 0}
                </Typography>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '2px 10px',
                  borderRadius: '4px',
                  backgroundColor: 'success.extraLight',
                  width: 'fit-content',
                }}
              >
                <Typography
                  variant="overline"
                  color="success.main"
                  sx={{ fontWeight: '500', lineHeight: 1 }}
                >
                  +
                  {leadStatisticsData?.dailyHistory?.dailyCreatedCounts?.[0]
                    ? (
                        ((leadStatisticsData?.dailyHistory?.dailyCreatedCounts?.[0] ?? 0) /
                          (weeklyTotal ?? 1)) *
                        100
                      ).toFixed(1)
                    : 0}
                  %
                </Typography>
              </Box>
            </Box>

            <Stack
              position={'relative'}
              direction={'row'}
              justifyContent={'center'}
              alignItems={'flex-end'}
            >
              <ResponsiveContainer width={200} height={160}>
                <PieChart>
                  <Tooltip content={<CustomTooltip />} />
                  <Pie
                    data={updatedData.length > 0 ? updatedData : placeholderData} // Veri boşsa placeholder kullan
                    innerRadius={60}
                    outerRadius={80}
                    dataKey="value1"
                    onClick={handlePieClick}
                  >
                    {updatedData.length > 0
                      ? donutChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={index === activeIndex ? selectedColor : donutChartColors[index]}
                          />
                        ))
                      : [<Cell key="placeholder" fill="#e0e0e0" />]}{' '}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>

              <Box
                position={'absolute'}
                top={'50%'}
                left={'50%'}
                width={'100%'}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  transform: 'translate(-50%, -50%)',
                  mb: 2,
                  zIndex: -1,
                }}
              >
                <Typography variant="h3" sx={{ fontWeight: 500 }}>
                  {weeklyTotal ?? 0}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{ fontWeight: 400, color: 'secondary.main', px: 1 }}
                >
                  {t('crm.weeklyTotal')}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    ) : null
  ) : (
    <Grid container spacing={2} my={1}>
      <Grid item xs={12} md={8}>
        <Stack direction={'row'} alignItems={'center'} gap={{ xs: 2, md: 4 }}>
          <Skeleton variant="rectangular" width={150} height={200} sx={{ borderRadius: '16px' }} />
          <Skeleton variant="rectangular" width={150} height={200} sx={{ borderRadius: '16px' }} />
          <Skeleton variant="rectangular" width={150} height={200} sx={{ borderRadius: '16px' }} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Skeleton variant="rectangular" width={'100%'} height={200} sx={{ borderRadius: '16px' }} />
      </Grid>
    </Grid>
  );
});
