/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, Divider, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ExpenseCategory,
  IExpenseDetailResult,
  ProgressCalculationType,
} from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { Iconify as Icon } from 'components';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';

export interface IExpenseDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IExpenseDetailResult> {
  expenseDetailData: IExpenseDetailResult | undefined;
  tabKey: 'info';
  name: 'crm.info';
}

export const ExpenseDetailDrawerTabInfoComponent = (
  props: IExpenseDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();

  const expense = props.expenseDetailData;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const maxHeight = isSmallScreen ? '100vh' : 'calc(100vh - 270px)';

  return (
    <Box sx={{ height: maxHeight, overflowY: 'auto' }}>
      <Stack direction={'column'} sx={{ py: 1, mb: 2, mt: 1, position: 'relative' }}>
        <Box>
          <Typography variant="h6" sx={{ marginBottom: 1 }}>
            {t('crm.expense.expense.expenseInfoTitle')}
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />

          <Grid container gap={3}>
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={1.5}>
                  {/* Prefix*/}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:cash" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.prefix || '-'}
                        </Typography>
                        <Typography variant="caption"> {t('crm.prefix')}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* Date*/}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:tag" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {moment(expense?.date).format() || ''}
                        </Typography>
                        <Typography variant="caption">{t('crm.date')}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* Kategori */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:cart-outline" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.category ? getTranslatedEnumValue(expense?.category) : '-'}
                        </Typography>
                        <Typography variant="caption">{t('crm.category')}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* currency */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon icon="mdi:percent" width={20} sx={{ color: 'secondary.main' }} />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.currency ? getTranslatedEnumValue(expense?.currency) : '-'}
                        </Typography>
                        <Typography variant="caption"> {t('crm.currency')}</Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* payment method */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="mdi:calculator-variant"
                          width={20}
                          sx={{ color: 'secondary.main' }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.paymentMethod
                            ? getTranslatedEnumValue(expense?.paymentMethod)
                            : '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.expense.expense.paymentMethod')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* totalAmount */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="mdi:calculator-variant"
                          width={20}
                          sx={{ color: 'secondary.main' }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.totalAmount ?? '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.expense.expense.totalAmount')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* totalTax */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="mdi:calculator-variant"
                          width={20}
                          sx={{ color: 'secondary.main' }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.totalTax ?? '-'}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.expense.expense.totalTax')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* isBillable */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="mdi:calculator-variant"
                          width={20}
                          sx={{ color: 'secondary.main' }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.isBillable ? t('yes') : t('no')}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.expense.expense.isBillable')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  {/* isInvoiced */}
                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <Box
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: 'secondary.extraLight',
                          borderRadius: '6px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Icon
                          icon="mdi:calculator-variant"
                          width={20}
                          sx={{ color: 'secondary.main' }}
                        />
                      </Box>
                      <Box>
                        <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                          {expense?.isInvoiced ? t('yes') : t('no')}
                        </Typography>
                        <Typography variant="caption">
                          {t('crm.expense.expense.isInvoiced')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};
