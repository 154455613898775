/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
  PermissionAction,
} from 'corede-common';
import {
  ICustomerCategory,
  ICustomerCategoryListInput,
  PermissionSubject,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useCustomerCategoryListQuery,
  useCustomerCategoryUpdateMutation,
} from '../../context/customerCategory.api';
import { CustomerCategoryDeleteDialog } from '../CustomerCategoryDeleteDialog';
import CustomerCategoryUpdateDrawerWrapper from '../update/CustomerCategoryUpdateDrawer.wrapper';
import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import { usePermissions } from 'permission/PermissionContext';

export interface ICustomerCategoryListGridComponent {
  // customerCategory
  setOpenCustomerCategoryCreateDrawer?: (value: boolean) => void | undefined;
}

const CustomerCategoryListGridComponent = memo((props: ICustomerCategoryListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedCustomerCategory, setSelectedCustomerCategory] =
    useState<TListGridSelectedEntity<ICustomerCategory>>();
  const [openCustomerCategoryDeleteDialog, setOpenCustomerCategoryDeleteDialog] = useState(false);
  const [openCustomerCategoryUpdateDrawer, setOpenCustomerCategoryUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.customerCategory,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.customerCategory,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.customerCategory,
    action: PermissionAction.delete,
  });

  const [customerCategoryListFilter, setCustomerCategoryListFilter] = useState<
    IGraphqlVariables<ICustomerCategoryListInput>
  >({
    input: {
      filter: {},
      pagination: { page: 1, pageSize: 10 },
    },
  });

  // queries
  const {
    data: customerCategoryListData,
    isLoading: customerCategoryListLoading,
    isFetching: customerCategoryListFetching,
    error: customerCategoryListError,
    refetch,
  } = useCustomerCategoryListQuery(customerCategoryListFilter);

  // used for direct update in the list rows
  const [
    customerCategoryUpdate,
    { isLoading: customerCategoryUpdateLoading, error: customerCategoryUpdateError },
  ] = useCustomerCategoryUpdateMutation();

  // effects

  // useEffect(() => {
  //   props.setCustomerCategoryListLoading && props.setCustomerCategoryListLoading(customerCategoryListLoading);
  // }, [customerCategoryListLoading]);
  useEffect(() => {
    refetch();
    return () => {
      // Cleanup function
    };
  }, [customerCategoryListFilter]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(customerCategoryListError as IBaseError, currentLanguage);
    return () => {
      // Cleanup function
    };
  }, [customerCategoryListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      customerCategoryUpdateError as IBaseError,
      currentLanguage
    );
    return () => {
      // Cleanup function
    };
  }, [customerCategoryUpdateError]);

  const isEmptyState =
    customerCategoryListData?.data?.length === 0 &&
    !customerCategoryListLoading &&
    !customerCategoryListFetching &&
    isNullOrUndefinedOrEmptyObject(customerCategoryListFilter?.input?.filter);

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedCustomerCategory}
      setSelectedEntity={setSelectedCustomerCategory}
      listFilter={customerCategoryListFilter}
      setListFilter={setCustomerCategoryListFilter}
      listData={customerCategoryListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {customerCategoryListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                customerCategoryListData?.data?.map((customerCategory, index) => ({
                  id:
                    ((customerCategoryListFilter.input?.pagination?.page || 1) - 1) *
                      (customerCategoryListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: customerCategory._id,
                  name: customerCategory.name,
                  icon: customerCategory.icon,
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'name',
                      filterable: true,
                      editable: hasUpdatePermission,
                      width: 200,
                    },
                    headerConfig: {
                      icon: 'radix-icons:text',
                      name: t('crm.name'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.icon,
                    column: {
                      field: 'icon',
                      editable: false,
                      filterable: false,
                      align: 'left',
                      width: 100,
                    },
                    headerConfig: {
                      icon: 'tdesign:icon',
                      name: t('crm.icon'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  edit: hasUpdatePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedCustomerCategory,
                          setOpenAction: setOpenCustomerCategoryUpdateDrawer,
                        },
                      }
                    : undefined,
                  delete: hasDeletePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedCustomerCategory,
                          setOpenAction: setOpenCustomerCategoryDeleteDialog,
                        },
                      }
                    : undefined,
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={
                customerCategoryListLoading ||
                customerCategoryUpdateLoading ||
                customerCategoryListFetching
              }
              listFilter={{
                filterInput: customerCategoryListFilter,
                setFilterInput: setCustomerCategoryListFilter,
              }}
              update={{
                updateQuery: customerCategoryUpdate,
              }}
              count={customerCategoryListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  fullName: false,
                  website: false,
                  company: false,
                  sector: false,
                  country: false,
                  state: false,
                  city: false,
                  language: false,
                  customerCategoryValue: false,
                  department: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.customer.customerCategory.emptyState1')}
              content2={t('crm.customer.customerCategory.emptyState2')}
              button1={{
                title: t('crm.customer.customerCategory.create'),
                onClick: () => (props?.setOpenCustomerCategoryCreateDrawer ?? (() => {}))(true),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
                permission: {
                  subject: PermissionSubject.customerCategory,
                  action: PermissionAction.create,
                },
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      <CustomerCategoryUpdateDrawerWrapper
        open={openCustomerCategoryUpdateDrawer}
        setOpen={setOpenCustomerCategoryUpdateDrawer}
        selectedCustomerCategory={selectedCustomerCategory}
      />

      <CustomerCategoryDeleteDialog
        open={openCustomerCategoryDeleteDialog}
        estimateId={selectedCustomerCategory?._id ?? ''}
        setEstimate={setSelectedCustomerCategory}
        onClose={() => {
          setSelectedCustomerCategory(undefined);
          setOpenCustomerCategoryDeleteDialog(false);
        }}
        key={selectedCustomerCategory?._id}
      />
    </DefaultListGridComponent>
  );
});

export default CustomerCategoryListGridComponent;
