import {
  projectListQuery,
  IProjectListRequest,
  IProjectListInput,
  IProjectListResponse,
  IProjectListResult,
  projectListByRelatedQuery,
  IProjectListByRelatedRequest,
  IProjectListByRelatedInput,
  IProjectListByRelatedResponse,
  IProjectListByRelatedResult,
  projectListCountQuery,
  IProjectListCountResponse,
  projectDetailQuery,
  IProjectDetailRequest,
  IProjectDetailInput,
  IProjectDetailResponse,
  IProjectDetailResult,
  projectListOwnQuery,
  IProjectListOwnRequest,
  IProjectListOwnInput,
  IProjectListOwnResponse,
  IProjectListOwnResult,
  projectCreateQuery,
  IProjectCreateRequest,
  IProjectCreateInput,
  IProjectCreateResponse,
  IProjectCreateResult,
  projectUpdateQuery,
  IProjectUpdateRequest,
  IProjectUpdateInput,
  IProjectUpdateFilterInput,
  IProjectUpdateResponse,
  IProjectUpdateResult,
  projectDeleteQuery,
  IProjectDeleteRequest,
  IProjectDeleteInput,
  IProjectDeleteResponse,
  IProjectDeleteResult,
  projectTimeSheetCreateQuery,
  IProjectTimeSheetCreateRequest,
  IProjectTimeSheetCreateInput,
  IProjectTimeSheetCreateResponse,
  IProjectTimeSheetCreateResult,
  IProjectTimeSheetCreateFilterInput,
  projectTimeSheetUpdateQuery,
  IProjectTimeSheetUpdateRequest,
  IProjectTimeSheetUpdateInput,
  IProjectTimeSheetUpdateFilterInput,
  IProjectTimeSheetUpdateResponse,
  IProjectTimeSheetUpdateResult,
  projectTimeSheetDeleteQuery,
  IProjectTimeSheetDeleteRequest,
  IProjectTimeSheetDeleteInput,
  IProjectTimeSheetDeleteResponse,
  IProjectTimeSheetDeleteResult,
  projectMilestoneCreateQuery,
  IProjectMilestoneCreateRequest,
  IProjectMilestoneCreateInput,
  IProjectMilestoneCreateResponse,
  IProjectMilestoneCreateResult,
  projectMilestoneUpdateQuery,
  IProjectMilestoneUpdateRequest,
  IProjectMilestoneUpdateInput,
  IProjectMilestoneUpdateFilterInput,
  IProjectMilestoneUpdateResponse,
  IProjectMilestoneUpdateResult,
  projectMilestoneDeleteQuery,
  IProjectMilestoneDeleteRequest,
  IProjectMilestoneDeleteInput,
  IProjectMilestoneDeleteResponse,
  IProjectMilestoneDeleteResult,
  IProjectMilestoneCreateFilterInput,
  projectMilestoneTaskAssignQuery,
  IProjectMilestoneTaskAssignRequest,
  IProjectMilestoneTaskAssignInput,
  IProjectMilestoneTaskAssignResponse,
  IProjectMilestoneTaskAssignResult,
  IProjectMilestoneTaskAssignFilterInput,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { projectTags } from './project.tags';
import { IGraphqlVariables, IBaseGraphqlRequest, ICount } from 'corede-common';

export const projectsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    projectList: builder.query<IProjectListResult, IGraphqlVariables<IProjectListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectListRequest,
        IProjectListResponse,
        IProjectListResult,
        IProjectListInput
      >({
        query: projectListQuery(),
        providesTags: [projectTags.projects],
      }),
    ),

    projectListByRelated: builder.query<
      IProjectListByRelatedResult,
      IGraphqlVariables<IProjectListByRelatedInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectListByRelatedRequest,
        IProjectListByRelatedResponse,
        IProjectListByRelatedResult,
        IProjectListByRelatedInput
      >({
        query: projectListByRelatedQuery,
        providesTags: [projectTags.projects],
      }),
    ),

    projectListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IBaseGraphqlRequest,
        IProjectListCountResponse,
        ICount,
        undefined
      >({
        query: projectListCountQuery,
        providesTags: [projectTags.projects],
      }),
    ),

    projectDetail: builder.query<IProjectDetailResult, IGraphqlVariables<IProjectDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectDetailRequest,
        IProjectDetailResponse,
        IProjectDetailResult,
        IProjectDetailInput
      >({
        query: projectDetailQuery(),
        providesTags: [projectTags.project],
      }),
    ),

    projectListOwn: builder.query<IProjectListOwnResult, IGraphqlVariables<IProjectListOwnInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectListOwnRequest,
        IProjectListOwnResponse,
        IProjectListOwnResult,
        IProjectListOwnInput
      >({
        query: projectListOwnQuery(),
        providesTags: [projectTags.projects],
      }),
    ),

    // mutation
    projectCreate: builder.mutation<IProjectCreateResult, IGraphqlVariables<IProjectCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectCreateRequest,
        IProjectCreateResponse,
        IProjectCreateResult,
        IProjectCreateInput
      >({
        query: projectCreateQuery,
        invalidatesTags: [projectTags.projects],
      }),
    ),

    projectUpdate: builder.mutation<
      IProjectUpdateResult,
      IGraphqlVariables<IProjectUpdateInput, IProjectUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectUpdateRequest,
        IProjectUpdateResponse,
        IProjectUpdateResult,
        IProjectUpdateInput,
        IProjectUpdateFilterInput
      >({
        query: projectUpdateQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectDelete: builder.mutation<IProjectDeleteResult, IGraphqlVariables<IProjectDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectDeleteRequest,
        IProjectDeleteResponse,
        IProjectDeleteResult,
        IProjectDeleteInput
      >({
        query: projectDeleteQuery,
        invalidatesTags: [projectTags.projects],
      }),
    ),

    projectTimeSheetCreate: builder.mutation<
      IProjectTimeSheetCreateResult,
      IGraphqlVariables<IProjectTimeSheetCreateInput, IProjectTimeSheetCreateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectTimeSheetCreateRequest,
        IProjectTimeSheetCreateResponse,
        IProjectTimeSheetCreateResult,
        IProjectTimeSheetCreateInput,
        IProjectTimeSheetCreateFilterInput
      >({
        query: projectTimeSheetCreateQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectTimeSheetUpdate: builder.mutation<
      IProjectTimeSheetUpdateResult,
      IGraphqlVariables<IProjectTimeSheetUpdateInput, IProjectTimeSheetUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectTimeSheetUpdateRequest,
        IProjectTimeSheetUpdateResponse,
        IProjectTimeSheetUpdateResult,
        IProjectTimeSheetUpdateInput,
        IProjectTimeSheetUpdateFilterInput
      >({
        query: projectTimeSheetUpdateQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectTimeSheetDelete: builder.mutation<
      IProjectTimeSheetDeleteResult,
      IGraphqlVariables<IProjectTimeSheetDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectTimeSheetDeleteRequest,
        IProjectTimeSheetDeleteResponse,
        IProjectTimeSheetDeleteResult,
        IProjectTimeSheetDeleteInput
      >({
        query: projectTimeSheetDeleteQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectMilestoneCreate: builder.mutation<
      IProjectMilestoneCreateResult,
      IGraphqlVariables<IProjectMilestoneCreateInput, IProjectMilestoneCreateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectMilestoneCreateRequest,
        IProjectMilestoneCreateResponse,
        IProjectMilestoneCreateResult,
        IProjectMilestoneCreateInput,
        IProjectMilestoneCreateFilterInput
      >({
        query: projectMilestoneCreateQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectMilestoneUpdate: builder.mutation<
      IProjectMilestoneUpdateResult,
      IGraphqlVariables<IProjectMilestoneUpdateInput, IProjectMilestoneUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectMilestoneUpdateRequest,
        IProjectMilestoneUpdateResponse,
        IProjectMilestoneUpdateResult,
        IProjectMilestoneUpdateInput,
        IProjectMilestoneUpdateFilterInput
      >({
        query: projectMilestoneUpdateQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectMilestoneKanbanUpdate: builder.mutation<
      IProjectMilestoneUpdateResult,
      IGraphqlVariables<IProjectMilestoneUpdateInput, IProjectMilestoneUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectMilestoneUpdateRequest,
        IProjectMilestoneUpdateResponse,
        IProjectMilestoneUpdateResult,
        IProjectMilestoneUpdateInput,
        IProjectMilestoneUpdateFilterInput
      >({
        query: projectMilestoneUpdateQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectMilestoneDelete: builder.mutation<
      IProjectMilestoneDeleteResult,
      IGraphqlVariables<IProjectMilestoneDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectMilestoneDeleteRequest,
        IProjectMilestoneDeleteResponse,
        IProjectMilestoneDeleteResult,
        IProjectMilestoneDeleteInput
      >({
        query: projectMilestoneDeleteQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),

    projectMilestoneTaskAssign: builder.mutation<
      IProjectMilestoneTaskAssignResult,
      IGraphqlVariables<IProjectMilestoneTaskAssignInput, IProjectMilestoneTaskAssignFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IProjectMilestoneTaskAssignRequest,
        IProjectMilestoneTaskAssignResponse,
        IProjectMilestoneTaskAssignResult,
        IProjectMilestoneTaskAssignInput,
        IProjectMilestoneTaskAssignFilterInput
      >({
        query: projectMilestoneTaskAssignQuery,
        invalidatesTags: [projectTags.projects, projectTags.project],
      }),
    ),
  }),
});

export const {
  useProjectListQuery,
  useLazyProjectListQuery,
  useProjectListByRelatedQuery,
  useLazyProjectListByRelatedQuery,
  useProjectListCountQuery,
  useLazyProjectListCountQuery,
  useProjectDetailQuery,
  useLazyProjectDetailQuery,
  useProjectListOwnQuery,
  useLazyProjectListOwnQuery,

  useProjectCreateMutation,
  useProjectUpdateMutation,
  useProjectDeleteMutation,
  useProjectTimeSheetCreateMutation,
  useProjectTimeSheetUpdateMutation,
  useProjectTimeSheetDeleteMutation,
  useProjectMilestoneCreateMutation,
  useProjectMilestoneUpdateMutation,
  useProjectMilestoneKanbanUpdateMutation,
  useProjectMilestoneDeleteMutation,
  useProjectMilestoneTaskAssignMutation,
} = projectsApi;
