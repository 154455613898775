/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Stack, Button, Divider, Theme, useMediaQuery } from '@mui/material';
import { Update } from '@mui/icons-material';
import { Iconify } from 'components';
import {
  ILeadDetailResult,
  LeadQualification,
  LeadType,
  PermissionSubject,
} from 'corede-common-cocrm';
import { convertEnums } from 'localization';
import { Language, PermissionAction } from 'corede-common';
import { t } from 'i18next';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';
import { PermissionWrapper } from 'permission/PermissionWrapper';

interface ILeadDetailTabInfoSectionComponent extends DefaultTabComponentProps<ILeadDetailResult> {
  leadData: ILeadDetailResult | undefined;
  setOpenLeadUpdateDrawer: (value: boolean) => void;
  tabKey: 'info';
  name: 'crm.info';
}

export const LeadDetailTabInfoSectionComponent = (props: ILeadDetailTabInfoSectionComponent) => {
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const localizedLeadQualifications = convertEnums(LeadQualification);
  const localizedLeadTypes = convertEnums(LeadType);
  const localizedLanguages = convertEnums(Language);

  const companyData = [
    {
      title: t('crm.company'),
      value: props.leadData?.company,
      icon: 'ic:round-home-work',
    },
    {
      title: t('crm.position'),
      value: props.leadData?.position,
      icon: 'iconoir:position',
    },
    {
      title: t('crm.sector'),
      value: props.leadData?.sector,
      icon: 'mingcute:sector-line',
    },
    {
      title: t('crm.website'),
      value: props.leadData?.website,
      icon: 'fluent-mdl2:website',
    },
  ];

  const localizationData = [
    {
      title: t('crm.country'),
      value: props.leadData?.country,
      icon: 'clarity:map-marker-line',
    },
    {
      title: t('crm.city'),
      value: props.leadData?.city,
      icon: 'solar:city-broken',
    },
    {
      title: t('crm.state'),
      value: props.leadData?.state,
      icon: 'mage:location',
    },
    {
      title: t('crm.language'),
      value:
        localizedLanguages[(props.leadData as any)?.language as keyof typeof localizedLanguages],
      icon: 'tabler:language',
    },
  ];

  return (
    <Grid container mt={downMd ? 0 : 2} spacing={2.5} mb={2} px={{ xs: 0, lg: 2 }}>
      <Grid item xs={12} md={4}>
        <Stack
          direction={'column'}
          p={3}
          gap={1.5}
          sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
        >
          <Stack direction={'column'} width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Typography variant={'overline'} sx={{ fontWeight: 500 }}>
                {t('crm.about')}
              </Typography>

              <PermissionWrapper
                check={{
                  subject: PermissionSubject.lead,
                  action: PermissionAction.update,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    width: 'auto',
                    height: 32,
                    fontSize: { xs: '13px', md: '13px' },
                    lineHeight: 1,
                    borderRadius: '24px',
                  }}
                  onClick={() => props.setOpenLeadUpdateDrawer(true)}
                >
                  <Update sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />
                  {t('crm.update')}
                </Button>
              </PermissionWrapper>
            </Stack>

            <Divider sx={{ mt: 1 }} />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              Full Name:
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {props.leadData?.fullName}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.lead.lead.leadType')}:
            </Typography>
            <Button
              sx={{
                padding: '4px 8px !important',
                height: 'auto',
                borderRadius: 0.5,
                backgroundColor: 'primary.extraLight',
              }}
            >
              <Typography
                variant={'caption'}
                sx={{ fontWeight: 500, lineHeight: 1, color: 'primary.main' }}
              >
                {localizedLeadTypes[
                  (props.leadData as any)?.type as keyof typeof localizedLeadTypes
                ] ?? ''}
              </Typography>
            </Button>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.status')}:
            </Typography>
            <Button
              sx={{
                padding: '4px 8px !important',
                height: 'auto',
                borderRadius: 0.5,
                backgroundColor: 'success.extraLight',
              }}
            >
              <Typography
                variant={'caption'}
                sx={{ fontWeight: 500, lineHeight: 1, color: 'success.main' }}
              >
                {props.leadData?.status?.name}
              </Typography>
            </Button>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.source')}:
            </Typography>
            <Button
              sx={{
                padding: '4px 8px !important',
                height: 'auto',
                borderRadius: 0.5,
                backgroundColor: 'warning.extraLight',
              }}
            >
              <Typography
                variant={'caption'}
                sx={{ fontWeight: 500, lineHeight: 1, color: 'warning.main' }}
              >
                {props.leadData?.source?.name}
              </Typography>
            </Button>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.lead.lead.qualification')}:
            </Typography>
            <Button
              sx={{
                padding: '4px 8px !important',
                height: 'auto',
                borderRadius: 0.5,
                backgroundColor: 'success.extraLight',
              }}
            >
              <Typography
                variant={'caption'}
                sx={{ fontWeight: 500, lineHeight: 1, color: 'success.main' }}
              >
                {localizedLeadQualifications[
                  (props.leadData as any)?.qualification as keyof typeof localizedLeadQualifications
                ] ?? ''}
              </Typography>
            </Button>
          </Stack>

          <Stack direction={'column'} width={'100%'}>
            <Typography variant={'overline'} sx={{ fontWeight: 500 }}>
              {t('crm.contact')}
            </Typography>
            <Divider />
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.contact')}:
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {props.leadData?.phoneNumber ?? '-'}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.website')}:
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {props.leadData?.website ?? '-'}
            </Typography>
          </Stack>

          <Stack direction={'row'} alignItems={'center'} gap={0.5} width={'100%'}>
            <Typography variant={'caption'} sx={{ fontWeight: 500 }}>
              {t('crm.email')}:
            </Typography>
            <Typography variant={'caption'} sx={{ fontWeight: 500, color: 'text.secondary' }}>
              {props.leadData?.email ?? '-'}
            </Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack direction={'column'} py={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.company')}
          </Typography>
          <Grid container>
            {companyData.map((item) => (
              <Grid item xs={6} md={3}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>

        {/* Localization */}
        <Stack direction={'column'} pb={2.5}>
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.localization')}
          </Typography>
          <Grid container>
            {localizationData.map((item) => (
              <Grid item xs={6} md={3}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
