import {
  IPermissionListInput,
  IPermissionListRequest,
  IPermissionListResponse,
  IPermissionListResult,
  permissionListQuery,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { permissionsTags } from './permissions.tags';
import { IGraphqlVariables } from 'corede-common';

export const permissionsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    permissionList: builder.query<IPermissionListResult, IGraphqlVariables<IPermissionListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPermissionListRequest,
        IPermissionListResponse,
        IPermissionListResult,
        IPermissionListInput
      >({
        query: permissionListQuery,
        providesTags: [permissionsTags.permissions],
      }),
    ),
  }),
});

export const { usePermissionListQuery, useLazyPermissionListQuery } = permissionsApi;
