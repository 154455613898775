/* eslint-disable react-hooks/exhaustive-deps */
import { useTicketDetailQuery } from '../../context/support.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { ITicket, ITicketDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { TicketDetailDrawerLeftPanelComponent } from './TicketDetailDrawer-leftPanel.component';
import { TicketDetailDrawerRightPanelComponent } from './TicketDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface ITicketDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedTicket: ITicketDetailResult & IHasId<number>;
  setSelectedTicket: Dispatch<SetStateAction<TListGridSelectedEntity<ITicket>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const TicketDetailDrawer = memo((props: ITicketDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: ticketDetailData, error: ticketDetailError } = useTicketDetailQuery(
    props?.selectedTicket?._id
      ? {
          input: {
            _id: props?.selectedTicket?._id,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(ticketDetailError as IBaseError, currentLanguage);
  }, [ticketDetailError]);

  return (
    <DefaultDetailDrawer<ITicketDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedTicket}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.support.support.title'),
            }
      }
    >
      <TicketDetailDrawerLeftPanelComponent
        ticketDetailData={ticketDetailData}
        setOpen={props.setOpen}
      />
      <TicketDetailDrawerRightPanelComponent ticketDetailData={ticketDetailData} />
    </DefaultDetailDrawer>
  );
});

export default TicketDetailDrawer;
