/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  Tooltip,
  IconButton,
} from '@mui/material';
import { FilledButton, Iconify } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ActionDrawer from 'components/drawer/ActionDrawer';
import InputAdornment from '@mui/material/InputAdornment';
import { useCreateContactFormMutation, useUserDetailOwnQuery } from 'apps/auth/context';
import { ContactFormSource, ContactFormType, ICreateContactFormInput } from 'corede-common-cocrm';
import { IGraphqlVariables, Language, unknownError } from 'corede-common';
import { Controller, useForm } from 'react-hook-form';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import CommunityImage from 'assets/images/community.png';

const List = () => {
  const { t } = useTranslation();
  const [openForm, setOpenForm] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const currentLanguage = getCurrentLanguage();
  // const [message, setMessage] = useState("");
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [
    createContactForm,
    {
      data: createContactFormData,
      isLoading: createContactFormLoading,
      error: createContactFormError,
    },
  ] = useCreateContactFormMutation();

  const initialValues = {
    input: {
      fullName: userDetailOwnData?.name + ' ' + userDetailOwnData?.surname || '',
      email: userDetailOwnData?.email || '',
      subject: 'Community Form',
      message: '',
      type: ContactFormType.waitlist,
      source: ContactFormSource.community,
      language: Language[currentLanguage],
      phone: userDetailOwnData?.phoneNumber,
      orgName: userDetailOwnData?.organization?.name,
    },
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IGraphqlVariables<ICreateContactFormInput & { phone?: string; orgName?: string }>>({
    defaultValues: initialValues,
    mode: 'onSubmit',
  });

  const drawerInputsLabelStyles = {
    fontWeight: 600,
    fontSize: 'small',
    mb: 0.5,
    color: 'text.primary',
  };

  const onSubmit = (
    values: IGraphqlVariables<ICreateContactFormInput & { phone?: string; orgName?: string }>,
  ) => {
    createContactForm({
      input: {
        fullName: values.input?.fullName,
        email: values.input?.email || '',
        subject: values.input?.subject || '',
        language: values.input?.language || Language[currentLanguage],
        source: values.input?.source || ContactFormSource.community,
        type: values.input?.type || ContactFormType.request,
        message: 'Phone: ' + values.input?.phone + ', OrgName: ' + values.input?.orgName,
      },
    });
  };

  useEffect(() => {
    if (createContactFormData) {
      setOpenForm(false);
      enqueueSnackbar(t('community.demo.demo.sendSuccess'), {
        variant: 'success',
      });
    }
  }, [createContactFormData]);

  useEffect(() => {
    if (createContactFormError) {
      enqueueSnackbar(
        (createContactFormError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [createContactFormError]);

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Stack direction={'column'} justifyContent={'center'} gap={2}>
          <Typography variant="h4">
            <Trans
              i18nKey={t('community.demo.demo.title')}
              components={{
                highlight: <span style={{ color: '#FF7D7D' }} />,
              }}
            />
          </Typography>
          <Typography variant="body1">{t('community.demo.demo.description')}</Typography>

          <Typography variant="h5">{t('community.demo.demo.featuresTitle')}</Typography>

          <ul style={{ margin: 0 }}>
            {[1, 2, 3, 4].map((item, index) => (
              <li key={index} style={{ marginBottom: '8px' }}>
                <Typography variant="body1">{t(`community.demo.demo.features${item}`)}</Typography>
              </li>
            ))}
          </ul>

          <Box
            sx={{
              animation: 'bounce 2s ease-in-out infinite',
              '@keyframes bounce': {
                '0%, 100%': {
                  transform: 'translateY(0)',
                },
                '50%': {
                  transform: 'translateY(-5px)',
                },
              },
            }}
          >
            <FilledButton
              color="secondary"
              title={t('community.demo.demo.buttonTitle')}
              onClick={() => setOpenForm(true)}
            />
          </Box>
        </Stack>
      </Grid>

      {!downMd && (
        <Grid item xs={12} lg={5}>
          <Stack alignItems={'end'}>
            <img
              src={CommunityImage}
              alt="Community"
              width={'100%'}
              style={{ maxWidth: '416px' }}
            />
          </Stack>
        </Grid>
      )}

      <ActionDrawer
        open={openForm}
        setOpen={setOpenForm}
        size="small"
        buttonTitle={t('community.demo.demo.formButtonTitle')}
        backgroundColor="background.default"
        handleSubmit={handleSubmit(onSubmit)}
        loading={createContactFormLoading}
        title={
          <Stack direction="row" alignItems="center" justifyContent={'space-between'}>
            <Typography variant="h5">{t('community.demo.demo.formTitle')}</Typography>

            <Tooltip title={t('webNotification.close')}>
              <IconButton sx={{ cursor: 'pointer' }} onClick={() => setOpenForm(false)}>
                <Iconify icon={'material-symbols:close'} />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      >
        <Stack direction={'column'} p={3}>
          <Box mb={3}>
            <Typography variant="body2">{t('community.demo.demo.formDescription')}</Typography>
          </Box>

          <Box mb={2}>
            <InputLabel sx={drawerInputsLabelStyles}>
              {t('community.demo.demo.fullName')}
            </InputLabel>
            <Controller
              name="input.fullName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="fullName"
                  error={!!errors.input?.fullName}
                  helperText={errors.input?.fullName?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents={true}>
                        <Iconify icon="solar:user-bold" sx={{ color: 'secondary.inputColor' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <InputLabel sx={drawerInputsLabelStyles}>{t('community.demo.demo.orgName')}</InputLabel>
            <Controller
              name="input.orgName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="orgName"
                  error={!!errors.input?.orgName}
                  helperText={errors.input?.orgName?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents={true}>
                        <Iconify icon="ic:round-home-work" sx={{ color: 'secondary.inputColor' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <InputLabel sx={drawerInputsLabelStyles}>{t('community.demo.demo.email')}</InputLabel>
            <Controller
              name="input.email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="email"
                  error={!!errors.input?.email}
                  helperText={errors.input?.email?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents={true}>
                        <Iconify icon="tabler:mail-filled" sx={{ color: 'secondary.inputColor' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
          <Box mb={2}>
            <InputLabel sx={drawerInputsLabelStyles}>{t('community.demo.demo.phone')}</InputLabel>
            <Controller
              name="input.phone"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  fullWidth
                  id="phone"
                  error={!!errors.input?.phone}
                  helperText={errors.input?.phone?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" disablePointerEvents={true}>
                        <Iconify icon="solar:phone-bold" sx={{ color: 'secondary.inputColor' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          {/* <Box mt={2}>
            <FilledButton
              color="secondary"
              title={t('community.demo.demo.formButtonTitle')}
              onClick={() => handleSubmit(onSubmit)()}
            />
          </Box> */}
        </Stack>
      </ActionDrawer>
    </>
  );
};

export default List;
