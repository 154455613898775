/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack, Typography } from '@mui/material';
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Iconify } from 'components';

export interface IInvoiceDetailDrawerLeftPanelAssignedsComponentProps {
  invoiceDetailData: IInvoiceDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  visible?: boolean;
}

/**
 * Container for views in assigned list section of left panel header
 */
export const InvoiceDetailDrawerLeftPanelAssignedsComponent = (
  props: IInvoiceDetailDrawerLeftPanelAssignedsComponentProps,
) => {
  const { t } = useTranslation();
  return props.visible ? (
    <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
      <Stack direction={'column'} width={'100%'} gap={0.5}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            color: 'text.primary',
          }}
        >
          {t('crm.sale.invoice.salesAgentsTitle')}
        </Typography>
        <Divider />
      </Stack>
      <Stack direction={'column'} width={'100%'} gap={1}>
        {props.invoiceDetailData?.salesAgents &&
        props.invoiceDetailData?.salesAgents?.length > 0 ? (
          props.invoiceDetailData?.salesAgents?.map((follower) => (
            <Stack
              direction={'row'}
              gap={1}
              alignItems={'center'}
              width={'100%'}
              key={follower?._id}
            >
              <Box
                sx={{
                  width: 32,
                  height: 32,
                  bgColor: 'secondary.extraLight',
                  borderRadius: '6px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Iconify icon="fa-solid:user-tie" width={18} sx={{ color: 'secondary.main' }} />
              </Box>
              <Box>
                {/* <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    lineHeight: 1,
                    color: 'text.primary',
                  }}
                >
                  {props.invoiceDetailData?.salesAgents?.length}
                </Typography> */}
                <Typography
                  variant="caption"
                  sx={{
                    color: 'text.secondary',
                  }}
                >
                  {follower?.name} {follower?.surname}
                </Typography>
              </Box>
            </Stack>
          ))
        ) : (
          <Stack direction={'row'} gap={2} alignItems={'center'} width={'100%'}>
            <Box
              sx={{
                width: 32,
                height: 32,
                bgColor: 'secondary.extraLight',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Iconify icon="fa-solid:user-tie" width={18} sx={{ color: 'secondary.main' }} />
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {t('crm.noSalentAgents')}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </Stack>
  ) : null;
};
