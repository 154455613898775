import { Stack, Typography, Grid } from '@mui/material';
import { OutlinedButton } from 'components/buttons';
import { IHasPermissionCheck } from 'permission/permission.interface';
import { PermissionWrapper } from 'permission/PermissionWrapper';
import { isValidElement } from 'react';

type Props = {
  children: React.ReactNode;
  title?: string;
  rightButton?: {
    title: string;
    onClick: () => void;
    icon?: React.ReactNode;
  } & IHasPermissionCheck;
  rightButton2?: {
    title: string;
    onClick: () => void;
    icon?: React.ReactNode;
  } & IHasPermissionCheck;
  type?: 'drawer' | null;
};

const DetailBox = (props: Props) => {
  return (
    <Stack
      direction={'column'}
      borderRadius={'20px'}
      py={props.type === 'drawer' ? 0 : 2}
      px={props.type === 'drawer' ? 2 : 4}
      sx={{ boxShadow: props.type === 'drawer' ? undefined : '0px 0px 20px #ccc' }}
    >
      <Stack direction="row" justifyContent={'flex-end'} gap={2}  alignItems={'center'}>
        <Typography fontSize={props.type === 'drawer' ? '16px' : '24px'} fontWeight={600}>
          {props.title}
        </Typography>
        {props?.rightButton && (
          <PermissionWrapper check={props.rightButton.permission}>
            <OutlinedButton
              title={props.rightButton?.title}
              onClick={props.rightButton?.onClick}
              leftIcon={
                isValidElement(props.rightButton?.icon) ? props.rightButton?.icon : undefined
              }
            />
          </PermissionWrapper>
        )}
        {props?.rightButton2 && (
          <PermissionWrapper check={props.rightButton2.permission}>
            <OutlinedButton
              title={props.rightButton2?.title}
              onClick={props.rightButton2?.onClick}
              leftIcon={
                isValidElement(props.rightButton2?.icon) ? props.rightButton2?.icon : undefined
              }
            />
          </PermissionWrapper>
        )}
      </Stack>
      <Grid container sx={{ mt: 2 }}>
        {props.children}
      </Grid>
    </Stack>
  );
};

export default DetailBox;
