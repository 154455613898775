/* eslint-disable react-hooks/exhaustive-deps */
import { PeopleAltOutlined, StorageOutlined } from '@mui/icons-material';
import { Box, Card, Chip, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { Iconify, OutlinedButton, SettingsTitle } from 'components';
import ActionDialog from 'components/dialog/ActionDialog';
import {
  IOrganizationCardDetailOwnResult,
  IOrganizationDetailResult,
  ISubscriptionDetailResult,
  OrganizationStatus,
  RecurringInterval,
  SubscriptionStatus,
} from 'corede-common-cocrm';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'apps/crm/domains/16-pricing/subdomains/pricing/pages';

export const PaymentCurrentPlan = (props: {
  setSelectedTabName: (tabName: string) => void;
  subscriptionData: ISubscriptionDetailResult | undefined;
  organizationData: IOrganizationDetailResult | undefined;
  organizationCardData: IOrganizationCardDetailOwnResult | undefined;
}) => {
  const { t } = useTranslation();
  const [openPricingPackages, setOpenPricingPackages] = useState(false);
  const [statusChip, setStatusChip] = useState<{ label: string; color: string } | undefined>(
    undefined,
  );

  useEffect(() => {
    switch (props.organizationData?.status) {
      case OrganizationStatus.active:
        setStatusChip({
          label: t('crm.pricing.pricing.active'),
          color: 'success',
        });
        break;
      case OrganizationStatus.passive:
        setStatusChip({
          label: t('crm.pricing.pricing.passive'),
          color: 'warning',
        });
        break;
      case OrganizationStatus.restricted:
        if (props.organizationData?.activeSubscriptions?.main) {
          switch (props.organizationData?.activeSubscriptions?.main?.status) {
            case SubscriptionStatus.cancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.cancelled'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.expired:
              setStatusChip({
                label: t('crm.pricing.pricing.expired'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.completed:
              setStatusChip({
                label: t('crm.pricing.pricing.completed'),
                color: 'success',
              });
              break;
            case SubscriptionStatus.failed:
              setStatusChip({
                label: t('crm.pricing.pricing.failed'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithCancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithCancelled'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithFailure:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithFailure'),
                color: 'success',
              });
              break;
            default:
              setStatusChip(undefined);
          }
        } else {
          switch (props.organizationData?.activeSubscriptions?.trial?.status) {
            case SubscriptionStatus.cancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.cancelled'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.expired:
              setStatusChip({
                label: t('crm.pricing.pricing.expired'),
                color: 'error',
              });
              break;
            case SubscriptionStatus.completed:
              setStatusChip({
                label: t('crm.pricing.pricing.completed'),
                color: 'success',
              });
              break;
            case SubscriptionStatus.failed:
              setStatusChip({
                label: t('crm.pricing.pricing.failed'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithCancelled:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithCancelled'),
                color: 'warning',
              });
              break;
            case SubscriptionStatus.ongoingWithFailure:
              setStatusChip({
                label: t('crm.pricing.pricing.ongoingWithFailure'),
                color: 'success',
              });
              break;
            default:
              setStatusChip(undefined);
              break;
          }
        }
        break;
      default:
        setStatusChip(undefined);
        break;
    }
  }, [props.organizationData]);

  const nextPaymentDate = props.subscriptionData?.nextPaymentDate;
  const today = moment();
  const targetDate = moment(nextPaymentDate);
  const daysUntilNextPayment = targetDate.diff(today, 'days');
  const pastDays =
    props.subscriptionData?.recurringInterval === RecurringInterval.monthly
      ? 30 - daysUntilNextPayment
      : 365 - daysUntilNextPayment;

  const trialDueDate = props.organizationData?.activeSubscriptions?.trial?.dueDate;
  const trialStartDate = props.organizationData?.activeSubscriptions?.trial?.startDate;

  return (
    <Grid container spacing={2} px={2}>
      <Grid item xs={12}>
        <Box p={3} border="1px solid" borderColor="divider" borderRadius={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack direction={'column'} gap={2}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Typography fontSize={16} fontWeight={500}>
                    {t('crm.pricing.pricing.currentPlan')}
                  </Typography>
                  {statusChip && (
                    <Chip
                      label={statusChip.label}
                      color={statusChip.color as any}
                      sx={{ height: 32, px: 3 }}
                    />
                  )}
                  {
                    <Box sx={{ visibility: 'hidden' }}>
                      <OutlinedButton
                        title={t('settings.settings.settings.browsePackages')}
                        onClick={() => setOpenPricingPackages(true)}
                      />
                    </Box>
                  }
                </Stack>
                <Stack direction={'column'} gap={1}>
                  <Typography fontSize={14} fontWeight={500}>
                    {t('settings.settings.settings.yourCurrentPlan')}{' '}
                    {props.subscriptionData?.paymentProduct?.name ??
                      (props.organizationData?.activeSubscriptions?.trial
                        ? t('settings.settings.settings.trial')
                        : '')}
                  </Typography>
                  <Typography fontSize={12} color={'text.secondary'}>
                    {t('settings.settings.settings.yourCurrentPlanDesc')}
                  </Typography>
                </Stack>
                <Stack direction={'column'} gap={1}>
                  <Typography fontSize={14} fontWeight={500}>
                    {t('settings.settings.settings.activeUntil')}{' '}
                    {props.organizationData?.activeSubscriptions?.main
                      ? props.subscriptionData?.nextPaymentDate
                        ? moment(props.subscriptionData?.nextPaymentDate).format('DD.MM.YYYY')
                        : ''
                      : props.organizationData?.activeSubscriptions?.trial
                        ? trialDueDate
                          ? moment(trialDueDate).format('DD.MM.YYYY')
                          : ''
                        : ''}
                  </Typography>
                  <Typography fontSize={12} color={'text.secondary'}>
                    {t('settings.settings.settings.activeUntilDesc')}
                  </Typography>
                </Stack>
                <Stack direction={'column'} gap={1}>
                  {props?.organizationData?.activeSubscriptions?.main ? (
                    <Typography fontSize={14} fontWeight={500}>
                      {'$'}
                      {props.subscriptionData?.recurringInterval === RecurringInterval.monthly
                        ? props.subscriptionData?.paymentProduct?.recurring?.monthly?.pricing
                            ?.discountedPrice
                        : props.subscriptionData?.paymentProduct?.recurring?.yearly?.pricing
                            ?.discountedPrice}{' '}
                      {props.subscriptionData?.recurringInterval === RecurringInterval.monthly
                        ? t('settings.settings.settings.perMonth')
                        : t('settings.settings.settings.prtYear')}{' '}
                    </Typography>
                  ) : props?.organizationData?.activeSubscriptions?.trial ? (
                    <Typography fontSize={14} fontWeight={500}>
                      {t('settings.settings.settings.trial')}
                    </Typography>
                  ) : null}
                  <Typography fontSize={12} color={'text.secondary'}>
                    {props.organizationData?.activeSubscriptions?.main
                      ? t('settings.settings.settings.paymentDesc')
                      : props.organizationData?.activeSubscriptions?.trial
                        ? t('settings.settings.settings.paymentForTrialDesc')
                        : ''}
                  </Typography>
                </Stack>
                <Stack direction={'row'} gap={2}>
                  <Chip
                    label={
                      props.organizationData?.activeSubscriptions?.main?.status ===
                      SubscriptionStatus.ongoing
                        ? t('settings.settings.settings.upgradePlan')
                        : t('settings.settings.settings.browsePackages')
                    }
                    color={'secondary'}
                    sx={{ height: 32, cursor: 'pointer' }}
                    disabled={
                      props.organizationData?.activeSubscriptions?.main?.status ===
                      SubscriptionStatus.ongoing
                    }
                    onClick={() => {
                      setOpenPricingPackages(true);
                    }}
                  />
                  <Chip
                    label={t('settings.settings.settings.cancelSubscription')}
                    color={'error'}
                    sx={{
                      height: 32,
                      bgcolor: '#FF4C5129',
                      color: 'secondary.dark',
                      cursor: 'pointer',
                    }}
                    disabled
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
            >
              <Stack direction={'column'} gap={2} width={'100%'}>
                {props.organizationData?.activeSubscriptions?.main?.status !==
                  SubscriptionStatus.ongoing && (
                  <Box bgcolor={'warning.lighter'} p={1} borderRadius={1}>
                    <Stack direction={'row'} gap={2}>
                      <Box
                        bgcolor={'warning.main'}
                        borderRadius={1}
                        sx={{
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Iconify
                          icon={'typcn:warning-outline'}
                          width={20}
                          sx={{ color: 'white' }}
                        />
                      </Box>
                      <Stack direction={'column'} gap={1}>
                        <Typography fontSize={14} fontWeight={500} color={'warning.main'}>
                          {t('settings.settings.settings.attentionTitle')}
                        </Typography>
                        <Typography fontSize={12} color={'warning.main'}>
                          {t('settings.settings.settings.attentionDesc')}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                )}

                <Stack direction={'column'} gap={1}>
                  <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography fontSize={14} fontWeight={500}>
                      {t('settings.settings.settings.days')}
                    </Typography>
                    <Typography fontSize={14} fontWeight={500}>
                      {props.organizationData?.activeSubscriptions?.main
                        ? pastDays
                        : props.organizationData?.activeSubscriptions?.trial
                          ? trialStartDate
                            ? moment().diff(moment(trialStartDate), 'days') < 8
                              ? moment().diff(moment(trialStartDate), 'days')
                              : 7
                            : 0
                          : 0}{' '}
                      {t('settings.settings.settings.of')}{' '}
                      {props.organizationData?.activeSubscriptions?.main
                        ? props.subscriptionData?.recurringInterval === RecurringInterval.monthly
                          ? 30
                          : 365
                        : props.organizationData?.activeSubscriptions?.trial
                          ? 7
                          : 0}{' '}
                      {t('settings.settings.settings.days')}
                    </Typography>
                  </Stack>
                  <LinearProgress
                    variant="determinate"
                    color="secondary"
                    sx={{
                      borderRadius: 2,
                      height: 8,
                    }}
                    value={
                      props.organizationData?.activeSubscriptions?.main
                        ? (pastDays /
                            (props.subscriptionData?.recurringInterval === RecurringInterval.monthly
                              ? 30
                              : 365)) *
                          100
                        : props.organizationData?.activeSubscriptions?.trial
                          ? trialStartDate
                            ? ((moment().diff(moment(trialStartDate), 'hours') < 169
                                ? moment().diff(moment(trialStartDate), 'hours')
                                : 168) /
                                168) *
                              100
                            : 0
                          : 0
                    }
                  />
                  <Typography fontSize={12} color={'text.secondary'}>
                    {props.organizationData?.activeSubscriptions?.main
                      ? daysUntilNextPayment
                      : props.organizationData?.activeSubscriptions?.trial
                        ? trialDueDate
                          ? moment(trialDueDate).diff(moment(), 'days') > 0
                            ? moment(trialDueDate).diff(moment(), 'days')
                            : 0
                          : 0
                        : 0}{' '}
                    {t('settings.settings.settings.remainingDesc')}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box p={3} border="1px solid" borderColor="divider" borderRadius={2}>
          <Stack direction={'column'} gap={2}>
            <Box
              bgcolor={'secondary.lighter'}
              borderRadius={1}
              sx={{
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Iconify icon={'ic:round-storage'} width={24} sx={{ color: 'secondary.main' }} />
            </Box>

            <Stack direction={'column'} gap={1}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={14} fontWeight={500}>
                  {t('settings.settings.settings.usageOfStorage')}
                </Typography>
                <Typography fontSize={14} fontWeight={500}>
                  {(props.organizationData?.activeSubscriptions?.storageUsageLimit?.current ?? 0) /
                    1024}{' '}
                  {t('settings.settings.settings.of')}{' '}
                  {(
                    (props.organizationData?.activeSubscriptions?.storageUsageLimit?.total ?? 0) /
                    1024
                  ).toFixed(2)}{' '}
                  GB
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                color="secondary"
                sx={{
                  borderRadius: 2,
                  height: 8,
                }}
                value={
                  ((props.organizationData?.activeSubscriptions?.storageUsageLimit?.current ?? 0) /
                    (props.organizationData?.activeSubscriptions?.storageUsageLimit?.total ?? 1)) *
                  100
                }
              />
            </Stack>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box p={3} border="1px solid" borderColor="divider" borderRadius={2}>
          <Stack direction={'column'} gap={2}>
            <Box
              bgcolor={'secondary.lighter'}
              borderRadius={1}
              sx={{
                width: '44px',
                height: '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Iconify icon={'solar:user-linear'} width={24} sx={{ color: 'secondary.main' }} />
            </Box>

            <Stack direction={'column'} gap={1}>
              <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={14} fontWeight={500}>
                  {t('settings.settings.settings.users')}
                </Typography>
                <Typography fontSize={14} fontWeight={500}>
                  {props.organizationData?.activeSubscriptions?.userUsageLimit?.current}{' '}
                  {t('settings.settings.settings.of')}{' '}
                  {props.organizationData?.activeSubscriptions?.userUsageLimit?.total}{' '}
                  {t('settings.settings.settings.users')}
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                color="secondary"
                sx={{
                  borderRadius: 2,
                  height: 8,
                }}
                value={
                  ((props.organizationData?.activeSubscriptions?.userUsageLimit?.current ?? 0) /
                    (props.organizationData?.activeSubscriptions?.userUsageLimit?.total ?? 1)) *
                  100
                }
              />
            </Stack>
          </Stack>
        </Box>
      </Grid>
      <ActionDialog
        open={openPricingPackages}
        onClose={() => setOpenPricingPackages(false)}
        title={t('settings.settings.settings.browsePackages')}
        width={1000}
      >
        <List />
      </ActionDialog>
    </Grid>
  );
};
