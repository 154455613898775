/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useEffect, useCallback, memo } from 'react';
import HomeForRestricted from './HomeForRestricted';
import HomeForActive from './HomeForActive';
import { useLazyOrganizationDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';
import { useAppDispatch } from 'context';
import { clearData } from 'context/slices/data';

const List = () => {
  const dispatch = useAppDispatch();
  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const [getOrganizationDetailOwn, { data: organizationDetailOwn }] =
    useLazyOrganizationDetailOwnQuery();

  const status = organizationDetailOwn?.status;

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      if (isMounted && userDetailOwnData) {
        await getOrganizationDetailOwn({});
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [userDetailOwnData, getOrganizationDetailOwn]);
  const cache = new Map();
  const clearCache = () => {
    cache.clear();
  };
  useEffect(() => {
    return () => {
      dispatch(clearData());
      clearCache();
    };
  }, []);

  return (
    <>
      {status !== 'active' && organizationDetailOwn && <HomeForRestricted />}
      {status === 'active' && organizationDetailOwn && <HomeForActive />}
    </>
  );
};

export default memo(List);
