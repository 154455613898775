import {
  IRoleListInput,
  IRoleListRequest,
  IRoleListResponse,
  IRoleListResult,
  roleListQuery,
  IRoleDetailInput,
  IRoleDetailRequest,
  IRoleDetailResponse,
  IRoleDetailResult,
  roleDetailQuery,
  IRoleCreateInput,
  IRoleCreateRequest,
  IRoleCreateResponse,
  IRoleCreateResult,
  roleCreateQuery,
  IRoleUpdateInput,
  IRoleUpdateRequest,
  IRoleUpdateResponse,
  IRoleUpdateResult,
  roleUpdateQuery,
  IRoleUpdateFilterInput,
  IRoleDeleteInput,
  IRoleDeleteRequest,
  IRoleDeleteResponse,
  IRoleDeleteResult,
  roleDeleteQuery,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { IGraphqlVariables } from 'corede-common';
import { rolesTags } from './roles.tags';

export const rolesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    roleList: builder.query<IRoleListResult, IGraphqlVariables<IRoleListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleListRequest,
        IRoleListResponse,
        IRoleListResult,
        IRoleListInput
      >({
        query: roleListQuery,
        providesTags: [rolesTags.roles],
      }),
    ),

    roleDetail: builder.query<IRoleDetailResult, IGraphqlVariables<IRoleDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleDetailRequest,
        IRoleDetailResponse,
        IRoleDetailResult,
        IRoleDetailInput
      >({
        query: roleDetailQuery,
        providesTags: [rolesTags.roles, rolesTags.role],
      }),
    ),

    // mutation

    roleCreate: builder.mutation<IRoleCreateResult, IGraphqlVariables<IRoleCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleCreateRequest,
        IRoleCreateResponse,
        IRoleCreateResult,
        IRoleCreateInput
      >({
        query: roleCreateQuery,
        invalidatesTags: [rolesTags.roles, rolesTags.role],
      }),
    ),

    roleUpdate: builder.mutation<
      IRoleUpdateResult,
      IGraphqlVariables<IRoleUpdateInput, IRoleUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleUpdateRequest,
        IRoleUpdateResponse,
        IRoleUpdateResult,
        IRoleUpdateInput,
        IRoleUpdateFilterInput
      >({
        query: roleUpdateQuery,
        invalidatesTags: [rolesTags.roles, rolesTags.role],
      }),
    ),

    roleDelete: builder.mutation<IRoleDeleteResult, IGraphqlVariables<IRoleDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IRoleDeleteRequest,
        IRoleDeleteResponse,
        IRoleDeleteResult,
        IRoleDeleteInput
      >({
        query: roleDeleteQuery,
        invalidatesTags: [rolesTags.roles],
      }),
    ),
  }),
});

export const {
  useRoleListQuery,
  useLazyRoleListQuery,
  useRoleDetailQuery,
  useLazyRoleDetailQuery,

  useRoleCreateMutation,
  useRoleUpdateMutation,
  useRoleDeleteMutation,
} = rolesApi;
