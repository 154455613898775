import { Stack, Box, Typography } from '@mui/material';
import { ResponsiveContainer, BarChart, Bar, Tooltip, XAxis } from 'recharts';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useState, memo, useCallback } from 'react';
import { Language } from 'corede-common';

interface ICustomBarChartV2Item {
  name: string;
  value1: number;
  value2Format: {
    count: number;
    prefix?: string;
    suffix?: string;
  };
}

interface IBottomToolbarData {
  label: string;
  valueFormat: {
    count: number;
    prefix?: string;
    suffix?: string;
  };
}

interface ICustomBarChartV2 {
  data: ICustomBarChartV2Item[];
  title: string;
  subtitle: string;
  theme: string;
  flexBasis: string;
  bottomToolbarData?: IBottomToolbarData[];
  language: Language;
}

const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.tertiary.main,
  },
}));

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.success.main,
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const CustomBarChartV2 = memo((props: ICustomBarChartV2) => {
  const theme = useTheme();
  const palette = theme.palette as Record<string, any>;
  const hexColor = palette[props.theme].lighter;
  const selectedColor = palette[props.theme].main;
  const zeroValueColor = theme.palette.grey[300]; // Slightly darker color for zero values

  const [activeIndex, setActiveIndex] = useState(1);

  const updatedData = props.data.map((item, index) => ({
    ...item,
    value1: item.value1 === 0 ? 0.1 : item.value1,
    fill: item.value1 === 0 ? zeroValueColor : (index === activeIndex ? selectedColor : hexColor),
  }));

  const displayValue = (value: number) => (value === 0.1 ? 0 : value);

  const CustomTooltip = useCallback(({ active, payload }: any) => {
    if (active && payload && payload.length) {

      const nameLabel = props.language === 'tr' ? 'İsim' : 'Name';
      const valueLabel = props.language === 'tr' ? 'Değer' : 'Value';
      return (
        <Box
          sx={{
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {`${nameLabel}: ${payload[0].payload.name}`}
          </Typography>
          <Typography variant="body2">{`${valueLabel}: ${displayValue(payload[0].value)}`}</Typography>
        </Box>
      );
    }
    return null;
  }, [props.language]);

  const handleClick = useCallback((e: any) => {
    setActiveIndex(e?.activeTooltipIndex ?? 1);
  }, []);

  return (
    <Stack
      direction={'column'}
      gap={1}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={props.flexBasis}
      width={'100%'}
    >
      <Box>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {props.title}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {props.subtitle}
        </Typography>
      </Box>

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
        sx={{ mt: 'auto' }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h3" sx={{ fontWeight: 500 }}>
            {displayValue(props.data[activeIndex]?.value1)}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '6px',
              bgcolor: `${props.theme}.extraLight`,
            }}
          >
            <Typography
              variant="caption"
              sx={{ fontWeight: 400, color: `${props.theme}.main`, px: 1 }}
            >
              {props.data[activeIndex]?.value2Format.prefix}
              {props.data[activeIndex]?.value2Format.count || 0}
              {props.data[activeIndex]?.value2Format.suffix}
            </Typography>
          </Box>
        </Box>

        <ResponsiveContainer width={300} height={100}>
          <BarChart
            data={updatedData}
            barGap={10}
            onClick={handleClick}
          >
            <XAxis dataKey="name" fontSize={9} tickLine={false} axisLine={false} />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="value1" fill={hexColor} radius={10} barSize={8} cursor="pointer" />
          </BarChart>
        </ResponsiveContainer>
      </Stack>
    </Stack>
  );
});

export default CustomBarChartV2;
