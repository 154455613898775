import demoLocale from '../domains/demo/locales/tr';

const locale = {
  demo: {
    ...demoLocale,
  },
  demoo: 'Teklif İste',
};

export default locale;
