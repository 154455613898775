import { useState, lazy, Suspense } from "react";
import { ILegalAgreementDetailDrawerProps } from "./LegalAgreementDetailDrawer";

const LegalAgreementDetailDrawer = lazy(() => import("./LegalAgreementDetailDrawer"));

const LegalAgreementDetailDrawerWrapper = ({
  open,
  setOpen,
  legalAgreement: selectedLegalAgreement,
  sideNavigationProps,
}: ILegalAgreementDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <LegalAgreementDetailDrawer
            open={open}
            setOpen={setOpen}
            legalAgreement={selectedLegalAgreement}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default LegalAgreementDetailDrawerWrapper;
