import { commonRoutes } from 'routes/route.common';

export const customerDomainRoutes = {
  base: 'customer',
  subdomains: {
    customer: {
      base: 'customer',
    },
    contact: {
      base: 'contact',
    },
    customerCategory: {
      base: 'customer-category',
    },
  },
};

export const baseCustomerRoute = (): string => `/${customerDomainRoutes.base}/`;
export const listCustomerRoute = (): string =>
  `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customer.base}/`;
export const listContactRoute = (): string =>
  `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.contact.base}/`;
export const viewCustomerRoute = (id: string): string =>
  `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customer.base}/${id}`;

export const createCustomerRoute = (): string =>
  `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customer.base}/${commonRoutes.create}`;
export const updateCustomerRoute = (id: string): string =>
  `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customer.base}/${id}/${commonRoutes.update}`;
export const importCustomerRoute = (): string =>
  `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customer.base}/${commonRoutes.import}`;

export const listCustomerCategoryRoute = (): string =>
  `/${customerDomainRoutes.base}/${customerDomainRoutes.subdomains.customerCategory.base}/`;
