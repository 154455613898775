const locale = {
  appointments: 'Appointments',
  appointment: 'Appointment',
  appointmentConversions: 'Appointment Conversions',
  title: 'Appointments',
  create: 'Create Appointment',
  update: 'Update Appointment',
  delete: 'Delete Appointment',
  details: 'Appointment Details',
  createSuccess: 'Appointment created successfully.',
  updateSuccess: 'Appointment updated successfully.',
  emptyState1: 'No appointments scheduled yet.',
  emptyState2:
    ' Set up and manage your appointments here to stay organized and connect with your customers seamlessly.',
  statusChangeSuccess: 'Appointment status changed successfully.',
  deleteConfirm: 'Are you sure you want to delete this appointment?',
  deletedSuccessfully: 'Appointment deleted successfully.',

  //fields
  startTime: 'Start Time',
  endTime: 'End Time',
  timezone: 'Timezone',
  location: 'Location',
  organizer: 'Organizer',
  targetType: 'Target Type',
  attendees: 'Attendees',
  category: 'Category',
  meetingLink: 'Meeting Link',
  duration: 'Duration',

  //detail drawer
  changeStatus: 'Change Status',
  generalInfoTitle: 'General Information',
  organizerInfoTitle: 'Organizer Information',
  appointmentInfoTitle: 'Appointment Information',
};

export default locale;
