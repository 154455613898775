const locale = {
  leadSources: "Lead Kaynakları",
  title: "Lead Kaynakları",
  create: "Lead Kaynağı Oluştur",
  delete: "Lead Kaynağı Sil",
  deleteConfirm: "Kaynağı silmek istediğinize emin misiniz: ",
  deletedSuccessfully: "Kaynak başarıyla silindi!",
  createSuccess: "Kaynak başarıyla eklendi!",
  updateSuccess: "Kaynak başarıyla güncellendi!",
  update: "Lead Kaynağı Güncelle",
  updateLeadSourceSuccess: "Kaynak başarıyla güncellendi!",
  selectColor: "Renk Seçiniz",
  selectIcon: "Ikon Seçiniz",
};

export default locale;
