import { useState, lazy, Suspense } from 'react';

const AppointmentCreateDrawer = lazy(() => import('./AppointmentCreateDrawer'));

const AppointmentCreateDrawerWrapper = ({ open, setOpen, overlayType }: any) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <AppointmentCreateDrawer open={open} setOpen={setOpen} overlayType={overlayType} />
        </Suspense>
      )}
    </>
  );
};

export default AppointmentCreateDrawerWrapper;
