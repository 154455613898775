

import { TasksDomainLayout } from "../layouts";
import { TasksRouter } from "../subdomains/task/routes/TasksRouter";
import { Route, Routes } from "react-router-dom";

export const TasksDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<TasksDomainLayout />}>
        <Route index element={<TasksRouter />} />
        <Route path="*" element={<TasksRouter />} />
      </Route>
    </Routes>
  );
};
