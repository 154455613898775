import {
  Autocomplete,
  Box,
  Chip,
  InputLabel,
  Switch,
  SwitchPropsColorOverrides,
  TextField,
  Typography,
} from '@mui/material';
import { ActionIconColor } from 'components/baseDataGrid/infra/IBaseGridColumnActionComponentsParams';
import { useState } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

export const DefaultSwitchInput = (props: {
  inputName: `input.${string}`;
  control: Control<any>;
  label?: string;
  description: string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default';
  isRequired?: boolean;
  labelShown?: boolean;
}) => {
  return (
    <>
      <InputLabel
        htmlFor={props.inputName}
        sx={{
          ml: 1,
          mb: 0.4,
          fontSize: 'small',
          color: !props.labelShown ? '#00000000' : 'text.secondary',
        }}
      >
        {props.label || '_'}
      </InputLabel>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '16px',
          p: 0.9,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Controller
          name={props.inputName}
          control={props.control}
          render={({ field }) => (
            <>
              <Switch
                id={props.inputName}
                checked={field.value || false}
                color={props.color || 'secondary'}
                size="small"
                onChange={(e) => field.onChange(e.target.checked)}
              />
              <Typography sx={{ ml: 1, fontSize: 'small', fontWeight: 'bold' }}>
                {props.description} {props.isRequired && '*'}
              </Typography>
            </>
          )}
        />
      </Box>
    </>
  );
};
