/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Update } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { PermissionList } from '../../../permission/components/PermissionList';
import {
  useUserPermissionDetailQuery,
  useUserPermissionUpdateMutation,
} from '../../context/user.api';
import { PermissionHelper } from '../../../permission/helper/permission.helper';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { Box, Grid, Stack } from '@mui/material';
import { PermissionCheckHelper } from 'permission/permission-check.helper';
import { isNullOrUndefinedOrEmpty, PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';

export interface IUserTabDetailPermissions extends DefaultTabComponentProps<any> {
  tabKey: 'permissions';
  name: 'crm.permissions';
}

export const UserTabDetailPermissionsTab = (props: IUserTabDetailPermissions) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [selectedPermissionIds, setSelectedPermissionIds] = useState<string[]>([]);
  const [prohibitedPermissionIds, setProhibitedPermissionIds] = useState<string[]>([]);
  const [rolePermissionIds, setRolePermissionIds] = useState<string[]>([]);

  // queries
  const {
    data: userPermissionData,
    isLoading: userPermissionLoading,
    isFetching: userPermissionFetching,
    error: userPermissionError,
  } = useUserPermissionDetailQuery(
    {
      input: {
        _id: id || '',
      },
    },
    {
      skip: !isNullOrUndefinedOrEmpty(id),
    },
  );

  const [
    userUpdatePermission,
    {
      data: userUpdatePermissionData,
      isLoading: userUpdatePermissionLoading,
      error: userUpdatePermissionError,
    },
  ] = useUserPermissionUpdateMutation();

  const handleUpdatePermissions = () => {
    if (userPermissionData) {
      userUpdatePermission({
        input: {
          permissionIds: selectedPermissionIds.filter(
            (id) => !PermissionHelper.isIncludesRole(rolePermissionIds, id),
          ),
          prohibitedPermissionIds: prohibitedPermissionIds,
        },
        filter: {
          _id: id || '',
        },
      });
    }
  };

  // use effects

  useEffect(() => {
    if (userPermissionData) {
      const allAllowedPermissionIds =
        PermissionCheckHelper.getAllowedPermissionIds(userPermissionData);
      setSelectedPermissionIds(allAllowedPermissionIds);
      setProhibitedPermissionIds(userPermissionData?.userProhibitedPermissions.map((p) => p._id));
      setRolePermissionIds(userPermissionData?.rolePermissions.map((p) => p._id) ?? []);
    }
  }, [userPermissionData]);

  useEffect(() => {
    if (userUpdatePermissionData) {
      enqueueSnackbar(t('crm.organizationalChart.user.permissionUpdateSuccess'), {
        variant: 'success',
      });
    }
  }, [userUpdatePermissionData]);

  return (
    <DefaultTabContainer
      title=""
      // title={t('crm.organizationalChart.user.permissions')}
      rightButton={{
        title: t('update'),
        icon: <Update sx={{ mr: 1 }} />,
        onClick: handleUpdatePermissions,
        permission: {
          subject: PermissionSubject.userPermission,
          action: PermissionAction.update,
        },
      }}
    >
      <Stack direction={'column'} width={'100%'}>
        <PermissionList
          existingPermissionIds={selectedPermissionIds}
          rolePermissionIds={rolePermissionIds}
          setSelectedPermissionIds={setSelectedPermissionIds}
          prohibitedPermissionState={{
            state: prohibitedPermissionIds,
            setState: setProhibitedPermissionIds,
          }}
        />
      </Stack>
    </DefaultTabContainer>
  );
};
