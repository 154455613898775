/* eslint-disable react-hooks/exhaustive-deps */
import { useTaskDetailQuery } from '../../context/task.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { ITask, ITaskDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { TaskDetailDrawerLeftPanelComponent } from './TaskDetailDrawer-leftPanel.component';
import { TaskDetailDrawerRightPanelComponent } from './TaskDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface ITaskDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedTask: ITaskDetailResult & IHasId<number>;
  setSelectedTask: Dispatch<SetStateAction<TListGridSelectedEntity<ITask>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const TaskDetailDrawer = memo((props: ITaskDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: taskDetailData, error: taskDetailError } = useTaskDetailQuery(
    {
      input: {
        _id: props?.selectedTask?._id,
      },
    },
    {
      skip: !props?.selectedTask?._id,
    },
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(taskDetailError as IBaseError, currentLanguage);
  }, [taskDetailError]);

  return (
    <DefaultDetailDrawer<ITaskDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedTask}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.task.task.tasks'),
            }
      }
    >
      <></>
      <TaskDetailDrawerLeftPanelComponent taskDetailData={taskDetailData} setOpen={props.setOpen} />
      <TaskDetailDrawerRightPanelComponent taskDetailData={taskDetailData} />
    </DefaultDetailDrawer>
  );
});

export default TaskDetailDrawer;
