/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, useMediaQuery } from '@mui/material';
import { IReminderDetailResult } from 'corede-common-cocrm';
import React from 'react';
import { DefaultDetailDrawerLeftPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-leftPanel.component';
import { ReminderDetailDrawerLeftPanelHeaderComponent } from './ReminderDetailDrawer-leftPanel-header.component';
import { ReminderDetailDrawerLeftPanelContactDetailComponent } from './ReminderDetailDrawer-leftPanel-contactDetail.component';
import { ReminderDetailDrawerLeftPanelAssignedsComponent } from './ReminderDetailDrawer-leftPanel-assigned.component';

export interface IReminderDetailDrawerLeftPanelComponentProps {
  reminderDetailData: IReminderDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReminderDetailDrawerLeftPanelComponent = (
  props: IReminderDetailDrawerLeftPanelComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <DefaultDetailDrawerLeftPanelComponent<IReminderDetailResult>
      entityData={props.reminderDetailData}
      setOpen={props.setOpen}
    >
      {/* Header */}
      <ReminderDetailDrawerLeftPanelHeaderComponent
        reminderDetailData={props.reminderDetailData}
        setOpen={props.setOpen}
      />

      {/* Contact Details */}
      <ReminderDetailDrawerLeftPanelContactDetailComponent
        reminderDetailData={props.reminderDetailData}
        setOpen={props.setOpen}
      />

      {/* Assigned List */}
      <ReminderDetailDrawerLeftPanelAssignedsComponent
        reminderDetailData={props.reminderDetailData}
        setOpen={props.setOpen}
        visible={!downLg}
      />
      <Divider />
    </DefaultDetailDrawerLeftPanelComponent>
  );
};
