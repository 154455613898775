import {
  // queries
  // IPermissionListInput, IPermissionListRequest, IPermissionListResponse, IPermissionListResult, permissionListQuery,

  IEstimateListInput,
  IEstimateListRequest,
  IEstimateListResponse,
  IEstimateListResult,
  estimateListQuery,
  IEstimateListCountRequest,
  IEstimateListCountResponse,
  estimateListCountQuery,
  IEstimateDetailInput,
  IEstimateDetailRequest,
  IEstimateDetailResponse,
  IEstimateDetailResult,
  estimateDetailQuery,

  // mutations
  IEstimateCreateInput,
  IEstimateCreateRequest,
  IEstimateCreateResponse,
  IEstimateCreateResult,
  estimateCreateQuery,
  IEstimateUpdateInput,
  IEstimateUpdateRequest,
  IEstimateUpdateResponse,
  IEstimateUpdateResult,
  estimateUpdateQuery,
  IEstimateUpdateFilterInput,
  IEstimateDeleteInput,
  IEstimateDeleteRequest,
  IEstimateDeleteResponse,
  IEstimateDeleteResult,
  estimateDeleteQuery,
  estimateSentQuery,
  IEstimateSentInput,
  IEstimateSentRequest,
  IEstimateSentResponse,
  IEstimateSentResult,
  estimateStatisticsQuery,
  IEstimateStatisticsInput,
  IEstimateStatisticsRequest,
  IEstimateStatisticsResponse,
  IEstimateStatisticsResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { estimateTags } from './estimate.tags';
import { ICount, IGraphqlVariables } from 'corede-common';

export const estimatesApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    estimateList: builder.query<IEstimateListResult, IGraphqlVariables<IEstimateListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateListRequest,
        IEstimateListResponse,
        IEstimateListResult,
        IEstimateListInput
      >({
        query: estimateListQuery,
        providesTags: [estimateTags.estimate, estimateTags.estimates],
      }),
    ),

    estimateListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateListCountRequest,
        IEstimateListCountResponse,
        ICount,
        undefined
      >({
        query: estimateListCountQuery,
        providesTags: [estimateTags.estimates],
      }),
    ),

    estimateDetail: builder.query<IEstimateDetailResult, IGraphqlVariables<IEstimateDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateDetailRequest,
        IEstimateDetailResponse,
        IEstimateDetailResult,
        IEstimateDetailInput
      >({
        query: estimateDetailQuery,
        providesTags: [estimateTags.estimate, estimateTags.estimates],
      }),
    ),

    estimateStatistics: builder.query<
      IEstimateStatisticsResult,
      IGraphqlVariables<IEstimateStatisticsInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateStatisticsRequest,
        IEstimateStatisticsResponse,
        IEstimateStatisticsResult,
        IEstimateStatisticsInput
      >({
        query: estimateStatisticsQuery,
        providesTags: [estimateTags.estimate, estimateTags.estimates],
      }),
    ),

    // mutations

    estimateCreate: builder.mutation<
      IEstimateCreateResult,
      IGraphqlVariables<IEstimateCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateCreateRequest,
        IEstimateCreateResponse,
        IEstimateCreateResult,
        IEstimateCreateInput
      >({
        query: estimateCreateQuery,
        invalidatesTags: [estimateTags.estimate, estimateTags.estimates],
      }),
    ),

    estimateUpdate: builder.mutation<
      IEstimateUpdateResult,
      IGraphqlVariables<IEstimateUpdateInput, IEstimateUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateUpdateRequest,
        IEstimateUpdateResponse,
        IEstimateUpdateResult,
        IEstimateUpdateInput,
        IEstimateUpdateFilterInput
      >({
        query: estimateUpdateQuery,
        invalidatesTags: [estimateTags.estimate, estimateTags.estimates],
      }),
    ),

    estimateDelete: builder.mutation<
      IEstimateDeleteResult,
      IGraphqlVariables<IEstimateDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateDeleteRequest,
        IEstimateDeleteResponse,
        IEstimateDeleteResult,
        IEstimateDeleteInput
      >({
        query: estimateDeleteQuery,
        invalidatesTags: [estimateTags.estimates],
      }),
    ),

    estimateSent: builder.mutation<IEstimateSentResult, IGraphqlVariables<IEstimateSentInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IEstimateSentRequest,
        IEstimateSentResponse,
        IEstimateSentResult,
        IEstimateSentInput
      >({
        query: estimateSentQuery,
        invalidatesTags: [estimateTags.estimate, estimateTags.estimates],
      }),
    ),
  }),
});

export const {
  useEstimateListQuery,
  useLazyEstimateListQuery,
  useEstimateListCountQuery,
  useLazyEstimateListCountQuery,
  useEstimateDetailQuery,
  useLazyEstimateDetailQuery,
  useEstimateStatisticsQuery,
  useLazyEstimateStatisticsQuery,
  useEstimateCreateMutation,
  useEstimateUpdateMutation,
  useEstimateDeleteMutation,
  useEstimateSentMutation,
} = estimatesApi;
