/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { IExpenseDetailResult } from 'corede-common-cocrm';
import { ExpenseDetailDrawerLeftPanelActionsComponent } from './ExpenseDetailDrawer-leftPanel-header-actions.component';

export interface IExpenseDetailDrawerLeftPanelHeaderComponentProps {
  expenseDetailData: IExpenseDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const ExpenseDetailDrawerLeftPanelHeaderComponent = (
  props: IExpenseDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* Expense Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.expenseDetailData?.name ?? '-'}
      </Typography>

      <ExpenseDetailDrawerLeftPanelActionsComponent
        expenseDetailData={props.expenseDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
