import {
  IPaymentProductListInput,
  IPaymentProductListRequest,
  IPaymentProductListResponse,
  IPaymentProductListResult,
  paymentProductListQuery,
  organizationTrialSubscriptionBeginQuery,
  IOrganizationTrialSubscriptionBeginRequest,
  IOrganizationTrialSubscriptionBeginResponse,
  subscriptionPurchaseQuery,
  ISubscriptionPurchaseRequest,
  ISubscriptionPurchaseResponse,
  ISubscriptionPurchaseInput,
  subscriptionDetailQuery,
  ISubscriptionDetailRequest,
  ISubscriptionDetailResponse,
  ISubscriptionDetailResult,
  exchangeRateRetrieveQuery,
  IExchangeRateRetrieveRequest,
  IExchangeRateRetrieveResponse,
  IExchangeRateRetrieveResult,
  IExchangeRateRetrieveInput,
  exchangeRateRetrieveForPaymentProductQuery,
  IExchangeRateRetrieveForPaymentProductRequest,
  IExchangeRateRetrieveForPaymentProductResponse,
  IExchangeRateRetrieveForPaymentProductResult,
  IExchangeRateRetrieveForPaymentProductInput,
  subscriptionInitializePurchaseWithCheckoutFormQuery,
  ISubscriptionInitializePurchaseWithCheckoutFormRequest,
  ISubscriptionInitializePurchaseWithCheckoutFormInput,
  ISubscriptionInitializePurchaseWithCheckoutFormResponse,
  ISubscriptionInitializePurchaseWithCheckoutFormResult,
  subscriptionFinalizePurchaseWithCheckoutFormQuery,
  ISubscriptionFinalizePurchaseWithCheckoutFormRequest,
  ISubscriptionFinalizePurchaseWithCheckoutFormInput,
  ISubscriptionFinalizePurchaseWithCheckoutFormResponse,
  ISubscriptionFinalizePurchaseWithCheckoutFormResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { pricingTags } from './pricing.tags';
import { IGraphqlVariables, IBaseResult, IEntity } from 'corede-common';
import { gql } from '@apollo/client/core';

const PAYMENT_PRODUCT_FIELDS = gql`
  fragment PaymentProductFields on PaymentProductListItemResult {
    _id
    name
    recurring {
      monthly {
        pricing {
          discountedPrice
        }
        entitlements {
          storageLimit
          userLimit
        }
      }
      yearly {
        pricing {
          discountedPrice
        }
        entitlements {
          storageLimit
          userLimit
        }
      }
    }
    recurringActions {
      monthly {
        canBePurchased
        paymentTypes
        subscriptionStatus
        upgradeDiscount
        willUpgrade
      }
      yearly {
        canBePurchased
        paymentTypes
        subscriptionStatus
        upgradeDiscount
        willUpgrade
      }
    }
  }
`;

const SUBSCRIPTION_DETAIL_FIELDS = gql`
  fragment SubscriptionDetailFields on SubscriptionDetailResult {
    _id
    paymentProduct {
      _id
      name
      recurring {
        yearly {
          entitlements {
            storageLimit
            userLimit
          }
          pricing {
            discountedPrice
          }
        }
        monthly {
          entitlements {
            storageLimit
            userLimit
          }
          pricing {
            discountedPrice
          }
        }
      }
    }
    recurringInterval
    nextPaymentDate
    savedCardToken
  }
`;

export const pricingApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    paymentProductList: builder.query<
      IPaymentProductListResult,
      IGraphqlVariables<IPaymentProductListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IPaymentProductListRequest,
        IPaymentProductListResponse,
        IPaymentProductListResult,
        IPaymentProductListInput
      >({
        query: paymentProductListQuery({
          fragment: PAYMENT_PRODUCT_FIELDS,
          fragmentName: 'PaymentProductFields',
        }),
        providesTags: [],
      }),
    ),

    subscriptionDetail: builder.query<ISubscriptionDetailResult, IGraphqlVariables<IEntity>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ISubscriptionDetailRequest,
        ISubscriptionDetailResponse,
        ISubscriptionDetailResult,
        IEntity
      >({
        query: subscriptionDetailQuery({
          fragment: SUBSCRIPTION_DETAIL_FIELDS,
          fragmentName: 'SubscriptionDetailFields',
        }),
        providesTags: [],
      }),
    ),

    exchangeRateRetrieve: builder.query<
      IExchangeRateRetrieveResult,
      IGraphqlVariables<IExchangeRateRetrieveInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExchangeRateRetrieveRequest,
        IExchangeRateRetrieveResponse,
        IExchangeRateRetrieveResult,
        IExchangeRateRetrieveInput
      >({
        query: exchangeRateRetrieveQuery,
        providesTags: [],
      }),
    ),

    exchangeRateRetrieveForPaymentProduct: builder.query<
      IExchangeRateRetrieveForPaymentProductResult,
      IGraphqlVariables<IExchangeRateRetrieveForPaymentProductInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IExchangeRateRetrieveForPaymentProductRequest,
        IExchangeRateRetrieveForPaymentProductResponse,
        IExchangeRateRetrieveForPaymentProductResult,
        IExchangeRateRetrieveForPaymentProductInput
      >({
        query: exchangeRateRetrieveForPaymentProductQuery,
        providesTags: [],
      }),
    ),

    // mutation

    organizationTrialSubscriptionBegin: builder.mutation<IBaseResult, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IOrganizationTrialSubscriptionBeginRequest,
        IOrganizationTrialSubscriptionBeginResponse,
        IBaseResult
      >({
        query: organizationTrialSubscriptionBeginQuery,
        invalidatesTags: [],
      }),
    ),

    subscriptionPurchase: builder.mutation<
      ISubscriptionDetailResult,
      IGraphqlVariables<ISubscriptionPurchaseInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ISubscriptionPurchaseRequest,
        ISubscriptionPurchaseResponse,
        ISubscriptionDetailResult,
        ISubscriptionPurchaseInput
      >({
        query: subscriptionPurchaseQuery,
        invalidatesTags: [],
      }),
    ),

    subscriptionInitializePurchaseWithCheckoutForm: builder.mutation<
      ISubscriptionInitializePurchaseWithCheckoutFormResult,
      IGraphqlVariables<ISubscriptionInitializePurchaseWithCheckoutFormInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ISubscriptionInitializePurchaseWithCheckoutFormRequest,
        ISubscriptionInitializePurchaseWithCheckoutFormResponse,
        ISubscriptionInitializePurchaseWithCheckoutFormResult,
        ISubscriptionInitializePurchaseWithCheckoutFormInput
      >({
        query: subscriptionInitializePurchaseWithCheckoutFormQuery,
        invalidatesTags: [],
      }),
    ),

    subscriptionFinalizePurchaseWithCheckoutForm: builder.mutation<
      ISubscriptionFinalizePurchaseWithCheckoutFormResult,
      IGraphqlVariables<ISubscriptionFinalizePurchaseWithCheckoutFormInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ISubscriptionFinalizePurchaseWithCheckoutFormRequest,
        ISubscriptionFinalizePurchaseWithCheckoutFormResponse,
        ISubscriptionFinalizePurchaseWithCheckoutFormResult,
        ISubscriptionFinalizePurchaseWithCheckoutFormInput
      >({
        query: subscriptionFinalizePurchaseWithCheckoutFormQuery,
        invalidatesTags: [],
      }),
    ),
  }),
});

export const {
  usePaymentProductListQuery,
  useLazyPaymentProductListQuery,
  useSubscriptionDetailQuery,
  useLazySubscriptionDetailQuery,
  useExchangeRateRetrieveQuery,
  useLazyExchangeRateRetrieveQuery,
  useExchangeRateRetrieveForPaymentProductQuery,
  useLazyExchangeRateRetrieveForPaymentProductQuery,

  useOrganizationTrialSubscriptionBeginMutation,
  useSubscriptionPurchaseMutation,
  useSubscriptionInitializePurchaseWithCheckoutFormMutation,
  useSubscriptionFinalizePurchaseWithCheckoutFormMutation,
} = pricingApi;
