/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Grow, Card, InputLabel, TextField, Typography, Divider } from '@mui/material';
import { FilledButton, SettingsTitle } from 'components';
import EmptyState from 'components/emptyState/EmptyState';
import { IBaseError, IGraphqlVariables, Language } from 'corede-common';
import {
  IOrganizationSettingsUpdateInput,
  LegalAgreementType,
  NotificationChannelType,
} from 'corede-common-cocrm';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import { useLegalAgreementListQuery } from '../../context/setting.api';
import { DefaultRichTextQuillInput } from 'components/form/DefaultRichTextQuillInput';
import { generateAutocompleteTranslatedOptions, getTranslatedEnumValue } from 'localization';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { RefreshOutlined } from '@mui/icons-material';
import {
  useOrganizationDetailOwnQuery,
  useOrganizationSettingsDetailOwnQuery,
  useOrganizationSettingsUpdateOwnMutation,
} from '../../context/setting.api';
import { enqueueSnackbar } from 'notistack';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import LegalAgreementCreateDrawerWrapper from '../legalAgreement/create/LegalAgreementCreateDrawer.wrapper';

export const CrmProposalTab = (props: { selectedTabName: string }) => {
  const { t } = useTranslation();

  const [openLegalAgreementCreateDrawer, setOpenLegalAgreementCreateDrawer] = useState(false);
  const [selectedLegalAgreementType, setSelectedLegalAgreementType] = useState<LegalAgreementType>();
  
  const { data: organizationSettings } = useOrganizationSettingsDetailOwnQuery({});

  const { data: NDAListData, isLoading: NDAListLoading } = useLegalAgreementListQuery({
    input: {
      filter: { types: [LegalAgreementType.NDA] },
      pagination: {
        sort: { createdAt: 1 },
      },
    },
  });

  const { data: termsAndConditionListData, isLoading: termsAndConditionListLoading } =
    useLegalAgreementListQuery({
      input: {
        filter: { types: [LegalAgreementType.termsAndConditions] },
        pagination: {
          sort: { createdAt: 1 },
        },
      },
    });

  const proposalFormInitialValues = useMemo<IGraphqlVariables<IOrganizationSettingsUpdateInput>>(
    () => ({
      input: {
        proposal: {
          followUpMailTemplate: {
            subject: {
              en: '',
              tr: '',
            },
            content: {
              en: '',
              tr: '',
            },
            type: NotificationChannelType.email,
            variables: [],
          },
          NDAId: undefined,
          prefix: undefined,
          termsAndConditionsId: undefined,
          companyBackground: undefined,
          companyReferences: undefined,
        },
      },
    }),
    [],
  );

  const proposalForm = useForm<IGraphqlVariables<IOrganizationSettingsUpdateInput>>({
    values: proposalFormInitialValues,
    mode: 'onSubmit',
  });

  const [
    updateOrganizationSettings,
    {
      data: organizationSettingsUpdateData,
      isLoading: organizationSettingsUpdateOwnLoading,
      error: organizationSettingsUpdateOwnError,
    },
  ] = useOrganizationSettingsUpdateOwnMutation();

  const proposalFormOnSubmit = async (
    values: IGraphqlVariables<IOrganizationSettingsUpdateInput>,
  ) => {
    updateOrganizationSettings({ input: values.input });
  };

  useEffect(() => {
    if (organizationSettingsUpdateData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
    }
  }, [organizationSettingsUpdateData]);

  useEffect(() => {
    if (organizationSettingsUpdateOwnError) {
      DefaultErrorHandlerUseEffect(organizationSettingsUpdateOwnError as IBaseError, Language.en);
    }
  }, [organizationSettingsUpdateOwnError]);

  useEffect(() => {
    if (organizationSettings) {
      proposalForm.reset({
        input: {
          proposal: {
            followUpMailTemplate: { 
              subject: {
                en: organizationSettings.proposal.followUpMailTemplate?.subject?.en,
                tr: organizationSettings.proposal.followUpMailTemplate?.subject?.tr,
              },
              content: {
                en: organizationSettings.proposal.followUpMailTemplate?.content?.en,
                tr: organizationSettings.proposal.followUpMailTemplate?.content?.tr,
              },
              type: NotificationChannelType.email,
              variables: organizationSettings.proposal.followUpMailTemplate?.variables ?? [],
            },
            NDAId: organizationSettings.proposal?.NDA?._id,
            prefix: organizationSettings.proposal?.prefix,
            termsAndConditionsId: organizationSettings.proposal?.termsAndConditions?._id,
            companyBackground: organizationSettings.proposal?.companyBackground,
            companyReferences: organizationSettings.proposal?.companyReferences,
          },
        },
      });
    }
  }, [organizationSettings]);

  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t('settings.settings.settings.proposal')} />
      <Grid item xs={12}>
        <Grow in={props.selectedTabName === 'settings.settings.settings.proposal'} timeout={500}>
          <Card
            sx={{
              p: 2,
              border: '1px solid',
              bgcolor: 'background.thirdWithBlur',
              borderColor: 'primary.lighter',
            }}
          >
            {/* <Grid container width={{ xs: '100%', md: '50%', lg: '35%' }} spacing={2}> */}
            <Grid container width={{ xs: '100%' }} spacing={2}>
              {true ? (
                <>
                  <Grid item xs={12}>
                    <DefaultTextFieldInput
                      control={proposalForm.control as any}
                      inputName="input.proposal.prefix"
                      label={t('settings.settings.settings.prefix')}
                      errors={proposalForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultObjectAutocompleteInput
                      control={proposalForm.control as any}
                      errors={proposalForm.formState.errors}
                      inputName="input.proposal.NDAId"
                      label={t('settings.settings.settings.NDA')}
                      options={NDAListData?.data || []}
                      touchedFields={proposalForm.formState.touchedFields}
                      onAddNewOption={() => {
                        setSelectedLegalAgreementType(LegalAgreementType.NDA);
                        setOpenLegalAgreementCreateDrawer(true);
                      }}
                      isLoading={NDAListLoading}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultObjectAutocompleteInput
                      control={proposalForm.control as any}
                      errors={proposalForm.formState.errors}
                      inputName="input.proposal.termsAndConditionsId"
                      label={t('settings.settings.settings.termsAndConditions')}
                      options={termsAndConditionListData?.data || []}
                      touchedFields={proposalForm.formState.touchedFields}
                      onAddNewOption={() => {
                        setSelectedLegalAgreementType(LegalAgreementType.termsAndConditions);
                        setOpenLegalAgreementCreateDrawer(true);
                      }}
                      isLoading={termsAndConditionListLoading}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DefaultTextFieldInput
                      control={proposalForm.control as any}
                      inputName="input.proposal.companyBackground"
                      label={t('settings.settings.settings.companyBackground')}
                      errors={proposalForm.formState.errors}
                      multiline
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DefaultTextFieldInput
                      control={proposalForm.control as any}
                      inputName="input.proposal.companyReferences"
                      label={t('settings.settings.settings.companyReferences')}
                      errors={proposalForm.formState.errors}
                      multiline
                      rows={3}
                    />
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Typography fontSize={13} fontWeight={600} gutterBottom>
                      {t('settings.settings.settings.followUpMailTemplate')}
                    </Typography>
                    <Divider />
                  </Grid>


                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={proposalForm.control as any}
                      inputName="input.proposal.followUpMailTemplate.subject.en"
                      label={
                        t('settings.settings.settings.subject') +
                        ` (${getTranslatedEnumValue(Language.en)})`
                      }
                      errors={proposalForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={proposalForm.control as any}
                      inputName="input.proposal.followUpMailTemplate.subject.tr"
                      label={
                        t('settings.settings.settings.subject') +
                        ` (${getTranslatedEnumValue(Language.tr)})`
                      }
                      errors={proposalForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultRichTextQuillInput
                      control={proposalForm.control as any}
                      inputName="input.proposal.followUpMailTemplate.content.en"
                      label={
                        t('settings.settings.settings.content') +
                        ` (${getTranslatedEnumValue(Language.en)})`
                      }
                      errors={proposalForm.formState.errors}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <DefaultRichTextQuillInput
                      control={proposalForm.control as any}
                      inputName="input.proposal.followUpMailTemplate.content.tr"
                      label={
                        t('settings.settings.settings.content') +
                        ` (${getTranslatedEnumValue(Language.tr)})`
                      }
                      errors={proposalForm.formState.errors}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FilledButton
                      leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                      color="secondary"
                      title={t('settings.settings.settings.updateSettings')}
                      onClick={proposalForm.handleSubmit(proposalFormOnSubmit)}
                      loading={organizationSettingsUpdateOwnLoading}
                      disabled={organizationSettingsUpdateOwnLoading}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} mb={2}>
                  <EmptyState
                    content1={t('settings.settings.settings.noProposalContent1')}
                    content2={t('settings.settings.settings.noProposalContent2')}
                  />
                </Grid>
              )}
            </Grid>
          </Card>
        </Grow>
      </Grid>

      <LegalAgreementCreateDrawerWrapper
        open={openLegalAgreementCreateDrawer}
        setOpen={setOpenLegalAgreementCreateDrawer}
        selectedType={selectedLegalAgreementType}
      />
    </Grid>
  );
};
