import { ContractDomainLayout } from '../layouts';
import { ContractRouter } from '../subdomains/contract/routes/ContractRouter';
import { ContractTemplateRouter } from '../subdomains/contractTemplate/routes/ContractTemplateRouter';
import { contractDomainRoutes } from './contract.base.route';
import { Route, Routes } from 'react-router-dom';

export const ContractDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContractDomainLayout />}>
        <Route index element={<ContractRouter />} />
        <Route
          path={`${contractDomainRoutes.subdomains.contract.base}/*`}
          element={<ContractRouter />}
        />
        <Route
          path={`${contractDomainRoutes.subdomains.constractTemplate.base}/*`}
          element={<ContractTemplateRouter />}
        />
      </Route>
    </Routes>
  );
};
