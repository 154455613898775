/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Skeleton, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { OutlinedButton } from 'components';
import { ExpandCircleDown } from '@mui/icons-material';
import { getCurrentLanguage } from 'localization';
import { IBaseError, PermissionAction } from 'corede-common';
import { IPermission, PermissionDomain } from 'corede-common-cocrm';
import { PermissionDomainAccordionComponent } from './PermissionDomainAccordion';
import { domainsInOrder } from './PermissionList.order.constants';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { usePermissionListQuery } from '../context';
import { PermissionHelper } from '../helper/permission.helper';
import { IUseStatePropagationProp } from 'interfaces/useState-propagation-props.interface';

/**
 * @param existingPermissionIds existing permissions that can be given or taken - for role entity this is role permissions, for user entity this is user specific allowed permissions
 * @param rolePermissionIds permissions that comes from owned roles - is used on user permissions
 * @param prohibitedPermissionIds permissions that are prohibited - is used on user permissions
 */
export interface IPermissionListProps {
  existingPermissionIds?: string[];
  rolePermissionIds?: string[];
  isLoading?: boolean;
  setSelectedPermissionIds?: Dispatch<SetStateAction<string[]>>;
  prohibitedPermissionState?: IUseStatePropagationProp<string[]>;
  hideChips?: boolean;
}

export const PermissionList = (props: IPermissionListProps) => {
  const currentLanguage = getCurrentLanguage();
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  const existingPermissionIds = props.existingPermissionIds ?? [];
  const [selectedPermissionIds, setSelectedPermissionIds] =
    useState<string[]>(existingPermissionIds);

  const [openedPermissionIds, setOpenedPermissionIds] = useState<any>([]);

  const [expandAll, setExpandAll] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState<Record<string, boolean>>({});

  const handleChangeCheckbox = useCallback(
    (permissionId: string) => {
      const isIncludedInRolePermissions = PermissionHelper.isIncludesRole(
        props.rolePermissionIds ?? [],
        permissionId,
      );
      if (
        !PermissionHelper.isProhibited(props.prohibitedPermissionState?.state ?? [], permissionId)
      ) {
        setSelectedPermissionIds((prev) => {
          if (prev.includes(permissionId)) {
            if (isIncludedInRolePermissions && props.prohibitedPermissionState) {
              props.prohibitedPermissionState.setState((prev) => [...prev, permissionId]);
            }
            return prev.filter((id) => id !== permissionId);
          } else {
            if (isIncludedInRolePermissions && props.prohibitedPermissionState) {
              props.prohibitedPermissionState.setState(
                props.prohibitedPermissionState.state.filter((id) => id !== permissionId),
              );
            }
            return [...prev, permissionId];
          }
        });
      } else {
        setSelectedPermissionIds((prev) => {
          if (prev.includes(permissionId)) {
            if (isIncludedInRolePermissions && props.prohibitedPermissionState) {
              props.prohibitedPermissionState.setState((prev) => [...prev, permissionId]);
            }
            return prev.filter((id) => id !== permissionId);
          } else {
            if (isIncludedInRolePermissions && props.prohibitedPermissionState) {
              props.prohibitedPermissionState.setState(
                props.prohibitedPermissionState.state.filter((id) => id !== permissionId),
              );
            }
            return [...prev, permissionId];
          }
        });
      }
    },
    [selectedPermissionIds],
  );

  const {
    data: permissionListData,
    isLoading: permissionListLoading,
    error: permissionListError,
  } = usePermissionListQuery({
    input: {
      filter: {
        actions: [
          PermissionAction.manage,
          PermissionAction.view,
          PermissionAction.import,
          PermissionAction.export,
        ],
      },
    },
  });

  const permissions: IPermission[] = permissionListData?.data ?? [];

  const domainToPermissionsMap: Map<PermissionDomain, IPermission[]> = new Map();
  domainsInOrder.forEach((d) => {
    domainToPermissionsMap.set(
      d,
      permissions.filter((p) => p.domain === d),
    );
  });

  // use Effects

  useEffect(() => {
    DefaultErrorHandlerUseEffect(permissionListError as IBaseError, currentLanguage);
  }, [permissionListError, currentLanguage]);

  useEffect(() => {
    if (props.setSelectedPermissionIds) {
      props.setSelectedPermissionIds(selectedPermissionIds);
    }
    console.log(selectedPermissionIds, 'PermissionList.selectedPermissionIds');
  }, [selectedPermissionIds]);

  useEffect(() => {
    setSelectedPermissionIds(existingPermissionIds);
    console.log(existingPermissionIds, 'PermissionList.existingPermissionIds');
  }, [existingPermissionIds]);

  return !(permissionListLoading || props.isLoading) ? (
    <>
      <Stack direction={'row'} justifyContent={'flex-end'} sx={{ width: '100%', mb: 1, mt: 2 }}>
        <OutlinedButton
          title={expandAll ? t('collapseAll') : t('expandAll')}
          onClick={() => {
            setExpandAll(!expandAll);
            const newExpandedAccordionsState = domainsInOrder.reduce(
              (result, domain) => {
                result[domain] = !expandAll;
                return result;
              },
              {} as Record<string, boolean>,
            );
            setExpandedAccordions(newExpandedAccordionsState);
          }}
          leftIcon={
            expandAll ? (
              <ExpandCircleDown
                sx={{ mr: 1, transform: 'rotate(180deg)', transition: 'all 0.3s' }}
              />
            ) : (
              <ExpandCircleDown sx={{ mr: 1, transition: 'all 0.3s' }} />
            )
          }
        />
      </Stack>
      {domainsInOrder.map((domain) => (
        <PermissionDomainAccordionComponent
          domain={domain}
          permissions={domainToPermissionsMap.get(domain) ?? []}
          downMd={downMd}
          downLg={downLg}
          expandedAccordions={expandedAccordions}
          setExpandedAccordions={setExpandedAccordions}
          selectedPermissionIds={selectedPermissionIds}
          rolePermissionIds={props.rolePermissionIds ?? []}
          openedPermissionsState={{
            state: openedPermissionIds,
            setState: setOpenedPermissionIds,
          }}
          handleChangeCheckbox={handleChangeCheckbox}
          hideChips={props.hideChips}
        />
      ))}
    </>
  ) : (
    <Stack
      direction="column"
      justifyContent="space-between"
      width={'100%'}
      alignItems={'center'}
      p={2}
      gap={1.5}
    >
      {[1, 2, 3, 4, 5].map((key) => (
        <Skeleton
          key={key}
          variant="rectangular"
          width={'100%'}
          height={90}
          sx={{ borderRadius: 1 }}
        />
      ))}
    </Stack>
  );
};
