import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector, setRoute } from 'context';
import { removeUser, setUser, useUserDetailOwnQuery } from 'apps/auth/context';
import { LoadingPage } from 'components';
import { AuthRouter } from 'apps/auth/routes/AuthRouter';
import { CrmRouter } from 'apps/crm/routes/CrmRouter';
import { LeadPlusRouter } from 'apps/leadPlus/routes/LeadPlusRouter';
import { CommunityRouter } from 'apps/community/routes/CommunityRouter';
import { MarketingRouter } from 'apps/marketing/routes/MarketingRouter';
import { WindowRouter } from 'apps/window/routes/WindowRouter';
import { enqueueSnackbar } from 'notistack';
import { SettingsRouter } from 'apps/settings/routes/SettingsRouter';
import { AccountRouter } from 'apps/account/routes/AccountRouter';
import { DiskRouter } from 'apps/disk/routes/DiskRouter';

function Router() {
  const path = window.location.pathname;
  const dispatch = useAppDispatch();
  const { type, accessToken } = useAppSelector((state) => state.auth);
  const [ready, setReady] = useState(false);
  const { route } = useAppSelector((state) => state.route);

  const { error } = useUserDetailOwnQuery(
    {},
    {
      skip: !accessToken,
    },
  );

  useEffect(() => {
    const user = localStorage.getItem('auth');
    dispatch(setUser(JSON.parse(user ?? '{}')));
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      setReady(false);
      setTimeout(() => setReady(true), 2500);
    }
  }, [accessToken]);

  useEffect(() => {
    if (error) {
      if ((error as any)?.error?.code === 102) {
        dispatch(removeUser());
        setTimeout(() => setReady(true), 2000);
      } else if ((error as any)?.error?.message) {
        enqueueSnackbar((error as any)?.error?.message?.en, { variant: 'error' });
        dispatch(removeUser());
        setTimeout(() => setReady(true), 2000);
      } else {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        dispatch(removeUser());
        setTimeout(() => setReady(true), 2000);
      }
    } else {
      setReady(true);
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (
      path.split('/')[1] === 'leadPlus' ||
      path.split('/')[1] === 'marketing' ||
      path.split('/')[1] === 'community' ||
      path.split('/')[1] === 'window' ||
      path.split('/')[1] === 'disk' ||
      path.split('/')[1] === 'settings' ||
      path.split('/')[1] === 'profile' ||
      path.split('/')[1] === 'account'
    ) {
      dispatch(setRoute(path.split('/')[1]));
    } else {
      dispatch(setRoute('crm'));
    }
  }, [route, dispatch, path]);

  const renderUserTypeRouter = () => {
    switch (ready) {
      case true:
        switch (type) {
          case 'cocrm':
            return CrmRouter();
          case 'org':
            switch (route) {
              case 'crm':
                return CrmRouter();
              case 'leadPlus':
                return LeadPlusRouter();
              case 'community':
                return CommunityRouter();
              case 'marketing':
                return MarketingRouter();
              case 'window':
                return WindowRouter();
              case 'disk':
                return DiskRouter();
              case 'settings':
                return SettingsRouter();
              case 'account':
                return AccountRouter();
              default:
                return CrmRouter();
            }
          default:
            return AuthRouter();
        }
      case false:
        return <Route path="*" element={<LoadingPage />} />;
      default:
        return <Route path="*" element={<LoadingPage />} />;
    }
  };

  return (
    <BrowserRouter>
      <Routes key={type}>{renderUserTypeRouter()}</Routes>
    </BrowserRouter>
  );
}

export default Router;
