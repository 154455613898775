import { Grid } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add } from '@mui/icons-material';
import { useProductListQuery } from '../../../common/context/productsService.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ProductListComponent from './ProductList-grid.component';
import ProductCreateDrawerWrapper from '../create/ProductCreateDrawer.wrapper';
import { useLocation } from 'react-router-dom';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';

const ProductList = memo(() => {
  // general
  const { t } = useTranslation();
  const location = useLocation();
  const [openProductCreateDrawer, setOpenProductCreateDrawer] = useState(false);

  // queries
  const { data: productListData, isLoading: productListLoading } = useProductListQuery({});

  let totalProductCount = productListData?.count ?? 0;
  let isEmpty = totalProductCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenProductCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.productAndService.product.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.productAndService.product.create`),
          onClick: () => setOpenProductCreateDrawer(true),
          permission: {
            subject: PermissionSubject.product,
            action: PermissionAction.create,
          },
        }}
      />

      <ProductListComponent setOpenProductCreateDrawer={setOpenProductCreateDrawer} />

      <ProductCreateDrawerWrapper
        open={openProductCreateDrawer}
        setOpen={setOpenProductCreateDrawer}
      />
    </Grid>
  );
});

export default ProductList;
