import { GridColumnHeaderParams, GridSingleSelectColDef } from "@mui/x-data-grid-pro";
import { IGridColConfigInput } from "../infra/IGridColConfigInput";
import { generateBaseSingleSelectColDef } from "../infra/GridColDefFactory";
import { IconButton, Stack } from "@mui/material";
import * as Icons from "@mui/icons-material";
import { Iconify } from "components/iconify";

export const BaseAnyGridColumnDef = (params: {
  config: IGridColConfigInput;
}): GridSingleSelectColDef => {
  const baseColDef = generateBaseSingleSelectColDef({
    config: params.config,
  });
  baseColDef.editable = false;
  baseColDef.filterable = false;
  baseColDef.sortable = false;

  baseColDef.renderCell = params.config.overrideCell;

  baseColDef.renderHeader = (headerParams: GridColumnHeaderParams) => (
    <Stack
      direction="row"
      alignItems={params.config.headerConfig.align ?? "center"}
    >
      <Iconify icon={params.config.headerConfig.icon} sx={{ width: '15px', mr: 1, color:'primary.main'}} />
      <span style={{ fontWeight: 500 }}>
        {headerParams.colDef.headerName}
      </span>
    </Stack>
  );

  return baseColDef;
};
