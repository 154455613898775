/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from 'react';
import { getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables, isNullOrUndefinedOrEmpty } from 'corede-common';
import { INoteUpdateFilterInput, INoteUpdateInput, NoteTargetType } from 'corede-common-cocrm';
import { validateUpdateNoteInput } from '../../validations/update.validation';
import { RequestTransformerHelper } from 'validations/request.transformer.helper';
import { useNoteUpdateMutation, useNoteDetailQuery } from '../../context/note.api';
import { enqueueSnackbar } from 'notistack';
import 'react-quill/dist/quill.snow.css';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpsertNoteComponent } from '../UpsertNote.component';
import ActionDrawer from 'components/drawer/ActionDrawer';
import ActionDialog, { OverlayType } from 'components/dialog/ActionDialog';

export interface INoteUpdateOverlay {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNoteId?: string;
  overlayType: OverlayType;
  targetId?: string;
  targetType?: NoteTargetType;
}

const NoteUpdateOverlay = memo((props: INoteUpdateOverlay) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [loading, setLoading] = useState(false);

  // queries
  const {
    data: noteDetailData,
    isLoading: noteDetailLoading,
    error: noteDetailError,
  } = useNoteDetailQuery(
    {
      input: {
        _id: props.selectedNoteId,
      },
    },
    {
      skip: isNullOrUndefinedOrEmpty(props.selectedNoteId),
    },
  );

  // mutations
  const [
    noteUpdate,
    { data: noteUpdateData, isLoading: noteUpdateLoading, error: noteUpdateError },
  ] = useNoteUpdateMutation();

  // constants
  const [initialValues, setInitialValues] = useState<
    IGraphqlVariables<INoteUpdateInput, INoteUpdateFilterInput>
  >({
    filter: {
      _id: noteDetailData?._id || '',
    },
    input: {
      title: noteDetailData?.title || '',
      content: noteDetailData?.content || '',
      targetId: props?.targetId ?? noteDetailData?.target?._id ?? '',
      targetType: props?.targetType ?? noteDetailData?.targetType,
    },
  });

  // form setup
  const noteUpdateUseForm = useForm<IGraphqlVariables<INoteUpdateInput, INoteUpdateFilterInput>>({
    values: initialValues,
    resolver: yupResolver(validateUpdateNoteInput),
    mode: 'onChange',
  });

  const onSubmit = async (values: IGraphqlVariables<INoteUpdateInput, INoteUpdateFilterInput>) => {
    setLoading(true);
    const transformedValues = RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
      input: values.input,
    });
    await noteUpdate({
      filter: values.filter,
      input: transformedValues,
    });
    setLoading(false);
  };

  // useEffects.success

  useEffect(() => {
    if (noteUpdateData) {
      enqueueSnackbar(t('crm.note.note.updateSuccess'), {
        variant: 'success',
      });
      noteUpdateUseForm.reset();
      props.setOpen(false);
    }
  }, [noteUpdateData, noteUpdateUseForm.reset]);

  // useEffects.error

  useEffect(() => {
    DefaultErrorHandlerUseEffect(noteDetailError as IBaseError, currentLanguage);
  }, [noteDetailError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(noteUpdateError as IBaseError, currentLanguage);
  }, [noteUpdateError]);

  // useEffects.init

  useEffect(() => {
    if (noteDetailData) {
      setInitialValues({
        filter: {
          _id: noteDetailData._id,
        },
        input: {
          title: noteDetailData?.title || '',
          content: noteDetailData?.content || '',
          targetId: props?.targetId ?? noteDetailData?.target?._id ?? '',
          targetType: props?.targetType ?? noteDetailData?.targetType,
        },
      });
    }
  }, [noteDetailData]);

  const commonContent = (
    <UpsertNoteComponent
      open={props.open}
      setOpen={props.setOpen}
      loading={noteUpdateLoading || loading}
      useForm={noteUpdateUseForm}
      targetId={props?.targetId ?? noteDetailData?.target?._id}
      targetType={props?.targetType ?? noteDetailData?.targetType}
    />
  );

  switch (props.overlayType) {
    case OverlayType.drawer:
      return (
        <ActionDrawer
          open={props.open}
          setOpen={props.setOpen}
          size="medium"
          backgroundColor={'background.default'}
          title={t('crm.note.note.update')}
          handleSubmit={noteUpdateUseForm.handleSubmit(onSubmit)}
          disabled={noteUpdateLoading || loading || !noteUpdateUseForm.formState.isValid}
          loading={noteUpdateLoading || loading}
          buttonTitle={t('crm.note.note.update')}
        >
          {commonContent}
        </ActionDrawer>
      );
    case OverlayType.dialog:
      return (
        <ActionDialog
          open={props.open}
          setOpen={props.setOpen}
          title={t('crm.note.note.update')}
          handleClick={noteUpdateUseForm.handleSubmit(onSubmit)}
          disabled={noteUpdateLoading || loading || !noteUpdateUseForm.formState.isValid}
          loading={noteUpdateLoading || loading}
          buttonTitle={t('crm.note.note.update')}
          width={500}
        >
          {commonContent}
        </ActionDialog>
      );
    default:
      return null;
  }
});

export default NoteUpdateOverlay;
