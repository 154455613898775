import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ContractListComponent from './ContractList-grid.component';
import { useLocation } from 'react-router-dom';
import ContractCreateDrawerWrapper from '../create/ContractCreateDrawer.wrapper';
import { useContractListCountQuery } from '../../context/contract.api';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const ContractList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openContractCreateDrawer, setOpenContractCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();

  // queries
  const { data: contractListCountData, isLoading: contractListCountLoading } =
    useContractListCountQuery({});

  let totalContractCount = contractListCountData?.count ?? 0;
  let isEmpty = totalContractCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenContractCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {t('crm.contract.contract.title')}
            </Typography>
          </Stack>
        }
        // description={
        //   <Typography variant={downMd ? 'caption' : 'body1'}>
        //     {t(`crm.contract.contract.description`)}
        //   </Typography>
        // }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.contract.contract.create`),
          onClick: () => setOpenContractCreateDrawer(true),
          permission: {
            subject: PermissionSubject.contract,
            action: PermissionAction.create,
          },
        }}
      />

      <ContractListComponent setOpenContractCreateDrawer={setOpenContractCreateDrawer} />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.contract,
          action: PermissionAction.create,
        }}
      >
        <ContractCreateDrawerWrapper
          open={openContractCreateDrawer}
          setOpen={setOpenContractCreateDrawer}
        />
      </PermissionWrapper>
    </Grid>
  );
});

export default ContractList;
