/* eslint-disable react-hooks/exhaustive-deps */
import { useInvoiceDetailQuery } from '../../context/invoice.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { InvoiceDetailDrawerLeftPanelComponent } from './InvoiceDetailDrawer-leftPanel.component';
import { InvoiceDetailDrawerRightPanelComponent } from './InvoiceDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { IInvoice, IInvoiceDetailResult } from 'corede-common-cocrm';

export interface IInvoiceDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedInvoice: IInvoiceDetailResult & IHasId<number>;
  setSelectedInvoice: Dispatch<SetStateAction<TListGridSelectedEntity<IInvoice>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const InvoiceDetailDrawer = memo((props: IInvoiceDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: invoiceDetailData, error: invoiceDetailError } = useInvoiceDetailQuery(
    props?.selectedInvoice?._id
      ? {
          input: {
            _id: props?.selectedInvoice?._id,
          },
        }
      : skipToken,
    {
      skip: !props?.selectedInvoice?._id,
    },
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(invoiceDetailError as IBaseError, currentLanguage);
  }, [invoiceDetailError]);

  return (
    <DefaultDetailDrawer<IInvoiceDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedInvoice}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.sale.invoice.invoices'),
            }
      }
    >
      <InvoiceDetailDrawerLeftPanelComponent
        invoiceDetailData={invoiceDetailData}
        setOpen={props.setOpen}
      />
      <InvoiceDetailDrawerRightPanelComponent invoiceDetailData={invoiceDetailData} />
    </DefaultDetailDrawer>
  );
});

export default InvoiceDetailDrawer;
