const locale = {
  projects: 'Projects',
  project: 'Project',
  projectConversions: 'Project Conversions',
  title: 'Projects',
  create: 'Create Project',
  update: 'Update Project',
  delete: 'Delete Project',
  createSuccess: 'Project created successfully.',
  updateSuccess: 'Project updated successfully.',
  deleteSuccess: 'Project deleted successfully.',
  deleteConfirm: 'Are you sure you want to delete this project. This action cannot be undone.',
  emptyState1: 'No projects started yet.',
  emptyState2:
    'Create and manage your projects here to track progress, assign tasks, and collaborate with your team.',
  progressCalculationType: 'Progress Calc.',
  progressPercentage: 'Progress %',
  isBillable: 'Billable',
  billingType: 'Billing Type',
  billingRatePerHour: 'Billing Rate',
  members: 'Members',
  startDate: 'Start Date',
  deadline: 'Deadline',
  estimatedHours: 'Estimated Hours',
  // labels
  welcome: 'Welcome',
  description: "Your project progress this week is amazing. Let's continue.",

  // detail
  changeStatus: 'Change Status',
  generalInfoTitle: 'General Information',
  customerInfoTitle: 'Customer Information',
  contactPhoneNumber: 'Contact Phone Number',
  membersTitle: 'Members',
  milestone: 'Milestone',
  timeSheet: 'Time Sheet',
  projectInfoTitle: 'Project Information',
  billingInfoTitle: 'Billing Information',
  statusChangeSuccess: 'Project status changed successfully.',
  detail: 'Project Detail',
  noMember: 'No member yet.',

  // timeSheet
  noAssigneeToCreateTimeSheet: 'Please assign users to project to create time sheets',
  emptyStateTimeSheetList1: 'There is no time sheet yet.',
  addTimeSheet: 'Add Time Sheet',
  timeSheetCreate: 'Create Time Sheet',
  timeSheetUpdate: 'Update Time Sheet',
  member: 'Member',
  startTime: 'Start Time',
  endTime: 'End Time',
  duration: 'Duration',
  timeSheetCreateSuccess: 'Time sheet created successfully.',
  timeSheetUpdateSuccess: 'Time sheet updated successfully.',
  timeSheetDeleteSuccess: 'Time sheet deleted successfully.',

  // milestone
  emptyStateMilestoneList1: 'There is no milestone yet.',
  addMilestone: 'Add Milestone',
  milestoneCreate: 'Create Milestone',
  milestoneUpdate: 'Update Milestone',
  milestoneCreateSuccess: 'Milestone created successfully.',
  milestoneUpdateSuccess: 'Milestone updated successfully.',
  milestoneDeleteSuccess: 'Milestone deleted successfully.',
  addTask: 'Add Task',
};

export default locale;
