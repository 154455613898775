/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  Divider,
  Grid,
  Grow,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FilledButton, MyTab, SettingsTitle } from 'components';
import {
  Currency,
  ExchangeRateMode,
  IGraphqlVariables,
  Language,
  unknownError,
} from 'corede-common';
import {
  DateFormat,
  IOrganizationSettingsCommonUpdateOwnInput,
  IOrganizationSettingsUpdateInput,
  IOrganizationUpdateInput,
  OrganizationStatus,
  TimeFormat,
} from 'corede-common-cocrm';
import { useEffect, useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import {
  useOrganizationDetailOwnQuery,
  useOrganizationLogoAssignMutation,
  useOrganizationSettingsDetailOwnQuery,
  useOrganizationSettingsUpdateOwnMutation,
  useOrganizationUpdateOwnMutation,
  useOrganizationLogoCreateMutation,
  useOrganizationSettingsCommonUpdateOwnMutation,
} from '../context/setting.api';
import {
  convertEnums,
  generateAutocompleteTranslatedOptions,
  getCurrentLanguage,
} from 'localization';
import { RefreshOutlined } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { UploadImageComponent } from 'components/uploadFile/UploadImage';
import Company from 'assets/images/company.png';
import {
  useCountryListQuery,
  useLazyCityListQuery,
  useLazyStateListQuery,
} from 'apps/auth/context';
import { useTranslation } from 'react-i18next';
import { validateGeneralFormInput } from '../validations/update.validation';
import { useAppSelector } from 'context';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultNameAutocompleteInput } from 'components/form/DefaultNameAutocompleteInput';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { useExchangeRateRetrieveQuery } from 'apps/crm/domains/16-pricing/subdomains/pricing';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';

const Organization = () => {
  // useEffect(() => {
  //   fetch('https://ipapi.co/json/')
  //     .then(response => response.json())
  //     .then(data => {
  //     })
  //     .catch(error => console.error('Error fetching IP information:', error));
  // }, []);

  const { t } = useTranslation();
  const { accessToken } = useAppSelector((state) => state.auth);
  const currentLanguage = getCurrentLanguage();
  const timezones = momentTimezone.tz.names();

  const [selectedTabName, setSelectedTabName] = useState('settings.settings.settings.general');
  const [openChangeImage, setOpenChangeImage] = useState(false);

  const [uploadLoading, setUploadLoading] = useState(false);

  const localizedCurrencies = convertEnums(Currency);
  const localizedLanguages = convertEnums(Language);
  const localizedExchangeRateModes = convertEnums(ExchangeRateMode);

  const { data: countryListData, isLoading: countryListLoading } = useCountryListQuery({
    input: {
      pagination: {
        sort: {
          name: 1,
        },
      },
    },
  });
  const [getStateList, { data: stateListData, isLoading: stateListLoading }] =
    useLazyStateListQuery({});
  const [getCityList, { data: cityListData, isLoading: cityListLoading }] = useLazyCityListQuery(
    {},
  );
  const { data: organizationDetailOwnData, refetch: organizationDetailOwnRefetch } =
    useOrganizationDetailOwnQuery({});
  const { data: organizationSettingsDetailOwnData } = useOrganizationSettingsDetailOwnQuery(
    {},
    {
      skip: !accessToken,
    },
  );
  const [
    organizationUpdateOwn,
    {
      data: organizationUpdateOwnData,
      isLoading: organizationUpdateOwnLoading,
      error: organizationUpdateOwnError,
    },
  ] = useOrganizationUpdateOwnMutation();

  const tabLabels =
    organizationDetailOwnData?.status === OrganizationStatus.active
      ? [
          'settings.settings.settings.general',
          'settings.settings.settings.localizations',
          'settings.settings.settings.finance',
        ]
      : ['settings.settings.settings.general', 'settings.settings.settings.localizations'];

  const [
    organizationSettingsUpdateOwn,
    {
      data: organizationSettingsUpdateOwnData,
      isLoading: organizationSettingsUpdateOwnLoading,
      error: organizationSettingsUpdateOwnError,
    },
  ] = useOrganizationSettingsUpdateOwnMutation();

  const [
    organizationSettingsCommonUpdateOwn,
    {
      data: organizationSettingsCommonUpdateOwnData,
      isLoading: organizationSettingsCommonUpdateOwnLoading,
      error: organizationSettingsCommonUpdateOwnError,
    },
  ] = useOrganizationSettingsCommonUpdateOwnMutation();

  const handleSaveLogo = async () => {};

  // General Settings

  const generalFormInitialValues = useMemo(
    () => ({
      input: {
        name: undefined,
        legalName: undefined,
        summary: undefined,
        website: undefined,
        addresses: [
          {
            address: '',
            country: '',
            state: '',
            city: '',
            zip: '',
            fullName: '',
            phone: '',
            street: '',
          },
        ],
        phone: undefined,
        email: undefined,
        socialMedias: {
          facebook: undefined,
          instagram: undefined,
          linkedin: undefined,
          x: undefined,
          youtube: undefined,
        },
      },
    }),
    [],
  );

  const generalForm = useForm<IGraphqlVariables<IOrganizationUpdateInput>>({
    defaultValues: generalFormInitialValues,
    resolver: yupResolver(validateGeneralFormInput),
    mode: 'onChange',
  });

  const generalFormOnSubmit = async (values: IGraphqlVariables<IOrganizationUpdateInput>) => {
    organizationUpdateOwn({
      input: values.input,
    });
  };

  useEffect(() => {
    if (organizationDetailOwnData) {
      generalForm.setValue('input.name', organizationDetailOwnData?.name);
      generalForm.setValue('input.legalName', organizationDetailOwnData?.legalName);
      generalForm.setValue('input.summary', organizationDetailOwnData?.summary);
      generalForm.setValue('input.website', organizationDetailOwnData?.website);
      generalForm.setValue('input.phone', organizationDetailOwnData?.phone);
      generalForm.setValue('input.email', organizationDetailOwnData?.email);
      generalForm.setValue('input.socialMedias', organizationDetailOwnData?.socialMedias);
      generalForm.setValue(
        'input.addresses.0.address',
        organizationDetailOwnData?.addresses?.[0]?.address ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.country',
        organizationDetailOwnData?.addresses?.[0]?.country ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.state',
        organizationDetailOwnData?.addresses?.[0]?.state ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.city',
        organizationDetailOwnData?.addresses?.[0]?.city ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.zip',
        organizationDetailOwnData?.addresses?.[0]?.zip ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.fullName',
        organizationDetailOwnData?.addresses?.[0]?.fullName ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.phone',
        organizationDetailOwnData?.addresses?.[0]?.phone ?? '',
      );
      generalForm.setValue(
        'input.addresses.0.street',
        organizationDetailOwnData?.addresses?.[0]?.street ?? '',
      );

      // setAddresses(organizationDetailOwnData?.addresses ?? []);
    }
  }, [organizationDetailOwnData]);

  useEffect(() => {
    if (organizationDetailOwnData?.addresses?.[0]?.country) {
      getStateList({
        input: {
          filter: {
            country_name: organizationDetailOwnData?.addresses?.[0]?.country,
          },
        },
      });
    }
  }, [organizationDetailOwnData?.addresses?.[0]?.country]);

  useEffect(() => {
    if (generalForm.watch('input.addresses.0.country')) {
      getStateList({
        input: {
          filter: {
            country_name: generalForm.watch('input.addresses.0.country'),
          },
        },
      });
    }
  }, [generalForm.watch('input.addresses.0.country')]);

  useEffect(() => {
    if (generalForm.watch('input.addresses.0.state')) {
      getCityList({
        input: {
          filter: {
            country_name: generalForm.watch('input.addresses.0.country'),
            state_name: generalForm.watch('input.addresses.0.state'),
          },
        },
      });
    }
  }, [
    generalForm.watch('input.addresses.0.state'),
    generalForm.watch('input.addresses.0.country'),
  ]);

  useEffect(() => {
    if (organizationUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
      organizationDetailOwnRefetch();
    }
  }, [organizationUpdateOwnData]);

  useEffect(() => {
    if (organizationUpdateOwnError) {
      enqueueSnackbar(
        (organizationUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationUpdateOwnError]);

  // Localizations

  const localizationsFormInitialValues = useMemo(
    () => ({
      input: {
        common: {
          timeZone: undefined,
          language: undefined,
          dateFormat: undefined,
          timeFormat: undefined,
        },
      },
    }),
    [],
  );

  const localizationsForm = useForm<IGraphqlVariables<IOrganizationSettingsUpdateInput>>({
    defaultValues: localizationsFormInitialValues,
    // resolver: yupResolver(validateOrganizationSettingsUpdateInput),
    mode: 'onSubmit',
  });

  const localizationsFormOnSubmit = (
    values: IGraphqlVariables<
      IOrganizationSettingsUpdateInput & IOrganizationSettingsCommonUpdateOwnInput
    >,
  ) => {
    organizationDetailOwnData?.status === OrganizationStatus.active
      ? organizationSettingsUpdateOwn({ input: values.input })
      : organizationSettingsCommonUpdateOwn({ input: values.input?.common });
  };

  useEffect(() => {
    if (organizationSettingsDetailOwnData) {
      localizationsForm.setValue(
        'input.common.timeZone',
        organizationSettingsDetailOwnData?.common?.timeZone,
      );
      localizationsForm.setValue(
        'input.common.language',
        organizationSettingsDetailOwnData?.common?.language,
      );
      localizationsForm.setValue(
        'input.common.dateFormat',
        organizationSettingsDetailOwnData?.common?.dateFormat,
      );
      localizationsForm.setValue(
        'input.common.timeFormat',
        organizationSettingsDetailOwnData?.common?.timeFormat,
      );
    }
  }, [organizationSettingsDetailOwnData]);

  useEffect(() => {
    if (organizationSettingsUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
    }
  }, [organizationSettingsUpdateOwnData]);

  useEffect(() => {
    if (organizationSettingsCommonUpdateOwnData) {
      enqueueSnackbar(t('settings.settings.settings.updateSuccess'), { variant: 'success' });
    }
  }, [organizationSettingsCommonUpdateOwnData]);

  useEffect(() => {
    if (organizationSettingsUpdateOwnError) {
      enqueueSnackbar(
        (organizationSettingsUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationSettingsUpdateOwnError]);

  useEffect(() => {
    if (organizationSettingsCommonUpdateOwnError) {
      enqueueSnackbar(
        (organizationSettingsCommonUpdateOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationSettingsCommonUpdateOwnError]);

  // Finance

  const financeFormInitialValues = useMemo(
    () => ({
      input: {
        common: {
          currency: undefined,
          adjustment: undefined,
          exchangeRates: {
            mode: ExchangeRateMode.automatic,
            manualRates: undefined,
          },
        },
      },
    }),
    [],
  );

  const financeForm = useForm<IGraphqlVariables<IOrganizationSettingsUpdateInput>>({
    defaultValues: financeFormInitialValues,
    mode: 'onChange',
  });

  const financeFormOnSubmit = (values: IGraphqlVariables<IOrganizationSettingsUpdateInput>) => {
    organizationSettingsUpdateOwn({ input: values.input });
  };

  const { data: useExchangeRateData } = useExchangeRateRetrieveQuery({
    input: {
      fromCurrency: Currency.USD,
      toCurrency: financeForm.watch('input.common.currency') ?? Currency.TRY,
    },
  });

  useEffect(() => {
    if (organizationSettingsDetailOwnData) {
      financeForm.setValue(
        'input.common.exchangeRates.mode',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.mode,
      );
      financeForm.setValue(
        'input.common.currency',
        organizationSettingsDetailOwnData?.common?.currency,
      );
      financeForm.setValue(
        'input.common.adjustment',
        organizationSettingsDetailOwnData?.common?.adjustment,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.fromCurrency',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.fromCurrency ??
          Currency.USD,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.toCurrency',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.toCurrency ??
          Currency.TRY,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.rate',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.rate ?? 35,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.updatedAt',
        organizationSettingsDetailOwnData?.common?.exchangeRates?.manualRates?.[0]?.updatedAt ??
          new Date(),
      );
    }
  }, [organizationSettingsDetailOwnData]);

  useEffect(() => {
    if (financeForm.watch('input.common.currency')) {
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.toCurrency',
        financeForm.watch('input.common.currency') as Currency,
      );
      financeForm.setValue(
        'input.common.exchangeRates.manualRates.0.fromCurrency',
        financeForm.watch('input.common.currency') === Currency.USD ? Currency.TRY : Currency.USD,
      );
    }
  }, [financeForm.watch('input.common.currency')]);

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={3}>
      <Stack
        direction={'row'}
        sx={{
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        <MyTab
          type="settings"
          labels={tabLabels}
          setSelectedTab={setSelectedTabName}
          selectedTab={selectedTabName}
        />
      </Stack>
      {selectedTabName === 'settings.settings.settings.general' && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.general')} />
          <Grid item xs={12} md={3}>
            <Grow in={!!organizationDetailOwnData} timeout={500}>
              <Card
                sx={{
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                  py: 10,
                }}
              >
                <Stack
                  direction={'column'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  gap={2}
                  width={'100%'}
                >
                  <InputLabel
                    htmlFor="input.name"
                    sx={{ ml: 1, mb: 0.5, fontSize: 'small', cursor: 'pointer' }}
                  >
                    {t('settings.settings.settings.organizationLogo')}
                  </InputLabel>
                  <Box
                    sx={{
                      border: '1px dashed',
                      borderColor: 'primary.light',
                      borderRadius: '55px',
                      position: 'relative',
                      cursor: 'pointer',
                    }}
                    onClick={() => setOpenChangeImage(true)}
                  >
                    <Avatar
                      key={organizationDetailOwnData?.logo?.thumbnailPublicUrl}
                      src={organizationDetailOwnData?.logo?.thumbnailPublicUrl}
                      sx={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '55px',
                        border: '5px solid',
                        borderColor: 'background.secondary',
                      }}
                    >
                      <img src={Company} alt="company" style={{ width: '100%', height: '100%' }} />
                    </Avatar>
                  </Box>
                </Stack>
              </Card>
            </Grow>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grow in={selectedTabName === 'settings.settings.settings.general'} timeout={500}>
              <Card
                sx={{
                  p: 2,
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                }}
              >
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <Typography fontWeight={600}>
                      {t('settings.settings.settings.generalInfo')}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={1.4}>
                      <Grid item xs={12}>
                        <DefaultTextFieldInput
                          control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                          errors={generalForm.formState.errors}
                          label={t('settings.settings.settings.name')}
                          inputName="input.name"
                          isFirstLetterCapitalize
                          touchedFields={generalForm.formState.touchedFields}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DefaultTextFieldInput
                          control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                          errors={generalForm.formState.errors}
                          label={t('settings.settings.settings.legalName')}
                          inputName="input.legalName"
                          isFirstLetterCapitalize
                          touchedFields={generalForm.formState.touchedFields}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <DefaultTextFieldInput
                          control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                          errors={generalForm.formState.errors}
                          label={t('settings.settings.settings.email')}
                          inputName="input.email"
                          touchedFields={generalForm.formState.touchedFields}
                          type="email"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      label={t('settings.settings.settings.summary')}
                      inputName="input.summary"
                      touchedFields={generalForm.formState.touchedFields}
                      isFirstLetterCapitalize
                      multiline
                      rows={7}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      label={t('settings.settings.settings.website')}
                      inputName="input.website"
                      touchedFields={generalForm.formState.touchedFields}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      label={t('settings.settings.settings.phone')}
                      inputName="input.phone"
                      touchedFields={generalForm.formState.touchedFields}
                      type="tel"
                    />
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>
                      {t('settings.settings.settings.address')}
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      label={t('settings.settings.settings.address')}
                      inputName="input.addresses.0.address"
                      touchedFields={generalForm.formState.touchedFields}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DefaultNameAutocompleteInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.addresses.0.country"
                      label={t('settings.settings.settings.country')}
                      options={countryListData?.data || []}
                      touchedFields={generalForm.formState.touchedFields}
                      isLoading={countryListLoading}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DefaultNameAutocompleteInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.addresses.0.state"
                      label={t('settings.settings.settings.state')}
                      options={stateListData?.data || []}
                      touchedFields={generalForm.formState.touchedFields}
                      isLoading={stateListLoading}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DefaultNameAutocompleteInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.addresses.0.city"
                      label={t('settings.settings.settings.city')}
                      options={cityListData?.data || []}
                      touchedFields={generalForm.formState.touchedFields}
                      isLoading={cityListLoading}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.addresses.0.zip"
                      touchedFields={generalForm.formState.touchedFields}
                      label={t('settings.settings.settings.zip')}
                    />
                  </Grid>

                  <Grid item xs={12} mt={1}>
                    <Typography fontWeight={600}>
                      {t('settings.settings.settings.socialMedia')}
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.socialMedias.facebook"
                      touchedFields={generalForm.formState.touchedFields}
                      label={'Facebook'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.socialMedias.instagram"
                      touchedFields={generalForm.formState.touchedFields}
                      label={'Instagram'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.socialMedias.linkedin"
                      touchedFields={generalForm.formState.touchedFields}
                      label={'Linkedin'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.socialMedias.x"
                      touchedFields={generalForm.formState.touchedFields}
                      label={'X'}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <DefaultTextFieldInput
                      control={generalForm.control as Control<IGraphqlVariables<any, any>>}
                      errors={generalForm.formState.errors}
                      inputName="input.socialMedias.youtube"
                      touchedFields={generalForm.formState.touchedFields}
                      label={'Youtube'}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    <FilledButton
                      leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                      color="primary"
                      loading={organizationUpdateOwnLoading}
                      title={t('settings.settings.settings.updateSettings')}
                      onClick={generalForm.handleSubmit(generalFormOnSubmit)}
                      disabled={!generalForm.formState.isValid}
                    />
                  </Stack>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}
      {selectedTabName === 'settings.settings.settings.localizations' && (
        <Grid container spacing={2} px={2}>
          <SettingsTitle selectedTabName={t('settings.settings.settings.localizations')} />

          <Grid item xs={12} lg={6}>
            <Grow in={selectedTabName === 'settings.settings.settings.localizations'} timeout={500}>
              <Card
                sx={{
                  p: 2,
                  border: '1px solid',
                  bgcolor: 'background.thirdWithBlur',
                  borderColor: 'primary.lighter',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DefaultObjectAutocompleteInput
                      control={localizationsForm.control as Control<IGraphqlVariables<any, any>>}
                      inputName="input.common.timeZone"
                      label={t('settings.settings.settings.timezone')}
                      options={timezones.map((timezone) => ({
                        name: timezone,
                        _id: timezone,
                      }))}
                      errors={localizationsForm.formState.errors}
                      touchedFields={localizationsForm.formState.touchedFields}
                      disableAddNewOption
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DefaultEnumAutocompleteInput
                      control={localizationsForm.control as Control<IGraphqlVariables<any, any>>}
                      enum={Language}
                      inputName="input.common.language"
                      label={t('settings.settings.settings.language')}
                      options={generateAutocompleteTranslatedOptions(Language)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DefaultObjectAutocompleteInput
                      control={localizationsForm.control as Control<IGraphqlVariables<any, any>>}
                      inputName="input.common.dateFormat"
                      label={t('settings.settings.settings.dateFormat')}
                      options={Object.values(DateFormat).map((dateFormat) => ({
                        name: dateFormat,
                        _id: dateFormat,
                      }))}
                      errors={localizationsForm.formState.errors}
                      touchedFields={localizationsForm.formState.touchedFields}
                      disableAddNewOption
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DefaultEnumAutocompleteInput
                      control={localizationsForm.control as Control<IGraphqlVariables<any, any>>}
                      enum={TimeFormat}
                      inputName="input.common.timeFormat"
                      label={t('settings.settings.settings.timeFormat')}
                      options={generateAutocompleteTranslatedOptions(TimeFormat)}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Stack direction={'row'} justifyContent={'flex-end'}>
                      <FilledButton
                        leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                        color="primary"
                        loading={
                          organizationSettingsUpdateOwnLoading ||
                          organizationSettingsCommonUpdateOwnLoading
                        }
                        title={t('settings.settings.settings.updateSettings')}
                        onClick={localizationsForm.handleSubmit(localizationsFormOnSubmit)}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grow>
          </Grid>
        </Grid>
      )}
      {
        selectedTabName === 'settings.settings.settings.finance' && (
          <Grid container spacing={2} px={2}>
            <SettingsTitle selectedTabName={t('settings.settings.settings.finance')} />
            <Grid item xs={12} lg={6}>
              <Grow in={selectedTabName === 'settings.settings.settings.finance'} timeout={500}>
                <Card
                  sx={{
                    p: 2,
                    border: '1px solid',
                    bgcolor: 'background.thirdWithBlur',
                    borderColor: 'primary.lighter',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor="input.common.currency"
                        sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                      >
                        {t('settings.settings.settings.currency')}
                      </InputLabel>
                      <Controller
                        name="input.common.currency"
                        control={financeForm.control}
                        render={({ field }) => (
                          <Autocomplete
                            noOptionsText={t('noOptionsText')}
                            id="financeForm"
                            disableClearable
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            size="small"
                            options={Object.values(Currency).map((currency) => ({
                              name: localizedCurrencies[currency as keyof typeof Currency],
                              _id: currency,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} />}
                            value={
                              field.value
                                ? {
                                    name: localizedCurrencies[field.value as keyof typeof Currency],
                                    _id: field.value,
                                  }
                                : undefined
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="adjustment" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                        {t('settings.settings.settings.adjustment')}
                      </InputLabel>
                      <Controller
                        name="input.common.adjustment"
                        control={financeForm.control}
                        render={({ field }) => (
                          <Autocomplete
                            noOptionsText={t('noOptionsText')}
                            id="adjustment"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option === value}
                            options={[0, 1, 2, 3]}
                            getOptionLabel={(option) => option.toString()}
                            renderInput={(params) => <TextField {...params} />}
                            value={field.value}
                            onChange={(e, value) => {
                              field.onChange(value);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputLabel
                        htmlFor="exchangeRateMode"
                        sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                      >
                        {t('settings.settings.settings.exchangeRateMode')}
                      </InputLabel>
                      <Controller
                        name="input.common.exchangeRates.mode"
                        control={financeForm.control}
                        render={({ field }) => (
                          <Autocomplete
                            noOptionsText={t('noOptionsText')}
                            id="mode"
                            size="small"
                            fullWidth
                            isOptionEqualToValue={(option, value) => option._id === value._id}
                            options={Object.values(ExchangeRateMode).map((mode) => ({
                              name: localizedExchangeRateModes[
                                mode as keyof typeof ExchangeRateMode
                              ],
                              _id: mode,
                            }))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                helperText={
                                  field.value === ExchangeRateMode.automatic && (
                                    <Typography fontSize={'small'} color={'text.secondary'}>
                                      1{' '}
                                      {financeForm.watch(
                                        'input.common.exchangeRates.manualRates.0.fromCurrency',
                                      )}{' '}
                                      = {useExchangeRateData?.rate}{' '}
                                      {financeForm.watch(
                                        'input.common.exchangeRates.manualRates.0.toCurrency',
                                      )}
                                    </Typography>
                                  )
                                }
                              />
                            )}
                            value={
                              field.value
                                ? {
                                    name: localizedExchangeRateModes[
                                      field.value as keyof typeof ExchangeRateMode
                                    ],
                                    _id: field.value,
                                  }
                                : null
                            }
                            onChange={(e, value) => {
                              field.onChange(value?._id);
                            }}
                          />
                        )}
                      />
                    </Grid>

                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="input.common.currency"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                        >
                          {t('settings.settings.settings.fromCurrency')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.fromCurrency"
                          control={financeForm.control}
                          render={({ field }) => (
                            <Autocomplete
                              noOptionsText={t('noOptionsText')}
                              id="financeForm"
                              disableClearable
                              isOptionEqualToValue={(option, value) => option._id === value._id}
                              size="small"
                              options={Object.values(Currency)
                                .filter(
                                  (currency) =>
                                    currency !== financeForm.watch('input.common.currency'),
                                )
                                .map((currency) => ({
                                  name: localizedCurrencies[currency as keyof typeof Currency],
                                  _id: currency,
                                }))}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => <TextField {...params} />}
                              value={
                                field.value
                                  ? {
                                      name: localizedCurrencies[
                                        field.value as keyof typeof Currency
                                      ],
                                      _id: field.value,
                                    }
                                  : undefined
                              }
                              onChange={(e, value) => {
                                field.onChange(value?._id);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="input.common.currency"
                          sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}
                        >
                          {t('settings.settings.settings.toCurrency')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.toCurrency"
                          control={financeForm.control}
                          render={({ field }) => (
                            <Autocomplete
                              noOptionsText={t('noOptionsText')}
                              id="financeForm"
                              disabled
                              disableClearable
                              isOptionEqualToValue={(option, value) => option._id === value._id}
                              size="small"
                              options={Object.values(Currency).map((currency) => ({
                                name: localizedCurrencies[currency as keyof typeof Currency],
                                _id: currency,
                              }))}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => <TextField {...params} />}
                              value={
                                field.value
                                  ? {
                                      name: localizedCurrencies[
                                        field.value as keyof typeof Currency
                                      ],
                                      _id: field.value,
                                    }
                                  : undefined
                              }
                              onChange={(e, value) => {
                                field.onChange(value?._id);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel htmlFor="rate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                          {t('settings.settings.settings.rate')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.rate"
                          control={financeForm.control}
                          render={({ field }) => (
                            <TextField
                              size="small"
                              type="number"
                              helperText={
                                field.value && (
                                  <Typography fontSize={'small'} color={'text.secondary'}>
                                    1{' '}
                                    {financeForm.watch(
                                      'input.common.exchangeRates.manualRates.0.fromCurrency',
                                    )}{' '}
                                    ={' '}
                                    {financeForm.watch(
                                      'input.common.exchangeRates.manualRates.0.rate',
                                    )}{' '}
                                    {financeForm.watch(
                                      'input.common.exchangeRates.manualRates.0.toCurrency',
                                    )}
                                  </Typography>
                                )
                              }
                              fullWidth
                              {...field}
                              onChange={(e) => {
                                const value =
                                  e.target.value === '' ? '' : parseFloat(e.target.value);
                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {financeForm.watch('input.common.exchangeRates.mode') ===
                      ExchangeRateMode.manual && (
                      <Grid item xs={6}>
                        <InputLabel htmlFor="updatedAt" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
                          {t('settings.settings.settings.updatedAt')}
                        </InputLabel>
                        <Controller
                          name="input.common.exchangeRates.manualRates.0.updatedAt"
                          control={financeForm.control}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              value={field.value ? moment(field.value) : null}
                              onChange={(date) => field.onChange(date)}
                              maxDate={moment()}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  fullWidth: true,
                                },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} mt={2}>
                      <Stack direction={'row'} justifyContent={'flex-end'}>
                        <FilledButton
                          leftIcon={<RefreshOutlined sx={{ mr: 2 }} />}
                          color="primary"
                          loading={organizationSettingsUpdateOwnLoading}
                          title={t('settings.settings.settings.updateSettings')}
                          onClick={financeForm.handleSubmit(financeFormOnSubmit)}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Card>
              </Grow>
            </Grid>
          </Grid>
        )
        //   <Grid item xs={12} lg={6}>

        //           <Grid item xs={12} >
        //             <InputLabel htmlFor="dateFormat" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("settings.settings.settings.dateFormat")}</InputLabel>
        //             <Controller
        //               name="input.common.dateFormat"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        // noOptionsText={t("noOptionsText")}
        //                   id="language"
        //                   size="small"
        //                   fullWidth
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   options={Object.values(Language).map((language) => ({
        //                     name: localizedLanguages[language as keyof typeof Language],
        //                     _id: language,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedLanguages[field.value as keyof typeof Language], _id: field.value }
        //                       : null
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12} >
        //             <InputLabel htmlFor="timeFormat" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>{t("settings.settings.settings.timeFormat")}</InputLabel>
        //             <Controller
        //               name="input.common.timeFormat"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        //noOptionsText={t("noOptionsText")}
        //                   id="language"
        //                   size="small"
        //                   fullWidth
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   options={Object.values(Language).map((language) => ({
        //                     name: localizedLanguages[language as keyof typeof Language],
        //                     _id: language,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedLanguages[field.value as keyof typeof Language], _id: field.value }
        //                       : null
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12} >
        //             <InputLabel htmlFor="input.common.adjustment" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //               {t("settings.settings.settings.adjustment")}*
        //             </InputLabel>
        //             <Controller
        //               name="input.common.adjustment"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <TextField
        //                   {...field}
        //                   id="input.common.adjustment"
        //                   size="small"
        //                   fullWidth
        //                   required
        //                   type="number"
        //                   inputProps={{
        //                     step: "0.01",
        //                   }}
        //                   onChange={(e) => {
        //                     const value = parseFloat(e.target.value);
        //                     field.onChange(value);
        //                   }}
        //                   error={!!commonForm.formState.errors.input?.common?.adjustment}
        //                   helperText={commonForm.formState.errors.input?.common?.adjustment?.message}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12}  >
        //             <InputLabel htmlFor="input.common.currency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //               {t("settings.settings.settings.currency")}*
        //             </InputLabel>
        //             <Controller
        //               name="input.common.currency"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        //noOptionsText={t("noOptionsText")}
        //                   id="currency"
        //                   fullWidth
        //                   disableClearable
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   size="small"
        //                   options={Object.values(Currency).map((currency) => ({
        //                     name: localizedCurrencies[currency as keyof typeof Currency],
        //                     _id: currency,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedCurrencies[field.value as keyof typeof Currency], _id: field.value }
        //                       : undefined
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12}  >
        //             <InputLabel htmlFor="input.common.currency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //               {t("settings.settings.settings.taxRate")}
        //             </InputLabel>
        //             <Controller
        //               name="input.common.currency"
        //               control={commonForm.control}
        //               render={({ field }) => (
        //                 <Autocomplete
        //noOptionsText={t("noOptionsText")}
        //                   id="currency"
        //                   fullWidth
        //                   disableClearable
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   size="small"
        //                   options={Object.values(Currency).map((currency) => ({
        //                     name: localizedCurrencies[currency as keyof typeof Currency],
        //                     _id: currency,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={
        //                     field.value
        //                       ? { name: localizedCurrencies[field.value as keyof typeof Currency], _id: field.value }
        //                       : undefined
        //                   }
        //                   onChange={(e, value) => {
        //                     field.onChange(value?._id);
        //                   }}
        //                 />
        //               )}
        //             />
        //           </Grid>
        //           <Grid item xs={12} mt={2} sx={{
        //             p: 1,
        //             boxShadow: 'none',
        //             borderRadius: "16px",
        //             border: '1px solid',
        //             borderColor: 'primary.light',
        //           }}>
        //             <Grid container spacing={1}>
        //               <Grid item xs={12}>
        //                 <Box>
        //                   <Typography color={"text.primary"} fontSize={14} fontWeight={600}>{t("settings.settings.settings.exchangeRates")}</Typography>
        //                   <Divider />
        //                 </Box>
        //               </Grid>
        //               <Grid item xs={12}>
        //                 {
        //                   manualRates?.map((item: any, index: number) => {
        //                     return (
        //                       <Stack direction="row" spacing={1} px={1} key={index} alignItems={"center"}>
        //                         <Typography key={index}>
        //                           {item.fromCurrency} -&gt; {item.toCurrency} : {item.rate} | {moment(item.updatedAt).format('YYYY-MM-DD')}
        //                         </Typography>
        //                         <IconButton
        //                           size="small"
        //                           color="error"
        //                           onClick={() => {
        //                             const updatedManualRates = manualRates.filter((id: any) => id !== item);
        //                             setManualRates(updatedManualRates);
        //                           }}
        //                         >
        //                           <Close fontSize="small" />
        //                         </IconButton>
        //                       </Stack>
        //                     )
        //                   })
        //                 }
        //               </Grid>
        //               <Grid item xs={4}>
        //                 <InputLabel htmlFor="fromCurrency" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //                   {t("settings.settings.settings.fromCurrency")}
        //                 </InputLabel>
        //                 <Autocomplete
        //noOptionsText={t("noOptionsText")}
        //                   id="currency"
        //                   fullWidth
        //                   disableClearable
        //                   isOptionEqualToValue={(option, value) => option._id === value._id}
        //                   size="small"
        //                   options={Object.values(Currency).map((currency) => ({
        //                     name: localizedCurrencies[currency as keyof typeof Currency],
        //                     _id: currency,
        //                   }))}
        //                   getOptionLabel={(option) => option.name}
        //                   renderInput={(params) => <TextField {...params} />}
        //                   value={manualRate?.toCurrency ? { name: localizedCurrencies[manualRate?.toCurrency as keyof typeof Currency], _id: manualRate?.toCurrency } : undefined}
        //                   onChange={(e, value) => {
        //                     setManualRate(manualRate ? { ...manualRate, toCurrency: value?._id } : undefined);
        //                   }}
        //                 />
        //               </Grid>
        //               <Grid item xs={4}>
        //                 <InputLabel htmlFor="rate" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //                   {t("settings.settings.settings.rate")}
        //                 </InputLabel>
        //                 <TextField
        //                   id="rate"
        //                   fullWidth
        //                   type="number"
        //                   size="small"
        //                   variant="outlined"
        //                   value={manualRate?.rate !== undefined ? manualRate.rate : ''}
        //                   onChange={(e) => {
        //                     const value = e.target.value;
        //                     if (value === '') {
        //                       setManualRate(manualRate ? { ...manualRate, rate: parseFloat("0") } : undefined);
        //                     } else {
        //                       setManualRate(manualRate ? { ...manualRate, rate: parseFloat(value) } : undefined);
        //                     }
        //                   }}
        //                   inputProps={{
        //                     step: 0.01,
        //                   }}
        //                 />
        //               </Grid>
        //               <Grid item xs={6}>
        //                 <InputLabel htmlFor="updatedAt" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
        //                   {t("settings.settings.settings.updatedAt")}
        //                 </InputLabel>
        //                 <DatePicker
        //                   slotProps={{
        //                     textField: {
        //                       size: 'small',
        //                       fullWidth: true,
        //                     },
        //                   }}
        //                   value={manualRate?.updatedAt ? moment(manualRate?.updatedAt) : undefined}
        //                   onChange={(date) => {
        //                     setManualRate(manualRate ? { ...manualRate, updatedAt: date?.toDate?.() ?? new Date() } : undefined);
        //                   }}
        //                 />
        //               </Grid>
        //               <Grid item xs={6}>
        //                 <Box height={20} />
        //                 <OutlinedButton
        //                   leftIcon={<Add />}
        //                   color="tertiary"
        //                   fullWidth
        //                   title={"Add"}
        //                   onClick={() => {
        //                     if (manualRate) {
        //                       const isDuplicate = manualRates && manualRates?.some(
        //                         (rate) =>
        //                           rate.fromCurrency === manualRate.fromCurrency &&
        //                           rate.toCurrency === manualRate.toCurrency &&
        //                           rate.rate === manualRate.rate &&
        //                           rate.updatedAt === manualRate.updatedAt
        //                       );
        //                       if (!isDuplicate && manualRates) {
        //                         setManualRates([...manualRates, manualRate]);
        //                       }
        //                       if (!isDuplicate && !manualRates) {
        //                         setManualRates([manualRate]);
        //                       }

        //                     }
        //                   }}
        //                 />
        //               </Grid>
        //             </Grid>
        //           </Grid>
        //
        //         </Grid>
        //       </Card>
        //     </Grow>
        //   </Grid>
        // </Grid>
      }

      <UploadImageComponent
        currentImage={organizationDetailOwnData?.logo?.thumbnailPublicUrl}
        setLoading={setUploadLoading}
        useImageCreateMutation={useOrganizationLogoCreateMutation}
        useImageAssignMutation={useOrganizationLogoAssignMutation}
        open={openChangeImage}
        setOpen={setOpenChangeImage}
        title={t('settings.settings.settings.organizationLogo')}
      />
    </Stack>
  );
};

export default Organization;
