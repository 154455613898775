import { IEstimateListItemResult } from 'corede-common-cocrm';
import { Box, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { Iconify } from 'components';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { convertCurrency } from 'utils/convertCurrency';

interface IEstimateListItemProps {
  estimate: IEstimateListItemResult;
}

const EstimateListItemComponent = (props: IEstimateListItemProps) => {
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      gap={1.5}
      p={3}
      sx={{
        width: '100%',
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: '24px',
        boxSizing: 'border-box',
      }}
    >
      <Stack
        direction={downSm ? 'column' : 'row'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        <Stack direction={'row'} alignItems={'flex-start'} gap={1.5}>
          <Box
            sx={{
              width: 42,
              height: 42,
              bgcolor: 'secondary.extraLight',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify icon="mingcute:user-star-fill" width={24} sx={{ color: 'secondary.main' }} />
          </Box>

          <Box>
            <Typography variant={downSm ? 'body1' : 'h5'} sx={{ fontWeight: 500 }}>
              {props.estimate?.prospectContactFirstName} {props.estimate?.prospectContactLastName}
            </Typography>

            <Typography
              variant={downSm ? 'body2' : 'subtitle2'}
              sx={{ fontWeight: 500, color: 'text.secondary' }}
            >
              {props.estimate?.prospectTargetEntityRef
                ? getTranslatedEnumValue(props.estimate?.prospectTargetEntityRef)
                : ''}
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor: 'success.extraLight',
              borderRadius: '48px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 12px',
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 500, color: 'success.main' }}>
              {props.estimate?.prospectType
                ? getTranslatedEnumValue(props.estimate?.prospectType)
                : ''}
            </Typography>
          </Box>
        </Stack>

        <Stack alignItems={downSm ? 'flex-end' : 'flex-start'}>
          <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
            <strong style={{ color: 'text.primary' }}>{t('crm.date')}: </strong>{' '}
            {props.estimate?.date ? moment(props.estimate?.date).format() : '-'}
          </Typography>

          <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.secondary' }}>
            <strong style={{ color: 'text.primary' }}>{t('crm.expireDate')}: </strong>{' '}
            {props.estimate?.expireDate ? moment(props.estimate?.expireDate).format() : '-'}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction={downSm ? 'column' : 'row'}
        justifyContent={'space-between'}
        sx={{ width: '100%' }}
      >
        <Stack
          direction={'row'}
          alignItems={'flex-start'}
          px={2}
          py={1}
          gap={1.5}
          sx={{ backgroundColor: downSm ? 'transparent' : '#23202005', borderRadius: '6px' }}
        >
          <Box>
            <Typography variant={downSm ? 'body1' : 'h5'} sx={{ fontWeight: 500 }}>
              {props.estimate?.estimateId}
            </Typography>

            <Typography
              variant={downSm ? 'body2' : 'subtitle2'}
              sx={{ fontWeight: 500, color: 'text.secondary' }}
            >
              {t('crm.estimateLabel') + ' ID'}
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor: 'tertiary.extraLight',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px 12px',
            }}
          >
            <Typography variant="caption" sx={{ fontWeight: 500, color: 'tertiary.main' }}>
              {props.estimate?.status ? getTranslatedEnumValue(props.estimate?.status) : ''}
            </Typography>
          </Box>
        </Stack>

        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          gap={5}
          sx={{
            bgcolor: 'secondary.extraLight',
            borderRadius: '60px',
            padding: '8px 24px',
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ color: 'secondary.main' }}>
              {convertCurrency(props.estimate?.currency)}
              {props.estimate?.totalPrice?.total
                ? props.estimate?.totalPrice?.total?.toFixed(1)
                : '-'}{' '}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('crm.finalPrice')}
            </Typography>
          </Box>

          <Box
            sx={{
              backgroundColor: 'secondary.main',
              borderRadius: '50%',
              width: 46,
              height: 46,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Iconify
              icon="solar:tag-price-broken"
              width={24}
              sx={{ color: 'secondary.contrastText' }}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EstimateListItemComponent;
