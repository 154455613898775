/* eslint-disable react-hooks/exhaustive-deps */
import { IProjectDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { ProjectDetailDrawerRightPanelHeaderComponent } from './ProjectDetailDrawer-rightPanel-header.component';
import { ProjectDetailDrawerRightPanelTabsComponent } from './ProjectDetailDrawer-rightPanel.tabs.component';

export interface IProjectDetailDrawerRightPanelComponentProps {
  projectDetailData: IProjectDetailResult | undefined;
}

export const ProjectDetailDrawerRightPanelComponent = (
  props: IProjectDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <ProjectDetailDrawerRightPanelHeaderComponent projectDetailData={props.projectDetailData} />

      {/* Tabs Section */}
      <ProjectDetailDrawerRightPanelTabsComponent projectDetailData={props.projectDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
