import { useState, lazy, Suspense } from "react";
import { IContractTemplateDetailDrawerProps } from "./ContractTemplateDetailDrawer";

const ContractTemplateDetailDrawer = lazy(() => import("./ContractTemplateDetailDrawer"));

const ContractTemplateDetailDrawerWrapper = ({
  open,
  setOpen,
  contractTemplate: selectedContractTemplate,
  sideNavigationProps,
}: IContractTemplateDetailDrawerProps) => {
  const [mounted, setMounted] = useState(false);

  if (open && !mounted) {
    setMounted(true);
  }

  return (
    <>
      {mounted && (
        <Suspense>
          <ContractTemplateDetailDrawer
            open={open}
            setOpen={setOpen}
            contractTemplate={selectedContractTemplate}
            sideNavigationProps={sideNavigationProps}
          />
        </Suspense>
      )}
    </>
  );
};

export default ContractTemplateDetailDrawerWrapper;
