import { IMenuItem } from "interfaces/menu-item.interface";
import { useTranslation } from "react-i18next"; // useTranslation hook'unu import ediyoruz
import {
  baseCalendarRoute,
  listAppointmentsRoute,
  listRemindersRoute,
} from "../routes/calendar.base.route";
import { PermissionSubdomain } from "corede-common-cocrm";

export const useCalendarDomainMenuItem = (): IMenuItem => {
  const { t } = useTranslation(); // useTranslation hook'unu kullanıyoruz

  return {
    title: t("calendar"), // getTranslatedText yerine t fonksiyonunu kullanıyoruz
    path: baseCalendarRoute(),
    children: [
      {
        title: t("reminders"),
        path: listRemindersRoute(),
        subdomain: PermissionSubdomain.reminder // TODO: add subdomain
      },
      {
        title: t("appointments"),
        path: listAppointmentsRoute(),
        subdomain: PermissionSubdomain.appointment // TODO: add subdomain
      },
    ],
  };
};
