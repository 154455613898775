import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
// import { useAppointmentListCountQuery } from '../../context/appointment.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import AppointmentListComponent from './AppointmentList-grid.component';
import { useLocation } from 'react-router-dom';
import { AppointmentListStatisticsView } from './AppointmentList-statistics.component';
import AppointmentCreateDrawerWrapper from '../create/AppointmentCreateDrawer.wrapper';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { useAppointmentListQuery } from '../../context/appointment.api';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const AppointmentList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openAppointmentCreateDrawer, setOpenAppointmentCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();

  // queries
  // const { data: appointmentListCountData, isLoading: appointmentListCountLoading } = useAppointmentListCountQuery({});
  const { data: appointmentListCountData, isLoading: appointmentListCountLoading } =
    useAppointmentListQuery({});

  let totalAppointmentCount = appointmentListCountData?.count ?? 0;
  let isEmpty = totalAppointmentCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenAppointmentCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {t(`crm.calendar.appointment.title`)}
            </Typography>
          </Stack>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.calendar.appointment.create`),
          onClick: () => setOpenAppointmentCreateDrawer(true),
          permission: {
            subject: PermissionSubject.appointment,
            action: PermissionAction.create,
          },
        }}
      />

      <AppointmentListStatisticsView
        count={totalAppointmentCount}
        appointmentListCountLoading={appointmentListCountLoading}
      />

      <AppointmentListComponent setOpenAppointmentCreateDrawer={setOpenAppointmentCreateDrawer} />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.appointment,
          action: PermissionAction.create,
        }}
      >
        <AppointmentCreateDrawerWrapper
          open={openAppointmentCreateDrawer}
          setOpen={setOpenAppointmentCreateDrawer}
        />
      </PermissionWrapper>
    </Grid>
  );
});

export default AppointmentList;
