import { Stack, useMediaQuery } from '@mui/material';
import CustomAreaChart from '../../components/CustomAreaChart';
import CustomBarChartV2 from '../../components/CustomBarChartV2';
import CustomBarChartV3 from '../../components/CustomBarChartV3';
import CustomDonutChart from '../../components/CustomDonutChart';
import CustomHalfPieChart from '../../components/CustomHalfPieChart';
import { useTranslation } from 'react-i18next';
import { useProposalStatisticsQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { ProposalProspectTargetEntityRef, ProposalStatus, ProposalType } from 'corede-common-cocrm';
import { getCurrentLanguage, getTranslatedEnumValue } from 'localization';
import { memo, useCallback } from 'react';
import { convertCurrency } from 'utils/convertCurrency';
import { useOrganizationSettingsDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';

const ProposalStatistics = memo((props: {}) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const { data: organizationSettingsDetailData } = useOrganizationSettingsDetailOwnQuery({});

  const { data: useProposalStatisticsData } = useProposalStatisticsQuery({
    input: {
      filter: {
        numberOfPastDays: 7,
      },
    },
  });

  const dailyCreatedCounts = useProposalStatisticsData?.dailyHistory?.dailyCreatedCounts ?? [];
  const totalDailyCreatedCounts = dailyCreatedCounts.reduce((a, b) => a + b, 0);
  const dailyTotalCounts = useProposalStatisticsData?.dailyHistory?.dailyTotalCounts ?? [];

  const proposalTotalPrice = useProposalStatisticsData?.sumOfAllTotalPricesOfAllProposals ?? 0;

  const countByProposalStatus = useProposalStatisticsData?.countByProposalStatus;
  const totalCountByProposalStatus = Object.values(
    useProposalStatisticsData?.countByProposalStatus ?? {},
  ).reduce((sum, count) => sum + count, 0);

  const countByProposalType = useProposalStatisticsData?.countByProposalType;
  const totalCountByProposalType = Object.values(
    useProposalStatisticsData?.countByProposalType ?? {},
  ).reduce((sum, count) => sum + count, 0);

  const countByProposalTargetType = useProposalStatisticsData?.countByProposalTargetType;
  const totalCountByProposalTargetType = Object.values(
    useProposalStatisticsData?.countByProposalTargetType ?? {},
  ).reduce((sum, count) => sum + count, 0);

  const getAreaChartData = useCallback(() => {
    return (
      dailyTotalCounts
        ?.map((item, index) => ({
          name: index.toString(),
          uv: item ?? 0,
          pv: 3000,
          amt: 3000,
        }))
        .reverse() ?? []
    );
  }, [dailyTotalCounts]);

  const getBottomToolbarData = useCallback(() => {
    return [
      {
        name: t('crm.dashboard.dashboard.today'),
        valueFormat: {
          count1: dailyTotalCounts?.[0] ?? 0,
          count2: dailyCreatedCounts?.[0] ?? undefined,
          prefix: dailyCreatedCounts?.[0] ? '+' : '',
        },
      },
      {
        name: t('crm.dashboard.dashboard.yesterday'),
        valueFormat: {
          count1: dailyTotalCounts?.[1] ?? 0,
          count2: dailyCreatedCounts?.[1] ?? undefined,
          prefix: dailyCreatedCounts?.[1] ? '+' : '',
        },
      },
    ];
  }, [dailyTotalCounts, dailyCreatedCounts, t]);

  return (
    <>
      <Stack direction={downMd ? 'column' : 'row'} gap={2} mb={1} width={'100%'}>
        <CustomHalfPieChart
          title={t('crm.dashboard.dashboard.totalProposal')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          description={t('crm.dashboard.dashboard.totalProposalSubtitle')}
          theme="tertiary"
          flexBasis={'33%'}
          data={[
            {
              name: 'A',
              value1: proposalTotalPrice,
              totalValue: proposalTotalPrice * 1000,
              value2Format: undefined,
              currency: convertCurrency(organizationSettingsDetailData?.common.currency),
            },
          ]}
        />
        <CustomAreaChart
          title={t('crm.dashboard.dashboard.totalProposal')}
          subtitle={t('crm.dashboard.dashboard.totalProposalSubtitle')}
          theme="warning"
          flexBasis={'67%'}
          icon="mdi:file-document-outline"
          count={dailyTotalCounts?.[0] ?? 0}
          data={getAreaChartData()}
          topRightCount={
            totalDailyCreatedCounts > 0
              ? `${(((totalDailyCreatedCounts ?? 0) / dailyTotalCounts[0]) * 100).toFixed(1)}%`
              : '0%'
          }
          bottomToolbarData={getBottomToolbarData()}
        />
      </Stack>
      <Stack direction={downMd ? 'column' : 'row'} gap={2} mb={1} width={'100%'}>
        <CustomBarChartV2
          title={t('crm.dashboard.dashboard.proposalsByTypes')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          language={getCurrentLanguage()}
          theme="secondary"
          flexBasis={'33%'}
          data={[
            {
              name: getTranslatedEnumValue(ProposalType.individual),
              value1: countByProposalType?.countByIndividual ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByProposalType?.countByIndividual ?? 0) / totalCountByProposalType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalType.business),
              value1: countByProposalType?.countByBusiness ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByProposalType?.countByBusiness ?? 0) / totalCountByProposalType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalType.government),
              value1: countByProposalType?.countByGovernment ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    ((countByProposalType?.countByGovernment ?? 0) / totalCountByProposalType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
          //   bottomToolbarData={[
          //     {
          //       label: 'Lorem ipsum',
          //       valueFormat: {
          //         count: 545.69,
          //         prefix: '$',
          //       },
          //     },
          //     {
          //       label: 'Lorem ipsum',
          //       valueFormat: {
          //         count: 545.69,
          //         prefix: '$',
          //       },
          //     },
          //     {
          //       label: 'Lorem ipsum',
          //       valueFormat: {
          //         count: 545.69,
          //         prefix: '$',
          //       },
          //     },
          //   ]}
        />
        <CustomBarChartV3
          title={t('crm.dashboard.dashboard.proposalsByTarget')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          theme="secondary"
          flexBasis={'33%'}
          language={getCurrentLanguage()}
          data={[
            {
              name: getTranslatedEnumValue(ProposalProspectTargetEntityRef.Lead),
              value1Format: {
                count: countByProposalTargetType?.countByLead ?? 0,
                prefix: '',
              },
              value2Format: {
                count: parseFloat(
                  (
                    ((countByProposalTargetType?.countByLead ?? 0) /
                      totalCountByProposalTargetType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalProspectTargetEntityRef.Customer),
              value1Format: {
                count: countByProposalTargetType?.countByCustomer ?? 0,
                prefix: '',
              },
              value2Format: {
                count: parseFloat(
                  (
                    ((countByProposalTargetType?.countByCustomer ?? 0) /
                      totalCountByProposalTargetType) *
                    100
                  ).toFixed(2),
                ),
                prefix: '',
                suffix: '%',
              },
            },
          ]}
        />

        <CustomDonutChart
          title={t('crm.dashboard.dashboard.proposalsByStatus')}
          subtitle={t('crm.dashboard.dashboard.weeklyReport')}
          colors={['#24B364', '#53D28C', '#7EDDA9', '#A9E9C5', '#D4F5E2', '#24B364', '#A9E9C5']}
          theme="success"
          flexBasis={'34%'}
          totalValue={totalCountByProposalStatus}
          language={getCurrentLanguage()}
          data={[
            {
              name: getTranslatedEnumValue(ProposalStatus.draft),
              value1: countByProposalStatus?.countByDraft ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    (countByProposalStatus?.countByDraft ?? 0 / (totalCountByProposalStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalStatus.sent),
              value1: countByProposalStatus?.countBySent ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    (countByProposalStatus?.countBySent ?? 0 / (totalCountByProposalStatus ?? 1)) *
                    100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalStatus.accepted),
              value1: countByProposalStatus?.countByAccepted ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    (countByProposalStatus?.countByAccepted ??
                      0 / (totalCountByProposalStatus ?? 1)) * 100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalStatus.declined),
              value1: countByProposalStatus?.countByDeclined ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    (countByProposalStatus?.countByDeclined ??
                      0 / (totalCountByProposalStatus ?? 1)) * 100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalStatus.expired),
              value1: countByProposalStatus?.countByExpired ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    (countByProposalStatus?.countByExpired ??
                      0 / (totalCountByProposalStatus ?? 1)) * 100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalStatus.opened),
              value1: countByProposalStatus?.countByOpened ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    (countByProposalStatus?.countByOpened ??
                      0 / (totalCountByProposalStatus ?? 1)) * 100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
            {
              name: getTranslatedEnumValue(ProposalStatus.revised),
              value1: countByProposalStatus?.countByRevised ?? 0,
              value2Format: {
                count: parseFloat(
                  (
                    (countByProposalStatus?.countByRevised ??
                      0 / (totalCountByProposalStatus ?? 1)) * 100
                  ).toFixed(2),
                ),
                prefix: '+',
                suffix: '%',
              },
            },
          ]}
        />
      </Stack>
    </>
  );
});

export default ProposalStatistics;
