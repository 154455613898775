import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { IBaseGridColumnParams } from '../infra/IBaseGridColumnParams';
import { IUserProfile } from 'corede-common';
import { TFunction } from 'i18next';
import {
  EntityProfileColumnCellComponent,
  IEntityProfile,
  UserProfileColumnCellComponent,
} from 'apps/crm/components/profile/profile-column-cell.component';

export class BaseGridColumnDefFactory {
  static CreatedByUserProfileColumnDef(params: {
    t: TFunction;
  }): IBaseGridColumnParams<IUserProfile> {
    return {
      config: {
        baseGridColType: BaseGridColType.any,
        column: {
          field: 'createdBy',
          width: 180,
          sortable: false,
          editable: false,
          filterable: false,
        },
        headerConfig: {
          name: `${params.t('crm.createdBy')}`,
        },
        overrideCell: UserProfileColumnCellComponent(),
      },
    };
  }

  static CustomUserProfileColumnDef(params: {
    headerName: string;
    field: string;
  }): IBaseGridColumnParams<IUserProfile> {
    return {
      config: {
        baseGridColType: BaseGridColType.any,
        column: {
          field: params.field,
          width: 180,
          sortable: false,
          editable: false,
          filterable: false,
        },
        headerConfig: {
          name: params.headerName,
        },
        overrideCell: UserProfileColumnCellComponent(),
      },
    };
  }

  static EntityProfileColumnDef(params: {
    field: string;
    headerName: string;
    headerIcon?: string,
  }): IBaseGridColumnParams<IEntityProfile> {
    return {
      config: {
        baseGridColType: BaseGridColType.any,
        column: {
          field: params.field,
          width: 180,
          sortable: false,
          editable: false,
          filterable: false,
        },
        headerConfig: {
          icon: params.headerIcon,
          name: params.headerName,
        },
        overrideCell: EntityProfileColumnCellComponent(),
      },
    };
  }
}
