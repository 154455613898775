import { TFunction } from 'i18next';
import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export function representString(data?: string, defaultValue: string = '-'): string {
  return data ?? defaultValue;
}

/**
 *
 * @param params.fieldName the name of the field that will be used to represent the object. The value of that field will be shown. Note that value of this field must be string representable. `defaultValue` : name
 * @param params.defaultValue if there is no data, this value will be shown. `defaultValue` : -
 * @returns
 */
export function representArrayOfObjectAsString(params: {
  dataList?: Object[];
  fieldName?: string;
  defaultValue?: string;
}): string {
  const fieldName = params.fieldName ?? 'name';
  const defaultValue = params.defaultValue ?? '-';
  return params.dataList && params.dataList?.length > 0
    ? params.dataList.map((data: any) => data[fieldName]).join(', ')
    : defaultValue;
}

// TODO: Date format locale'e gore degistirilmesi lazim @madraselcuk
export function representDateAsString(date?: Date, defaultValue: string = '-'): string {
  return date ? `${moment(date).format(DEFAULT_DATE_FORMAT)}` : defaultValue;
}

export function representDateTimeAsString(date?: Date, defaultValue: string = '-'): string {
  return date ? `${moment(date).format(DEFAULT_DATE_TIME_FORMAT)}` : defaultValue;
}

// TODO: Date format locale'e gore degistirilmesi lazim @madraselcuk
export function representStringDateAsString(date?: string, defaultValue: string = '-'): string {
  return date ? `${moment(new Date(date)).format(DEFAULT_DATE_FORMAT)}` : defaultValue;
}

export function representHtmlAsString(params: {
  data?: string;
  defaultValue?: string;
  trimLength?: number;
}): string {
  const defaultValue = '-';

  const content = params.data ? params.data.replace(/(<([^>]+)>)/gi, '') : defaultValue;
  return representTextByTrimming({ text: content, trimLength: params.trimLength });
}

export function representBooleanAsString(params: {
  data?: boolean;
  trueValue?: string;
  falseValue?: string;
  defaultValue?: string;
  t?: TFunction<'translation', undefined>;
}): string {
  const defaultValue = params.defaultValue ?? '';
  switch (params.data) {
    case true:
      return params.trueValue ?? (params.t ? params.t('yes') : '+');
    case false:
      return params.falseValue ?? (params.t ? params.t('no') : '-');
    default:
      return defaultValue;
  }
}

export function representTextByTrimming(params: { text: string; trimLength?: number }): string {
  if (!params.trimLength || params.trimLength <= 0 || params.text.length <= params.trimLength) {
    return params.text;
  }

  const trimmedContent = params.text.slice(0, params.trimLength).concat('...');

  return trimmedContent;
}
