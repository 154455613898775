/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Card, Grid, Grow, Stack, Typography } from '@mui/material';
import { MyTab, SettingsTitle } from 'components';
import { unknownError } from 'corede-common';
import { useEffect, useState } from 'react';
import { useOrganizationDetailOwnQuery } from '../context/setting.api';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyState from 'components/emptyState/EmptyState';

const User = () => {
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [tabLabels, setTabLabels] = useState(['']);
  const [selectedTabName, setSelectedTabName] = useState('');

  const {
    data: organizationDetailOwnData,
    isLoading: organizationDetailOwnLoading,
    error: organizationDetailOwnError,
  } = useOrganizationDetailOwnQuery({});

  const status = organizationDetailOwnData?.status;

  //Common

  useEffect(() => {
    switch (status) {
      case 'restricted':
        setTabLabels([
          'settings.settings.settings.userList',
          'settings.settings.settings.permissions',
        ]);
        break;
      case 'active':
        setTabLabels([
          'settings.settings.settings.userList',
          'settings.settings.settings.permissions',
        ]);
        break;
      default: 
        break;
    } 
  }, [status]);

  useEffect(() => {
    if (tabLabels) {
      switch (tab) {
        case 'userList':
          setSelectedTabName('settings.settings.settings.userList');
          break;
        default:
          setSelectedTabName(tabLabels[0]);
          break;
      }
    }
  }, [tabLabels, tab]);

  useEffect(() => {
    if (organizationDetailOwnError) {
      enqueueSnackbar(
        (organizationDetailOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationDetailOwnError]);

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={3}>
      <Stack
        direction={'row'}
        sx={{
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        <MyTab
          type="settings"
          labels={tabLabels}
          setSelectedTab={setSelectedTabName}
          selectedTab={selectedTabName}
        />
      </Stack>
      {selectedTabName === 'settings.settings.settings.userList' && (
        <UserListTab selectedTabName={selectedTabName} />
      )}
      {selectedTabName === 'settings.settings.settings.permissions' && (
        <PermissionsTab selectedTabName={selectedTabName} />
      )}
    </Stack>
  );
};

const UserListTab = (props: { selectedTabName: string }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t('settings.settings.settings.userList')} />
      <Grid item xs={12}>
        <Grow in={props.selectedTabName === 'settings.settings.settings.userList'} timeout={500}>
          <Card
            sx={{
              p: 2,
              border: '1px solid',
              bgcolor: 'background.thirdWithBlur',
              borderColor: 'primary.lighter',
            }}
          >
            <Grid container>
              <Grid item xs={12} mb={2}>
                <EmptyState
                  content1={t('settings.settings.settings.noUserListContent1')}
                  content2={t('settings.settings.settings.noUserListContent2')}
                />
              </Grid>
            </Grid>
          </Card>
        </Grow>
      </Grid>
    </Grid>
  );
};

const PermissionsTab = (props: { selectedTabName: string }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} px={2}>
      <SettingsTitle selectedTabName={t('settings.settings.settings.permissions')} />
      <Grid item xs={12}>
        <Grow in={props.selectedTabName === 'settings.settings.settings.permissions'} timeout={500}>
          <Card
            sx={{
              p: 2,
              border: '1px solid',
              bgcolor: 'background.thirdWithBlur',
              borderColor: 'primary.lighter',
            }}
          >
            <Grid container>
              <Grid item xs={12} mb={2}>
                <EmptyState
                  content1={t('settings.settings.settings.noPermissionsContent1')}
                  content2={t('settings.settings.settings.noPermissionsContent2')}
                />
              </Grid>
            </Grid>
          </Card>
        </Grow>
      </Grid>
    </Grid>
  );
};

export default User;
