import {
  ticketListQuery,
  ITicketListRequest,
  ITicketListInput,
  ITicketListResponse,
  ITicketListResult,
  ticketListCountQuery,
  ITicketListCountRequest,
  ITicketListCountResponse,
  ticketDetailQuery,
  ITicketDetailRequest,
  ITicketDetailInput,
  ITicketDetailResponse,
  ITicketDetailResult,
  ticketStatisticsQuery,
  ITicketStatisticsRequest,
  ITicketStatisticsResponse,
  ITicketStatisticsResult,
  ticketCreateQuery,
  ITicketCreateRequest,
  ITicketCreateInput,
  ITicketCreateResponse,
  ITicketCreateResult,
  ticketDeleteQuery,
  ITicketDeleteRequest,
  ITicketDeleteInput,
  ITicketDeleteResponse,
  ITicketDeleteResult,
  ticketUpdateQuery,
  ITicketUpdateRequest,
  ITicketUpdateInput,
  ITicketUpdateFilterInput,
  ITicketUpdateResponse,
  ITicketUpdateResult,
  ticketStatusUpdateQuery,
  ITicketStatusUpdateRequest,
  ITicketStatusUpdateInput,
  ITicketStatusUpdateFilterInput,
  ITicketStatusUpdateResponse,
  ITicketStatusUpdateResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { supportTags } from './support.tags';
import { ICount, IGraphqlVariables } from 'corede-common';

export const supportApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    ticketList: builder.query<ITicketListResult, IGraphqlVariables<ITicketListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketListRequest,
        ITicketListResponse,
        ITicketListResult,
        ITicketListInput
      >({
        query: ticketListQuery,
        providesTags: [supportTags.ticketList, supportTags.ticketDetail],
      }),
    ),

    ticketListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketListCountRequest,
        ITicketListCountResponse,
        ICount,
        undefined
      >({
        query: ticketListCountQuery,
        providesTags: [supportTags.ticketList],
      }),
    ),

    ticketDetail: builder.query<ITicketDetailResult, IGraphqlVariables<ITicketDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketDetailRequest,
        ITicketDetailResponse,
        ITicketDetailResult,
        ITicketDetailInput
      >({
        query: ticketDetailQuery,
        providesTags: [supportTags.ticketDetail],
      }),
    ),

    ticketStatistics: builder.query<ITicketStatisticsResult, IGraphqlVariables<undefined>>({
      query: EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketStatisticsRequest,
        ITicketStatisticsResponse,
        ITicketStatisticsResult,
        undefined
      >({
        query: ticketStatisticsQuery,
        providesTags: [supportTags.ticketList],
      }),
    }),

    // mutation

    ticketCreate: builder.mutation<ITicketCreateResult, IGraphqlVariables<ITicketCreateInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketCreateRequest,
        ITicketCreateResponse,
        ITicketCreateResult,
        ITicketCreateInput
      >({
        query: ticketCreateQuery,
        invalidatesTags: [supportTags.ticketList, supportTags.ticketDetail],
      }),
    ),

    ticketDelete: builder.mutation<ITicketDeleteResult, IGraphqlVariables<ITicketDeleteInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketDeleteRequest,
        ITicketDeleteResponse,
        ITicketDeleteResult,
        ITicketDeleteInput
      >({
        query: ticketDeleteQuery,
        invalidatesTags: [supportTags.ticketList],
      }),
    ),

    ticketUpdate: builder.mutation<
      ITicketUpdateResult,
      IGraphqlVariables<ITicketUpdateInput, ITicketUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketUpdateRequest,
        ITicketUpdateResponse,
        ITicketUpdateResult,
        ITicketUpdateInput,
        ITicketUpdateFilterInput
      >({
        query: ticketUpdateQuery,
        invalidatesTags: [supportTags.ticketList, supportTags.ticketDetail],
      }),
    ),

    ticketStatusUpdate: builder.mutation<
      ITicketStatusUpdateResult,
      IGraphqlVariables<ITicketStatusUpdateInput, ITicketStatusUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ITicketStatusUpdateRequest,
        ITicketStatusUpdateResponse,
        ITicketStatusUpdateResult,
        ITicketStatusUpdateInput,
        ITicketStatusUpdateFilterInput
      >({
        query: ticketStatusUpdateQuery,
        invalidatesTags: [supportTags.ticketList, supportTags.ticketDetail],
      }),
    ),
  }),
});

export const {
  useTicketListQuery,
  useLazyTicketListQuery,
  useTicketListCountQuery,
  useLazyTicketListCountQuery,
  useTicketDetailQuery,
  useLazyTicketDetailQuery,
  useTicketStatisticsQuery,
  useLazyTicketStatisticsQuery,

  useTicketCreateMutation,
  useTicketDeleteMutation,
  useTicketUpdateMutation,
  useTicketStatusUpdateMutation,
} = supportApi;
