import { Stack, Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { memo, useCallback } from 'react';

interface ICustomCompareChartItem {
  title: string;
  count1: {
    value: number;
    prefix?: string;
    suffix?: string;
  };
  count2: {
    value: number;
    prefix?: string;
    suffix?: string;
  };
}

interface ICustomCompareChart {
  data: ICustomCompareChartItem[];
  title: string;
  count?: {
    value: number;
    prefix?: string;
    suffix?: string;
  };
  totalValue: {
    value: number;
    prefix?: string;
    suffix?: string;
  };
  flexBasis: string;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.tertiary.main,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.info.main,
  },
}));

const CustomCompareChart = memo((props: ICustomCompareChart) => {
  const calculateProgress = useCallback(() => {
    return (props.data[0].count1.value / (props.data[0].count1.value + props.data[1].count1.value)) * 100;
  }, [props.data]);

  return (
    <Stack
      direction={'column'}
      gap={1}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={props.flexBasis}
      width={'100%'}
    >
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Box>
          <Typography
            variant="caption"
            sx={{ fontWeight: 500, color: 'text.secondary', textTransform: 'uppercase' }}
          >
            {props.title}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: 500 }}>
            {props.count?.prefix}
            {props.count?.value}
            {props.count?.suffix}
          </Typography>
        </Box>

        <Typography
          variant="caption"
          sx={{
            fontWeight: 500,
            color: props.count?.prefix === '-' ? 'error.main' : 'success.main',
            textTransform: 'uppercase',
          }}
        >
          {props.count?.prefix}
          {props.count?.value}
          {props.count?.suffix}
        </Typography>
      </Stack>

      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={1}
        sx={{ mt: 'auto' }}
      >
        <Stack gap={2}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {props.data[0].title}
          </Typography>

          <Box>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {props.data[0].count1.prefix}
              {props.data[0].count1.value}
              {props.data[0].count1.suffix}
            </Typography>

            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {props.data[0].count2.prefix}
              {props.data[0].count2.value}
              {props.data[0].count2.suffix}
            </Typography>
          </Box>
        </Stack>

        <Box
          sx={{ position: 'relative', height: '100%', border: '1px solid', borderColor: 'divider' }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: 24,
              height: 24,
              transform: 'translate(-50%, -50%)',
              borderRadius: '50%',
              bgcolor: 'divider',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="caption" sx={{ color: 'text.secondary', lineHeight: 1 }}>
              VS
            </Typography>
          </Box>
        </Box>

        <Stack gap={2} sx={{ textAlign: 'right' }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {props.data[1].title}
          </Typography>

          <Box>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>
              {props.data[1].count1.prefix}
              {props.data[1].count1.value}
              {props.data[1].count1.suffix}
            </Typography>

            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {props.data[1].count2.prefix}
              {props.data[1].count2.value}
              {props.data[1].count2.suffix}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <BorderLinearProgress
        variant="determinate"
        value={calculateProgress()}
      />
    </Stack>
  );
});

export default CustomCompareChart;
