/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { IAppointmentDetailResult } from 'corede-common-cocrm';
import { AppointmentDetailDrawerLeftPanelActionsComponent } from './AppointmentDetailDrawer-leftPanel-header-actions.component';

export interface IAppointmentDetailDrawerLeftPanelHeaderComponentProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const AppointmentDetailDrawerLeftPanelHeaderComponent = (
  props: IAppointmentDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* Appointment Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.appointmentDetailData?.title ?? ''}
      </Typography>

      <AppointmentDetailDrawerLeftPanelActionsComponent
        appointmentDetailData={props.appointmentDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
