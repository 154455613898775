/* eslint-disable react-hooks/exhaustive-deps */
import { Add } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IBaseError,
  IGraphqlVariables,
  isNullOrUndefinedOrEmptyObject,
  PermissionAction,
} from 'corede-common';
import {
  IBaseProductCategoryEntity,
  IProductCategory,
  IProductCategoryListInput,
  PermissionSubject,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import {
  useProductCategoryListQuery,
  useProductCategoryUpdateMutation,
} from '../../context/productCategory.api';
import { DeleteProductCategoryDialog } from '../delete/DeleteProductCategoryDialog';

import {
  DefaultListGridComponent,
  TListGridSelectedEntity,
} from 'apps/crm/components/list/DefaultList-grid.component';
import { IPendingNavigation } from 'apps/crm/util/handleSideNavigationNavigate.action';
import ProductCategoryUpdateWraper from '../update/ProductCategoryUpdateWraper';
import { usePermissions } from 'permission/PermissionContext';

export interface IProductCategoryListGridComponent {
  // productCategory

  setOpenProductCategoryCreateDrawer?: (value: boolean) => void | undefined;
}

const ProductCategoryListGridComponent = memo((props: IProductCategoryListGridComponent) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // states
  const [selectedProductCategory, setSelectedProductCategory] =
    useState<TListGridSelectedEntity<IProductCategory>>();
  const [openProductCategoryDetailDrawer, setOpenProductCategoryDetailDrawer] = useState(false);
  const [openProductCategoryDeleteDialog, setOpenProductCategoryDeleteDialog] = useState(false);
  const [openProductCategoryUpdateDrawer, setOpenProductCategoryUpdateDrawer] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<IPendingNavigation | null>(null);

  const { hasPermission } = usePermissions();
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.productCategory,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.productCategory,
    action: PermissionAction.delete,
  });

  const [productCategoryListFilter, setProductCategoryListFilter] = useState<
    IGraphqlVariables<IProductCategoryListInput>
  >({
    input: {
      filter: {
        businessTypes: undefined,
        isJunk: undefined,
      },
      pagination: { page: 1, pageSize: 10 },
    },
  });

  const currentPage = productCategoryListFilter?.input?.pagination?.page ?? 1;
  const currentPageSize = productCategoryListFilter?.input?.pagination?.pageSize ?? 10;

  // queries
  const {
    data: productCategoryListData,
    isLoading: productCategoryListLoading,
    isFetching: productCategoryListFetching,
    error: productCategoryListError,
    refetch,
  } = useProductCategoryListQuery(productCategoryListFilter);

  const [
    productCategoryUpdate,
    { isLoading: productCategoryUpdateLoading, error: productCategoryUpdateError },
  ] = useProductCategoryUpdateMutation();

  // effects

  useEffect(() => {
    refetch();
  }, [productCategoryListFilter]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(productCategoryListError as IBaseError, currentLanguage);
  }, [productCategoryListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(productCategoryUpdateError as IBaseError, currentLanguage);
  }, [productCategoryUpdateError]);

  const isFilterEmpty = isNullOrUndefinedOrEmptyObject(productCategoryListFilter?.input?.filter);
  const isJunkUndefined = (productCategoryListFilter as any)?.filter?.isJunk === undefined;
  const isBusinessTypesUndefined =
    productCategoryListFilter?.input?.filter?.businessTypes === undefined;
  const isEmptyState =
    productCategoryListData?.data?.length === 0 &&
    !productCategoryListLoading &&
    !productCategoryListFetching &&
    (isFilterEmpty || (isJunkUndefined && isBusinessTypesUndefined));

  // custom views

  return (
    <DefaultListGridComponent
      selectedEntity={selectedProductCategory}
      setSelectedEntity={setSelectedProductCategory}
      listFilter={productCategoryListFilter}
      setListFilter={setProductCategoryListFilter}
      listData={productCategoryListData}
      pendingNavigation={pendingNavigation}
      setPendingNavigation={setPendingNavigation}
      listFetching
      listLoading
    >
      <Card sx={{ bgcolor: 'background.secondWithBlur', p: '0px', height: '100%' }}>
        {productCategoryListData ? (
          !isEmptyState ? (
            <BaseDataGrid
              rows={
                productCategoryListData?.data?.map((productCategory, index) => ({
                  id:
                    ((productCategoryListFilter.input?.pagination?.page || 1) - 1) *
                      (productCategoryListFilter.input?.pagination?.pageSize || 0) +
                    index +
                    1,
                  _id: productCategory._id,
                  nameTranslation: productCategory.nameTranslation,
                  name: productCategory.name || '-',
                  icon: productCategory.icon || '-',
                })) || []
              }
              columns={[
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'id',
                      disableColumnMenu: true,
                      filterable: false,
                      width: 40,
                      align: 'center',
                    },
                    headerConfig: {
                      name: '#',
                      align: 'center',
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.text,
                    column: {
                      field: 'name',
                      filterable: true,
                      editable: hasUpdatePermission,
                    },
                    headerConfig: {
                      icon: 'mdi:label-outline',
                      name: t('crm.name'),
                    },
                  },
                },
                {
                  config: {
                    baseGridColType: BaseGridColType.icon,
                    column: {
                      field: 'icon',
                      editable: false,
                      filterable: false,
                    },
                    headerConfig: {
                      icon: 'mdi:star-outline',
                      name: t('crm.icon'),
                    },
                  },
                },
              ]}
              actionColumn={{
                width: 80,
                defaultActions: {
                  // view: {
                  //   clickConfig: {
                  //     setSelectedRow: setSelectedProductCategory,
                  //     setOpenAction: setOpenProductCategoryDetailDrawer,
                  //   },
                  // },
                  edit: hasUpdatePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedProductCategory,
                          setOpenAction: setOpenProductCategoryUpdateDrawer,
                        },
                      }
                    : undefined,
                  delete: hasDeletePermission
                    ? {
                        clickConfig: {
                          setSelectedRow: setSelectedProductCategory,
                          setOpenAction: setOpenProductCategoryDeleteDialog,
                        },
                      }
                    : undefined,
                },
                actionHeaderName: t('crm.actions'),
              }}
              loading={
                productCategoryListLoading ||
                productCategoryUpdateLoading ||
                productCategoryListFetching
              }
              listFilter={{
                filterInput: productCategoryListFilter,
                setFilterInput: setProductCategoryListFilter,
              }}
              update={{
                updateQuery: productCategoryUpdate,
              }}
              count={productCategoryListData?.count ?? 0}
              config={{
                columnVisibilityModel: {
                  fullName: false,
                  website: false,
                  company: false,
                  sector: false,
                  country: false,
                  state: false,
                  city: false,
                  language: false,
                  productCategoryValue: false,
                  department: false,
                },
                features: [],
              }}
              toolbar={{
                customActions: [],
              }}
            />
          ) : (
            <EmptyState
              content1={t('crm.productAndService.productCategory.emptyState1')}
              content2={t('crm.productAndService.productCategory.emptyState2')}
              button1={{
                title: t('crm.productAndService.productCategory.create'),
                leftIcon: <Add sx={{ mr: 1 }} fontSize="small" />,
                onClick: () => (props?.setOpenProductCategoryCreateDrawer ?? (() => {}))(true),
                permission: {
                  subject: PermissionSubject.productCategory,
                  action: PermissionAction.create,
                },
              }}
            />
          )
        ) : null}
      </Card>
      <Box sx={{ width: '100%', height: '10px' }} />

      {/* <ProductCategoryDetailDrawerWrapper
        open={openProductCategoryDetailDrawer}
        setOpen={setOpenProductCategoryDetailDrawer}
        selectedProductCategory={selectedProductCategory as IProductCategoryDetailResult & IHasId<number>} // TODO:
        sideNavigationProps={{
          count: productCategoryListData?.count ?? 0,
          handleNavigate: handleSideNavigationNavigate({
            currentPage,
            currentPageSize,
            listData: productCategoryListData,
            listFilter: productCategoryListFilter,
            setListFilter: setProductCategoryListFilter,
            selectedEntity: selectedProductCategory,
            setSelectedEntity: setSelectedProductCategory,
            setPendingNavigation: setPendingNavigation,
          }),
        }}
      /> */}

      <ProductCategoryUpdateWraper
        open={openProductCategoryUpdateDrawer}
        setOpen={setOpenProductCategoryUpdateDrawer}
        selectedRow={selectedProductCategory as IProductCategory}
      />

      <DeleteProductCategoryDialog
        open={openProductCategoryDeleteDialog}
        item={selectedProductCategory as IBaseProductCategoryEntity}
        onClose={() => {
          setSelectedProductCategory(undefined);
          setOpenProductCategoryDeleteDialog(false);
        }}
        key={selectedProductCategory?._id}
      />
    </DefaultListGridComponent>
  );
});

export default ProductCategoryListGridComponent;
