/* eslint-disable react-hooks/exhaustive-deps */
import { IAppointmentDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { AppointmentDetailDrawerRightPanelHeaderComponent } from './AppointmentDetailDrawer-rightPanel-header.component';
import { AppointmentDetailDrawerRightPanelTabsComponent } from './AppointmentDetailDrawer-rightPanel.tabs.component';

export interface IAppointmentDetailDrawerRightPanelComponentProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
}

export const AppointmentDetailDrawerRightPanelComponent = (
  props: IAppointmentDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <AppointmentDetailDrawerRightPanelHeaderComponent appointmentDetailData={props.appointmentDetailData} />

      {/* Tabs Section */}
      <AppointmentDetailDrawerRightPanelTabsComponent appointmentDetailData={props.appointmentDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
