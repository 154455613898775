import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { Stack, Button, CircularProgress, Typography } from '@mui/material';
import { Iconify } from 'components';
import ActionDialog from 'components/dialog/ActionDialog';
import {
  invoiceAcceptQuery,
  invoiceDeclineQuery,
  InvoiceStatus,
  IInvoiceDetailPublicResult,
} from 'corede-common-cocrm';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ActionButtons = (props: {
  invoiceData: IInvoiceDetailPublicResult | undefined;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<
    ApolloQueryResult<{
      invoiceDetailPublic: IInvoiceDetailPublicResult;
    }>
  >;
}) => {
  const { t } = useTranslation();
  const [openDeclineReasonDialog, setOpenDeclineReasonDialog] = useState(false);
  const [reason, setReason] = useState('');
  const [
    invoiceAccept,
    { data: invoiceAcceptData, error: invoiceAcceptError, loading: invoiceAcceptLoading },
  ] = useMutation(invoiceAcceptQuery);
  const [
    invoiceDecline,
    { data: invoiceDeclineData, error: invoiceDeclineError, loading: invoiceDeclineLoading },
  ] = useMutation(invoiceDeclineQuery);

  const handleAccept = () => {
    invoiceAccept();
  };

  const handleDecline = () => {
    invoiceDecline({
      variables: {
        input: {
          reason,
        },
      },
    });
  };

  useEffect(() => {
    if (invoiceAcceptData) {
      enqueueSnackbar(t('auth.invoiceAccepted'), { variant: 'success' });
      props.refetch();
    }
  }, [invoiceAcceptData, t, props]);

  useEffect(() => {
    if (invoiceDeclineData) {
      setOpenDeclineReasonDialog(false);
      enqueueSnackbar(t('auth.invoiceDeclined'), { variant: 'success' });
      props.refetch();
    }
  }, [invoiceDeclineData, t, props]);

  return (
    <Stack gap={2}>
      {props.invoiceData?.status === InvoiceStatus.accepted && (
        <Typography
          fontSize={14}
          sx={{ fontWeight: 500 }}
          color={'success.main'}
          textAlign={'center'}
        >
          {t('auth.invoiceAccepted')}
        </Typography>
      )}
      {props.invoiceData?.status === InvoiceStatus.declined && (
        <Typography
          fontSize={14}
          sx={{ fontWeight: 500 }}
          color={'error.main'}
          textAlign={'center'}
        >
          {t('auth.invoiceDeclined')}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="success"
        fullWidth
        disabled={invoiceAcceptLoading || props.invoiceData?.status !== InvoiceStatus.opened}
        onClick={() => handleAccept()}
        sx={{ fontSize: '15px', borderRadius: '6px', height: 38, fontWeight: 400 }}
      >
        {invoiceDeclineLoading ? (
          <CircularProgress size={'1rem'} />
        ) : (
          <Iconify icon={'charm:tick'} width={24} sx={{ color: 'text.white', mr: 1 }} />
        )}
        {t('auth.accept')}
      </Button>

      <Button
        type="submit"
        variant="contained"
        color="error"
        fullWidth
        disabled={invoiceDeclineLoading || props.invoiceData?.status !== InvoiceStatus.opened}
        onClick={() => setOpenDeclineReasonDialog(true)}
        sx={{ fontSize: '15px', borderRadius: '6px', height: 38, fontWeight: 400 }}
      >
        {invoiceDeclineLoading ? (
          <CircularProgress size={'1rem'} />
        ) : (
          <Iconify
            icon={'material-symbols-light:close'}
            width={24}
            sx={{ color: 'text.white', mr: 1 }}
          />
        )}
        {t('auth.decline')}
      </Button>

      {/* <Button
        type="submit"
        variant="contained"
        color="info"
        fullWidth
        onClick={() => console.log('Download')}
        sx={{ fontSize: '15px', borderRadius: '6px', height: 38, fontWeight: 400 }}
      >
        <Iconify icon={'lucide:download'} width={24} sx={{ color: 'text.white', mr: 1 }} />
        {t('auth.download')}
      </Button> */}

      <ActionDialog
        open={openDeclineReasonDialog}
        onClose={() => setOpenDeclineReasonDialog(false)}
        title={t('auth.declineReason')}
        handleClick={handleDecline}
        width={500}
        loading={invoiceDeclineLoading}
        disabled={reason === '' || invoiceDeclineLoading}
      >
        <Stack gap={2} width={'100%'}>
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
            {t('auth.declineReason')}
          </Typography>
          <textarea
            style={{
              width: '95%',
              padding: '10px',
              borderRadius: '6px',
              border: '1px solid #ccc',
              resize: 'none',
            }}
            rows={4}
            placeholder={t('auth.declineReasonPlaceholder')}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </Stack>
      </ActionDialog>
    </Stack>
  );
};

export default ActionButtons;
