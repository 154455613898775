const locale = {
  diskTitle: 'Disk',
  detail: 'Dökuman Detayı',
  documentPicture: 'Profil Resmi',
  name: 'İsim',
  surname: 'Soyisim',
  birthDate: 'Doğum Tarihi',
  gender: 'Cinsiyet',
  update: 'Güncelle',
  changeImage: 'Resmi Değiştir',
  change: 'Değiştir',
  updateDocumentSuccess: 'Profil bilgileri güncellendi',
  updateDocumentPictureSuccess: 'Profil resmi güncellendi',
  country: 'Ülke',
  city: 'Şehir',
  emptyStateContent1: 'Döküman bulunamadı',
  emptyStateContent2: 'Yeni bir döküman oluşturun',
  delete: 'Sil',
  deleteConfirm: 'Bu dökumanı silmek istediginizden emin misiniz? Bu işlem geri alınamaz.',
  deletedSuccessfully: 'Dökuman silindi',
  recentFiles: 'Son Dosyalar',
  file: 'dosya',
  files: 'dosya',
  Folders: 'Klasörler',
  folders: 'klasör',
  folder: 'klasör',

  properties: 'Özellikler',
  size: 'Boyut',
  downloadCount: 'İndirilme Sayısı',

  // menu
  myDocuments: 'Kendi Dökümanlarım',
  sharedDocuments: 'Paylaşılan Dökümanlar',
  allDocuments: 'Tüm Dökümanlar',
  privateDocuments: 'Özel Dökümanlar',

  //filter
  search: 'Ara',
  fileType: 'Dökuman Tipi',
  target: 'Hedef',
  tags: 'Etiketler',
};

export default locale;
