import { Grid } from '@mui/material';
import { memo, useState } from 'react';
import { Add } from '@mui/icons-material';
import { useLeadStatusListCountQuery } from '../../context/leadStatus.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import LeadStatusListComponent from './LeadStatusList-grid.component';
import LeadStatusCreateDrawerWrapper from '../create/LeadStatusCreateDrawer.wrapper';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const LeadStatusList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openLeadStatusCreateDrawer, setOpenLeadStatusCreateDrawer] = useState(false);

  const [leadStatusListType, setLeadStatusListType] = useState('list');

  // queries
  const { data: leadStatusListCountData } = useLeadStatusListCountQuery({});

  let totalLeadStatusCount = leadStatusListCountData?.count ?? 0;
  let isEmpty = totalLeadStatusCount === 0;

  // useEffects.error

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={t(`crm.lead.leadStatus.title`)}
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.lead.leadStatus.create`),
          onClick: () => setOpenLeadStatusCreateDrawer(true),
          permission: {
            subject: PermissionSubject.leadStatus,
            action: PermissionAction.create,
          },
        }}
      />

      {leadStatusListType === 'list' ? (
        <LeadStatusListComponent setOpenLeadStatusCreateDrawer={setOpenLeadStatusCreateDrawer} />
      ) : null}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.leadStatus,
          action: PermissionAction.create,
        }}
      >
        <LeadStatusCreateDrawerWrapper
          open={openLeadStatusCreateDrawer}
          setOpen={setOpenLeadStatusCreateDrawer}
        />
      </PermissionWrapper>
    </Grid>
  );
});

export default LeadStatusList;
