/* eslint-disable react-hooks/exhaustive-deps */
import { Stack, Theme, useMediaQuery } from '@mui/material';
import React from 'react';

export interface IDefaultDetailDrawerLeftPanelComponentProps<T> {
  children: React.ReactNode;
  entityData: T | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DefaultDetailDrawerLeftPanelComponent = <T,>(
  props: IDefaultDetailDrawerLeftPanelComponentProps<T>,
) => {
  const downSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Stack
      sx={{
        width: { xs: '100%', lg: '400px' },
        height: { xs: '100%', lg: '85dvh' },
        px: downSm ? 0 : 2.5,
        py: 3,
        backgroundColor: 'background.default',
        borderRadius: '24px',
        boxShadow: downSm ? 'none' : '0px 3px 12px 0px rgba(47, 43, 61, 0.14)',
        boxSizing: 'border-box',
      }}
    >
      <Stack direction={'column'} alignItems={'flex-start'} gap={1}>
        {props.children}
      </Stack>
    </Stack>
  );
};
