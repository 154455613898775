/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { ITicketDetailResult } from 'corede-common-cocrm';
import { TicketDetailDrawerLeftPanelActionsComponent } from './TicketDetailDrawer-leftPanel-header-actions.component';

export interface ITicketDetailDrawerLeftPanelHeaderComponentProps {
  ticketDetailData: ITicketDetailResult | undefined;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Container for views in left panel header
 */
export const TicketDetailDrawerLeftPanelHeaderComponent = (
  props: ITicketDetailDrawerLeftPanelHeaderComponentProps,
) => {
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack
      direction={{ xs: 'row', lg: 'column' }}
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      gap={1}
    >
      {/* Ticket Base Info Section */}
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', color: 'primary.main' }}>
        {props.ticketDetailData?.subject ?? '-'}
      </Typography>

      <TicketDetailDrawerLeftPanelActionsComponent
        ticketDetailData={props.ticketDetailData}
        setOpen={props.setOpen}
      />
    </Stack>
  );
};
