import { OrganizationalChartDomainLayout } from '../layouts';
import { organizationalChartDomainRoutes } from './organizationalChart.base.route';
import { Route, Routes } from 'react-router-dom';
import { UsersRouter } from '../subdomains/user/routes/UsersRouter';
import { DepartmentsRouter } from '../subdomains/department/routes/DepartmentsRouter';
import { RolesRouter } from '../subdomains/role/routes/RolesRouter';

export const OrganizationalChartDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<OrganizationalChartDomainLayout />}>
        <Route index element={<UsersRouter />} />
        <Route
          path={`${organizationalChartDomainRoutes.subdomains.user.base}/*`}
          element={<UsersRouter />}
        />
        <Route
          path={`${organizationalChartDomainRoutes.subdomains.department.base}/*`}
          element={<DepartmentsRouter />}
        />
        <Route
          path={`${organizationalChartDomainRoutes.subdomains.role.base}/*`}
          element={<RolesRouter />}
        />
      </Route>
    </Routes>
  );
};
