import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import { IAppointmentDetailResult } from 'corede-common-cocrm';
import React from 'react';
import moment from 'moment';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';

export interface IAppointmentDetailDrawerLeftPanelProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppointmentDetailDrawerLeftPanelContactDetailComponent = (
  props: IAppointmentDetailDrawerLeftPanelProps,
) => {
  const { t } = useTranslation();
  const downLg = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));

  return (
    <Stack>
      {/* Genel Bilgiler */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={2}>
        {!downLg && (
          <Stack direction={'column'} width={'100%'} gap={0.5}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                color: 'text.primary',
              }}
            >
              {t('crm.calendar.appointment.generalInfoTitle')}
            </Typography>
            <Divider />
          </Stack>
        )}

        {/* Appointment Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.name')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.appointmentDetailData?.title || '-'}
          </Typography>
        </Stack>

        {/* Location */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.calendar.appointment.location')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.appointmentDetailData?.location || ''}
          </Typography>
        </Stack>

        {/* Created At */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.createdAt')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {moment(props.appointmentDetailData?.createdAt).format('DD/MM/YYYY') || '-'}
          </Typography>
        </Stack>
      </Stack>

      {/* Organizatör Bilgileri */}
      <Stack direction={'column'} width={'100%'} gap={2} mt={4}>
        <Stack direction={'column'} width={'100%'} gap={0.5}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              color: 'text.primary',
            }}
          >
            {t('crm.calendar.appointment.organizerInfoTitle')}
          </Typography>
          <Divider />
        </Stack>

        {/* Organizer Name */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.name')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.appointmentDetailData?.organizer?.name || '-'}
          </Typography>
        </Stack>

        {/* Organizer Surname */}
        <Stack direction={'row'} gap={1} alignItems={'center'} width={'100%'}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.secondary',
            }}
          >
            {t('crm.surname')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '12px',
              color: 'text.primary',
            }}
          >
            {props.appointmentDetailData?.organizer?.surname || '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
