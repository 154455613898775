import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { routes } from '../routes/routes';

const RedirectError = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get('message');
  const navigate = useNavigate();

  useEffect(() => {
    if (message === 'alreadyRegisteredError') {
      enqueueSnackbar(t('auth.alreadyRegisteredError'), { variant: 'warning' });
      navigate(routes.auth.login);
    }
    if (message === 'userNotFoundError') {
      enqueueSnackbar(t('auth.userNotFoundError'), { variant: 'warning' });
      navigate(routes.auth.login);
    }
    if (message === 'access_denied') {
      navigate(routes.auth.login);
    }
  }, [message, navigate]);

  return null;
};

export default RedirectError;
