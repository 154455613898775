export const userTags = {
  organizations: "organizations",
  organization: "organization",
  user: "user",
  users: "users",
  usersByRelated: "usersByRelated",
  permissions: "permissions",
  roles: "roles",
  role: "role",
};
