import { Grid, Stack, Typography, Divider } from '@mui/material'

export const SettingsTitle = (props: {
    selectedTabName: string
    rightButton?: JSX.Element | undefined
}) => {
    return <Grid item xs={12}>
        <Stack direction={"column"}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                {/* <Typography fontSize={"20px"} fontWeight={700}>
                    {props.selectedTabName}
                </Typography> */}
                <Typography />
                {props.rightButton}
            </Stack>
            {/* <Divider /> */}
        </Stack>
    </Grid>
}