/* eslint-disable react-hooks/exhaustive-deps */
import { IProposalDetailResult } from 'corede-common-cocrm';
import { ProposalDetailDrawerHeaderStatusComponent } from './ProposalDetailDrawer-rightPanel-header-status.component';
// import { ProposalDetailDrawerBaseInfoComponent } from './ProposalDetailDrawer-rightPanel-header-baseInfo.component';

export interface IProposalDetailDrawerRightPanelHeaderComponentProps {
  proposalDetailData: IProposalDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ProposalDetailDrawerRightPanelHeaderComponent = (
  props: IProposalDetailDrawerRightPanelHeaderComponentProps,
) => {
  return (
    <>
      <ProposalDetailDrawerHeaderStatusComponent proposalDetailData={props.proposalDetailData} />
      {/* <ProposalDetailDrawerBaseInfoComponent proposalDetailData={props.proposalDetailData} /> */}
    </>
  );
};
