import { commonRoutes } from 'routes/route.common';

export const organizationalChartDomainRoutes = {
  base: 'organizational-chart',
  subdomains: {
    user: {
      base: 'user',
    },
    department: {
      base: 'department',
    },
    role: {
      base: 'role',
    },
    // add subdomain route names here
  },
};

// Organization routes
export const baseOrganizationalChartRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/`;
export const listUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/`;
export const viewUsersRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${id}`;
export const createUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${commonRoutes.create}`;
export const updateUsersRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${id}/${commonRoutes.update}`;
export const rolesUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${commonRoutes.roles}`;
export const userListUsersRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.user.base}/${commonRoutes.userList}`;
export const listDepartmentsRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.department.base}`;
export const listRolesRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.role.base}`;
export const viewRoleRoute = (id: string): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.role.base}/${id}`;
export const createRoleRoute = (): string =>
  `/${organizationalChartDomainRoutes.base}/${organizationalChartDomainRoutes.subdomains.role.base}/${commonRoutes.create}`;
// add subdomain routes here
