import { Grid, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { memo, useState, useEffect } from 'react';
import { Add, ViewList, ViewWeek } from '@mui/icons-material';
import { useTicketListQuery } from '../../context/support.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import TicketCreateDrawerWrapper from '../create/TicketCreateOverlay';
import { OverlayType } from 'components/dialog/ActionDialog';
import TicketListGridComponent from './TicketList-grid.component';
import { PermissionSubject, TicketTargetType } from 'corede-common-cocrm';
import { PermissionAction } from 'corede-common';
import { usePermissions } from 'permission/PermissionContext';

const TicketList = memo(() => {
  // general
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [openTicketCreateDrawer, setOpenTicketCreateDrawer] = useState(false);
  const [ticketListType, setTicketListType] = useState('list');

  // queries
  const { data: ticketListData, isLoading: ticketListLoading } = useTicketListQuery({});

  let totalTicketCount = ticketListData?.count ?? 0;
  let isEmpty = totalTicketCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenTicketCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            {t(`crm.support.support.title`)}
          </Stack>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.support.support.create`),
          onClick: () => setOpenTicketCreateDrawer(true),
          permission: {
            subject: PermissionSubject.ticket,
            action: PermissionAction.create,
          },
        }}
      />

      {ticketListType === 'list' ? (
        <TicketListGridComponent setOpenTicketCreateDrawer={setOpenTicketCreateDrawer} />
      ) : null}

      <TicketCreateDrawerWrapper
        open={openTicketCreateDrawer}
        setOpen={setOpenTicketCreateDrawer}
        overlayType={OverlayType.drawer}
      />
    </Grid>
  );
});

export default TicketList;
