import { Route, Routes } from "react-router-dom";

import { CustomerList } from "../pages";

import { CustomersLayout } from "../layouts/CustomersLayout";
import {CustomerDetail} from "../pages/detail/CustomerDetail";
import { commonRoutes } from "routes/route.common";
import Import from "../pages/Import";
export const CustomersRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomersLayout />}>
        <Route index element={<CustomerList />} />
        <Route path={`/:id`} element={<CustomerDetail />} />
        <Route path={`/${commonRoutes.import}`} element={<Import />} />
       </Route>
    </Routes>
  );
};
