import { IEntityProfile, IUserProfile } from './profile-column-cell.component';

export class UserProfileFactory {
  static CreateUserProfileFrom(params: IUserProfile): IUserProfile {
    return params;
  }
}

export class EntityProfileFactory {
  static CreateEntityProfileFrom(params: IEntityProfile): IEntityProfile {
    return params;
  }
}
