/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useLeadFormDeleteMutation } from "../context/leadForm.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const LeadFormDeleteDialog = memo(
  (props: {
    open: boolean;
    leadFormId: string;
    onClose: () => void;
    setLeadForm: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      leadFormDelete,
      {
        data: leadFormDeleteData,
        isLoading: leadFormDeleteIsLoading,
        error: leadFormDeleteError,
      },
    ] = useLeadFormDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.leadFormId}
        setItem={props.setLeadForm}
        deleteMutation={leadFormDelete}
        deleteMutationData={{
          data: leadFormDeleteData,
          isLoading: leadFormDeleteIsLoading,
          error: leadFormDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.delete"),
          successMessage: t("crm.lead.leadForm.deletedSuccessfully"),
          content: t("crm.lead.leadForm.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
