/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseError } from "corede-common";
import { Dispatch, memo, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { EntityDeleteDialog } from "components/entityDeleteDialog/EntityDeleteDialog";
import { useContractDeleteMutation } from "../context/contract.api";
import { TListGridSelectedEntity } from "apps/crm/components/list/DefaultList-grid.component";

export const ContractDeleteDialog = memo(
  (props: {
    open: boolean;
    contractId: string;
    onClose: () => void;
    setContract: Dispatch<SetStateAction<TListGridSelectedEntity<any>>>
  }) => {
    // general
    const { t } = useTranslation();

    // mutations
    const [
      contractDelete,
      {
        data: contractDeleteData,
        isLoading: contractDeleteIsLoading,
        error: contractDeleteError,
      },
    ] = useContractDeleteMutation();

    return (
      <EntityDeleteDialog
        open={props.open}
        itemId={props.contractId}
        setItem={props.setContract}
        deleteMutation={contractDelete}
        deleteMutationData={{
          data: contractDeleteData,
          isLoading: contractDeleteIsLoading,
          error: contractDeleteError as IBaseError,
        }}
        dialog={{
          title: t("crm.delete"),
          successMessage: t("crm.contract.contract.deletedSuccessfully"),
          content: t("crm.contract.contract.deleteConfirm"),
        }}
        onClose={props.onClose}
      />
    );
  }
);
