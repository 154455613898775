/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { generateAutocompleteTranslatedOptions, getCurrentLanguage } from 'localization';
import { useTranslation } from 'react-i18next';
import { IBaseError, IGraphqlVariables } from 'corede-common';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';

import { UseFormReturn } from 'react-hook-form';
import { DefaultEnumAutocompleteInput } from 'components/form/DefaultEnumAutocompleteInput';
import { DefaultObjectAutocompleteInput } from 'components/form/DefaultObjectAutocompleteInput';
import {
  DepartmentRelatedTargetType,
  TicketCategory,
  TicketPriority,
  TicketTargetType,
  UserRelatedTargetType,
} from 'corede-common-cocrm';
import { useEstimateListQuery } from 'apps/crm/domains/05-preSale/subdomains/estimate';
import { useProposalListQuery } from 'apps/crm/domains/05-preSale/subdomains/proposal';
import { useInvoiceListQuery } from 'apps/crm/domains/06-sale/subdomains/invoice';
import { DefaultTextFieldInput } from 'components/form/DefaultTextFieldInput';
import { DefaultArrayObjectAutocompleteInput } from 'components/form/DefaultArrayObjectAutocompleteInput';
import { useUserListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import { DefaultArrayAutocompleteInput } from 'components/form/DefaultArrayAutocompleteInput';
import { useTaskListQuery } from 'apps/crm/domains/11-task/subdomains/task';
import { useDepartmentListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/department';
import { DefaultRelationshipSwitchInput } from 'components/form/DefaultRelationshipSwitchInput';
import { useProjectListQuery } from 'apps/crm/domains/10-project/subdomains/project';
import { convertUserListToUserProfileList } from 'utils/user.entity.util';

export interface IUpsertTicketComponentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  useForm: UseFormReturn<IGraphqlVariables<any, any>>;
  targetId?: string;
  targetType?: TicketTargetType;
  type?: 'create' | 'update';
}

export const UpsertTicketComponent = (props: IUpsertTicketComponentProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();
  const [haveRelation, setHaveRelation] = useState(false);

  // queries
  const { data: departmentListData, isLoading: departmentListLoading } =
    useDepartmentListByRelatedQuery({
      input: {
        filter: {
          relatedTargetType: DepartmentRelatedTargetType.Ticket,
        },
      },
    });

  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
  } = useUserListByRelatedQuery({
    input: {
      filter: {
        relatedTargetType: UserRelatedTargetType.Ticket,
        departmentId: props.useForm.watch('input.departmentId')
          ? props.useForm.watch('input.departmentId') !== ''
            ? props.useForm.watch('input.departmentId')
            : undefined
          : undefined,
      },
    },
  });

  const {
    data: estimateListData,
    isLoading: estimateListLoading,
    error: estimateListError,
  } = useEstimateListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Estimate),
    },
  );

  const {
    data: proposalListData,
    isLoading: proposalListLoading,
    error: proposalListError,
  } = useProposalListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Proposal),
    },
  );

  const {
    data: invoiceListData,
    isLoading: invoiceListLoading,
    error: invoiceListError,
  } = useInvoiceListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Invoice),
    },
  );

  const {
    data: taskListData,
    isLoading: taskListLoading,
    error: taskListError,
  } = useTaskListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Task),
    },
  );

  const {
    data: projectListData,
    isLoading: projectListLoading,
    error: projectListError,
  } = useProjectListQuery(
    {},
    {
      skip: !(props.useForm.watch('input.targetType') === TicketTargetType.Project),
    },
  );

  const estimateListForOptions: { name: string; _id: string }[] =
    estimateListData?.data.map((estimate) => ({
      name: estimate.prospectName,
      _id: estimate._id,
    })) ?? [];

  const proposalListForOptions: { name: string; _id: string }[] =
    proposalListData?.data.map((proposal) => ({
      name: proposal.prospectName,
      _id: proposal._id,
    })) ?? [];

  const invoiceListForOptions: { name: string; _id: string }[] =
    invoiceListData?.data.map((invoice) => ({
      name: invoice.invoiceId.toString(),
      _id: invoice._id,
    })) ?? [];

  const taskListForOptions: { name: string; _id: string }[] =
    taskListData?.data.map((task) => ({
      name: task.subject,
      _id: task._id,
    })) ?? [];

  const projectListForOptions: { name: string; _id: string }[] =
    projectListData?.data.map((project) => ({
      name: project.name,
      _id: project._id,
    })) ?? [];

  // useEffects.success

  // useEffects.error

  // useEffects init

  useEffect(() => {
    DefaultErrorHandlerUseEffect(userListError as IBaseError, currentLanguage);
  }, [userListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(estimateListError as IBaseError, currentLanguage);
  }, [estimateListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(proposalListError as IBaseError, currentLanguage);
  }, [proposalListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(invoiceListError as IBaseError, currentLanguage);
  }, [invoiceListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(taskListError as IBaseError, currentLanguage);
  }, [taskListError]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(projectListError as IBaseError, currentLanguage);
  }, [projectListError]);

  useEffect(() => {
    if (props.useForm.watch('input.targetType') === TicketTargetType.Estimate) {
      props.useForm.setValue('input.targetId', undefined);
    }
  }, [props.useForm.watch('input.targetType')]);

  useEffect(() => {
    if (!haveRelation) {
      props.useForm.setValue('input.targetType', undefined);
      props.useForm.setValue('input.targetId', undefined);
    }
  }, [haveRelation]);

  useEffect(() => {
    if (!props.open) {
      setHaveRelation(false);
    }
  }, [props.open]);

  return (
    <Grid container spacing={2} sx={{ mb: 2, p: 2 }}>
      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.subject"
          control={props.useForm.control}
          label={t('crm.subject')}
          isRequired
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultTextFieldInput
          inputName="input.ticketBody"
          control={props.useForm.control}
          label={t('crm.description')}
          multiline
          rows={3}
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DefaultTextFieldInput
          inputName="input.contactName"
          control={props.useForm.control}
          label={t('crm.contactName')}
          isRequired
          isFirstLetterCapitalize
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DefaultTextFieldInput
          inputName="input.contactEmail"
          control={props.useForm.control}
          label={t('crm.contactEmail')}
          isRequired
          type="email"
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DefaultEnumAutocompleteInput
          inputName="input.priority"
          label={t('crm.priority')}
          options={generateAutocompleteTranslatedOptions(TicketPriority)}
          enum={TicketPriority}
          control={props.useForm.control}
          disableClearable
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DefaultObjectAutocompleteInput
          control={props.useForm.control}
          errors={props.useForm.formState.errors}
          inputName="input.departmentId"
          label={t('crm.department')}
          options={departmentListData?.data ?? []}
          touchedFields={props.useForm.formState.touchedFields}
          disableAddNewOption
          isLoading={departmentListLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultArrayObjectAutocompleteInput
          inputName="input.assigneeIds"
          control={props.useForm.control}
          label={t('crm.assignees')}
          options={convertUserListToUserProfileList(userListData?.data)}
          loading={userListLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultEnumAutocompleteInput
          inputName="input.category"
          label={t('crm.category')}
          options={generateAutocompleteTranslatedOptions(TicketCategory)}
          enum={TicketCategory}
          control={props.useForm.control}
          disableClearable
        />
      </Grid>

      <Grid item xs={12}>
        <DefaultArrayAutocompleteInput
          inputName="input.tags"
          control={props.useForm.control}
          label={t('crm.tags')}
          options={[]}
          errors={props.useForm.formState.errors}
          touchedFields={props.useForm.formState.touchedFields}
          placeholder={t('crm.tagsPlaceholder')}
        />
      </Grid>

      {props.type === 'create' && (
        <Grid item xs={12} md={6}>
          <DefaultRelationshipSwitchInput
            description={t('crm.relationship')}
            checked={haveRelation}
            setChecked={setHaveRelation}
          />
        </Grid>
      )}

      {haveRelation && (
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: !!props.targetType ? 'none' : 'block',
          }}
        >
          <DefaultEnumAutocompleteInput
            inputName="input.targetType"
            label={t('crm.relationshipType')}
            options={generateAutocompleteTranslatedOptions(TicketTargetType)}
            enum={TicketTargetType}
            control={props.useForm.control}
            disableClearable
          />
        </Grid>
      )}

      {(props.useForm.watch('input.targetType') || props.targetType) && props.type === 'create' && (
        <Grid item xs={12}>
          <DefaultObjectAutocompleteInput
            key={props.useForm.watch('input.targetId')}
            inputName="input.targetId"
            control={props.useForm.control}
            options={
              props.useForm.watch('input.targetType') === TicketTargetType.Estimate
                ? estimateListForOptions
                : props.useForm.watch('input.targetType') === TicketTargetType.Proposal
                  ? proposalListForOptions
                  : props.useForm.watch('input.targetType') === TicketTargetType.Invoice
                    ? invoiceListForOptions
                    : props.useForm.watch('input.targetType') === TicketTargetType.Task
                      ? taskListForOptions
                      : props.useForm.watch('input.targetType') === TicketTargetType.Project
                        ? projectListForOptions
                        : []
            }
            label={
              props.useForm.watch('input.targetType') === TicketTargetType.Estimate
                ? t('crm.relatedEstimate')
                : props.useForm.watch('input.targetType') === TicketTargetType.Proposal
                  ? t('crm.relatedProposal')
                  : props.useForm.watch('input.targetType') === TicketTargetType.Invoice
                    ? t('crm.relatedInvoice')
                    : props.useForm.watch('input.targetType') === TicketTargetType.Task
                      ? t('crm.relatedTask')
                      : props.useForm.watch('input.targetType') === TicketTargetType.Project
                        ? t('crm.relatedProject')
                        : t('crm.relatedEntity')
            }
            errors={props.useForm.formState.errors}
            touchedFields={props.useForm.formState.touchedFields}
            isRequired
            isLoading={
              props.useForm.watch('input.targetType') === TicketTargetType.Estimate
                ? estimateListLoading
                : props.useForm.watch('input.targetType') === TicketTargetType.Proposal
                  ? proposalListLoading
                  : props.useForm.watch('input.targetType') === TicketTargetType.Invoice
                    ? invoiceListLoading
                    : props.useForm.watch('input.targetType') === TicketTargetType.Task
                      ? taskListLoading
                      : props.useForm.watch('input.targetType') === TicketTargetType.Project
                        ? projectListLoading
                        : false
            }
            disableAddNewOption
          />
        </Grid>
      )}
    </Grid>
  );
};
