/* eslint-disable react-hooks/exhaustive-deps */
import { IContractDetailResult } from 'corede-common-cocrm';
import { DefaultDetailDrawerRightPanelComponent } from '../../../../../../components/detailDrawer/DefaultDetailDrawer-rightPanel.component';
import { ContractDetailDrawerRightPanelHeaderComponent } from './ContractDetailDrawer-rightPanel-header.component';
import { ContractDetailDrawerRightPanelTabsComponent } from './ContractDetailDrawer-rightPanel.tabs.component';

export interface IContractDetailDrawerRightPanelComponentProps {
  contractDetailData: IContractDetailResult | undefined;
}

export const ContractDetailDrawerRightPanelComponent = (
  props: IContractDetailDrawerRightPanelComponentProps,
) => {
  return (
    <DefaultDetailDrawerRightPanelComponent>
      {/* Header Section */}
      <ContractDetailDrawerRightPanelHeaderComponent contractDetailData={props.contractDetailData} />

      {/* Tabs Section */}
      <ContractDetailDrawerRightPanelTabsComponent contractDetailData={props.contractDetailData} />
    </DefaultDetailDrawerRightPanelComponent>
  );
};
