import { Grid, Stack, Typography, useMediaQuery, Theme } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { Add } from '@mui/icons-material';
// import { useExpenseListCountQuery } from '../../context/expense.api';
import { HeaderBase } from 'components/contentHeaders/crm/HeaderBase';
import { useTranslation } from 'react-i18next';
import ExpenseListComponent from './ExpenseList-grid.component';
import { useLocation } from 'react-router-dom';
import { ExpenseListStatisticsView } from './ExpenseList-statistics.component';
import ExpenseCreateDrawerWrapper from '../create/ExpenseCreateDrawer.wrapper';
import { useExpenseListQuery } from '../../context/expense.api';
import { PermissionAction } from 'corede-common';
import { PermissionSubject } from 'corede-common-cocrm';
import { PermissionWrapper } from 'permission/PermissionWrapper';

const ExpenseList = memo(() => {
  // general
  const { t } = useTranslation();
  const [openExpenseCreateDrawer, setOpenExpenseCreateDrawer] = useState(false);
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const location = useLocation();

  // queries
  // const { data: expenseListCountData, isLoading: expenseListCountLoading } = useExpenseListCountQuery({});
  const { data: expenseListCountData, isLoading: expenseListCountLoading } = useExpenseListQuery(
    {},
  );

  let totalExpenseCount = expenseListCountData?.count ?? 0;
  let isEmpty = totalExpenseCount === 0;

  // useEffects.init
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#create') {
      setOpenExpenseCreateDrawer(true);
    }
  }, [location]);

  return (
    <Grid item xs={12} height={'calc(100vh - 260px)'}>
      <HeaderBase
        title={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant={downMd ? 'body1' : 'h4'} sx={{ fontWeight: '500' }}>
              {t(`crm.expense.expense.title`)}
            </Typography>
          </Stack>
        }
        rightButton={{
          visibility: !isEmpty,
          icon: <Add sx={{ mr: 1, opacity: 0.7 }} fontSize="small" />,
          title: t(`crm.expense.expense.create`),
          onClick: () => setOpenExpenseCreateDrawer(true),
          permission: {
            subject: PermissionSubject.expense,
            action: PermissionAction.create,
          },
        }}
      />

      <ExpenseListStatisticsView
        count={totalExpenseCount}
        expenseListCountLoading={expenseListCountLoading}
      />

      <ExpenseListComponent setOpenExpenseCreateDrawer={setOpenExpenseCreateDrawer} />

      <PermissionWrapper
        check={{
          subject: PermissionSubject.expense,
          action: PermissionAction.create,
        }}
      >
        <ExpenseCreateDrawerWrapper
          open={openExpenseCreateDrawer}
          setOpen={setOpenExpenseCreateDrawer}
        />
      </PermissionWrapper>
    </Grid>
  );
});

export default ExpenseList;
