import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import EmptyState from 'components/emptyState/EmptyState';
import {
  IProposalListItemResult,
  PermissionSubject,
  ProposalProspectTargetEntityRef,
} from 'corede-common-cocrm';
import { useState } from 'react';
import { representDateAsString, representString } from 'utils/representationHelper';
import { useProposalListQuery, useProposalUpdateMutation } from '../context/proposal.api';
import { ProposalDeleteDialog } from '../pages/delete/ProposalDeleteDialog';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import { IEntity, PermissionAction } from 'corede-common';
import { useTranslation } from 'react-i18next';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { Stack } from '@mui/material';
import { Iconify } from 'components';
import DocumentEmptyIcon from 'assets/icons/Document_empty.png';
import ProposalListItemComponent from './ProposalListItem';
import { useNavigate } from 'react-router-dom';
import { listProposalsRoute } from '../../../routes/preSales.base.route';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export interface IProposalListTabSectionComponent extends DefaultTabComponentProps<IEntity> {
  prospectTargetId: string;
  prospectTargetEntityRef: ProposalProspectTargetEntityRef;
  tabKey: 'proposals';
  name: 'crm.proposals';
}

const ProposalListTabSectionComponent = (props: IProposalListTabSectionComponent) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openProposalDetailDrawer, setOpenProposalDetailDrawer] = useState(false);
  const [openProposalDeleteDialog, setOpenProposalDeleteDialog] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState<IProposalListItemResult>();

  const { hasPermission } = usePermissions();
  const hasDetailPermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.detail,
  });
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.update,
  });
  const hasDeletePermission = hasPermission({
    subject: PermissionSubject.proposal,
    action: PermissionAction.delete,
  });

  const { data: proposalListData, isLoading: proposalListLoading } = useProposalListQuery(
    {
      input: {
        filter: {
          prospectTargetEntityId: props?.prospectTargetId,
          prospectTargetEntityRef: ProposalProspectTargetEntityRef.Lead,
        },
      },
    },
    {
      skip: !props.prospectTargetId,
    },
  );

  const [proposalUpdate] = useProposalUpdateMutation();

  return (
    <DefaultTabContainer title={''}>
      {proposalListData && proposalListData?.count === 0 && (
        <EmptyState
          icon={
            <img
              src={DocumentEmptyIcon}
              style={{
                width: '180px',
                height: '180px',
                marginBottom: '30px',
              }}
              alt="There is no proposal yet."
            />
          }
          content1={t('crm.noProposalContent1')}
          // content2={t('crm.noProposal')}
          button1={{
            title: t('crm.preSale.proposal.create'),
            onClick: () => {
              navigate(listProposalsRoute() + '#create');
            },
            leftIcon: <Iconify icon={'mdi:plus'} sx={{ mr: 1 }} />,
            permission: {
              subject: PermissionSubject.proposal,
              action: PermissionAction.create,
            },
          }}
        />
      )}

      {proposalListData && proposalListData?.count > 3 && (
        <BaseDataGrid
          rows={proposalListData?.data?.map((proposal, index) => ({
            id: index + 1,
            prefix: representString(proposal?.prefix),
            createdAt: representDateAsString(proposal?.createdAt),
          }))}
          columns={[
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'id',
                  disableColumnMenu: true,
                  filterable: false,
                  width: 40,
                },
                headerConfig: {
                  name: '#',
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'prefix',
                  editable: false,
                  filterable: false,
                  width: 100,
                },
                headerConfig: {
                  name: t('crm.preSale.proposal.prefix'),
                },
              },
            },
            {
              config: {
                baseGridColType: BaseGridColType.text,
                column: {
                  field: 'createdAt',
                  editable: false,
                  filterable: false,
                  width: 140,
                },
                headerConfig: {
                  name: t('crm.preSale.proposal.createdAt'),
                },
              },
            },
          ]}
          actionColumn={{
            width: 80,
            defaultActions: {
              view: hasDetailPermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalDetailDrawer,
                    },
                  }
                : undefined,
              delete: hasDeletePermission
                ? {
                    clickConfig: {
                      setSelectedRow: setSelectedProposal,
                      setOpenAction: setOpenProposalDeleteDialog,
                    },
                  }
                : undefined,
            },
            actionHeaderName: t('crm.actions'),
          }}
          loading={proposalListLoading}
          listFilter={{
            filterInput: {},
            setFilterInput: () => null,
          }}
          count={proposalListData?.count ?? 0}
          config={{
            columnVisibilityModel: {
              roles: false,
            },
            features: [],
          }}
          update={{
            updateQuery: hasUpdatePermission ? proposalUpdate : undefined,
          }}
          disableColumnFilter={true}
          hideFooterPagination={true}
          sortingMode={'client'}
        />
      )}

      {proposalListData && proposalListData?.count > 0 && proposalListData?.count < 4 && (
        <Stack direction={'column'} gap={1.5} sx={{ width: '100%' }}>
          {proposalListData?.data?.map((proposal, index) => {
            if (index > 3) return null;

            return <ProposalListItemComponent key={index} proposal={proposal} />;
          })}
        </Stack>
      )}

      <PermissionWrapper
        check={{
          subject: PermissionSubject.proposal,
          action: PermissionAction.delete,
        }}
      >
        <ProposalDeleteDialog
          open={openProposalDeleteDialog}
          onClose={() => {
            setSelectedProposal(undefined);
            setOpenProposalDeleteDialog(false);
          }}
          proposalId={selectedProposal?._id ?? ''}
          setProposal={setSelectedProposal}
        />
      </PermissionWrapper>
    </DefaultTabContainer>
  );
};

export default ProposalListTabSectionComponent;
