import { Currency } from 'corede-common';

export function convertCurrency(currency?: Currency): string {
  if (!currency) {
    return '';
  }
  switch (currency) {
    case Currency.TRY:
      return '₺';
    case Currency.USD:
      return '$';
    case Currency.EUR:
      return '€';
    default:
      return currency;
  }
}
