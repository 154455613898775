/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Stack } from '@mui/material';
import { IHasId } from 'corede-common';
import {
  IProjectDetailResult,
  ITask,
  ITaskChecklistItem,
  ITaskDetailResult,
  ITaskListItemResult,
  TaskStatus,
  TaskTargetType,
} from 'corede-common-cocrm';
import ColumnContainer from './MilestoneKanban-columnContainer.component';
import TaskCard from './MilestoneKanban-taskCard.component';
import { getTranslatedEnumValue } from 'localization';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  useProjectMilestoneKanbanUpdateMutation,
  useProjectDetailQuery,
  useProjectMilestoneTaskAssignMutation,
} from '../../context/project.api';
import TaskDetailDrawer from 'apps/crm/domains/11-task/subdomains/task/pages/detailDrawer/TaskDetailDrawer';
import { TaskDeleteDialog } from 'apps/crm/domains/11-task/subdomains/task/pages/TaskDeleteDialog';
import TaskUpdateDrawerWrapper from 'apps/crm/domains/11-task/subdomains/task/pages/update/TaskUpdateOverlay';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';
import { OverlayType } from 'components/dialog/ActionDialog';
import {
  DndContext,
  DragOverlay,
  DragStartEvent,
  DragEndEvent,
  DragOverEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import {
  useTaskKanbanUpdateMutation,
  useTaskListQuery,
} from 'apps/crm/domains/11-task/subdomains/task';

export type Id = string | number;

export type Column = {
  id: Id;
  title: string;
};

export type Task = {
  id: Id;
  milestone: Id;
  subject: string;
  description: string;
  checklistTotal: number;
  checklistCompleted: number;
  documentCount: number;
  assignees: any[]; //TODO
  dueDate: Date | undefined;
};

export type MilestoneTasks = {
  [key: string]: Task[];
};

export interface IMilestoneKanbanComponent {
  // task

  projectDetailData: IProjectDetailResult | undefined;
  setOpenTaskCreateDrawer?: (value: boolean) => void | undefined;
}

const MilestoneKanbanComponent = memo((props: IMilestoneKanbanComponent) => {
  // init

  // states
  const [selectedTask, setSelectedTask] = useState<TListGridSelectedEntity<ITask>>();

  const [openTaskDetailDrawer, setOpenTaskDetailDrawer] = useState(false);
  const [openTaskDeleteDialog, setOpenTaskDeleteDialog] = useState(false);
  const [openTaskUpdateDrawer, setOpenTaskUpdateDrawer] = useState(false);

  // queries
  const { data: projectDetailData } = useProjectDetailQuery({
    input: {
      _id: props.projectDetailData?._id ?? '',
    },
  });
  const { data: taskListData } = useTaskListQuery({
    input: {
      filter: {
        relatedEntityRef: TaskTargetType.Project,
        relatedEntityId: props.projectDetailData?._id ?? '',
      },
    },
  });

  const milestones = projectDetailData?.milestones ?? [];
  const allTasks = taskListData?.data ?? [];
  const milestoneTaskIds = projectDetailData?.taskList?.map((task) => task.task?._id) ?? [];
  const unMilestoneTasks = allTasks.filter((task) => !milestoneTaskIds.includes(task._id));

  // mutations
  const [taskKanbanUpdate] = useTaskKanbanUpdateMutation();
  const [milestoneTaskAssign] = useProjectMilestoneTaskAssignMutation();

  // kanban

  const UNCATEGORIZED_COLUMN = {
    id: 'uncategorized',
    title: 'Uncategorized',
  };

  const [columns, setColumns] = useState<Column[]>([]);
  const [activeColumn, setActiveColumn] = useState<Column | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskCounts, setTaskCounts] = useState<{ [key: string]: number }>({});
  const [milestoneTasks, setMilestoneTasks] = useState<MilestoneTasks>({});
  const columnsId = useMemo(() => columns.map((col) => col.id), [columns]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { distance: 3 },
    }),
  );

  const updateTask = (id: Id, content: string) => {
    const updatedTasks = tasks.map((task) => (task.id === id ? { ...task, content } : task));
    setTasks(updatedTasks);
  };

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'Column') {
      setActiveColumn(event.active.data.current.column);
    } else if (event.active.data.current?.type === 'Task') {
      setActiveTask(event.active.data.current.task);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    setActiveColumn(null);
    setActiveTask(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (!activeId || !overId) return;

    if (active.id === over.id) return;

    const isTask = active.data.current?.type === 'Task';
    const isOverColumn = over.data.current?.type === 'Column';

    if (isTask && isOverColumn) {
      const activeTaskId = active.data.current?.task?.id;
      const overColumnId = over.data.current?.column?.id;
      const sourceColumnId = active.data.current?.task?.milestone;

      if (!activeTaskId || !overColumnId || !sourceColumnId) return;

      if (sourceColumnId === UNCATEGORIZED_COLUMN.id && overColumnId !== UNCATEGORIZED_COLUMN.id) {
        setMilestoneTasks((prevMilestoneTasks) => {
          const sourceTasks = prevMilestoneTasks[sourceColumnId] || [];
          const targetTasks = prevMilestoneTasks[overColumnId] || [];

          const updatedSourceTasks = sourceTasks.filter((task) => task.id !== activeTaskId);
          const updatedTargetTasks = [
            ...targetTasks,
            { ...active.data?.current?.task, milestone: overColumnId },
          ];

          return {
            ...prevMilestoneTasks,
            [sourceColumnId]: updatedSourceTasks,
            [overColumnId]: updatedTargetTasks,
          };
        });

        milestoneTaskAssign({
          filter: {
            projectId: projectDetailData?._id ?? '',
            taskId: activeTaskId,
          },
          input: {
            milestoneId: overColumnId,
          },
        }).catch((error) => {
          console.error('Error updating task:', error);
        });
      }
    }
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    if (active.id === over.id) return;

    const activeId = active.id;
    const overId = over.id;

    const isTask = active.data.current?.type === 'Task';
    const isOverTask = over.data.current?.type === 'Task';

    if (isTask && isOverTask) {
      setTasks((prevTasks) => {
        const activeIndex = prevTasks.findIndex((task) => task.id === activeId);
        const overIndex = prevTasks.findIndex((task) => task.id === overId);

        if (activeIndex !== -1 && overIndex !== -1) {
          prevTasks[activeIndex].milestone = prevTasks[overIndex].milestone;
          return arrayMove(prevTasks, activeIndex, overIndex);
        }
        return prevTasks;
      });
    } else if (isTask && over.data.current?.type === 'Column') {
      setTasks((prevTasks) => {
        const activeIndex = prevTasks.findIndex((task) => task.id === activeId);
        if (activeIndex !== -1) {
          prevTasks[activeIndex].milestone = overId;
          return [...prevTasks];
        }
        return prevTasks;
      });
    }
  };

  const handleLoadMore = () => {
    switch (true) {
      default:
        break;
    }
  };

  // effects

  useEffect(() => {
    const transformTasks = (tasks: ITaskListItemResult[] | undefined = []) =>
      tasks.map((task) => ({
        id: task._id,
        status: task.status,
        subject: task.subject,
        description: task.description,
        checklistTotal: task.checklist?.length ?? 0,
        checklistCompleted:
          task.checklist?.filter((item: ITaskChecklistItem) => item.isChecked).length ?? 0,
        documentCount: task.documents?.length ?? 0,
        assignees: task.assignees ?? [],
        dueDate: task.dueDate ?? undefined,
      }));

    const categorizedTasks: MilestoneTasks = milestones.reduce(
      (acc, milestone) => {
        acc[milestone._id] = [];
        return acc;
      },
      { uncategorized: [] } as MilestoneTasks,
    );

    allTasks?.forEach((task) => {
      const milestoneId = projectDetailData?.taskList?.find((t) => t.task?._id === task._id)
        ?.milestone?._id;
      if (milestoneId) {
        categorizedTasks[milestoneId].push({
          id: task._id,
          milestone: milestoneId,
          subject: task.subject,
          description: task.description,
          checklistTotal: task.checklist?.length ?? 0,
          checklistCompleted:
            task.checklist?.filter((item: ITaskChecklistItem) => item.isChecked).length ?? 0,
          documentCount: task.documents?.length ?? 0,
          assignees: task.assignees ?? [],
          dueDate: task.dueDate ?? undefined,
        });
      } else {
        categorizedTasks['uncategorized'].push({
          id: task._id,
          milestone: 'uncategorized',
          subject: task.subject,
          description: task.description,
          checklistTotal: task.checklist?.length ?? 0,
          checklistCompleted:
            task.checklist?.filter((item: ITaskChecklistItem) => item.isChecked).length ?? 0,
          documentCount: task.documents?.length ?? 0,
          assignees: task.assignees ?? [],
          dueDate: task.dueDate ?? undefined,
        });
      }
    });

    setMilestoneTasks(categorizedTasks);
  }, [allTasks, milestones, projectDetailData]);

  useEffect(() => {
    if (unMilestoneTasks?.length > 0) {
      setColumns([
        UNCATEGORIZED_COLUMN,
        ...milestones.map((milestone) => ({
          id: milestone._id,
          title: milestone.name,
        })),
      ]);
    } else {
      setColumns([
        ...milestones.map((milestone) => ({
          id: milestone._id,
          title: milestone.name,
        })),
      ]);
    }
  }, [unMilestoneTasks]);
  // custom views

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
      }}
    >
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <SortableContext items={columnsId}>
            {columns.map((col) => (
              <ColumnContainer
                key={col.id}
                column={col}
                tasks={milestoneTasks[col.id] || []}
                taskCount={milestoneTasks[col.id]?.length || 0}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
                handleLoadMore={handleLoadMore}
              />
            ))}
          </SortableContext>
        </Stack>

        {createPortal(
          <DragOverlay>
            {activeColumn && (
              <ColumnContainer
                column={activeColumn}
                tasks={tasks.filter((task) => task.milestone === activeColumn.id)}
                taskCount={taskCounts[activeColumn.id]}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
                handleLoadMore={handleLoadMore}
              />
            )}
            {activeTask && (
              <TaskCard
                task={activeTask}
                updateTask={updateTask}
                setSelectedTask={setSelectedTask}
                setOpenTaskDetailDrawer={setOpenTaskDetailDrawer}
              />
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>

      <Box sx={{ width: '100%', height: '10px' }} />

      <TaskDetailDrawer
        setSelectedTask={setSelectedTask}
        open={openTaskDetailDrawer}
        setOpen={setOpenTaskDetailDrawer}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
      />

      <TaskUpdateDrawerWrapper
        open={openTaskUpdateDrawer}
        setOpen={setOpenTaskUpdateDrawer}
        targetId={selectedTask?.relatedEntity?._id ?? ''}
        targetEntityRef={selectedTask?.relatedEntityRef}
        item={selectedTask}
        overlayType={OverlayType.drawer}
      />

      <TaskDeleteDialog
        open={openTaskDeleteDialog}
        selectedTask={selectedTask as ITaskDetailResult & IHasId<number>}
        setItem={setSelectedTask}
        key={selectedTask?._id}
        onClose={() => {
          setSelectedTask(undefined);
          setOpenTaskDeleteDialog(false);
        }}
      />
    </Box>
  );
});

export default MilestoneKanbanComponent;
