/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Grid, InputLabel, TextField, Typography } from '@mui/material';
import { IBaseError, IGraphqlVariables, PermissionAction } from 'corede-common';
import {
  IBaseLeadEntity,
  ILeadContactAddInput,
  PermissionSubject,
  UserRelatedTargetType,
} from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useLeadContactAddMutation } from '../../context/lead.api';
import ActionDialog from 'components/dialog/ActionDialog';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateLeadContactAddInput } from '../../validations/addContact.validation';
import { useUserListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import ReactQuill from 'react-quill-new';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { usePermissions } from 'permission/PermissionContext';

interface ILeadContactAddDialogProps {
  open: boolean;
  lead: IBaseLeadEntity | undefined;
  onClose: () => void;
}

export const LeadContactAddDialog = memo((props: ILeadContactAddDialogProps) => {
  // general
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  const { hasAnyPermission } = usePermissions();
  const hasLeadMutationPermission = hasAnyPermission([
    {
      subject: PermissionSubject.lead,
      action: PermissionAction.create,
    },
    {
      subject: PermissionSubject.lead,
      action: PermissionAction.update,
    },
  ]);

  // queries

  // mutations
  const { data: userListData, isLoading: userListLoading } = useUserListByRelatedQuery(
    {
      input: {
        filter: {
          relatedTargetType: UserRelatedTargetType.Lead,
        },
      },
    },
    {
      skip: !hasLeadMutationPermission,
    },
  );

  const [
    leadContactAdd,
    { data: leadContactAddData, isLoading: leadContactAddIsLoading, error: leadContactAddError },
  ] = useLeadContactAddMutation();

  // constants
  const { control, handleSubmit, reset } = useForm<IGraphqlVariables<ILeadContactAddInput>>({
    defaultValues: {
      input: {
        contactedId: undefined,
        contactedDate: new Date(),
        note: '',
      },
    },
    resolver: yupResolver(validateLeadContactAddInput),
    mode: 'onChange',
  });

  // action handlers
  const handleAddContact = async (values: IGraphqlVariables<ILeadContactAddInput>) => {
    leadContactAdd({
      filter: {
        _id: props?.lead?._id as string,
      },
      input: values.input,
    });
  };

  // useEffects.success
  useEffect(() => {
    if (leadContactAddData) {
      props?.onClose();
      enqueueSnackbar(t('crm.lead.lead.updatedSuccessfully'), {
        variant: 'success',
      });
      reset();
    }
    return () => {
      reset();
    };
  }, [leadContactAddData, reset, props, t]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(leadContactAddError as IBaseError, currentLanguage);
  }, [leadContactAddError, currentLanguage]);

  return (
    <ActionDialog
      open={props?.open}
      onClose={props?.onClose}
      title={t('crm.lead.lead.addContact')}
      buttonTitle={t('add')}
      buttonColor="secondary"
      width={500}
      handleClick={handleSubmit(handleAddContact)}
      // disabled={leadContactAddIsLoading}
      loading={leadContactAddIsLoading}
    >
      <Grid item xs={12}>
        <InputLabel sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t('crm.lead.lead.contacted')}
        </InputLabel>
        <Controller
          name="input.contactedId"
          control={control}
          render={({ field }) => (
            <Autocomplete
              noOptionsText={t('noOptionsText')}
              {...field}
              id="contactedId"
              size="small"
              isOptionEqualToValue={(option, value) => option?._id === value?._id}
              fullWidth
              getOptionLabel={(option) => option?.name + ' ' + option?.surname || ''}
              options={userListData?.data || []}
              loading={userListLoading}
              renderInput={(params) => <TextField {...params} />}
              value={
                field.value
                  ? userListData?.data?.find((user) => user._id === field.value) || null
                  : null
              }
              onChange={(e, value) => {
                field.onChange(value?._id || '');
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="date" sx={{ ml: 1, mb: 0.5, fontSize: 'small' }}>
          {t('crm.lead.lead.contactedDate')}
        </InputLabel>
        <Controller
          name="input.contactedDate"
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...field}
              value={field.value ? moment(field.value) : null}
              onChange={(newValue) => {
                field.onChange(moment(newValue).toDate());
              }}
              slotProps={{
                textField: { size: 'small', id: 'date', fullWidth: true },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ ml: 1, mb: 0.5, fontSize: 'small', color: 'grey.600' }}>
          {t('crm.noteLabel')}
        </Typography>
        <Box
          sx={{
            bgcolor: 'background.secondary',
            borderRadius: 2,
            height: '160px',
            mt: 1,
            border: '1px solid',
            borderColor: 'grey.400',
          }}
        >
          <Controller
            name="input.note"
            control={control}
            render={({ field }) => (
              <ReactQuill
                id="input.note"
                className="react-quill"
                theme="snow"
                value={field.value || ''}
                onChange={(content, delta, source, editor) => field.onChange(editor.getHTML())}
                placeholder={t('crm.preSale.estimate.description')}
                style={{ height: 130, boxSizing: 'border-box' }}
              />
            )}
          />
        </Box>
      </Grid>
    </ActionDialog>
  );
});
