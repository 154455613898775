/* eslint-disable react-hooks/exhaustive-deps */
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  DocumentTargetType,
  IAppointmentDetailResult,
  NoteTargetType,
  PermissionSubject,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { AppointmentDetailDrawerTabInfoComponent } from './AppointmentDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import {
  useAppointmentDocumentAddManyMutation,
  useAppointmentDocumentCreateManyMutation,
} from 'apps/disk/domains/document/subdomains/document';
import { Iconify } from 'components';
import { PermissionAction } from 'corede-common';

export interface IAppointmentDetailDrawerRightPanelTabsComponentProps {
  appointmentDetailData: IAppointmentDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const AppointmentDetailDrawerRightPanelTabsComponent = (
  props: IAppointmentDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  const icons = [
    <Iconify icon="fluent:info-32-regular" width={18} />,
    <Iconify icon="stash:reading-time-solid" width={18} />,
    <Iconify icon="fluent:document-32-regular" width={18} />,
  ] as React.ReactElement[];

  return (
    <DefaultTabsComponent<IAppointmentDetailResult>
      entityData={props.appointmentDetailData}
      icons={icons}
    >
      <AppointmentDetailDrawerTabInfoComponent
        appointmentDetailData={props.appointmentDetailData}
        tabKey="info"
        name="crm.info"
      />

      <NoteListTabComponent
        targetId={props.appointmentDetailData?._id ?? ''}
        targetEntityRef={NoteTargetType.Appointment}
        targetDisplayName={props.appointmentDetailData?._id.toString() ?? ''}
        tabKey="notes"
        name="crm.notes"
        permission={{
          subject: PermissionSubject.note,
          action: PermissionAction.list,
        }}
      />
      <DocumentListTabComponent
        targetId={props.appointmentDetailData?._id ?? ''}
        targetEntityRef={DocumentTargetType.Appointment}
        tabKey={'documents'}
        name={'crm.documents'}
        useDocumentCreateManyMutation={useAppointmentDocumentCreateManyMutation}
        useDocumentAddManyMutation={useAppointmentDocumentAddManyMutation}
        permission={{
          subject: PermissionSubject.file,
          action: PermissionAction.list,
        }}
      />
    </DefaultTabsComponent>
  );
};
