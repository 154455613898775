import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IEstimateDetailPublicResult } from 'corede-common-cocrm';
import moment from 'moment';

const Header = (props: { estimateData: IEstimateDetailPublicResult | undefined }) => {
  const { t } = useTranslation();
  
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      sx={{ bgcolor: '#2320200D', borderRadius: '6px', p: 3 }}
    >
      <Stack alignItems={'flex-start'} gap={3}>
        {props.estimateData?.organization?.logo ? (
          <img
            src={props.estimateData?.organization?.logo?.publicUrl}
            alt="coCRM"
            style={{
              height: '40px',
              objectFit: 'cover',
            }}
          />
        ) : (
          <Typography variant="h5">{props.estimateData?.organization?.name}</Typography>
        )}

        <Typography
          variant="body2"
          sx={{ maxWidth: '300px', color: 'text.secondary', whiteSpace: 'pre-wrap' }}
        >
          {(props.estimateData?.organization?.logo?.publicUrl
            ? props.estimateData?.organization?.name + ` \n`
            : '') +
            (props.estimateData?.organization?.addresses?.[0]?.address
              ? props.estimateData?.organization?.addresses?.[0]?.address + ' '
              : '') +
            (props.estimateData?.organization?.addresses?.[0]?.country
              ? props.estimateData?.organization?.addresses?.[0]?.country + ' '
              : '') +
            (props.estimateData?.organization?.addresses?.[0]?.state
              ? props.estimateData?.organization?.addresses?.[0]?.state + ' '
              : '') +
            (props.estimateData?.organization?.addresses?.[0]?.city
              ? props.estimateData?.organization?.addresses?.[0]?.city + ' '
              : '') +
            (props.estimateData?.organization?.addresses?.[0]?.street
              ? props.estimateData?.organization?.addresses?.[0]?.street + ' '
              : '') +
            (props.estimateData?.organization?.addresses?.[0]?.zip
              ? props.estimateData?.organization?.addresses?.[0]?.zip + ' '
              : '') +
            (props.estimateData?.organization?.addresses?.[0]?.phone
              ? props.estimateData?.organization?.addresses?.[0]?.phone
              : '')}
        </Typography>
      </Stack>

      <Stack direction={'column'} justifyContent={'space-between'} gap={3}>
        <Typography variant="h5">
          {t('auth.estimate')} - #{props.estimateData?.estimateId}
        </Typography>

        <Stack>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('auth.startDate')}:{' '}
            {props.estimateData?.date ? moment(props.estimateData?.date).format('DD/MM/YYYY') : '-'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('auth.expireDate')}:{' '}
            {props.estimateData?.expireDate
              ? moment(props.estimateData?.expireDate).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
