import { Add, PersonAdd } from '@mui/icons-material';
import {
  Stack,
  Typography,
  Box,
  AvatarGroup,
  Avatar,
  IconButton,
  Button,
  Theme,
  useMediaQuery,
  Tooltip,
  Fade,
  Grow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AvatarGroupBase from 'components/avatar/AvatarGroupBase';

type Props = {
  userListData: any;
  setOpenCreateUserDrawer: any;
  setSelectedUser: any;
  setOpenUserDetail: any;
  activeCount: number;
};

export const OrganizationalChartHeader = (props: Props) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={2} alignItems="center">
        <Grow in={true} timeout={500}>
          <Stack direction={'column'} alignItems={{ xs: 'center', md: 'flex-start' }}>
            <Typography sx={{ fontSize: { xs: '20px', md: '24px' }, fontWeight: 'bold' }}>
              {t('crm.organizationalChart.user.organizationChart')}
            </Typography>
            <Typography sx={{ fontSize: { xs: '12px', md: '14px' }, fontWeight: 'medium' }}>
              {props.activeCount}{' '}
              {(props.userListData as any)?.count > 1
                ? t('crm.organizationalChart.user.activeUsers')
                : t('crm.organizationalChart.user.activeUser')}
              {/* User Users */}
            </Typography>
          </Stack>
        </Grow>
        <Box display="flex" alignItems="center">
          <AvatarGroupBase
            userList={props.userListData?.data}
            setOpenUserDetail={props.setOpenUserDetail}
            setSelectedUser={props.setSelectedUser}
          />

          <Grow in={true} timeout={500}>
            <Tooltip title={t('crm.organizationalChart.user.addUser')}>
              <IconButton
                sx={{
                  border: '2px solid',
                  borderColor: 'primary.light',
                  width: 35,
                  height: 35,
                  ml: 1,
                  color: 'primary.main',
                }}
                onClick={() => props.setOpenCreateUserDrawer(true)}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Grow>
        </Box>
      </Stack>
    </Stack>
  );
};
