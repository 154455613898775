/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import { IBaseError } from 'corede-common';
import { IBaseDepartmentEntity } from 'corede-common-cocrm';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { useDepartmentDeleteMutation } from '../../context/department.api';
import ActionDialog from 'components/dialog/ActionDialog';

export const DepartmentDeleteDialog = memo(
  (prop: { open: boolean; item: IBaseDepartmentEntity | undefined; onClose: () => void }) => {
    // general
    const { t } = useTranslation();
    const currentLanguage = getCurrentLanguage();

    // queries

    // mutations
    const [
      departmentDelete,
      {
        data: departmentDeleteData,
        isLoading: departmentDeleteIsLoading,
        error: departmentDeleteError,
      },
    ] = useDepartmentDeleteMutation();

    // constants

    // // action handlers
    const handleDelete = (_id: string) => {
      departmentDelete({ input: { _id: _id } });
    };

    // useEffects.success
    useEffect(() => {
      if (departmentDeleteData) {
        prop?.onClose();
        enqueueSnackbar(t('crm.organizationalChart.department.deletedSuccessfully'), {
          variant: 'success',
        });
      }
      return () => {
        // Cleanup if necessary
      };
    }, [departmentDeleteData]);

    // // useEffects.error
    useEffect(() => {
      DefaultErrorHandlerUseEffect(departmentDeleteError as IBaseError, currentLanguage);
      return () => {
        // Cleanup if necessary
      };
    }, [departmentDeleteError, currentLanguage]);

    // useEffects.init

    return (
      <ActionDialog
        open={prop?.open}
        onClose={prop?.onClose}
        title={t('crm.organizationalChart.department.delete')}
        buttonTitle={t('delete')}
        handleClick={() => handleDelete(prop?.item?._id as string)}
        disabled={departmentDeleteIsLoading}
        loading={departmentDeleteIsLoading}
        buttonColor="error"
      >
        <Grid item xs={12}>
          <Typography>{t('crm.organizationalChart.department.deleteConfirm')}</Typography>
        </Grid>
      </ActionDialog>
    );
  },
);
