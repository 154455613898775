/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Stack, Typography } from '@mui/material';
import { IProjectDetailResult } from 'corede-common-cocrm';
import parse from 'html-react-parser';

interface IProjectDetailProfileSectionComponent {
  projectData: IProjectDetailResult | undefined;
}

export const ProjectDetailProfileSectionComponent = (props: IProjectDetailProfileSectionComponent) => {
  return (
    <Stack
      direction={'row'}
      alignItems={'end'}
      gap={1}
      sx={{
        mt: '-54px',
      }}
    >
      <Avatar
        sx={{
          width: '108px',
          height: '108px',
          ml: 2,
          border: '5px solid #fff',
          objectFit: 'cover',
          fontSize: '40px',
        }}
        alt={props.projectData?.name}
      >
        {props.projectData?.name
          ?.split(' ')
          ?.map((n) => n[0])
          .join('')
          .toUpperCase()}
      </Avatar>
      <Stack direction={'column'}>
        <Typography variant="h5">{props.projectData?.name} </Typography>
        <Typography variant="subtitle2" color={'text.secondary'}>
          {parse(props.projectData?.description ?? "")}
        </Typography>
      </Stack>
    </Stack>
  );
};
