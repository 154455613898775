/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DefaultTabComponentProps } from 'apps/crm/components/tabs/DefaultTabs.component';
import {
  ILeadDetailResult,
  IUserDetailResult,
  LeadQualification,
  LeadType,
} from 'corede-common-cocrm';
import { BaseDataGrid } from 'components/baseDataGrid/BaseDataGrid';
import { useLeadListQuery, useLeadUpdateMutation } from '../context/lead.api';
import { BaseGridColType } from 'components/baseDataGrid/infra/enums';
import { Default1 } from 'assets/images/avatars';
import { TCellRenderFunc } from 'components/baseDataGrid/infra/types';
import EmptyState from 'components/emptyState/EmptyState';
import { IHasId, Language } from 'corede-common';
import { useLeadSourceListQuery } from '../../leadSource/context/leadSource.api';
import { useLeadStatusListQuery } from '../../leadStatus/context/leadStatus.api';
import LeadDetailDrawerWrapper from '../pages/detailDrawer/LeadDetailDrawer.wrapper';
import LeadUpdateDrawerWrapper from '../pages/update/LeadUpdateWrapper';
import { LeadDeleteDialog } from '../pages/LeadDeleteDialog';
import { DefaultTabContainer } from 'apps/crm/components/tabs/DefaultTab.container';
import { BaseGridColumnDefFactory } from 'components/baseDataGrid/factories/base-grid-column.factory';

interface IAssociatedLeadsTabSectionComponent extends DefaultTabComponentProps<IUserDetailResult> {
  tabKey: 'associatedLeads';
  name: 'crm.organizationalChart.user.associatedLeads';
}

export const AssociatedLeadsTab = (props: IAssociatedLeadsTabSectionComponent) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [openDetail, setOpenDetail] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState<ILeadDetailResult | undefined>(undefined);

  const {
    data: leadListData,
    isLoading: leadListLoading,
    isFetching: leadListFetching,
    error: leadListError,
  } = useLeadListQuery({
    input: {
      filter: {
        assignedIds: [id || ''],
      },
    },
  });
  const { data: leadStatusesData } = useLeadStatusListQuery({});
  const { data: leadSourcesData } = useLeadSourceListQuery({});

  const [leadUpdate] = useLeadUpdateMutation();

  return (
    <DefaultTabContainer title={t('crm.organizationalChart.user.associatedLeads')}>
      {leadListData ? (
        leadListData?.count > 0 ? (
          <BaseDataGrid
            rows={
              leadListData?.data?.map((lead, index) => ({
                id: index + 1,
                _id: lead._id,
                leadInfo: {
                  fullName: lead.fullName ?? '',
                  position: lead.position ?? '',
                  email: lead.email ?? '',
                },
                fullName: lead.fullName || '-',
                position: lead.position || '-',
                email: lead.email || '-',
                website: lead.website || '-',
                phoneNumber: lead.phoneNumber || '-',
                company: lead.company || '-',
                sector: lead.sector || '-',
                status: lead.status || null,
                source: lead.source || null,
                type: lead.type || '-',
                qualification: lead.qualification || '-',
                country: lead.country || '-',
                state: lead.state || '-',
                city: lead.city || '-',
                language: lead.language || '-',
                leadValue: lead.leadValue || '-',
                organization: lead.organization._id || '-',
                department: lead.department || '-',
              })) || []
            }
            columns={[
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'id',
                    disableColumnMenu: true,
                    filterable: false,
                    width: 40,
                    align: 'center',
                  },
                  headerConfig: {
                    name: '#',
                    align: 'center',
                  },
                },
              },
              BaseGridColumnDefFactory.EntityProfileColumnDef({
                field: 'leadInfo',
                headerName: `${t('crm.name')}`,
                headerIcon: 'radix-icons:person',
              }),
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'position',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'radix-icons:crosshair-2',
                    name: t('crm.position'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'email',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'radix-icons:envelope-closed',
                    name: t('crm.email'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'phoneNumber',
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'mi:call',
                    name: t('crm.phone'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.object,
                  column: {
                    field: 'status',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'majesticons:chat-status-line',
                    name: t('crm.status'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `statusIds`,
                    isArrayFilter: true,
                  },
                  valueOptionFields: leadStatusesData?.data,
                  //   changed: ["valueOptions", "cell", "editCell", "header"],
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.object,
                  column: {
                    field: 'source',
                    editable: true,
                    width: 130,
                  },
                  headerConfig: {
                    icon: 'mdi:source-fork',
                    name: t('crm.source'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `sourceIds`,
                    isArrayFilter: true,
                  },
                  valueOptionFields: leadSourcesData?.data,
                },
                //   changed: ["valueOptions", "cell", "editCell", "header"],
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'website',
                    width: 140,
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'streamline:web',
                    name: t('crm.website'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'company',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'material-symbols:business-center-outline',
                    name: t('crm.company'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'sector',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'ic:round-business',
                    name: t('crm.sector'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'type',
                    editable: true,
                    width: 90,
                  },
                  headerConfig: {
                    icon: 'oui:app-users-roles',
                    name: t('crm.type'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `types`,
                    isArrayFilter: true,
                  },
                },
                data: LeadType,
                //   changed: ["valueOptions", "cell", "editCell"],
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'qualification',
                    editable: true,
                    width: 150,
                  },
                  headerConfig: {
                    icon: 'octicon:ruby-16',
                    name: t('crm.lead.lead.qualification'),
                    creatable: false,
                  },
                  filterConfig: {
                    customFieldName: `qualifications`,
                    isArrayFilter: true,
                  },
                },
                data: LeadQualification,
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'country',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'bi:geo-alt',
                    name: t('crm.country'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'state',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'bi:geo',
                    name: t('crm.state'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'city',
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'hugeicons:city-03',
                    name: t('crm.city'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.enum,
                  column: {
                    field: 'language',
                    width: 100,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'clarity:language-line',
                    name: t('crm.language'),
                  },
                  filterConfig: {
                    customFieldName: `languages`,
                    isArrayFilter: true,
                  },
                },
                data: Language,
                //   changed: ["valueOptions", "cell"],
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'leadValue',
                    width: 150,
                    filterable: false,
                    editable: true,
                  },
                  headerConfig: {
                    icon: 'fluent:star-32-regular',
                    name: t('crm.lead.lead.leadValue'),
                  },
                },
              },
              {
                config: {
                  baseGridColType: BaseGridColType.text,
                  column: {
                    field: 'department',
                    width: 150,
                    editable: false,
                  },
                  headerConfig: {
                    icon: 'mingcute:department-line',
                    name: t('crm.department'),
                  },
                  filterConfig: {
                    customFieldName: `departmentIds`,
                    isArrayFilter: true,
                  },
                },
              },
            ]}
            actionColumn={{
              width: 70,
              defaultActions: {
                view: {
                  clickConfig: {
                    setSelectedRow: setSelectedRow,
                    setOpenAction: setOpenDetail,
                  },
                },
                edit: {
                  clickConfig: {
                    setSelectedRow: setSelectedRow,
                    setOpenAction: setOpenUpdate,
                  },
                },
                delete: {
                  clickConfig: {
                    setSelectedRow: setSelectedRow,
                    setOpenAction: setOpenDelete,
                  },
                },
              },
              actionHeaderName: t('crm.actions'),
            }}
            loading={leadListLoading || leadListFetching}
            listFilter={{
              filterInput: {},
              setFilterInput: () => null,
            }}
            count={leadListData?.count}
            config={{
              columnVisibilityModel: {
                website: false,
                company: false,
                sector: false,
                country: false,
                state: false,
                city: false,
                language: false,
                leadValue: false,
                department: false,
              },
              features: [],
            }}
            update={{
              updateQuery: leadUpdate,
            }}
            disableColumnFilter={true}
            hideFooterPagination={true}
          />
        ) : (
          <EmptyState content1={t('crm.lead.lead.emptyState1')} />
        )
      ) : null}
      <LeadDetailDrawerWrapper
        open={openDetail}
        setOpen={setOpenDetail}
        selectedLead={selectedRow as ILeadDetailResult & IHasId<number>}
      />
      <LeadUpdateDrawerWrapper
        open={openUpdate}
        setOpen={setOpenUpdate}
        selectedLead={selectedRow as ILeadDetailResult & IHasId<number>}
      />
      <LeadDeleteDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        leadId={selectedRow?._id ?? ''}
        setLead={setSelectedRow}
      />
    </DefaultTabContainer>
  );
};
