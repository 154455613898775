const locale = {
  productCategories: 'Categories',
  title: 'Product/Service Categories',
  create: 'Create Category',
  delete: 'Delete Category',
  deleteConfirm: 'Are you sure you want to delete this status: ',
  deletedSuccessfully: 'Category deleted successfully',
  createCategory: 'Create Category',
  categoryCreateSuccess: 'Category created successfully',
  name: 'Category Name',
  icon: 'Category Icon',
  showMore: 'Show More',
  update: 'Update',
  updateCategory: 'Update Category',
  categoryUpdateSuccess: 'Category updated successfully',
  en: 'English',
  tr: 'Turkish',
  createSuccess: 'Category created successfully',
  updateSuccess: 'Product category updated successfully',
  actions: 'Actions',
  createdAt: 'Created At',
  emptyState1: 'No categories found',
  emptyState2: 'Please create new category',
  createProductCategory: 'Create Product Category',
};

export default locale;
