/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { IProjectDetailResult, ProjectStatus } from 'corede-common-cocrm';

import { getTranslatedEnumValue } from 'localization';

interface IProjectDetailHeaderStatusComponentProps {
  projectDetailData: IProjectDetailResult | undefined;
}

export const ProjectDetailStatusComponent = (props: IProjectDetailHeaderStatusComponentProps) => {
  const downSm = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  return downSm ? null : (
    <Stack direction="row" spacing={0} sx={{ borderRadius: '16px', overflow: 'hidden', mb: 1 }}>
      {Object.entries(ProjectStatus).map(([key, value], index) => (
        <Box
          key={key}
          sx={{
            backgroundColor:
              value === props.projectDetailData?.status ? 'secondary.main' : 'secondary.extraLight',
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            justifyContent: 'center',
            py: 1,
            px: 3,
            ...(index === 0 && { borderTopLeftRadius: '16px', borderBottomLeftRadius: '16px' }),
            ...(index === Object.entries(ProjectStatus).length - 1 && {
              borderTopRightRadius: '16px',
              borderBottomRightRadius: '16px',
            }),
            flexBasis: `calc(100% / ${Object.entries(ProjectStatus).length})`,
          }}
        >
          <Typography
            fontSize={'12px'}
            sx={{
              color: value === props.projectDetailData?.status ? 'white' : 'secondary.main',
              px: 1,
            }}
          >
            {getTranslatedEnumValue(value as ProjectStatus)}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};
