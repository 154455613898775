import { Stack, Box, Typography, Theme, useMediaQuery, CircularProgress } from '@mui/material';
import { Iconify } from 'components';
import { PermissionSubject } from 'corede-common-cocrm';
import { usePermissions } from 'permission/PermissionContext';
import { useTranslation } from 'react-i18next';
import { memo, useCallback } from 'react';
import { ApolloClient, ApolloProvider, gql, InMemoryCache, useQuery } from '@apollo/client';
import { useAppSelector } from 'context';

const DASHBOARD_STATISTICS = gql`
  query dashboardEntityCounts {
    totalLeadCount: leadListCount {
      count
    }
    totalCustomerCount: customerListCount {
      count
    }
    totalEstimateCount: estimateListCount {
      count
    }
    totalProposalCount: proposalListCount {
      count
    }
  }
`;

const StatisticsComponent = () => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { hasPermission } = usePermissions();

  const { loading, error, data: statisticData } = useQuery(DASHBOARD_STATISTICS);

  const data = useCallback(() => {
    const tempData = [];
    if (
      hasPermission({
        subject: PermissionSubject.lead,
      })
    ) {
      tempData.push({
        icon: 'material-symbols-light:social-leaderboard-outline-rounded',
        title: t(`crm.lead.lead.leads`),
        value: statisticData?.totalLeadCount?.count || 0,
        color: 'tertiary',
      });
    }
    if (
      hasPermission({
        subject: PermissionSubject.customer,
      })
    ) {
      tempData.push({
        icon: 'solar:user-linear',
        title: t(`crm.customer.customer.customers`),
        value: statisticData?.totalCustomerCount?.count || 0,
        color: 'info',
      });
    }
    if (
      hasPermission({
        subject: PermissionSubject.estimate,
      })
    ) {
      tempData.push({
        icon: 'mage:preview',
        title: t(`crm.preSale.estimate.title`),
        value: statisticData?.totalEstimateCount?.count || 0,
        color: 'secondary',
      });
    }
    if (
      hasPermission({
        subject: PermissionSubject.proposal,
      })
    ) {
      tempData.push({
        icon: 'mdi:file-document-outline',
        title: t(`crm.preSale.proposal.title`),
        value: statisticData?.totalProposalCount?.count || 0,
        color: 'success',
      });
    }
    return tempData;
  }, [t, statisticData]);

  return (
    <Stack
      direction={'column'}
      gap={4}
      p={2}
      sx={{ borderRadius: 3, border: '1px solid', borderColor: 'divider', boxSizing: 'border-box' }}
      flexBasis={'66%'}
      width={'100%'}
    >
      <Stack direction={'row'} justifyContent={'space-between'} gap={1} width={'100%'}>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {t('crm.dashboard.dashboard.statistics')}
        </Typography>
        {/* <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Updated 1 month ago
        </Typography> */}
      </Stack>

      <Stack direction={downMd ? 'column' : 'row'} gap={2}>
        {data().map((data) => (
          <Box sx={{ width: downMd ? '100%' : '25%' }}>
            <Stack direction={'row'} alignItems={'center'} gap={{ xs: 1, md: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 54,
                  height: 54,
                  borderRadius: '6px',
                  backgroundColor: `${data.color}.extraLight`,
                }}
              >
                <Iconify icon={data.icon} sx={{ color: `${data.color}.main` }} width={32} />
              </Box>
              <Box>
                <Typography variant={'body1'} sx={{ fontWeight: '500' }}>
                  {data.value}
                </Typography>
                <Typography variant={'caption'} color="text.secondary" sx={{ fontWeight: '500' }}>
                  {data.title}
                </Typography>
              </Box>
            </Stack>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

const Statistics = () => {
  const { accessToken } = useAppSelector((state) => state.auth);

  if (!accessToken) {
    return <CircularProgress />;
  }

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
    cache: new InMemoryCache(),
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });

  return (
    <ApolloProvider client={client}>
      <StatisticsComponent />
    </ApolloProvider>
  );
};

export default memo(Statistics);
