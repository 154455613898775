const locale = {
  title: 'Sözleşme Şablonu',
  emptyState1: 'Sözleşme şablonu yok.',
  emptyState2:
    'Faturalandırmanızı kolaylaştırmak ve müşterilerinizle net anlaşmalar sürdürmek için tüm sözleşme ayrıntılarını burada saklayın ve yönetin.',
  create: 'Sözleşme Şablonu Oluştur',
  update: 'Sözleşme Şablonunu Güncelle',
  delete: 'Sözleşme Şablonunu Sil',
  createSuccess: 'Sözleşme şablonu oluşturuldu.',
  updateSuccess: 'Sözleşme şablonu güncellendi.',
  deleteSuccess: 'Sözleşme şablonu silindi.',
  deleteConfirm: 'Sözleşme şablonunu silmek istediğinize emin misiniz: ',
};

export default locale;
