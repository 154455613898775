import { Grid, Stack, Typography, Box, useMediaQuery, Theme } from '@mui/material';
import { listPricingRoute } from 'apps/crm/domains/16-pricing/routes/pricing.base.route';
import { FilledButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOrganizationDetailOwnQuery } from 'apps/settings/domains/settings/subdomains/settings';
import DashboardImage from 'assets/images/dashboard.png';
import { memo, useCallback } from 'react';

interface IHomeForRestricted {}

const HomeForRestricted = memo((props: IHomeForRestricted) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: organizationDetailOwn } = useOrganizationDetailOwnQuery({});

  const trialStatus = organizationDetailOwn?.activeSubscriptions?.trial?.status;
  const mainStatus = organizationDetailOwn?.activeSubscriptions?.main?.status;
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const handleNavigate = useCallback(() => {
    navigate(listPricingRoute());
  }, [navigate]);

  return (
    <>
      <Grid item xs={12} lg={6}>
        <Stack direction={'column'} justifyContent={'center'} gap={2}>
          <Typography variant="h4">{t('crm.dashboard.dashboard.title')}</Typography>

          <Typography variant="body1">{t('crm.dashboard.dashboard.description')}</Typography>

          {trialStatus === 'expired' && (
            <Typography variant="body1" color={'secondary.main'}>
              {t('crm.dashboard.dashboard.trialExpired')}
            </Typography>
          )}
          {mainStatus === 'failed' && (
            <Typography variant="body1" color={'secondary.main'}>
              {t('crm.dashboard.dashboard.mainFailed')}
            </Typography>
          )}
          {mainStatus === 'completed' && (
            <Typography variant="body1" color={'secondary.main'}>
              {t('crm.dashboard.dashboard.mainCompleted')}
            </Typography>
          )}

          <Typography variant="h5">{t('crm.dashboard.dashboard.featuresTitle')}</Typography>

          <ul style={{ margin: 0 }}>
            {[1, 2, 3, 4].map((item, index) => (
              <li key={index} style={{ marginBottom: '8px' }}>
                <Typography variant="body1">
                  {t(`crm.dashboard.dashboard.features${item}`)}
                </Typography>
              </li>
            ))}
          </ul>

          <Box
            sx={{
              animation: 'bounce 2s ease-in-out infinite',
              '@keyframes bounce': {
                '0%, 100%': {
                  transform: 'translateY(0)',
                },
                '50%': {
                  transform: 'translateY(-5px)',
                },
              },
            }}
          >
            <FilledButton
              color="secondary"
              title={t('crm.dashboard.dashboard.buttonTitle')}
              onClick={handleNavigate}
            />
          </Box>
        </Stack>
      </Grid>

      {!downMd && (
        <Grid item xs={12} lg={5}>
          <Stack alignItems={'end'}>
            <img
              src={DashboardImage}
              alt="Dashboard"
              width={'100%'}
              style={{ maxWidth: '416px' }}
            />
          </Stack>
        </Grid>
      )}
    </>
  );
});

export default HomeForRestricted;
