/* eslint-disable react-hooks/exhaustive-deps */
import { useProjectDetailQuery } from '../../context/project.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { IProject, IProjectDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { ProjectDetailDrawerLeftPanelComponent } from './ProjectDetailDrawer-leftPanel.component';
import { ProjectDetailDrawerRightPanelComponent } from './ProjectDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IProjectDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedProject: IProjectDetailResult & IHasId<number>;
  setSelectedProject: Dispatch<SetStateAction<TListGridSelectedEntity<IProject>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const ProjectDetailDrawer = memo((props: IProjectDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: projectDetailData, error: projectDetailError } = useProjectDetailQuery(
    props?.selectedProject?._id
      ? {
          input: {
            _id: props?.selectedProject?._id,
          },
        }
      : skipToken,
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(projectDetailError as IBaseError, currentLanguage);
  }, [projectDetailError]);

  return (
    <DefaultDetailDrawer<IProjectDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedProject}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.project.project.title'),
            }
      }
    >
      <ProjectDetailDrawerLeftPanelComponent
        projectDetailData={projectDetailData}
        setOpen={props.setOpen}
      />
      <ProjectDetailDrawerRightPanelComponent projectDetailData={projectDetailData} />
    </DefaultDetailDrawer>
  );
});

export default ProjectDetailDrawer;
