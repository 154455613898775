import { Cancel, ExpandCircleDown } from '@mui/icons-material';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { IFileListResult } from 'corede-common-cocrm';
import EmptyState from 'components/emptyState/EmptyState';
import { FileTargetType } from 'components/uploadFile/UploadDocument';
import { FilesGridListCard } from './FilesGridList-card.component';
import { useTranslation } from 'react-i18next';

const FilesGridList = (props: {
  fileData: IFileListResult | undefined;
  title?: FileTargetType;
  selectedFolder?: FileTargetType;
  setSelectedFolder?: (folder: FileTargetType | undefined) => void;
  disableShow?: boolean;
  smallSize?: boolean;
}) => {
  const [showFiles, setShowFiles] = useState(true);
  const { t } = useTranslation();

  return (
    <Grid container width={'100%'}>
      <Grid item xs={12}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'column'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={1}>
              <Typography fontSize={'16px'} fontWeight={600}>
                {props.title
                  ? props.title.slice(0, 1).toUpperCase() + props.title.slice(1)
                  : t('disk.document.document.recentFiles')}
              </Typography>
              {props?.selectedFolder && (
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={() => props.setSelectedFolder && props.setSelectedFolder(undefined)}
                >
                  <Cancel fontSize="small" />
                </IconButton>
              )}
            </Stack>
            <Stack>
              <Typography fontSize={'12px'} color={'GrayText'} fontWeight={600}>
                {props.fileData?.count} {props.fileData?.count === 1 ? t('disk.document.document.file') : t('disk.document.document.files')}
              </Typography>
            </Stack>
          </Stack>
          {/* <Stack>
            <IconButton color="primary" size="small" onClick={() => setShowFiles(!showFiles)}>
              <ExpandCircleDown
                fontSize="medium"
                sx={{ transform: showFiles ? 'rotate(0deg)' : 'rotate(180deg)' }}
              />
            </IconButton>
          </Stack> */}
        </Stack>
      </Grid>
      <Grid container mt={2} spacing={2} sx={{ display: showFiles ? 'flex' : 'none' }}>
        {props.fileData && props.fileData?.count > 0 ? (
          props.fileData?.data?.map((file, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <FilesGridListCard
                file={file}
                disableShow={props.disableShow}
                smallSize={props.smallSize}
              />
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <EmptyState content1={'No files found'} content2={'No files found'} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FilesGridList;
