import { Route, Routes } from 'react-router-dom';

import { List } from '../pages';

import { ContractLayout } from '../layouts/ContractLayout';

export const ContractRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ContractLayout />}>
        <Route index element={<List />} />
        <Route path="*" element={<List />} />
      </Route>
    </Routes>
  );
};
