import {
  Stack,
  Typography,
  LinearProgress,
  Checkbox,
  Autocomplete,
  Grid,
  InputLabel,
  TextField,
  CircularProgress,
} from '@mui/material';
import {
  ITaskDetailResult,
  IUser,
  PermissionSubject,
  UserRelatedTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import {
  useTaskChecklistItemAddMutation,
  useTaskChecklistItemCheckMutation,
  useTaskChecklistItemUnCheckMutation,
  useTaskChecklistItemDeleteMutation,
} from '../../context/task.api';
import { ToggleButton } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { AddCircle } from '@mui/icons-material';
import ActionDialog from 'components/dialog/ActionDialog';
import { useUserListByRelatedQuery } from 'apps/crm/domains/02-organizationalChart/subdomains/user';
import { usePermissions } from 'permission/PermissionContext';
import { PermissionAction } from 'corede-common';
import { PermissionWrapper } from 'permission/PermissionWrapper';

export const DetailDrawerChecklistComponent = (props: {
  taskDetailData: ITaskDetailResult | undefined;
}) => {
  const { t } = useTranslation();

  const [openAddSubTaskDialog, setOpenAddSubTaskDialog] = useState(false);
  const [openDeleteSubTaskDialog, setOpenDeleteSubTaskDialog] = useState(false);
  const [subTask, setSubTask] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState<IUser | undefined>(undefined);

  const { hasPermission } = usePermissions();
  const hasUpdatePermission = hasPermission({
    subject: PermissionSubject.task,
    action: PermissionAction.update,
  });

  const {
    data: userListData,
    isLoading: userListLoading,
    error: userListError,
  } = useUserListByRelatedQuery({
    input: {
      filter: {
        relatedTargetType: UserRelatedTargetType.Task,
      },
    },
  });

  const [itemAdd, { data: itemAddData, isLoading: itemAddLoading }] =
    useTaskChecklistItemAddMutation();
  const [itemCheck, { isLoading: itemCheckLoading }] = useTaskChecklistItemCheckMutation();
  const [itemUnCheck, { data: itemUnCheckData, isLoading: itemUnCheckLoading }] =
    useTaskChecklistItemUnCheckMutation();
  const [itemDelete, { data: itemDeleteData, isLoading: itemDeleteLoading }] =
    useTaskChecklistItemDeleteMutation();

  const filteredUserList = useMemo(() => {
    if (!props.taskDetailData?.assignees) return [];
    return userListData?.data?.filter((user) => {
      return props.taskDetailData?.assignees?.find((assignedUser) => assignedUser._id === user._id);
    });
  }, [props.taskDetailData?.assignees, userListData?.data]);

  useEffect(() => {
    if (itemAddData) {
      setOpenAddSubTaskDialog(false);
      setSubTask('');
      setSelectedAssignee(undefined);
    }
  }, [itemAddData]);

  return (
    <>
      <Stack
        direction={'column'}
        alignItems={'flex-start'}
        sx={{ px: 2, py: 1, mb: 1, position: 'relative' }}
      >
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          gap={1}
          mb={1}
          width={'100%'}
        >
          <Typography sx={{ fontWeight: 600, fontSize: '16px', color: 'text.primary' }}>
            {t('crm.task.task.checklist')}
          </Typography>
          {itemCheckLoading || itemUnCheckLoading || itemDeleteLoading ? (
            <CircularProgress size={20} />
          ) : (
            <CircularProgress size={20} sx={{ visibility: 'hidden' }} />
          )}
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            gap={1}
            sx={{ flex: 1, pr: 1 }}
          >
            <LinearProgress
              variant="buffer"
              value={
                ((props.taskDetailData?.checklist?.filter((item) => item.isChecked).length ?? 1) /
                  (props.taskDetailData?.checklist?.length ?? 1)) *
                100
              }
              valueBuffer={100}
              color="secondary"
              sx={{
                width: '90%',
                borderRadius: 5,
              }}
            />
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '12px',
                color: 'text.primary',
              }}
            >
              {props.taskDetailData?.checklist?.filter((item) => item.isChecked).length ?? 0} /{' '}
              {props.taskDetailData?.checklist?.length ?? 0}
            </Typography>
          </Stack>
        </Stack>
        {props.taskDetailData?.assignees && props.taskDetailData?.assignees?.length > 0 ? (
          <>
            {props.taskDetailData?.checklist?.map((item, index) => (
              <Stack key={index} direction="row" alignItems="center" gap={0.5} sx={{ mb: 1 }}>
                <Checkbox
                  checked={item.isChecked}
                  size="small"
                  onChange={() => {
                    if (item.isChecked) {
                      itemUnCheck({
                        input: { taskId: props.taskDetailData?._id ?? '', checklistId: item._id },
                      });
                    } else {
                      itemCheck({
                        input: { taskId: props.taskDetailData?._id ?? '', checklistId: item._id },
                      });
                    }
                  }}
                  color="secondary"
                  disabled={!hasUpdatePermission}
                />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: item.isChecked ? 'text.primary' : 'text.secondary',
                  }}
                >
                  {item.description}
                </Typography>
              </Stack>
            ))}
            <PermissionWrapper
              check={{
                subject: PermissionSubject.task,
                action: PermissionAction.update,
              }}
            >
              <ToggleButton
                onClick={() => setOpenAddSubTaskDialog(true)}
                bgcolor="background.thirdWithBlur"
                title={t('crm.task.task.subtask')}
                icon={<AddCircle sx={{ mr: 1 }} />}
              />

              <ActionDialog
                open={openAddSubTaskDialog}
                onClose={() => {
                  setSubTask('');
                  setSelectedAssignee(undefined);
                  setOpenAddSubTaskDialog(false);
                }}
                title={t('crm.task.task.addSubtask')}
                loading={itemAddLoading}
                disabled={itemAddLoading}
                buttonTitle={t('add')}
                width={500}
                handleClick={() => {
                  itemAdd({
                    filter: {
                      taskId: props.taskDetailData?._id ?? '',
                    },
                    input: {
                      checklistItem: {
                        assigneeId: selectedAssignee?._id ?? '',
                        description: subTask,
                      },
                    },
                  });
                }}
              >
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <InputLabel sx={{ ml: 1, mb: 0.5 }}>{t('crm.description')}</InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    value={subTask}
                    onChange={(e) => setSubTask(e.target.value)}
                    rows={2}
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <InputLabel sx={{ ml: 1, mb: 0.5 }}>{t('crm.task.task.assignee')}</InputLabel>
                  <Autocomplete
                    noOptionsText={t('noOptionsText')}
                    size="small"
                    isOptionEqualToValue={(option, value) => option?._id === value?._id}
                    fullWidth
                    getOptionLabel={(option) => option?.name + ' ' + option?.surname || ''}
                    options={filteredUserList || []}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={(event, value) => {
                      setSelectedAssignee(value as IUser);
                    }}
                  />
                </Grid>
              </ActionDialog>
            </PermissionWrapper>
          </>
        ) : (
          <Stack>
            <Typography sx={{ fontWeight: 400, fontSize: '16px', color: 'text.secondary' }}>
              {t('crm.task.task.noAssigneeToCreateChecklist')}
            </Typography>
          </Stack>
        )}
      </Stack>
    </>
  );
};
