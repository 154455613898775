/* eslint-disable react-hooks/exhaustive-deps */
import { SwipeableDrawer, Box, Stack, Typography, Button, Divider, CircularProgress, Grid } from '@mui/material'
import { RichTreeViewPro } from '@mui/x-tree-view-pro/RichTreeViewPro'
import { TreeViewBaseItem } from '@mui/x-tree-view/models'
import { useOrganizationChartUpdateMutation } from 'apps/crm/domains/02-organizationalChart/subdomains/user'
import ActionDrawer from 'components/drawer/ActionDrawer'
import { InfoBox } from 'components/infoBox'
import { unknownError } from 'corede-common'
import { getCurrentLanguage } from 'localization'
import { enqueueSnackbar } from 'notistack'
import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IChartEditDrawer {
    open: boolean
    setOpen: (open: boolean) => void
    orgChartData: any
    setConvertedChart: (convertedChart: any) => void
}


const ChartEditDrawer = memo((props: IChartEditDrawer) => {

    const { t } = useTranslation();
    const [organizationChartUpdate, { data: organizationChartUpdateData, isLoading: organizationChartUpdateLoading, error: organizationChartUpdateError }] = useOrganizationChartUpdateMutation();
    const currentLanguage = getCurrentLanguage();
    const [items, setItems] = useState<TreeViewBaseItem[]>(() => [transformToTreeViewItems(props.orgChartData ?? {})]);
    const [expandedItems, setExpandedItems] = useState<string[]>([items[0]?.id]);


    function transformToTreeViewItems(node: any) {
        const { user, children } = node;
        const label = `${user?.name || ''} ${user?.surname || ''}`.trim();

        return {
            id: user?._id || Math.random().toString(36).substr(2, 9),
            label: label?.length > 0 ? label : t("crm.organizationalChart.user.undefinedUser"),
            children: children ? children.map((child: any) => transformToTreeViewItems(child)) : [],
        };
    }

    useEffect(() => {
        setItems([transformToTreeViewItems(props.orgChartData ?? {})]);
        return () => {
            setItems([]);
        };
    }, [props.orgChartData]);

    useEffect(() => {
        if (organizationChartUpdateError) {
            enqueueSnackbar((organizationChartUpdateError as any)?.error?.message[currentLanguage] ?? unknownError.message[currentLanguage], { variant: 'error' });
        }
        return () => {
            // Cleanup if necessary
        };
    }, [organizationChartUpdateError, currentLanguage]);

    const convertLikeOrgChart = (items: any) => {
        const result = items.map((item: any) => {
            return {
                user: {
                    _id: item?.id,
                },
                children: item?.children ? convertLikeOrgChart(item.children) : [],
            };
        });
        return result;
    };

    const handleItemPositionChange = (params: any) => {
        const { itemId, newPosition } = params;

        const findAndRemoveItem = (items: any, itemId: any) => {
            let itemToRemove = null;
            const updatedItems = items.filter((item: any) => {
                if (item?.id === itemId) {
                    itemToRemove = item;
                    return false;
                }
                if (item.children) {
                    const result = findAndRemoveItem(item.children, itemId);
                    if (result.itemToRemove) {
                        itemToRemove = result.itemToRemove;
                        item.children = result.updatedItems;
                    }
                }
                return true;
            });
            return { updatedItems, itemToRemove };
        };

        const insertItemAtPosition = (items: any, item: any, position: any) => {
            if (position.parentId === null) {
                items.splice(position.index, 0, item);
                return items;
            }
            return items.map((i: any) => {
                if (i?.id === position.parentId) {
                    i.children = i.children || [];
                    i.children.splice(position.index, 0, item);
                } else if (i.children) {
                    i.children = insertItemAtPosition(i.children, item, position);
                }
                return i;
            });
        };

        const { updatedItems, itemToRemove } = findAndRemoveItem(items, itemId);
        const newItems = insertItemAtPosition(updatedItems, itemToRemove, newPosition);

        setItems(newItems); // items'ı güncelle
    };

    const handleNodeToggle = (nodeId: string) => {
        if (expandedItems.includes(nodeId)) {
            setExpandedItems(expandedItems.filter(id => id !== nodeId));
        } else {
            setExpandedItems([...expandedItems, nodeId]);
        }
    };

    useEffect(() => {
        if (organizationChartUpdateData) {
            enqueueSnackbar(t("crm.organizationalChart.user.chartUpdateSuccess"), { variant: 'success' });
        }
        return () => {
            // Cleanup if necessary
        };
    }, [organizationChartUpdateData, t]);


    return <ActionDrawer
        open={props.open}
        setOpen={props.setOpen}
        title={t("crm.organizationalChart.user.editOrganizationChart")}
        handleSubmit={() => {
            organizationChartUpdate({
                input: {
                    chart: convertLikeOrgChart(items)?.[0]

                }
            })
            props.setOpen(false);
        }}
        disabled={organizationChartUpdateLoading}
        loading={organizationChartUpdateLoading}
        size='small'
        buttonTitle={t("crm.organizationalChart.user.save")}
    >
        <Grid item xs={12} className='chart-nav'>
            <InfoBox content={t("crm.organizationalChart.user.editOrganizationDetail")} />
            <Stack spacing={2}>
                <Box sx={{ minHeight: 352, minWidth: 300 }}>
                    <RichTreeViewPro
                        items={items}
                        itemsReordering
                        sx={{
                            color: 'text.primary',
                            '& .MuiCollapse': {
                                borderLeft: `0px`,
                            }
                        }}
                        experimentalFeatures={{
                            indentationAtItemLevel: true,
                            itemsReordering: true,
                        }}
                        defaultExpandedItems={expandedItems}
                        onItemPositionChange={handleItemPositionChange}
                    />
                </Box>
            </Stack>
        </Grid>
    </ActionDrawer>;
});

export default ChartEditDrawer;