import {
  IReminderListInput,
  IReminderListRequest,
  IReminderListResponse,
  IReminderListResult,
  reminderListQuery,
  IReminderListCountRequest,
  IReminderListCountResponse,
  reminderListCountQuery,
  IReminderDetailInput,
  IReminderDetailRequest,
  IReminderDetailResponse,
  IReminderDetailResult,
  reminderDetailQuery,
  IReminderCreateInput,
  IReminderCreateRequest,
  IReminderCreateResult,
  IReminderCreateResponse,
  reminderCreateQuery,
  IReminderUpdateInput,
  IReminderUpdateRequest,
  IReminderUpdateResult,
  IReminderUpdateResponse,
  IReminderUpdateFilterInput,
  reminderUpdateQuery,
  IReminderDeleteInput,
  IReminderDeleteRequest,
  IReminderDeleteResult,
  IReminderDeleteResponse,
  reminderDeleteQuery,
  reminderCancelQuery,
  IReminderCancelRequest,
  IReminderCancelInput,
  IReminderCancelResponse,
  IReminderCancelResult,
  reminderCompleteQuery,
  IReminderCompleteRequest,
  IReminderCompleteInput,
  IReminderCompleteResponse,
  IReminderCompleteResult,
  reminderScheduleQuery,
  IReminderScheduleRequest,
  IReminderScheduleInput,
  IReminderScheduleResponse,
  IReminderScheduleResult,
  IReminderScheduleFilterInput,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { remindersTags } from './reminder.tags';
import { ICount, IGraphqlVariables } from 'corede-common';

export const remindersApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    reminderList: builder.query<IReminderListResult, IGraphqlVariables<IReminderListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderListRequest,
        IReminderListResponse,
        IReminderListResult,
        IReminderListInput
      >({
        query: reminderListQuery,
        providesTags: [remindersTags.reminder, remindersTags.reminders],
      }),
    ),

    reminderListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderListCountRequest,
        IReminderListCountResponse,
        ICount,
        undefined
      >({
        query: reminderListCountQuery,
        providesTags: [remindersTags.reminder, remindersTags.reminders],
      }),
    ),

    reminderDetail: builder.query<IReminderDetailResult, IGraphqlVariables<IReminderDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderDetailRequest,
        IReminderDetailResponse,
        IReminderDetailResult,
        IReminderDetailInput
      >({
        query: reminderDetailQuery,
        providesTags: [remindersTags.reminder],
      }),
    ),

    // mutation

    reminderCreate: builder.mutation<
      IReminderCreateResult,
      IGraphqlVariables<IReminderCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderCreateRequest,
        IReminderCreateResponse,
        IReminderCreateResult,
        IReminderCreateInput
      >({
        query: reminderCreateQuery,
        invalidatesTags: [remindersTags.reminder, remindersTags.reminders],
      }),
    ),

    reminderUpdate: builder.mutation<
      IReminderUpdateResult,
      IGraphqlVariables<IReminderUpdateInput, IReminderUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderUpdateRequest,
        IReminderUpdateResponse,
        IReminderUpdateResult,
        IReminderUpdateInput,
        IReminderUpdateFilterInput
      >({
        query: reminderUpdateQuery,
        invalidatesTags: [remindersTags.reminder, remindersTags.reminders],
      }),
    ),

    reminderDelete: builder.mutation<
      IReminderDeleteResult,
      IGraphqlVariables<IReminderDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderDeleteRequest,
        IReminderDeleteResponse,
        IReminderDeleteResult,
        IReminderDeleteInput
      >({
        query: reminderDeleteQuery,
        invalidatesTags: [remindersTags.reminders],
      }),
    ),

    reminderCancel: builder.mutation<
      IReminderCancelResult,
      IGraphqlVariables<IReminderCancelInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderCancelRequest,
        IReminderCancelResponse,
        IReminderCancelResult,
        IReminderCancelInput
      >({
        query: reminderCancelQuery,
        invalidatesTags: [remindersTags.reminders, remindersTags.reminder],
      }),
    ),

    reminderComplete: builder.mutation<
      IReminderCompleteResult,
      IGraphqlVariables<IReminderCompleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderCompleteRequest,
        IReminderCompleteResponse,
        IReminderCompleteResult,
        IReminderCompleteInput
      >({
        query: reminderCompleteQuery,
        invalidatesTags: [remindersTags.reminders, remindersTags.reminder],
      }),
    ),

    reminderSchedule: builder.mutation<
      IReminderScheduleResult,
      IGraphqlVariables<IReminderScheduleInput, IReminderScheduleFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IReminderScheduleRequest,
        IReminderScheduleResponse,
        IReminderScheduleResult,
        IReminderScheduleInput,
        IReminderScheduleFilterInput
      >({
        query: reminderScheduleQuery,
        invalidatesTags: [remindersTags.reminders, remindersTags.reminder],
      }),
    ),
  }),
});

export const {
  useReminderListQuery,
  useReminderDetailQuery,
  useReminderCreateMutation,
  useReminderUpdateMutation,
  useReminderDeleteMutation,
  useReminderCancelMutation,
  useReminderCompleteMutation,
  useReminderScheduleMutation,
} = remindersApi;
