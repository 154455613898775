/* eslint-disable react-hooks/exhaustive-deps */
import ReminderListTabComponent from 'apps/crm/domains/12-calendar/subdomains/reminder/components/ReminderListTabComponent';
import NoteListTabComponent from 'apps/crm/domains/14-note/subdomains/note/components/NoteListTabComponent';
import {
  IReminderDetailResult,
  NoteTargetType,
  ProposalProspectTargetEntityRef,
  ReminderTargetType,
  TaskTargetType,
} from 'corede-common-cocrm';
import { useTranslation } from 'react-i18next';
import { DefaultEmptyTabComponent } from '../../../../../../components/tabs/DefaultTab.emptyState.component';
import { DefaultTabsComponent } from '../../../../../../components/tabs/DefaultTabs.component';
import { ReminderDetailDrawerTabInfoComponent } from './ReminderDetailDrawer-tab-infoSection.component';
import TaskListTabComponent from 'apps/crm/domains/11-task/subdomains/task/pages/components/TaskTabComponent';
import DocumentListTabComponent from 'apps/disk/domains/document/subdomains/document/components/DocumentTabComponent';
import { Iconify } from 'components';
export interface IReminderDetailDrawerRightPanelTabsComponentProps {
  reminderDetailData: IReminderDetailResult | undefined;
}

/**
 * Container for views in left panel header
 */
export const ReminderDetailDrawerRightPanelTabsComponent = (
  props: IReminderDetailDrawerRightPanelTabsComponentProps,
) => {
  const { t } = useTranslation();

  const icons = [
    <Iconify icon="fluent:info-32-regular" width={18} />,
    <Iconify icon="hugeicons:contact" width={18} />,
    <Iconify icon="hugeicons:task-01" width={18} />,
    <Iconify icon="carbon:product" width={18} />,
    <Iconify icon="fluent:document-32-regular" width={18} />,
    <Iconify icon="iconoir:bell" width={18} />,
    <Iconify icon="tabler:activity" width={18} />,
  ] as React.ReactElement[];

  return (
    // <DefaultTabsComponent<IReminderDetailResult>
    //   entityData={props.reminderDetailData}
    //   icons={icons}
    // >
    <ReminderDetailDrawerTabInfoComponent
      reminderDetailData={props.reminderDetailData}
      tabKey="info"
      name="crm.info"
    />
  );
};
