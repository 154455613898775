import { ExpandMore, InfoOutlined } from '@mui/icons-material';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import { IPermission, PermissionDomain, PermissionSubdomain } from 'corede-common-cocrm';
import { getTranslatedEnumValue } from 'localization';
import { useTranslation } from 'react-i18next';
import { PermissionSubDomainAccordionComponent } from './PermissionSubdomainAccordion';
import { PermissionAccordionDetails } from './PermissionAccordionDetailsContainer';
import { PermissionAccordion } from './PermissionAccordionContainer';
import { PermissionAccordionSummary } from './PermissionAccordionSummaryContainer';
import { Dispatch, SetStateAction } from 'react';
import { subDomainsInOrder } from './PermissionList.order.constants';
import { IUseStatePropagationProp } from 'interfaces/useState-propagation-props.interface';

export interface IPermissionDomainAccordionComponentProps {
  domain: PermissionDomain;
  permissions: IPermission[];
  downMd: boolean;
  downLg: boolean;
  expandedAccordions: Record<string, boolean>;
  setExpandedAccordions: Dispatch<SetStateAction<Record<string, boolean>>>;
  selectedPermissionIds: string[];
  rolePermissionIds: string[];
  handleChangeCheckbox: (permissionId: string) => void;
  openedPermissionsState: IUseStatePropagationProp<string[]>;
  hideChips?: boolean;
}

export const PermissionDomainAccordionComponent = (
  props: IPermissionDomainAccordionComponentProps,
) => {
  const { t } = useTranslation();

  console.log(props.hideChips)
  const subdomains = subDomainsInOrder.get(props.domain) ?? [];
  const subdomainToPermissionsMap: Map<PermissionSubdomain, IPermission[]> = new Map();
  subdomains.forEach((s) => {
    subdomainToPermissionsMap.set(
      s,
      props.permissions.filter((p) => p.subdomain === s),
    );
  });

  return (
    <PermissionAccordion
      key={props.domain}
      expanded={props.expandedAccordions[props.domain] || false}
      className="accordion-domain"
      onChange={() => {
        props.setExpandedAccordions((prevState) => ({
          ...prevState,
          [props.domain]: !prevState[props.domain],
        }));
      }}
    >
      <PermissionAccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls={`panel-${props.domain}-content`}
        id={`panel-${props.domain}-header`}
      >
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Typography fontSize={'large'} fontWeight={'bold'}>
            {getTranslatedEnumValue(props.domain)}
          </Typography>
          {!props.downLg && !props.hideChips && (
            <Stack direction="row" spacing={1}>
              {' '}
              {subdomains.map((subdomain) => (
                <Chip
                  key={subdomain}
                  label={getTranslatedEnumValue(subdomain)}
                  // size="small"
                  sx={{ fontWeight: 'bold', opacity: 0.7 }}
                  variant="outlined"
                  color="primary"
                />
              ))}
            </Stack>
          )}
        </Stack>
      </PermissionAccordionSummary>
      <PermissionAccordionDetails sx={{}} downMd={props.downMd}>
        {!props.downLg && (
          // The header for permission action scopes
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={'flex-end'}
            sx={{ width: '100%' }}
          >
            <Stack direction={'row'} width={'170px'} alignItems={'center'}>
              <Typography fontSize={'small'} fontWeight={'bold'}>
                {t('organizationLevel')}
              </Typography>
              <Tooltip title={t('organizationLevelDesc')}>
                <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
              </Tooltip>
            </Stack>
            <Stack direction={'row'} width={'170px'} alignItems={'center'}>
              <Typography fontSize={'small'} fontWeight={'bold'}>
                {t('departmentLevel')}
              </Typography>
              <Tooltip title={t('departmentLevelDesc')}>
                <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
              </Tooltip>
            </Stack>
            <Stack direction={'row'} width={'170px'} alignItems={'center'}>
              <Typography fontSize={'small'} fontWeight={'bold'}>
                {t('userLevel')}
              </Typography>
              <Tooltip title={t('userLevelDesc')}>
                <InfoOutlined sx={{ fontSize: '14px', ml: '2px' }} />
              </Tooltip>
            </Stack>
          </Stack>
        )}
        {subdomains.map((s) => (
          <PermissionSubDomainAccordionComponent
            subdomain={s}
            permissions={subdomainToPermissionsMap.get(s) ?? []}
            downMd={props.downMd}
            downLg={props.downLg}
            handleChangeCheckbox={props.handleChangeCheckbox}
            selectedPermissionIds={props.selectedPermissionIds}
            rolePermissionIds={props.rolePermissionIds}
            openedPermissionsState={props.openedPermissionsState}
          />
        ))}
      </PermissionAccordionDetails>
    </PermissionAccordion>
  );
};
