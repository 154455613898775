/* eslint-disable react-hooks/exhaustive-deps */
import { useReminderDetailQuery } from '../../context/reminder.api';
import { Dispatch, memo, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { IReminder, IReminderDetailResult } from 'corede-common-cocrm';
import { IBaseError, IHasId } from 'corede-common';
import { DefaultDetailDrawer } from '../../../../../../components/detailDrawer/DefaultDetailDrawer';
import { ReminderDetailDrawerLeftPanelComponent } from './ReminderDetailDrawer-leftPanel.component';
import { ReminderDetailDrawerRightPanelComponent } from './ReminderDetailDrawer-rightPanel.component';
import { DefaultErrorHandlerUseEffect } from 'utils/useEffect.helper';
import { getCurrentLanguage } from 'localization';
import { TListGridSelectedEntity } from 'apps/crm/components/list/DefaultList-grid.component';

export interface IReminderDetailDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedReminder: IReminderDetailResult & IHasId<number>;
  setSelectedReminder: Dispatch<SetStateAction<TListGridSelectedEntity<IReminder>>>;
  sideNavigationProps?: {
    count: number;
    handleNavigate: Function;
  };
}

const ReminderDetailDrawer = memo((props: IReminderDetailDrawerProps) => {
  // init
  const { t } = useTranslation();
  const currentLanguage = getCurrentLanguage();

  console.debug('loaded: ReminderDetailDrawer');

  // queries
  const { data: reminderDetailData, error: reminderDetailError } = useReminderDetailQuery(
    {
      input: {
        _id: props?.selectedReminder?._id,
      },
    },
    {
      skip: !props?.selectedReminder?._id,
    },
  );

  useEffect(() => {
    DefaultErrorHandlerUseEffect(reminderDetailError as IBaseError, currentLanguage);
  }, [reminderDetailError]);

  return (
    <DefaultDetailDrawer<IReminderDetailResult>
      open={props.open}
      setOpen={props.setOpen}
      entity={props.selectedReminder}
      sideNavigationProps={
        !props.sideNavigationProps
          ? undefined
          : {
              count: props.sideNavigationProps.count,
              handleNavigate: props.sideNavigationProps.handleNavigate,
              recordsTranslation: t('crm.calendar.reminder.reminder'),
            }
      }
    >
      <ReminderDetailDrawerLeftPanelComponent
        reminderDetailData={reminderDetailData}
        setOpen={props.setOpen}
      />
      <ReminderDetailDrawerRightPanelComponent reminderDetailData={reminderDetailData} />
    </DefaultDetailDrawer>
  );
});

export default ReminderDetailDrawer;
