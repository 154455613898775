/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { MyTab } from 'components';
import { unknownError } from 'corede-common';
import { useEffect, useMemo, useState } from 'react';
import {
  useOrganizationCardDetailOwnQuery,
  useOrganizationDetailOwnQuery,
} from '../../context/setting.api';
import { getCurrentLanguage } from 'localization';
import { enqueueSnackbar } from 'notistack';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { useSearchParams } from 'react-router-dom';
import { useLazySubscriptionDetailQuery } from 'apps/crm/domains/16-pricing/subdomains/pricing';
import { useUserDetailOwnQuery } from 'apps/auth/context';
import { PaymentCurrentPlan } from './payment.currentPlan';
import { PaymentBillingInfo } from './payment.billingInfo';
import { PaymentCards } from './payment.cards';
import { PaymentInvoices } from './payment.invoices';

const Payment = () => {
  const currentLanguage = getCurrentLanguage();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [tabLabels, setTabLabels] = useState(['']);
  const [selectedTabName, setSelectedTabName] = useState('');

  const { data: userDetailOwnData } = useUserDetailOwnQuery({});
  const { data: organizationDetailOwnData, error: organizationDetailOwnError } =
    useOrganizationDetailOwnQuery({});
  const { data: organizationCardDetailOwnData } = useOrganizationCardDetailOwnQuery(
    {},
    {
      skip: !organizationDetailOwnData?.integrations?.iyzicoUserCard,
    },
  );

  const [getSubscriptionDetail, { data: subscriptionDetailData }] =
    useLazySubscriptionDetailQuery();

  const status = organizationDetailOwnData?.status;
  const trialStatus = organizationDetailOwnData?.activeSubscriptions?.trial?.status;
  const mainStatus = organizationDetailOwnData?.activeSubscriptions?.main?.status;

  // Current Plan

  useEffect(() => {
    if (!!organizationDetailOwnData?.activeSubscriptions?.main) {
      getSubscriptionDetail({
        input: {
          _id: organizationDetailOwnData?.activeSubscriptions?.main?._id ?? '',
        },
      });
    }
  }, [organizationDetailOwnData?.activeSubscriptions?.main]);

  // Billing Info

  // Cards

  useEffect(() => {
    if (organizationDetailOwnError) {
      enqueueSnackbar(
        (organizationDetailOwnError as any)?.error?.message[currentLanguage] ??
          unknownError.message[currentLanguage],
        { variant: 'error' },
      );
    }
  }, [organizationDetailOwnError]);

  //Common 

  useEffect(() => {
    switch (status) {
      case 'restricted':
        if (mainStatus || trialStatus) {
          setTabLabels([
            'settings.settings.settings.currentPlan',
            'settings.settings.settings.billingInfo',
            'settings.settings.settings.cards',
          ]);
        } else {
          setTabLabels([
            'settings.settings.settings.billingInfo',
            'settings.settings.settings.cards',
          ]);
        }
        break;
      case 'active':
        setTabLabels([
          'settings.settings.settings.currentPlan',
          'settings.settings.settings.billingInfo',
          'settings.settings.settings.cards',
          'settings.settings.settings.invoices',
        ]);
        break;
      default:
        break;
    }
  }, [status]);

  useEffect(() => {
    if (tabLabels) {
      switch (tab) {
        case 'billingInfo':
          setSelectedTabName('settings.settings.settings.billingInfo');
          break;
        case 'cards':
          setSelectedTabName('settings.settings.settings.cards');
          break;
        default:
          setSelectedTabName(tabLabels[0]);
          break;
      }
    }
  }, [tabLabels, tab, status]);

  const memoizedTab = useMemo(
    () => (
      <MyTab
        type="settings"
        labels={tabLabels}
        setSelectedTab={setSelectedTabName}
        selectedTab={selectedTabName}
      />
    ),
    [tabLabels, selectedTabName, status],
  );

  return (
    <Stack direction={'column'} width={'100%'} spacing={2} pt={3}>
      <Stack
        direction={'row'}
        sx={{
          // bgcolor: 'background.secondary',
          overflow: 'hidden',
          borderRadius: '30px',
          px: 5,
        }}
      >
        {memoizedTab}
      </Stack>

      {selectedTabName === 'settings.settings.settings.currentPlan' && (
        <PaymentCurrentPlan
          setSelectedTabName={setSelectedTabName}
          organizationCardData={organizationCardDetailOwnData}
          organizationData={organizationDetailOwnData}
          subscriptionData={subscriptionDetailData}
        />
      )}
      {selectedTabName === 'settings.settings.settings.billingInfo' && (
        <PaymentBillingInfo userDetailOwnData={userDetailOwnData} />
      )}
      {selectedTabName === 'settings.settings.settings.cards' && (
        <PaymentCards
          organizationCardDetailOwnData={organizationCardDetailOwnData}
          organizationDetailOwnData={organizationDetailOwnData}
        />
      )}

      {selectedTabName === 'settings.settings.settings.invoices' && (
        <PaymentInvoices selectedTabName={selectedTabName} />
      )}
    </Stack>
  );
};

export default Payment;
