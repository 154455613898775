import {
  contractListQuery,
  IContractListRequest,
  IContractListInput,
  IContractListResponse,
  IContractListResult,
  contractListCountQuery,
  IContractListCountRequest,
  IContractListCountResponse,
  contractDetailQuery,
  IContractDetailRequest,
  IContractDetailInput,
  IContractDetailResponse,
  IContractDetailResult,
  contractCreateQuery,
  IContractCreateRequest,
  IContractCreateInput,
  IContractCreateResponse,
  IContractCreateResult,
  contractUpdateQuery,
  IContractUpdateRequest,
  IContractUpdateInput,
  IContractUpdateFilterInput,
  IContractUpdateResponse,
  IContractUpdateResult,
  contractDeleteQuery,
  IContractDeleteRequest,
  IContractDeleteInput,
  IContractDeleteResponse,
  IContractDeleteResult,
  contractRenewQuery,
  IContractRenewRequest,
  IContractRenewInput,
  IContractRenewFilterInput,
  IContractRenewResponse,
  IContractRenewResult,
} from 'corede-common-cocrm';
import { commonApi } from 'context/commonApi';
import { EndpointQueryBuilder } from 'apps/helper/endpoint-query.builder';
import { contractTags } from './contract.tags';
import { IGraphqlVariables, ICount } from 'corede-common';

export const contractsApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    contractList: builder.query<IContractListResult, IGraphqlVariables<IContractListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractListRequest,
        IContractListResponse,
        IContractListResult,
        IContractListInput
      >({
        query: contractListQuery(),
        providesTags: [contractTags.contracts],
      }),
    ),

    contractListCount: builder.query<ICount, IGraphqlVariables<undefined>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractListCountRequest,
        IContractListCountResponse,
        ICount,
        undefined
      >({
        query: contractListCountQuery,
        providesTags: [contractTags.contracts],
      }),
    ),

    contractDetail: builder.query<IContractDetailResult, IGraphqlVariables<IContractDetailInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractDetailRequest,
        IContractDetailResponse,
        IContractDetailResult,
        IContractDetailInput
      >({
        query: contractDetailQuery(),
        providesTags: [contractTags.contract],
      }),
    ),

    // mutation
    contractCreate: builder.mutation<
      IContractCreateResult,
      IGraphqlVariables<IContractCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractCreateRequest,
        IContractCreateResponse,
        IContractCreateResult,
        IContractCreateInput
      >({
        query: contractCreateQuery,
        invalidatesTags: [contractTags.contracts],
      }),
    ),

    contractUpdate: builder.mutation<
      IContractUpdateResult,
      IGraphqlVariables<IContractUpdateInput, IContractUpdateFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractUpdateRequest,
        IContractUpdateResponse,
        IContractUpdateResult,
        IContractUpdateInput,
        IContractUpdateFilterInput
      >({
        query: contractUpdateQuery,
        invalidatesTags: [contractTags.contract, contractTags.contracts],
      }),
    ),

    contractDelete: builder.mutation<
      IContractDeleteResult,
      IGraphqlVariables<IContractDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractDeleteRequest,
        IContractDeleteResponse,
        IContractDeleteResult,
        IContractDeleteInput
      >({
        query: contractDeleteQuery,
      }),
    ),

    contractRenew: builder.mutation<
      IContractRenewResult,
      IGraphqlVariables<IContractRenewInput, IContractRenewFilterInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IContractRenewRequest,
        IContractRenewResponse,
        IContractRenewResult,
        IContractRenewInput,
        IContractRenewFilterInput
      >({
        query: contractRenewQuery,
        invalidatesTags: [contractTags.contract, contractTags.contracts],
      }),
    ),
  }),
});

export const {
  useContractListQuery,
  useLazyContractListQuery,
  useContractListCountQuery,
  useLazyContractListCountQuery,
  useContractDetailQuery,
  useLazyContractDetailQuery,

  useContractCreateMutation,
  useContractUpdateMutation,
  useContractDeleteMutation,
  useContractRenewMutation
} = contractsApi;
