/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Stack, Typography, Grid, useMediaQuery, Theme } from '@mui/material';
import { Iconify } from 'components';
import { useTranslation } from 'react-i18next';
import { IInvoiceDetailResult } from 'corede-common-cocrm';
import { DefaultTabComponentProps } from '../../../../../../components/tabs/DefaultTabs.component';

export interface IInvoiceDetailDrawerTabInfoComponentProps
  extends DefaultTabComponentProps<IInvoiceDetailResult> {
  invoiceDetailData: IInvoiceDetailResult | undefined;
  tabKey: 'additionalInfo';
  name: 'crm.additionalInfo';
}

export const InvoiceDetailDrawerTabAdditionalInfoComponent = (
  props: IInvoiceDetailDrawerTabInfoComponentProps,
) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const { useLegalNDA, useLegalTermAndCond } = props.invoiceDetailData || {};

  const additionalInfoData = [
    {
      title: t('crm.preSale.estimate.useLegalNDA'),
      value: useLegalNDA ? t('crm.preSale.estimate.yes') : t('crm.preSale.estimate.no'),
      icon: 'mage:location',
    },
    {
      title: t('crm.preSale.estimate.useLegalTermAndCond'),
      value: useLegalTermAndCond ? t('crm.preSale.estimate.yes') : t('crm.preSale.estimate.no'),
      icon: 'tabler:language',
    },
  ];
  return (
    <Grid container mt={downMd ? 0 : 2} mb={2}>
      <Grid item xs={12}>
        <Stack
          direction={'column'}
          p={2.5}
          mt={downMd ? 2 : 0}
          sx={{ borderRadius: 3, boxShadow: '0px 3px 12px 0px rgba(35, 32, 32, 0.1)' }}
        >
          <Typography
            variant={'caption'}
            sx={{ fontWeight: 500, color: 'text.primary', marginBottom: '6px' }}
          >
            {t('crm.preSale.estimate.additionalInfoTitle')}
          </Typography>
          <Grid container spacing={1}>
            {additionalInfoData.map((item) => (
              <Grid item xs={12}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      bgcolor: 'secondary.extraLight',
                      borderRadius: '6px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Iconify icon={item.icon} width={18} sx={{ color: 'secondary.main' }} />
                  </Box>
                  <Stack direction={'column'}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }} color={'text.primary'}>
                      {item.value ?? '-'}
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      {item.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};
